import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AppConfig } from '../app.config';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private _baseUrl = AppConfig.settings.baseUrl;
  private _reqHeader = new HttpHeaders({ 'Accept': 'application/json', 'Content-Type': 'application/json' });

  constructor ( 
    private _http: HttpClient, 
  ){}

  getUserById(userId) {
    return this._http.get(this._baseUrl + '/api/Account/GetUserById/' + userId, { headers: this._reqHeader });
  }

  getMyUser() {
    return this._http.get(this._baseUrl + '/api/Account/GetMyUser', { headers: this._reqHeader });
  }

  createUser(user) {
    return this._http.post(this._baseUrl + '/api/Account/CreateUser', user, { headers: this._reqHeader });
  }

  updateUser(userId, user) {
    return this._http.put(this._baseUrl + '/api/Account/UpdateUser/' + userId, user, { headers: this._reqHeader });
  }

  deleteUser(userId, forceDisassociationOrDeletionOfDependencies) {
    return this._http.delete(this._baseUrl + '/api/Account/DeleteUser/' + userId  + '/' + forceDisassociationOrDeletionOfDependencies, { headers: this._reqHeader });
  }

  updateMyUser(user) {
    return this._http.put(this._baseUrl + '/api/Account/UpdateMyUser', user, { headers: this._reqHeader });
  }

  changeMyPassword(params) {
    return this._http.put(this._baseUrl + '/api/Account/ChangeMyPassword', params, { headers: this._reqHeader });
  }

  setPassword(userId, params) {
    return this._http.put(this._baseUrl + '/api/Account/SetPassword/' + userId, params, { headers: this._reqHeader });
  }

  getAllManageableUsers(params?) {
    return this._http.get(this._baseUrl + '/api/Account/GetAllManageableUsers', { params: params, headers: this._reqHeader });
  }

  getActiveManageableUsers(params?) {
    return this._http.get(this._baseUrl + '/api/Account/GetActiveManageableUsers', { params: params, headers: this._reqHeader });
  }

  getAllManageableUsersByStoreGroup(storeGroupId) {
    return this._http.get(this._baseUrl + '/api/Account/GetAllManageableUsersByStoreGroup/' + storeGroupId, { headers: this._reqHeader });
  }

  getActiveManageableUsersByStoreGroup(storeGroupId) {
    return this._http.get(this._baseUrl + '/api/Account/GetActiveManageableUsersByStoreGroup/' + storeGroupId, { headers: this._reqHeader });
  }

  getSuperStoreGroupAdministratorsManagingStoreGroup(storeGroupId) {
    return this._http.get(this._baseUrl + '/api/Account/GetSuperStoreGroupAdministratorsManagingStoreGroup/' + storeGroupId, { headers: this._reqHeader });
  }

  getAllManageableUsersByStore(storeId) {
    return this._http.get(this._baseUrl + '/api/Account/GetAllManageableUsersByStore/' + storeId, { headers: this._reqHeader });
  }

  getActiveManageableUsersByStore(storeId) {
    return this._http.get(this._baseUrl + '/api/Account/GetActiveManageableUsersByStore/' + storeId, { headers: this._reqHeader });
  }

  getAllRoles(){
    return this._http.get(this._baseUrl + '/api/Roles/GetAllRoles', { headers: this._reqHeader });
  }

  getRoleCollections(){
    return this._http.get(this._baseUrl + '/api/Roles/GetRoleCollections', { headers: this._reqHeader });
  }

  getRoleNamesByCollectionName(roleCollectionName){
    return this._http.get(this._baseUrl + '/api/Roles/GetRoleNamesByCollectionName/' + roleCollectionName, { headers: this._reqHeader });
  }
}
