import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { AppConfig } from '../app.config';

@Injectable({
  providedIn: 'root'
})
export class PriceManagementService {

  private _baseUrl = AppConfig.settings.baseUrl;
  private _reqHeader = new HttpHeaders({ 'Accept': 'application/json', 'Content-Type': 'application/json' });

  constructor ( 
    private _http: HttpClient, 
  ){}

  getAllStockItemsByStoreId(storeId) {
    return this._http.get(this._baseUrl + '/api/PriceManagement/GetAllStockItemsByStoreId/' + storeId, { headers: this._reqHeader });
  }

  getAllNonStockItemsByStoreId(storeId) {
    return this._http.get(this._baseUrl + '/api/PriceManagement/GetAllNonStockItemsByStoreId/' + storeId, { headers: this._reqHeader });
  }

  getAllItemsByStoreIdCount(storeId) {
    return this._http.get(this._baseUrl + '/api/PriceManagement/GetAllItemsByStoreId/' + storeId + '?$count=true', { headers: this._reqHeader });
  }

  getAllItemsByStoreId(storeId, params?) {
    return this._http.get(this._baseUrl + '/api/PriceManagement/GetAllItemsByStoreId/' + storeId, { params: params, headers: this._reqHeader });
  }

  getAllPreviewItemsByStoreId(storeId) {
    return this._http.get(this._baseUrl + '/api/PriceManagement/GetAllPreviewItemsByStoreId/' + storeId, { headers: this._reqHeader });
  }

  getAllPreviewItemsByStoreIdV2(storeId) {
    return this._http.get(this._baseUrl + '/api/PriceManagement/GetAllPreviewItemsByStoreIdV2/' + storeId, { headers: this._reqHeader });
  }

  getAllItemsByStoreIdAndItemListId(storeId, itemListId) {
    return this._http.get(this._baseUrl + '/api/PriceManagement/GetAllItemsByStoreIdAndItemListId/' + storeId + '/' + itemListId, { headers: this._reqHeader });
  }

  getPricingGridMetadata() {
    return this._http.get(this._baseUrl + '/api/PriceManagement/GetPricingGridMetadata', { headers: this._reqHeader });
  }

  updatePricingGridMetadata(pricingGridMetadata) {
    return this._http.put(this._baseUrl + '/api/PriceManagement/UpdatePricingGridMetadata', pricingGridMetadata, { headers: this._reqHeader });
  }

  getStoreItemForAssociatedPreviewStoreItemByPreviewStoreItemId(previewStoreItemId) {
    return this._http.get(this._baseUrl + '/api/PriceManagement/GetStoreItemForAssociatedPreviewStoreItemByPreviewStoreItemId/' + previewStoreItemId, { headers: this._reqHeader });
  }

  getReachableActiveUsers() {
    return this._http.get(this._baseUrl + '/api/PriceManagement/GetReachableActiveUsers', { headers: this._reqHeader });
  }

  getAllStoreItemPriceChangesByStoreItemId(storeItemId) {
    return this._http.get(this._baseUrl + '/api/StoreItemPriceChanges/GetAllStoreItemPriceChangesByStoreItemId/' + storeItemId, { headers: this._reqHeader });
  }

  getAllPreviewStoreItemPriceChangesByPreviewStoreItemId(previewStoreItemId) {
    return this._http.get(this._baseUrl + '/api/PreviewStoreItemPriceChanges/GetAllPreviewStoreItemPriceChangesByPreviewStoreItemId/' + previewStoreItemId, { headers: this._reqHeader });
  }

  getAllOrderHeaders() {
    return this._http.get(this._baseUrl + '/api/OrderHeaders/GetAllOrderHeaders', { headers: this._reqHeader });
  }

  getAllStockItemsWithTprTagPendingFulfillmentByStoreId(storeId) {
    return this._http.get(this._baseUrl + '/api/PriceManagement/GetAllStockItemsWithTprTagPendingFulfillmentByStoreId/' + storeId, { headers: this._reqHeader });
  }

  getAllStockItemsWithShelfTagPendingFulfillmentByStoreId(storeId) {
    return this._http.get(this._baseUrl + '/api/PriceManagement/GetAllStockItemsWithShelfTagPendingFulfillmentByStoreId/' + storeId, { headers: this._reqHeader });
  }

  getStoreItemsToBeSynchronizedCountByStoreIdSinceDateTime(storeId, dateSince){
    return this._http.get(this._baseUrl + '/api/PriceManagement/GetStoreItemsToBeSynchronizedCountByStoreIdSinceDateTime/' + storeId + '/' + dateSince, { headers: this._reqHeader });
  }

  getPreviewStoreItemsToBeSynchronizedCountByStoreIdSinceDateTime(storeId, dateSince){
    return this._http.get(this._baseUrl + '/api/PriceManagement/GetPreviewStoreItemsToBeSynchronizedCountByStoreIdSinceDateTime/' + storeId + '/' + dateSince, { headers: this._reqHeader });
  }

  getCachedStoreDataExpiration() {
    return this._http.get(this._baseUrl + '/api/PriceManagement/GetCachedStoreDataExpiration/', { headers: this._reqHeader });
  }

  getControllingAndControlledStoreItemsByStoreIdAndItemId(storeId, itemId) {
    return this._http.get(this._baseUrl + '/api/PriceManagement/GetControllingAndControlledStoreItemsByStoreIdAndItemId/' + storeId + '/' + itemId, { headers: this._reqHeader });
  }

  getControllingAndControlledPreviewStoreItemsByStoreIdAndItemId(storeId, itemId) {
    return this._http.get(this._baseUrl + '/api/PriceManagement/GetControllingAndControlledPreviewStoreItemsByStoreIdAndItemId/' + storeId + '/' + itemId, { headers: this._reqHeader });
  }

}
