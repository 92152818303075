import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DeletionService } from '../deletion.service';
import { MessageService } from '../message.service';

@Component({
  selector: 'app-deletion-history-dialog',
  templateUrl: './deletion-history-dialog.component.html',
  styleUrls: ['./deletion-history-dialog.component.css']
})
export class DeletionHistoryDialogComponent implements OnInit {

  logEntries = [];
  entityType;

  //this will be used for all entities
  //pass in the entity type and call corresponding api call
  constructor(
    private _deletionService: DeletionService,
    private _messageService: MessageService,
    public dialogRef: MatDialogRef<DeletionHistoryDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) 
  { 
    this.entityType = data.entityType;
  }

  ngOnInit(): void {
    this.getStoreGroupDeletionLogs();
  }

  getStoreGroupDeletionLogs(){
    this._deletionService.getDeletionLogsByDeletionEntityType(this.entityType)
      .subscribe(
        (data: any) => {
          this.logEntries = data;
          this.logEntries.map(l => l.performedAt = new Date(l.performedAt).toLocaleString());
          this.logEntries.map(l => l.deletionDescription = this.formatLogDescription(l.deletionDescription));
        },
        (response) => {
          this._messageService.onFailure('Failed to get Deletion Logs.', response);
        })
  }

  formatLogDescription(description) {
    if (description) {
      description = description.replace(/\r\n/g, '<br/>')
      .replace(/\u00b9/g, '<span class="description-highlight">')
      .replace(/\u00b2/g, '<span class="description-highlight">')
      .replace(/\u00b3/g, '<span class="description-highlight">')
      .replace(/\u2074/g, '<span class="description-highlight">')
      .replace(/\u2075/g, '<span class="description-highlight">')
      .replace(/\u2076/g, '<span class="description-highlight">')
      .replace(/\u2077/g, '<span class="description-highlight">')
      .replace(/\u2078/g, '<span class="description-highlight">')
      .replace(/\u00b7/g, '</span>');
    }
    return description;
  }

  closeDialog(){
    this.dialogRef.close();
  }


}
