import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { MessageService } from '../shared/message.service';

@Injectable({
  providedIn: 'root'
})
export class PriceManagementFilterService {

  gridFilterModel = {};
  tabModel = {
    activeTab: 'AllItems',
    activeSubTab: 'AllItems',
  }
  //store filterModelFromGrid everytime model changes on grid
  //when a tab is clicked, the deptCode, isStock, etc.  will be added and sent back to the grid
  filterModelFromGrid: any = {};
  quickFilterText = '';
  nameOfItemFilterToApplyAfterLoadingItems: string = '';

  tabModel$: Observable<any>;
  gridFilterModel$: Observable<any>;

  //need BehaviorSubject so that it sends stored value even if no change
  private _tabModelSubject = new BehaviorSubject<any>(this.tabModel);
  private _gridFilterModelSubject = new BehaviorSubject<any>(this.gridFilterModel);

  constructor(
    private _messageService: MessageService,
  ) {
    this.tabModel$ = this._tabModelSubject.asObservable();
    this.gridFilterModel$ = this._gridFilterModelSubject.asObservable();
  }

  getGridFilterModel(){
    return this.filterModelFromGrid;
  }

  getTabModel(){
    return this.tabModel;
  }

  // Sets tab model and appearance of tabs but doesn't append to gridFilterModel
  setTabModel(tabModel){
    this.tabModel = tabModel;
    this._tabModelSubject.next(tabModel);
  }

  setGridFilterModel(filterModelFromGrid){
    this.filterModelFromGrid = this.removePropertiesNotBeingFilteredFromGridFilterModel(filterModelFromGrid);
  }

  // Sets tab model and appends to gridFilterModel which filters grid
  // This is used on tab clicks
  setGridFilterModelFromTabModel(tabModel){
    this.setTabModel(tabModel);
    this.gridFilterModel = this.appendTabFiltersToGridFilterModel(this.filterModelFromGrid, this.tabModel);
    this._gridFilterModelSubject.next(this.gridFilterModel);
    this.showCurrentFiltersThatAreApplied(this.gridFilterModel);
  }

  setQuickFilterText(quickFilterText){
    this.quickFilterText = quickFilterText;
  }

  getNameOfItemFilterToApplyAfterLoadingItems(){
    return this.nameOfItemFilterToApplyAfterLoadingItems;
  }

  setNameOfItemFilterToApplyAfterLoadingItems(itemFilterName){
    this.nameOfItemFilterToApplyAfterLoadingItems = itemFilterName;
  }

  updateSubscribersToGridFilterModel(){
    this._gridFilterModelSubject.next(this.gridFilterModel);
  }

  appendTabFiltersToGridFilterModel(filterModelFromGrid, tabModel){
    // activeTab
    if (filterModelFromGrid.hasOwnProperty('deptCode')) delete filterModelFromGrid.deptCode;
    if (filterModelFromGrid.hasOwnProperty('isPreview')) delete filterModelFromGrid.isPreview;
    var tabValueArray = this.getValuesForActiveTabFilter(tabModel.activeTab);
    if (tabValueArray.length > 0){
      if (tabValueArray[0] === 'PREVIEW'){
        filterModelFromGrid.isPreview = {
          filterType: 'set',
          values: ['true'],
        }
      }
      else{
        filterModelFromGrid.isPreview = {
          filterType: 'set',
          values: ['false'],
        }
        filterModelFromGrid.deptCode = {
          filterType: 'set',
          values: tabValueArray,
        }
      }
    }
    else { // All Depts Tab
      filterModelFromGrid.isPreview = {
        filterType: 'set',
        values: ['false'],
      }
    }

    //active subTab
    if (filterModelFromGrid.hasOwnProperty('isStock')) delete filterModelFromGrid.isStock;
    var subTabValueArray = this.getValuesForActiveSubTabFilter(tabModel.activeSubTab);
    if (subTabValueArray.length > 0){
      if (subTabValueArray[0] === 'true' || subTabValueArray[0] === 'false'){
        filterModelFromGrid.isStock = {
          filterType: 'set',
          values: subTabValueArray,
        }
      }
      else{
        filterModelFromGrid.deptCode = {
          filterType: 'set',
          values: subTabValueArray,
        }
      }
    }
    return filterModelFromGrid;
  }

  getValuesForActiveTabFilter(activeTab) {
    var valuesArray = [];
    switch (activeTab) {
      case 'AllItems':
        valuesArray = [];
        break;
      case 'Grocery':
        valuesArray = ['A', 'B', 'C', 'E', 'K'];
        break;
      case 'Meat':
        valuesArray = ['M', 'N'];
        break;
      case 'Produce':
        valuesArray = ['P'];
        break;
      case 'DeliBakery':
        valuesArray = ['S', 'X', 'Z']; 
        break;
      case 'Dairy':
        valuesArray = ['D'];
        break;
      case 'HBA':
        valuesArray = ['G', 'H', 'I'];
        break;
      case 'FrozenFood':
        valuesArray = ['F'];
        break;
      case 'Preview':
        valuesArray = ['PREVIEW'];
        break;
      default:
        valuesArray = [];
    }
    return valuesArray;
  }

  getValuesForActiveSubTabFilter(activeSubTab) {
    var valuesArray = [];
    switch (activeSubTab) {
      case 'StockItems':
        valuesArray = ['true'];
        break;
      case 'NonStockItems':
        valuesArray = ['false'];
        break;
      case 'GroceryPreviewItems':
        valuesArray = ['A', 'B', 'C', 'K', 'S', 'X', 'Z', 'D', 'E', 'G', 'H', 'I', 'F'];
        break;
      case 'MeatPreviewItems':
        valuesArray = ['M', 'N'];
        break;
      case 'ProducePreviewItems':
        valuesArray = ['P'];
        break;
      default:
        valuesArray = [];
    }
    return valuesArray;
  }

  // for highlight clear filters button
  testIfFilterApplied(){
    if (this.filterModelFromGrid){
      var keys = Object.keys(this.filterModelFromGrid);
      for (var i=0; i<keys.length; i++){
        if (keys[i] != 'deptCode' && keys[i] != 'isStock' && keys[i] != 'isPreview'){
          if (this.filterModelFromGrid[keys[i]].hasOwnProperty('filter') && this.filterModelFromGrid[keys[i]].filter != '' && this.filterModelFromGrid[keys[i]].filter != null){
            return true;
          }
          // set filter is set
          else if (this.filterModelFromGrid[keys[i]].hasOwnProperty('values') && this.filterModelFromGrid[keys[i]].values.length > 0){
            return true;
          }
          // item list filter is set
          else if (keys[i] == 'itemLists' && this.filterModelFromGrid[keys[i]].hasOwnProperty('value')){
            return true;
          }
          // the grid's new multi value conditional filters with AND and OR
          else if (this.filterModelFromGrid[keys[i]].hasOwnProperty('condition1') && this.filterModelFromGrid[keys[i]].condition1.hasOwnProperty('filter') && this.filterModelFromGrid[keys[i]].condition1.filter != '' && this.filterModelFromGrid[keys[i]].condition1.filter != null){
            return true;
          }
        } 
      }
    }
    if (this.quickFilterText != ''){
      return true;
    }
    return false;
  }

  //after removing a filter the grid's filter model leaves a filter property that is empty and it needs to be removed
  removePropertiesNotBeingFilteredFromGridFilterModel(filterModelFromGrid){
    if (filterModelFromGrid){
      var keys = Object.keys(filterModelFromGrid);
      for (var i=0; i<keys.length; i++){
        if (keys[i] != 'deptCode' && keys[i] != 'isStock' && keys[i] != 'isPreview'){
          if (filterModelFromGrid[keys[i]].hasOwnProperty('filter') && (filterModelFromGrid[keys[i]].filter == '' || filterModelFromGrid[keys[i]].filter == null)){
            delete filterModelFromGrid[keys[i]];
          }
          else if (filterModelFromGrid[keys[i]].hasOwnProperty('values') && filterModelFromGrid[keys[i]].values.length < 1){
            delete filterModelFromGrid[keys[i]];
          }
        } 
      }
    }
    return filterModelFromGrid;
  }

  showCurrentFiltersThatAreApplied(filterModel) {
    //remove the columns that will always be filtered because of tabs
    if (filterModel.hasOwnProperty('deptCode')) delete filterModel.deptCode;
    if (filterModel.hasOwnProperty('isStock')) delete filterModel.isStock;
    if (filterModel.hasOwnProperty('isPreview')) delete filterModel.isPreview;
    if (filterModel.hasOwnProperty('keywordsFilteredBy')) delete filterModel.keywordsFilteredBy;
    if (!(Object.keys(filterModel).length === 0 && filterModel.constructor === Object)) {
      var columns = '';
      var numberOfKeys = 0;
      for (var key in filterModel) {
        numberOfKeys++;
        columns += this.convertCamelCaseToSentenceCaseWithSpaces(key) + ', ';
      }
      columns = columns.replace(/,([^,]*)$/, '$1');
      if (numberOfKeys > 1) {
        columns = columns.replace(/,([^,]*)$/, ' and $1');
        this._messageService.showToastTopRight(columns + ' columns are filtered.');
      }
      else {
        this._messageService.showToastTopRight(columns + ' column is filtered.');
      }
    }
    if (this.quickFilterText != ''){
      this._messageService.showToastTopRight('Quick Filtering on "' + this.quickFilterText + '"');
    }
  }

  convertCamelCaseToSentenceCaseWithSpaces(str) {
    var result = str.replace(/([A-Z])/g, " $1");
    return result.charAt(0).toUpperCase() + result.slice(1);
  }




}
