import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, Subject } from 'rxjs';
import * as moment from 'moment';
//import { environment } from 'src/environments/environment';
import { AppConfig } from '../app.config';

@Injectable({
  providedIn: 'root'
})
export class NotificationWindowService {
  private config = AppConfig.settings;

  areHubsLoaded: boolean = false;
  areHubsLoaded$: Observable<any>;
  notificationHtml$: Observable<any>;
  reachableUsers$: Observable<any>;
  nodeIdToView$: Observable<any>;

  //need BehaviorSubject so that it sends stored value even if no change
  private _areHubsLoadedSubject = new BehaviorSubject<any>(this.areHubsLoaded);
  private _notificationHtmlSubject = new Subject<any>();
  private _reachableUsersSubject = new Subject<any>();
  private _nodeIdToViewSubject = new Subject<any>();

  constructor(
  ) {
    if (this.config.logInits) console.log('window notification service init');
    this.areHubsLoaded$ = this._areHubsLoadedSubject.asObservable();
    this.notificationHtml$ = this._notificationHtmlSubject.asObservable();
    this.reachableUsers$ = this._reachableUsersSubject.asObservable();
    this.nodeIdToView$ = this._nodeIdToViewSubject.asObservable();
   }

  setNodeIdToView(nodeId){
    this._nodeIdToViewSubject.next(nodeId);
  }

  setAreHubsLoaded(bool){
    this.areHubsLoaded = bool;
    this._areHubsLoadedSubject.next(this.areHubsLoaded);
  }

  setReachableUsers(users){
    this._reachableUsersSubject.next(users);
  }

  createUserWorkingInStoreNotification(userInfo) {
    var dateStr = moment(userInfo.lastInteractionDate).format("M/D/YYYY, h:mm:ss a");
    var inStore = (!this.isEmpty(userInfo.storeWorkingIn)) ? 'In Store # ' + userInfo.storeWorkingIn.storeNumber : '';
    var message = userInfo.user.fullName + ' is now working in Store: ' + userInfo.storeWorkingIn.numberAndLocation + '.';

    var notificationHtml =
      '<div class="system enter">' +
      '<div class="sender">' +
      '<div class="sender-name"><span class="full-name">' + userInfo.user.fullName + '</span><span class="in-store">' + inStore + '</span></div>' +
      '<div class="sender-email">' + userInfo.user.userName + '</div>' +
      '</div>' +
      '<div class="message">' + this.htmlEncode(message) + '</div>' +
      '<div class="send-info">' + dateStr + '</div >' +
      '<span class="close" (click)="closeNotification($event)"><i class="fa fa-times" aria-hidden="true"></i></span>' +
      '</div> ';

    this._notificationHtmlSubject.next(notificationHtml);
  }

  createSystemNotification(data) {
    var dateStr = moment(data.sent).format("M/D/YYYY, h:mm:ss a");
    var inStore = (!this.isEmpty(data.store)) ? 'In Store # ' + data.store.storeNumber : '';
    var message = data.message;

    var notificationHtml =
      '<div class="system">' +
      '<div class="sender">' +
      '<div class="sender-name"><span class="full-name">' + data.userInfo.user.fullName + '</span><span class="in-store">' + inStore + '</span></div>' +
      '<div class="sender-email">' + data.userInfo.user.userName + '</div>' +
      '</div>' +
      '<div class="message">' + this.htmlEncode(message) + '</div>' +
      '<div class="send-info">' + dateStr + '</div >' +
      '<span class="close"><i class="fa fa-times" aria-hidden="true"></i></span>' +
      '</div> ';

    this._notificationHtmlSubject.next(notificationHtml);
  }

  createSystemMessageNotification(data) {
    var dateStr = moment(data.date).format("M/D/YYYY, h:mm:ss a");
    var message = this.htmlEncode(data.reason) + ' [' + dateStr + ']';

    this._notificationHtmlSubject.next(message);
  }

  createUserNotification(data) {
    var dateStr = moment(data.sent).format("M/D/YYYY, h:mm:ss a");
    var inStore = (!this.isEmpty(data.store)) ? 'In Store # ' + data.store.storeNumber : '';
    var myUser = JSON.parse(sessionStorage.getItem('rpmsUser')); //trying to get this from authenticationService caused circular dependency
    var chatClass = myUser.userName == data.userInfo.user.userName ? 'chat me' : 'chat';

    var notificationHtml =
      '<div class="' + chatClass + '">' +
      '<div class="sender">' +
      '<div class="sender-name"><span class="full-name">' + data.userInfo.user.fullName + '</span><span class="in-store">' + inStore + '</span></div>' +
      '<div class="sender-email">' + data.userInfo.user.userName + '</div>' +
      '</div>' +
      '<div class="message">' + this.htmlEncode(data.message) + '</div>' +
      '<div class="send-info">' + dateStr + '</div >' +
      '<span class="close"><i class="fa fa-times" aria-hidden="true"></i></span>' +
      '</div> ';

    this._notificationHtmlSubject.next(notificationHtml);
  }

  createUserExitedStoreNotification(userInfo, store) {
    var dateStr = moment(userInfo.lastInteractionDate).format("M/D/YYYY, h:mm:ss a");
    var inStore = (!this.isEmpty(store)) ? 'In Store # ' + store.storeNumber : '';
    var message = userInfo.user.fullName + ' exited Store: ' + store.numberAndLocation + '.';

    var notificationHtml =
      '<div class="system exit">' +
      '<div class="sender">' +
      '<div class="sender-name"><span class="full-name">' + userInfo.user.fullName + '</span><span class="in-store">' + inStore + '</span></div>' +
      '<div class="sender-email">' + userInfo.user.userName + '</div>' +
      '</div>' +
      '<div class="message">' + this.htmlEncode(message) + '</div>' +
      '<div class="send-info">' + dateStr + '</div >' +
      '<span class="close"><i class="fa fa-times" aria-hidden="true"></i></span>' +
      '</div> ';

    this._notificationHtmlSubject.next(notificationHtml);
  }

  createStoreItemTprTagUpdatedNotification(user, data) {
    var dateStr = moment(data.tprTagRequestDate).format("M/D/YYYY, h:mm:ss a");
    var inStore = (!this.isEmpty(data.store)) ? 'In Store # ' + data.store.storeNumber : '';
    var persist = data.tprPersist ? 'Always' : 'Once';
    var message = '';
    //var nodeId = data.isPreview ? data.previewStoreItemId : data.storeItemId;
    var nodeId = data.isPreview ? 'P' + data.itemCode : 'S' + data.itemCode;
    if (!data.tprTagPendingFulfillment) {
      message = user.fullName + ' canceled a Compare At Tag for the item with <a class="item-link" id="itemLink-' + nodeId + '">Item Code: ' + data.item.itemCode + '</a>.';
    }
    else {
      message = user.fullName + ' requested a Compare At Tag for the item with <a class="item-link" id="itemLink-' + nodeId + '">Item Code: ' + data.item.itemCode + '</a>. Tag Details: Book ' + data.tprBook + ', ' + persist;
    }

    var notificationHtml =
      '<div class="tag">' +
      '<div class="sender">' +
      '<div class="sender-name"><span class="full-name">' + user.fullName + '</span><span class="in-store">' + inStore + '</span></div>' +
      '<div class="sender-email">' + user.userName + '</div>' +
      '</div>' +
      '<div class="message">' + this.htmlEncode(message) + '</div>' +
      '<div class="send-info">' + dateStr + '</div >' +
      '<span class="close"><i class="fa fa-times" aria-hidden="true"></i></span>' +
      '</div> ';

    this._notificationHtmlSubject.next(notificationHtml);
  }

  createStoreItemShelfTagUpdatedNotification(user, data) {
    var dateStr = moment(data.shelfTagRequestDate).format("M/D/YYYY, h:mm:ss a");
    var inStore = (!this.isEmpty(data.store)) ? 'In Store # ' + data.store.storeNumber : '';
    //var nodeId = data.isPreview ? data.previewStoreItemId : data.storeItemId;
    var nodeId = data.isPreview ? 'P' + data.itemCode : 'S' + data.itemCode;
    var message = user.fullName + ' requested ' + data.shelfTagQuantityOrdered + ' Shelf Tags for the item with <a class="item-link" id="itemLink-' + nodeId + '">Item Code: ' + data.item.itemCode + '</a>.';

    var notificationHtml =
      '<div class="tag">' +
      '<div class="sender">' +
      '<div class="sender-name"><span class="full-name">' + user.fullName + '</span><span class="in-store">' + inStore + '</span></div>' +
      '<div class="sender-email">' + user.userName + '</div>' +
      '</div>' +
      '<div class="message">' + this.htmlEncode(message) + '</div>' +
      '<div class="send-info">' + dateStr + '</div >' +
      '<span class="close"><i class="fa fa-times" aria-hidden="true"></i></span>' +
      '</div> ';

    this._notificationHtmlSubject.next(notificationHtml);
  }

  createPreviewStoreItemPriceChangesEnabledStateChangeNotification(data) {
    var dateStr = moment(data.sent).format("M/D/YYYY, h:mm:ss a");
    var accessClass = data.value ? 'access-open' : 'access-closed';

    var notificationHtml =
      '<div class="' + accessClass + '">' +
      '<div class="sender">' +
      '<div class="sender-name"><span class="full-name">Pricing Window</span></div>' +
      '<div class="sender-email">Grocery Preview Store Items</div>' +
      '</div>' +
      '<div class="message">' + this.htmlEncode(data.reason) + '</div>' +
      '<div class="send-info">' + dateStr + '</div >' +
      '<span class="close"><i class="fa fa-times" aria-hidden="true"></i></span>' +
      '</div> ';

    this._notificationHtmlSubject.next(notificationHtml);
  }

  createMeatAndProducePreviewStoreItemPriceChangesEnabledStateChangeNotification(data) {
    var dateStr = moment(data.sent).format("M/D/YYYY, h:mm:ss a");
    var accessClass = data.value ? 'access-open' : 'access-closed';

    var notificationHtml =
      '<div class="' + accessClass + '">' +
      '<div class="sender">' +
      '<div class="sender-name"><span class="full-name">Pricing Window</span></div>' +
      '<div class="sender-email">Meat and Produce Preview Store Items</div>' +
      '</div>' +
      '<div class="message">' + this.htmlEncode(data.reason) + '</div>' +
      '<div class="send-info">' + dateStr + '</div >' +
      '<span class="close"><i class="fa fa-times" aria-hidden="true"></i></span>' +
      '</div> ';

    this._notificationHtmlSubject.next(notificationHtml);
  }

  createStoreItemPriceChangesEnabledStateChangeNotification(data) {
    var dateStr = moment(data.sent).format("M/D/YYYY, h:mm:ss a");
    var accessClass = data.value ? 'access-open' : 'access-closed';

    var notificationHtml =
      '<div class="' + accessClass + '">' +
      '<div class="sender">' +
      '<div class="sender-name"><span class="full-name">Pricing Window</span></div>' +
      '<div class="sender-email">Store Items</div>' +
      '</div>' +
      '<div class="message">' + this.htmlEncode(data.reason) + '</div>' +
      '<div class="send-info">' + dateStr + '</div >' +
      '<span class="close"><i class="fa fa-times" aria-hidden="true"></i></span>' +
      '</div> ';

    this._notificationHtmlSubject.next(notificationHtml);
  }

  createMeatAndProduceStoreItemPriceChangesEnabledStateChangeNotification(data) {
    var dateStr = moment(data.sent).format("M/D/YYYY, h:mm:ss a");
    var accessClass = data.value ? 'access-open' : 'access-closed';

    var notificationHtml =
      '<div class="' + accessClass + '">' +
      '<div class="sender">' +
      '<div class="sender-name"><span class="full-name">Pricing Window</span></div>' +
      '<div class="sender-email">Meat And Produce Store Items</div>' +
      '</div>' +
      '<div class="message">' + this.htmlEncode(data.reason) + '</div>' +
      '<div class="send-info">' + dateStr + '</div >' +
      '<span class="close"><i class="fa fa-times" aria-hidden="true"></i></span>' +
      '</div> ';

    this._notificationHtmlSubject.next(notificationHtml);
  }

  createServerMaintenanceEnabledStateChangeNotification(data) {
    var dateStr = moment(data.sent).format("M/D/YYYY, h:mm:ss a");
    var accessClass = data.value ? 'access-open' : 'access-closed';

    var notificationHtml =
      '<div class="' + accessClass + '">' +
      '<div class="sender">' +
      '<div class="sender-name"><span class="full-name">Server Maintenance Window</span></div>' +
      '<div class="sender-email">System Message</div>' +
      '</div>' +
      '<div class="message">' + this.htmlEncode(data.reason) + '</div>' +
      '<div class="send-info">' + dateStr + '</div >' +
      '<span class="close"><i class="fa fa-times" aria-hidden="true"></i></span>' +
      '</div> ';

    this._notificationHtmlSubject.next(notificationHtml);
  }

  createSynchronizationNotification(storeItem) {
    var dateStr = moment(storeItem.modified).format("M/D/YYYY, h:mm:ss a");
    var nodeId = storeItem.isPreview ? 'P' + storeItem.item.itemCode : 'S' + storeItem.item.itemCode;
    var notificationHtml = 'The item with <a class="item-link" id="itemLink-' + nodeId + '">Item Code: ' + storeItem.item.itemCode + '</a> has been synchronized. [' + dateStr + ']';
    
    this._notificationHtmlSubject.next(notificationHtml);
  }

  createItemUpdateNotification(user, storeItem) {
    var notificationHtml = '';
    var oldValue, newValue;
    var dateTime = moment(storeItem.modified).format('M/D/YYYY h:mm:ss A');
    //var nodeId = storeItem.isPreview ? storeItem.previewStoreItemId : storeItem.storeItemId;
    var nodeId = storeItem.isPreview ? 'P' + storeItem.item.itemCode : 'S' + storeItem.item.itemCode;
    //TODO - need store info from JL
    var inStore = (!this.isEmpty(storeItem.store)) ? 'In Store # ' + storeItem.store.storeNumber : '';
    if (user != null) {
      if (!this.isEmpty(storeItem.priceChangeInfo)) { //Price, Percent, Book changes
        dateTime = moment(storeItem.priceChangeInfo.changeDate).format('M/D/YYYY h:mm:ss A');
        var type = storeItem.priceChangeInfo.changeType;
        if ((type == 'Price' || type == 'Percent') && storeItem.priceChangeInfo.oldValue == storeItem.priceChangeInfo.newValue) {//keep price, keep percent
          oldValue = type == 'Price' ? storeItem.lastKeepSrp : storeItem.lastKeepPercent;
          newValue = type == 'Price' ? storeItem.keepSrp : storeItem.keepPercent;
          //Keep Price or Keep Percent changed
          notificationHtml =
            '<div class="' + type.toLowerCase() + '">' +
            '<div class="sender">' +
            '<div class="sender-name"><span class="full-name">' + storeItem.priceChangeInfo.user.fullName + '</span><span class="in-store">' + inStore + '</span></div>' +
            '<div class="sender-email">' + storeItem.priceChangeInfo.user.userName + '</div>' +
            '</div>' +
            '<div class="message">' +
            '<div class = "change-info">' + storeItem.priceChangeInfo.user.fullName + ' made a change to Keep ' + type + '</div>' +
            '<table><tr><td>Item Code</td><td>Old Value</td><td>New Value</td></tr><tr><td><a class="item-link" id="itemLink-' + nodeId + '">' + storeItem.item.itemCode + '</a></td><td>' + oldValue + '</td><td>' + newValue + '</td></tr></table>' +
            '</div>' +
            '<div class="send-info">' + moment(storeItem.priceChangeInfo.changeDate).format('M/D/YYYY h:mm:ss a') + '</div >' +
            '<span class="close"><i class="fa fa-times" aria-hidden="true"></i></span>' +
            '</div> ';
        }
        else {
          notificationHtml =
            '<div class="' + type.toLowerCase() + '">' +
            '<div class="sender">' +
            '<div class="sender-name"><span class="full-name">' + storeItem.priceChangeInfo.user.fullName + '</span><span class="in-store">' + inStore + '</span></div>' +
            '<div class="sender-email">' + storeItem.priceChangeInfo.user.userName + '</div>' +
            '</div>' +
            '<div class="message">' +
            '<div class = "change-info">' + storeItem.priceChangeInfo.user.fullName + ' made a ' + type + ' Change</div>' +
            '<table><tr><td>Item Code</td><td>Old Value</td><td>New Value</td></tr><tr><td><a class="item-link" id="itemLink-' + nodeId + '">' + storeItem.item.itemCode + '</a></td><td>' + storeItem.priceChangeInfo.oldValue + '</td><td>' + storeItem.priceChangeInfo.newValue + '</td></tr></table>' +
            '</div>' +
            '<div class="send-info">' + moment(storeItem.priceChangeInfo.changeDate).format('M/D/YYYY h:mm:ss a') + '</div >' +
            '<span class="close"><i class="fa fa-times" aria-hidden="true"></i></span>' +
            '</div> ';
        }

        this._notificationHtmlSubject.next(notificationHtml);
      }
      else if (storeItem.book == 'D') { //Deleted Items
        var fullName = user.fullName != '' ? user.fullName : user.userName;

        notificationHtml =
          '<div class="delete">' +
          '<div class="sender">' +
          '<div class="sender-name"><span class="full-name">' + fullName + '</span><span class="in-store">' + inStore + '</span></div>' +
          '<div class="sender-email">' + user.userName + '</div>' +
          '</div>' +
          '<div class="message">' +
          '<div class = "change-info">' + fullName + ' deleted item with Item Code: <a class="item-link" id="itemLink-' + nodeId + '">' + storeItem.item.itemCode + '</a></div>' +
          '</div>' +
          '<div class="send-info">' + dateTime + '</div >' +
          '<span class="close"><i class="fa fa-times" aria-hidden="true"></i></span>' +
          '</div> ';

        this._notificationHtmlSubject.next(notificationHtml);
      }
    }
    // this shouldn't happen
    else { 
      var message = 'Item with item code: ' + storeItem.item.itemCode + ' has been updated. [' + dateTime + ']';
      this._notificationHtmlSubject.next(message);
    }
  }


  htmlEncode(message) {
    //if we need to html encode the messages in the chat window, do it here
    return message;
  }

  isEmpty(obj) {
    var hasOwnProperty = Object.prototype.hasOwnProperty;
    if (obj == null) return true;
    if (obj.length > 0) return false;
    if (obj.length === 0) return true;
    if (typeof obj !== "object") return true;
    for (var key in obj) {
        if (hasOwnProperty.call(obj, key)) return false;
    }
    return true;
  }

  handleSychronizeMessages(d, myUserWorkingInStore) {
    //console.log(d);
    // clear notification window here
    this._notificationHtmlSubject.next('CLEAR');
    for (var i = 0; i < d.messages.length; i++) {
      var data = JSON.parse(d.messages[i].data);
      switch (d.messages[i].function) {
        case 'PreviewStoreItemPriceChangesEnabledStateChange':
          this.createPreviewStoreItemPriceChangesEnabledStateChangeNotification(data);
          break;
        case 'MeatAndProducePreviewStoreItemPriceChangesEnabledStateChange':
          this.createMeatAndProducePreviewStoreItemPriceChangesEnabledStateChangeNotification(data);
          break;
        case 'StoreItemPriceChangesEnabledStateChange':
          this.createStoreItemPriceChangesEnabledStateChangeNotification(data);
          break;
        case 'MeatAndProduceStoreItemPriceChangesEnabledStateChange':
          this.createMeatAndProduceStoreItemPriceChangesEnabledStateChangeNotification(data);
          break;
        case 'ServerMaintenanceEnabledStateChange':
          this.createServerMaintenanceEnabledStateChangeNotification(data);
          break;
        case 'PreviewStoreItemPriceChanged':
          this.createItemUpdateNotification(data.user, data.previewStoreItem);
          break;
        case 'StoreItemPriceChanged':
          this.createItemUpdateNotification(data.user, data.storeItem);
          break;
        case 'StoreItemDeleted':
          this.createItemUpdateNotification(data.user, data.storeItem);
          break;
        case 'StoreItemTprTagUpdated':
          this.createStoreItemTprTagUpdatedNotification(data.user, data.storeItem);
          break;
        case 'StoreItemShelfTagUpdated':
          this.createStoreItemShelfTagUpdatedNotification(data.user, data.storeItem);
          break;
        case 'UserExitedStore':
          this.createUserExitedStoreNotification(data.userInfo, data.store);
          break;
        case 'UserWorkingInStore':
          //don't write the last working in store message because it is a duplicate of the 
          //myUser working in store that will be written above the older messages line (see the end of this function)
          if (i != d.messages.length - 1) this.createUserWorkingInStoreNotification(data);
          break;
        case 'SystemNotification':
          this.createSystemNotification(data);
          break;
        case 'UserNotification':
          this.createUserNotification(data);
          break;
        case 'ErrorNotification':
          var obj = {
            reason: data,
            date: d.messages[i].sent,
          };
          //Probably shouldn't write errors to chat
          //writeSystemMessageToChatWindow(obj);
          break;
        default:
          this.createSystemMessageNotification(data);
          break;
      }
    }

    var notificationHtml = '<p class="message-timeframe-description"><i class="fa fa-arrow-up" aria-hidden="true"></i> New Messages <i class="fa fa-arrow-up" aria-hidden="true"></i></p><hr><p class="message-timeframe-description"><i class="fa fa-arrow-down" aria-hidden="true"></i> Message History <i class="fa fa-arrow-down" aria-hidden="true"></i></p>';

    this._notificationHtmlSubject.next(notificationHtml);

    if (!this.isEmpty(myUserWorkingInStore)) this.createUserWorkingInStoreNotification(myUserWorkingInStore);
  }



}
