import { Component, ViewChild, ElementRef } from '@angular/core';
import { MessageService } from 'src/app/shared/message.service';

@Component({
  selector: 'rpms-custom-header',
  template: `
    <div *ngIf="params.enableMenu" #menuButton class="customHeaderMenuButton help-trigger" (touchstart)="onMenuClicked($event)" (click)="onMenuClicked($event)" (mouseenter)="showHelpfulTip($event)" (mouseleave)="hideHelpfulTip($event)" data-help="column-menu"><i class="fa fa-bars"></i></div>
    <div class="customHeaderLabel help-trigger" (touchstart)="onSortRequested($event)" (click)="onSortRequested($event)" (mouseenter)="showHelpfulTip($event)" (mouseleave)="hideHelpfulTip($event)" [attr.data-help]="this.params.column.colId + '-column'"><span class="sortIcon" [innerHTML]="this.sortIconHtml | sanitizeHtml"></span>{{this.params.displayName}}</div>
    <div class="master-input" *ngIf="params.column.colId == 'masterChangeCheckbox'">
      <input id="checkbox-master-input" type="checkbox" (change)="onMasterCheckboxChange($event)"/>
    </div>
    <div class="master-input" *ngIf="params.column.colId == 'book'">
      <select id="book-master-input" (change)="onInputChange('book', $event)">
        <option value=""></option>
        <option>1</option>
        <option>2</option>
        <option>3</option>
        <option>4</option>
        <option>5</option>
        <option>6</option>
        <option>7</option>
        <option>C</option>
        <option>D</option>
      </select>
    </div>
    <div class="master-input" *ngIf="params.column.colId == 'srpCode'">
      <select id="srpCode-master-input" (change)="onInputChange('srpCode', $event)">
      <option value=""></option>
      <option>A</option>
      <option>B</option>
      <option>C</option>
      <option>H</option>
      <option>1</option>
      <option>2</option>
      <option>3</option>
      <option>4</option>
      <option>5</option>
      <option>6</option>
      <option>7</option>
      <option>8</option>
      <option>9</option>
      </select>
    </div>
    <div class="master-input" *ngIf="params.column.colId == 'multi'">
      <input id="multi-master-input" type="number" step="1" min="0" max="99" (input)="onInputChange('multi', $event)" (mousedown)="onInputMouseDown($event)"/>
    </div>
    <div class="master-input" *ngIf="params.column.colId == 'price'">
      <input id="price-master-input" type="number" step="0.01" min="0" max="999.99" (input)="onInputChange('price', $event)" (mousedown)="onInputMouseDown($event)" (blur)="onPriceBlur()" (focus)="onPriceFocus()"/>
    </div>
    <div class="master-input" *ngIf="params.column.colId == 'keepSrp'">
      <select id="keepSrp-master-input" (change)="onInputChange('keepSrp', $event)">
        <option value=""></option>
        <option value=true>True</option>
        <option value=false>False</option>
      </select>
    </div>
    <div class="master-input" *ngIf="params.column.colId == 'percent'">
      <input id="percent-master-input" type="number" step="1" min="-99" max="99" (input)="onInputChange('percent', $event)" (mousedown)="onInputMouseDown($event)"/>
    </div>
    <div class="master-input" *ngIf="params.column.colId == 'keepPercent'">
      <select id="keepPercent-master-input" (change)="onInputChange('keepPercent', $event)">
        <option value=""></option>
        <option value=true>True</option>
        <option value=false>False</option>
      </select>
    </div>
  `,
})
export class CustomHeaderComponent {
  @ViewChild('menuButton', { read: ElementRef }) public menuButton;
  
  params: any;
  sortIconHtml;
  pmComponent;

  constructor(
    private _messageService: MessageService,
  ){}

  //this component gets called for each column so component-wide variables don't work
  agInit(params): void {
    this.params = params;
    //this.changeType = '';
    //console.log('header params: ');
    //console.log(params);
    params.column.addEventListener('sortChanged', this.onSortChanged.bind(this));
    this.onSortChanged();
  }

  ngAfterViewInit(){
    this.pmComponent = this.params.context.componentParent;
    if (this.params.context.componentParent.masterChangeModel.hasOwnProperty('values')){
      let values = this.params.context.componentParent.masterChangeModel.values;
      let bookInput = <HTMLInputElement>document.getElementById('book-master-input');
      let srpCodeInput = <HTMLInputElement>document.getElementById('srpCode-master-input');
      let multiInput = <HTMLInputElement>document.getElementById('multi-master-input');
      let priceInput = <HTMLInputElement>document.getElementById('price-master-input');
      let keepSrpInput = <HTMLInputElement>document.getElementById('keepSrp-master-input');
      let percentInput = <HTMLInputElement>document.getElementById('percent-master-input');
      let keepPercentInput = <HTMLInputElement>document.getElementById('keepPercent-master-input');
      if (bookInput) bookInput.value = values.book;
      if (srpCodeInput) srpCodeInput.value = values.srpCode;
      if (multiInput) multiInput.value = values.multi;
      if (priceInput) priceInput.value = values.price;
      if (keepSrpInput) keepSrpInput.value = values.keepSrp;
      if (percentInput) percentInput.value = values.percent;
      if (keepPercentInput) keepPercentInput.value = values.keepPercent;
      if (this.params.context.componentParent.masterChangeModel.hasOwnProperty('changeType')){
        let changeType = this.params.context.componentParent.masterChangeModel.changeType;
        this.handleAppearanceOfChangeType(changeType);
      }
    }
  }

  onMenuClicked() {
    this.params.showColumnMenu(this.menuButton.nativeElement);
  }

  onSortChanged() {
    if (this.params.column.isSortAscending()) {
      this.sortIconHtml = '<i class="fa fa-arrow-up"></i>&nbsp;';
    } 
    else if (this.params.column.isSortDescending()) {
      this.sortIconHtml = '<i class="fa fa-arrow-down"></i>&nbsp;';
    } 
    else {
      this.sortIconHtml = '';
    }
  }

  onSortRequested(event) {
    this.params.progressSort(event.shiftKey);
  }

  onMasterCheckboxChange(event){
    var rowNodes = [];
    this.params.api.forEachNodeAfterFilterAndSort(function (rowNode) {
      rowNode.data.masterChangeCheckbox = event.target.checked;
      rowNodes.push(rowNode);//creating an array of nodes that need to be refreshed
    });
    this.params.api.refreshCells({ rowNodes: rowNodes, force: true });
  }

  onInputMouseDown(event){
    event.stopPropagation();
  }

  onPriceFocus(){
    let multiInput = <HTMLInputElement>document.getElementById('multi-master-input');
    if (multiInput && multiInput.value == '') multiInput.value = this.getMultiValueBasedOnMasterInputSelectedRows();
  }

  onPriceBlur(){
    let priceInput = <HTMLInputElement>document.getElementById('price-master-input');
    if (priceInput) priceInput.value = (Number(priceInput.value)).toFixed(2);
  }

  onInputChange(field, event){
    //console.log(field);
    //console.log(event.target.value);
    let changeType;
    let bookInput = <HTMLInputElement>document.getElementById('book-master-input');
    let srpCodeInput = <HTMLInputElement>document.getElementById('srpCode-master-input');
    let multiInput = <HTMLInputElement>document.getElementById('multi-master-input');
    let priceInput = <HTMLInputElement>document.getElementById('price-master-input');
    let keepSrpInput = <HTMLInputElement>document.getElementById('keepSrp-master-input');
    let percentInput = <HTMLInputElement>document.getElementById('percent-master-input');
    let keepPercentInput = <HTMLInputElement>document.getElementById('keepPercent-master-input');
    switch(field){
      case 'book':
        if (event.target.value === 'C'){
          changeType = 'price';
          if (srpCodeInput) srpCodeInput.value = '';
        }
        else{
          changeType = 'book';
          if (multiInput) multiInput.value = '';
          if (priceInput) priceInput.value = '';
          if (keepSrpInput) keepSrpInput.value = '';
        }
        if (percentInput) percentInput.value = '';
        if (keepPercentInput) keepPercentInput.value = '';
        break;
      case 'srpCode':
        changeType = 'book';
        if (multiInput) multiInput.value = '';
        if (priceInput) priceInput.value = '';
        if (keepSrpInput) keepSrpInput.value = '';
        if (percentInput) percentInput.value = '';
        if (keepPercentInput) keepPercentInput.value = '';
      break;
      case 'multi':
        changeType = 'price';
        if (bookInput) bookInput.value = '';
        if (srpCodeInput) srpCodeInput.value = '';
        if (percentInput) percentInput.value = '';
        break;
      case 'price':
        changeType = 'price';
        if (bookInput) bookInput.value = '';
        if (srpCodeInput) srpCodeInput.value = '';
        if (percentInput) percentInput.value = '';
        break;
      case 'keepSrp':
        if (bookInput && bookInput.value == '' &&
            srpCodeInput && srpCodeInput.value == '' &&
            multiInput && multiInput.value == '' &&
            priceInput && priceInput.value == '' &&
            percentInput && percentInput.value == '') {
          changeType = 'keepOnly';
        }
        else if ((priceInput && priceInput.value != '') || (multiInput && multiInput.value != '')) changeType = 'price';
        else if ((percentInput && percentInput.value != '')) changeType = 'percent';
        if (keepPercentInput) keepPercentInput.value = '';
        if (bookInput) bookInput.value = '';
        if (srpCodeInput) srpCodeInput.value = '';
        break;
      case 'percent':
        changeType = 'percent';
        if (bookInput) bookInput.value = '';
        if (srpCodeInput) srpCodeInput.value = '';
        if (multiInput) multiInput.value = '';
        if (priceInput) priceInput.value = '';
        break;
      case 'keepPercent':
        if (bookInput && bookInput.value == '' &&
            srpCodeInput && srpCodeInput.value == '' &&
            multiInput && multiInput.value == '' &&
            priceInput && priceInput.value == '' &&
            percentInput && percentInput.value == '') {
          changeType = 'keepOnly';
        }
        else if ((priceInput && priceInput.value != '') || (multiInput && multiInput.value != '')) changeType = 'price';
        else if ((percentInput && percentInput.value != '')) changeType = 'percent';
        if (keepSrpInput) keepSrpInput.value = '';
        if (bookInput) bookInput.value = '';
        if (srpCodeInput) srpCodeInput.value = '';
        break;
    }
    //need to check if inputs exists because the grid doesn't render them if they are off-screen
    let masterChangeModel = {
      changeType: changeType,
      values: {
        book: bookInput ? bookInput.value : '',
        srpCode: srpCodeInput ? srpCodeInput.value : '',
        multi: multiInput ? multiInput.value : '',
        price: priceInput ? priceInput.value : '',
        keepSrp: keepSrpInput ? keepSrpInput.value != '' ? keepSrpInput.value : null : null,
        percent: percentInput ? percentInput.value : '',
        keepPercent: keepPercentInput ? keepPercentInput.value != '' ? keepPercentInput.value : null : null,
      }
    }
    this.handleAppearanceOfChangeType(changeType)
    this.params.context.componentParent.setMasterChangeModel(masterChangeModel);
  }

  handleAppearanceOfChangeType(changeType){
    let highlightColor = "#ffff00";
    let bgColor = "#f1ffd8";
    let bookInput = <HTMLInputElement>document.getElementById('book-master-input');
    let srpCodeInput = <HTMLInputElement>document.getElementById('srpCode-master-input');
    let multiInput = <HTMLInputElement>document.getElementById('multi-master-input');
    let priceInput = <HTMLInputElement>document.getElementById('price-master-input');
    let keepSrpInput = <HTMLInputElement>document.getElementById('keepSrp-master-input');
    let percentInput = <HTMLInputElement>document.getElementById('percent-master-input');
    let keepPercentInput = <HTMLInputElement>document.getElementById('keepPercent-master-input');
    if (bookInput) bookInput.style.backgroundColor = bgColor;
    if (srpCodeInput) srpCodeInput.style.backgroundColor = bgColor;
    if (multiInput) multiInput.style.backgroundColor = bgColor;
    if (priceInput) priceInput.style.backgroundColor = bgColor;
    if (keepSrpInput) keepSrpInput.style.backgroundColor = bgColor;
    if (percentInput) percentInput.style.backgroundColor = bgColor;
    if (keepPercentInput) keepPercentInput.style.backgroundColor = bgColor;
    switch (changeType){
      case 'book':
        if (bookInput) bookInput.style.backgroundColor = highlightColor;
        if (srpCodeInput) srpCodeInput.style.backgroundColor = highlightColor;
        break;
      case 'price':
        if (multiInput) multiInput.style.backgroundColor = highlightColor;
        if (priceInput) priceInput.style.backgroundColor = highlightColor;
        break;
      case 'percent':
        if (percentInput) percentInput.style.backgroundColor = highlightColor;
        break;
      default:
        break;
    }
    
  }

  //GAIR-115 block
  getMultiValueBasedOnMasterInputSelectedRows(){
    let selectedRowsData = [];
    this.pmComponent.agGrid.api.forEachNodeAfterFilterAndSort(function (rowNode) {
      if (rowNode.data.masterChangeCheckbox) { //if row is checked
        selectedRowsData.push(rowNode.data);
      }
    });

    if (selectedRowsData.length > 0) {

      let isAllEqual = selectedRowsData.every((obj, i, arr) => {
          return i === 0 || (obj.multi === arr[i - 1].multi);
      });

      if(!isAllEqual) { //selected rows do NOT have matching Multis

        // Create a frequency map
        let frequencyMap = {};
        selectedRowsData.forEach((obj) => {
            if(frequencyMap[obj.multi]) {
                frequencyMap[obj.multi]++;
            } else {
                frequencyMap[obj.multi] = 1;
            }
        });

        // Find the multi value that occurs the most
        let mostFrequentValue = Object.keys(frequencyMap).reduce((a, b) => frequencyMap[a] > frequencyMap[b] ? a : b);
        this._messageService.alert(`The Multi values in your selected rows do not match.<br><br>
          Based on the selected rows, the value "${mostFrequentValue}" was automatically entered for the Master Inputs Multi field.<br><br>
          Please make sure this is what you want before applying the change.`);
        return mostFrequentValue;
      }
      else { //selected rows had matching Multis
        return selectedRowsData[0].multi;
      }
    }
    else { //no rows were selected when Price field was focused
      return 1;
    }
  }
  //end GAIR-115 block

  showHelpfulTip(event){
    //console.log(event)
    this.params.context.componentParent.showHelpfulTip(event);
  }

  hideHelpfulTip(event){
    //console.log(event)
    this.params.context.componentParent.hideHelpfulTip(event);
  }

}

