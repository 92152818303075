import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { AppConfig } from '../app.config';

@Injectable({
  providedIn: 'root'
})
export class AccessService {

  private _baseUrl = AppConfig.settings.baseUrl;
  private _reqHeader = new HttpHeaders({ 'Accept': 'application/json', 'Content-Type': 'application/json' });

  constructor ( 
    private _http: HttpClient, 
  ){}

  arePreviewStoreItemPriceChangesEnabledForStore(storeId) {
    return this._http.get(this._baseUrl + '/api/Access/ArePreviewStoreItemPriceChangesEnabledForStore/' + storeId, { headers: this._reqHeader });
  }

  areMeatAndProducePreviewStoreItemPriceChangesEnabledForStore(storeId) {
    return this._http.get(this._baseUrl + '/api/Access/AreMeatAndProducePreviewStoreItemPriceChangesEnabledForStore/' + storeId, { headers: this._reqHeader });
  }

  areStoreItemPriceChangesEnabledForStore(storeId) {
    return this._http.get(this._baseUrl + '/api/Access/AreStoreItemPriceChangesEnabledForStore/' + storeId, { headers: this._reqHeader });
  }

  areMeatAndProduceStoreItemPriceChangesEnabledForStore(storeId) {
    return this._http.get(this._baseUrl + '/api/Access/AreMeatAndProduceStoreItemPriceChangesEnabledForStore/' + storeId, { headers: this._reqHeader });
  }

  isAdministrationUsersAddUserEnabled() {
    return this._http.get(this._baseUrl + '/api/Access/IsAdministrationUsersAddUserEnabled', { headers: this._reqHeader });
  }

  isAdministrationUsersAddUserStoreGroupSelectionEnabled() {
    return this._http.get(this._baseUrl + '/api/Access/IsAdministrationUsersAddUserStoreGroupSelectionEnabled', { headers: this._reqHeader });
  }

  isAdministrationUsersAddUserStoreSelectionEnabled() {
    return this._http.get(this._baseUrl + '/api/Access/IsAdministrationUsersAddUserStoreSelectionEnabled', { headers: this._reqHeader });
  }

  isItemFilterModifiable(itemFilterId) {
    return this._http.get(this._baseUrl + '/api/Access/IsItemFilterModifiable/' + itemFilterId, { headers: this._reqHeader });
  }

  isItemListModifiable(itemListId) {
    return this._http.get(this._baseUrl + '/api/Access/IsItemListModifiable/' + itemListId, { headers: this._reqHeader });
  }

  canItemFilterSharedWithAndOrModifiedByPropertiesBeChanged(itemFilterId) {
    return this._http.get(this._baseUrl + '/api/Access/CanItemFilterSharedWithAndOrModifiedByPropertiesBeChanged/' + itemFilterId, { headers: this._reqHeader });
  }

  canItemListSharedWithAndOrModifiedByPropertiesBeChanged(itemListId) {
    return this._http.get(this._baseUrl + '/api/Access/CanItemListSharedWithAndOrModifiedByPropertiesBeChanged/' + itemListId, { headers: this._reqHeader });
  }

  canStoreGroupBeDeleted(storeGroupId) {
    return this._http.get(this._baseUrl + '/api/Access/CanStoreGroupBeDeleted/' + storeGroupId, { headers: this._reqHeader });
  }

  isForcedDeletionRequiredForStore(storeId) {
    return this._http.get(this._baseUrl + '/api/Access/IsForcedDeletionRequiredForStore/' + storeId, { headers: this._reqHeader });
  }

  isForcedDeletionRequiredForStoreGroup(storeGroupId) {
    return this._http.get(this._baseUrl + '/api/Access/IsForcedDeletionRequiredForStoreGroup/' + storeGroupId, { headers: this._reqHeader });
  }

  isForcedDeletionRequiredForUser(userId) {
    return this._http.get(this._baseUrl + '/api/Access/IsForcedDeletionRequiredForUser/' + userId, { headers: this._reqHeader });
  }
  
}
