import { Component, OnInit } from '@angular/core';
//import { AppConfig } from 'src/app/app.config';
import { PermissionsService } from 'src/app/shared/permissions.service';
//import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css']
})
export class AdminComponent implements OnInit {
  //private config = AppConfig.settings;

  permissions: any = {};

  constructor(
    private _permissionsService: PermissionsService
  ) { }

  ngOnInit() {
    //if (this.config.logInits) console.log('admin init');
    this.permissions = this._permissionsService.getPermissions();
  }

}
