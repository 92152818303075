import { Injectable, SecurityContext } from '@angular/core';
import { MessageDialogComponent } from './message-dialog/message-dialog.component';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class MessageService {

  messageDialogRef: MatDialogRef<MessageDialogComponent>;
  htmlContent: string;

  constructor(              
    private _dialog: MatDialog, 
    private _domSanitizer: DomSanitizer,
    ) { }

  alert(msg, title?){
    if (title == null) title = 'Attention';
    msg = '<p class="dialog-message">'+msg+'</p>';
    this.messageDialogRef = this._dialog.open(MessageDialogComponent, {
      width: 'auto',
      data: {
        //https://stackoverflow.com/questions/45020835/how-do-i-pass-html-to-a-template-dialog-component-using-material-with-angular-2
        //message: this._domSanitizer.bypassSecurityTrustHtml(msg), 
        message: this._domSanitizer.sanitize(SecurityContext.HTML, msg),
        title: title
      }
    });
  }

  showAlertThenPeformActionOnClose(msg, callback, title?) { 
    if (title == null) title = 'Attention';
    msg = '<p class="dialog-message">'+msg+'</p>';
    this.messageDialogRef = this._dialog.open(MessageDialogComponent, {
      disableClose: false,
      data: {
        title: title,
        message: this._domSanitizer.sanitize(SecurityContext.HTML, msg),
      }
    });
    this.messageDialogRef.afterClosed().subscribe(result => {
      callback();
      this.messageDialogRef = null;
    });
  }

  alertReadOnlyRestriction(){
    let title = 'Attention';
    let msg = `
    <p class="dialog-message">
      You are restricted to Read Only actions. Contact your administrator for more details.
    </p>`;
    this.messageDialogRef = this._dialog.open(MessageDialogComponent, {
      width: 'auto',
      data: {
        //https://stackoverflow.com/questions/45020835/how-do-i-pass-html-to-a-template-dialog-component-using-material-with-angular-2
        //message: this._domSanitizer.bypassSecurityTrustHtml(msg), 
        message: this._domSanitizer.sanitize(SecurityContext.HTML, msg),
        title: title
      }
    });
  }

  closeAll() {
    this._dialog.closeAll();
  }

  lockoutAlert(msg, title?){
    if (title == null) title = 'Attention';
    msg = '<p class="dialog-message">'+msg+'</p>';
    this.messageDialogRef = this._dialog.open(MessageDialogComponent, {
      width: 'auto',
      data: {
        //https://stackoverflow.com/questions/45020835/how-do-i-pass-html-to-a-template-dialog-component-using-material-with-angular-2
        //message: this._domSanitizer.bypassSecurityTrustHtml(msg), 
        message: this._domSanitizer.sanitize(SecurityContext.HTML, msg),
        title: title,
        preventClosing: true,
      },
      hasBackdrop: true,
      disableClose: true,
    });
  }

  confirm(msg, title, proceedText, cancelText){
    if (title == null) title = 'Attention';
    msg = '<p class="dialog-message">'+msg+'</p>';
    this.messageDialogRef = this._dialog.open(MessageDialogComponent, {
      width: 'auto',
      data: {
        message: this._domSanitizer.sanitize(SecurityContext.HTML, msg),
        title: title
      }
    });
  }

  private guidGenerator() {
    var S4 = function() {
       return (((1+Math.random())*0x10000)|0).toString(16).substring(1);
    };
    return ("TST"+S4()+S4()+"-"+S4()+"-"+S4()+"-"+S4()+"-"+S4()+S4()+S4());
  }

  onSuccess(msg){
    var toastsBottomLeft = document.getElementById('toasts-bottom-left');
    var id = this.guidGenerator();
    toastsBottomLeft.insertAdjacentHTML('afterbegin', '<div id="' + id + '"><span class="success">' + msg + '</span></div>');
    var el = document.getElementById(id);
    setTimeout(function() { toastsBottomLeft.removeChild(el); }, 7000);
  }

  showToastTopRight(msg) {
    var toastsTopRight = document.getElementById('toasts-top-right');
    var id = this.guidGenerator();
    toastsTopRight.insertAdjacentHTML('afterbegin', '<div id="' + id + '"><span class="">' + msg + '</span></div>');
    var el = document.getElementById(id);
    setTimeout(function() { toastsTopRight.removeChild(el); }, 7000);
  }

  showErrorToastTopRight(msg) {
    var toastsTopRight = document.getElementById('toasts-top-right');
    var id = this.guidGenerator();
    toastsTopRight.insertAdjacentHTML('afterbegin', '<div id="' + id + '"><span class="error">' + msg + '</span></div>');
    var el = document.getElementById(id);
    setTimeout(function() { toastsTopRight.removeChild(el); }, 7000);
  }

  showToastBottomLeft(msg) {
    var toastsBottomLeft = document.getElementById('toasts-bottom-left');
    var id = this.guidGenerator();
    toastsBottomLeft.insertAdjacentHTML('afterbegin', '<div id="' + id + '"><span class="">' + msg + '</span></div>');
    var el = document.getElementById(id);
    setTimeout(function() { toastsBottomLeft.removeChild(el); }, 7000);
  }

  onFailure(msg, response?, title?){
    if (!title) title = 'Attention';
    var htmlContent = '<p class="dialog-message">' + msg + '</p>';
    if (response){
      if (response.error){
        if (response.error.error_description) htmlContent += '<p class="friendly-error">' + response.error.error_description + '</p>';
        if (response.error.message) htmlContent += '<p class="friendly-error">' + response.error.message + '</p>';
        if (response.error.modelState) {
          if (response.error.modelState.hasOwnProperty('')){
            htmlContent += '<p class="friendly-error">' + response.error.modelState[''].toString() + '</p>';
          }
        }
      }
      if (response.message) htmlContent += '<p class="dialog-error">' + response.message + '</p>';
    }
    this.messageDialogRef = this._dialog.open(MessageDialogComponent, {
      data: {
        message: this._domSanitizer.sanitize(SecurityContext.HTML, htmlContent),
        title: title
      }
    });
  }

  // onFailure(msg, response?, title?){
  //   if (!title) title = 'Attention';
  //   var htmlContent = '<p class="dialog-message">' + msg + '</p>';
  //   if (response){
  //     if (response.error){
  //       if (response.error.error_description) htmlContent += '<p class="friendly-error">' + response.error.error_description + '</p>';
  //     }
  //     if (response.message) htmlContent += '<p class="dialog-error">' + response.message + '</p>';
  //   }
  //   this.messageDialogRef = this._dialog.open(MessageDialogComponent, {
  //     //width: '250px',
  //     data: {
  //       //https://stackoverflow.com/questions/45020835/how-do-i-pass-html-to-a-template-dialog-component-using-material-with-angular-2
  //       //message: this._domSanitizer.bypassSecurityTrustHtml(msg), 
  //       message: this._domSanitizer.sanitize(SecurityContext.HTML, htmlContent),
  //       title: title
  //     }
  //   });
  // }

}
