
<div ng-if="ac.roleAllowsUserToViewAdminPricingRules" id="pricing-rule-page">
	<div class="breadcrumbs">
		<span><a (click)="cancel()">Pricing Rules</a></span>
		<span>Pricing Rule</span>
	</div>
	<h1><i class="fa fa-usd-circle"></i>{{mode}} Pricing Rule</h1>
	<form id="pricing-rule-form" #pricingRuleForm="ngForm">
    <div class="checkbox-form-field">
      <mat-checkbox name="active" #activeCheckbox="ngModel" [(ngModel)]="pricingRule.active" (change)="onActiveCheckboxChange()" [disabled]="!permissions.roleAllowsUserToEditPricingRule || myUser.hasReadOnlyRestriction" >Active</mat-checkbox>
		</div>
		<div class="row">
      <mat-form-field>
        <mat-label>Rule Name</mat-label>
        <input matInput name="name" #nameInput="ngModel" [(ngModel)]="pricingRule.name" placeholder="Rule Name" (change)="onAnyChange()" [disabled]="!permissions.roleAllowsUserToEditPricingRule || myUser.hasReadOnlyRestriction" required maxlength="30">
        <mat-error *ngIf="nameInput.errors?.required">Rule Name is required</mat-error>
				<mat-hint align="end">{{pricingRule.name?.length}} / 30</mat-hint>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Rule Type</mat-label>
        <mat-select name="ruleType" #ruleTypeSelect="ngModel" [(ngModel)]="pricingRule.ruleType" placeholder="Rule Type" (selectionChange)="onRuleTypeChanged()" [disabled]="!permissions.roleAllowsUserToEditPricingRule || myUser.hasReadOnlyRestriction" required >
          <mat-option value="Store">Store</mat-option>
          <mat-option value="StoreGroup">Store Group</mat-option>
        </mat-select>
        <mat-error *ngIf="ruleTypeSelect.errors?.required">Rule Type is required</mat-error>
      </mat-form-field>
      <mat-form-field [hidden]="!isStoreSelectionVisible">
        <mat-label>Store</mat-label>
        <mat-select name="store" #storeSelect="ngModel" [(ngModel)]="pricingRule.storeId" placeholder="Store" (selectionChange)="onStoreChanged()" [disabled]="!permissions.roleAllowsUserToEditPricingRule || myUser.hasReadOnlyRestriction" [required]="pricingRule.ruleType === 'Store'" >
          <mat-option *ngFor="let store of stores" [value]="store.storeId">
            {{store.numberAndLocation}} <span *ngIf="!store.active" class="not-active">(not active)</span>
          </mat-option>
        </mat-select>
        <mat-error *ngIf="storeSelect.errors?.required">Store is required</mat-error>
      </mat-form-field>
      <mat-form-field [hidden]="!isStoreGroupSelectionVisible">
        <mat-label>Store Group</mat-label>
        <mat-select name="storeGroup" #storeGroupSelect="ngModel" [(ngModel)]="pricingRule.storeGroupId" placeholder="Store Group" (selectionChange)="onStoreGroupChanged()" [disabled]="!permissions.roleAllowsUserToEditPricingRule || myUser.hasReadOnlyRestriction" [required]="pricingRule.ruleType === 'StoreGroup'" >
          <mat-option *ngFor="let storeGroup of storeGroups" [value]="storeGroup.storeGroupId">
            {{storeGroup.name}}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="storeGroupSelect.errors?.required">Store Group is required</mat-error>
      </mat-form-field>
    </div>
		<div class="row">
      <mat-form-field>
        <mat-label>Description</mat-label>
        <input matInput name="description" #descriptionInput="ngModel" [(ngModel)]="pricingRule.description" placeholder="Description" (change)="onAnyChange()" [disabled]="!permissions.roleAllowsUserToEditPricingRule || myUser.hasReadOnlyRestriction">
      </mat-form-field>
		</div>

		<section>
			<h3>Match Criteria:</h3>
			<div *ngFor="let mc of pricingRule.matchCriteria; let i = index" class="row" style="white-space:nowrap">
        <mat-form-field>
          <mat-label>Type</mat-label>
          <mat-select name="mc.type{{i}}" #typeSelect="ngModel" [(ngModel)]="mc.type" placeholder="Type" (selectionChange)="onCriteriaTypeChanged(i, mc.type)" [disabled]="!permissions.roleAllowsUserToEditPricingRule || myUser.hasReadOnlyRestriction" required >
            <mat-option *ngFor="let type of types" [value]="type.criterionType">
              {{type.description}}
            </mat-option>
          </mat-select>
        </mat-form-field>	
        <mat-form-field>
          <mat-label>Matching Strategy</mat-label>
          <mat-select name="mc.matchingStrategy{{i}}" #matchingStrategySelect="ngModel" [(ngModel)]="mc.matchingStrategy" placeholder="Matching Strategy" (selectionChange)="onMatchingStrategyChanged(i)" [disabled]="!permissions.roleAllowsUserToEditPricingRule || myUser.hasReadOnlyRestriction" required >
            <mat-option *ngFor="let ms of mc.supportedStrategyTypes" [value]="ms.strategyType">
              {{ms.description}}
            </mat-option>
          </mat-select>
        </mat-form-field>	
        <mat-form-field *ngIf="!mc.validMatchingValues" [hidden]="mc.matchingStrategy === 'HasValue' || mc.matchingStrategy === 'NotHasValue'">
          <mat-label>{{mc.matchingValuePlaceholder ? mc.matchingValuePlaceholder : 'Value'}}</mat-label>
          <input matInput name="mc.matchingValue{{i}}" #matchingValueInput="ngModel" [(ngModel)]="mc.matchingValue" placeholder="{{mc.matchingValuePlaceholder ? mc.matchingValuePlaceholder : 'Value'}}" (change)="onValueChanged()" [disabled]="!permissions.roleAllowsUserToEditPricingRule || myUser.hasReadOnlyRestriction || mc.matchingStrategy =='HasValue' || mc.matchingStrategy =='NotHasValue'" [required]="!mc.validMatchingValues && mc.matchingStrategy !='HasValue' && mc.matchingStrategy !='NotHasValue'">
        </mat-form-field>
        <mat-form-field *ngIf="mc.validMatchingValues && mc.type !== 'PrivateLabel'" [hidden]="mc.matchingStrategy === 'HasValue' || mc.matchingStrategy === 'NotHasValue'">
          <mat-label>Value</mat-label>
          <mat-select name="mc.matchingValueSelect1{{i}}" #matchingValueSelect1="ngModel" [(ngModel)]="mc.matchingValueSelect" placeholder="Value" (selectionChange)="onValueChanged()" [disabled]="!permissions.roleAllowsUserToEditPricingRule || myUser.hasReadOnlyRestriction || mc.matchingStrategy =='HasValue' || mc.matchingStrategy =='NotHasValue'" [required]="mc.validMatchingValues && mc.matchingStrategy !='HasValue' && mc.matchingStrategy !='NotHasValue'" >
            <mat-option *ngFor="let mv of mc.validMatchingValues" [value]="mv.value">
              {{mv.label}}
            </mat-option>
          </mat-select>
        </mat-form-field>	
        <mat-form-field *ngIf="mc.validMatchingValues && mc.type === 'PrivateLabel'" [hidden]="mc.matchingStrategy === 'HasValue' || mc.matchingStrategy === 'NotHasValue'">
          <mat-label>Value</mat-label>
          <mat-select multiple name="mc.matchingValueSelect2{{i}}" #matchingValueSelect2="ngModel" [(ngModel)]="mc.matchingValueMultipleSelect" placeholder="Value" (selectionChange)="onValueChanged()" [disabled]="!permissions.roleAllowsUserToEditPricingRule || myUser.hasReadOnlyRestriction || mc.matchingStrategy =='HasValue' || mc.matchingStrategy =='NotHasValue'" [required]="mc.validMatchingValues && mc.matchingStrategy !='HasValue' && mc.matchingStrategy !='NotHasValue'" >
            <mat-option *ngFor="let mv of mc.validMatchingValues" [value]="mv.value">
              {{mv.label}}
            </mat-option>
          </mat-select>
        </mat-form-field>	
        <button mat-raised-button *ngIf="pricingRule.matchCriteria.length > 1" (click)="removeCriteriaEntry(i)" [disabled]="!permissions.roleAllowsUserToEditPricingRule || myUser.hasReadOnlyRestriction">Remove</button>
			</div>
      <button mat-raised-button (click)="addCriteriaEntry($event)" [disabled]="!permissions.roleAllowsUserToEditPricingRule || myUser.hasReadOnlyRestriction" style="margin-bottom: 20px">Add Another</button>
			<hr class="mt-m" style="border: #ccc dotted 1px;" />
      <div class="checkbox-form-field">
        <mat-checkbox name="setPricing" #setPricingCheckbox="ngModel" [(ngModel)]="pricingRule.setPricing" (change)="onSetPricingChange()" [disabled]="!permissions.roleAllowsUserToEditPricingRule || myUser.hasReadOnlyRestriction">Set Pricing on Matched Items</mat-checkbox>
      </div>

			<p class="field-note">Unchecking the "Set Pricing on Matched Items" checkbox above will still use the match criteria above to match items, but pricing will not be set on those items.</p>
			<p class="field-note">This can be used to exclude items that match the criteria above from any price changes that would be applied from lower priority pricing rules.</p> 
    </section>
    
		<section *ngIf="pricingRule.setPricing">
			<h3>Pricing should be set to:</h3>
			<table id="set-pricing">
				<thead>
					<tr>
						<th class="book-col">{{pricingRule.changeType === 'Book' ? 'Book*' : 'Book'}}</th>
						<th class="srp-col">{{pricingRule.changeType === 'Book' ? 'SRP*' : 'SRP'}}</th>
						<th class="multi-col">{{pricingRule.changeType === 'Price' ? 'Multi*' : 'Multi'}}</th>
						<th class="price-col">{{pricingRule.changeType === 'Price' ? 'Price*' : 'Price'}}</th>
						<th class="keepSrp-col">Keep Price</th>
						<th class="percent-col">{{pricingRule.changeType === 'Percent' ? '%*' : '%'}}</th>
						<th class="keepPercent-col">Keep %</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td>
							<select name="book" #bookSelect="ngModel" [(ngModel)]="pricingRule.book" [ngClass]="{'highlight': pricingRule.changeType === 'Book' }" (change)="setChangeType('Book')" [disabled]="!permissions.roleAllowsUserToEditPricingRule || myUser.hasReadOnlyRestriction" [required]="pricingRule.changeType === 'Book'">
								<option value=""></option>
								<option value="1">1</option>
								<option value="2">2</option>
								<option value="3">3</option>
								<option value="4">4</option>
								<option value="5">5</option>
								<option value="6">6</option>
								<option value="7">7</option>
								<option value="C">C</option>
								<option value="D">D</option>
							</select>
						</td>
						<td>
							<select name="srpCode" #srpCodeSelect="ngModel" [(ngModel)]="pricingRule.srpCode" [ngClass]="{'highlight': pricingRule.changeType === 'Book' }" (change)="setChangeType('Book')" [disabled]="!permissions.roleAllowsUserToEditPricingRule || myUser.hasReadOnlyRestriction" [required]="pricingRule.changeType == 'Book' && pricingRule.book != 'D'">
								<option value=""></option>
								<option value="A">A</option>
								<option value="B">B</option>
								<option value="C">C</option>
								<option value="H">H</option>
								<option value="1">1</option>
								<option value="2">2</option>
								<option value="3">3</option>
								<option value="4">4</option>
								<option value="5">5</option>
								<option value="6">6</option>
								<option value="7">7</option>
								<option value="8">8</option>
								<option value="9">9</option>
							</select>
						</td>
						<td>
							<input type="number" name="multi" #multiInput="ngModel" [(ngModel)]="pricingRule.multi" step="1" min="0" max="99" [ngClass]="{'highlight': pricingRule.changeType === 'Price' }" (change)="setChangeType('Price')" (blur)="onMultiBlur()" [disabled]="!permissions.roleAllowsUserToEditPricingRule || myUser.hasReadOnlyRestriction" [required]="pricingRule.changeType == 'Price'" />
						</td>
						<td>
							<input type="number" name="price" #priceInput="ngModel" [(ngModel)]="pricingRule.price" step=".01" min="0" max="999.99" [ngClass]="{'highlight': pricingRule.changeType === 'Price' }" (change)="setChangeType('Price')" (blur)="onPriceBlur()" [disabled]="!permissions.roleAllowsUserToEditPricingRule || myUser.hasReadOnlyRestriction" [required]="pricingRule.changeType == 'Price'" />
						</td>
						<td>
							<select name="keepSrp" #keepSrpSelect="ngModel" [(ngModel)]="pricingRule.keepSrp" [ngClass]="{'highlight': pricingRule.changeType === 'Price' }" (change)="setChangeType('Price')" [disabled]="!permissions.roleAllowsUserToEditPricingRule || myUser.hasReadOnlyRestriction">
								<option value=""></option>
								<option value="true">True</option>
								<option value="false">False</option>
							</select>
						</td>
						<td>
							<input type="number" name="percent" #percentInput="ngModel" [(ngModel)]="pricingRule.percent" step="1" min="-99" max="99" [ngClass]="{'highlight': pricingRule.changeType === 'Percent' }" (change)="setChangeType('Percent')" (blur)="onPercentBlur()" [disabled]="!permissions.roleAllowsUserToEditPricingRule || myUser.hasReadOnlyRestriction" [required]="pricingRule.changeType == 'Percent'" />
						</td>
						<td>
							<select name="keepPercent" #keepPercentSelect="ngModel" [(ngModel)]="pricingRule.keepPercent" [ngClass]="{'highlight': pricingRule.changeType === 'Percent' }" (change)="setChangeType('Percent')" [disabled]="!permissions.roleAllowsUserToEditPricingRule || myUser.hasReadOnlyRestriction">
								<option value=""></option>
								<option value="true">True</option>
								<option value="false">False</option>
							</select>
						</td>
					</tr>
				</tbody>
			</table>
      <div class="pricing-error" *ngIf="multiInput.errors?.min">Multi must be between 0 and 99</div>
      <div class="pricing-error" *ngIf="multiInput.errors?.max">Multi must be between 0 and 99</div>
      <div class="pricing-error" *ngIf="priceInput.errors?.min">Price must be between 0 and 999.99</div>
      <div class="pricing-error" *ngIf="priceInput.errors?.max">Price must be between 0 and 999.99</div>
      <div class="pricing-error" *ngIf="percentInput.errors?.min">Percent must be between -99 and 99</div>
      <div class="pricing-error" *ngIf="percentInput.errors?.max">Percent must be between -99 and 99</div>
      <div *ngIf="pricingError != ''" class="pricing-error">{{pricingError}}</div>
		</section>
    <hr style="border: #000 solid 1px; margin: 20px 0 30px;" />
    <rpms-pricing-rule-preview 
      [pricingRulePreviewModel]="pricingRulePreviewModel" 
      [pricingRulePreviewStoreId]="pricingRulePreviewStoreId"
      [pricingRulePreviewStores]="pricingRulePreviewStores"
      [isPricingRuleFormValid]="pricingRuleForm.valid"
      [areAllCriteriaFieldsValid]="areAllCriteriaFieldsValid"
      [haveCriteriaFieldsChangedSinceViewingMatchedItems]="haveCriteriaFieldsChangedSinceViewingMatchedItems" 
      (haveCriteriaFieldsChangedSinceViewingMatchedItemsEvent)="setHaveCriteriaFieldsChangedSinceViewingMatchedItems($event)"
      (numberOfMatchingItemsEvent)="setNumberOfMatchingItems($event)"
      (isGridDataLoadedEvent)="setIsGridDataLoaded($event)">
    </rpms-pricing-rule-preview>
    <hr style="border: #000 solid 1px; margin: 20px 0 30px;" />
    <p class="field-note" *ngIf="!pricingRuleForm.valid || !permissions.roleAllowsUserToEditPricingRule || !haveAnyFieldsChanged || haveCriteriaFieldsChangedSinceViewingMatchedItems || !isGridDataLoaded">The Save button will only be enabled if you have made changes, filled in all required fields (marked with *) with valid values, and are viewing the Matching Items grid.</p>
		<div class="btn-row row" style="position: relative;">
      <button mat-raised-button color="primary" (click)="onSubmitPricingRule()" [disabled]="!pricingRuleForm.valid || !permissions.roleAllowsUserToEditPricingRule || !haveAnyFieldsChanged || haveCriteriaFieldsChangedSinceViewingMatchedItems || !isGridDataLoaded">Save Pricing Rule</button>
      <button mat-raised-button (click)="cancel()">Cancel</button>
		</div>
    <div *ngIf="pricingError != ''" class="pricing-error">{{pricingError}}</div>
	</form>

</div>

