import { Component, OnInit, ViewChild, Input, SimpleChanges, ChangeDetectorRef, EventEmitter, Output } from '@angular/core';
import { AgGridAngular } from 'ag-grid-angular';
import { GridOptions } from 'ag-grid-community';
import { MatDialogRef } from '@angular/material/dialog';
//import { PricingRulePreviewDialog } from '../admin-pricing-rules-detail/admin-pricing-rules-detail.component';
import { PricingRulesService } from 'src/app/shared/pricing-rules.service';
import { GridService } from 'src/app/shared/grid.service';
import { MessageService } from 'src/app/shared/message.service';
import { PermissionsService } from 'src/app/shared/permissions.service';
import * as moment from 'moment';
import { PrivateLabelsService } from 'src/app/shared/private-labels.service';
import { AppConfig } from 'src/app/app.config';

@Component({
  selector: 'rpms-pricing-rule-preview',
  templateUrl: './pricing-rule-preview.component.html',
  styleUrls: ['./pricing-rule-preview.component.css']
})
export class PricingRulePreviewComponent implements OnInit {
  @ViewChild('agGrid') agGrid: AgGridAngular;

  @Input() pricingRulePreviewModel: any; 
  @Input() pricingRulePreviewStoreId;
  @Input() pricingRulePreviewStores;
  @Input() haveCriteriaFieldsChangedSinceViewingMatchedItems: boolean;
  @Input() isPricingRuleFormValid: boolean;
  @Input() areAllCriteriaFieldsValid: boolean;
  @Output() haveCriteriaFieldsChangedSinceViewingMatchedItemsEvent = new EventEmitter<boolean>();
  @Output() numberOfMatchingItemsEvent = new EventEmitter<boolean>();
  @Output() isGridDataLoadedEvent = new EventEmitter<boolean>();
  private config = AppConfig.settings;

  gridOptions: GridOptions;
  quickFilterText: string = '';
  context; //required by grid
  permissions: any = {};
  rowCount;
  privateLabels;
  isDataLoaded: boolean = false;

  rowData = [];
  columnDefs = [
    {
      headerName: 'Item Code',
      field: "itemCode",
      width: 90,
      cellClass: ["itemCode-col", "tac"],
      cellRenderer: this._gridService.itemCodeRenderer,
      pinned: "left",
      floatingFilterComponent: this._gridService.getCustomNumberFloatingFilter(),
      filter: this._gridService.getCustomNumberFilter(),
      filterParams: {
        defaultOption: "startsWith",
        ignoreLeadingZeros: true,
      },
      get headerTooltip() { return this.headerName; }
    },
    {
      headerName: "Dept Code",
      field: "deptCode",
      width: 100,
      cellClass: ["deptCode-col"],
      filter: "agSetColumnFilter",
      //hide: true,
      get headerTooltip() { return this.headerName; }
    },
    {
      headerName: "Is Stock",
      field: "isStock",
      width: 100,
      cellClass: ["isStock-col"],
      filter: "agSetColumnFilter",
      //hide: true,
      get headerTooltip() { return this.headerName; }
    },
    {
      headerName: "Is Preview",
      field: "isPreview",
      width: 100,
      cellClass: ["isPreview-col"],
      filter: "agSetColumnFilter",
      //hide: true,
      get headerTooltip() { return this.headerName; }
    },
    {
      headerName: "Size",
      field: "size",
      width: 100,
      cellClass: ["size-col"],
      filter: "agTextColumnFilter",
      get headerTooltip() { return this.headerName; }
    },
    {
      headerName: "Description",
      field: "description",
      width: 300,
      cellClass: ["description-col"],
      floatingFilterComponent: this._gridService.getCustomTextFloatingFilter(),
      filter: this._gridService.getCustomTextFilter(),
      filterParams: {
        defaultOption: "listOr"
      },
      get headerTooltip() { return this.headerName; }
    },
    {
      headerName: "Long Description",
      field: "longDescription",
      width: 300,
      cellClass: ["longDescription-col"],
      floatingFilterComponent: this._gridService.getCustomTextFloatingFilter(),
      filter: this._gridService.getCustomTextFilter(),
      filterParams: {
        defaultOption: "listOr"
      },
      comparator: this._gridService.caseInsensitiveTextComparator,
      get headerTooltip() { return this.headerName; }
    },
    {
      headerName: "UPC",
      field: "upcCode",
      width: 120,
      cellClass: ["upcCode-col"],
      filter: "agTextColumnFilter",
      filterParams: {
        defaultOption: "startsWith"
      },
      get headerTooltip() { return this.headerName; }
    },
    {
      headerName: "Group #",
      field: "groupNumber",
      width: 50,
      cellClass: ["groupNumber-col", "tar"],
      floatingFilterComponent: this._gridService.getCustomGroupNumberFloatingFilter(),
      filter: this._gridService.getCustomGroupNumberFilter(),
      filterParams: {
        defaultOption: "listEqualsOrRange",
        inRangeInclusive: true,
      },
      get headerTooltip() { return this.headerName; }
    },
    {
      headerName: "Group Description",
      field: "groupDescription",
      width: 250,
      cellClass: ["groupDescription-col"],
      filter: "agTextColumnFilter",
      get headerTooltip() { return this.headerName; }
    },
    {
      headerName: "Movement Group",
      width: 150,
      headerClass: 'movement-column-group',
      children: [
        {
          headerName: '',
          field: 'movementGroup',
          width: 150,
          columnGroupShow: 'closed',
          filter: false,
          suppressHeaderMenuButton: true,
        },
        {
          headerName: "LY Mvt",
          field: "lastYearsMovementForStore",
          width: 50,
          cellClass: ["lastYearsMovementForStore-col", "tar"],
          filter: "agNumberColumnFilter",
          columnGroupShow: 'open',
          get headerTooltip() { return this.headerName; }
        },
        {
          headerName: "YTD Mvt",
          field: "yearToDateMovementForStore",
          width: 50,
          cellClass: ["yearToDateMovementForStore-col", "tar"],
          filter: "agNumberColumnFilter",
          filterParams: {
            defaultOption: "equals",
            inRangeInclusive: true,
          },
          columnGroupShow: 'open',
          get headerTooltip() { return this.headerName; }
        },
        {
          headerName: "W LY Mvt",
          field: "lastYearsMovement",
          width: 70,
          cellClass: ["lastYearsMovement-col", "tar"],
          filter: "agNumberColumnFilter",
          filterParams: {
            defaultOption: "equals",
            inRangeInclusive: true,
          },
          columnGroupShow: 'open',
          get headerTooltip() { return this.headerName; }
        },
        {
          headerName: "W YTD Mvt",
          field: "yearToDateMovement",
          width: 70,
          cellClass: ["yearToDateMovement-col", "tar"],
          filter: "agNumberColumnFilter",
          filterParams: {
            defaultOption: "equals",
            inRangeInclusive: true,
          },
          columnGroupShow: 'open',
          get headerTooltip() { return this.headerName; }
        },
      ]
    },
    {
      headerName: "Classification Group",
      width: 150,
      headerClass: 'classification-column-group',
      children: [
        {
          headerName: '',
          field: 'classificationGroup',
          width: 150,
          columnGroupShow: 'closed',
          filter: false,
          suppressHeaderMenuButton: true,
        },
        {
          headerName: "Pvt Label",
          field: "privateLabel",
          width: 50,
          cellClass: ["privateLabel-col", "tac"],
          cellRenderer: this.privateLabelRenderer,
          filter: 'agSetColumnFilter',
          filterParams: {
            cellRenderer: this.privateLabelRenderer,
            newRowsAction: 'keep'
          },
          columnGroupShow: 'open',
          get headerTooltip() { return this.headerName; }
        },
        {
          headerName: "New",
          field: "newItem",
          width: 50,
          cellClass: ["newItem-col", "tac"],
          cellRenderer: this._gridService.newItemRenderer,
          filter: "agSetColumnFilter",
          filterParams: {
            values: [true, false],
            newRowsAction: 'keep'
          },
          columnGroupShow: 'open',
          get headerTooltip() { return this.headerName; }
        },
        {
          headerName: "First Time Buy",
          field: "firstTimeBuy",
          width: 50,
          cellClass: ["firstTimeBuy-col", "tac"],
          cellRenderer: this._gridService.firstTimeBuyRenderer,
          filter: "agSetColumnFilter",
          filterParams: {
            values: [true, false],
            newRowsAction: 'keep'
          },
          columnGroupShow: 'open',
          get headerTooltip() { return this.headerName; }
        },
        {
          headerName: "WIC",
          field: "wicItem",
          width: 50,
          cellClass: ["wicItem-col", "tac"],
          cellRenderer: this._gridService.wicItemRenderer,
          filter: "agSetColumnFilter",
          filterParams: {
            values: [true, false],
            newRowsAction: 'keep'
          },
          columnGroupShow: 'open',
          get headerTooltip() { return this.headerName; }
        },
        {
          headerName: "NOW",
          field: "nowItem",
          width: 50,
          cellClass: ["nowItem-col", "tac"],
          cellRenderer: this._gridService.nowItemRenderer,
          filter: "agSetColumnFilter",
          filterParams: {
            values: [true, false],
            newRowsAction: 'keep'
          },
          columnGroupShow: 'open',
          get headerTooltip() { return this.headerName; }
        },
        {
          headerName: "FTB or NOW",
          field: "firstTimeBuyOrNowItem",
          width: 50,
          cellClass: ["firstTimeBuyOrNowItem-col", "tac"],
          cellRenderer: this._gridService.checkRenderer,
          filter: "agSetColumnFilter",
          filterParams: {
            values: [true, false],
            newRowsAction: 'keep'
          },
          columnGroupShow: 'open',
          get headerTooltip() { return this.headerName; }
        },
      ]
    },
    {
      headerName: "Unit Cost Less Delivery Fee",
      field: "unitCostLessDeliveryFee",
      width: 80,
      cellClass: ["unitCostLessDeliveryFee-col", "tar"],
      cellRenderer: this._gridService.currencyRenderer,
      filter: "agNumberColumnFilter",
      filterParams: {
        defaultOption: "equals",
        inRangeInclusive: true,
      },
      get headerTooltip() { return this.headerName; }
    },
    {
      headerName: "Delivered Unit Cost",
      field: "deliveredUnitCost",
      width: 80,
      cellClass: ["deliveredUnitCost-col", "tar"],
      cellRenderer: this._gridService.currencyRenderer,
      filter: "agNumberColumnFilter",
      filterParams: {
        defaultOption: "equals",
        inRangeInclusive: true,
      },
      get headerTooltip() { return this.headerName; }
    },
    {
      headerName: "Master Change Row Selector",
      field: "masterChangeCheckbox",
      width: 50,
      cellClass: ["masterChangeCheckbox-col"],
      cellRenderer: this._gridService.checkboxRenderer,
      hide: true,
      suppressHeaderMenuButton: true,
      filter: false,
      headerComponentParams: {
        masterInputCheckBox: true,
        masterInputHtml: '<div class="master-input master-input-checkbox"><input id="master-check-master-input" type="checkbox"/></div>'
      },
      get headerTooltip() { return this.headerName; }
    },
    {
      headerName: "Book",
      field: "book",
      width: 50,
      //cellClass: function (params) { if (params.data) return params.data.edited && params.data.book != params.data.oldBook ? ["book-col", "tac", "changed"] : ["book-col", "tac"]; },
      editable: false,
      //cellRenderer: this.bookSrpCodeRenderer,
      cellEditor: this._gridService.getBookCellEditor(this),
      filter: "agSetColumnFilter",
      filterParams: {
        values: ['1', '2', '3', '4', '5', '6', '7', 'C', 'D'],
        newRowsAction: 'keep'
      },
      headerComponentParams: {
        dynamicClassType: 'book',
        masterInputHtml: '<select id="book-master-input">' + 
          '<option value=""></option>' +
          '<option>1</option>' +
          '<option>2</option>' +
          '<option>3</option>' +
          '<option>4</option>' +
          '<option>5</option>' +
          '<option>6</option>' +
          '<option>7</option>' +
          '<option>C</option>' +
          '<option>D</option>' +
          '</select>'
      },
      get headerTooltip() { return this.headerName; }
    },
    {
      headerName: "SRP Code",
      field: "srpCode",
      width: 50,
      //cellClass: function (params) { if (params.data) return params.data.edited && params.data.srpCode != params.data.oldSrp ? ["srpCode-col", "tac", "changed"] : ["srpCode-col", "tac"]; },
      editable: false,
      //cellRenderer: this.bookSrpCodeRenderer,
      cellEditor: this._gridService.getSrpCodeCellEditor(this),
      filter: "agSetColumnFilter",
      filterParams: {
        values: ['A', 'B', 'C', 'H', '1', '2', '3', '4', '5', '6', '7', '8', '9'],
        newRowsAction: 'keep'
      },
      headerComponentParams: {
        dynamicClassType: 'book',
        masterInputHtml: '<select id="srpCode-master-input">' +
          '<option value=""></option>' +
          '<option>A</option>' +
          '<option>B</option>' +
          '<option>C</option>' +
          '<option>H</option>' +
          '<option>1</option>' +
          '<option>2</option>' +
          '<option>3</option>' +
          '<option>4</option>' +
          '<option>5</option>' +
          '<option>6</option>' +
          '<option>7</option>' +
          '<option>8</option>' +
          '<option>9</option>' +
          '</select>'
      },
      get headerTooltip() { return this.headerName; }
    },
    {
      headerName: "Multi",
      field: "multi",
      width: 50,
      //cellClass: function (params) { if (params.data) return params.data.edited && params.data.multi != params.data.oldMulti ? ["n-col", "tar", "changed"] : ["n-col", "tar"]; },
      editable: false,
      //cellRenderer: this.pricePercentRenderer,
      cellEditor: this._gridService.getMultiCellEditor(this),
      filter: "agNumberColumnFilter",
      filterParams: {
        defaultOption: "equals",
        inRangeInclusive: true,
      },
      headerComponentParams: {
        dynamicClassType: 'price',
        masterInputHtml: '<input id="multi-master-input" type="text"/>'
      },
      get headerTooltip() { return this.headerName; }
    },
    {
      headerName: "Current Price",
      field: "currentPrice",
      width: 80,
      cellClass: ["tar"],
      //cellClass: function (params) { if (params.data) return params.data.edited && params.data.price != params.data.oldPrice ? ["price-col", "tar", "changed"] : ["price-col", "tar"]; },
      editable: false,
      cellRenderer: this._gridService.currencyRenderer,
      filter: "agNumberColumnFilter",
      filterParams: {
        defaultOption: "equals",
        inRangeInclusive: true,
      },
      get headerTooltip() { return this.headerName; }
    },
    {
      headerName: "Price",
      field: "price",
      width: 80,
      //cellClass: function (params) { if (params.data) return params.data.edited && params.data.price != params.data.oldPrice ? ["price-col", "tar", "changed"] : ["price-col", "tar"]; },
      editable: false,
      //cellRenderer: this.pricePercentRenderer,
      cellRenderer: this._gridService.currencyRenderer,
      //cellEditor: this._gridService.getPriceCellEditor(this),
      filter: "agNumberColumnFilter",
      filterParams: {
        defaultOption: "equals",
        inRangeInclusive: true,
      },
      headerComponentParams: {
        dynamicClassType: 'price',
        masterInputHtml: '<input id="price-master-input" type="text"/>'
      },
      get headerTooltip() { return this.headerName; }
    },
    {
      headerName: "Keep Price",
      field: "keepSrp",
      width: 100,
      //cellClass: function (params) { if (params.data) return params.data.edited && params.data.keepSrp != params.data.oldKeepSrp ? ["keepSrp-col", "tac", "changed"] : ["keepSrp-col", "tac"]; },
      //cellRenderer: this.pricePercentRenderer,
      //cellEditor: this._gridService.getKeepSrpCellEditor(this),
      filter: "agSetColumnFilter",
      filterParams: {
        values: [true, false],
        newRowsAction: 'keep'
      },
      headerComponentParams: {
        dynamicClassType: 'price',
        masterInputHtml: '<select id="keepSrp-master-input">' +
          '<option value=""></option>' +
          '<option value=true>True</option>' +
          '<option value=false>False</option>' +
          '</select>'
      },
      get headerTooltip() { return this.headerName; }
    },
    {
      headerName: "%",
      field: "percent",
      width: 50,
      //cellClass: function (params) { if (params.data) return params.data.edited && params.data.percent != params.data.oldPercent ? ["percent-col", "tar", "changed"] : ["percent-col", "tar"]; },
      editable: false,
      //cellRenderer: this.pricePercentRenderer,
      //cellEditor: this._gridService.getPercentCellEditor(this),
      filter: "agNumberColumnFilter",
      filterParams: {
        defaultOption: "equals",
        inRangeInclusive: true,
      },
      headerComponentParams: {
        dynamicClassType: 'percent',
        masterInputHtml: '<input id="percent-master-input" type="text"/>'
      },
      get headerTooltip() { return this.headerName; }
    },
    {
      headerName: "Keep Percent",
      field: "keepPercent",
      width: 100,
      //cellClass: function (params) { if (params.data) return params.data.edited && params.data.keepPercent != params.data.oldKeepPercent ? ["keepPercent-col", "tac", "changed"] : ["keepPercent-col", "tac"]; },
      //cellRenderer: this.pricePercentRenderer,
      //cellEditor: this._gridService.getKeepPercentCellEditor(this),
      filter: "agSetColumnFilter",
      filterParams: {
        values: [true, false],
        newRowsAction: 'keep'
      },
      headerComponentParams: {
        dynamicClassType: 'percent',
        masterInputHtml: '<select id="keepPercent-master-input">' +
          '<option value=""></option>' +
          '<option value=true>True</option>' +
          '<option value=false>False</option>' +
          '</select>'
      },
      get headerTooltip() { return this.headerName; }
    },
    {
      headerName: "Deal",
      field: "deal",
      width: 80,
      cellClass: ["deal-col", "tar"],
      cellRenderer: this._gridService.currencyRenderer,
      filter: "agNumberColumnFilter",
      filterParams: {
        defaultOption: "equals",
        inRangeInclusive: true,
      },
      get headerTooltip() { return this.headerName; }
    },
    {
      headerName: "Deal Date",
      field: "dealDate",
      width: 100,
      cellClass: ["dealDate-col"],
      cellRenderer: this._gridService.dateRenderer,
      filter: 'agDateColumnFilter',
      filterParams: {
        comparator: this._gridService.dateComparator,
        inRangeInclusive: true
      },
      get headerTooltip() { return this.headerName; }
    },
    {
      headerName: "CHG",
      field: "chg",
      width: 80,
      cellClass: ["chg-col"],
      filter: "agTextColumnFilter",
      hide: false
    },
    {
      headerName: "Old Price Group",
      width: 150,
      headerClass: 'old-price-column-group',
      children: [
        {
          headerName: '',
          field: 'oldPriceGroup',
          width:150,
          columnGroupShow: 'closed',
          filter: false,
          suppressHeaderMenuButton: true,
        },
        {
          headerName: "Old Book",
          field: "oldBook",
          width: 50,
          cellClass: ["oldBook-col", "tac", "old-values-column"],
          filter: "agTextColumnFilter",
          hide: false,
          columnGroupShow: 'open',
          get headerTooltip() { return this.headerName; }
        },
        {
          headerName: "Old SRP Code",
          field: "oldSrp",
          width: 50,
          cellClass: ["oldSrp-col", "tac", "old-values-column"],
          filter: "agTextColumnFilter",
          hide: false,
          columnGroupShow: 'open',
          get headerTooltip() { return this.headerName; }
        },
        {
          headerName: "Old Multi",
          field: "oldMulti",
          width: 50,
          cellClass: ["oldMulti-col", "tar", "old-values-column"],
          filter: "agNumberColumnFilter",
          hide: false,
          columnGroupShow: 'open',
          filterParams: {
            defaultOption: "equals",
            inRangeInclusive: true,
          },
          get headerTooltip() { return this.headerName; }
        },
        {
          headerName: "Old Price",
          field: "oldPrice",
          width: 80,
          cellClass: ["oldPrice-col", "tar", "old-values-column"],
          hide: false,
          cellRenderer: this._gridService.currencyRenderer,
          filter: "agNumberColumnFilter",
          columnGroupShow: 'open',
          filterParams: {
            defaultOption: "equals",
            inRangeInclusive: true,
          },
          get headerTooltip() { return this.headerName; }
        },
        {
          headerName: "Old Keep Price",
          field: "oldKeepSrp",
          width: 80,
          cellClass: ["oldKeepSrp-col", "tac", "old-values-column"],
          hide: false,
          cellRenderer: this._gridService.checkRenderer,
          filter: "agSetColumnFilter",
          filterParams: {
            values: [true, false],
            newRowsAction: 'keep'
          },
          columnGroupShow: 'open',
          get headerTooltip() { return this.headerName; }
        },
        {
          headerName: "Old %",
          field: "oldPercent",
          width: 50,
          cellClass: ["oldPercent-col", "tar", "old-values-column"],
          hide: false,
          filter: "agNumberColumnFilter",
          columnGroupShow: 'open',
          filterParams: {
            defaultOption: "equals",
            inRangeInclusive: true,
          },
          get headerTooltip() { return this.headerName; }
        },
        {
          headerName: "Old Keep Percent",
          field: "oldKeepPercent",
          width: 80,
          cellClass: ["oldKeepPercent-col", "tac", "old-values-column"],
          hide: false,
          cellRenderer: this._gridService.checkRenderer,
          filter: "agSetColumnFilter",
          filterParams: {
            values: [true, false],
            newRowsAction: 'keep'
          },
          columnGroupShow: 'open',
          get headerTooltip() { return this.headerName; }
        },
      ]
    },
    {
      headerName: "Case Cost Less Delivery Fee",
      field: "caseCostLessDeliveryFee",
      width: 80,
      cellClass: ["caseCostLessDeliveryFee-col", "tar"],
      hide: false,
      cellRenderer: this._gridService.currencyRenderer,
      filter: "agNumberColumnFilter",
      filterParams: {
        defaultOption: "equals",
        inRangeInclusive: true,
      },
      get headerTooltip() { return this.headerName; }
    },
    {
      headerName: "Delivered Case Cost",
      field: "deliveredCaseCost",
      width: 80,
      cellClass: ["deliveredCaseCost-col", "tar"],
      hide: false,
      cellRenderer: this._gridService.currencyRenderer,
      filter: "agNumberColumnFilter",
      filterParams: {
        defaultOption: "equals",
        inRangeInclusive: true,
      },
      get headerTooltip() { return this.headerName; }
    },
    {
      headerName: "Base Cost",
      field: "baseCost",
      width: 80,
      cellClass: ["baseCost-col", "tar"],
      hide: false,
      cellRenderer: this._gridService.currencyRenderer,
      filter: "agNumberColumnFilter",
      filterParams: {
        defaultOption: "equals",
        inRangeInclusive: true,
      },
      get headerTooltip() { return this.headerName; }
    },
    {
      headerName: "Old Cost Group",
      width: 150,
      headerClass: 'old-cost-column-group',
      children: [
        {
          headerName: '',
          field: 'oldCostGroup',
          width: 150,
          columnGroupShow: 'closed',
          filter: false,
          suppressHeaderMenuButton: true,
        },
        {
          headerName: "Old Delivered Case Cost",
          field: "oldDeliveredCaseCost",
          width: 80,
          cellClass: ["oldDeliveredCaseCost-col", "tar", "old-values-column"],
          hide: false,
          cellRenderer: this._gridService.currencyRenderer,
          filter: "agNumberColumnFilter",
          filterParams: {
            defaultOption: "equals",
            inRangeInclusive: true,
          },
          columnGroupShow: 'open',
          get headerTooltip() { return this.headerName; }
        },
        {
          headerName: "Old Base Cost",
          field: "oldBaseCost",
          width: 80,
          cellClass: ["oldBaseCost-col", "tar", "old-values-column"],
          hide: false,
          cellRenderer: this._gridService.currencyRenderer,
          filter: "agNumberColumnFilter",
          filterParams: {
            defaultOption: "equals",
            inRangeInclusive: true,
          },
          columnGroupShow: 'open',
          get headerTooltip() { return this.headerName; }
        },
        {
          headerName: "Old Delivered Unit Cost",
          field: "oldDeliveredUnitCost",
          width: 80,
          cellClass: ["oldDeliveredUnitCost-col", "tar", "old-values-column"],
          hide: false,
          cellRenderer: this._gridService.currencyRenderer,
          filter: "agNumberColumnFilter",
          filterParams: {
            defaultOption: "equals",
            inRangeInclusive: true,
          },
          columnGroupShow: 'open',
          get headerTooltip() { return this.headerName; }
        },
      ]
    },
    {
      headerName: "Off Pack",
      field: "offPack",
      width: 80,
      cellClass: ["offPack-col", "tac"],
      filter: "agTextColumnFilter",
      filterParams: {
        defaultOption: "startsWith",
      },
      hide: true,
      get headerTooltip() { return this.headerName; }
    },
    {
      headerName: "Edited",
      field: "edited",
      width: 80,
      cellClass: ["edited-col", "tac"],
      cellRenderer: this._gridService.checkRenderer,
      filter: "agSetColumnFilter",
      filterParams: {
        values: [true, false],
        newRowsAction: 'keep'
      },
      get headerTooltip() { return this.headerName; }
    },
];
  
  constructor(
    private _pricingRulesService: PricingRulesService,
    private _privateLabelsService: PrivateLabelsService,
    private _gridService: GridService,
    private _messageService: MessageService,
    private _permissionsService: PermissionsService,
    private _changeDetectorRef: ChangeDetectorRef,
    ) {
    this.permissions = this._permissionsService.getPermissions();
    this.gridOptions = <GridOptions> {
			columnDefs: this.columnDefs,
			rowData: this.rowData,
      rowHeight: 35,    
      defaultColDef: {
        sortable: true,
        resizable: true,
        floatingFilter: true,
        suppressSpanHeaderHeight: true,
      }
    }
    this.context = { componentParent: this }
   }

  ngOnInit() {
    if (this.config.logInits) console.log('preview pricing rule init');
    this.getActivePrivateLabels();
  }

  ngOnChanges() {
    //console.log(`haveCriteriaFieldsChangedSinceViewingMatchedItems: ${this.haveCriteriaFieldsChangedSinceViewingMatchedItems}`);
    //console.log(`isPricingRuleFormValid: ${this.isPricingRuleFormValid}`);
    //console.log(`this.pricingRulePreviewStoreId: ${this.pricingRulePreviewStoreId}`);
    //console.log(this.pricingRulePreviewModel);
    //console.log(`areAllCriteriaFieldsValid: ${this.areAllCriteriaFieldsValid}`);
  }

  getActivePrivateLabels(){
    this._privateLabelsService.getActivePrivateLabels()
      .subscribe(
        (data) => {
          this.privateLabels = data;
        },
        (response) => {
          this._messageService.onFailure('Failed to get private labels.', response);
        }
      )
  }

  onStoreSelected(){
    this.showItems();
  }

  //removed lastItemCode and numberOfItemsToReturn since they aren't used anymore - RG 2-27-2024
  showItems(){
    var pricingRulePreviewBindingModel = {
      "pricingRuleId": this.pricingRulePreviewModel.pricingRuleId != null ? this.pricingRulePreviewModel.pricingRuleId : 0,
      "itemCodeExclusiveLowerBound": 0,
      "numberOfStoreItemsToReturn": 999999,
      "name": this.pricingRulePreviewModel.name,
      "description": this.pricingRulePreviewModel.description,
      "ruleType": 'StoreGroup',
      "storeGroupId": this.pricingRulePreviewModel.storeGroupId,
      "storeId": this.pricingRulePreviewStoreId, // use this because store group type won't have a storeId in the pricing rule
      "changeType": this.pricingRulePreviewModel.changeType,
      "book": "1",
      "srpCode": "A",
      "multi": null,
      "price": null,
      "percent": null,
      "keepPercent": null,
      "keepSrp": null,
      "matchCriteria": this.pricingRulePreviewModel.matchCriteria,
    }
    this.previewPricingRule(pricingRulePreviewBindingModel);
  }

  previewPricingRule(pricingRulePreviewBindingModel){
    this._pricingRulesService.previewPricingRule(pricingRulePreviewBindingModel)
    .subscribe(
      (data) => {
        this.formatStoreItemsRowData(data);
      },
      (response) => {
        this._messageService.onFailure('Failed to get preview of pricing rules.', response);
      }
    )
  }

  formatSingleRowData(data) {
    var associatedStoreItemToPreviewItem = data; //(data.isPreview) ? allItemsHashTable[data.item.itemCode] : {};
    var lastPurchasedDate = (data.isPreview) ? associatedStoreItemToPreviewItem.lastPurchasedDate : data.lastPurchasedDate;
    var shelfTagPendingFulfillment = (data.isPreview) ? associatedStoreItemToPreviewItem.shelfTagPendingFulfillment : data.shelfTagPendingFulfillment;
    var shelfTagRequestDate = (data.isPreview) ? associatedStoreItemToPreviewItem.shelfTagRequestDate : data.shelfTagRequestDate;
    var shelfTagQuantityOrdered = (data.isPreview) ? associatedStoreItemToPreviewItem.shelfTagQuantityOrdered : data.shelfTagQuantityOrdered;
    var tprTagPendingFulfillment = (data.isPreview) ? associatedStoreItemToPreviewItem.tprTagPendingFulfillment : data.tprTagPendingFulfillment;
    var tprTagRequestDate = (data.isPreview) ? associatedStoreItemToPreviewItem.tprTagRequestDate : data.tprTagRequestDate;
    var tprPersist = (data.isPreview) ? associatedStoreItemToPreviewItem.tprPersist : data.tprPersist;
    var tprBook = (data.isPreview) ? associatedStoreItemToPreviewItem.tprBook : data.tprBook;
    var storeItemId = (data.isPreview) ? associatedStoreItemToPreviewItem.storeItemId : data.storeItemId;
    var lastYearsMovement = (data.isPreview) ? associatedStoreItemToPreviewItem.item.lastYearsMovement : data.item.lastYearsMovement;
    var yearToDateMovement = (data.isPreview) ? associatedStoreItemToPreviewItem.item.yearlyMovement : data.item.yearlyMovement;
    var weeksInInventory = (data.isPreview) ? associatedStoreItemToPreviewItem.item.weeksInInventory : data.item.weeksInInventory;
    var averageWeeklyMovement = (data.isPreview) ? associatedStoreItemToPreviewItem.item.averageWeeklyMovement : data.item.averageWeeklyMovement;
    var balanceOnHand = (data.isPreview) ? associatedStoreItemToPreviewItem.item.balanceOnHand : data.item.balanceOnHand;
    var offPack = (data.isPreview) ? associatedStoreItemToPreviewItem.item.offPack : data.item.offPack;
    var groupNumber = (data.isPreview) ? associatedStoreItemToPreviewItem.item.groupNumber : data.item.groupNumber;
    var groupDescription = (data.isPreview) ? associatedStoreItemToPreviewItem.item.groupDescription : data.item.groupDescription;
    var buyerCode = (data.isPreview) ? associatedStoreItemToPreviewItem.item.buyerCode : data.item.buyerCode;
    var buyerName = (data.isPreview) ? associatedStoreItemToPreviewItem.item.buyerFullName : data.item.buyerFullName;
    var buyerPhone = (data.isPreview) ? associatedStoreItemToPreviewItem.item.buyerPhoneNumber : data.item.buyerPhoneNumber;
    var buyerEmail = (data.isPreview) ? associatedStoreItemToPreviewItem.item.buyerEmailAddress : data.item.buyerEmailAddress;
    var lastYearsMovementForStore = (data.isPreview) ? associatedStoreItemToPreviewItem.lastYearsMovementForStore : data.lastYearsMovementForStore;
    var yearToDateMovementForStore = (data.isPreview) ? associatedStoreItemToPreviewItem.yearlyMovementForStore : data.yearlyMovementForStore;
    var weeksInStore = (data.isPreview) ? associatedStoreItemToPreviewItem.weeksInStore : data.weeksInStore;
    var averageWeeklyMovementForStore = (data.isPreview) ? associatedStoreItemToPreviewItem.averageWeeklyMovementForStore : data.averageWeeklyMovementForStore;

    var singleRowData = {};
    singleRowData = {
      action: "",
      itemCode: data.item.itemCode,
      deptCode: data.item.deptCode,
      isStock: data.isStock,
      isPreview: data.isPreview,
      size: data.item.size,
      description: (data.item.description).trim(),
      longDescription: (data.item.longDescription != null) ? (data.item.longDescription).trim() : '',
      privateLabel: data.item.privateLabel == '' ? ' ' : data.item.privateLabel,
      newItem: data.item.newItem,
      firstTimeBuy: data.firstTimeBuy,
      wicItem: data.wicItem,
      nowItem: data.nowItem,
      firstTimeBuyOrNowItem: data.firstTimeBuyOrNowItem,
      pricingRuleMatched: data.pricingRuleMatched,
      unitCostLessDeliveryFee: (data.isPreview) ? this.convertToTwoDecimals(data.previewItem.defaultUnitCostLessDeliveryFee) : this.convertToTwoDecimals(data.unitCostLessDeliveryFee),
      deliveredUnitCost: (data.isPreview) ? this.convertToTwoDecimals(data.previewItem.defaultDeliveredUnitCost) : this.convertToTwoDecimals(data.deliveredUnitCost),
      caseCostLessDeliveryFee: (data.isPreview) ? this.convertToTwoDecimals(data.previewItem.defaultCaseCostLessDeliveryFee) : this.convertToTwoDecimals(data.caseCostLessDeliveryFee),
      deliveredCaseCost: (data.isPreview) ? this.convertToTwoDecimals(data.previewItem.defaultDeliveredCaseCost) : this.convertToTwoDecimals(data.deliveredCaseCost),
      baseCost: (data.isPreview) ? this.convertToTwoDecimals(data.previewItem.defaultBaseCost) : this.convertToTwoDecimals(data.baseCost),
      checked: false,
      book: data.book,
      srpCode: data.srpCode,
      multi: data.multi,
      currentPrice: data.currentPrice,
      price: data.price,
      percent: data.percent,
      deal: (data.isPreview) ? data.previewItem.dealAmt : data.dealAmt,
      dealDate: (data.isPreview) ? (data.previewItem.dealDate != null && data.previewItem.dealDate != '' ? moment(data.previewItem.dealDate, 'MMDDYY').toDate() : '') : (data.dealDate != null ? moment(data.dealDate).toDate() : ''),
      chg: (data.changeCode != null) ? data.changeCode : '',
      oldBook: (data.lastBook != null) ? data.lastBook : '',
      oldSrp: (data.lastSrpCode != null) ? data.lastSrpCode : '',
      oldMulti: (data.lastMulti != null) ? data.lastMulti : '',
      oldPrice: (data.lastPrice != null) ? data.lastPrice : '',
      oldKeepSrp: (data.lastKeepSrp != null) ? data.lastKeepSrp : '',
      oldPercent: (data.lastPercent != null) ? data.lastPercent : '',
      oldKeepPercent: (data.lastKeepPercent != null) ? data.lastKeepPercent : '',
      oldBaseCost: (data.lastBaseCost != null) ? this.convertToTwoDecimals(data.lastBaseCost) : '',
      oldDeliveredCaseCost: (data.lastDeliveredCaseCost != null) ? this.convertToTwoDecimals(data.lastDeliveredCaseCost) : '',
      oldDeliveredUnitCost: (data.lastDeliveredUnitCost != null) ? this.convertToTwoDecimals(data.lastDeliveredUnitCost) : '',
      edited: data.edited,
      itemId: data.item.itemId,
      upcCode: data.item.upcCode,
      status: data.item.status,
      //statusDescription: getStatusDesc(data.item.status, data.item.balanceOnHand),
      lastYearsMovement: lastYearsMovement,
      yearToDateMovement: yearToDateMovement,
      weeksInInventory: weeksInInventory,
      averageWeeklyMovement: averageWeeklyMovement,
      balanceOnHand: balanceOnHand,
      offPack: offPack,
      groupNumber: Number(groupNumber),
      groupDescription: groupDescription,
      buyerCode: buyerCode,
      buyerName: buyerName,
      buyerPhone: buyerPhone,
      buyerEmail: buyerEmail,
      //storeNumber: vm.store.storeNumber,
      storeItemId: storeItemId,
      previewStoreItemId: data.previewStoreItemId,
      lastYearsMovementForStore: lastYearsMovementForStore,
      yearToDateMovementForStore: yearToDateMovementForStore,
      weeksInStore: weeksInStore,
      averageWeeklyMovementForStore: averageWeeklyMovementForStore,
      lastPurchasedDate: (lastPurchasedDate != null && lastPurchasedDate != '') ? moment.utc(new Date(lastPurchasedDate)).format("MM/DD/YY") : '',
      shelfTagPendingFulfillment: shelfTagPendingFulfillment,
      shelfTagRequestDate: (shelfTagRequestDate != null && shelfTagRequestDate != '') ? moment.utc(new Date(shelfTagRequestDate)).format("MM/DD/YY") : '',
      shelfTagQuantityOrdered: shelfTagQuantityOrdered,
      tprTagPendingFulfillment: tprTagPendingFulfillment,
      tprTagRequestDate: (tprTagRequestDate != null && tprTagRequestDate != '') ? moment.utc(new Date(tprTagRequestDate)).format("MM/DD/YY") : '',
      tprPersist: tprPersist,
      tprBook: tprBook,
      keepSrp: data.keepSrp,
      keepPercent: data.keepPercent,
      multi1: (data.isPreview) ? data.previewItem.multi1 : data.item.multi1,
      base1: (data.isPreview) ? data.previewItem.base1 : data.item.base1,
      percent1: (data.isPreview) ? data.previewItem.percent1 : data.item.percent1,
      multi2: (data.isPreview) ? data.previewItem.multi2 : data.item.multi2,
      base2: (data.isPreview) ? data.previewItem.base2 : data.item.base2,
      percent2: (data.isPreview) ? data.previewItem.percent2 : data.item.percent2,
      multi3: (data.isPreview) ? data.previewItem.multi3 : data.item.multi3,
      base3: (data.isPreview) ? data.previewItem.base3 : data.item.base3,
      percent3: (data.isPreview) ? data.previewItem.percent3 : data.item.percent3,
      multi4: (data.isPreview) ? data.previewItem.multi4 : data.item.multi4,
      base4: (data.isPreview) ? data.previewItem.base4 : data.item.base4,
      percent4: (data.isPreview) ? data.previewItem.percent4 : data.item.percent4,
      multi5: (data.isPreview) ? data.previewItem.multi5 : data.item.multi5,
      base5: (data.isPreview) ? data.previewItem.base5 : data.item.base5,
      percent5: (data.isPreview) ? data.previewItem.percent5 : data.item.percent5,
      multi6: (data.isPreview) ? data.previewItem.multi6 : data.item.multi6,
      base6: (data.isPreview) ? data.previewItem.base6 : data.item.base6,
      percent6: (data.isPreview) ? data.previewItem.percent6 : data.item.percent6,
      multi7: (data.isPreview) ? data.previewItem.multi7 : data.item.multi7,
      base7: (data.isPreview) ? data.previewItem.base7 : data.item.base7,
      percent7: (data.isPreview) ? data.previewItem.percent7 : data.item.percent7,
      multi1C: (data.isPreview) ? data.previewItem.multi1C : data.item.multi1C,
      current1: (data.isPreview) ? data.previewItem.current1 : data.item.current1,
      percent1C: (data.isPreview) ? data.previewItem.percent1C : data.item.percent1C,
      multi2C: (data.isPreview) ? data.previewItem.multi2C : data.item.multi2C,
      current2: (data.isPreview) ? data.previewItem.current2 : data.item.current2,
      percent2C: (data.isPreview) ? data.previewItem.percent2C : data.item.percent2C,
      multi3C: (data.isPreview) ? data.previewItem.multi3C : data.item.multi3C,
      current3: (data.isPreview) ? data.previewItem.current3 : data.item.current3,
      percent3C: (data.isPreview) ? data.previewItem.percent3C : data.item.percent3C,
      multi4C: (data.isPreview) ? data.previewItem.multi4C : data.item.multi4C,
      current4: (data.isPreview) ? data.previewItem.current4 : data.item.current4,
      percent4C: (data.isPreview) ? data.previewItem.percent4C : data.item.percent4C,
      multi5C: (data.isPreview) ? data.previewItem.multi5C : data.item.multi5C,
      current5: (data.isPreview) ? data.previewItem.current5 : data.item.current5,
      percent5C: (data.isPreview) ? data.previewItem.percent5C : data.item.percent5C,
      multi6C: (data.isPreview) ? data.previewItem.multi6C : data.item.multi6C,
      current6: (data.isPreview) ? data.previewItem.current6 : data.item.current6,
      percent6C: (data.isPreview) ? data.previewItem.percent6C : data.item.percent6C,
      multi7C: (data.isPreview) ? data.previewItem.multi7C : data.item.multi7C,
      current7: (data.isPreview) ? data.previewItem.current7 : data.item.current7,
      percent7C: (data.isPreview) ? data.previewItem.percent7C : data.item.percent7C,
      removedItem: false,
    };

    return singleRowData;
  }

  convertToTwoDecimals(num) {
    return Number(num.toFixed(2));
  }

  formatStoreItemsRowData(data) {
    this.clearGrid();
    if (data.length > 0) {
      for (let i = 0; i < data.length; i++) {
        var singleRowData = this.formatSingleRowData(data[i]);
        this.rowData.push(singleRowData);
      }
      this.agGrid.api.setGridOption("rowData", this.rowData);
      this.isDataLoaded = true;
      setTimeout(() => {
        this.autoSizeColumns();
        //this._gridService.sizeGrid('#ag-grid-wrapper', 0);
      }, 100); 
    }
    this.haveCriteriaFieldsChangedSinceViewingMatchedItems = false;
    this.haveCriteriaFieldsChangedSinceViewingMatchedItemsEvent.emit(false);
    this.numberOfMatchingItemsEvent.emit(data.length);
    this.isGridDataLoadedEvent.emit(true);
  }

  onModelUpdated(){
    this.rowCount = this.agGrid.api.getDisplayedRowCount();
  }

  clearGrid() {
    this.rowData = [];
    this.isDataLoaded = false;
    this.agGrid.api.setGridOption("rowData", this.rowData);
  };

  clearFilters(){
    this.quickFilterText = '';
    this.agGrid.api.setGridOption('quickFilterText', this.quickFilterText);
    this.agGrid.api.setFilterModel(null);
    this.agGrid.api.onFilterChanged();
  }

  resetColumns(){
    this.agGrid.api.resetColumnState();
    this.fitColumns(); //since this is done by default also
  }

  autoSizeColumns(){
    var columns = this.agGrid.api.getColumns();
    this.agGrid.api.autoSizeColumns(columns);
  }

  fitColumns(){
    this.agGrid.api.sizeColumnsToFit();
  }

  onQuickFilterChange(){
    this.agGrid.api.setGridOption('quickFilterText', this.quickFilterText);
  }

  privateLabelRenderer(params) {
    var label = '';
    var eCell = document.createElement('span');
    for (var i = 0; i < this.privateLabels.length; i++) {
      if (params.value == this.privateLabels[i].letterCode) {
        label = this.privateLabels[i].label;
        break;
      }
    }
    if (params.value == ' ') {
      label = params.hasOwnProperty('data') ? '' : 'None';
    }
    eCell.innerHTML = label;
    return eCell;
  }


}
