import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'app-transfer-button',
  template: `<span><button style="height: 20px" (click)="invokeParentMethod()" class="btn btn-info" title="Transfer Assets"><i class="fas fa-share"></i></button></span>`,
  styles: [
    `.btn {
             line-height: 0.5;
             background: orange !important;
             color:#fff;
         }`
  ]
})
export class TransferButtonComponent implements ICellRendererAngularComp  {

  public params!: ICellRendererParams;

  agInit(params: ICellRendererParams): void {
      this.params = params;
  }

  public invokeParentMethod() {
      this.params.context.componentParent.onTransferButtonClick(this.params.data);
  }

  refresh(): boolean {
      return false;
  }

}
