<div [hidden]="!isUserLoggedIn">
  <!-- <audio id="audio" src="/assets/audio/pop.mp3" autostart="false"></audio> -->
  <div id="notification-window" [@notificationWindowAnimation]="isNotificationWindowOpen ? 'open' : 'closed'">
      <div class="title-bar">
        <span class="quantity" *ngIf="quantity > 0">{{quantity}}</span>Live Updates
        <span (click)="clearWindow()" class="clear-notification help-trigger" (mouseenter)="showHelpfulTip($event)" (mouseleave)="hideHelpfulTip($event)" data-help="clear-notifications"><i class="fal fa-trash-alt"></i></span>
        <span (click)="toggleNotificationWindow()" class="arrow" [@arrowAnimation]="isNotificationWindowOpen ? 'open' : 'closed'"><i class="fas fa-caret-up"></i></span>
      </div>
      <div id="notification-content">
      </div>
      <div class="send-options">
          <div class="remember">
              <input type="checkbox" id="rememberSendTo" name="rememberSendTo" />
              <label for="rememberSendTo">Remember selection</label>
          </div>
          <label>Send Message To:</label>
          <div id="users-select-wrapper"></div>
      </div>
      <div class="send-message">
          <textarea id="message-text"></textarea>
          <button (click)="sendMessage()">Send</button>
      </div>
      <div id="users-pane" [@usersPaneAnimation]="isUsersPaneOpen ? 'open' : 'closed'">
          <h3>Connected Users</h3>
          <div id="users-wrapper"></div>
          <div id="users-pane-tab" (click)="toggleUsersPane()"><i class="fa fa-user" aria-hidden="true"></i></div>
      </div>
  </div>
</div>

<div id="clear-notifications-tooltip" class="help">
    <div class="wrapper">
        <h4>Clear Notifications</h4>
        <p>This button will remove all notifications from the notification window.</p>
    </div>
</div>

