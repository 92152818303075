
<div id="controlled-store-diagram-wrapper" *ngIf="shouldDiagramBeVisible">
  <div id="store-diagram-trigger" (click)="showDiagram($event)" (mouseleave)="hideDiagram($event)" title="Show Controlled Stores Diagram">
    <i class="fas fa-sitemap"></i>
  </div>

  <div id="store-diagram">
    <div class="wrapper">
      <div [innerHTML]="storeGroupDiagramHtml"></div>
    </div>
  </div>
</div>