<div id="app-content">
  <span [hidden]="true">RPMS-version={{version}}</span>
  <rpms-app-header></rpms-app-header>
  <div class="main-content" >
    <router-outlet></router-outlet>
  </div>
  <rpms-spinner></rpms-spinner>
  <rpms-idle-dialog></rpms-idle-dialog>
  <rpms-notification-window></rpms-notification-window>
  <div id="toast-holder">
    <div id="toasts-top-right">
        <!--<div><span>This is a sample toast</span></div>
      <div><span>This is a sample toast</span></div>-->
    </div>
    <div id="toasts-bottom-left">
        <!--<div><span>This is a sample toast</span></div>
      <div><span>This is a sample toast</span></div>-->
    </div>
  </div>
</div>
