  <form [formGroup]="loginForm" id="login-form" (ngSubmit)="onSubmit()" [hidden]="isLoggedIn" >
    <h1>Login</h1>
    <mat-form-field>
      <mat-label>Username</mat-label>
      <input matInput id="userName" formControlName="userName" placeholder="Username">
      <mat-error *ngIf="loginForm.hasError('required', 'userName')">Username is required</mat-error>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Password</mat-label>
      <input matInput id="password" formControlName="password" placeholder="Password" [type]="hide ? 'password' : 'text'">
      <mat-icon matSuffix (click)="hide = !hide">{{hide ? 'visibility' : 'visibility_off'}}</mat-icon>
      <mat-error *ngIf="loginForm.hasError('required', 'password')">Password is required</mat-error>
    </mat-form-field>
    <div>
      <button mat-raised-button color="primary" [disabled]="!loginForm.valid" type="submit">Login</button>
    </div>
  </form>
  <div id="offline-link"><a (click)="navigateToOffline()">View Offline Data</a></div>

  <div id="share-screen-buttons">
    <a class="button" href="http://showmypc.com/users/appbuilder/showmypcauto.php?at=&ci=pig&pr=1&tk=cbbf0d5f1a28" target="_blank">Show My PC</a>
    <a class="button" href="https://gorpms.screenconnect.com/" target="_blank">Screen Connect</a>
  </div>

