<div id="tpr-tag-editor-wrapper" class="editor-wrapper" cdkDrag>
  <div class="editor-title" cdkDragHandle>
    Compare At Tag Request
      <span class="close" (click)="close()"><i class="fa fa-times"></i></span>
    </div>
  <div class="editor-item-title">
    Item Code: {{rowData.itemCode}}
  </div>
  <div class="editor-content">
    <mat-radio-group name="tprType" #tprTypeRadioGroup="ngModel" [(ngModel)]="tprType" (change)="onTprTypeChanged()">
      <mat-radio-button value="always">Always</mat-radio-button>
      <mat-radio-button value="once">Once</mat-radio-button>
      <mat-radio-button value="none">None Requested</mat-radio-button>
    </mat-radio-group>
    <table>
      <tr>
        <th>Book</th>
        <th>Retail</th>
      </tr>
      <tr *ngFor="let bv of bookValues; let i=index">
        <td [ngClass]="{'selected': selectedBook == i+1, 'disabled': bv<rowData.price }" (click)="onCellClicked(i+1, bv<rowData.price)">{{i+1}}</td>
        <td [ngClass]="{'selected': selectedBook == i+1, 'disabled': bv<rowData.price }" (click)="onCellClicked(i+1, bv<rowData.price)">{{bv}}</td>
      </tr>
    </table>
    <div class="button-wrapper">
      <button mat-raised-button color="primary" (click)="sendRowNodeAndTprTagModel()" [disabled]="isSaveDisabled">Save Changes</button>
    </div>
  </div>
</div>
