import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { PosExportService } from 'src/app/shared/pos-export.service';
import { MatDialogRef } from '@angular/material/dialog';
import { ClonePosSettingsDialog } from '../admin-pos-export-list/admin-pos-export-list.component';
import { MessageService } from 'src/app/shared/message.service';
import { AuthenticationService } from 'src/app/core/authentication/shared/authentication.service';

@Component({
  selector: 'rpms-pos-export-clone',
  templateUrl: './pos-export-clone.component.html',
  styleUrls: ['./pos-export-clone.component.css']
})
export class PosExportCloneComponent implements OnInit {
  @Input() data: any;

  allSettingsForCloning;
  myUser;

  clonePosExportSettingsForm = this._fb.group({
    sourceStore: [''],
    targetPosSettingsId: [''],
  });
  
  constructor(
    private _fb: UntypedFormBuilder,    
    private _posExportService: PosExportService,
    private _authenticationService: AuthenticationService,
    private _messageService: MessageService,
    public dialogRef: MatDialogRef<ClonePosSettingsDialog>,
    ) { 
      this.myUser = this._authenticationService.getMyUser();
    }

  ngOnInit() {
    if (this.myUser.hasReadOnlyRestriction) this.clonePosExportSettingsForm.disable();
    this.allSettingsForCloning = this.data.allSettingsForCloning;
    this.clonePosExportSettingsForm.patchValue({
      sourceStore: this.data.sourceStoreNumberAndLocation,
    });
  }

  onSubmitClonePosExportSettings(){
    //Users with hasReadOnlyRestriction set to true should not even be allowed to invoke this action because it is hidden or disabled in the UI.
    //This is for extra protection. The back end will also be protected.
    if (this.myUser.hasReadOnlyRestriction){
      this._messageService.alertReadOnlyRestriction();
      return;
    }
    
    var targetSettingsId = this.clonePosExportSettingsForm.value.targetPosSettingsId;
    var sourceSettingsId = this.data.sourceSettingsId;
    this._posExportService.updateStorePosExportSettingsFromSettings(targetSettingsId, sourceSettingsId, {})
      .subscribe(
        (data) => {
          this.dialogRef.close('reload');
          this._messageService.onSuccess('Successfully cloned POS Settings');
        },
        (response) => {
          this._messageService.onFailure('Failed to clone POS Settings.', response)
        }
      );
  }

  closeDialog(){
    this.dialogRef.close();
  }

}
