import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AdminRoutingModule } from './admin-routing.module';
import { AdminComponent } from './admin/admin.component';
import { AdminHomeComponent } from './admin-home/admin-home.component';
import { AdminUserListComponent, AdminUserDetailDialog } from './admin-users/admin-user-list/admin-user-list.component';
import { AdminUserDetailComponent } from './admin-users/admin-user-detail/admin-user-detail.component';
import { AdminApplicationComponent } from './admin-application/admin-application.component';
import { AdminItemFilterDetailComponent } from './admin-item-filters/admin-item-filter-detail/admin-item-filter-detail.component';
import { AdminItemFilterListComponent, AdminItemFilterDetailDialog } from './admin-item-filters/admin-item-filter-list/admin-item-filter-list.component';
import { AdminItemListListComponent, AdminItemListDetailDialog } from './admin-item-lists/admin-item-list-list/admin-item-list-list.component';
import { AdminItemListDetailComponent } from './admin-item-lists/admin-item-list-detail/admin-item-list-detail.component';
import { AdminPosExportListComponent, AdminPosExportDetailDialog, ClonePosSettingsDialog } from './admin-pos-export/admin-pos-export-list/admin-pos-export-list.component';
import { AdminPosExportDetailComponent } from './admin-pos-export/admin-pos-export-detail/admin-pos-export-detail.component';
import { AdminScheduleComponent } from './admin-schedule/admin-schedule.component';
import { AdminStoreListComponent, AdminStoreDetailDialog } from './admin-stores/admin-store-list/admin-store-list.component';
import { AdminStoreDetailComponent } from './admin-stores/admin-store-detail/admin-store-detail.component';
import { AdminStoreGroupListComponent, AdminStoreGroupDetailDialog } from './admin-store-groups/admin-store-group-list/admin-store-group-list.component';
import { AdminStoreGroupDetailComponent } from './admin-store-groups/admin-store-group-detail/admin-store-group-detail.component';
import { AdminShelfTagListComponent } from './admin-tag-orders/admin-shelf-tag-list/admin-shelf-tag-list.component';
import { AdminTprTagListComponent } from './admin-tag-orders/admin-tpr-tag-list/admin-tpr-tag-list.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule} from '@angular/material/button';
import { MatInputModule} from '@angular/material/input';
import { MatFormFieldModule} from '@angular/material/form-field';
import { MatSelectModule} from '@angular/material/select';
import { MatOptionModule} from '@angular/material/core';
import { MatIconModule} from '@angular/material/icon';
import { MatDialogModule} from '@angular/material/dialog';
import { MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { MatCardModule} from '@angular/material/card';
import { MatCheckboxModule} from '@angular/material/checkbox';
import { MatTabsModule} from '@angular/material/tabs';
import { MatTooltipModule} from '@angular/material/tooltip';
import { MatExpansionModule} from '@angular/material/expansion';
import { MatMenuModule } from '@angular/material/menu';
import { MatRadioModule} from '@angular/material/radio';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { PosExportCloneComponent } from './admin-pos-export/pos-export-clone/pos-export-clone.component';
import { PosExportDepartmentAssociationsComponent } from './admin-pos-export/pos-export-department-associations/pos-export-department-associations.component';
//import { NgxMaskModule } from 'ngx-mask';
import { AdminPrivateLabelsListComponent, AdminPrivateLabelsDetailDialog } from './admin-private-labels/admin-private-labels-list/admin-private-labels-list.component';
import { AdminPrivateLabelsDetailComponent } from './admin-private-labels/admin-private-labels-detail/admin-private-labels-detail.component';
import { AdminPricingRulesListComponent, PrioritizePricingRulesDialog } from './admin-pricing-rules/admin-pricing-rules-list/admin-pricing-rules-list.component';
import { AdminPricingRulesDetailComponent } from './admin-pricing-rules/admin-pricing-rules-detail/admin-pricing-rules-detail.component';
//import { AdminPricingRulesDetailComponent, PricingRulePreviewDialog } from './admin-pricing-rules/admin-pricing-rules-detail/admin-pricing-rules-detail.component';
import { PricingRulePreviewComponent } from './admin-pricing-rules/pricing-rule-preview/pricing-rule-preview.component';
import { PrioritizePricingRulesComponent } from './admin-pricing-rules/prioritize-pricing-rules/prioritize-pricing-rules.component';
import { FileUploadModule } from 'ng2-file-upload';
import { AdminAnnouncementsListComponent, AdminAnnouncementsDetailDialog } from './admin-announcements/admin-announcements-list/admin-announcements-list.component';
import { AdminAnnouncementsDetailComponent } from './admin-announcements/admin-announcements-detail/admin-announcements-detail.component';
import { StoreGroupOwnedAssetsComponent } from './admin-store-groups/store-group-owned-assets/store-group-owned-assets.component';
import { StoreGroupTransferDialogComponent } from './admin-store-groups/store-group-transfer-dialog/store-group-transfer-dialog.component';
import { AssetsDialogComponent } from '../shared/assets-dialog/assets-dialog.component';
import { StoreOwnedAssetsComponent } from './admin-stores/store-owned-assets/store-owned-assets.component';
import { UserOwnedAssetsComponent } from './admin-users/user-owned-assets/user-owned-assets.component';
import { UserTransferDialogComponent } from './admin-users/user-transfer-dialog/user-transfer-dialog.component';
import { StoreTransferDialogComponent } from './admin-stores/store-transfer-dialog/store-transfer-dialog.component';
import { AgGridModule } from 'ag-grid-angular';
import { NgxMaskDirective, NgxMaskPipe } from 'ngx-mask';

@NgModule({
    imports: [
        CommonModule,
        AdminRoutingModule,
        AgGridModule,
        FormsModule,
        ReactiveFormsModule,
        MatButtonModule,
        MatInputModule,
        MatFormFieldModule,
        MatSelectModule,
        MatOptionModule,
        MatIconModule,
        MatDialogModule,
        MatProgressSpinnerModule,
        MatCardModule,
        MatCheckboxModule,
        MatTabsModule,
        MatRadioModule,
        MatTooltipModule,
        MatExpansionModule,
        MatMenuModule,
        DragDropModule,
        //NgxMaskModule.forChild(),
        NgxMaskDirective, NgxMaskPipe,
        FileUploadModule,
    ],
    declarations: [
        AdminComponent,
        AdminHomeComponent,
        AdminUserListComponent, AdminUserDetailDialog,
        AdminUserDetailComponent,
        AdminApplicationComponent,
        AdminItemFilterDetailComponent,
        AdminItemFilterListComponent, AdminItemFilterDetailDialog,
        AdminItemListListComponent, AdminItemListDetailDialog,
        AdminItemListDetailComponent,
        AdminPosExportListComponent,
        AdminPosExportDetailComponent,
        AdminScheduleComponent,
        AdminStoreListComponent, AdminStoreDetailDialog,
        AdminStoreDetailComponent,
        AdminStoreGroupListComponent, AdminStoreGroupDetailDialog,
        AdminStoreGroupDetailComponent,
        AdminShelfTagListComponent,
        AdminTprTagListComponent,
        PosExportCloneComponent, AdminPosExportDetailDialog, ClonePosSettingsDialog,
        PosExportDepartmentAssociationsComponent,
        AdminPrivateLabelsListComponent, AdminPrivateLabelsDetailDialog,
        AdminPrivateLabelsDetailComponent,
        AdminPricingRulesListComponent, PrioritizePricingRulesDialog,
        AdminPricingRulesDetailComponent,
        //AdminPricingRulesDetailComponent, PricingRulePreviewDialog,
        PricingRulePreviewComponent,
        PrioritizePricingRulesComponent,
        AdminAnnouncementsListComponent, 
        AdminAnnouncementsDetailDialog,
        AdminAnnouncementsDetailComponent,
        StoreGroupTransferDialogComponent,
        UserTransferDialogComponent,
        StoreTransferDialogComponent,
        AssetsDialogComponent,
        StoreGroupOwnedAssetsComponent,
        StoreOwnedAssetsComponent,
        UserOwnedAssetsComponent,
    ],
    exports: [
        AdminUserDetailDialog
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AdminModule { }
