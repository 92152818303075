
import { APP_INITIALIZER, Injector } from '@angular/core';
import { AppConfig } from './app.config';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ErrorHandler, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgIdleModule } from '@ng-idle/core';
//import { ModuleRegistry, AllModules } from '@ag-grid-enterprise/all-modules';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
//import { NgxMaskModule } from 'ngx-mask';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule} from '@angular/material/form-field';
import { MatSelectModule} from '@angular/material/select';
import { MatOptionModule} from '@angular/material/core';
import { MatIconModule} from '@angular/material/icon';
import { MatDialogModule} from '@angular/material/dialog';
import { MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { MatCardModule} from '@angular/material/card';
import { MatCheckboxModule} from '@angular/material/checkbox';
import { MatTabsModule} from '@angular/material/tabs';
import { MatRadioModule} from '@angular/material/radio';
import { MatTooltipModule} from '@angular/material/tooltip';
import { MatSlideToggleModule} from '@angular/material/slide-toggle';
import { MatNativeDateModule} from '@angular/material/core';
import { MatDatepickerModule} from '@angular/material/datepicker';
import { MatProgressBarModule} from '@angular/material/progress-bar';

import { AppComponent } from './app.component';
import { LoginComponent } from './core/authentication/login/login.component';
import { HomeComponent } from './home/home.component';
import { AuthGuard } from './core/authentication/shared/auth.guard';
import { HeaderComponent } from './header/header.component';
import { AuthInterceptor } from './core/authentication/shared/auth.interceptor';
import { MessageDialogComponent } from './shared/message-dialog/message-dialog.component';
import { PriceManagementComponent, GroupHeadersDialog } from './price-management/price-management.component';
import { AdWeekComponent } from './ad-week/ad-week.component';
import { HelpComponent } from './help/help.component';
import { ViewDetailButtonComponent } from './shared/view-detail-button/view-detail-button.component';
import { AdminUserDetailDialog } from './admin/admin-users/admin-user-list/admin-user-list.component';
import { AdminModule } from './admin/admin.module';
import { DeleteButtonComponent } from './shared/delete-button/delete-button.component';
import { ConfirmationDialogComponent } from './shared/confirmation-dialog/confirmation-dialog.component';
import { PosActionButtonsComponent } from './admin/admin-pos-export/admin-pos-export-list/admin-pos-export-list.component';
import { PriceManagementTabsComponent } from './price-management/price-management-tabs/price-management-tabs.component';
import { PriceManagementItemFilterComponent } from './price-management/price-management-item-filter/price-management-item-filter.component';
import { PricePercentEditorComponent } from './price-management/price-percent-editor/price-percent-editor.component';
import { BookEditorComponent } from './price-management/book-editor/book-editor.component';
import { ItemListEditorComponent, ItemListDetailDialog } from './price-management/item-list-editor/item-list-editor.component';
import { TprTagEditorComponent } from './price-management/tpr-tag-editor/tpr-tag-editor.component';
import { ShelfTagEditorComponent } from './price-management/shelf-tag-editor/shelf-tag-editor.component';
import { ItemListDetailComponent } from './price-management/item-list-editor/item-list-detail/item-list-detail.component';
import { SanitizeHtmlPipe } from './shared/sanitize-html.pipe';
import { CustomHeaderComponent } from './price-management/custom-header/custom-header.component';
import { NotificationWindowComponent } from './shared/notification-window/notification-window.component';
import { OfflinePriceManagementComponent } from './price-management/offline-price-management/offline-price-management.component';
import { RouteReuseStrategy } from "@angular/router";
import { CustomRouteReuseStrategy } from './core/authentication/shared/custom-route-reuse-strategy';
import { GroupHeadersComponent } from './price-management/group-headers/group-headers.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { IdleDialogComponent } from './shared/idle-dialog/idle-dialog.component';
import { SpinnerComponent } from './shared/spinner/spinner.component';
import { GlobalErrorHandler } from './shared/error-handler';
import { CriticalErrorDialogComponent } from './shared/critical-error-dialog/critical-error-dialog.component';
import { PipeModule } from './shared/pipe/pipe.module';
import { ControlledStoreDiagramComponent } from './price-management/controlled-store-diagram/controlled-store-diagram.component';
import { ControlledStorePricingComponent } from './price-management/controlled-store-pricing/controlled-store-pricing.component';
import { ViewControlledStorePricingButtonComponent } from './shared/view-controlled-store-pricing-button/view-controlled-store-pricing-button.component';
import { SpinnerService } from './shared/spinner.service';
import { ReportService } from './shared/report.service';
import { AgGridModule } from 'ag-grid-angular';
import { TransferButtonComponent } from './shared/transfer-button/transfer-button.component';
import { TransferDialogComponent } from './shared/transfer-dialog/transfer-dialog.component';
import { MatExpansionModule } from '@angular/material/expansion';
//import { StoreGroupTransferDialogComponent } from './admin/admin-stores/store-group-transfer-dialog/store-group-transfer-dialog.component';
import { DeletionHistoryDialogComponent } from './shared/deletion-history-dialog/deletion-history-dialog.component';
import { ViewAssetsButtonComponent } from './shared/view-assets-button/view-assets-button.component';
import { AssetsDialogComponent } from './shared/assets-dialog/assets-dialog.component';
import { AdminActionButtonsComponent } from './shared/admin-action-buttons/admin-action-buttons.component';
import { ModuleRegistry } from 'ag-grid-enterprise';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
//import { StoreGroupOwnedAssetsComponent } from './shared/store-group-owned-assets/store-group-owned-assets.component';

const appRoutes: Routes = [
  { 
    path: 'home', 
    component: HomeComponent, 
    canActivate:[AuthGuard],
    data: { title: 'Home Page' }
    //data: { shouldReuse: true }
  },
  { 
    path: 'price-management', 
    component: PriceManagementComponent, 
    canActivate:[AuthGuard],
    data: { title: 'Price Management' }
  },
  { 
    path: 'offline-price-management', 
    component: OfflinePriceManagementComponent, 
    data: { shouldReuse: true, title: 'Offline Price Management' }
  },
  { 
    path: 'ad-week', 
    component: AdWeekComponent, 
    canActivate:[AuthGuard],
    data: { title: 'Ad Week' }
    //data: { shouldReuse: true }
  },
  {
    path: 'reports',
    canActivate:[AuthGuard],
    loadChildren: () => import('./reports/reports.module').then(m => m.ReportsModule),
    data: { title: 'Reports' }
    //data: { preload: true }
  },
  {
    path: 'admin',
    canActivate:[AuthGuard],
    loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule),
    data: { title: 'Admin' }
    //data: { preload: true }
  },
  { 
    path: 'help', 
    component: HelpComponent, 
    canActivate:[AuthGuard],
    data: { shouldReuse: true, title: 'Help' }
  },
  {
    path: 'login', 
    component: LoginComponent,
    children: [{ path: '', component: LoginComponent }],
    data: { title: 'Login' }
    //data: { shouldReuse: true }
  },
  { path : '', redirectTo:'/home', pathMatch : 'full'},
  { path: '**', redirectTo:'/home', pathMatch : 'full'},

]

export function initializeApp(appConfig: AppConfig) {
  return () => appConfig.load();
}

@NgModule({ declarations: [
        AppComponent,
        LoginComponent,
        HomeComponent,
        HeaderComponent,
        MessageDialogComponent,
        PriceManagementComponent, GroupHeadersDialog,
        CustomHeaderComponent,
        AdWeekComponent,
        HelpComponent,
        ViewDetailButtonComponent,
        DeleteButtonComponent,
        PosActionButtonsComponent,
        ConfirmationDialogComponent,
        PriceManagementTabsComponent,
        PriceManagementItemFilterComponent,
        PricePercentEditorComponent,
        BookEditorComponent,
        ItemListEditorComponent, ItemListDetailDialog,
        TprTagEditorComponent,
        ShelfTagEditorComponent,
        ItemListDetailComponent,
        SanitizeHtmlPipe,
        NotificationWindowComponent,
        OfflinePriceManagementComponent,
        GroupHeadersComponent,
        IdleDialogComponent,
        SpinnerComponent,
        CriticalErrorDialogComponent,
        ControlledStoreDiagramComponent,
        ControlledStorePricingComponent,
        ViewControlledStorePricingButtonComponent,
        TransferButtonComponent,
        TransferDialogComponent,
        DeletionHistoryDialogComponent,
        ViewAssetsButtonComponent,
        AdminActionButtonsComponent,
        //AssetsDialogComponent,
        //UserTransferDialogComponent,
        //StoreTransferDialogComponent,
        //StoreGroupTransferDialogComponent,
        //StoreGroupOwnedAssetsComponent,
    ],
    exports: [
        RouterModule,
    ],
    schemas: [
        CUSTOM_ELEMENTS_SCHEMA
    ],
    bootstrap: [AppComponent], imports: [BrowserModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule.forRoot(appRoutes, { useHash: true }),
        //NgxMaskModule.forRoot(),
        NgxMaskDirective, NgxMaskPipe,
        BrowserAnimationsModule,
        MatExpansionModule,
        MatButtonModule,
        MatInputModule,
        MatFormFieldModule,
        MatSelectModule,
        MatOptionModule,
        MatIconModule,
        MatDialogModule,
        MatProgressSpinnerModule,
        MatCardModule,
        MatCheckboxModule,
        MatTabsModule,
        MatRadioModule,
        MatTooltipModule,
        MatSlideToggleModule,
        MatNativeDateModule,
        MatDatepickerModule,
        MatProgressBarModule,
        DragDropModule,
        NgIdleModule.forRoot(),
        //AgGridModule.withComponents([PricePercentEditorComponent]),
        AgGridModule,
        AdminModule,
        PipeModule], providers: [
        AppConfig,
        {
            provide: APP_INITIALIZER,
            useFactory: initializeApp,
            deps: [AppConfig],
            multi: true
        },
        AuthGuard,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true
        },
        {
            provide: RouteReuseStrategy,
            useClass: CustomRouteReuseStrategy
        },
        {
            provide: ErrorHandler,
            useClass: GlobalErrorHandler,
            deps: [AppConfig, Injector],
        },
        provideNgxMask(),
        provideHttpClient(withInterceptorsFromDi())
    ] })
export class AppModule { 
  constructor() {
    //ModuleRegistry.registerModules(AllModules);
  }
}
