<div class="assets-scroller">
  <div class="action-buttons">
    <button mat-button (click)="accordion.openAll()">Expand All</button>
    <button mat-button (click)="accordion.closeAll()">Collapse All</button>
    <button *ngIf="showTransferButton && permissions.roleAllowsUserToTransferStoreGroupAssets" class="transfer-button" mat-stroked-button color="primary" (click)="onTransferButtonClick()">Open Transfer Dialog</button>
  </div>
  <mat-accordion multi>

    <mat-expansion-panel id="users-panel" (opened)="usersPanelOpenState = true" (closed)="usersPanelOpenState = false">
      <mat-expansion-panel-header>
        <mat-panel-title>
          Users
        </mat-panel-title>
        <mat-panel-description>
          {{usersDescription}}
        </mat-panel-description>
      </mat-expansion-panel-header>
      <div *ngIf="users && users.length > 0" class="asset-row header">
        <div>User Name</div>
        <div>Full Name</div>
        <div>Role</div>
        <div *ngIf="permissions.roleAllowsUserToDeleteStoreGroupUser && showDelete && userHasDeletionPrivilege" class="delete-col">
          <button mat-stroked-button color="primary" [matMenuTriggerFor]="menu" [disabled]="myUser.hasReadOnlyRestriction">Actions <mat-icon>expand_more</mat-icon></button>
          <mat-menu #menu="matMenu" xPosition="before">
            <button mat-menu-item style="text-transform: unset !important;" (click)="selectAllUsers(true)">Select All (Users)</button>
            <button mat-menu-item style="text-transform: unset !important;" (click)="selectAllUsers(false)">Unselect All (Users)</button>
            <button mat-menu-item style="text-transform: unset !important;" (click)="onUserDeleteClick()">Delete Selected (Users)</button>
          </mat-menu>
        </div>
      </div>
      <form [formGroup]="userForm">
        <div formArrayName="users" *ngFor="let user of usersFormArray.controls; let i = index" class="asset-row">
            <div>{{users[i]?.userName}}</div>
            <div>{{users[i]?.fullName}}</div>
            <div>{{addSpacesBetweenWords(users[i]?.roles[0])}}</div>
            <!-- <div class="delete-col"><button (click)="invokeParentMethod()" mat-flat-button color="warn" matTooltip="Delete" matTooltipPosition="left" matTooltipShowDelay="500"><i class="fas fa-times"></i></button></div> -->
            <div *ngIf="permissions.roleAllowsUserToDeleteStoreGroupUser && showDelete && userHasDeletionPrivilege" class="delete-col"><mat-checkbox [formControlName]="i" [disabled]="myUser.hasReadOnlyRestriction"></mat-checkbox></div>
        </div>
      </form>
    </mat-expansion-panel>

    <mat-expansion-panel  id="pricing-rules-panel" (opened)="pricingRulesPanelOpenState = true" (closed)="pricingRulesPanelOpenState = false">
      <mat-expansion-panel-header>
        <mat-panel-title>
          Pricing Rules
        </mat-panel-title>
        <mat-panel-description>
          {{pricingRulesDescription}}
        </mat-panel-description>
      </mat-expansion-panel-header>
      <div *ngIf="pricingRules && pricingRules.length > 0" class="asset-row header">
        <div class="priority-col">Priority</div>
        <div>Rule Name</div>
        <div class="ruleType-col">Rule Type</div>
        <div class="storeNumber-col">Store Number</div>
        <div *ngIf="permissions.roleAllowsUserToDeletePricingRule && showDelete && userHasDeletionPrivilege" class="delete-col">
          <button mat-stroked-button color="primary" [matMenuTriggerFor]="menu" [disabled]="myUser.hasReadOnlyRestriction">Actions <mat-icon>expand_more</mat-icon></button>
          <mat-menu #menu="matMenu" xPosition="before">
            <button mat-menu-item style="text-transform: unset !important;" (click)="selectAllPricingRules(true)">Select All (Pricing Rules)</button>
            <button mat-menu-item style="text-transform: unset !important;" (click)="selectAllPricingRules(false)">Unselect All (Pricing Rules)</button>
            <button mat-menu-item style="text-transform: unset !important;" (click)="onPricingRuleDeleteClick()">Delete Selected (Pricing Rules)</button>
          </mat-menu>
        </div>
      </div>
      <form [formGroup]="pricingRuleForm">
        <div formArrayName="pricingRules" *ngFor="let pricingRule of pricingRulesFormArray.controls; let i = index" class="asset-row">
          <div class="priority-col">{{pricingRules[i]?.priority}}</div>
          <div>{{pricingRules[i]?.name}}</div>
          <div class="ruleType-col">{{addSpacesBetweenWords(pricingRules[i]?.ruleType)}}</div>
          <div class="storeNumber-col">{{pricingRules[i]?.storeNumber}}</div>
          <!-- <div class="delete-col"><button (click)="invokeParentMethod()" mat-flat-button color="warn" matTooltip="Delete" matTooltipPosition="left" matTooltipShowDelay="500"><i class="fas fa-times"></i></button></div> -->
          <div *ngIf="permissions.roleAllowsUserToDeletePricingRule && showDelete && userHasDeletionPrivilege" class="delete-col"><mat-checkbox [formControlName]="i" [disabled]="myUser.hasReadOnlyRestriction"></mat-checkbox></div>
        </div>
      </form>
    </mat-expansion-panel>

    <mat-expansion-panel id="super-store-group-admins-panel" (opened)="superStoreGroupAdminsPanelOpenState = true" (closed)="superStoreGroupAdminsPanelOpenState = false">
      <mat-expansion-panel-header>
        <mat-panel-title>
          Super Store Group Admins
        </mat-panel-title>
        <mat-panel-description>
          {{superStoreGroupAdminsDescription}}
        </mat-panel-description>
      </mat-expansion-panel-header>
      <div *ngIf="superStoreGroupAdmins && superStoreGroupAdmins.length > 0" class="asset-row header">
        <div>User Name</div>
        <div>Full Name</div>
        <div>Role</div>
        <div *ngIf="permissions.roleAllowsUserToRemoveSSGAFromManagement && showDelete && userHasDeletionPrivilege" class="delete-col">
          <button mat-stroked-button color="primary" [matMenuTriggerFor]="menu" [disabled]="myUser.hasReadOnlyRestriction">Actions <mat-icon>expand_more</mat-icon>
            </button>
          <mat-menu #menu="matMenu" xPosition="before">
            <button mat-menu-item style="text-transform: unset !important;" (click)="selectAllSuperStoreGroupAdmins(true)">Select All
              (Super Store Group Admins)</button>
            <button mat-menu-item style="text-transform: unset !important;" (click)="selectAllSuperStoreGroupAdmins(false)">Unselect
              All (Super Store Group Admins)</button>
            <button mat-menu-item style="text-transform: unset !important;" (click)="onSuperStoreGroupAdminDeleteClick()">Remove Selected from Management
              </button>
          </mat-menu>
        </div>
      </div>
      <form [formGroup]="superStoreGroupAdminForm">
        <div formArrayName="superStoreGroupAdmins" *ngFor="let superStoreGroupAdmin of superStoreGroupAdminsFormArray.controls; let i = index"
          class="asset-row">
          <div>{{superStoreGroupAdmins[i]?.userName}}</div>
          <div>{{superStoreGroupAdmins[i]?.fullName}}</div>
          <div>{{addSpacesBetweenWords(superStoreGroupAdmins[i]?.roles[0])}}</div>
          <!-- <div class="delete-col"><button (click)="invokeParentMethod()" mat-flat-button color="warn" matTooltip="Delete" matTooltipPosition="left" matTooltipShowDelay="500"><i class="fas fa-times"></i></button></div> -->
          <div *ngIf="permissions.roleAllowsUserToRemoveSSGAFromManagement && showDelete && userHasDeletionPrivilege" class="delete-col">
            <mat-checkbox [formControlName]="i" [disabled]="myUser.hasReadOnlyRestriction"></mat-checkbox>
          </div>
        </div>
      </form>
    </mat-expansion-panel>

    <mat-expansion-panel id="managed-stores-panel" (opened)="managedStoresPanelOpenState = true" (closed)="managedStoresPanelOpenState = false">
      <mat-expansion-panel-header>
        <mat-panel-title>
          Member Stores
        </mat-panel-title>
        <mat-panel-description>
          {{managedStoresDescription}}
        </mat-panel-description>
      </mat-expansion-panel-header>
      <div *ngIf="managedStores && managedStores.length > 0" class="asset-row header">
        <div>Number and Location</div>
        <div>Manager</div>
        <div>Email</div>
        <div *ngIf="permissions.roleAllowsUserToDeleteStore && showDelete && userHasDeletionPrivilege" class="delete-col">
          <button mat-stroked-button color="primary" [matMenuTriggerFor]="menu" [disabled]="myUser.hasReadOnlyRestriction">Actions <mat-icon>expand_more</mat-icon>
            </button>
          <mat-menu #menu="matMenu" xPosition="before">
            <button mat-menu-item style="text-transform: unset !important;" (click)="selectAllManagedStores(true)">Select All
              (Managed Stores)</button>
            <button mat-menu-item style="text-transform: unset !important;" (click)="selectAllManagedStores(false)">Unselect
              All (Managed Stores)</button>
            <button mat-menu-item style="text-transform: unset !important;" (click)="onManagedStoreDeleteClick()">Delete
              Selected (Managed Stores)</button>
          </mat-menu>
        </div>
      </div>
      <form [formGroup]="managedStoreForm">
        <div formArrayName="managedStores" *ngFor="let managedStore of managedStoresFormArray.controls; let i = index"
          class="asset-row">
          <div>{{managedStores[i]?.numberAndLocation}}</div>
          <div>{{managedStores[i]?.manager}}</div>
          <div>{{managedStores[i]?.email}}</div>
          <!-- <div class="delete-col"><button (click)="invokeParentMethod()" mat-flat-button color="warn" matTooltip="Delete" matTooltipPosition="left" matTooltipShowDelay="500"><i class="fas fa-times"></i></button></div> -->
          <div *ngIf="permissions.roleAllowsUserToDeleteStore && showDelete && userHasDeletionPrivilege" class="delete-col">
            <mat-checkbox [formControlName]="i" [disabled]="myUser.hasReadOnlyRestriction"></mat-checkbox>
          </div>
        </div>
      </form>
    </mat-expansion-panel>

  </mat-accordion>
</div>
