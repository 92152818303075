import { Component, OnInit, Input, HostListener } from '@angular/core';
import { AuthenticationService } from '../core/authentication/shared/authentication.service';
import { Router } from '@angular/router'; //needed for routerLinks to work
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { AdminUserDetailDialog } from '../admin/admin-users/admin-user-list/admin-user-list.component';
import { PriceManagementDataService } from '../price-management/price-management-data.service';
import { PriceManagementService } from '../shared/price-management.service';
import { MessageService } from '../shared/message.service';
import { HelpService } from '../shared/help.service';
import { PermissionsService } from '../shared/permissions.service';
import { AdWeekService } from '../shared/ad-week.service';
import { environment } from 'src/environments/environment';
//import { AppConfig } from '../app.config';

@Component({
  selector: 'rpms-app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  //private config = AppConfig.settings;

  adminDetailRef: MatDialogRef<AdminUserDetailDialog>;

  isDevMode = environment.isDevMode;

  accountMenuVisible: boolean = false;
  isUserLoggedIn: boolean;
  myUser: any;
  userRole;
  isHelpfulMode: boolean =  false;
  isMobile: boolean = false;
  permissions: any = {};
  isUserLoggedInSubscription;
  myUserSubscription;
  permissionsSubscription;

  constructor(
    private _authenticationService : AuthenticationService, 
    private _priceManagementDataService: PriceManagementDataService, //so that columnMetadata can be saved before logging out
    private _priceManagementService: PriceManagementService, //so that columnMetadata can be saved before logging out
    private _adWeekService: AdWeekService, //so that columnMetadata can be saved before logging out
    private _messageService: MessageService,
    private _router: Router,
    private _dialog: MatDialog, 
    private _helpService: HelpService,
    private _permissionsService: PermissionsService,
    ) { }

  ngOnInit(){
    //if (this.config.logInits) console.log('header init');
    this.isUserLoggedIn = this._authenticationService.getIsUserLoggedIn();
    this.myUser = this._authenticationService.getMyUser();
    this.userRole = this._authenticationService.getUserRole();
    this.permissions = this._permissionsService.getPermissions();
  
    this.isUserLoggedInSubscription = this._authenticationService.isUserLoggedIn$
      .subscribe((data) => {
        this.isUserLoggedIn = data; 
      }
    ); 
    this.myUserSubscription = this._authenticationService.myUser$
      .subscribe((data) => {
          this.myUser = data; 
          this.userRole = this.addSpacesBetweenWords(data.roles[0]);
      }
    );
    this.permissionsSubscription = this._permissionsService.permissions$
      .subscribe((data) => {
          this.permissions = data; 
      }
    );

    this._helpService.setIsHelpfulMode(this.isHelpfulMode);

    this.isMobile =   // will be true if running on a mobile device
      navigator.userAgent.indexOf( "Mobile" ) !== -1 || 
      navigator.userAgent.indexOf( "iPhone" ) !== -1 || 
      navigator.userAgent.indexOf( "Android" ) !== -1 || 
      navigator.userAgent.indexOf( "Windows Phone" ) !== -1 ;
  }

  ngOnDestroy(){
    this.isUserLoggedInSubscription.unsubscribe();
    this.myUserSubscription.unsubscribe();
    this.permissionsSubscription.unsubscribe();
  }

  @HostListener('document:click', ['$event']) clickout(event) {
    // Click outside of the menu was detected
    this.accountMenuVisible = false;
  }

  onHelpfulModeChange(){
    this.isHelpfulMode = !this.isHelpfulMode;
    this._helpService.setIsHelpfulMode(this.isHelpfulMode);
    var appContent = document.getElementById('app-content');
    if (this.isHelpfulMode){
      appContent.classList.add('helpful-mode');
    }
    else{
      appContent.classList.remove('helpful-mode');
    }
  }

  toggleAccountMenu(event: Event){
    this.accountMenuVisible = !this.accountMenuVisible;
    event.stopPropagation();
  }

  logout(){
    //console.log(this._router.url);
    if (this._router.url === '/price-management'){
      var columnMetadata = this._priceManagementDataService.getColumnMetadata();
      //console.log(columnMetadata);
      this._priceManagementService.updatePricingGridMetadata({ pricingGridMetadata: JSON.stringify(columnMetadata) })
        .subscribe(
          (data) => {
            this._authenticationService.logout();
          },
          (response) => {
            this._messageService.onFailure('Failed to save grid metadata.', response);
          }
        )
    }
    else if (this._router.url === '/ad-week'){
      var columnMetadata = this._adWeekService.getColumnMetadata();
      //console.log(columnMetadata);
      this._adWeekService.updateAdWeekGridMetadata({ adWeekGridMetadata: JSON.stringify(columnMetadata) })
        .subscribe(
          (data) => {
            this._authenticationService.logout();
          },
          (response) => {
            this._messageService.onFailure('Failed to save grid metadata.', response);
          }
        )
    }
    else{
      this._authenticationService.logout();
    }
  }

  //TODO - break this out where the whole Admin module doesn't need to be imported
  goToMyAccount(){
    //this._router.navigate(['/admin/users', 'my-user']);
    this.adminDetailRef = this._dialog.open(AdminUserDetailDialog, {
      width: '850px',
      //height: '600px',
      data: {
        id: this.myUser.userId,
        isMyUser: true,
      }
    });
  }

  addSpacesBetweenWords(v) {
    if (v != null && v != '') {
      var valueNoSpaces = v.replace(/\s/g, '');//first remove all spaces if any
      var valueWithSpaces = valueNoSpaces.replace(/([A-Z])/g, ' $1');
      valueWithSpaces = valueWithSpaces.substr(1); //remove space before first word
      return valueWithSpaces;
    }
  }

  navigateToHome(){
    this._router.navigate(['/home']);
  }

}


