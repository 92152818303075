<div class="delete admin-dialog deletion-history"><div class="detail admin-dialog">
  <h1 mat-dialog-title class="deletion-history-header">{{entityType}} Deletion History</h1>
  <mat-dialog-content>
  <!-- <div class="log-scroller"> -->
    <div class="log-entry col-headers">
      <div class="time">Date, Time</div>
      <div class="type">Asset Type</div>
      <div class="entity">Entity Name</div>
      <div class="description">Description</div>
      <div class="user">Performed By</div>
    </div>
    <div class="log-entry" *ngFor="let logEntry of logEntries">
      <div class="time">{{logEntry.performedAt}}</div>
      <div class="type">{{logEntry.assetType}}</div>
      <div class="entity">{{logEntry.entityName}}</div>
      <div class="description" [innerHTML]="logEntry.deletionDescription"></div>
      <div class="user">{{logEntry.performedByFullName}}</div>
    </div>
  <!-- </div> -->
</mat-dialog-content>  
<mat-dialog-actions class="buttons-wrapper">
  <button mat-raised-button (click)="closeDialog()" type="button">Close</button>
</mat-dialog-actions>

</div>
