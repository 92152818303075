<h1><i class="fa fa-wrench"></i>Application</h1>
<div class="section">
    <h2>Indexed DB</h2>
    <button mat-raised-button color="primary" (click)="showDeleteIndexedDbConfirmation()" type="button">Delete Indexed DB</button>
    <p>This will remove all RPMS data that is stored locally on this computer for this browser.</p>
    <p>A new empty RPMS Indexed DB will be created and pricing data will be saved to it as new store data is loaded from the Price Management page.</p>
</div>
<div class="section" *ngIf="permissions.roleAllowsUserToLogOutInactiveUsers">
    <h2>Inactive Users</h2>
    <button mat-raised-button color="primary" (click)="logOutInactiveUsers()" type="button">Log Out Inactive Users</button>
</div>
