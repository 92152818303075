import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { AppConfig } from '../app.config';

@Injectable({
  providedIn: 'root'
})
export class ReportService {

  //AppConfig needed to be put in the deps:[] in the Error Handler provider in app.module
  //because the report service is used in the Global Error Hanlder which gets initialized before
  //this service (and the rest of the app) has access to the AppConfig settings (baseUrl).
  private _baseUrl = AppConfig.settings.baseUrl;
  //private _baseUrl = environment.baseUrl;
  private _reqHeader = new HttpHeaders({ 'Accept': 'application/json', 'Content-Type': 'application/json' });

  constructor ( 
    private _http: HttpClient,
  ){}

  getWarehouseCostChangesPerStoreAndDateRange(storeId, dateStart, dateEnd) {
    return this._http.get(this._baseUrl + '/api/ReportsAndExports/WarehouseCostChangesPerStoreAndDateRange/' + storeId + '/' + dateStart + '/' + dateEnd, { headers: this._reqHeader });
  }

  posExportByStoreIdDateAndFilter(storeId, weekEndingDateOrNone, filter) {
    return this._http.get(this._baseUrl + '/api/ReportsAndExports/PosExportByStoreIdDateAndFilter/' + storeId + '/' + weekEndingDateOrNone + '/' + filter, { headers: this._reqHeader });
  }

  getWeekEndingDatesForPosExport(filter) {
    return this._http.get(this._baseUrl + '/api/ReportsAndExports/GetWeekEndingDatesForPosExport/' + filter, { headers: this._reqHeader });
  }

  delimitedPosExportByStoreIdDateAndFilter(storeId, weekEndingDateOrNone, filter) {
    return this._http.get(this._baseUrl + '/api/ReportsAndExports/DelimitedPosExportByStoreIdDateAndFilter/' + storeId + '/' + weekEndingDateOrNone + '/' + filter, { headers: this._reqHeader, responseType: 'blob', observe: 'response' });
  }
      // .pipe(
      //   map( (response: any) => {  // NOTE: response is of type SomeType
      //     console.log(response.data);
      //     console.log(response.headers);
      //       // Does something on response.data
      //       // modify the response.data as you see fit.
  
      //       // return the modified data:
      //       return response; // kind of useless
      //   }),
      //   catchError( error => {
      //       return throwError(error);
      //   })
    //); // end of pipe}

  // responseType: 'arraybuffer',
  // // block added by Jack on 2017-02-28
  // transformResponse: function (data, headers) {
  //   function parseFilenameFromHeaders(headers) {
  //     //var hdrs = headers();
  //     var header = headers('content-disposition');
  //     var result = header.split(';')[1].trim().split('=')[1];
  //     return result.replace(/"/g, '');
  //   }
  //   return {
  //     data: data,
  //     filename: parseFilenameFromHeaders(headers)
  //   };
  // },

  getBillingProfitReport(storeId, reportType) {
    return this._http.get(this._baseUrl + '/api/ReportsAndExports/BillingProfitReportByStoreAndReportType/' + storeId + '/' + reportType, { headers: this._reqHeader });
  }
  
  getPriceChangeStats(storeGroupIdOr0, storeIdOr0, usernameOrNone, dateStartOrNone, dateEndOrNone) {
    return this._http.get(this._baseUrl + '/api/ReportsAndExports/PriceChangeStats/' + storeGroupIdOr0 + '/' + storeIdOr0 + '/' + usernameOrNone + '/' + dateStartOrNone + '/' + dateEndOrNone, { headers: this._reqHeader });
  }

  getPreviewPriceChangeStats(storeGroupIdOr0, storeIdOr0, usernameOrNone, dateStartOrNone, dateEndOrNone) {
    return this._http.get(this._baseUrl + '/api/ReportsAndExports/PreviewPriceChangeStats/' + storeGroupIdOr0 + '/' + storeIdOr0 + '/' + usernameOrNone + '/' + dateStartOrNone + '/' + dateEndOrNone, { headers: this._reqHeader });
  }

  getArchivalPreviewPriceChanges(storeGroupIdOr0, storeIdOr0, usernameOrNone, dateStartOrNone, dateEndOrNone, excludeMainframe) {
    return this._http.get(this._baseUrl + '/api/ReportsAndExports/DenormalizedPreviewPriceChanges/' + storeGroupIdOr0 + '/' + storeIdOr0 + '/' + usernameOrNone + '/' + dateStartOrNone + '/' + dateEndOrNone + '/' + excludeMainframe, { headers: this._reqHeader });
  }

  getReportingAudit(storeGroupIdOr0, storeIdOr0, usernameOrNone, dateStartOrNone, dateEndOrNone, reportNameOrNone) {
    return this._http.get(this._baseUrl + '/api/ReportsAndExports/ReportingAudit/' + storeGroupIdOr0 + '/' + storeIdOr0 + '/' + usernameOrNone + '/' + dateStartOrNone + '/' + dateEndOrNone + '/' + reportNameOrNone, { headers: this._reqHeader });
  }

  getLogActionsByDateRange(dateStart, dateEnd) {
    return this._http.get(this._baseUrl + '/api/LogActions/GetLogActionsByDateRange/' + dateStart + '/' + dateEnd, { headers: this._reqHeader });
  }

  getLogActionsByUserIdAndDateRange(userId, dateStart, dateEnd) {
    return this._http.get(this._baseUrl + '/api/LogActions/GetLogActionsByUserIdAndDateRange/' + userId + '/' + dateStart + '/' + dateEnd, { headers: this._reqHeader });
  }

  logClientMessage(msg) {
    return this._http.post(this._baseUrl + '/api/LogActions/LogClientMessage', msg, { headers: this._reqHeader });
  }



}
