import { Component, OnInit } from '@angular/core';
import { ScheduleService } from '../shared/schedule.service';
import { HttpErrorResponse } from '@angular/common/http';
import { MessageService } from '../shared/message.service';
import { NotificationService } from '../shared/notification.service';
import { PriceManagementFilterService } from '../price-management/price-management-filter.service';
import { Router } from '@angular/router';
import { PermissionsService } from '../shared/permissions.service';
import { AuthenticationService } from '../core/authentication/shared/authentication.service';
import * as moment from 'moment';
import { ReportService } from '../shared/report.service';
import { StoreService } from '../shared/store.service';
import { AnnouncementService } from '../shared/announcement.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  schedule;
  weekEndingDateForPosExport;
  previewWindowCountdownTimer = 'calculating...';
  storeItemPricingWindowCountdownTimer = 'calculating...';
  previewPricingWindowOpen: boolean = false;
  storeItemPricingWindowOpen: boolean = false;
  previewTimer;
  storeItemPricingTimer;
  nowCostChangesCount: string = '<i class="fas fa-sync fa-spin"></i>';
  firstTimeBuysCount: string = '<i class="fas fa-sync fa-spin"></i>';
  now: number = Date.now();
  nowCostChangesSubscription;
  firstTimeBuysSubscription;
  permissions: any = {};
  announcements = [];

  constructor(
    private _scheduleService: ScheduleService,
    private _announcementService: AnnouncementService,
    private _messageService: MessageService,
    private _notificationService: NotificationService,
    private _priceManagementFilterService: PriceManagementFilterService,
    private _permissionsService: PermissionsService,
    private _authenticationService: AuthenticationService,
    private _reportService: ReportService,
    private _storeService: StoreService,
    private _router: Router,
    ) { 
      this.permissions = this._permissionsService.getPermissions();
    }

  ngOnInit() {
    this.getActiveAnnouncements();
  }

  getActiveAnnouncements(){
    this._announcementService.getActiveAnnouncements()
      .subscribe(
        (data : any)=>{
          this.announcements = data;
          this.getSchedule();
        },
        (response : HttpErrorResponse)=>{
          this._messageService.onFailure('Failed to get announcements.', response);
        });
  }

  getSchedule() {
    this._scheduleService.getSchedule()
      .subscribe(
        (data : any)=>{
          this.schedule = data;
          this.getPreviewPricingWindowStatus();
          this.getNowCostChangesCount();
          this.getFirstTimeBuysCount();
        },
        (response : HttpErrorResponse)=>{
          this._messageService.onFailure('Failed to get schedule.', response);
        });
  }

  getNowCostChangesCount(){
    this.nowCostChangesSubscription = this._notificationService.getNowCostChangesCount()
      .subscribe(
        (data : any)=>{
          this.nowCostChangesCount = data.nowCostChangesCount;
        },
        (response : HttpErrorResponse)=>{
          this._messageService.onFailure('Failed to get now cost changes.', response);
        })
  }

  getFirstTimeBuysCount(){
    this.firstTimeBuysSubscription = this._notificationService.getFirstTimeBuysCount()
      .subscribe(
        (data : any)=>{
          this.firstTimeBuysCount = data.firstTimeBuysCount;
        },
        (response : HttpErrorResponse)=>{
          this._messageService.onFailure('Failed to get first time buys.', response);
        })
  }

  getPreviewPricingWindowStatus(){
    this._scheduleService.isScheduleWindowOpen('PreviewPricingWindow')
      .subscribe(
        (data : any)=>{
          this.previewPricingWindowOpen = data.value;
          this.previewPricingWindowOpen ? this.startPreviewWindowCountDownTimer(this.schedule.previewPricingWindowCloses) : this.startPreviewWindowCountDownTimer(this.schedule.previewPricingWindowOpens);
          this.getStoreItemPriceChangesWindowStatus();
        },
        (response : HttpErrorResponse)=>{
          this._messageService.onFailure('Failed to get preview pricing window status.', response);
        });
  }

  getStoreItemPriceChangesWindowStatus(){
    this._scheduleService.isScheduleWindowOpen('StoreItemPriceChangesWindow')
      .subscribe(
        (data : any)=>{
          this.storeItemPricingWindowOpen = data.value;
          this.storeItemPricingWindowOpen ? this.startStoreItemPricingWindowCountDownTimer(this.schedule.storeItemPriceChangesWindowCloses) : this.startStoreItemPricingWindowCountDownTimer(this.schedule.storeItemPriceChangesWindowOpens);
        },
        (response : HttpErrorResponse)=>{
          this._messageService.onFailure('Failed to get store item price changes window status.', response);
        });
  }

  startPreviewWindowCountDownTimer(dt) {
    var that = this;
    var end = new Date(dt);

    var _second = 1000;
    var _minute = _second * 60;
    var _hour = _minute * 60;
    var _day = _hour * 24;

    function showRemaining() {
      var now = new Date();
      var nowOffset = now.getTimezoneOffset();
      var endOffset = end.getTimezoneOffset();
      var offset = endOffset - nowOffset;
      now.setMinutes(now.getMinutes() + offset);
      var distance = end.getTime() - now.getTime();
      if (distance < 0) {
        clearInterval(that.previewTimer);
        that.previewWindowCountdownTimer = 'Expired!';
        return;
      }
      var days = Math.floor(distance / _day);
      var hours = Math.floor((distance % _day) / _hour);
      var minutes = Math.floor((distance % _hour) / _minute);
      var seconds = Math.floor((distance % _minute) / _second);
      var result = days + ' days ' + hours + ' hrs ' + minutes + ' mins ' + seconds + ' secs';
      
      that.previewWindowCountdownTimer = result;
      //return result;
    }
    this.previewTimer = setInterval(showRemaining, 1000);
  }

  startStoreItemPricingWindowCountDownTimer(dt) {
    var that = this;
    var end = new Date(dt);

    var _second = 1000;
    var _minute = _second * 60;
    var _hour = _minute * 60;
    var _day = _hour * 24;

    function showRemaining() {
      var now = new Date();
      var nowOffset = now.getTimezoneOffset();
      var endOffset = end.getTimezoneOffset();
      var offset = endOffset - nowOffset;
      now.setMinutes(now.getMinutes() + offset);
      var distance = end.getTime() - now.getTime();
      if (distance < 0) {
        clearInterval(that.storeItemPricingTimer);
        that.storeItemPricingTimer = 'Expired!';
        return;
      }
      var days = Math.floor(distance / _day);
      var hours = Math.floor((distance % _day) / _hour);
      var minutes = Math.floor((distance % _hour) / _minute);
      var seconds = Math.floor((distance % _minute) / _second);
      var result = days + ' days ' + hours + ' hrs ' + minutes + ' mins ' + seconds + ' secs';
      
      that.storeItemPricingWindowCountdownTimer = result;
      //return result;
    }
    this.storeItemPricingTimer = setInterval(showRemaining, 1000);
  }

  goToPriceManagement(itemFilterName, tab, subtab){
    var tabFilterModel = {
      activeTab: tab,
      activeSubTab: subtab,
    }
    this._priceManagementFilterService.setTabModel(tabFilterModel);
    this._priceManagementFilterService.setNameOfItemFilterToApplyAfterLoadingItems(itemFilterName);
    this._router.navigate(['/price-management/']);
  }

  onExportClick(){
    var homeStoreId = this._authenticationService.getMyUser().homeStoreId;
    if (homeStoreId) {
      this._storeService.getStoreById(homeStoreId)
        .subscribe(
          (data : any) => {
            this.getWeekEndingDatesForPosExport(data);
          },
          (response : HttpErrorResponse)=>{
            this._messageService.onFailure('Failed to get store.', response);
          });
    }
    else {
      this._router.navigate(['/reports/cash-register-actual-file-report']);
    }
  }

  getWeekEndingDatesForPosExport(store) {
    this._reportService.getWeekEndingDatesForPosExport('Grocery')
      .subscribe(
        (data: any) => {
          this.weekEndingDateForPosExport = String(moment(data[0]).format("YYYY-MM-DD"))
          this.exportCashRegisterFile(store);
        },
        (response) => {
          this._messageService.onFailure('Failed to get Week Ending Date.', response);
        }
      )
  }

  exportCashRegisterFile(store){
    if (this.permissions.roleAllowsUserToExportCashRegisterFile) {
      this._reportService.delimitedPosExportByStoreIdDateAndFilter(store.storeId, this.weekEndingDateForPosExport, 'Grocery')
        .subscribe(
          (result: any) => {
            //console.log(result)
            //var url = URL.createObjectURL(new Blob([result.data]));
            var url = URL.createObjectURL(result.body);
            var a = document.createElement('a');
            a.href = url;
            //POS_Data_for_Store_006_with_Filter_None_Exported_2018-11-29_131944.csv
            var downloadDateTime = moment().format('YYYY-MM-DD_HHmmss');
            a.download = 'POS_Data_for_Store_' + this.pad(store.storeNumber, 3) + '_with_Filter_Grocery_Exported_' + downloadDateTime + '_Week_Ending_Date_' + this.weekEndingDateForPosExport + '.csv';
            //a.download = result.filename;
            a.target = '_blank';
            a.click();
          },
          (response) => {
            this._messageService.onFailure('Failed to get Cash Register File.', response);
          }
        )
    }
    else {
      this._messageService.alert('Your role does not have permission to export this file.');
    }
  }

  pad(num, size) {
    var s = num+"";
    while (s.length < size) s = "0" + s;
    return s;
  }

  ngOnDestroy(){
    clearInterval(this.previewTimer);
    clearInterval(this.storeItemPricingTimer);
    this.nowCostChangesSubscription.unsubscribe();
    this.firstTimeBuysSubscription.unsubscribe();
  }


}
