import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { AppConfig } from '../app.config';

@Injectable({
  providedIn: 'root'
})
export class AdWeekService {

  private _baseUrl = AppConfig.settings.baseUrl;
  private _reqHeader = new HttpHeaders({ 'Accept': 'application/json', 'Content-Type': 'application/json' });

  columnMetadata;

  constructor(
    private _http: HttpClient, 
  ) { }

  getAllAdWeekItemsByStoreId(storeId) {
    return this._http.get(this._baseUrl + '/api/AdWeek/GetAllAdWeekItemsByStoreId/' + storeId, { headers: this._reqHeader });
  }

  getAllAdWeekItemsByStoreIdAdYearAndAdNumber(storeId, adYear, adNumber, params?) {
    return this._http.get(this._baseUrl + '/api/AdWeek/GetAllAdWeekItemsByStoreIdAdYearAndAdNumber/' + storeId + '/' + adYear + '/' + adNumber, { params: params, headers: this._reqHeader });
  }

  getMostFutureAdItemBreakingDateByStoreId(storeId) {
    return this._http.get(this._baseUrl + '/api/AdWeek/GetMostFutureAdItemBreakingDateByStoreId/' + storeId, { headers: this._reqHeader });
  }

  getQueryableAdWeekAdNumbers() {
    return this._http.get(this._baseUrl + '/api/AdWeek/GetQueryableAdWeekAdNumbers', { headers: this._reqHeader });
  }

  getAdWeekGridMetadata() {
    return this._http.get(this._baseUrl + '/api/AdWeek/GetAdWeekGridMetadata', { headers: this._reqHeader });
  }

  updateAdWeekGridMetadata(adWeekGridMetadata) {
    return this._http.put(this._baseUrl + '/api/AdWeek/UpdateAdWeekGridMetadata', adWeekGridMetadata, { headers: this._reqHeader });
  }

  getColumnMetadata(){
    return this.columnMetadata;
  }

  setColumnMetadata(data){
    this.columnMetadata = data;
  }
}
