import { Component, OnInit, Input, Inject, LOCALE_ID } from '@angular/core';
import { Validators, UntypedFormBuilder } from '@angular/forms';
import { ItemFilterService } from 'src/app/shared/item-filter.service';
import { MatDialogRef } from '@angular/material/dialog';
import { AdminItemFilterDetailDialog } from '../admin-item-filter-list/admin-item-filter-list.component';
import { MessageService } from 'src/app/shared/message.service';
import { AccessService } from 'src/app/shared/access.service';
//import { environment } from 'src/environments/environment';
import { AppConfig } from 'src/app/app.config';
import { AuthenticationService } from 'src/app/core/authentication/shared/authentication.service';

@Component({
  selector: 'rpms-admin-item-filter-detail',
  templateUrl: './admin-item-filter-detail.component.html',
  styleUrls: ['./admin-item-filter-detail.component.css']
})
export class AdminItemFilterDetailComponent implements OnInit {

  @Input() id: string; 
  private config = AppConfig.settings;

  itemFilterId: string;
  mode: string;
  itemFilter;
  modifiedByOptions = ['Everyone', 'AllUsersManagedByMe', 'NoOne'];
  sharedWithOptions = ['Everyone', 'AllUsersManagedByMe', 'NoOne'];
  isItemFilterModifiable: boolean = false;
  canItemFilterSharedWithAndOrModifiedByPropertiesBeChanged: boolean = false;
  myUser;

  itemFilterDetailForm = this._fb.group({
    active: [''],
    name: ['', Validators.required],
    description: [''],
    creator: [''],
    sharedWith: [''],
    modifiedBy: [''],
    itemFilterMetadata: [''],
  });

  constructor(
    private _fb: UntypedFormBuilder,
    private _accessService: AccessService,
    private _itemFilterService: ItemFilterService,
    private _authenticationService: AuthenticationService,
    private _messageService: MessageService,
    public dialogRef: MatDialogRef<AdminItemFilterDetailDialog>,
    @Inject(LOCALE_ID) private locale: string
    ) { }

  ngOnInit() {
    if (this.config.logInits) console.log('init item filter detail');
    this.myUser = this._authenticationService.getMyUser();
    if(this.myUser.hasReadOnlyRestriction) this.itemFilterDetailForm.disable();
    this.itemFilterId = this.id;
    this.mode = this.itemFilterId == '0' ? 'New' : 'Edit';
    if (this.itemFilterId != '0') this.getIsItemFilterModifiable();
  }

  getIsItemFilterModifiable(){
    this._accessService.isItemFilterModifiable(this.itemFilterId)
      .subscribe(
        (data:any) => {
          this.isItemFilterModifiable = data.value;
          if (!this.isItemFilterModifiable) {
            this.itemFilterDetailForm.get('active').disable();
            this.itemFilterDetailForm.get('name').disable();
            this.itemFilterDetailForm.get('description').disable();
            this.itemFilterDetailForm.get('sharedWith').disable();
            this.itemFilterDetailForm.get('modifiedBy').disable();
          }
          //these are always disabled
          this.itemFilterDetailForm.get('creator').disable();
          this.itemFilterDetailForm.get('itemFilterMetadata').disable();
          this.getCanItemFilterSharedWithAndOrModifiedByPropertiesBeChanged();
        },
        (response) => {
          this._messageService.onFailure('Failed to determine if item filter is modifiable.', response);
        }
      )
  }

  getCanItemFilterSharedWithAndOrModifiedByPropertiesBeChanged(){
    this._accessService.canItemFilterSharedWithAndOrModifiedByPropertiesBeChanged(this.itemFilterId)
      .subscribe(
        (data:any) => {
          this.canItemFilterSharedWithAndOrModifiedByPropertiesBeChanged = data.value;
          if (!this.canItemFilterSharedWithAndOrModifiedByPropertiesBeChanged) {
            this.itemFilterDetailForm.get('sharedWith').disable();
            this.itemFilterDetailForm.get('modifiedBy').disable();
          }
          this.getItemFilter();
        },
        (response) => {
          this._messageService.onFailure('Failed to determine if item filter\'s Shared With and Modified By properties can be changed.', response);
        }
      )
  }

  getItemFilter(){
    this._itemFilterService.getItemFilterById(this.itemFilterId)
      .subscribe(
        (data:any) => {
          this.itemFilter = data;
          if (this.config.logIds) console.log(`%citemFilterName: ${data.name}`, 'background: black; color: white; font-weight: bold; font-size: 120%');
          if (this.config.logIds) console.log(`%citemFilterId: ${data.itemFilterId}`, 'background: yellow; font-weight: bold; font-size: 120%');

          this.itemFilterDetailForm.patchValue({
            active: data.active,
            name: data.name,
            description: data.description,
            creator: data.owner.fullNameAndEmail,
            sharedWith: data.sharedWith,
            modifiedBy: data.modifiedBy,
            itemFilterMetadata: JSON.stringify(data.itemFilterMetadata),
          });
          this.itemFilterDetailForm.get('creator').disable();
          this.itemFilterDetailForm.get('itemFilterMetadata').disable();
        },
        (response) => {
          this._messageService.onFailure('Failed to get Item Filter.', response);
        })
  }

  onSubmitItemFilter(){
    //Users with hasReadOnlyRestriction set to true should not even be allowed to invoke this action because it is hidden or disabled in the UI.
    //This is for extra protection. The back end will also be protected.
    if (this.myUser.hasReadOnlyRestriction){
      this._messageService.alertReadOnlyRestriction();
      return;
    }
    
    var itemFilter = this.itemFilterDetailForm.value;
    itemFilter.itemFilterId = this.itemFilterId;
    itemFilter.itemFilterMetadata = JSON.stringify(this.itemFilter.itemFilterMetadata);
    itemFilter.priceManagementTab = this.itemFilter.priceManagementTab;
    itemFilter.priceManagementSubTab = this.itemFilter.priceManagementSubTab;
    if (this.mode === 'New') { //this is not implemented
      this._itemFilterService.createItemFilter(itemFilter)
      .subscribe(
        (data) => {
          this.dialogRef.close('reload');
          this._messageService.onSuccess('Successfully create Item Filter');
        },
        (response) => {
          this._messageService.onFailure('Failed to create Item Filter.', response)
        }
      );
    }
    else {
      this._itemFilterService.updateItemFilter(this.itemFilterId, itemFilter)
      .subscribe(
        (data) => {
          this.dialogRef.close('reload');
          this._messageService.onSuccess('Successfully updated Item Filter');
        },
        (response) => {
          this._messageService.onFailure('Failed to update Item Filter.', response)
        }
      );
    }
  }

  addSpacesBetweenWords(v) {
    if (v != null && v != '') {
      var valueWithSpaces = v.replace(/([A-Z])/g, ' $1');
      valueWithSpaces = valueWithSpaces.substr(1); //remove space before first word
      return valueWithSpaces;
    }
  }

  closeDialog(){
    this.dialogRef.close();
  }

}
