<div class="detail admin-dialog">
<h1 mat-dialog-title>POS Export Settings for<br/>Store {{storeName}}</h1>
<div mat-dialog-content>
  <form [formGroup]="posExportSettingsDetailForm" id="pos-settings-detail-form" (ngSubmit)="onSubmitPosSettings()">
    <div class="checkbox-form-field">
      <mat-checkbox id="qualifyText" formControlName="qualifyText">Qualify Text</mat-checkbox>
      <mat-checkbox id="includeHeaderRow" formControlName="includeHeaderRow">Include Header Row</mat-checkbox>
    </div>
    <div class="row">
      <mat-form-field class="w100">
        <mat-label>Text Qualifier</mat-label>
        <input matInput id="textQualifier" formControlName="textQualifier" placeholder="Text Qualifier" required>
      </mat-form-field>
      <mat-form-field class="w100">
        <mat-label>Delimiter</mat-label>
        <input matInput id="delimiter" formControlName="delimiter" placeholder="Delimiter" required>
      </mat-form-field>
    </div>
    <label class="mat-label">Export Boolean Values As</label>
    <div class="row">
      <mat-radio-group id="booleanValuesAs0Or1" formControlName="booleanValuesAs0Or1">
        <mat-radio-button value="true">1/0</mat-radio-button>
        <mat-radio-button value="false">True/False</mat-radio-button>
      </mat-radio-group>
    </div>
    <label class="mat-label">Export Single Unit Multis (0 or 1) As</label>
    <div class="row">
      <mat-radio-group id="singleUnitMultiFormat" formControlName="singleUnitMultiFormat">
        <mat-radio-button value="0">0</mat-radio-button>
        <mat-radio-button value="1">1</mat-radio-button>
      </mat-radio-group>
    </div>
    <div class="checkbox-form-field">
      <mat-checkbox id="forceWicItemOff" formControlName="forceWicItemOff">Force WIC Item Off</mat-checkbox>
    </div>
    <div class="detail-action-buttons">
      <button mat-raised-button color="primary" [disabled]="!posExportSettingsDetailForm.valid" type="submit">Save</button>
      <button mat-raised-button (click)="closeDialog()" type="button">Cancel</button>
    </div>
  </form>
</div>
</div>
