import { Component, OnInit, ViewChild, Inject, NgZone } from '@angular/core';
import { AgGridAngular } from 'ag-grid-angular';
import { GridOptions } from 'ag-grid-community';
import { UserService } from 'src/app/shared/user.service';
import { GridService } from 'src/app/shared/grid.service';
import { ViewDetailButtonComponent } from 'src/app/shared/view-detail-button/view-detail-button.component';
import { DeleteButtonComponent } from 'src/app/shared/delete-button/delete-button.component';
import { TransferButtonComponent } from 'src/app/shared/transfer-button/transfer-button.component';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { StoreService } from 'src/app/shared/store.service';
import { StoreGroupService } from 'src/app/shared/store-group.service';
import { MessageService } from 'src/app/shared/message.service';
import { PermissionsService } from 'src/app/shared/permissions.service';
import { ConfirmationDialogComponent } from 'src/app/shared/confirmation-dialog/confirmation-dialog.component';
import { AccessService } from 'src/app/shared/access.service';
import { TransferService } from 'src/app/shared/transfer.service';
import { AdminUserDetailComponent } from '../admin-user-detail/admin-user-detail.component';
import { DeletionHistoryDialogComponent } from 'src/app/shared/deletion-history-dialog/deletion-history-dialog.component';
import { AssetsDialogComponent } from 'src/app/shared/assets-dialog/assets-dialog.component';
import { ViewAssetsButtonComponent } from 'src/app/shared/view-assets-button/view-assets-button.component';
import { UserTransferDialogComponent } from '../user-transfer-dialog/user-transfer-dialog.component';
import { AdminActionButtonsComponent } from 'src/app/shared/admin-action-buttons/admin-action-buttons.component';
import { AuthenticationService } from 'src/app/core/authentication/shared/authentication.service';

@Component({
  selector: 'app-admin-user-list',
  templateUrl: './admin-user-list.component.html',
  styleUrls: ['./admin-user-list.component.css'],
})
export class AdminUserListComponent implements OnInit {
  @ViewChild('agGrid') agGrid: AgGridAngular;

  adminDetailRef: MatDialogRef<AdminUserDetailComponent>;
  ownedAssetsRef: MatDialogRef<AssetsDialogComponent>;
  confirmationDialogRef: MatDialogRef<ConfirmationDialogComponent>;
  transferDialogRef: MatDialogRef<UserTransferDialogComponent>;
  deletionHistoryDialogRef: MatDialogRef<DeletionHistoryDialogComponent>;

  gridOptions: GridOptions;
  quickFilterText: string = '';
  context; //required by grid
  //frameworkComponents; //required by grid
  stores;
  storeGroups;
  permissions: any = {};
  users;
  rowData = [];
  myUser;

  getColumnDefs() { 
    return [
      {
        headerName: "",
        field: "action",
        width: 70,
        minWidth: 70,
        maxWidth: 90,
        cellClass: "action-col",
        cellRenderer: this.permissions.roleAllowsUserToEditUser ? "ViewDetailButtonComponent" : null,
        pinned: "left",
        sortable: false,
        filter: false,
        suppressHeaderMenuButton: true,
        suppressNavigable: true,
        hide: !this.permissions.roleAllowsUserToEditUser
      },
      {
        headerName: "User Name",
        field: "userName",
        cellClass: "userName-col",
        filter: "agTextColumnFilter",
        get headerTooltip() { return this.headerName; }
      },
      {
        headerName: "Full Name",
        field: "fullName",
        cellClass: "fullName-col",
        filter: "agTextColumnFilter",
        get headerTooltip() { return this.headerName; }
      },
      {
        headerName: "Role",
        field: "role",
        cellClass: "role-col",
        filter: "agSetColumnFilter",
        get headerTooltip() { return this.headerName; }
      },
      {
        headerName: "Home Store Group",
        field: "homeStoreGroup",
        cellClass: "homeStoreGroup-col",
        filter: "agTextColumnFilter",
        get headerTooltip() { return this.headerName; }
      },
      {
        headerName: "Home Store",
        field: "homeStore",
        cellClass: "homeStore-col",
        filter: "agTextColumnFilter",
        get headerTooltip() { return this.headerName; }
      },
      {
        headerName: "Phone",
        field: "phone",
        cellClass: "phone-col",
        cellRenderer: this._gridService.phoneNumberRenderer,
        filter: "agTextColumnFilter",
        get headerTooltip() { return this.headerName; }
      },
      {
        headerName: "Last Login Date",
        field: "lastLoginDate",
        cellClass: "lastLoginDate-col",
        cellRenderer: this._gridService.dateTimeRenderer,
        filter: 'agDateColumnFilter',
        filterParams: {
          comparator: this._gridService.dateComparator,
          inRangeInclusive: true
        },
        get headerTooltip() { return this.headerName; }
      },
      {
        headerName: "Enabled",
        field: "active",
        width: 80,
        cellClass: ["active-col", "tac"],
        cellRenderer: this._gridService.checkRenderer,
        filter: "agSetColumnFilter",
        filterParams: {
          cellRenderer: this._gridService.checkFilterRenderer,
          values: [true, false],
          newRowsAction: 'keep'
        },
        get headerTooltip() { return this.headerName; }
      },
      {
        headerName: "Deletion Privilege",
        field: "hasDeletionPrivilege",
        width: 80,
        cellClass: ["hasDeletionPrivilege-col", "tac"],
        cellRenderer: this._gridService.checkRenderer,
        filter: "agSetColumnFilter",
        filterParams: {
          cellRenderer: this._gridService.checkFilterRenderer,
          values: [true, false],
          newRowsAction: 'keep'
        },
        get headerTooltip() { return this.headerName; }
      },
      {
        headerName: "Transfer Privilege",
        field: "hasTransferPrivilege",
        width: 80,
        cellClass: ["hasTransferPrivilege-col", "tac"],
        cellRenderer: this._gridService.checkRenderer,
        filter: "agSetColumnFilter",
        filterParams: {
          cellRenderer: this._gridService.checkFilterRenderer,
          values: [true, false],
          newRowsAction: 'keep'
        },
        get headerTooltip() { return this.headerName; }
      },
      {
        headerName: "Actions",
        field: "actions",
        width: 160,
        minWidth: 160,
        maxWidth: 160,
        cellClass: ["assets-col", "tac"],
        cellRenderer: "AdminActionButtonsComponent",
        sortable: false,
        filter: false,
        suppressHeaderMenuButton: true,
        suppressNavigable: true,
      },

      // {
      //   headerName: "Assets",
      //   field: "assets",
      //   width: 80,
      //   minWidth: 80,
      //   maxWidth: 80,
      //   cellClass: ["assets-col", "tac"],
      //   cellRenderer: this.permissions.roleAllowsUserToDeleteUser ? "ViewAssetsButtonComponent" : null,
      //   sortable: false,
      //   filter: false,
      //   suppressHeaderMenuButton: true,
      //   suppressNavigable: true,
      //   hide: !this.permissions.roleAllowsUserToDeleteUser
      // },
      // {
      //   headerName: "Transfer",
      //   field: "transfer",
      //   width: 80,
      //   minWidth: 80,
      //   maxWidth: 80,
      //   cellClass: ["transfer-col", "tac"],
      //   cellRenderer:  this.permissions.roleAllowsUserToTransferUserAssets ? "TransferButtonComponent" : null,
      //   sortable: false,
      //   filter: false,
      //   suppressHeaderMenuButton: true,
      //   suppressNavigable: true,
      //   hide: !this.permissions.roleAllowsUserToTransferUserAssets,
      // },
      // {
      //   headerName: "Delete",
      //   field: "delete",
      //   width: 80,
      //   minWidth: 80,
      //   maxWidth: 80,
      //   cellClass: ["delete-col", "tac"],
      //   cellRenderer:  this.permissions.roleAllowsUserToDeleteUser ? "DeleteButtonComponent" : null,
      //   sortable: false,
      //   filter: false,
      //   suppressHeaderMenuButton: true,
      //   suppressNavigable: true,
      //   hide: !this.permissions.roleAllowsUserToDeleteUser,
      // },

    ];
  }
  
  constructor(
    private _storeService: StoreService,
    private _storeGroupService: StoreGroupService,
    private _userService: UserService,
    private _accessService: AccessService,
    private _transferService: TransferService,
    private _gridService: GridService,
    private _dialog: MatDialog, 
    private _messageService: MessageService,
    private _permissionsService: PermissionsService,
    private _authenticationService: AuthenticationService,
    private ngZone: NgZone,
    ) {
      this.permissions = this._permissionsService.getPermissions();
      this.myUser = this._authenticationService.getMyUser();

      this.gridOptions = <GridOptions> {
			columnDefs: this.getColumnDefs(),
			rowData: this.rowData,
      rowHeight: 35,    
      components: {
        ViewDetailButtonComponent: ViewDetailButtonComponent,
        ViewAssetsButtonComponent: ViewAssetsButtonComponent,
        DeleteButtonComponent: DeleteButtonComponent,
        TransferButtonComponent: TransferButtonComponent,
        AdminActionButtonsComponent: AdminActionButtonsComponent,
      },
      defaultColDef: {
        sortable: true,
        resizable: true,
        floatingFilter: true,
      },
      statusBar: {
        statusPanels: [
          { statusPanel: 'agTotalAndFilteredRowCountComponent', align: 'left' },
        ],
      },

    }
    this.context = { componentParent: this }
   }

  ngOnInit() {
    this.doOnInit();
  }

  doOnInit(){
    this.getAllStores();
  }

  getAllStores(){
    this._storeService.getAllStores()
      .subscribe(
        (data) => {
          this.stores = data;
          this.getStoreGroups();
        },
        (response) => {
          this._messageService.onFailure('Failed to get Stores.', response);
        })
  }

  getStoreGroups(){
    this._storeGroupService.getAllStoreGroups()
      .subscribe(
        (data) => {
          this.storeGroups = data;
          this.getUsers();
        },
        (response) => {
          this._messageService.onFailure('Failed to get Store Groups.', response);
        })
  }

  getUsers(){
    this._userService.getAllManageableUsers()
      .subscribe(
        (data) => {
          this.users = data; //used to pass users to transfer dialog
          this.formatandSetRowData(data);
        },
        (response) => {
          this._messageService.onFailure('Failed to get users.', response);
        })
  }

  formatandSetRowData(data) {
    var gridData = [];
    for (var i=0; i<data.length; i++){
      var singleRowData = {};
      var actionFieldValue = '';
      var deleteFieldValue = '';
      singleRowData = {
        action: actionFieldValue,
        userId: data[i].userId,
        userName: data[i].email,
        fullName: data[i].fullName,
        role: this._gridService.addSpacesBetweenWords(data[i].roles[0]),
        homeStoreGroup: this.getHomeStoreGroupFromId(data[i].homeStoreGroupId),
        homeStoreGroupId: data[i].homeStoreGroupId,
        homeStore: this.getHomeStoreFromId(data[i].homeStoreId),
        homeStoreId: data[i].homeStoreId,
        phone: data[i].phoneNumber,
        lastLoginDate: data[i].lastLoginDate,
        active: data[i].active,
        hasDeletionPrivilege: data[i].hasDeletionPrivilege,
        hasTransferPrivilege: data[i].hasTransferPrivilege,
        delete: deleteFieldValue,
        permissions: { //used to show or hide action buttons
          roleAllowsUserToViewAssets: this.permissions.roleAllowsUserToViewUserAssets, 
          roleAllowsUserToTransferAssets: this.permissions.roleAllowsUserToTransferUserAssets, 
          roleAllowsUserToDelete: this.permissions.roleAllowsUserToDeleteUser,
          userHasReadOnlyRestriction: this.myUser.hasReadOnlyRestriction,
        },

      };
      gridData.push(singleRowData);
    }
    this.rowData = gridData;
    this._gridService.onWindowResize('#ag-grid-wrapper');
    setTimeout(() => {
      this.fitColumns();
      this._gridService.sizeGrid('#ag-grid-wrapper');
    }, 100); 
  }

  getHomeStoreFromId(id) {
    for (var i = 0; i < this.stores.length; i++) {
      if (this.stores[i].storeId === id) return this.stores[i].numberAndLocation;
    }
    return '';
  }

  getHomeStoreGroupFromId(id) {
    for (var i = 0; i < this.storeGroups.length; i++) {
      if (this.storeGroups[i].storeGroupId === id) return this.storeGroups[i].name;
    }
    return '';
  }

  clearFilters(){
    this.quickFilterText = '';
    this.agGrid.api.setGridOption('quickFilterText', this.quickFilterText);
    this.agGrid.api.setFilterModel(null);
    this.agGrid.api.onFilterChanged();
  }

  resetColumns(){
    this.agGrid.api.resetColumnState();
    this.fitColumns(); //since this is done by default also
  }

  autoSizeColumns(){
    var columns = this.agGrid.api.getColumns();
    this.agGrid.api.autoSizeColumns(columns);
  }

  fitColumns(){
    this.agGrid.api.sizeColumnsToFit();
  }

  onQuickFilterChange(){
    this.agGrid.api.setGridOption('quickFilterText', this.quickFilterText);
  }

  onDetailButtonClick(params){
    //this._router.navigate(['/admin/users', params.data.userId]);
    this.adminDetailRef = this._dialog.open(AdminUserDetailComponent, {
      width: '90vw',
      maxWidth: '1000px',
      maxHeight: '90vh',
      data: {
        id: params.data.userId,
        params: params,
        users: this.users,
        rowData: params.data,
        entityType: 'User',
        userId: params.data.userId,
      }
    });
    const subscribeDialog = this.adminDetailRef.componentInstance.onOpenTransferDialog.subscribe((params) => {
      this.onTransferButtonClick(params)
    });

    // this.adminDetailRef.beforeClosed().subscribe(result => {
    //   console.log(this.adminDetailRef);
    //   let wasTransferMade = this.adminDetailRef.componentInstance.wasTransferMade;
    //   if (wasTransferMade) this.getAllStores();
    //   this.adminDetailRef = null;
    // });

    this.adminDetailRef.afterClosed().subscribe(reload => {
      if(reload) {
        this.doOnInit();
      }
      subscribeDialog.unsubscribe();
      this.adminDetailRef = null;
    });

  }

  onAssetButtonClick(params){
    this.ownedAssetsRef = this._dialog.open(AssetsDialogComponent, {
      width: '90vw',
      maxWidth: '1000px',
      maxHeight: '90vh',
      data: {
        rowData: params.data,
        params: params,
        entityName: params.data.userName,
        entityType: 'User',
        userId: params.data.userId,
      }
    });
    const subscribeDialog = this.ownedAssetsRef.componentInstance.onOpenTransferDialog.subscribe((params) => {
      this.onTransferButtonClick(params)
    });
    this.ownedAssetsRef.afterClosed().subscribe(reload => {
      if(reload) {
        this.doOnInit();
      }
      subscribeDialog.unsubscribe();
      this.ownedAssetsRef = null;
    });
  }

  onTransferButtonClick(params) {
    this.openTransferDialog(params);

    //this call may no longer be needed since the user transfer dialog allows moving user to new store even without assets
    //https://stackoverflow.com/questions/41990171/angular-2-not-re-rendering-until-i-click-the-page
    // this.ngZone.run(() => { //need this because dialog contents only showed up after a mouse click
    //   this._transferService.doesUserHaveTransferableAssets(paramsRowData.userId)
    //     .subscribe(
    //       (data: any) => { //returns boolean
    //         if (data) { 
    //           this.openTransferDialog(paramsRowData); 
    //         }
    //         else{
    //           this._messageService.alert('This user does not have any transferable assets.');
    //         }
    //       },
    //       (response) => {
    //         this._messageService.onFailure('Failed to determine if this user has transferable assets.', response);
    //       }
    //     )
    // });
  }

  openTransferDialog(params) {
    this.transferDialogRef = this._dialog.open(UserTransferDialogComponent, {
      //disableClose: false,
      width: '90vw',
      maxWidth: '1000px',
      maxHeight: '90vh',
      data: {
        entityType: 'User',
        entityName: params.data.userName,
        donatingEntityId: params.data.userId,
        rowData: params.data,
        users: this.users,
        storeGroups: this.storeGroups,
        storeGroupId: params.data.homeStoreGroupId,
        homeStoreId: params.data.homeStoreId,
        userId: params.data.userId,
      }
    })
    this.transferDialogRef.afterClosed().subscribe(result => {
      if (result){
        this.doOnInit();
      }
      this.transferDialogRef = null;
    });

  }

  onDeleteButtonClick(params){
    this.ngZone.run(() => {
      if (this.myUser.hasDeletionPrivilege) {
        this._accessService.isForcedDeletionRequiredForUser(params.data.userId)
          .subscribe(
            (data: any) => {
              this.confirmDeletion(params, data.forcedDeletionRequired, data.reason);
            },
            (response) => {
              this._messageService.onFailure('Failed to determine if forced deletion is required.', response);
            }
          )
        }
        else {
          this._messageService.alert('You are not authorized to delete users. If you believe you should have that privilege, contact an administrator.');
        }
    });
  }

  confirmDeletion(params, isForcedDeletionRequiredForUser, reason){
    //if (reason) reason = reason.replace(/\r\n/g, '<br/>').replace(/\.\.\./g, '<span class="indenter">&bull;</span>');
    if (reason) reason = reason.replace(/\r\n/g, '<br/>')
    .replace(/\u00b9/g, '<span class="indent-1"></span>')
    .replace(/\u00b2/g, '<span class="indent-2"></span>')
    .replace(/\u00b3/g, '<span class="indent-3"></span>')
    .replace(/\u2074/g, '<span class="indent-4"></span>')
    .replace(/\u2075/g, '<span class="indent-5"></span>')
    .replace(/\u2076/g, '<span class="indent-6"></span>')
    .replace(/\u2077/g, '<span class="indent-7"></span>')
    .replace(/\u2078/g, '<span class="indent-8"></span>');
    let msg = isForcedDeletionRequiredForUser ? reason : 
      '"Forced Deletion" is not required for this user. Do you want to delete ' + params.data.userName + ' (' + params.data.fullName +')?';
    this.confirmationDialogRef = this._dialog.open(ConfirmationDialogComponent, {
      disableClose: false,
      //width: '600px',
      maxHeight: '80vh',
      data: {
        title: 'Are you sure?',
        message: msg,
        confirmText: 'Yes, delete user',
        cancelText: 'No, cancel this action!',
        thirdButtonText: isForcedDeletionRequiredForUser ? 'Open Transfer Dialog' : undefined,
      }
    });
    this.confirmationDialogRef.afterClosed().subscribe(result => {
      if (result == 'thirdAction'){
        this.openTransferDialog(params);
      }
      else if(result) {
        if (isForcedDeletionRequiredForUser) this.deleteUser(params, true);
        else this.deleteUser(params, false);
      }
      this.confirmationDialogRef = null;
    });
  }

  onViewDeletionHistoryButtonClick() {
    this.deletionHistoryDialogRef = this._dialog.open(DeletionHistoryDialogComponent, {
      disableClose: false,
      width: '90vw',
      maxWidth: '1500px',
      maxHeight: '90vh',
      data: {
        entityType: 'User'
      }
    })
  }

  deleteUser(params, forceDisassociationOrDeletionOfDependencies){
    //Users with hasReadOnlyRestriction set to true should not even be allowed to invoke this action because it is hidden or disabled in the UI.
    //This is for extra protection. The back end will also be protected.
    if (this.myUser.hasReadOnlyRestriction){
      this._messageService.alertReadOnlyRestriction();
      return;
    }

    this._userService.deleteUser(params.data.userId, forceDisassociationOrDeletionOfDependencies) 
    .subscribe(
      (data:any) => {
        this._messageService.onSuccess('Successfully deleted user');
        this.getUsers();
      },
      (response) => {
        this._messageService.onFailure('Failed to delete user.', response);
      })
  }

  goToCreateUser(){
    //this._router.navigate(['/admin/users', 0]);
    this.adminDetailRef = this._dialog.open(AdminUserDetailComponent, {
      width: '850px',
      //height: '700px',
      data: {
        id: '0'
      }
    });
    this.adminDetailRef.afterClosed().subscribe(result => {
      if(result === 'reload') {
        this.getAllStores();
      }
      this.adminDetailRef = null;
    });
  }

  goToMyUser(){
    //this._router.navigate(['/admin/users', 'my-user']);
    this.adminDetailRef = this._dialog.open(AdminUserDetailComponent, {
      width: '850px',
      //height: '600px',
      data: {
        id: this.myUser.userId,
        isMyUser: true,
      }
    });
    this.adminDetailRef.afterClosed().subscribe(result => {
      if(result === 'reload') {
        this.getAllStores();
      }
      this.adminDetailRef = null;
    });
  }

}

@Component({
  selector: 'app-admin-detail-dialog',
  template: `<rpms-admin-user-detail [id]="data.id"></rpms-admin-user-detail>`,
})
export class AdminUserDetailDialog {

  constructor(
    public dialogRef: MatDialogRef<AdminUserDetailDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any) {}

  onNoClick(): void {
    this.dialogRef.close();
  }

}
