import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { AppConfig } from '../app.config';

@Injectable({
  providedIn: 'root'
})
export class PrivateLabelsService {

  private _baseUrl = AppConfig.settings.baseUrl;
  private _reqHeader = new HttpHeaders({ 'Accept': 'application/json', 'Content-Type': 'application/json' });

  constructor ( 
    private _http: HttpClient, 
  ){}

  getAllPrivateLabels(params?) {
    return this._http.get(this._baseUrl + '/api/PrivateLabels/GetAllPrivateLabels', { params: params, headers: this._reqHeader });
  }

  getActivePrivateLabels(params?) {
    return this._http.get(this._baseUrl + '/api/PrivateLabels/GetActivePrivateLabels', { params: params, headers: this._reqHeader });
  }

  getPrivateLabelById(privateLabelId) {
    return this._http.get(this._baseUrl + '/api/PrivateLabels/GetPrivateLabelById/' + privateLabelId, { headers: this._reqHeader });
  }

  createPrivateLabel(privateLabel) {
    return this._http.post(this._baseUrl + '/api/PrivateLabels/CreatePrivateLabel', privateLabel, { headers: this._reqHeader });
  }

  updatePrivateLabel(privateLabelId, privateLabel) {
    return this._http.put(this._baseUrl + '/api/PrivateLabels/UpdatePrivateLabel/' + privateLabelId, privateLabel, { headers: this._reqHeader });
  }

  deletePrivateLabel(privateLabelId) {
    return this._http.delete(this._baseUrl + '/api/PrivateLabels/DeletePrivateLabel/' + privateLabelId, { headers: this._reqHeader });
  }
}
