import { Injectable } from '@angular/core';
//import * as $ from 'jquery';
import * as moment from 'moment';
import { MessageService } from './message.service';

@Injectable({
  providedIn: 'root'
})
export class GridService {

  constructor( 
    private _messageService: MessageService
   ) { 
    //GAIR-105. This is so I can call the convertFeatureToText function from the featureRenderer function. Both functions are in this GridService.
    //I separated those 2 functions out so that the convertFeatureToText function can also be used by the Ad week export to format that column
    this.featureRenderer = this.featureRenderer.bind(this);
   }

  sizeGrid(selector, bottomPadding?) {
    var bottomPadding = bottomPadding ? bottomPadding : 45;
    var el = document.querySelector(selector);
    var windowHeight = window.innerHeight;
    if (document.body.contains(el)){
      var gridOffset = el.getBoundingClientRect().top //el.offsetTop;
      var remainingSpace = windowHeight - gridOffset - bottomPadding;
      el.style.height = remainingSpace + 'px';
    }
  }
  sizeGridToRows(selector, numberOfRows, rowHeight) {
    var tableHeight = 0;
    var id = selector.substring(1);
    var el = document.getElementById(id);
    if (document.body.contains(el)){
      tableHeight = numberOfRows * rowHeight + 100; //100 is for header
      el.style.height = tableHeight + 'px';
    }
  }

  onWindowResize(id, bottomPadding?) {
    var that = this;
    var rtime;
    var timeout = false;
    var delta = 200;
    window.onresize = function () {
      rtime = new Date();
      if (timeout === false) {
        timeout = true;
        setTimeout(resizeend, delta);
      }
    };

    function resizeend() {
      if (new Date().getTime() - rtime < delta) {
        setTimeout(resizeend, delta);
      } else {
        timeout = false;
        //done resizing
        that.sizeGrid(id, bottomPadding);
      }
    }
  }

  addSpacesBetweenWords(v) {
    if (v != null && v != '') {
      var valueWithSpaces = v.replace(/([A-Z])/g, ' $1');
      valueWithSpaces = valueWithSpaces.substr(1); //remove space before first word
      return valueWithSpaces;
    }
  }

  checkRenderer(params) {
    var eCell;
    if (params.value != null) {
      eCell = document.createElement('span');
      if (params.value == true) {
        eCell.innerHTML = '<i class="fa fa-check"></i>';
      }
    }
    else {
      eCell = document.createElement('span');
      eCell.innerHTML = '';
    }
    return eCell;
  }

  checkFilterRenderer(params) {
    if (params.value != null) {
      var eCell = document.createElement('span');
      if (params.value == 'true') {
        eCell.innerHTML = 'True';
      }
      else {
        eCell.innerHTML = 'False';
      }
    }
    return eCell;
  }

  currencyRenderer(params) {
    var eCell = document.createElement('span');
    var value = !isNaN(params.value) ? document.createTextNode(Number(params.value).toFixed(2)) : document.createTextNode('');
    eCell.appendChild(value);
    return eCell;
  }

  currencyRendererOrEmpty(params) {
    var eCell = document.createElement('span');
    var value = !isNaN(params.value) && params.value !== null ? document.createTextNode(Number(params.value).toFixed(2)) : document.createTextNode('');
    eCell.appendChild(value);
    return eCell;
  }

  dateRenderer(params) {
    var eCell = document.createElement('span');
    params.value != null && params.value != '' ? eCell.innerHTML = moment(params.value).format("M/D/YYYY") : eCell.innerHTML = '';
    if (eCell.innerHTML == '1/1/1900') eCell.innerHTML = 'All Past Dates';
    else if (eCell.innerHTML == '12/31/9999') eCell.innerHTML = 'All Future Dates';
    return eCell;
  }

  dateTimeRenderer(params) {
    var eCell = document.createElement('span');
    params.value != null && params.value != '' ? eCell.innerHTML = moment(params.value).format("M/D/YYYY, h:mm a") : eCell.innerHTML = '';
    return eCell;
  }

  htmlRenderer(params) {
    var eCell = document.createElement('span');
    params.value != null ? eCell.innerHTML = params.value : eCell.innerHTML = '';
    return eCell;
  }

  itemCodeRenderer(params) {
    var width = 6;
    var z = z || '0';
    var n = params.value + '';
    return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
  }

  percentRenderer(params) {
    var eCell = document.createElement('span');
    var value = !isNaN(params.value) ? document.createTextNode(Number(params.value).toFixed(2) + ' %') : document.createTextNode('');
    eCell.appendChild(value);
    return eCell;
  }

  phoneNumberRenderer(params) {
    var eCell = document.createElement('span');
    if (params.value) params.value.length == 10 ? eCell.innerHTML = '(' + params.value.substring(0, 3) + ') ' + params.value.substring(3, 6) + '-' + params.value.substring(6) : eCell.innerHTML = params.value;
    return eCell;
  }

  dateComparator(filterLocalDateAtMidnight, cellValue) {
    if (cellValue != null && cellValue != '') {
      var cellDate = new Date(cellValue).setHours(0, 0, 0, 0);

      // Now that both parameters are Date objects, we can compare
      if (cellDate < filterLocalDateAtMidnight) {
        return -1;
      } else if (cellDate > filterLocalDateAtMidnight) {
        return 1;
      } else {
        return 0;
      }
    }
  }

  //https://github.com/ag-grid/ag-grid/issues/1795
  caseInsensitiveTextComparator(a, b) {
    if (typeof a === 'string') {
      return a.localeCompare(b);
    } else {
      return (a > b ? 1 : (a < b ? -1 : 0));
    }
  } 

  privateLabelRenderer(params) {
    var self = params.context.componentParent;
    var label = '';
    var eCell = document.createElement('span');
    for (var i = 0; i < self.privateLabels.length; i++) {
      if (params.value == self.privateLabels[i].letterCode) {
        label = self.privateLabels[i].label;
        break;
      }
    }
    if (params.value == ' ') {
      label = params.hasOwnProperty('data') ? '' : 'None';
    }
    eCell.innerHTML = label;
    return eCell;
  }

  privateLabelFilterRenderer(params) {
    var label = '';
    var eCell = document.createElement('span');
    if (params.api){
      var self = params.api.gridOptionsWrapper.gridOptions.context.componentParent;
      for (var i = 0; i < self.privateLabels.length; i++) {
        if (params.value == self.privateLabels[i].letterCode) {
          label = self.privateLabels[i].label;
          break;
        }
      }
      if (params.value == ' ') {
        label = params.hasOwnProperty('data') ? '' : 'None';
      }
    }
    eCell.innerHTML = label;
    return eCell;
  }

  newItemRenderer(params) {
    var eCell = document.createElement('span');
    eCell.innerHTML = params.data.newItem ? '<img src="/assets/images/new.png"/>' : '';
    return eCell;
  }

  firstTimeBuyRenderer(params) {
    var eCell = document.createElement('span');
    eCell.innerHTML = params.data.firstTimeBuy ? '<img src="/assets/images/first-time-buy.png"/>' : '';
    return eCell;
  }

  wicItemRenderer(params) {
    var eCell = document.createElement('span');
    eCell.innerHTML = params.data.wicItem ? '<img src="/assets/images/wic.png"/>' : '';
    return eCell;
  }

  nowItemRenderer(params) {
    var eCell = document.createElement('span');
    eCell.innerHTML = params.data.nowItem ? '<img src="/assets/images/now.png"/>' : '';
    return eCell;
  }

  wicChangeRenderer(params) {
    var eCell = document.createElement('span');
    eCell.className = 'change-render wic-change-render';
    eCell.innerHTML = params.data.hadWicStateChange ? 'WIC Change' : '';
    return eCell;
  }

  priceChangeRenderer(params) {
    var eCell = document.createElement('span');
    eCell.className = 'change-render price-change-render';
    eCell.innerHTML = params.data.hadPriceChange ? 'Price Change' : '';
    return eCell;
  }

  costChangeRenderer(params) {
    var eCell = document.createElement('span');
    eCell.className = 'change-render cost-change-render';
    eCell.innerHTML = params.data.hadCostChange ? 'Cost Change' : '';
    return eCell;
  }

  upcChangeRenderer(params) {
    var eCell = document.createElement('span');
    eCell.className = 'change-render upc-change-render';
    eCell.innerHTML = params.data.hadPreviousUpcCode ? 'UPC Change' : '';
    return eCell;
  }

  sizeChangeRenderer(params) {
    var eCell = document.createElement('span');
    eCell.className = 'change-render size-change-render';
    eCell.innerHTML = params.data.hadSizeChange ? 'Size Change' : '';
    return eCell;
  }

  shortDescriptionChangeRenderer(params) {
    var eCell = document.createElement('span');
    eCell.className = 'change-render description-change-render';
    eCell.innerHTML = params.data.hadShortDescriptionChange ? 'Desc. Change' : '';
    return eCell;
  }

  checkboxRenderer(params) {
    var eCell = document.createElement('span');
    var eCheckbox = document.createElement('input');
    eCheckbox.type = "checkbox";
    eCell.appendChild(eCheckbox);
    let priceManagementComponent = params.context.componentParent;
    let isItemReadOnly = priceManagementComponent.checkItemForReadOnly(params.data, params);
    eCheckbox.disabled = isItemReadOnly;
    eCheckbox.checked = isItemReadOnly ? false : params.value;
    params.data[params.colDef.field] = isItemReadOnly ? false : params.value;
    eCheckbox.addEventListener('change', function () {
      var newValue = eCheckbox.checked;
      params.data[params.colDef.field] = newValue;
      params.value = newValue;
      //console.log(params.data[params.colDef.field]);
    });
    return eCell;
  }

  masterCheckboxRenderer(params) {
    var eCell = document.createElement('span');
    var eCheckbox = document.createElement('input');
    eCheckbox.type = "checkbox";
    eCell.appendChild(eCheckbox);
    let priceManagementComponent = params.context.componentParent;
    let isItemReadOnly = priceManagementComponent.checkItemForReadOnly(params.data, params);
    eCheckbox.disabled = isItemReadOnly;
    eCheckbox.checked = isItemReadOnly ? false : params.value;
    params.data[params.colDef.field] = isItemReadOnly ? false : params.value;
    
    //console.log(params);
    params.eGridCell.addEventListener('keyup', function (e) {
      console.log(e);
      if (e.key.toLowerCase() == 't' || e.key == '1'){
        eCheckbox.checked = true;
        params.setValue(true);
      }
      else if (e.key.toLowerCase() == 'f' || e.key == '0'){
        eCheckbox.checked = false;
        params.setValue(false);
      }
    });
    
    eCheckbox.addEventListener('change', function () {
      var newValue = eCheckbox.checked;
      params.data[params.colDef.field] = newValue;
      params.value = newValue;
      params.setValue(newValue);
      //console.log(params);
      //console.log(params.data[params.colDef.field]);
      let d = params.api.getSelectedRows()[0];
      //console.log(d.masterChangeCheckbox);
    });
    return eCell;
  }

  featureRenderer(params) {
    var eCell = document.createElement('span');
    if (params.value != null) {
      eCell.innerHTML = this.convertFeatureToText(params.value)
    }
    return eCell;
  }

  //used in ad week column renderer AND ad week export
  convertFeatureToText(val){
    let textVal = '';
    switch (String(val)) {
      case "1":
        textVal = 'Feature Item';
        break;
      case "2":
        textVal = 'Sub-Feature Item';
        break;
      case "3":
        textVal = 'Line Item';
        break;
      case "4":
        textVal = 'TPR';
        break;
      default:
        textVal = String(val);
        break;
    }
    return textVal;
  }

  // dateFilter(filterObj, gridOptions) {
  //   if (gridOptions.api != null) {
  //     var colid = filterObj.closest('.ag-header-cell').attr("colid");
  //     var value = filterObj.val();
  //     var valDate = '';

  //     var filterApi = gridOptions.api.getFilterInstance(colid);
  //     var valueArray = [];
  //     var i, val;

  //     filterApi.selectNothing();

  //     if (value == "") {
  //       filterApi.selectEverything();
  //     }
  //     else {
  //       if (value.indexOf("/") != -1) {  //if date
  //         valueArray = value.split("/");
  //         for (i = 0; i < valueArray.length; i++) {
  //           if (valueArray[i].length == 1) {
  //             valueArray[i] = "0" + valueArray[i]; //add leading zeros
  //           }
  //         }
  //         if (valueArray.length == 3) {
  //           valDate = valueArray[2] + '-' + valueArray[0] + '-' + valueArray[1];
  //         }
  //         else if (valueArray.length == 2) {
  //           valDate = valueArray[0] + '-' + valueArray[1];
  //         }
  //         else {
  //           valDate = 'xxxxx'; //select nothing if extra slashes
  //         }
  //         for (i = 0; i < filterApi.getUniqueValueCount(); i++) {
  //           val = filterApi.getUniqueValue(i);
  //           if (val != null) {
  //             if (val.indexOf(valDate) != -1) {
  //               filterApi.selectValue(val);
  //             }
  //           }
  //         }
  //       }
  //       else {   //else if contains (as string)
  //         for (i = 0; i < filterApi.getUniqueValueCount(); i++) {
  //           val = filterApi.getUniqueValue(i);
  //           if (val != null) {
  //             var valLowerCase = val.toLowerCase();
  //             var valueLowerCase = value.toLowerCase();
  //             if (valLowerCase.indexOf(valueLowerCase) != -1) {
  //               filterApi.selectValue(val);
  //             }
  //           }
  //         }
  //       }
  //     }
  //     gridOptions.api.onFilterChanged();
  //   }
  // }

  // numberFilter(filterObj, gridOptions) {

  //   var colid = filterObj.closest('.ag-header-cell').attr("colid");
  //   var value = filterObj.val();

  //   var filterApi = gridOptions.api.getFilterInstance(colid);
  //   var valueArray = [];
  //   var i, val;
  //   filterApi.selectNothing();

  //   if (value == "") {
  //     filterApi.selectEverything();
  //   }
  //   else {
  //     if (value.indexOf(":") != -1) {  //if range (as number)
  //       valueArray = value.split(":");
  //       for (i = 0; i < filterApi.getUniqueValueCount(); i++) {
  //         val = filterApi.getUniqueValue(i);
  //         if (val != null) {
  //           val = val * 1;
  //           if (val >= valueArray[0] * 1 && val <= valueArray[1] * 1) {
  //             filterApi.selectValue(val);
  //           }
  //         }
  //       }
  //     }
  //     else if (value.indexOf("0") == 0 && value.length == 1) { //select only zeros
  //       for (i = 0; i < filterApi.getUniqueValueCount(); i++) {
  //         val = filterApi.getUniqueValue(i);
  //         if (val != null) {
  //           if (val == 0) {
  //             filterApi.selectValue(val);
  //           }
  //         }
  //       }
  //     }
  //     else {   //else if contains (as string)
  //       for (i = 0; i < filterApi.getUniqueValueCount(); i++) {
  //         val = filterApi.getUniqueValue(i);
  //         if (val != null) {
  //           var valLowerCase = val.toLowerCase();
  //           var valueLowerCase = value.toLowerCase();
  //           if (valLowerCase.indexOf(valueLowerCase) != -1) {
  //             filterApi.selectValue(val);
  //           }
  //         }
  //       }
  //     }
  //   }

  //   gridOptions.api.onFilterChanged();

  // }

  // currencyFilter(filterObj, gridOptions) {

  //   var colid = filterObj.closest('.ag-header-cell').attr("colid");
  //   var value = filterObj.val();

  //   var filterApi = gridOptions.api.getFilterInstance(colid);
  //   var valueArray = [];
  //   var i, val;
  //   filterApi.selectNothing();

  //   if (value == "") {
  //     filterApi.selectEverything();
  //   }
  //   else {
  //     if (value.indexOf(":") != -1) {  //if range (as number)
  //       valueArray = value.split(":");
  //       for (i = 0; i < filterApi.getUniqueValueCount(); i++) {
  //         val = filterApi.getUniqueValue(i);
  //         if (val != null) {
  //           val = val * 1;
  //           if (val >= valueArray[0] * 1 && val <= valueArray[1] * 1) {
  //             filterApi.selectValue(val);
  //           }
  //         }
  //       }
  //     }
  //     else if (value.indexOf("0") == 0 && value.length == 1) { //select only zeros
  //       for (i = 0; i < filterApi.getUniqueValueCount(); i++) {
  //         val = filterApi.getUniqueValue(i);
  //         if (val != null) {
  //           if (val == 0) {
  //             filterApi.selectValue(val);
  //           }
  //         }
  //       }
  //     }
  //     else {   //else if contains (as string) works with currency fixed 2 decimals
  //       for (i = 0; i < filterApi.getUniqueValueCount(); i++) {
  //         val = filterApi.getUniqueValue(i);
  //         if (val != null) {
  //           var valFixed = String(Number(val).toFixed(2));
  //           if (valFixed.indexOf(value) != -1) {
  //             filterApi.selectValue(val);
  //           }
  //         }
  //       }
  //     }
  //   }

  //   gridOptions.api.onFilterChanged();

  // }

  //!///////////////////////////////
  // Cell Editors
  //////////////////////////////////

  getBookCellEditor(self) {

    function bookCellEditor() { }

    bookCellEditor.prototype.init = function (params) {
      // create the cell
      this.eInput = document.createElement('select');
      this.eInput.id = "bookCell";
      params.eGridCell.id = "bookCellWrapper";
      //Create array of options to be added
      var array = ["1", "2", "3", "4", "5", "6", "7", "C", "D"];

      //Create and append the options
      for (var i = 0; i < array.length; i++) {
        var option = document.createElement("option");
        option.value = array[i];
        option.text = array[i];
        this.eInput.appendChild(option);
      }

      this.eInput.value = params.value;
      this.focusAfterAttached = params.cellStartedEdit;

      if (params.eventKey != '' && params.eventKey != null && array.indexOf(params.eventKey)) {
        this.eInput.value = params.eventKey.toUpperCase();
        onValueChanged(params.eventKey);
      }

      this.eInput.addEventListener('change', function (e) {
        onValueChanged(e.target.value);
      });

      this.eInput.addEventListener('keydown', function (e) {
        if (e.keyCode === 13) {
          setTimeout(function() { 
            self.agGrid.api.clearRangeSelection();
            self.agGrid.api.setFocusedCell(params.rowIndex + 1, 'book', null);
          }, 500);
        }

        if (e.keyCode === 39) {
          e.preventDefault();
          e.stopPropagation();
          self.agGrid.api.tabToNextCell();
        }

        if (e.keyCode === 37) {
          e.preventDefault();
          e.stopPropagation();
          self.agGrid.api.tabToPreviousCell();
        }
      });

      function onValueChanged(val) {
        setTimeout(()=>{ //let all inputs be rendered first
          self.changeType = 'book';
          self.valueInRowChanged = true;
          if (val == 'C') {
            var srpCodeCell = <HTMLInputElement>document.getElementById('srpCodeCell');
            if (srpCodeCell) srpCodeCell.value = '';
            self.changeType = 'price';
          }

          //GAIR-118
          //Only run this if NOT a test store (this was the existing way, before GAIR-117 backend changes)
          let pmComponent = params.context.componentParent;
          let selectedStoreNumber = pmComponent.store.storeNumber;

          if (!pmComponent.testStoreNumbers.includes(selectedStoreNumber)) {
            if (val == 'D') {
              var srpCodeCell = <HTMLInputElement>document.getElementById('srpCodeCell');
              if (srpCodeCell) srpCodeCell.value = '';
            }
          }
          //end GAIR-118 block

          var keepSrpCell = <HTMLInputElement>document.getElementById('keepSrpCell');
          if (keepSrpCell) keepSrpCell.checked = false;
          var keepPercentCell = <HTMLInputElement>document.getElementById('keepPercentCell');
          if (keepPercentCell) keepPercentCell.checked = false;
          self.handleAppearanceOfChangeType(self.changeType);
        }, 10);
      }

    };

    // gets called once when grid ready to insert the element
    bookCellEditor.prototype.getGui = function () {
      return this.eInput;
    };

    // focus and select can be done after the gui is attached
    bookCellEditor.prototype.afterGuiAttached = function () {
      if (this.focusAfterAttached) {
        this.eInput.focus();
      }
    };

    // when we tab onto this editor, we want to focus the contents
    bookCellEditor.prototype.focusIn = function () {
      var eInput = this.getGui();
      eInput.focus();
    };

    // when we tab out of the editor, this gets called
    //bookCellEditor.prototype.focusOut = function () {
    //};

    // returns the new value after editing
    bookCellEditor.prototype.getValue = function (params) {
      return this.eInput.value;
    };
    return bookCellEditor;
  }

  getSrpCodeCellEditor(self) {

    function srpCodeCellEditor() { }

    srpCodeCellEditor.prototype.init = function (params) {
      // create the cell
      this.eInput = document.createElement('select');
      this.eInput.id = "srpCodeCell";
      params.eGridCell.id = "srpCodeCellWrapper";
      //Create array of options to be added
			var array = ["A", "B", "C", "H", "1", "2", "3", "4", "5", "6", "7", "8", "9"];

      //Create and append the options
      for (var i = 0; i < array.length; i++) {
        var option = document.createElement("option");
        option.value = array[i];
        option.text = array[i];
        this.eInput.appendChild(option);
      }
      this.eInput.value = params.value;
      this.focusAfterAttached = params.cellStartedEdit;

      if (params.eventKey != '' && params.eventKey != null && array.indexOf(params.eventKey)) {
        this.eInput.value = params.eventKey.toUpperCase();
        onValueChanged(params.eventKey);
      }

      this.eInput.addEventListener('change', function (e) {
        onValueChanged(e.target.value);
      });

      this.eInput.addEventListener('keydown', function (e) {
        if (e.keyCode === 13) {
          setTimeout(function() { 
            self.agGrid.api.clearRangeSelection();
            self.agGrid.api.setFocusedCell(params.rowIndex + 1, 'srpCode', null);
          }, 500);
        }

        if (e.keyCode === 39) {
          e.preventDefault();
          e.stopPropagation();
          self.agGrid.api.tabToNextCell();
        }

        if (e.keyCode === 37) {
          e.preventDefault();
          e.stopPropagation();
          self.agGrid.api.tabToPreviousCell();
        }
      });

      function onValueChanged(val) {
        setTimeout(()=>{ //let all inputs be rendered first
          self.changeType = 'book';
          self.valueInRowChanged = true;
          var keepSrpCell = <HTMLInputElement>document.getElementById('keepSrpCell');
          if (keepSrpCell) keepSrpCell.checked = false;
          var keepPercentCell = <HTMLInputElement>document.getElementById('keepPercentCell');
          if (keepPercentCell) keepPercentCell.checked = false;
          self.handleAppearanceOfChangeType(self.changeType);
        }, 10);
      }

    };

    // gets called once when grid ready to insert the element
    srpCodeCellEditor.prototype.getGui = function () {
      return this.eInput;
    };

    // focus and select can be done after the gui is attached
    srpCodeCellEditor.prototype.afterGuiAttached = function () {
      if (this.focusAfterAttached) {
        this.eInput.focus();
      }
    };

    // when we tab onto this editor, we want to focus the contents
    srpCodeCellEditor.prototype.focusIn = function () {
      var eInput = this.getGui();
      eInput.focus();
    };

    // when we tab out of the editor, this gets called
    //srpCodeCellEditor.prototype.focusOut = function () {
    //};

    // returns the new value after editing
    srpCodeCellEditor.prototype.getValue = function (params) {
      return this.eInput.value;
    };
    return srpCodeCellEditor;
  }

  getMultiCellEditor(self) {

    let gridService = this;

		function multiCellEditor() { }

		multiCellEditor.prototype.init = function (params) {
			// create the cell
			this.eInput = document.createElement('input');
			this.eInput.value = params.value;
			this.eInput.type = "number";
			this.eInput.step = "1";
			this.eInput.min = "0";
			this.eInput.max = "99";
			this.eInput.id = "multiCell";
      params.eGridCell.id = "multiCellWrapper";
      this.focusAfterAttached = params.cellStartedEdit;

      if (params.eventKey != '' && params.eventKey != null) {
        this.eInput.value = params.eventKey;
        onValueChanged(params.eventKey);
      }

			this.eInput.addEventListener('input', function (e) {
        onValueChanged(e.target.value);
      });

      this.eInput.addEventListener('keydown', function (e) {
        if (e.keyCode === 13) {
          setTimeout(function() { 
            self.agGrid.api.clearRangeSelection();
            self.agGrid.api.setFocusedCell(params.rowIndex + 1, 'multi', null);
          }, 500);
        }

        if (e.keyCode === 39) {
          e.preventDefault();
          e.stopPropagation();
          self.agGrid.api.tabToNextCell();
        }

        if (e.keyCode === 37) {
          e.preventDefault();
          e.stopPropagation();
          self.agGrid.api.tabToPreviousCell();
        }

      });

      function onValueChanged(val) {
        setTimeout(()=>{ //let all inputs be rendered first
          self.changeType = 'price';
          self.valueInRowChanged = true;
          var bookCell = <HTMLInputElement>document.getElementById('bookCell');
          var srpCodeCell = <HTMLInputElement>document.getElementById('srpCodeCell');
          if (bookCell) bookCell.value = 'C';
          if (srpCodeCell) srpCodeCell.value = '';
          self.handleAppearanceOfChangeType('price');
          gridService.onMultiChange(val, params.data);
        }, 10);
      }

		};

		// gets called once when grid ready to insert the element
		multiCellEditor.prototype.getGui = function () {
			return this.eInput;
		};

		// focus and select can be done after the gui is attached
		multiCellEditor.prototype.afterGuiAttached = function () {
			if (this.focusAfterAttached) {
				this.eInput.focus();
			}
		};

		// when we tab onto this editor, we want to focus the contents
		multiCellEditor.prototype.focusIn = function () {
			var eInput = this.getGui();
			eInput.focus();
			eInput.select();
		};

		// when we tab out of the editor, this gets called
		//multiCellEditor.prototype.focusOut = function () {
		//};

		// returns the new value after editing
		multiCellEditor.prototype.getValue = function () {
      //with AG Grid v32 data types must be correct
			return Number(this.eInput.value);
		};
    return multiCellEditor;
  }

  getPriceCellEditor(self) {

    let gridService = this;

		function priceCellEditor() { }

		priceCellEditor.prototype.init = function (params) {
			// create the cell
			this.eInput = document.createElement('input');
			this.eInput.value = Number(params.value).toFixed(2);
			this.eInput.type = "number";
			this.eInput.step = ".01";
			this.eInput.min = "0";
			this.eInput.max = "999.99";
			this.eInput.id = "priceCell";
      params.eGridCell.id = "priceCellWrapper";
      this.focusAfterAttached = params.cellStartedEdit;

      var that = this; //scope that to this

      if (params.eventKey != '' && params.eventKey != null) {
        this.eInput.value = params.eventKey;
        onValueChanged(params.eventKey);
      }

			this.eInput.addEventListener('input', function (e) {
        onValueChanged(e.target.value);
      });
      
      this.eInput.addEventListener('blur', function (e) {
				that.eInput.value = Number(that.eInput.value).toFixed(2);
      });

      this.eInput.addEventListener('keydown', function (e) {
        if (e.keyCode === 13) {
          setTimeout(function() { 
            self.agGrid.api.clearRangeSelection();
            self.agGrid.api.setFocusedCell(params.rowIndex + 1, 'price', null);
          }, 500);
        }

        if (e.keyCode === 39) {
          e.preventDefault();
          e.stopPropagation();
          self.agGrid.api.tabToNextCell();
        }

        if (e.keyCode === 37) {
          e.preventDefault();
          e.stopPropagation();
          self.agGrid.api.tabToPreviousCell();
        }

      });

      function onValueChanged(val) {
        setTimeout(()=>{ //let all inputs be rendered first
          self.changeType = 'price';
          self.valueInRowChanged = true;
          var bookCell = <HTMLInputElement>document.getElementById('bookCell');
          var srpCodeCell = <HTMLInputElement>document.getElementById('srpCodeCell');
          if (bookCell) bookCell.value = 'C';
          if (srpCodeCell) srpCodeCell.value = '';
          self.handleAppearanceOfChangeType('price');
          gridService.onPriceChange(val, params.data);
        }, 10);
      }

		};

		// gets called once when grid ready to insert the element
		priceCellEditor.prototype.getGui = function () {
			return this.eInput;
		};

		// focus and select can be done after the gui is attached
		priceCellEditor.prototype.afterGuiAttached = function () {
			if (this.focusAfterAttached) {
				this.eInput.focus();
				//this.eInput.select(); //not needed if setting first character as value
			}
		};

		// when we tab onto this editor, we want to focus the contents
		priceCellEditor.prototype.focusIn = function () {
			var eInput = this.getGui();
			eInput.focus();
			eInput.select();
		};

		// when we tab out of the editor, this gets called
		//priceCellEditor.prototype.focusOut = function () {
		//};

		// returns the new value after editing
		priceCellEditor.prototype.getValue = function () {
			return Number(this.eInput.value);
		};
    return priceCellEditor;
  }

  getKeepSrpCellEditor(self) {

		function keepSrpCellEditor() { }

		keepSrpCellEditor.prototype.init = function (params) {
			this.eInput = document.createElement('input');
			this.eInput.id = "keepSrpCell";
      params.eGridCell.id = "keepSrpCellWrapper";
			this.eInput.type = "checkbox";
			this.eInput.checked = params.value;
			this.focusAfterAttached = params.cellStartedEdit;
      //console.log(params);

      if (params.eventKey != '' && params.eventKey != null) {
        if (params.eventKey.toLowerCase() == 't' || params.eventKey == '1'){
          this.eInput.checked = true;
          onValueChanged();
        }
        else if (params.eventKey.toLowerCase() == 'f' || params.eventKey == '0'){
          this.eInput.checked = false;
          onValueChanged();
        }
      }

      this.eInput.addEventListener('click', function (e) {
        //don't let single click event to propagate to pricePercentCellRenderer or else it will undo the checkbox change
        e.stopPropagation();
      });

			this.eInput.addEventListener('change', function (e) {
        onValueChanged();
      });

      this.eInput.addEventListener('keydown', function (e) {
        if (e.keyCode === 84 || e.keyCode === 49 || e.keyCode === 97) { //t,T,1 or keypad 1
          e.target.checked = true;
          onValueChanged();
        }

        if (e.keyCode === 70 || e.keyCode === 48 || e.keyCode === 96) { //f,F,0 or keypad 0
          e.target.checked = false;
          onValueChanged();
        }

        if (e.keyCode === 13) {
          setTimeout(function() { 
            self.agGrid.api.clearRangeSelection();
            self.agGrid.api.setFocusedCell(params.rowIndex + 1, 'keepSrp', null);
          }, 500);
        }

        if (e.keyCode === 39) {
          e.preventDefault();
          e.stopPropagation();
          self.agGrid.api.tabToNextCell();
        }

        if (e.keyCode === 37) {
          e.preventDefault();
          e.stopPropagation();
          self.agGrid.api.tabToPreviousCell();
        }

      });

      function onValueChanged() {
        setTimeout(()=>{ //let all inputs be rendered first

          // //GAIR-118 block A
          // //Only run this if a test store
          // let pmComponent = params.context.componentParent;
          // let selectedStoreNumber = pmComponent.store.storeNumber;

          // if (pmComponent.testStoreNumbers.includes(selectedStoreNumber)) {
          //   //If nothing else on the row has been changed, this will be a keepOnly change
          //   if (!self.valueInRowChanged) {
          //     self.changeType = 'keepOnly';
          //   }
          // }
          // //end GAIR-118 block A

          //GAIR-140 block A
          //Run for all stores
          let pmComponent = params.context.componentParent;
          let selectedStoreNumber = pmComponent.store.storeNumber;
          let isCondition7Enabled = pmComponent.config.isCondition7Enabled;
      
          //If nothing else on the row has been changed, this will be a keepOnly change
          if (!self.valueInRowChanged) {
            self.changeType = 'keepOnly';
          }
          //end GAIR-140 block A

          self.valueInRowChanged = true; //this always stays (not part of GAIR-118)

          // //GAIR-118 block B
          // //Only run this if NOT a test store (this was the existing way, before GAIR-117 backend changes)
          // if (!pmComponent.testStoreNumbers.includes(selectedStoreNumber)) {
          //   //if there is a book value, send price change rather than keepOnly change
          //   var bookCell = <HTMLInputElement>document.getElementById('bookCell');
          //   var srpCodeCell = <HTMLInputElement>document.getElementById('srpCodeCell');
          //   if (bookCell && bookCell.value != 'C') {
          //     self.changeType = 'price';
          //     bookCell.value = 'C';
          //     if (srpCodeCell) srpCodeCell.value = null;
          //     self.handleAppearanceOfChangeType('price');
          //   }
          // }
          // //end GAIR-118 block B

          //GAIR-140 block B
          var bookCell = <HTMLInputElement>document.getElementById('bookCell');
          let isBookSet = bookCell && bookCell.value != 'C';
          //Only run this if NOT a test store and Book is set and Condition7 is not enabled
          //This just highlights the price cells to show that it will be sent as a price change
          //The sending as a price change logic is in the sendPricePercentOrBookChange function in price management component line 4592
          if (!pmComponent.testStoreNumbers.includes(selectedStoreNumber) && (isBookSet && !isCondition7Enabled)) {
            self.handleAppearanceOfChangeType('price');
          }
          //end GAIR-140 block B

          var keepPercentCell = <HTMLInputElement>document.getElementById('keepPercentCell');
          if (keepPercentCell) keepPercentCell.checked = false;
        }, 10);
      }

		};

		// gets called once when grid ready to insert the element
		keepSrpCellEditor.prototype.getGui = function () {
			return this.eInput;
		};

		// focus and select can be done after the gui is attached
		keepSrpCellEditor.prototype.afterGuiAttached = function () {
			if (this.focusAfterAttached) {
				this.eInput.focus();
				this.eInput.select();
			}
		};

		// when we tab onto this editor, we want to focus the contents
		keepSrpCellEditor.prototype.focusIn = function () {
			var eInput = this.getGui();
			eInput.focus();
		};

		// when we tab out of the editor, this gets called
		//keepSrpCellEditor.prototype.focusOut = function () {
		//};

		// returns the new value after editing
		keepSrpCellEditor.prototype.getValue = function (params) {
			return this.eInput.checked;
		};
    return keepSrpCellEditor;
  }

  getPercentCellEditor(self) {

    let gridService = this;

		function percentCellEditor() { }

		percentCellEditor.prototype.init = function (params) {
			// create the cell
			this.eInput = document.createElement('input');
			this.eInput.type = "number";
			this.eInput.step = "1";
			this.eInput.min = "-99";
			this.eInput.max = "99";
      this.eInput.id = "percentCell";
      this.eInput.value = params.value;

      params.eGridCell.id = "percentCellWrapper";
			this.focusAfterAttached = params.cellStartedEdit;

      //disable percent if item is on deal?  look at code before 10/2022

      if (params.eventKey != '' && params.eventKey != null) {
        this.eInput.value = params.eventKey;
        onValueChanged(params.eventKey);
      }

			this.eInput.addEventListener('input', function (e) {
        onValueChanged(e.target.value);
      });

      this.eInput.addEventListener('keydown', function (e) {
        if (e.keyCode === 13) {
          setTimeout(function() { 
            self.agGrid.api.clearRangeSelection();
            self.agGrid.api.setFocusedCell(params.rowIndex + 1, 'percent', null);
          }, 500);
        }

        if (e.keyCode === 39) {
          e.preventDefault();
          e.stopPropagation();
          self.agGrid.api.tabToNextCell();
        }

        if (e.keyCode === 37) {
          e.preventDefault();
          e.stopPropagation();
          self.agGrid.api.tabToPreviousCell();
        }

      });

      function onValueChanged(val) {
        setTimeout(()=>{ //let all inputs be rendered first
          self.changeType = 'percent';
          self.valueInRowChanged = true;
          var bookCell = <HTMLInputElement>document.getElementById('bookCell');
          var srpCodeCell = <HTMLInputElement>document.getElementById('srpCodeCell');
          if (bookCell) bookCell.value = 'C';
          if (srpCodeCell) srpCodeCell.value = '';
          self.handleAppearanceOfChangeType('percent');
          gridService.onPercentChange(val, params.data);
        }, 10);
      }

		};

		// gets called once when grid ready to insert the element
		percentCellEditor.prototype.getGui = function () {
			return this.eInput;
		};

		// focus and select can be done after the gui is attached
		percentCellEditor.prototype.afterGuiAttached = function () {
			if (this.focusAfterAttached) {
				this.eInput.focus();
				//this.eInput.select();
			}
		};

		// when we tab onto this editor, we want to focus the contents
		percentCellEditor.prototype.focusIn = function () {
			var eInput = this.getGui();
			eInput.focus();
			eInput.select();
		};

		// when we tab out of the editor, this gets called
		// percentCellEditor.prototype.focusOut = function () {
		// };

		// returns the new value after editing
		percentCellEditor.prototype.getValue = function () {
			return Number(this.eInput.value);
		};
    return percentCellEditor;
  }

  getKeepPercentCellEditor(self) {

		function keepPercentCellEditor() { }

		keepPercentCellEditor.prototype.init = function (params) {
			// create the cell
			this.eInput = document.createElement('input');
			this.eInput.id = "keepPercentCell";
      params.eGridCell.id = "keepPercentCellWrapper";
			this.eInput.type = "checkbox";
			this.eInput.checked = params.value;

      //disable percent if item is on deal?  look at code before 10/2022

      this.focusAfterAttached = params.cellStartedEdit;

      if (params.eventKey != '' && params.eventKey != null) {
        //console.log(params.eventKey)
        if (params.eventKey.toLowerCase() == 't' || params.eventKey == '1'){
          this.eInput.checked = true;
          onValueChanged();
        }
        else if (params.eventKey.toLowerCase() == 'f' || params.eventKey == '0'){
          this.eInput.checked = false;
          onValueChanged();
        }
      }

      this.eInput.addEventListener('click', function (e) {
        //don't let single click event to propagate to pricePercentCellRenderer or else it will undo the checkbox change
        e.stopPropagation();
      });

			this.eInput.addEventListener('change', function (e) {
        onValueChanged();
      });

      this.eInput.addEventListener('keydown', function (e) {
        if (e.keyCode === 84 || e.keyCode === 49 || e.keyCode === 97) { //t,T,1 or keypad 1
          e.target.checked = true;
          onValueChanged();
        }

        if (e.keyCode === 70 || e.keyCode === 48 || e.keyCode === 96) { //f,F,0 or keypad 0
          e.target.checked = false;
          onValueChanged();
        }

        if (e.keyCode === 13) {
          setTimeout(function() { 
            self.agGrid.api.clearRangeSelection();
            self.agGrid.api.setFocusedCell(params.rowIndex + 1, 'keepPercent', null);
          }, 500);
        }

        if (e.keyCode === 39) {
          e.preventDefault();
          e.stopPropagation();
          self.agGrid.api.tabToNextCell();
        }
        
        if (e.keyCode === 37) {
          e.preventDefault();
          e.stopPropagation();
          self.agGrid.api.tabToPreviousCell();
        }

      });

      function onValueChanged() {
        setTimeout(()=>{ //let all inputs be rendered first

          // //GAIR-118 block A
          // //Only run this if a test store
          // let pmComponent = params.context.componentParent;
          // let selectedStoreNumber = pmComponent.store.storeNumber;

          // if (pmComponent.testStoreNumbers.includes(selectedStoreNumber)) {
          //   //If nothing else on the row has been changed, this will be a keepOnly change
          //   if (!self.valueInRowChanged) {
          //     self.changeType = 'keepOnly';
          //   }
          // }
          // //end GAIR-118 block A

          //GAIR-140 block A
          //Only run this if a test store
          let pmComponent = params.context.componentParent;
          let selectedStoreNumber = pmComponent.store.storeNumber;
          let isCondition8Enabled = pmComponent.config.isCondition8Enabled;

          //If nothing else on the row has been changed, this will be a keepOnly change
          if (!self.valueInRowChanged) {
            self.changeType = 'keepOnly';
          }
          //end GAIR-140 block A

          self.valueInRowChanged = true;//this always stays (not part of GAIR-118)

          // //GAIR-118 block B
          // //Only run this if NOT a test store (this was the existing way, before GAIR-117 backend changes)
          // if (!pmComponent.testStoreNumbers.includes(selectedStoreNumber)) {
          //   //if there is a book value, send price change rather than keepOnly change
          //   //RG and JL think sending this as a price change rather than a percent change is the intended behavior
          //   var bookCell = <HTMLInputElement>document.getElementById('bookCell');
          //   var srpCodeCell = <HTMLInputElement>document.getElementById('srpCodeCell');
          //   if (bookCell && bookCell.value != 'C') {
          //     self.changeType = 'price';
          //     bookCell.value = 'C';
          //     if (srpCodeCell) srpCodeCell.value = null;
          //     self.handleAppearanceOfChangeType('price');
          //   }
          // }
          // //end GAIR-118 block B

          //GAIR-140 block B
          var bookCell = <HTMLInputElement>document.getElementById('bookCell');
          let isBookSet = bookCell && bookCell.value != 'C';
          //Only run this if NOT a test store and Book is set and Condition8 is not enabled
          //This just highlights the price cells to show that it will be sent as a price change
          //The sending as a price change logic is in the sendPricePercentOrBookChange function in price management component line 4592
          if (!pmComponent.testStoreNumbers.includes(selectedStoreNumber) && (isBookSet && !isCondition8Enabled)) {
            self.handleAppearanceOfChangeType('price');
          }
          //end GAIR-140 block B

          var keepSrpCell = <HTMLInputElement>document.getElementById('keepSrpCell');
          if (keepSrpCell) keepSrpCell.checked = false;
        }, 10);
      }

		};

		// gets called once when grid ready to insert the element
		keepPercentCellEditor.prototype.getGui = function () {
			return this.eInput;
		};

		// focus and select can be done after the gui is attached
		keepPercentCellEditor.prototype.afterGuiAttached = function () {
			if (this.focusAfterAttached) {
				this.eInput.focus();
				this.eInput.select();
			}
		};

		// when we tab onto this editor, we want to focus the contents
		keepPercentCellEditor.prototype.focusIn = function () {
			var eInput = this.getGui();
			eInput.focus();
		};

		// when we tab out of the editor, this gets called
		//keepPercentCellEditor.prototype.focusOut = function () {
		//};

		// returns the new value after editing
		keepPercentCellEditor.prototype.getValue = function (params) {
			return this.eInput.checked;
		};
    return keepPercentCellEditor;
  }


  //!///////////////////////////////
  // Custom Filters
  //////////////////////////////////

  getCustomItemListFilter(self) {
    //var self = this;

    function CustomItemListFilter() {
    }

    CustomItemListFilter.prototype.init = function (params) {
      this.valueGetter = params.valueGetter;
      this.filterSelectValue = '';
      this.setupGui(params);
      this.params = params;
    };

    // not called by ag-Grid, just for us to help setup
    CustomItemListFilter.prototype.setupGui = function (params) {
      var itemListOptions = '';
      for (var i = 0; i < self.itemLists.length; i++) {
        itemListOptions += '<option value="' + self.itemLists[i].itemListId + '">' + self.itemLists[i].fullName + '</option>';
      }
      this.gui = document.createElement('div');
      this.gui.innerHTML =
        '<div style="padding: 4px;">' +
        '<div><select id="filterSelect">' +
        '<option value="">Choose Item List</option>' +
        itemListOptions +
        '</select></div > ' +
        '<div>' +
        '</div>';

      this.eFilterSelect = this.gui.querySelector('#filterSelect');
      this.eFilterSelect.addEventListener("change", listener);

      var that = this;

      function listener(event) {
        that.filterSelectValue = event.target.value;
        params.filterChangedCallback();
      }

    };

    CustomItemListFilter.prototype.getGui = function () {
      return this.gui;
    };

    CustomItemListFilter.prototype.doesFilterPass = function (params) {
      var valueGetter = this.valueGetter;
      var value = valueGetter(params);
      for (var i = 0; i < value.length; i++) {
        if (value[i].itemListId == this.filterSelectValue) {
          return true;
        }
      }
      return false;
    };

    CustomItemListFilter.prototype.isFilterActive = function () {
      var isActive = this.filterSelectValue !== '';
      return isActive;
    };

    CustomItemListFilter.prototype.getApi = function () {
      var that = this;
      return {
        getModel: function () {
          var model = { value: that.eFilterSelect.value, name: that.eFilterSelect.text };
          return model;
        },
        setModel: function (model) {
          that.eFilterSelect.value = model.value;
          that.eFilterSelect.name = model.name;
        }
      };
    };

    CustomItemListFilter.prototype.getModel = function () {
      var model = this.eFilterSelect.value !== '' ? { value: this.eFilterSelect.value, name: this.eFilterSelect[this.eFilterSelect.selectedIndex].text } : '';
      return model;
    };

    CustomItemListFilter.prototype.setModel = function (model) {
      if (model) {
        this.eFilterSelect.value = model.value;
        this.eFilterSelect.name = model.name;
        this.filterSelectValue = this.eFilterSelect.value;
      }
      else {
        this.eFilterSelect.value = '';
        this.filterSelectValue = '';
      }
    };

    CustomItemListFilter.prototype.onFloatingFilterChanged = function (floatingFilterObj) {
       //this.eFilterSelect.value = floatingFilterObj.value;
       this.setModel(floatingFilterObj);
       this.params.filterChangedCallback();
    }

    return CustomItemListFilter;
  }

  getCustomItemListFloatingFilter(self) {
    //var self = this;

    function CustomItemListFloatingFilter() {
    }

    CustomItemListFloatingFilter.prototype.init = function (params) {
      this.onFloatingFilterChanged = params.onFloatingFilterChanged;
      var itemListOptions = '';
      for (var i = 0; i < self.itemLists.length; i++) {
        itemListOptions += '<option value="' + self.itemLists[i].itemListId + '">' + self.itemLists[i].fullName + '</option>';
      }
      this.eGui = document.createElement('div');
      this.eGui.classList.add('ag-floating-filter-input');

      this.eGui.innerHTML = 
      `<div role="presentation" ref="eFloatingFilterInput" class="ag-labeled ag-label-align-left ag-text-field ag-input-field">
        <div ref="eLabel" class="ag-input-field-label ag-label ag-hidden ag-text-field-label"></div>
        <div ref="eWrapper" class="ag-wrapper ag-input-wrapper ag-text-field-input-wrapper" role="presentation">
          <select id="filterSelect" style="width:100%; height:23px;" class="ag-input-field-input ag-text-field-input">
            <option value="">Choose Item List</option>
            ${itemListOptions}
          </select>
        </div>
      </div>`;
      
      this.currentValue = null;
      this.eFilterSelect = this.eGui.querySelector('#filterSelect');
      var that = this;
      function onInputBoxChanged() {
        if (that.eFilterSelect.value === '') {
          //Remove the filter
          //that.onFloatingFilterChanged(null);
          params.parentFilterInstance((instance) => {
            instance.onFloatingFilterChanged(null);
          });
          return;
        }
        that.currentValue = that.eFilterSelect.value;
        //that.onFloatingFilterChanged({ value: that.currentValue, name: '' });
        params.parentFilterInstance((instance) => {
          instance.onFloatingFilterChanged({ value: that.currentValue, name: '' });
        });
      }
      this.eFilterSelect.addEventListener('change', onInputBoxChanged);
    };

    CustomItemListFloatingFilter.prototype.onParentModelChanged = function (parentModel) {
      // When the filter is empty we will receive a null message here
      if (!parentModel) {
        this.eFilterSelect.value = '';
      } else {
        this.eFilterSelect.value = parentModel.value;
      }
    };

    CustomItemListFloatingFilter.prototype.getGui = function () {
      return this.eGui;
    };

    return CustomItemListFloatingFilter;
  }



  /**********************************************************************************************************************/
  //Custom Text Filter

  //https://www.ag-grid.com/javascript-grid-floating-filter-component/
  //https://www.ag-grid.com/javascript-grid-filter-component/

  getCustomTextFilter() {
    function CustomTextFilter() {
    }

    CustomTextFilter.prototype.init = function (params) {
      this.valueGetter = params.valueGetter;
      this.filterText = null;
      //default option is passed from filterParams on column definition
      this.filterSelect = params.defaultOption ? params.defaultOption : 'contains';
      //this.filterType = 'getCustomTextFilter()';
      this.setupGui(params);
      this.params = params;
    };

    // not called by ag-Grid, just for us to help setup
    CustomTextFilter.prototype.setupGui = function (params) {
      this.gui = document.createElement('div');
      this.gui.innerHTML =
        '<div style="padding: 4px;">' +
        '<div><select id="filterSelect">' +
        '<option value="listOr">CSV List (Or)</option>' +
        '<option value="listAnd">CSV List (And)</option>' +
        '<option value="listStartsWith">CSV List (Starts with)</option>' +
        '<option value="equals">Equals</option>' +
        '<option value="notEqual">Not equal</option>' +
        '<option value="startsWith">Starts with</option>' +
        '<option value="endsWith">Ends with</option>' +
        '<option value="contains">Contains</option>' +
        '<option value="notContains">Not contains</option>' +
        '<option value="regex">Regular expression</option>' +
        '</select></div > ' +
        '<div><input type="text" id="filterText" placeholder="Filter..."/></div>' +
        '</div>';

      this.eFilterText = this.gui.querySelector('#filterText');
      this.eFilterText.addEventListener("changed", listener);
      this.eFilterText.addEventListener("paste", listener);
      this.eFilterText.addEventListener("input", listener);
      // IE doesn't fire changed for special keys (eg delete, backspace), so need to
      // listen for this further ones
      //this.eFilterText.addEventListener("keydown", listener);
      this.eFilterText.addEventListener("keyup", listener);

      this.eFilterSelect = this.gui.querySelector('#filterSelect');
      this.eFilterSelect.addEventListener("change", listener2);
      this.eFilterSelect.addEventListener("paste", listener2);
      this.eFilterSelect.addEventListener("input", listener2);

      var that = this;

      function listener(event) {
        that.filterText = event.target.value;
        that.filterSelect = that.eFilterSelect.options[that.eFilterSelect.selectedIndex].value;
        params.filterChangedCallback();
      }

      function listener2(event) {
        that.filterText = that.eFilterText.value;
        that.filterSelect = event.target.value;
        params.filterChangedCallback();
      }

    };

    CustomTextFilter.prototype.getGui = function () {
      return this.gui;
    };

    CustomTextFilter.prototype.doesFilterPass = function (params) {
      var valueGetter = this.valueGetter;
      var value = valueGetter(params);
      var filterTextNoSpaces = this.filterText.replace(/\s*,\s*/g, ",");
      var passed;
      switch (this.filterSelect) {
        case 'listOr':
          passed = false;
          filterTextNoSpaces.toLowerCase().split(",").forEach(function (filterWord) {
            if (value.toString().toLowerCase().indexOf(filterWord) >= 0) {
              passed = true;
            }
          });
          return passed;

        case 'listAnd':
          passed = true;
          filterTextNoSpaces.toLowerCase().split(",").forEach(function (filterWord) {
            if (value.toString().toLowerCase().indexOf(filterWord) === -1) {
              passed = false;
            }
          });
          return passed;

        case 'listStartsWith':
          passed = false;
          filterTextNoSpaces.toLowerCase().split(",").forEach(function (filterWord) {
            if (value.toString().toLowerCase().indexOf(filterWord) === 0) {
              passed = true;
            }
          });
          return passed;

        case 'equals':
          return (value.toString().toLowerCase() === this.filterText.toLowerCase());

        case 'notEqual':
          return (value.toString().toLowerCase() != this.filterText.toLowerCase());

        case 'startsWith':
          return (value.toString().toLowerCase().indexOf(this.filterText.toLowerCase()) === 0);

        case 'endsWith':
          var index = value.toString().toLowerCase().lastIndexOf(this.filterText.toLowerCase());
          return index >= 0 && index === (value.length - this.filterText.length);

        case 'contains':
          return (value.toString().toLowerCase().indexOf(this.filterText.toLowerCase()) >= 0);

        case 'notContains':
          return (value.toString().toLowerCase().indexOf(this.filterText.toLowerCase()) === -1);

        case 'regex':
          var isValidRegex = true;
          try {
            new RegExp(this.filterText);
          } catch (e) {
            isValidRegex = false;
          }

          if (isValidRegex) {
            return (new RegExp(this.filterText).test(value));
          }
          else {
            return false;
          }

        default:
          // should never happen
          console.warn('invalid filter type');
          return false;
      }
    };

    CustomTextFilter.prototype.isFilterActive = function () {
      var isActive = this.filterText !== null && this.filterText !== undefined && this.filterText !== '';
      return isActive;
    };

    CustomTextFilter.prototype.getApi = function () {
      var that = this;
      return {
        getModel: function () {
          //var model = { type: that.filterSelect.value, filter: that.filterText.value, filterType: "getCustomTextFilter()" };
          var model = { type: that.filterSelect.value, filter: that.filterText.value };
          return model;
        },
        setModel: function (model) {
          //that.eFilterText.value = model.value;
          that.eFilterText.value = model.filter;
          that.eFilterSelect.value = model.type;
        }
      };
    };

    // only used if using a read only floating filter
    //CustomTextFilter.prototype.getModelAsString = function (model) {
    //    return model ? model.filter : '';
    //};

    CustomTextFilter.prototype.getModel = function () {
      //var model = { type: this.eFilterSelect.value, filter: this.eFilterText.value, filterType: "getCustomTextFilter()" };
      var model = { type: this.eFilterSelect.value, filter: this.eFilterText.value };
      return model;
      //return this.eFilterText.value;
    };

    CustomTextFilter.prototype.setModel = function (model) {
      //this.eFilterText.value = model.value;
      if (model) {
        this.eFilterText.value = model.filter;
        this.filterText = this.eFilterText.value;
        this.eFilterSelect.value = model.type;
        this.filterSelect = this.eFilterSelect.value;
      }
      else {
        this.eFilterText.value = '';
        this.filterText = '';
        //this.eFilterSelect.value = 'listOr';
        //this.filterSelect = 'listOr';
      }
    };

    CustomTextFilter.prototype.onFloatingFilterChanged = function (floatingFilterText) {
      this.eFilterText.value = floatingFilterText;
      this.filterText = floatingFilterText;
      this.params.filterChangedCallback();
    };

    return CustomTextFilter;
  }

  getCustomTextFloatingFilter() {
    function CustomTextFloatingFilter() {
    }

    CustomTextFloatingFilter.prototype.init = function (params) {
      this.onFloatingFilterChanged = params.onFloatingFilterChanged;
      this.eGui = document.createElement('div');
      this.eGui.classList.add('ag-floating-filter-input');

      this.eGui.innerHTML = 
      `<div role="presentation" ref="eFloatingFilterInput" class="ag-labeled ag-label-align-left ag-text-field ag-input-field">
        <div ref="eLabel" class="ag-input-field-label ag-label ag-hidden ag-text-field-label"></div>
        <div ref="eWrapper" class="ag-wrapper ag-input-wrapper ag-text-field-input-wrapper" role="presentation">
          <input ref="eInput" class="ag-input-field-input ag-text-field-input" type="text"/>
        </div>
      </div>`;
      
      this.currentValue = null;
      this.eFilterInput = this.eGui.querySelector('input');
      var that = this;
      function onInputBoxChanged() {
        if (that.eFilterInput.value === '') {
          //Remove the filter
          //that.onFloatingFilterChanged(null);
          params.parentFilterInstance((instance) => {
            instance.onFloatingFilterChanged(null);
          });
          return;
        }
        that.currentValue = that.eFilterInput.value;
        //that.onFloatingFilterChanged(that.currentValue);
        params.parentFilterInstance((instance) => {
          instance.onFloatingFilterChanged(that.currentValue);
        });
      }
      this.eFilterInput.addEventListener('input', onInputBoxChanged);
      this.eFilterInput.addEventListener('keyup', onInputBoxChanged);
    };

    CustomTextFloatingFilter.prototype.onParentModelChanged = function (parentModel) {
      // When the filter is empty we will receive a null message here
      if (!parentModel) {
        this.eFilterInput.value = '';
      } else {
        this.eFilterInput.value = parentModel.filter + '';
        this.currentValue = parentModel.filter;
      }
    };

    CustomTextFloatingFilter.prototype.getGui = function () {
      return this.eGui;
    };

    return CustomTextFloatingFilter;
  }

  /**********************************************************************************************************************/
  //Custom Number Filter //TODO make sure these custom filters match old RPMS

  getCustomNumberFilter() {
    function CustomNumberFilter() {
    }

    CustomNumberFilter.prototype.init = function (params) {
      //console.log(params);
      this.valueGetter = params.valueGetter;
      this.filterText = null;
      this.filterSelect = params.defaultOption ? params.defaultOption : 'startsWith';
      //this.filterType = 'getCustomNumberFilter()';
      this.setupGui(params);
      this.params = params;
    };

    // not called by ag-Grid, just for us to help setup
    CustomNumberFilter.prototype.setupGui = function (params) {
      this.gui = document.createElement('div');
      this.gui.innerHTML =
        '<div style="padding: 4px;">' +
        '<div><select id="filterSelect">' +
        '<option value="listOr">CSV List (Or)</option>' +
        '<option value="listAnd">CSV List (And)</option>' +
        '<option value="listStartsWith">CSV List (Starts with)</option>' +
        '<option value="equals">Equals</option>' +
        '<option value="notEqual">Not equal</option>' +
        '<option value="startsWith">Starts with</option>' +
        '<option value="endsWith">Ends with</option>' +
        '<option value="contains">Contains</option>' +
        '<option value="notContains">Not contains</option>' +
        '<option value="lessThan">Less than</option>' +
        '<option value="lessThanOrEquals">Less than or equals</option>' +
        '<option value="greaterThan">Greater than</option>' +
        '<option value="greaterThanOrEquals">Greater than or equals</option>' +
        '<option value="inRange">In range</option>' +
        '</select></div > ' +
        '<div><input type="text" id="filterText" placeholder="Filter..."/></div>' +
        '</div>';

      this.eFilterText = this.gui.querySelector('#filterText');
      this.eFilterText.addEventListener("changed", listener);
      this.eFilterText.addEventListener("paste", listener);
      this.eFilterText.addEventListener("input", listener);
      // IE doesn't fire changed for special keys (eg delete, backspace), so need to
      // listen for this further ones
      //this.eFilterText.addEventListener("keydown", listener);
      this.eFilterText.addEventListener("keyup", listener);

      this.eFilterSelect = this.gui.querySelector('#filterSelect');
      this.eFilterSelect.addEventListener("change", listener2);
      this.eFilterSelect.addEventListener("paste", listener2);
      this.eFilterSelect.addEventListener("input", listener2);

      this.eFilterSelect.value = params.defaultOption;

      var that = this;

      function listener(event) {
        that.filterText = event.target.value;
        that.filterSelect = that.eFilterSelect.options[that.eFilterSelect.selectedIndex].value;
        params.filterChangedCallback();
      }

      function listener2(event) {
        that.filterText = that.eFilterText.value;
        that.filterSelect = event.target.value;
        params.filterChangedCallback();
      }

    };

    CustomNumberFilter.prototype.getGui = function () {
      return this.gui;
    };

    CustomNumberFilter.prototype.doesFilterPass = function (params) {
      var valueGetter = this.valueGetter;
      var value = valueGetter(params);
      var filterTextNoSpaces = this.filterText.replace(/\s*,\s*/g, ",");
      var passed;

      if (this.filterText * 1 === 0) return true;

      switch (this.filterSelect) {
        case 'listOr':
          passed = false;
          filterTextNoSpaces.toLowerCase().split(",").forEach(function (filterWord) {
            if (value.toString().toLowerCase().indexOf((filterWord * 1).toString()) >= 0) {
              passed = true;
            }
          });
          return passed;

        case 'listAnd':
          passed = true;
          filterTextNoSpaces.toLowerCase().split(",").forEach(function (filterWord) {
            if (value.toString().toLowerCase().indexOf((filterWord * 1).toString()) === -1) {
              passed = false;
            }
          });
          return passed;

        case 'listStartsWith':
          passed = false;
          filterTextNoSpaces.toLowerCase().split(",").forEach(function (filterWord) {
            if (value.toString().toLowerCase().indexOf((filterWord * 1).toString()) === 0) {
              passed = true;
            }
          });
          return passed;

        case 'equals':
          return (value.toString().toLowerCase() === (this.filterText * 1).toString().toLowerCase());

        case 'notEqual':
          return (value.toString().toLowerCase() != (this.filterText * 1).toString().toLowerCase());

        case 'startsWith':
          return (value.toString().toLowerCase().indexOf((this.filterText * 1).toString().toLowerCase()) === 0);

        case 'endsWith':
          var index = value.toString().toLowerCase().lastIndexOf((this.filterText * 1).toString().toLowerCase());
          return index >= 0 && index === (value.toString().length - (this.filterText * 1).toString().length);

        case 'contains':
          return (value.toString().toLowerCase().indexOf((this.filterText * 1).toString().toLowerCase()) >= 0);

        case 'notContains':
          return (value.toString().toLowerCase().indexOf((this.filterText * 1).toString().toLowerCase()) === -1);

        case 'lessThan':
          return (value < Number(this.filterText));

        case 'lessThanOrEquals':
          return (value <= Number(this.filterText));

        case 'greaterThan':
          return (value > Number(this.filterText));

        case 'greaterThanOrEquals':
          return (value >= Number(this.filterText));

        case 'inRange':
          passed = false;
          var filterTextArray = filterTextNoSpaces.toLowerCase().split(":");
          var lower = filterTextArray[0] * 1;
          var upper = filterTextArray[1] * 1;
          if (value >= lower && value <= upper) {
            passed = true;
          }
          return passed;

        case 'regex':
          var isValidRegex = true;
          try {
            new RegExp(this.filterText);
          } catch (e) {
            isValidRegex = false;
          }

          if (isValidRegex) {
            return (new RegExp(this.filterText).test(value));
          }
          else {
            return false;
          }

        default:
          // should never happen
          console.warn('invalid filter type');
          return false;
      }
    };

    CustomNumberFilter.prototype.isFilterActive = function () {
      var isActive = this.filterText !== null && this.filterText !== undefined && this.filterText !== '';
      return isActive;
    };

    CustomNumberFilter.prototype.getApi = function () {
      var that = this;
      return {
        getModel: function () {
          //var model = { type: that.filterSelect.value, filter: that.filterText.value, filterType: "getCustomNumberFilter()" };
          var model = { type: that.filterSelect.value, filter: that.filterText.value };
          return model;
        },
        setModel: function (model) {
          //that.eFilterText.value = model.value;
          that.eFilterText.value = model.filter;
          that.eFilterSelect.value = model.type;
        }
      };
    };

    // only used if using a read only floating filter
    //CustomNumberFilter.prototype.getModelAsString = function (model) {
    //    return model ? model.filter : '';
    //};

    CustomNumberFilter.prototype.getModel = function () {
      //var model = { type: this.eFilterSelect.value, filter: this.eFilterText.value, filterType: "getCustomNumberFilter()" };
      var model = { type: this.eFilterSelect.value, filter: this.eFilterText.value };
      return model;
      //return this.eFilterText.value;
    };

    CustomNumberFilter.prototype.setModel = function (model) {
      //this.eFilterText.value = model.value;
      if (model) {
        this.eFilterText.value = model.filter;
        this.filterText = this.eFilterText.value;
        this.eFilterSelect.value = model.type;
        this.filterSelect = this.eFilterSelect.value;
      }
      else {
        this.eFilterText.value = '';
        this.filterText = '';
        //this.eFilterSelect.value = 'listOr';
        //this.filterSelect = 'listOr';
      }
    };

    CustomNumberFilter.prototype.onFloatingFilterChanged = function (floatingFilterText) {
      this.eFilterText.value = floatingFilterText;
      this.filterText = floatingFilterText;
      this.params.filterChangedCallback();
    };

    return CustomNumberFilter;
  }

  getCustomNumberFloatingFilter() {
    function CustomNumberFloatingFilter() {
    }

    CustomNumberFloatingFilter.prototype.init = function (params) {
      this.onFloatingFilterChanged = params.onFloatingFilterChanged;
      this.eGui = document.createElement('div');
      this.eGui.classList.add('ag-floating-filter-input');

      this.eGui.innerHTML = 
      `<div role="presentation" ref="eFloatingFilterInput" class="ag-labeled ag-label-align-left ag-text-field ag-input-field">
        <div ref="eLabel" class="ag-input-field-label ag-label ag-hidden ag-text-field-label"></div>
        <div ref="eWrapper" class="ag-wrapper ag-input-wrapper ag-text-field-input-wrapper" role="presentation">
          <input ref="eInput" class="ag-input-field-input ag-text-field-input" type="text"/>
        </div>
      </div>`;

      this.currentValue = null;
      this.eFilterInput = this.eGui.querySelector('input');
      var that = this;
      function onInputBoxChanged() {
        if (that.eFilterInput.value === '') {
          //Remove the filter
          //that.onFloatingFilterChanged(null);
          params.parentFilterInstance((instance) => {
            instance.onFloatingFilterChanged(null);
          });
          return;
        }
        that.currentValue = that.eFilterInput.value;
        //that.onFloatingFilterChanged(that.currentValue);
        params.parentFilterInstance((instance) => {
          instance.onFloatingFilterChanged(that.currentValue);
        });
      }
      this.eFilterInput.addEventListener('input', onInputBoxChanged);
      this.eFilterInput.addEventListener('keyup', onInputBoxChanged);
    };

    CustomNumberFloatingFilter.prototype.onParentModelChanged = function (parentModel) {
      // When the filter is empty we will receive a null message here
      if (!parentModel) {
        this.eFilterInput.value = '';
      } else {
        this.eFilterInput.value = parentModel.filter + '';
        this.currentValue = parentModel.filter;
      }
    };

    CustomNumberFloatingFilter.prototype.getGui = function () {
      return this.eGui;
    };

    return CustomNumberFloatingFilter;
  }

  /******************* Custom Group Number Filter ***********************/

  getCustomGroupNumberFilter() {
    function CustomGroupNumberFilter() {
    }

    CustomGroupNumberFilter.prototype.init = function (params) {
      this.valueGetter = params.valueGetter;
      this.filterText = null;
      this.filterSelect = params.defaultOption ? params.defaultOption : 'listEqualsOrRange';
      this.setupGui(params);
      this.params = params;
    };

    // not called by ag-Grid, just for us to help setup
    CustomGroupNumberFilter.prototype.setupGui = function (params) {
      this.gui = document.createElement('div');
      this.gui.innerHTML =
        '<div style="padding: 4px;">' +
        '<div><select id="filterSelect">' +
        '<option value="listEqualsOrRange">CSV List (Equals or Range)</option>' +
        '<option value="equals">Equals</option>' +
        '<option value="lessThanOrEquals">Less than or equals</option>' +
        '<option value="greaterThanOrEquals">Greater than or equals</option>' +
        '<option value="inRange">In range</option>' +
        '</select></div > ' +
        '<div><input type="text" id="filterText" placeholder="Filter..."/></div>' +
        '</div>';

      this.eFilterText = this.gui.querySelector('#filterText');
      this.eFilterText.addEventListener("changed", listener);
      this.eFilterText.addEventListener("paste", listener);
      this.eFilterText.addEventListener("input", listener);
      // IE doesn't fire changed for special keys (eg delete, backspace), so need to
      // listen for this further ones
      //this.eFilterText.addEventListener("keydown", listener);
      this.eFilterText.addEventListener("keyup", listener);

      this.eFilterSelect = this.gui.querySelector('#filterSelect');
      //$(this.eFilterSelect).val(params.defaultOption);
      this.eFilterSelect.addEventListener("change", listener2);
      this.eFilterSelect.addEventListener("paste", listener2);
      this.eFilterSelect.addEventListener("input", listener2);

      var that = this;

      function listener(event) {
        that.filterText = event.target.value;
        that.filterSelect = that.eFilterSelect.options[that.eFilterSelect.selectedIndex].value;
        params.filterChangedCallback();
      }

      function listener2(event) {
        that.filterText = that.eFilterText.value;
        that.filterSelect = event.target.value;
        params.filterChangedCallback();
      }

    };

    CustomGroupNumberFilter.prototype.getGui = function () {
      return this.gui;
    };

    CustomGroupNumberFilter.prototype.doesFilterPass = function (params) {
      var valueGetter = this.valueGetter;
      var value = valueGetter(params);
      var filterTextNoSpaces = this.filterText.replace(/\s*,\s*/g, ",");
      switch (this.filterSelect) {

        case 'listEqualsOrRange':
          var passed = false;
          filterTextNoSpaces.toLowerCase().split(",").forEach(function (filterWord) {
            if (filterWord.indexOf(':') !== -1) {
              var filterTextArray = filterWord.split(":");
              if (value >= (filterTextArray[0] * 1) && value <= (filterTextArray[1] * 1)) {
                passed = true;
              }
            }
            else {
              if (value.toString().toLowerCase() === filterWord.toLowerCase()) {
                passed = true;
              }
            }
          });
          return passed;

        case 'equals':
          return (value.toString().toLowerCase() === this.filterText.toLowerCase());

        case 'lessThanOrEquals':
          return (value <= Number(this.filterText));

        case 'greaterThanOrEquals':
          return (value >= Number(this.filterText));

        case 'inRange':
          var filterTextArray = filterTextNoSpaces.toLowerCase().split(":");
          return (value >= (filterTextArray[0] * 1) && value <= (filterTextArray[1] * 1));

        case 'regex':
          var isValidRegex = true;
          try {
            new RegExp(this.filterText);
          } catch (e) {
            isValidRegex = false;
          }

          if (isValidRegex) {
            return (new RegExp(this.filterText).test(value));
          }
          else {
            return false;
          }

        default:
          // invalid filter type
          return false;
      }
    };

    CustomGroupNumberFilter.prototype.isFilterActive = function () {
      var isActive = this.filterText !== null && this.filterText !== undefined && this.filterText !== '';
      return isActive;
    };

    CustomGroupNumberFilter.prototype.getApi = function () {
      var that = this;
      return {
        getModel: function () {
          var model = { type: that.filterSelect.value, filter: that.filterText.value };
          return model;
        },
        setModel: function (model) {
          that.eFilterText.value = model.filter;
          that.eFilterSelect.value = model.type;
        }
      };
    };

    CustomGroupNumberFilter.prototype.getModel = function () {
      var model = this.eFilterText.value !== '' ? { type: this.eFilterSelect.value, filter: this.eFilterText.value } : '';
      return model;
    };

    CustomGroupNumberFilter.prototype.setModel = function (model) {
      if (model) {
        this.eFilterText.value = model.filter;
        this.filterText = this.eFilterText.value;
        this.eFilterSelect.value = model.type;
        this.filterSelect = this.eFilterSelect.value;
      }
      else {
        this.eFilterText.value = '';
        this.filterText = '';
      }
    };

    CustomGroupNumberFilter.prototype.onFloatingFilterChanged = function (floatingFilterText) {
      this.eFilterText.value = floatingFilterText;
      this.filterText = floatingFilterText;
      this.params.filterChangedCallback();
    };

    return CustomGroupNumberFilter;
  }

  getCustomGroupNumberFloatingFilter() {
    function CustomGroupNumberFloatingFilter() {
    }

    CustomGroupNumberFloatingFilter.prototype.init = function (params) {
      this.onFloatingFilterChanged = params.onFloatingFilterChanged;
      this.eGui = document.createElement('div');
      this.eGui.classList.add('ag-floating-filter-input');

      this.eGui.innerHTML = 
      `<div role="presentation" ref="eFloatingFilterInput" class="ag-labeled ag-label-align-left ag-text-field ag-input-field">
        <div ref="eLabel" class="ag-input-field-label ag-label ag-hidden ag-text-field-label"></div>
        <div ref="eWrapper" class="ag-wrapper ag-input-wrapper ag-text-field-input-wrapper" role="presentation">
          <input ref="eInput" class="ag-input-field-input ag-text-field-input" type="text"/>
        </div>
      </div>`;

      this.currentValue = null;
      this.eFilterInput = this.eGui.querySelector('input');
      var that = this;
      function onInputBoxChanged() {
        if (that.eFilterInput.value === '') {
          //Remove the filter
          //that.onFloatingFilterChanged(null);
          params.parentFilterInstance((instance) => {
            instance.onFloatingFilterChanged(null);
          });
          return;
        }
        that.currentValue = that.eFilterInput.value;
        //that.onFloatingFilterChanged(that.currentValue);
        params.parentFilterInstance((instance) => {
          instance.onFloatingFilterChanged(that.currentValue);
        });
      }
      this.eFilterInput.addEventListener('input', onInputBoxChanged);
      this.eFilterInput.addEventListener('keyup', onInputBoxChanged);
    };

    CustomGroupNumberFloatingFilter.prototype.onParentModelChanged = function (parentModel) {
      // When the filter is empty we will receive a null message here
      if (!parentModel) {
        this.eFilterInput.value = '';
      } else {
        this.eFilterInput.value = parentModel.filter + '';
        this.currentValue = parentModel.filter;
      }
    };

    CustomGroupNumberFloatingFilter.prototype.getGui = function () {
      return this.eGui;
    };

    return CustomGroupNumberFloatingFilter;
  }

  onMultiChange(val, rowData) {
    let priceMaximum = rowData.current1;
    let percentMaximum = rowData.percent1C;
    let currentUnitCost = rowData.deliveredUnitCost;

    var prePriced = rowData.offPack.indexOf('PP') == 0;
    var priceCell = <HTMLInputElement>document.getElementById('priceCell');
    var percentCell = <HTMLInputElement>document.getElementById('percentCell');
    var price = priceCell? Number(priceCell.value): 0;
    var multi = val;

    //GAIR-69 MOVED THIS INTO this.validateRanges();
    //Added by RG on 10/9/2023
    // if (multi && (multi < 0 || multi > 99)){
    //   this._messageService.alert('Multi must be between 0 and 99.<br/>');
    //   this.handleErrorHighlight('multi');
    //   return;
    // }
    //end GAIR-69

    multi = (multi == 0 ? 1 : multi);
    var tempPrice = price / multi;

    if (prePriced && tempPrice > priceMaximum) {
        price = priceMaximum * multi;
        tempPrice = priceMaximum;
        //this._messageService.alert('The price cannot exceed $' + price.toFixed(2) + ' (' + priceMaximum.toFixed(2) + ' x ' + multi + ') for this pre-priced item.');
        //GAIR-103
        this._messageService.alert(`This item is prepriced at $${priceMaximum.toFixed(2)}. You cannot set the price higher than the package price.`);
    }

    if (priceCell) priceCell.value = Number(price).toFixed(2);

    var percent = Math.floor(((tempPrice - currentUnitCost) / tempPrice) * 100);
    if (percentCell) percentCell.value = String(percent);

    this.validateRanges();
  }

  onPriceChange(val, rowData){
    let priceMaximum = rowData.current1;
    let percentMaximum = rowData.percent1C;
    let currentUnitCost = rowData.deliveredUnitCost;

    var price = val;
    var prePriced = rowData.offPack.indexOf('PP') == 0;
    var priceCell = <HTMLInputElement>document.getElementById('priceCell');
    var percentCell = <HTMLInputElement>document.getElementById('percentCell');
    var multiCell = <HTMLInputElement>document.getElementById('multiCell');
    var multi = multiCell? Number(multiCell.value): 0;
    multi = (multi == 0 ? 1 : multi);
    var tempPrice = price / multi;

    //GAIR-69 MOVED THIS INTO this.validateRanges();
    //Added by RG on 10/9/2023
    // if (price && (price < 0 || price > 999.99)){
    //   this._messageService.alert('Price must be between 0 and 999.99.<br/>');
    //   this.handleErrorHighlight('price');
    //   return;
    // }
    //end GAIR-69

    if (prePriced && tempPrice > priceMaximum) {
        price = priceMaximum * multi;
        tempPrice = priceMaximum;
        if (priceCell) priceCell.value = String(price); //set it to maximum
        this._messageService.closeAll();
        //this._messageService.alert('The price cannot exceed $' + price.toFixed(2) + ' (' + priceMaximum.toFixed(2) + ' x ' + multi + ') for this pre-priced item.');
        //GAIR-103
        this._messageService.alert(`This item is prepriced at $${priceMaximum.toFixed(2)}. You cannot set the price higher than the package price.`);
    }

    //don't include this here. it will not let you type a decimal value in the price
    //if (priceCell) priceCell.value = Number(price).toFixed(2);

    var percent = Math.floor(((tempPrice - currentUnitCost) / tempPrice) * 100);
    if (percentCell) percentCell.value = String(percent);

    this.validateRanges();

  }

  onPercentChange(val, rowData) {
    let priceMaximum = rowData.current1;
    let percentMaximum = rowData.percent1C;
    let currentUnitCost = rowData.deliveredUnitCost;

    var percent = val;
    var prePriced = rowData.offPack.indexOf('PP') == 0;
    var priceCell = <HTMLInputElement>document.getElementById('priceCell');
    var percentCell = <HTMLInputElement>document.getElementById('percentCell');
    var multiCell = <HTMLInputElement>document.getElementById('multiCell');
    var multi = multiCell? Number(multiCell.value): 0;
    multi = (multi == 0 ? 1 : multi);
    var price = Number(currentUnitCost / (1 - (percent / 100)) * multi);
    var tempPrice = price / multi;

    var maxPercent = Math.floor(((priceMaximum - currentUnitCost) / priceMaximum) * 100);

    //GAIR-69 MOVED THIS INTO this.validateRanges();
    //Added by RG on 10/9/2023
    // if (percent && (percent < -99 || percent > 99)){
    //   this._messageService.alert('Percent must be between -99 and 99.<br/>');
    //   this.handleErrorHighlight('percent');
    //   return;
    // }
    //end GAIR-69

    if (prePriced && tempPrice > priceMaximum) {
        price = priceMaximum * multi;
        tempPrice = priceMaximum;
        //this._messageService.alert('The percent cannot exceed ' + maxPercent + ' because it would cause the price to exceed $' + price.toFixed(2) + ' (' + priceMaximum.toFixed(2) + ' x ' + multi + ') for this pre-priced item.');
        //GAIR-103
        this._messageService.alert(`This item is prepriced at $${priceMaximum.toFixed(2)}. You cannot set the price higher than the package price.`);
    }

    if (priceCell) priceCell.value = Number(price).toFixed(2);

    if (prePriced && percent > maxPercent){
        if (percentCell) percentCell.value = String(maxPercent); //used String because number not assignable to string
    }

    this.validateRanges();

  }

  validateRanges(){
        
    //GAIR-69
    //Added by RG on 10/16/2023
    //checks to see if new values are in a valid range and highlight errors
    //as always each input must be checked to see if it exists 
    //because with ag-grid, the inputs don't exist when they are scrolled out of view

    var priceCell = <HTMLInputElement>document.getElementById('priceCell');
    var percentCell = <HTMLInputElement>document.getElementById('percentCell');
    var multiCell = <HTMLInputElement>document.getElementById('multiCell');

    let multi, price, percent;

    if (multiCell) {
      multi = multiCell.value;
      multiCell.style.color = 'black';
      multiCell.style.borderColor = 'black';
    }
    if (priceCell) {
      price = priceCell.value;
      priceCell.style.color = 'black';
      priceCell.style.borderColor = 'black';
    }
    if (percentCell) {
      percent = percentCell.value;
      percentCell.style.color = 'black';
      percentCell.style.borderColor = 'black';
    }

    // check if multi, price and percent values are in range
    if (multi && (multi < 0 || multi > 99)){
      if (multiCell) {
        multiCell.style.color = 'red';
        multiCell.style.borderColor = 'red';
      }
      this._messageService.showErrorToastTopRight('Multi must be between 0 and 99');
    }
    if (price && (price < 0 || price > 999.99)){
      if (priceCell) {
        priceCell.style.color = 'red';
        priceCell.style.borderColor = 'red';
      }
      this._messageService.showErrorToastTopRight('Price must be between 0 and 999.99');
    }
    if (percent && (percent < -99 || percent > 99)){
      if (percentCell) {
        percentCell.style.color = 'red';
        percentCell.style.borderColor = 'red';
      }
      this._messageService.showErrorToastTopRight('Percent must be between -99 and 99');
    }

  }

}
