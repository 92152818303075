import { Component } from '@angular/core';
import { MessageService } from 'src/app/shared/message.service';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from 'src/app/shared/confirmation-dialog/confirmation-dialog.component';
import { PermissionsService } from 'src/app/shared/permissions.service';
import { ApplicationService } from 'src/app/shared/application.service';

@Component({
  selector: 'app-admin-application',
  templateUrl: './admin-application.component.html',
  styleUrls: ['./admin-application.component.css']
})
export class AdminApplicationComponent {

  permissions;

  confirmationDialogRef: MatDialogRef<ConfirmationDialogComponent>;

  constructor(
    private _permissionsService: PermissionsService,
    private _applicationService: ApplicationService,
    private _messageService: MessageService,
    private _dialog: MatDialog,
  ) {
    this.permissions = this._permissionsService.getPermissions();
   }

  showDeleteIndexedDbConfirmation(){
    this.confirmationDialogRef = this._dialog.open(ConfirmationDialogComponent, {
      disableClose: false,
      //width: '600px',
      data: {
        title: 'Are you sure?',
        message: 'Do you want to delete the local Indexed Db?',
        confirmText: 'Yes, delete the indexed Db',
        cancelText: 'No, cancel this action!'
      }
    });
    this.confirmationDialogRef.afterClosed().subscribe(result => {
      if(result) {
        this.deleteIndexedDb();
        localStorage.removeItem ('rpmsDatePricingDataWasLastSavedForStoresById');
      }
      this.confirmationDialogRef = null;
    });
  }

  deleteIndexedDb(){
    var that = this;
    //indexedDB.open("RPMS").onsuccess = function (e) { var db = e.target.result; db.close(); };
    var req = indexedDB.deleteDatabase('RPMS2');
    req.onsuccess = function (e) {
      that._messageService.alert("Successfully deleted IndexedDb.", "Success");
    };
    req.onerror = function (e) {
      that._messageService.alert("Failed to delete IndexedDb.", "Alert");
    };
    req.onblocked = function (e) {
      that._messageService.alert("Failed to delete IndexedDb due to the operation being blocked.", "Alert");
    };
  }

  logOutInactiveUsers() {
    this._applicationService.logOutInactiveUsers()
    .subscribe(
      (data:any) => {
        this._messageService.alert(data.message);
      },
      response => {
        this._messageService.onFailure('Failed to log out inactive users.', response);
      }
    )
  }

}
