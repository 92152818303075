import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { AuthenticationService } from './authentication.service';
import { MessageService } from 'src/app/shared/message.service';
import { SpinnerService } from 'src/app/shared/spinner.service';
import { environment } from "src/environments/environment";
 
@Injectable()
export class AuthGuard  {

  constructor(
    private _router : Router,
    private _authenticationService: AuthenticationService,
    private _messageService: MessageService,
    private _spinnerService: SpinnerService,
    ){}
  
  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot):  boolean {

      //anytime we change routes, make sure the spinner reverts to the generic message
      this._spinnerService.setProgressSpinnerMessage('Processing Request...');

      //var token = this._authenticationService.getToken();
      var token = sessionStorage.getItem('rpmsToken');
      var sessionId = localStorage.getItem('rpmsSessionId');
      
      if (token){
        //If environment.production is false (running locally)
        //it will not check to see if RPMS is running in more than one window.
        //This is done so that we can do Cypress testing locally without error
        if(environment.production) {
          if (sessionId === window.name){
            return true;
          }
          else {
            this._messageService.alert('RPMS can only run on one browser tab. You may have closed the previous tab without logging out first. Please close all but one tab running RPMS.  You may have to log in again.');
            //document.documentElement.innerHTML = '';
            if (this._authenticationService.isUserLoggedIn) {
              setTimeout(()=> { this._authenticationService.logout() }, 5000);
            }
            //this._router.navigate(['/login']);
            return false;
          }
        }
        else {
          return true;
        }
      }
      else{
        this._router.navigate(['/login']);
        return false;
      }
  }
}