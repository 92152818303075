import { Component, OnInit, Input, Inject, LOCALE_ID } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from 'src/app/shared/confirmation-dialog/confirmation-dialog.component';
import { Validators, UntypedFormBuilder } from '@angular/forms';
import { PrivateLabelsService } from 'src/app/shared/private-labels.service';
import { MessageService } from 'src/app/shared/message.service';
import { AdminItemListDetailDialog } from '../../admin-item-lists/admin-item-list-list/admin-item-list-list.component';
import { formatDate } from '@angular/common';
import { AnnouncementService } from 'src/app/shared/announcement.service';
import { AuthenticationService } from 'src/app/core/authentication/shared/authentication.service';

@Component({
  selector: 'rpms-admin-announcements-detail',
  templateUrl: './admin-announcements-detail.component.html',
  styleUrls: ['./admin-announcements-detail.component.css']
})
export class AdminAnnouncementsDetailComponent implements OnInit {
  @Input() id: string; 

  confirmationDialogRef: MatDialogRef<ConfirmationDialogComponent>;

  mode: string;
  announcement;
  myUser;

  announcementDetailForm = this._fb.group({
    active: [''],
    message: ['', Validators.required],
    created: [''],
    modified: [''],
  });

  constructor(private _fb: UntypedFormBuilder,
              private _announcementsService: AnnouncementService,
              private _authenticationService: AuthenticationService,
              private _messageService: MessageService,
              public dialogRef: MatDialogRef<AdminItemListDetailDialog>,
              @Inject(LOCALE_ID) private locale: string) { 
                this.myUser = this._authenticationService.getMyUser();
              }

  ngOnInit() {
    if (this.myUser.hasReadOnlyRestriction) this.announcementDetailForm.disable();
    this.mode = this.id == '0' ? 'New' : 'Edit';
    if (this.id != '0') {
      this.getAnnouncement();
    }
    else{
      this.announcementDetailForm.patchValue({ active: true });
    }
    this.announcementDetailForm.get('created').disable();
    this.announcementDetailForm.get('modified').disable();
  }

  getAnnouncement(){
    this._announcementsService.getAnnouncementById(this.id)
      .subscribe(
        (data:any) => {
          this.announcement = data;
          this.announcementDetailForm.patchValue({
            active: data.active,
            message: data.message,
            created: data.created ? formatDate(data.created, 'MM/dd/yyyy h:mm:ss a', this.locale) : '',
            modified: data.modified ? formatDate(data.modified, 'MM/dd/yyyy h:mm:ss a', this.locale) : '',
          });
        },
        (response) => {
          this._messageService.onFailure('Failed to get announcement.', response);
        })
  }

  onSubmitAnnouncement(){
    //Users with hasReadOnlyRestriction set to true should not even be allowed to invoke this action because it is hidden or disabled in the UI.
    //This is for extra protection. The back end will also be protected.
    if (this.myUser.hasReadOnlyRestriction){
      this._messageService.alertReadOnlyRestriction();
      return;
    }

    var announcement = this.announcementDetailForm.value;
    if (this.mode === 'New') {
      this._announcementsService.createAnnouncement(announcement)
      .subscribe(
        () => {
          this.dialogRef.close('reload');
          this._messageService.onSuccess('Successfully created announcement');
        },
        (response) => {
          this._messageService.onFailure('Failed to create announcement.', response)
        }
      );
    }
    else {
      announcement.announcementId = this.id;
      this._announcementsService.updateAnnouncement(this.id, announcement)
      .subscribe(
        () => {
          this.dialogRef.close('reload');
          this._messageService.onSuccess('Successfully updated announcement');
        },
        (response) => {
          this._messageService.onFailure('Failed to update announcement.', response)
        }
      );
    }
  }

  closeDialog(){
    this.dialogRef.close();
  }

}