<div class="detail admin-dialog">
<h1 mat-dialog-title [ngClass]="{'accent': privateLabelId == '0'}">{{mode}} Private Label: {{privateLabelDetailForm.get('label').value}}</h1>
<div mat-dialog-content>
  <form [formGroup]="privateLabelDetailForm" id="private-label-detail-form" (ngSubmit)="onSubmitPrivateLabel()">
    <div class="checkbox-form-field">
      <mat-checkbox id="active" formControlName="active">Active</mat-checkbox>
    </div>
    <div class="row">
      <mat-form-field>
        <mat-label>Letter Code</mat-label>
        <input matInput id="letterCode" formControlName="letterCode" placeholder="Letter Code" required maxlength="1">
        <mat-error *ngIf="privateLabelDetailForm.hasError('required', 'letterCode')">Letter Code is required</mat-error>
        <mat-error *ngIf="privateLabelDetailForm.hasError('pattern', 'letterCode')">Letter Code must be a capital letter</mat-error>
      <mat-hint align="end">{{privateLabelDetailForm.get('letterCode').value.length}} / 1</mat-hint>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Label</mat-label>
        <input matInput id="label" formControlName="label" placeholder="Label" required maxlength="30">
        <mat-error *ngIf="privateLabelDetailForm.hasError('required', 'label')">Label is required</mat-error>
      <mat-hint align="end">{{privateLabelDetailForm.get('label').value.length}} / 30</mat-hint>
      </mat-form-field>
    </div>
    <div class="row" *ngIf="mode !== 'New'">
      <mat-form-field>
        <mat-label>Created</mat-label>
        <input matInput id="created" formControlName="created" placeholder="Created">
      </mat-form-field>
      <mat-form-field>
        <mat-label>Modified</mat-label>
        <input matInput id="modified" formControlName="modified" placeholder="Modified">
      </mat-form-field>
    </div>
    <div class="detail-action-buttons">
      <button mat-raised-button color="primary" [disabled]="!privateLabelDetailForm.valid" type="submit">Save</button>
      <button mat-raised-button (click)="closeDialog()" type="button">Cancel</button>
    </div>
  </form>
</div>
</div>
