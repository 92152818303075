import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { AppConfig } from '../app.config';

@Injectable({
  providedIn: 'root'
})
export class StoreGroupService {

  private _baseUrl = AppConfig.settings.baseUrl;
  private _reqHeader = new HttpHeaders({ 'Accept': 'application/json', 'Content-Type': 'application/json' });

  constructor ( 
    private _http: HttpClient, 
  ){}

  getStoreGroupById(storeGroupId) {
    return this._http.get(this._baseUrl + '/api/StoreGroups/GetStoreGroupById/' + storeGroupId, { headers: this._reqHeader });
  }

  getStoreGroupWithStoresById(storeGroupId) {
    return this._http.get(this._baseUrl + '/api/StoreGroups/GetStoreGroupWithStoresById/' + storeGroupId, { headers: this._reqHeader });
  }

  createStoreGroup(storeGroup) {
    return this._http.post(this._baseUrl + '/api/StoreGroups/CreateStoreGroup', storeGroup, { headers: this._reqHeader });
  }

  updateStoreGroup(storeGroupId, storeGroup) {
    return this._http.put(this._baseUrl + '/api/StoreGroups/UpdateStoreGroup/' + storeGroupId, storeGroup, { headers: this._reqHeader });
  }

  getAllStoreGroups(params?) {
    return this._http.get(this._baseUrl + '/api/StoreGroups/GetAllStoreGroups', { params: params, headers: this._reqHeader });
  }

  getMyStoreGroupsWithStores(params?) {
    return this._http.get(this._baseUrl + '/api/StoreGroups/GetMyStoreGroupsWithStores', { params: params, headers: this._reqHeader });
  }

  getStoreGroupsWithStoresForUser(userId) {
    return this._http.get(this._baseUrl + '/api/StoreGroups/GetStoreGroupsWithStoresForUser/' + userId, { headers: this._reqHeader });
  }

  //Note this is in Jack's Account controller
  getMyManagedStoreGroups(params?) {
    return this._http.get(this._baseUrl + '/api/Account/GetMyManagedStoreGroups', { params: params, headers: this._reqHeader });
  }

  //Note this is in Jack's Account controller
  getManagedStoreGroupsByUserId(userId) {
    return this._http.get(this._baseUrl + '/api/Account/GetManagedStoreGroupsByUserId/' + userId, { headers: this._reqHeader });
  }

  deleteStoreGroup(storeGroupId, forceDisassociationOrDeletionOfDependencies) {
    return this._http.delete(this._baseUrl + '/api/StoreGroups/DeleteStoreGroup/' + storeGroupId + '/' + forceDisassociationOrDeletionOfDependencies, { headers: this._reqHeader });
  }

}
