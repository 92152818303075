import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { AgGridAngular } from 'ag-grid-angular';
import { GridOptions } from 'ag-grid-community';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from 'src/app/shared/confirmation-dialog/confirmation-dialog.component';
import { GridService } from 'src/app/shared/grid.service';
import { MessageService } from 'src/app/shared/message.service';
import { ViewDetailButtonComponent } from 'src/app/shared/view-detail-button/view-detail-button.component';
import { DeleteButtonComponent } from 'src/app/shared/delete-button/delete-button.component';
import { ItemFilterService } from 'src/app/shared/item-filter.service';
import { PermissionsService } from 'src/app/shared/permissions.service';
import { AuthenticationService } from 'src/app/core/authentication/shared/authentication.service';
import { AppConfig } from 'src/app/app.config';

@Component({
  selector: 'app-admin-item-filter-list',
  templateUrl: './admin-item-filter-list.component.html',
  styleUrls: ['./admin-item-filter-list.component.css']
})
export class AdminItemFilterListComponent implements OnInit {
  @ViewChild('agGrid') agGrid: AgGridAngular;

  adminDetailRef: MatDialogRef<AdminItemFilterDetailDialog>;
  confirmationDialogRef: MatDialogRef<ConfirmationDialogComponent>;
  private config = AppConfig.settings;

  gridOptions: GridOptions;
  quickFilterText: string = '';
  context; //required by grid
  //frameworkComponents; //required by grid
  allStoreGroups;
  permissions: any = {};
  myUser;

  rowData = [];

  //done this way so that permissions can be read before applying columnDefs to gridOptions
  //the delete and/or detail buttons depend on permissions as to whether to use those button renderers or not
  getColumnDefs(){
    return [
    {
      headerName: "",
      field: "action",
      width: 70,
      minWidth: 70,
      maxWidth: 90,
      cellClass: "action-col",
      cellRenderer: "ViewDetailButtonComponent",
      pinned: "left",
      sortable: false,
      filter: false,
      suppressHeaderMenuButton: true,
      suppressNavigable: true,
    },
    {
      headerName: "Filter Name",
      field: "filterName",
      cellClass: "filterName-col",
      filter: "agTextColumnFilter",
      get headerTooltip() { return this.headerName; }
    },
    {
      headerName: "Description",
      field: "description",
      cellClass: "description-col",
      filter: "agTextColumnFilter",
      get headerTooltip() { return this.headerName; }
    },
    {
      headerName: "Creator",
      field: "creator",
      cellClass: "creator-col",
      filter: "agTextColumnFilter",
      filterParams: {
        defaultOption: "contains"
      },
      get headerTooltip() { return this.headerName; }
    },
    {
      headerName: "Last Accessed",
      field: "lastAccessed",
      cellClass: "lastAccessed-col",
      cellRenderer: this._gridService.dateTimeRenderer,
      filter: "agDateColumnFilter",
      filterParams: {
        comparator: this._gridService.dateComparator,
        inRangeInclusive: true
      },
      get headerTooltip() { return this.headerName; }
    },
    {
      headerName: "Shared With",
      field: "sharedWith",
      cellClass: "sharedWith-col",
      filter: "agTextColumnFilter",
      get headerTooltip() { return this.headerName; }
    },
    {
      headerName: "Modified By",
      field: "modifiedBy",
      cellClass: "modifiedBy-col",
      filter: "agTextColumnFilter",
      get headerTooltip() { return this.headerName; }
    },
    {
      headerName: "Active",
      field: "active",
      cellClass: ["active-col", "tac"],
      cellRenderer: this._gridService.checkRenderer,
      filter: "agSetColumnFilter",
      filterParams: {
        cellRenderer: this._gridService.checkFilterRenderer,
        values: [true, false],
        newRowsAction: 'keep'
      },
      get headerTooltip() { return this.headerName; }
    },
    {
      headerName: "",
      field: "delete",
      width: 80,
      minWidth: 80,
      maxWidth: 80,
      cellClass: ["delete-col", "tac"],
      cellRenderer: this.permissions.roleAllowsUserToDeleteItemFilter && !this.myUser.hasReadOnlyRestriction ? "DeleteButtonComponent" : null,
      suppressHeaderMenuButton: true,
      sortable: false,
      filter: false,
      suppressNavigable: true,
      hide: !this.permissions.roleAllowsUserToDeleteItemFilter,
    }

  ];
}
  
  constructor(
    private _itemFilterService: ItemFilterService,
    private _gridService: GridService,
    private _dialog: MatDialog, 
    private _messageService: MessageService,
    private _permissionsService: PermissionsService,
    private _authenticationService: AuthenticationService,
    ) {
      if (this.config.logInits) console.log('item filter init');
    this.myUser = this._authenticationService.getMyUser();
    this.permissions = this._permissionsService.getPermissions();
    this.gridOptions = <GridOptions> {
			columnDefs: this.getColumnDefs(),
			rowData: this.rowData,
      rowHeight: 35,    
      components: {
        ViewDetailButtonComponent: ViewDetailButtonComponent,
        DeleteButtonComponent: DeleteButtonComponent,
      },
      defaultColDef: {
        sortable: true,
        resizable: true,
        floatingFilter: true,
      },
      statusBar: {
        statusPanels: [
          { statusPanel: 'agTotalAndFilteredRowCountComponent', align: 'left' },
        ],
      },

    }
    this.context = { componentParent: this }
   }

  ngOnInit() {
    this.getAllSharedItemFilters();
  }

  getAllSharedItemFilters(){
    this._itemFilterService.getAllSharedItemFilters()
      .subscribe(
        (data) => {
          this.formatandSetRowData(data);
        },
        (response) => {
          this._messageService.onFailure('Failed to get Item Filters.', response);
        })
  }

  formatandSetRowData(data) {
    var gridData = [];
    for (var i=0; i<data.length; i++){
      var singleRowData = {};
      var actionFieldValue = '';
      var deleteFieldValue = '';
      singleRowData = {
        itemFilterId: data[i].itemFilterId,
        action: actionFieldValue,
        filterName: data[i].name,
        description: data[i].description,
        creator: data[i].owner.fullNameAndEmail,
        ownerUserId: data[i].owner.userId,
        lastAccessed: data[i].accessed != null ? new Date(data[i].accessed) : '',
        sharedWith: this._gridService.addSpacesBetweenWords(data[i].sharedWith),
        modifiedBy: this._gridService.addSpacesBetweenWords(data[i].modifiedBy),
        active: data[i].active,
        delete: deleteFieldValue
    };
      gridData.push(singleRowData);
    }
    this.rowData = gridData;
    this._gridService.onWindowResize('#ag-grid-wrapper');
    setTimeout(() => {
      this.fitColumns();
      this._gridService.sizeGrid('#ag-grid-wrapper');
    }, 100); 
  }

  clearFilters(){
    this.quickFilterText = '';
    this.agGrid.api.setGridOption('quickFilterText', this.quickFilterText);
    this.agGrid.api.setFilterModel(null);
    this.agGrid.api.onFilterChanged();
  }

  resetColumns(){
    this.agGrid.api.resetColumnState();
    this.fitColumns(); //since this is done by default also
  }

  autoSizeColumns(){
    var columns = this.agGrid.api.getColumns();
    this.agGrid.api.autoSizeColumns(columns);
  }

  fitColumns(){
    this.agGrid.api.sizeColumnsToFit();
  }

  onQuickFilterChange(){
    this.agGrid.api.setGridOption('quickFilterText', this.quickFilterText);
  }

  onDetailButtonClick(params){
    this.adminDetailRef = this._dialog.open(AdminItemFilterDetailDialog, {
      width: '850px',
      //height: '600px',
      data: {
        id: params.data.itemFilterId
      }
    });
    this.adminDetailRef.afterClosed().subscribe(result => {
      if(result === 'reload') {
        this.getAllSharedItemFilters();
      }
      this.adminDetailRef = null;
    });
  }

  onDeleteButtonClick(params){
    //Users with hasReadOnlyRestriction set to true should not even be allowed to invoke this action because it is hidden or disabled in the UI.
    //This is for extra protection. The back end will also be protected.
    if (this.myUser.hasReadOnlyRestriction){
      this._messageService.alertReadOnlyRestriction();
      return;
    }
    
    if (this.myUser.hasDeletionPrivilege || params.data.ownerUserId == this.myUser.userId){
      this.confirmationDialogRef = this._dialog.open(ConfirmationDialogComponent, {
        disableClose: false,
        //width: '600px',
        data: {
          title: 'Are you sure?',
          message: 'Do you want to delete "' + params.data.filterName + '"?',
          confirmText: 'Yes, delete item filter',
          cancelText: 'No, cancel this action!'
        }
      });
      this.confirmationDialogRef.afterClosed().subscribe(result => {
        if(result) {
          //console.log('confirmed!');
          this._itemFilterService.deleteItemFilter(params.data.itemFilterId)
          .subscribe(
            (data) => {
              this._messageService.onSuccess('Successfully deleted item filter');
              this.getAllSharedItemFilters();
            },
            (response) => {
              this._messageService.onFailure('Failed to get delete item filter.', response);
            })
        }
        this.confirmationDialogRef = null;
      });
    }
    else {
      this._messageService.alert('You are not authorized to delete this item filter because you are not the creator. If you believe you should have that privilege, contact an administrator.');
    }

  }

}

@Component({
  selector: 'app-admin-item-filter-detail-dialog',
  template: `<rpms-admin-item-filter-detail [id]="data.id"></rpms-admin-item-filter-detail>`,
})
export class AdminItemFilterDetailDialog {

  constructor(
    public dialogRef: MatDialogRef<AdminItemFilterDetailDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any) {}

}
