import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'rpms-shelf-tag-editor',
  templateUrl: './shelf-tag-editor.component.html',
  styleUrls: ['./shelf-tag-editor.component.css']
})
export class ShelfTagEditorComponent implements OnInit {
  @Input() rowNodeToUpdate: any;
  @Input() changeType: string;
  @Output() updatedRowNodeEvent = new EventEmitter<any>();
  @Output() isShelfTagEditorVisibleEvent = new EventEmitter<boolean>();

  rowData: any = {};
  shelfTagQuantities;
  isSaveDisabled: boolean = true;
  shelfTagQty;

  constructor(
  ) { }

  ngOnInit() {
    this.rowData = this.rowNodeToUpdate.data;
    this.shelfTagQuantities = ['0','1','2','3','4','5','6','7','8','9'];
    this.shelfTagQty = this.rowData.shelfTagQuantityOrdered ? String(this.rowData.shelfTagQuantityOrdered) : '0';
  }

  sendRowNodeAndShelfTagQty() {
    this.rowNodeToUpdate.data.shelfTagQty = this.shelfTagQty;
    this.updatedRowNodeEvent.next({ rowNode: this.rowNodeToUpdate, quantity: this.shelfTagQty});
  }

  close(){
      this.isShelfTagEditorVisibleEvent.next(false);
  }

}
