<div id="filter-selector" class="element help-trigger mat-select-override" data-help="saved-filters" >
    <mat-select id="item-filter-select" name="itemFilter" #itemFilterSelect="ngModel" [(ngModel)]="itemFilter"
        placeholder=" &#xf0b0; Saved Item Filters" [compareWith]="compareItemFilterObjects" (selectionChange)="onItemFilterSelected()">
        <!-- <mat-option [value]="{ itemFilterId: null }" style="color: #ccc"><i class="fas fa-filter"></i> Saved Item Filters</mat-option> -->
        <mat-option *ngFor="let filter of itemFilters" [value]="filter">
            {{filter.name}}
        </mat-option>
    </mat-select>
    <button id="save-filter-button" (click)="showFilterSaveDialog()" mat-stroked-button *ngIf="permissions.roleAllowsUserToCreateItemFilter" [disabled]="myUser.hasReadOnlyRestriction"><i class="fas fa-save"></i></button>
</div>
<div id="save-filter" class="element help-trigger" data-help="save-filter" *ngIf="isShowingForm">
    <form #itemFilterForm="ngForm" (submit)="onSaveItemFilter()">
        <h3>Save Current Item Filter</h3>
        <div class="row">
            <mat-form-field>
              <mat-label>Item Filter Name</mat-label>
              <input matInput name="itemFilterName" placeholder="Item Filter Name" type="text" #itemFilterNameInput [(ngModel)]="itemFilterName" maxlength="30"
                  required [disabled]="!isItemFilterModifiable" />
              <mat-error *ngIf="itemFilterNameInput.errors?.required">Item Filter Name is required</mat-error>
              <mat-hint align="end">{{itemFilterName ? itemFilterName.length : 0}} / 30</mat-hint>
            </mat-form-field>
        </div>
        <div class="row">
            <mat-form-field>
              <mat-label>Shared With</mat-label>
              <mat-select name="sharedWith" placeholder="Shared With" #sharedWithSelect="ngModel"
                  [(ngModel)]="sharedWith" required [disabled]="!isItemFilterModifiable || !canItemFilterSharedWithAndOrModifiedByPropertiesBeChanged">
                  <mat-option *ngFor="let option of itemFilterSelectOptions" [value]="option">
                      {{addSpacesBetweenWords(option)}}
                  </mat-option>
              </mat-select>
            </mat-form-field>
        </div>
        <div class="row">
            <mat-form-field>
              <mat-label>Can Be Modified By</mat-label>
              <mat-select name="modifiedBy" placeholder="Can Be Modified By" #modifiedBySelect="ngModel" [(ngModel)]="modifiedBy" required
                  [disabled]="!isItemFilterModifiable || !canItemFilterSharedWithAndOrModifiedByPropertiesBeChanged">
                  <mat-option *ngFor="let option of itemFilterSelectOptions" [value]="option">
                      {{addSpacesBetweenWords(option)}}
                  </mat-option>
              </mat-select>
            </mat-form-field>
        </div>
        <div class="row">
            <mat-form-field>
              <mat-label>Description (optional)</mat-label>
              <input matInput name="description" placeholder="Description (optional)" #descriptionSelect="ngModel" type="text" [(ngModel)]="description" 
                    ng-disabled="!isItemFilterModifiable" />
            </mat-form-field>
        </div>
        <div>
            <button mat-raised-button color="primary" type="submit" [disabled]="!itemFilterForm.valid || !isItemFilterModifiable">Save</button>
            <button mat-raised-button type="button" (click)="cancel()">Cancel</button>
        </div>
    </form>
</div>