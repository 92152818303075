import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PriceManagementDataService {

  signalRStoreItemUpdate;
  signalRDataExpirationDate;
  updatedRowNode;
  columnMetadata;
  storeItemPriceChangesEnabledForStoreObject:any = { value: false };
  meatAndProduceStoreItemPriceChangesEnabledForStoreObject:any = { value: false };
  previewStoreItemPriceChangesEnabledForStoreObject:any = { value: false };
  meatAndProducePreviewStoreItemPriceChangesEnabledForStoreObject:any = { value: false };
  serverMaintenanceEnabledStateChangeObject:any = { value: false };

  signalRStoreItemUpdate$: Observable<any>;
  private _signalRStoreItemUpdateSubject = new Subject<any>();

  signalRDataExpirationDate$: Observable<any>;
  private _signalRDataExpirationDateSubject = new Subject<any>();

  updatedRowNode$: Observable<any>;
  private _updatedRowNodeSubject = new BehaviorSubject<any>({});

  storeItemPriceChangesEnabled$: Observable<any>;
  private _storeItemPriceChangesEnabledSubject = new BehaviorSubject<any>(this.storeItemPriceChangesEnabledForStoreObject);

  meatAndProduceStoreItemPriceChangesEnabled$: Observable<any>;
  private _meatAndProduceStoreItemPriceChangesEnabledSubject = new BehaviorSubject<any>(this.meatAndProduceStoreItemPriceChangesEnabledForStoreObject);

  previewStoreItemPriceChangesEnabled$: Observable<any>;
  private _previewStoreItemPriceChangesEnabledSubject = new BehaviorSubject<any>(this.previewStoreItemPriceChangesEnabledForStoreObject);

  meatAndProducePreviewStoreItemPriceChangesEnabled$: Observable<any>;
  private _meatAndProducePreviewStoreItemPriceChangesEnabledSubject = new BehaviorSubject<any>(this.meatAndProducePreviewStoreItemPriceChangesEnabledForStoreObject);
  
  serverMaintenanceEnabled$: Observable<any>;
  private _serverMaintenanceEnabledSubject = new BehaviorSubject<any>(this.serverMaintenanceEnabledStateChangeObject);

  constructor() { 
    this.signalRStoreItemUpdate$ = this._signalRStoreItemUpdateSubject.asObservable();
    this.signalRDataExpirationDate$ = this._signalRDataExpirationDateSubject.asObservable();
    this.updatedRowNode$ = this._updatedRowNodeSubject.asObservable();
    this.storeItemPriceChangesEnabled$ = this._storeItemPriceChangesEnabledSubject.asObservable();
    this.meatAndProduceStoreItemPriceChangesEnabled$ = this._meatAndProduceStoreItemPriceChangesEnabledSubject.asObservable();
    this.previewStoreItemPriceChangesEnabled$ = this._previewStoreItemPriceChangesEnabledSubject.asObservable();
    this.meatAndProducePreviewStoreItemPriceChangesEnabled$ = this._meatAndProducePreviewStoreItemPriceChangesEnabledSubject.asObservable();
    this.serverMaintenanceEnabled$ = this._serverMaintenanceEnabledSubject.asObservable();
  }

  setSignalRStoreItemUpdate(storeItem){
    this.signalRStoreItemUpdate = storeItem;
    this._signalRStoreItemUpdateSubject.next(storeItem);
  }

  setSignalRDataExpirationDate(date){
    this.signalRDataExpirationDate = date;
    this._signalRDataExpirationDateSubject.next(date);
  }

  setUpdatedRowNode(updatedRowNode){
    this.updatedRowNode = updatedRowNode;
    this._updatedRowNodeSubject.next(updatedRowNode);
  }

  setStoreItemPriceChangesEnabledForStoreObject(obj){
    this.storeItemPriceChangesEnabledForStoreObject = obj;
    this._storeItemPriceChangesEnabledSubject.next(obj);
  }

  setMeatAndProduceStoreItemPriceChangesEnabledForStoreObject(obj){
    this.meatAndProduceStoreItemPriceChangesEnabledForStoreObject = obj;
    this._meatAndProduceStoreItemPriceChangesEnabledSubject.next(obj);
  }

  setPreviewStoreItemPriceChangesEnabledForStoreObject(obj){
    this.previewStoreItemPriceChangesEnabledForStoreObject = obj;
    this._previewStoreItemPriceChangesEnabledSubject.next(obj);
  }

  setMeatAndProducePreviewStoreItemPriceChangesEnabledForStoreObject(obj){
    this.meatAndProducePreviewStoreItemPriceChangesEnabledForStoreObject = obj;
    this._meatAndProducePreviewStoreItemPriceChangesEnabledSubject.next(obj);
  }

  setServerMaintenanceEnabledStateChangeObject(obj){
    this.serverMaintenanceEnabledStateChangeObject = obj;
    this._serverMaintenanceEnabledSubject.next(obj);
  }

  getColumnMetadata(){
    return this.columnMetadata;
  }

  setColumnMetadata(data){
    this.columnMetadata = data;
  }
}
