import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AppConfig } from '../app.config';

@Injectable({
  providedIn: 'root'
})
export class AnnouncementService {

  private _baseUrl = AppConfig.settings.baseUrl;
  private _reqHeader = new HttpHeaders({ 'Accept': 'application/json', 'Content-Type': 'application/json' });

  constructor(
    private _http: HttpClient, 
  ) { }
  
  getAllAnnouncements() {
    return this._http.get(this._baseUrl + '/api/Announcements/GetAllAnnouncements', { headers: this._reqHeader });
  }

  getActiveAnnouncements() {
    return this._http.get(this._baseUrl + '/api/Announcements/GetActiveAnnouncements', { headers: this._reqHeader });
  }

  getAnnouncementById(announcementId) {
    return this._http.get(this._baseUrl + '/api/Announcements/GetAnnouncementById/' + announcementId, { headers: this._reqHeader });
  }

  createAnnouncement(announcement) {
    return this._http.post(this._baseUrl + '/api/Announcements/CreateAnnouncement', announcement, { headers: this._reqHeader });
  }

  updateAnnouncement(announcementId, announcement) {
    return this._http.put(this._baseUrl + '/api/Announcements/UpdateAnnouncement/' + announcementId, announcement, { headers: this._reqHeader });
  }

  deleteAnnouncement(announcementId) {
    return this._http.delete(this._baseUrl + '/api/Announcements/DeleteAnnouncement/' + announcementId, { headers: this._reqHeader });
  }
}
