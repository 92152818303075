import { Component, OnInit, ViewChild, Inject, NgZone } from '@angular/core';
import { AgGridAngular } from 'ag-grid-angular';
import { GridOptions } from 'ag-grid-community';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { StoreService } from 'src/app/shared/store.service';
import { GridService } from 'src/app/shared/grid.service';
import { ViewDetailButtonComponent } from 'src/app/shared/view-detail-button/view-detail-button.component';
import { StoreGroupService } from 'src/app/shared/store-group.service';
import { DeleteButtonComponent } from 'src/app/shared/delete-button/delete-button.component';
import { MessageService } from 'src/app/shared/message.service';
import { ConfirmationDialogComponent } from 'src/app/shared/confirmation-dialog/confirmation-dialog.component';
import { PermissionsService } from 'src/app/shared/permissions.service';
import { AccessService } from 'src/app/shared/access.service';
import { TransferButtonComponent } from 'src/app/shared/transfer-button/transfer-button.component';
import { TransferService } from 'src/app/shared/transfer.service';
import { AdminStoreDetailComponent } from '../admin-store-detail/admin-store-detail.component';
import { DeletionHistoryDialogComponent } from 'src/app/shared/deletion-history-dialog/deletion-history-dialog.component';
import { ViewAssetsButtonComponent } from 'src/app/shared/view-assets-button/view-assets-button.component';
import { AssetsDialogComponent } from 'src/app/shared/assets-dialog/assets-dialog.component';
import { StoreTransferDialogComponent } from '../store-transfer-dialog/store-transfer-dialog.component';
import { AdminActionButtonsComponent } from 'src/app/shared/admin-action-buttons/admin-action-buttons.component';
import { AuthenticationService } from 'src/app/core/authentication/shared/authentication.service';

@Component({
  selector: 'app-admin-store-list',
  templateUrl: './admin-store-list.component.html',
  styleUrls: ['./admin-store-list.component.css']
})
export class AdminStoreListComponent implements OnInit {
  @ViewChild('agGrid') agGrid: AgGridAngular;

  adminDetailRef: MatDialogRef<AdminStoreDetailComponent>;
  ownedAssetsRef: MatDialogRef<AssetsDialogComponent>;
  confirmationDialogRef: MatDialogRef<ConfirmationDialogComponent>;
  transferDialogRef: MatDialogRef<StoreTransferDialogComponent>;
  deletionHistoryDialogRef: MatDialogRef<DeletionHistoryDialogComponent>;

  gridOptions: GridOptions;
  quickFilterText: string = '';
  context; //required by grid
  //frameworkComponents; //required by grid
  allStoreGroups;
  stores;
  permissions: any = {};
  myUser;

  rowData = [];

  getColumnDefs(){ 
    return [
      {
        headerName: "",
        field: "action",
        width: 70,
        minWidth: 70,
        maxWidth: 90,
        cellClass: "action-col",
        cellRenderer: this.permissions.roleAllowsUserToEditStore ? "ViewDetailButtonComponent" : null,
        pinned: "left",
        sortable: false,
        filter: false,
        suppressHeaderMenuButton: true,
        suppressNavigable: true,
        hide: !this.permissions.roleAllowsUserToEditStore,
      },
      {
        headerName: "Store #",
        field: "storeNumber",
        width: 100,
        minWidth: 100,
        cellClass: "storeNumber-col",
        filter: "agTextColumnFilter",
        get headerTooltip() { return this.headerName; }
      },
      {
        headerName: "Location",
        field: "location",
        cellClass: "location-col",
        filter: "agTextColumnFilter",
        get headerTooltip() { return this.headerName; }
      },
      {
        headerName: "Store Group",
        field: "storeGroup",
        cellClass: "storeGroup-col",
        filter: "agTextColumnFilter",
        get headerTooltip() { return this.headerName; }
      },
      {
        headerName: "Address",
        field: "address",
        cellClass: "address-col",
        filter: "agTextColumnFilter",
        get headerTooltip() { return this.headerName; }
      },
      {
        headerName: "Phone #",
        field: "phone",
        cellClass: "phone-col",
        cellRenderer: this._gridService.phoneNumberRenderer,
        filter: "agTextColumnFilter",
        get headerTooltip() { return this.headerName; }
      },
      {
        headerName: "Email",
        field: "email",
        cellClass: "email-col",
        filter: "agTextColumnFilter",
        get headerTooltip() { return this.headerName; }
      },
      {
        headerName: "Enabled",
        field: "active",
        width: 80,
        cellClass: ["active-col", "tac"],
        cellRenderer: this._gridService.checkRenderer,
        filter: "agSetColumnFilter",
        filterParams: {
          cellRenderer: this._gridService.checkFilterRenderer,
          values: [true, false],
          newRowsAction: 'keep'
        },
        get headerTooltip() { return this.headerName; }
      },
      {
        headerName: "Actions",
        field: "actions",
        width: 160,
        minWidth: 160,
        maxWidth: 160,
        cellClass: ["assets-col", "tac"],
        cellRenderer: "AdminActionButtonsComponent",
        sortable: false,
        filter: false,
        suppressHeaderMenuButton: true,
        suppressNavigable: true,
      },

      // {
      //   headerName: "Assets",
      //   field: "assets",
      //   width: 80,
      //   minWidth: 80,
      //   maxWidth: 80,
      //   cellClass: ["assets-col", "tac"],
      //   cellRenderer: this.permissions.roleAllowsUserToDeleteStore ? "ViewAssetsButtonComponent" : null,
      //   sortable: false,
      //   filter: false,
      //   suppressHeaderMenuButton: true,
      //   suppressNavigable: true,
      //   hide: !this.permissions.roleAllowsUserToDeleteStore
      // },
      // {
      //   headerName: "Transfer",
      //   field: "transfer",
      //   width: 80,
      //   minWidth: 80,
      //   maxWidth: 80,
      //   cellClass: ["transfer-col", "tac"],
      //   cellRenderer:  this.permissions.roleAllowsUserToTransferStoreAssets ? "TransferButtonComponent" : null,
      //   sortable: false,
      //   filter: false,
      //   suppressHeaderMenuButton: true,
      //   suppressNavigable: true,
      //   hide: !this.permissions.roleAllowsUserToTransferStoreAssets,
      // },
      // {
      //   headerName: "Delete",
      //   field: "delete",
      //   width: 80,
      //   minWidth: 80,
      //   maxWidth: 80,
      //   cellClass: ["delete-col", "tac"],
      //   cellRenderer:  this.permissions.roleAllowsUserToDeleteStore ? "DeleteButtonComponent" : null,
      //   sortable: false,
      //   filter: false,
      //   suppressHeaderMenuButton: true,
      //   suppressNavigable: true,
      //   hide: !this.permissions.roleAllowsUserToDeleteStore,
      // },

    ];
  }
  
  constructor(
    private _storeGroupService: StoreGroupService,
    private _storeService: StoreService,
    private _accessService: AccessService,
    private _transferService: TransferService,
    private _gridService: GridService,
    private _dialog: MatDialog, 
    private _messageService: MessageService,
    private _permissionsService: PermissionsService,
    private _authenticationService: AuthenticationService,
    private ngZone: NgZone,
    ) {
    this.permissions = this._permissionsService.getPermissions();
    this.myUser = this._authenticationService.getMyUser();

    this.gridOptions = <GridOptions> {
			columnDefs: this.getColumnDefs(),
      rowData: this.rowData,
      rowHeight: 35,    
      components: {
        ViewDetailButtonComponent: ViewDetailButtonComponent,
        ViewAssetsButtonComponent: ViewAssetsButtonComponent,
        DeleteButtonComponent: DeleteButtonComponent,
        TransferButtonComponent: TransferButtonComponent,
        AdminActionButtonsComponent: AdminActionButtonsComponent,
      },
      defaultColDef: {
        sortable: true,
        resizable: true,
        floatingFilter: true,
      },
      statusBar: {
        statusPanels: [
          { statusPanel: 'agTotalAndFilteredRowCountComponent', align: 'left' },
        ],
      },

    }
    this.context = { componentParent: this }
  }

  ngOnInit() {
    this.doOnInit();
  }

  ngAfterViewInit() {
    //this.doOnInit();
  }

  doOnInit(){
    this.getMyStoreGroupsWithStores();
  }

  getMyStoreGroupsWithStores(){
    this._storeGroupService.getMyStoreGroupsWithStores()
      .subscribe(
        (data) => {
          this.allStoreGroups = data;
          this.getAllStores();
        },
        (response) => {
          this._messageService.onFailure('Failed to get Store Groups.', response);
        })
  }

  getAllStores(){
    this._storeService.getAllStores()
      .subscribe(
        (data) => {
          this.stores = data;
          this.formatandSetRowData(data);
        },
        (response) => {
          this._messageService.onFailure('Failed to get Stores.', response);
        })
  }

  formatandSetRowData(data) {
    var gridData = [];
    for (var i=0; i<data.length; i++){
      var singleRowData = {};
      var actionFieldValue = '';
      var deleteFieldValue = '';
      singleRowData = {
        storeId: data[i].storeId, //needed this to pass to store detail dialog
        action: actionFieldValue,
        storeNumber: data[i].storeNumber,
        location: data[i].city + ", " + data[i].state,
        numberAndLocation: data[i].numberAndLocation,
        storeGroup: this.getStoreGroupName(data[i].storeGroupId),
        storeGroupId: data[i].storeGroupId,
        address: data[i].streetAddress,
        phone: data[i].phoneNumber,
        email: data[i].storeEmailAddress,
        active: data[i].active,
        delete: deleteFieldValue,
        permissions: { //used to show or hide action buttons
          roleAllowsUserToViewAssets: this.permissions.roleAllowsUserToViewStoreAssets, 
          roleAllowsUserToTransferAssets: this.permissions.roleAllowsUserToTransferStoreAssets, 
          roleAllowsUserToDelete: this.permissions.roleAllowsUserToDeleteStore,
          userHasReadOnlyRestriction: this.myUser.hasReadOnlyRestriction,
        },
    };
      gridData.push(singleRowData);
    }
    this.rowData = gridData;
    this._gridService.onWindowResize('#ag-grid-wrapper');
    setTimeout(() => {
      this.fitColumns();
      this._gridService.sizeGrid('#ag-grid-wrapper');
    }, 100); 
  }

  getStoreGroupName(storeGroupId) {
    for (var i = 0; i < this.allStoreGroups.length; i++) {
      if (this.allStoreGroups[i].storeGroupId == storeGroupId) return this.allStoreGroups[i].name;
    }
    return '';
  }

  clearFilters(){
    this.quickFilterText = '';
    this.agGrid.api.setGridOption('quickFilterText', this.quickFilterText);
    this.agGrid.api.setFilterModel(null);
    this.agGrid.api.onFilterChanged();
  }

  resetColumns(){
    this.agGrid.api.resetColumnState();
    this.fitColumns(); //since this is done by default also
  }

  autoSizeColumns(){
    var columns = this.agGrid.api.getColumns();
    this.agGrid.api.autoSizeColumns(columns);
  }

  fitColumns(){
    this.agGrid.api.sizeColumnsToFit();
  }

  onQuickFilterChange(){
    this.agGrid.api.setGridOption('quickFilterText', this.quickFilterText);
  }

  onDetailButtonClick(params){
    this.adminDetailRef = this._dialog.open(AdminStoreDetailComponent, {
      width: '90vw',
      maxWidth: '1000px',
      maxHeight: '90vh',
      data: {
        id: params.data.storeId,
        params: params,
        stores: this.stores,
        storeGroups: this.allStoreGroups,
        storeId: params.data.storeId,
        entityType: 'Store',
      }
    });

    const subscribeDialog = this.adminDetailRef.componentInstance.onOpenTransferDialog.subscribe((params) => {
      this.onTransferButtonClick(params)
    });

    // this.adminDetailRef.beforeClosed().subscribe(result => {
    //   console.log(this.adminDetailRef);
    //   let wasTransferMade = this.adminDetailRef.componentInstance.wasTransferMade;
    //   if (wasTransferMade) this.getMyStoreGroupsWithStores();
    //   this.adminDetailRef = null;
    // });

    this.adminDetailRef.afterClosed().subscribe(reload => {
      if(reload) {
        this.doOnInit();
      }
      subscribeDialog.unsubscribe();
      this.adminDetailRef = null;
    });
  }

  onAssetButtonClick(params){
    this.ownedAssetsRef = this._dialog.open(AssetsDialogComponent, {
      width: '90vw',
      maxWidth: '1000px',
      maxHeight: '90vh',
      data: {
        rowData: params.data,
        params: params,
        entityName: params.data.numberAndLocation,
        entityType: 'Store',
        storeId: params.data.storeId,
        storeGroupId: params.data.storeGroupId,
      }
    });
    const subscribeDialog = this.ownedAssetsRef.componentInstance.onOpenTransferDialog.subscribe((params) => {
      this.onTransferButtonClick(params)
    });
    this.ownedAssetsRef.afterClosed().subscribe(reload => {
      if(reload) {
        this.doOnInit();
      }
      subscribeDialog.unsubscribe();
      this.ownedAssetsRef = null;
    });
  }

  onTransferButtonClick(params) {
    this.openTransferDialog(params);
    // this.ngZone.run(() => {
    //   this._transferService.doesStoreHaveTransferableAssets(paramsRowData.storeId)
    //     .subscribe(
    //       (data: any) => { //returns boolean
    //         if (data) { 
    //           this.openTransferDialog(paramsRowData);
    //         }
    //         else{
    //           this._messageService.alert('This store does not have any transferable assets.');
    //         }
    //       },
    //       (response) => {
    //         this._messageService.onFailure('Failed to determine if this store has transferable assets.', response);
    //       }
    //     )
    // });
  }

  openTransferDialog(params) {
    this.transferDialogRef = this._dialog.open(StoreTransferDialogComponent, {
      disableClose: false,
      width: '90vw',
      maxWidth: '1000px',
      maxHeight: '90vh',
      data: {
        donatingEntityId: params.data.storeId,
        storeId: params.data.storeId,
        rowData: params.data,
        entityType: 'Store',
        entityName: params.data.storeNumber + ' - ' + params.data.location,
        stores: this.stores,
        storeGroupId: params.data.storeGroupId,
        storeGroups: this.allStoreGroups,
      }
    })
    this.transferDialogRef.afterClosed().subscribe(result => {
      if (result){
        this.getMyStoreGroupsWithStores();
      }
      this.transferDialogRef = null;
    });

  }

  onDeleteButtonClick(params){
    this.ngZone.run(() => {
      if (this.myUser.hasDeletionPrivilege) {
        this._accessService.isForcedDeletionRequiredForStore(params.data.storeId)
          .subscribe(
            (data: any) => {
              this.confirmDeletion(params, data.forcedDeletionRequired, data.reason);
            },
            (response) => {
              this._messageService.onFailure('Failed to determine if forced deletion is required.', response);
            }
          )
      }
      else {
        this._messageService.alert('You are not authorized to delete stores. If you believe you should have that privilege, contact an administrator.');
      }
    });
  }

  confirmDeletion(params, isForcedDeletionRequiredForStore, reason){
    //if (reason) reason = reason.replace(/\r\n/g, '<br/>').replace(/\.\.\./g, '<span class="indenter">&bull;</span>');
    if (reason) reason = reason.replace(/\r\n/g, '<br/>')
    .replace(/\u00b9/g, '<span class="indent-1"></span>')
    .replace(/\u00b2/g, '<span class="indent-2"></span>')
    .replace(/\u00b3/g, '<span class="indent-3"></span>')
    .replace(/\u2074/g, '<span class="indent-4"></span>')
    .replace(/\u2075/g, '<span class="indent-5"></span>')
    .replace(/\u2076/g, '<span class="indent-6"></span>')
    .replace(/\u2077/g, '<span class="indent-7"></span>')
    .replace(/\u2078/g, '<span class="indent-8"></span>');
    let msg = isForcedDeletionRequiredForStore ? reason : 
      '"Forced Deletion" is not required for this store. Do you want to delete ' + params.data.storeNumber + ', ' + params.data.location +'?';
    this.confirmationDialogRef = this._dialog.open(ConfirmationDialogComponent, {
      disableClose: false,
      //width: '600px',
      data: {
        title: 'Are you sure?',
        message: msg,
        confirmText: 'Yes, delete store',
        cancelText: 'No, cancel this action!',
        thirdButtonText: isForcedDeletionRequiredForStore ? 'Open Transfer Dialog' : undefined,
      }
    });
    this.confirmationDialogRef.afterClosed().subscribe(result => {
      if (result == 'thirdAction'){
        this.openTransferDialog(params);
      }
      else if(result) {
        if (isForcedDeletionRequiredForStore) this.deleteStore(params, true);
        else this.deleteStore(params, false);
      }
      this.confirmationDialogRef = null;
    });
  }

  onViewDeletionHistoryButtonClick() {
    this.deletionHistoryDialogRef = this._dialog.open(DeletionHistoryDialogComponent, {
      disableClose: false,
      width: '90vw',
      maxWidth: '1500px',
      maxHeight: '90vh',
      data: {
        entityType: 'Store'
      }
    })
  }

  deleteStore(params, forceDisassociationOrDeletionOfDependencies){
    //Users with hasReadOnlyRestriction set to true should not even be allowed to invoke this action because it is hidden or disabled in the UI.
    //This is for extra protection. The back end will also be protected.
    if (this.myUser.hasReadOnlyRestriction){
      this._messageService.alertReadOnlyRestriction();
      return;
    }

    this._storeService.deleteStore(params.data.storeId, forceDisassociationOrDeletionOfDependencies)
    .subscribe(
      (data) => {
        this._messageService.onSuccess('Successfully deleted store');
        this.getMyStoreGroupsWithStores();
      },
      (response) => {
        this._messageService.onFailure('Failed to delete store.', response);
      })
  }

  goToCreateStore(){
    //this._router.navigate(['/admin/users', 0]);
    this.adminDetailRef = this._dialog.open(AdminStoreDetailComponent, {
      width: '950px',
      //height: '700px',
      data: {
        id: '0'
      }
    });
    this.adminDetailRef.afterClosed().subscribe(result => {
      if(result === 'reload') {
        this.getMyStoreGroupsWithStores();
      }
      this.adminDetailRef = null;
    });
  }

}

@Component({
  selector: 'app-admin-store-detail-dialog',
  template: `<rpms-admin-store-detail [id]="data.id"></rpms-admin-store-detail>`,
})
export class AdminStoreDetailDialog {

  constructor(
    public dialogRef: MatDialogRef<AdminStoreDetailDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any) {}

}
