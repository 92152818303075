import { RouteReuseStrategy, ActivatedRouteSnapshot, DetachedRouteHandle } from "@angular/router";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: 'root'
})
export class CustomRouteReuseStrategy implements RouteReuseStrategy {

  handlers: { [key: string]: DetachedRouteHandle } = {};

  shouldDetach(route: ActivatedRouteSnapshot): boolean {
    var shouldReuse = route.data.shouldReuse;
    //console.log('shouldDetach: ' + shouldReuse || false);
    return shouldReuse || false;
  }

  store(route: ActivatedRouteSnapshot, handle: {}): void {
    if (route.data.shouldReuse) {
        //console.log('store: ');
        //console.log(handle);
        this.handlers[route.routeConfig.path] = handle;
    }
  }

  shouldAttach(route: ActivatedRouteSnapshot): boolean {
    var routeConfig = !!route.routeConfig;
    var handler = route.routeConfig ? !!this.handlers[route.routeConfig.path] : false;
    //console.log('shouldAttach: ' + (routeConfig && handler));
    return routeConfig && handler;
  }

  retrieve(route: ActivatedRouteSnapshot): {} {
    if (!route.routeConfig) return null;
    //console.log('retrieve: ' + this.handlers[route.routeConfig.path]);
    return this.handlers[route.routeConfig.path];
  }

  shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
    //console.log('shouldReuseRoute: ' + future.data.shouldReuse || false);
    return future.data.shouldReuse || false;
  }

}