<div class="transfer admin-dialog">
<h1 mat-dialog-title class="transfer-header">User Transfer: {{entityName}}</h1>
<mat-dialog-content>
<mat-tab-group dynamicHeight="true">
  <mat-tab label="Transfer">
    <form [formGroup]="assetTransferForm" id="asset-transfer-form" (ngSubmit)="onTransferClick()">

      <div class="no-privilege" *ngIf="!myUser.hasTransferPrivilege">You are not authorized to transfer assets. If you believe you should have that privilege, contact an administrator.</div>

      <mat-radio-group formControlName="userRadioButton" aria-label="Select an option" class="vertical-radio"
        (change)="onUserRadioButtonChange($event.value)">
        <mat-radio-button value="1">Transfer user: {{entityName}} to another Store Group and Home Store
        </mat-radio-button>
        <mat-radio-button value="2">Transfer user: {{entityName}} to another Home Store within their Store Group
        </mat-radio-button>
        <mat-radio-button value="3">Transfer the Assets of user: {{entityName}} to another User</mat-radio-button>
      </mat-radio-group>

      <div class="form-controls" *ngIf="showFormControls">

        <div class="row" *ngIf="showInheritingStoreGroupSelect && storeGroups.length > 0">
          <mat-form-field>
            <mat-label>Inheriting Store Group</mat-label>
            <mat-select id="inheritingStoreGroupId" formControlName="inheritingStoreGroupId"
              placeholder="Inheriting Store Group" (selectionChange)="onInheritingStoreGroupChange($event.value)" (click)="onSelectClick('inheritingStoreGroupId')">
              <mat-option *ngFor="let storeGroup of storeGroups" [value]="storeGroup.storeGroupId">
                {{storeGroup.name}}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="assetTransferForm.hasError('required', 'storeGroup')">Inheriting Store Group is required
            </mat-error>
          </mat-form-field>
        </div>
        <div class="row" *ngIf="showInheritingStoreGroupSelect && storeGroups.length == 0">
          <div class="no-assets">You have no other store groups available to which to transfer assets.</div>
        </div>

        <div class="row" *ngIf="showInheritingUserSelect && users.length > 0">
          <mat-form-field>
            <mat-label>Inheriting User</mat-label>
            <mat-select id="inheritingUserId" formControlName="inheritingUserId" placeholder="Inheriting User"
              (selectionChange)="onInheritingUserChange($event.value)" (click)="onSelectClick('inheritingUserId')">
              <mat-option *ngFor="let user of users" [value]="user.userId">
                {{user.fullNameAndEmail}}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="assetTransferForm.hasError('required', 'user')">Inheriting User is required</mat-error>
          </mat-form-field>
        </div>
        <div class="row" *ngIf="showInheritingUserSelect && users.length == 0">
          <div class="no-assets">You have no users available to which to transfer assets.</div>
        </div>

        <div class="row" *ngIf="showAssetTypeSelect">
          <mat-form-field>
            <mat-label>Asset Type</mat-label>
            <mat-select id="assetType" formControlName="assetType" placeholder="Asset Type" 
              (selectionChange)="onAssetTypeChange($event.value)">
              <mat-option *ngFor="let type of assetTypes" [value]="type">
                {{type}}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="assetTransferForm.hasError('required', 'user')">Asset Type is required</mat-error>
          </mat-form-field>
        </div>

        <div class="row" *ngIf="showItemFiltersSelect && itemFilters.length > 0">
          <mat-form-field>
            <mat-label>Item Filters</mat-label>
            <mat-select multiple id="itemFilterIds" formControlName="itemFilterIds" placeholder="Item Filters">
              <mat-option *ngIf="itemFilters.length > 1" [value]="0"
                (click)="selectAllOptions(sa1, 'itemFilterIds', 'allItemFilterIds')" #sa1>Select All</mat-option>
              <mat-option *ngFor="let itemFilter of itemFilters" [value]="itemFilter.itemFilterId">
                {{itemFilter.name}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="row" *ngIf="showItemFiltersSelect && itemFilters.length == 0 && areItemFiltersLoaded">
          <div class="no-assets">This user does not own any item filters.</div>
        </div>

        <div class="row" *ngIf="showItemListsSelect && itemLists.length > 0">
          <mat-form-field>
            <mat-label>Item Lists</mat-label>
            <mat-select multiple id="itemListIds" formControlName="itemListIds" placeholder="Item Lists">
              <mat-option *ngIf="itemLists.length > 1" [value]="0"
                (click)="selectAllOptions(sa2, 'itemListIds', 'allItemListIds')" #sa2>Select All</mat-option>
              <mat-option *ngFor="let itemList of itemLists" [value]="itemList.itemListId">
                {{itemList.name}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="row" *ngIf="showItemListsSelect && itemLists.length == 0 && areItemListsLoaded">
          <div class="no-assets">This user does not own any item lists.</div>
        </div>

        <div class="row" *ngIf="showManagedStoreGroupsSelect && managedStoreGroups.length > 0">
          <mat-form-field>
            <mat-label>Managed Store Groups</mat-label>
            <mat-select multiple id="managedStoreGroupIds" formControlName="managedStoreGroupIds" placeholder="Managed Store Groups">
              <mat-option *ngIf="managedStoreGroups.length > 1" [value]="0"
                (click)="selectAllOptions(sa3, 'managedStoreGroupIds', 'allManagedStoreGroupIds')" #sa3>Select All
              </mat-option>
              <mat-option *ngFor="let storeGroup of managedStoreGroups" [value]="storeGroup.storeGroupId">
                {{storeGroup.name}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="row" *ngIf="showManagedStoreGroupsSelect && managedStoreGroups.length == 0 && areManagedStoreGroupsLoaded">
          <div class="no-assets">This user does not manage any store groups.</div>
        </div>

        <div class="row" *ngIf="showHomeStoreSelect && homeStores.length > 0">
          <mat-form-field>
            <mat-label>Home Store</mat-label>
            <mat-select id="homeStoreId" formControlName="homeStoreId" placeholder="Home Store">
              <mat-option *ngFor="let store of homeStores" [value]="store.storeId">
                {{store.numberAndLocation}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="row" *ngIf="showHomeStoreSelect && homeStores.length == 0 && areHomeStoresLoaded">
          <div class="no-assets">There are no other stores available.</div>
        </div>
      </div>
      <div class="buttons-wrapper">
        <button mat-raised-button color="primary" [disabled]="!assetTransferForm.valid" type="submit">Transfer</button>
        <button mat-raised-button (click)="closeDialog()" type="button">Close</button>
      </div>

    </form>
  </mat-tab>

  <mat-tab label="Owned Assets">
    <ng-template mat-tab-label>
      <div>Owned Assets<span class="badge">{{assetCount}}</span></div>
    </ng-template>
    <app-user-owned-assets (assetCountEvent)="onAssetCountEvent($event)" [userId]="userId" [showTransferButton]="showTransferButton" [transferOccurred]="transferOccurred"></app-user-owned-assets>
    <div class="buttons-wrapper">
      <button mat-raised-button (click)="closeDialog()" type="button">Close</button>
    </div>
  </mat-tab>

  <mat-tab label="Transfer History">
    <ng-template mat-tab-label>
      <div>Transfer History<span class="badge">{{logEntries.length}}</span></div>
    </ng-template>
      <div class="log-scroller">
      <div class="log-entry col-headers">
        <div class="time">Date, Time</div>
        <div class="type">Asset Type</div>
        <div class="description">Description</div>
        <div class="entity">Inheriting Entity</div>
        <div class="user">Performed By</div>
      </div>
      <div class="log-entry" *ngFor="let logEntry of logEntries">
        <div class="time">{{logEntry.performedAt}}</div>
        <div class="type">{{logEntry.assetType}}</div>
        <div class="description" [innerHTML]="logEntry.transferDescription"></div>
        <div class="entity">{{logEntry.inheritingEntityName}}</div>
        <div class="user">{{logEntry.performedByFullName}}</div>
      </div>
    </div>
    <div class="buttons-wrapper">
      <button mat-raised-button (click)="closeDialog()" type="button">Close</button>
    </div>
  </mat-tab>
</mat-tab-group>
</mat-dialog-content>
</div>