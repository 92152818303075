import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormGroup, UntypedFormArray, UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatAccordion } from '@angular/material/expansion';
import { forEach } from 'cypress/types/lodash';
import { AuthenticationService } from 'src/app/core/authentication/shared/authentication.service';
import { AccessService } from 'src/app/shared/access.service';
import { ConfirmationDialogComponent } from 'src/app/shared/confirmation-dialog/confirmation-dialog.component';
import { DeletionService } from 'src/app/shared/deletion.service';
import { ItemFilterService } from 'src/app/shared/item-filter.service';
import { ItemListService } from 'src/app/shared/item-list.service';
import { MessageService } from 'src/app/shared/message.service';
import { PermissionsService } from 'src/app/shared/permissions.service';
import { StoreGroupService } from 'src/app/shared/store-group.service';
import { UserService } from '../../../shared/user.service';

@Component({
  selector: 'app-user-owned-assets',
  templateUrl: './user-owned-assets.component.html',
  styleUrls: ['./user-owned-assets.component.css']
})
export class UserOwnedAssetsComponent implements OnInit {
  @ViewChild(MatAccordion) accordion: MatAccordion;
  @Input() userId: any;
  @Input() showTransferButton: boolean;
  @Input() showDelete: boolean;
  @Input() transferOccurred: boolean;
  @Output() openTransferDialogEvent = new EventEmitter<any>();
  @Output() assetCountEvent = new EventEmitter<any>();
  @Output() deletionOccurredEvent = new EventEmitter<any>();

  confirmationDialogRef: MatDialogRef<ConfirmationDialogComponent>;

  assetCount;
  itemFilters = [];
  itemLists = [];
  managedStoreGroups = [];
  itemFilterDescription;
  itemListDescription;
  managedStoreGroupDescription;
  selectedItemFilterIds;
  selectedItemListIds;
  selectedManagedStoreGroupIds;
  shouldShowManagedStoreGroupsSection: boolean = false;

  itemFilterPanelOpenState: boolean = false;
  itemListPanelOpenState: boolean = false;
  managedStoreGroupPanelOpenState: boolean = false;

  itemFilterForm: UntypedFormGroup;
  itemListForm: UntypedFormGroup;
  managedStoreGroupForm: UntypedFormGroup;

  permissions: any = {};
  myUser;
  userHasDeletionPrivilege: boolean = false;
  
  get itemFiltersFormArray() {
    return this.itemFilterForm.controls.itemFilters as UntypedFormArray;
  }

  get itemListsFormArray() {
    return this.itemListForm.controls.itemLists as UntypedFormArray;
  }

  get  managedStoreGroupsFormArray() {
    return this.managedStoreGroupForm.controls.managedStoreGroups as UntypedFormArray;
  }

  constructor(
    private _fb: UntypedFormBuilder,
    private _itemFilterService: ItemFilterService,
    private _itemListService: ItemListService,
    private _storeGroupService: StoreGroupService,
    private _deletionService: DeletionService,
    private _accessService: AccessService,
    private _permissionsService: PermissionsService,
    private _authenticationService: AuthenticationService,
    private _userService: UserService,
    private _messageService: MessageService,
    private _dialog: MatDialog,
  ) { 
    this.itemFilterForm = this._fb.group({
      itemFilters: new UntypedFormArray([])
    });

    this.itemListForm = this._fb.group({
      itemLists: new UntypedFormArray([])
    });

    this.managedStoreGroupForm = this._fb.group({
      managedStoreGroups: new UntypedFormArray([])
    });

    this.permissions = this._permissionsService.getPermissions();
    this.myUser = this._authenticationService.getMyUser();
    if (this.myUser.hasDeletionPrivilege) this.userHasDeletionPrivilege = true;
  }
  
  addItemFilterCheckboxesToForm(arr) {
    arr.forEach(() => this.itemFiltersFormArray.push(new UntypedFormControl(false)));
  }

  addItemListCheckboxesToForm(arr) {
    arr.forEach(() => this.itemListsFormArray.push(new UntypedFormControl(false)));
  }

  addManagedStoreGroupCheckboxesToForm(arr) {
    arr.forEach(() => this.managedStoreGroupsFormArray.push(new UntypedFormControl(false)));
  }
  
  ngOnInit(): void {
    this.doOnInit();
  }

  doOnInit(){
    this.itemFilterForm = this._fb.group({
      itemFilters: new UntypedFormArray([])
    });

    this.itemListForm = this._fb.group({
      itemLists: new UntypedFormArray([])
    });

    this.managedStoreGroupForm = this._fb.group({
      managedStoreGroups: new UntypedFormArray([])
    });
    this.getUserById();
    this.getOwnedItemFilters(this.userId);
  }

  ngOnChanges(changes) {
    //transferOccurred change will cause a reload of this component
    if (changes && changes?.transferOccurred?.currentValue) this.doOnInit();
    //console.log(changes);
  }

  onTransferButtonClick(){
    this.openTransferDialogEvent.next(null);
  }  

  getUserById(){
    this._userService.getUserById(this.userId)
    .subscribe(
      (data:any) => {
        if (data.roles[0] == 'SuperStoreGroupAdmin'){
          this.shouldShowManagedStoreGroupsSection = true;
        }
      },
      (response) => {
        this._messageService.onFailure('Failed to get selected user.', response);
      })
  }

  getOwnedItemFilters(userId){
    this._itemFilterService.getOwnedItemFilters(userId)
      .subscribe(
        (data:any) => {
          this.itemFilters = data;
          switch(data.length){
            case 1: this.itemFilterDescription = '1 Item Filter is owned by this user';
              break;
            default: this.itemFilterDescription = data.length + ' Item Filters are owned by this user';
              break
          }
          this.addItemFilterCheckboxesToForm(this.itemFilters);
          this.getOwnedItemLists(this.userId);
        },
        (response) => {
          this._messageService.onFailure('Failed to get owned Item Filters.', response);
        })
  }

  getOwnedItemLists(userId){
    this._itemListService.getOwnedItemLists(userId)
      .subscribe(
        (data:any) => {
          this.itemLists = data;
          switch(data.length){
            case 1: this.itemListDescription = '1 Item List is owned by this user';
              break;
            default: this.itemListDescription = data.length + ' Item Lists are owned by this user';
              break
          }
          this.addItemListCheckboxesToForm(this.itemLists);
          this.getManagedStoreGroupsByUserId(this.userId);
        },
        (response) => {
          this._messageService.onFailure('Failed to get Item Lists.', response);
        })
  }

  getManagedStoreGroupsByUserId(userId){
    this._storeGroupService.getManagedStoreGroupsByUserId(userId)
      .subscribe(
        (data:any) => {
          //this.managedStoreGroups = data;
          this.managedStoreGroups = data
            .sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : -1)
            .map(obj => ({ ...obj, storesInGroup: this.getStoreNumbers(obj.stores) })) 
          switch(data.length){
            case 1: this.managedStoreGroupDescription = '1 Store Group is managed by this user';
              break;
            default: this.managedStoreGroupDescription = data.length + ' Store Groups are managed by this user';
              break
          }
          this.addManagedStoreGroupCheckboxesToForm(this.managedStoreGroups);
          this.assetCount = this.itemFilters.length + this.itemLists.length + this.managedStoreGroups.length;
          this.assetCountEvent.next(this.assetCount);
        },
        (response) => {
          this._messageService.onFailure('Failed to get managed Store Groups for User.', response);
        })
  }

  getStoreNumbers(stores) {
    let storeInfo = [];
    stores.forEach((store) => { storeInfo.push(store.numberAndLocation) })
    return storeInfo.join(' | ');
  }

  selectAllItemFilters(bool){
    this.itemFiltersFormArray.controls.map(control=>{
      control.setValue(bool);
    });
  }

  onItemFilterDeleteClick(){
    if (this.myUser.hasDeletionPrivilege){
      this.selectedItemFilterIds = this.itemFilterForm.value.itemFilters
        .map((checked, i) => checked ? this.itemFilters[i].itemFilterId : null)
        .filter(v => v !== null);
      //console.log(this.selectedItemFilterIds);
      if (this.selectedItemFilterIds.length > 0) this.confirmDeletion('Item Filters', this.selectedItemFilterIds, false);
      else this._messageService.alert('No item filters are selected. Please select at least one item filter and try again.');
    }
    else {
      this._messageService.alert('You are not authorized to delete item filters. If you believe you should have that privilege, contact an administrator.');
    }
  }

  selectAllItemLists(bool){
    this.itemListsFormArray.controls.map(control=>{
      control.setValue(bool);
    });
  }

  onItemListDeleteClick(){
    if (this.myUser.hasDeletionPrivilege){
      this.selectedItemListIds = this.itemListForm.value.itemLists
        .map((checked, i) => checked ? this.itemLists[i].itemListId : null)
        .filter(v => v !== null);
      //console.log(this.selectedItemListIds);
      if (this.selectedItemListIds.length > 0) this.confirmDeletion('Item Lists', this.selectedItemListIds, false);
      else this._messageService.alert('No item lists are selected. Please select at least one item list and try again.');
    }
    else {
      this._messageService.alert('You are not authorized to delete item lists. If you believe you should have that privilege, contact an administrator.');
    }
  }

  selectAllManagedStoreGroups(bool){
    this.managedStoreGroupsFormArray.controls.map(control=>{
      control.setValue(bool);
    });
  }

  onManagedStoreGroupDeleteClick(){
    if (this.myUser.hasDeletionPrivilege){
      this.selectedManagedStoreGroupIds = this.managedStoreGroupForm.value.managedStoreGroups
        .map((checked, i) => checked ? this.managedStoreGroups[i].storeGroupId : null)
        .filter(v => v !== null);
      //console.log(this.selectedManagedStoreGroupIds);
      if (this.selectedManagedStoreGroupIds.length > 0) this.confirmDeletion('Managed Store Groups', this.selectedManagedStoreGroupIds, false);
      else this._messageService.alert('No managed store groups are selected. Please select at least one managed store group and try again.');
    }
    else {
      this._messageService.alert('You are not authorized to unmanage store groups. If you believe you should have that privilege, contact an administrator.');
    }
  }

  deleteAssets(assetType, selectedIds, forceDeletion){
    //Users with hasReadOnlyRestriction set to true should not even be allowed to invoke this action because it is hidden or disabled in the UI.
    //This is for extra protection. The back end will also be protected.
    if (this.myUser.hasReadOnlyRestriction){
      this._messageService.alertReadOnlyRestriction();
      return;
    }
    
    this._deletionService.deleteSelectAssetsFromEntity(this.userId, 'User', assetType, forceDeletion, selectedIds)
      .subscribe(
        (data:any) => {
          this._messageService.onSuccess('Successfully performed action on selected assets');
          this.deletionOccurredEvent.next(true);
          this.doOnInit();
        },
        (response) => {
          let reason = response.error.modelState ? response.error?.modelState[''].join(' ') : undefined;
          if (reason) this.confirmLongDeletion(assetType, selectedIds, reason);
          else this._messageService.onFailure('Failed to performed action on selected assets.', response);
        })
  }

  confirmDeletion(assetType, selectedIds, forceDeletion){
    this.confirmationDialogRef = this._dialog.open(ConfirmationDialogComponent, {
      disableClose: false,
      //width: '600px',
      maxHeight: '80vh',
      data: {
        title: 'Are you sure?',
        message: `Do you want to perform this action with the selected assets of type: ${assetType}?`,
        confirmText: 'Yes, proceed with this action',
        cancelText: 'No, cancel this action!',
        thirdButtonText: undefined,
      }
    });
    this.confirmationDialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.deleteAssets(assetType, selectedIds, false);
      }
      this.confirmationDialogRef = null;
    });
  }

  confirmLongDeletion(assetType, selectedIds, reason){
    if (reason) reason = reason.replace(/\r\n/g, '<br/>')
      .replace(/\u00b9/g, '<span class="indent-1"></span>')
      .replace(/\u00b2/g, '<span class="indent-2"></span>')
      .replace(/\u00b3/g, '<span class="indent-3"></span>')
      .replace(/\u2074/g, '<span class="indent-4"></span>')
      .replace(/\u2075/g, '<span class="indent-5"></span>')
      .replace(/\u2076/g, '<span class="indent-6"></span>')
      .replace(/\u2077/g, '<span class="indent-7"></span>')
      .replace(/\u2078/g, '<span class="indent-8"></span>');
    this.confirmationDialogRef = this._dialog.open(ConfirmationDialogComponent, {
      disableClose: false,
      //width: '600px',
      maxHeight: '80vh',
      data: {
        title: 'Are you sure?',
        message: reason,
        confirmText: 'Yes, proceed with this action',
        cancelText: 'No, cancel this action!',
        thirdButtonText: undefined,
      }
    });
    this.confirmationDialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.deleteAssets(assetType, selectedIds, true);
      }
      this.confirmationDialogRef = null;
    });
  }

  addSpacesBetweenWords(v) {
    if (v != null && v != '' && v != undefined) {
      var valueNoSpaces = v.replace(/\s/g, '');//first remove all spaces if any
      var valueWithSpaces = valueNoSpaces.replace(/([A-Z])/g, ' $1');
      valueWithSpaces = valueWithSpaces.substr(1); //remove space before first word
      return valueWithSpaces;
    }
  }

}
