import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'phoneNumber'
})
export class PhoneNumberPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    let phoneNumber;
    if (value !== undefined && value !== null) {
        let str = String(value);
        str.length == 10
          ? (phoneNumber =
              '(' +
              str.substring(0, 3) +
              ') ' +
              str.substring(3, 6) +
              '-' +
              str.substring(6))
          : (phoneNumber = value);
      return phoneNumber;
    } else {
      return "";
    }
  }

}