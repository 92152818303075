import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { AppConfig } from '../app.config';

@Injectable({
  providedIn: 'root'
})
export class RpmsMainframeOperationsService {

  private _baseUrl = AppConfig.settings.baseUrl;
  private _reqHeader = new HttpHeaders({ 'Accept': 'application/json', 'Content-Type': 'application/json' });

  constructor ( 
    private _http: HttpClient, 
  ){}

  updatePreviewStoreItemPricing(previewStoreItemId, previewStoreItem) {
    return this._http.put(this._baseUrl + '/api/RpmsToMainframeOperations/UpdatePreviewStoreItemPricing/' + previewStoreItemId, previewStoreItem, { headers: this._reqHeader });
  }

  updateStoreItemPricing(storeItemId, storeItem) {
    return this._http.put(this._baseUrl + '/api/RpmsToMainframeOperations/UpdateStoreItemPricing/' + storeItemId, storeItem, { headers: this._reqHeader });
  }

  updatePreviewStoreItemTprTagStateToAlways(previewStoreItemId, tagModel) {
    return this._http.put(this._baseUrl + '/api/RpmsToMainframeOperations/UpdatePreviewStoreItemTprTagStateToAlways/' + previewStoreItemId, tagModel, { headers: this._reqHeader });
  }

  updateStoreItemTprTagStateToAlways(storeItemId, tagModel) {
    return this._http.put(this._baseUrl + '/api/RpmsToMainframeOperations/UpdateStoreItemTprTagStateToAlways/' + storeItemId, tagModel, { headers: this._reqHeader });
  }

  updatePreviewStoreItemTprTagStateToOnce(previewStoreItemId, tagModel) {
    return this._http.put(this._baseUrl + '/api/RpmsToMainframeOperations/UpdatePreviewStoreItemTprTagStateToOnce/' + previewStoreItemId, tagModel, { headers: this._reqHeader });
  }

  updateStoreItemTprTagStateToOnce(storeItemId, tagModel) {
    return this._http.put(this._baseUrl + '/api/RpmsToMainframeOperations/UpdateStoreItemTprTagStateToOnce/' + storeItemId, tagModel, { headers: this._reqHeader });
  }

  deletePreviewStoreItemTprTag(previewStoreItemId) {
    return this._http.delete(this._baseUrl + '/api/RpmsToMainframeOperations/DeletePreviewStoreItemTprTag/' + previewStoreItemId, { headers: this._reqHeader });
  }

  deleteStoreItemTprTag(storeItemId) {
    return this._http.delete(this._baseUrl + '/api/RpmsToMainframeOperations/DeleteStoreItemTprTag/' + storeItemId, { headers: this._reqHeader });
  }

  updatePreviewStoreItemShelfTagState(previewStoreItemId, tagModel) {
    return this._http.put(this._baseUrl + '/api/RpmsToMainframeOperations/UpdatePreviewStoreItemShelfTagState/' + previewStoreItemId, tagModel, { headers: this._reqHeader });
  }

  updateStoreItemShelfTagState(storeItemId, tagModel) {
    return this._http.put(this._baseUrl + '/api/RpmsToMainframeOperations/UpdateStoreItemShelfTagState/' + storeItemId, tagModel, { headers: this._reqHeader });
  }

  deletePreviewStoreItemShelfTag(previewStoreItemId) {
    return this._http.delete(this._baseUrl + '/api/RpmsToMainframeOperations/DeletePreviewStoreItemShelfTag/' + previewStoreItemId, { headers: this._reqHeader });
  }

  deleteStoreItemShelfTag(storeItemId) {
    return this._http.delete(this._baseUrl + '/api/RpmsToMainframeOperations/DeleteStoreItemShelfTag/' + storeItemId, { headers: this._reqHeader });
  }

  deleteStoreItem(previewStoreItemId) {
    return this._http.delete(this._baseUrl + '/api/RpmsToMainframeOperations/DeleteStoreItem/' + previewStoreItemId, { headers: this._reqHeader });
  }

  deletePreviewStoreItem(storeItemId) {
    return this._http.delete(this._baseUrl + '/api/RpmsToMainframeOperations/DeletePreviewStoreItem/' + storeItemId, { headers: this._reqHeader });
  }

}
