<div id="help-page" class="content cf">
    <h1><i class="far fa-question-circle fa-lg"></i>Help</h1>
    <p>RPMS Version: {{version}}</p>
    <hr style="margin: 0 0 30px 0;" />
    <h2>Technical Support</h2>
    <p>Weekday Support: <strong>(800) 947-3328</strong> or <strong>(205) 481-2328</strong></p>
    <p>Weekend Support: <strong>(800) 947-3319</strong></p>
    <p>Email Support: <a href="mailto:support@pwadc.com">support&#64;pwadc.com</a></p>
    <a class="button" href="http://showmypc.com/users/appbuilder/showmypcauto.php?at=&ci=pig&pr=1&tk=cbbf0d5f1a28" target="_blank">Show My PC</a>
    <a class="button" href="https://gorpms.screenconnect.com/" target="_blank">Screen Connect</a>
    <hr style="margin: 30px 0;" />
    <h2>RPMS User Manual</h2>
    <i class="far fa-file-pdf" style="margin-right: 10px;"></i> <a href="../../assets/pdfs/RPMS_User_Manual_v2.pdf" download="RPMS_User_Manual_v2.pdf">RPMS User Manual (4.7MB PDF)</a>
    <p>Clicking the link above will start the download.  After clicking, see Chrome's status bar at the bottom of this window.</p>
    <hr style="margin: 30px 0;" />
    <h2>Training Videos</h2>
    <p>After pressing the play button, click the full screen button ( <img id="full-screen-icon" src="/assets/images/fullscreen.svg" />
        ) located on the controls of each video to view it full screen. Press the 'Esc' key to exit full screen
        mode.</p>
      <section class="cf">
        <div class="video-wrapper">
          <i class="fa fa-spinner fa-pulse fa-3x fa-fw"></i>
          <div class="video-box">
              <div class="iframe-wrapper">
                <video src="assets/videos/Price Change using price editor window4.mp4" width="100%" controls></video>
              </div>
              <h3>RPMS Training: Price Change - Using Price Editor (ver. 2.0)</h3>
          </div>
      </div>
      <div class="video-wrapper">
          <i class="fa fa-spinner fa-pulse fa-3x fa-fw"></i>
          <div class="video-box">
              <div class="iframe-wrapper">
                <video src="assets/videos/Price Change using cell editing2.mp4" width="100%" controls></video>
              </div>
              <h3>RPMS Training: Price Change - Using Cell Editing (ver. 2.0)</h3>
          </div>
      </div>
      <div class="video-wrapper">
          <i class="fa fa-spinner fa-pulse fa-3x fa-fw"></i>
          <div class="video-box">
              <div class="iframe-wrapper">
                <video src="assets/videos/Bulk Price Changes using master inputs.mp4" width="100%" controls></video>
              </div>
              <h3>RPMS Training: Bulk Price Changes (ver. 2.0)</h3>
          </div>
      </div>
      <div class="video-wrapper">
          <i class="fa fa-spinner fa-pulse fa-3x fa-fw"></i>
          <div class="video-box">
              <div class="iframe-wrapper">
                <video src="assets/videos/Columns.mp4" width="100%" controls></video>
              </div>
              <h3>RPMS Training: Columns (ver. 2.0)</h3>
          </div>
      </div>
      <div class="video-wrapper">
          <i class="fa fa-spinner fa-pulse fa-3x fa-fw"></i>
          <div class="video-box">
              <div class="iframe-wrapper">
                <video src="assets/videos/Sorting.mp4" width="100%" controls></video>
              </div>
              <h3>RPMS Training: Sorting (ver. 2.0)</h3>
          </div>
      </div>
      <div class="video-wrapper">
          <i class="fa fa-spinner fa-pulse fa-3x fa-fw"></i>
          <div class="video-box">
              <div class="iframe-wrapper">
                <video src="assets/videos/Filters.mp4" width="100%" controls></video>
              </div>
              <h3>RPMS Training: Filters (ver. 2.0)</h3>
          </div>
      </div>
      <div class="video-wrapper">
          <i class="fa fa-spinner fa-pulse fa-3x fa-fw"></i>
          <div class="video-box">
              <div class="iframe-wrapper">
                <video src="assets/videos/Item Lists.mp4" width="100%" controls></video>
              </div>
              <h3>RPMS Training: Item Lists (ver. 2.0)</h3>
          </div>
      </div>
      <div class="video-wrapper">
          <i class="fa fa-spinner fa-pulse fa-3x fa-fw"></i>
          <div class="video-box">
              <div class="iframe-wrapper">
                <video src="assets/videos/Tag Requests.mp4" width="100%" controls></video>
              </div>
              <h3>RPMS Training: Tag Requests (ver. 2.0)</h3>
          </div>
      </div>
    </section>
    <hr style="margin: 30px 0;" />
    <section class="cf">
        <h2>RPMS version 1 Videos</h2>
        <p>The following videos show RPMS version 1, but the information is still relevant to version 2.</p>
    
      <div class="video-wrapper">
        <i class="fa fa-spinner fa-pulse fa-3x fa-fw"></i>
        <div class="video-box">
            <div class="iframe-wrapper">
                <iframe src="https://www.youtube.com/embed/E7mOsASoHPk?rel=0" frameborder="0" allow="autoplay; encrypted-media"
                    allowfullscreen></iframe>
            </div>
            <h3>RPMS Reload (ver. 1.0)</h3>
        </div>
    </div>
    <div class="video-wrapper">
        <i class="fa fa-spinner fa-pulse fa-3x fa-fw"></i>
        <div class="video-box">
            <div class="iframe-wrapper">
                <iframe src="https://www.youtube.com/embed/D8ojlgvjUQE?rel=0" frameborder="0" allow="autoplay; encrypted-media"
                    allowfullscreen></iframe>
            </div>
            <h3>RPMS Training: Tags (ver. 1.0)</h3>
        </div>
    </div>
    <div class="video-wrapper">
        <i class="fa fa-spinner fa-pulse fa-3x fa-fw"></i>
        <div class="video-box">
            <div class="iframe-wrapper">
                <iframe src="https://www.youtube.com/embed/6JRL-ZJk7hc?rel=0" frameborder="0" allow="autoplay; encrypted-media"
                    allowfullscreen></iframe>
            </div>
            <h3>RPMS Training: Footer Info (ver. 1.0)</h3>
        </div>
    </div>
    <div class="video-wrapper">
        <i class="fa fa-spinner fa-pulse fa-3x fa-fw"></i>
        <div class="video-box">
            <div class="iframe-wrapper">
                <iframe src="https://www.youtube.com/embed/mTBJnXOeMlg?rel=0" frameborder="0" allow="autoplay; encrypted-media"
                    allowfullscreen></iframe>
            </div>
            <h3>RPMS Training: Filtering (ver. 1.0)</h3>
        </div>
    </div>
    <div class="video-wrapper">
        <i class="fa fa-spinner fa-pulse fa-3x fa-fw"></i>
        <div class="video-box">
            <div class="iframe-wrapper">
                <iframe src="https://www.youtube.com/embed/o_OlhSMt33w?rel=0" frameborder="0" allow="autoplay; encrypted-media"
                    allowfullscreen></iframe>
            </div>
            <h3>RPMS Training: Preview Price Managment (ver. 1.0)</h3>
        </div>
    </div>
  </section>
</div>