
//PRODUCTION

export const environment = {
  name: "prod",
  production: true, //means this is a production build, not deployed to production
  isDevMode: false,  //will not show DEV in header
  baseUrl: "https://webapi.gorpms.com", //PROD
  version: "2.3.0.20240823-1200"
};
