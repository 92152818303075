import { Component, AfterContentInit, ViewChild } from '@angular/core';
import { PriceManagementFilterService } from '../price-management-filter.service';
import { PriceManagementDataService } from '../price-management-data.service';
//import { environment } from 'src/environments/environment';
import { AppConfig } from 'src/app/app.config';

@Component({
  selector: 'rpms-price-management-tabs',
  templateUrl: './price-management-tabs.component.html',
  styleUrls: ['./price-management-tabs.component.css']
})
export class PriceManagementTabsComponent implements AfterContentInit {
  private config = AppConfig.settings;

  tabModel = {
    activeTab: 'Grocery',
    activeSubTab: 'StockItems'
  }
  storeItemPriceChangesEnabledForStoreObject:any;
  meatAndProduceStoreItemPriceChangesEnabledForStoreObject:any;
  previewStoreItemPriceChangesEnabledForStoreObject:any;
  meatAndProducePreviewStoreItemPriceChangesEnabledForStoreObject:any;

  tabModelSubscription;
  storeItemPriceChangesEnabledSubscription;
  meatAndProduceStoreItemPriceChangesEnabledSubscription;
  previewStoreItemPriceChangesEnabledSubscription;
  meatAndProducePreviewStoreItemPriceChangesEnabledSubscription;

  constructor(
    private _priceManagementFilterService: PriceManagementFilterService,
    private _priceManagementDataService: PriceManagementDataService,
  ) { 
  }

  //the reason for ngAfterContentInit - https://stackoverflow.com/questions/43375532/expressionchangedafterithasbeencheckederror-explained
  ngAfterContentInit(){
    if (this.config.logInits) console.log('tabs init');
    this.tabModelSubscription = this._priceManagementFilterService.tabModel$
      .subscribe(
        (data) => {
          this.tabModel = data; 
        }
      );
      this.storeItemPriceChangesEnabledSubscription = this._priceManagementDataService.storeItemPriceChangesEnabled$
      .subscribe(
        (data) => {
          this.storeItemPriceChangesEnabledForStoreObject = data; 
        }
      );
      this.meatAndProduceStoreItemPriceChangesEnabledSubscription = this._priceManagementDataService.meatAndProduceStoreItemPriceChangesEnabled$
      .subscribe(
        (data) => {
          this.meatAndProduceStoreItemPriceChangesEnabledForStoreObject = data; 
        }
      );
      this.previewStoreItemPriceChangesEnabledSubscription = this._priceManagementDataService.previewStoreItemPriceChangesEnabled$
      .subscribe(
        (data) => {
          this.previewStoreItemPriceChangesEnabledForStoreObject = data; 
        }
      );
      this.meatAndProducePreviewStoreItemPriceChangesEnabledSubscription = this._priceManagementDataService.meatAndProducePreviewStoreItemPriceChangesEnabled$
      .subscribe(
        (data) => {
          this.meatAndProducePreviewStoreItemPriceChangesEnabledForStoreObject = data; 
        }
      );
  }

  ngOnDestroy(){
    this.tabModelSubscription.unsubscribe();
    this.storeItemPriceChangesEnabledSubscription.unsubscribe();
    this.meatAndProduceStoreItemPriceChangesEnabledSubscription.unsubscribe();
    this.previewStoreItemPriceChangesEnabledSubscription.unsubscribe();
    this.meatAndProducePreviewStoreItemPriceChangesEnabledSubscription.unsubscribe();
  }

  onTabClick(tab, subTab){
    this.tabModel.activeTab = tab;
    this.tabModel.activeSubTab = subTab;
    setTimeout(() => this._priceManagementFilterService.setGridFilterModelFromTabModel(this.tabModel), 1); 
  }

}
