import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MessageService } from 'src/app/shared/message.service';
import { PriceManagementService } from 'src/app/shared/price-management.service';

@Component({
  selector: 'rpms-controlled-store-pricing',
  templateUrl: './controlled-store-pricing.component.html',
  styleUrls: ['./controlled-store-pricing.component.css']
})
export class ControlledStorePricingComponent implements OnInit {
  @Input() rowNodeToUpdate: any;
  @Input() selectedStore: any;
  @Output() isControlledStorePricingVisibleEvent = new EventEmitter<boolean>();

  rowNode;
  rowData;
  pricingData;

  constructor(
    private _priceManagementService: PriceManagementService,
    private _messageService: MessageService,
  ) { }

  ngOnInit() {
    this.rowNode = this.rowNodeToUpdate;
    this.rowData = this.rowNode.data;
    //console.log(this.rowNode);
    if (this.rowData) {
      if (this.rowData.isPreview) this.getControllingAndControlledPreviewStoreItemsByStoreIdAndItemId(this.selectedStore.storeId, this.rowData.itemId);
      else this.getControllingAndControlledStoreItemsByStoreIdAndItemId(this.selectedStore.storeId, this.rowData.itemId);
    }
  }

  getControllingAndControlledStoreItemsByStoreIdAndItemId(storeId, itemId) {
    this._priceManagementService.getControllingAndControlledStoreItemsByStoreIdAndItemId(storeId, itemId)
      .subscribe(
        (data:any[]) => {
          let controllingStoreIndex = 0;
          for (let i=0; i<data.length; i++) {
            if (data[i].store.controllingStoreId == null) {
              data[i].store.isControllingStore = true;
              controllingStoreIndex = i;
            }
            else{
              data[i].isControllingStore = false;
            }
          }
          let controlledStore = data.splice(controllingStoreIndex, 1)[0]; //returns array, so need item at 0 index
          data.unshift(controlledStore);
          this.pricingData = data;
          //console.log(data);
        },
        (response) => {
          this._messageService.onFailure('Failed to get controlling and controlled store items.', response);
        }
      )
  }

  getControllingAndControlledPreviewStoreItemsByStoreIdAndItemId(storeId, itemId) {
    this._priceManagementService.getControllingAndControlledPreviewStoreItemsByStoreIdAndItemId(storeId, itemId)
      .subscribe(
        (data) => {
          this.pricingData = data;
          console.log(data)
        },
        (response) => {
          this._messageService.onFailure('Failed to get controlling and controlled store items.', response);
        }
      )
  }

  close(){
    this.isControlledStorePricingVisibleEvent.next(false);
  }

}
