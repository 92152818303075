import { Component} from '@angular/core';
import {ICellRendererAngularComp} from 'ag-grid-angular';

@Component({
  selector: 'app-view-detail-button',
  template: `<span><button (click)="invokeParentMethod()" class="btn btn-info" title="View Detail"><i class="far fa-eye"></i></button></span>`,
  styles: [
    `.btn {
             line-height: 0.5
         }`
  ]
})
export class ViewDetailButtonComponent implements ICellRendererAngularComp  {

  public params: any;

  agInit(params: any): void {
      this.params = params;
  }

  public invokeParentMethod() {
      this.params.context.componentParent.onDetailButtonClick(this.params);
  }

  refresh(): boolean {
      return false;
  }

}
