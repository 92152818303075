import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatAccordion } from '@angular/material/expansion';
import { AuthenticationService } from 'src/app/core/authentication/shared/authentication.service';
import { AccessService } from 'src/app/shared/access.service';
import { ConfirmationDialogComponent } from 'src/app/shared/confirmation-dialog/confirmation-dialog.component';
import { DeletionService } from 'src/app/shared/deletion.service';
import { MessageService } from 'src/app/shared/message.service';
import { PermissionsService } from 'src/app/shared/permissions.service';
import { PricingRulesService } from 'src/app/shared/pricing-rules.service';
import { StoreService } from 'src/app/shared/store.service';
import { UserService } from 'src/app/shared/user.service';

@Component({
  selector: 'app-store-owned-assets',
  templateUrl: './store-owned-assets.component.html',
  styleUrls: ['./store-owned-assets.component.css']
})
export class StoreOwnedAssetsComponent implements OnInit {
  @ViewChild(MatAccordion) accordion: MatAccordion;
  @Input() storeId: any;
  @Input() showTransferButton: boolean;
  @Input() showDelete: boolean;
  @Input() transferOccurred: boolean;
  @Output() openTransferDialogEvent = new EventEmitter<any>();
  @Output() assetCountEvent = new EventEmitter<any>();
  @Output() deletionOccurredEvent = new EventEmitter<any>();

  confirmationDialogRef: MatDialogRef<ConfirmationDialogComponent>;

  assetCount;
  usersDescription;
  pricingRulesDescription;
  controlledStoresDescription;
  users = [];
  pricingRules = [];
  controlledStores = [];
  selectedUserIds;
  selectedPricingRuleIds;
  selectedControlledStoreIds;

  usersPanelOpenState: boolean = false;
  pricingRulesPanelOpenState: boolean = false;
  controlledStoresPanelOpenState: boolean = false;

  userForm: UntypedFormGroup;
  pricingRuleForm: UntypedFormGroup;
  controlledStoreForm: UntypedFormGroup;

  permissions: any = {};
  myUser;
  userHasDeletionPrivilege: boolean = false;

  get usersFormArray() {
    return this.userForm.controls.users as UntypedFormArray;
  }

  get pricingRulesFormArray() {
    return this.pricingRuleForm.controls.pricingRules as UntypedFormArray;
  }

  get controlledStoresFormArray() {
    return this.controlledStoreForm.controls.controlledStores as UntypedFormArray;
  }

  constructor(
    private _fb: UntypedFormBuilder,
    private _userService: UserService,
    private _storeService: StoreService,
    private _pricingRulesService: PricingRulesService,
    private _deletionService: DeletionService,
    private _accessService: AccessService,
    private _permissionsService: PermissionsService,
    private _authenticationService: AuthenticationService,
    private _messageService: MessageService,
    private _dialog: MatDialog,
  ) { 
    this.userForm = this._fb.group({
      users: new UntypedFormArray([])
    });

    this.pricingRuleForm = this._fb.group({
      pricingRules: new UntypedFormArray([])
    });

    this.controlledStoreForm = this._fb.group({
      controlledStores: new UntypedFormArray([])
    });

    this.permissions = this._permissionsService.getPermissions();
    this.myUser = this._authenticationService.getMyUser();
    if (this.myUser.hasDeletionPrivilege) this.userHasDeletionPrivilege = true;
  }

  addUserCheckboxesToForm(arr) {
    arr.forEach(() => this.usersFormArray.push(new UntypedFormControl(false)));
  }

  addPricingRuleCheckboxesToForm(arr) {
    arr.forEach(() => this.pricingRulesFormArray.push(new UntypedFormControl(false)));
  }

  addControlledStoreCheckboxesToForm(arr) {
    arr.forEach(() => this.controlledStoresFormArray.push(new UntypedFormControl(false)));
  }
  
  ngOnInit(): void {
    this.doOnInit();
  }

  doOnInit(){
    this.userForm = this._fb.group({
      users: new UntypedFormArray([])
    });

    this.pricingRuleForm = this._fb.group({
      pricingRules: new UntypedFormArray([])
    });

    this.controlledStoreForm = this._fb.group({
      controlledStores: new UntypedFormArray([])
    });

    this.getAllManageableUsersByStore(this.storeId);
  }
 
  ngOnChanges(changes) {
    //transferOccurred change will cause a reload of this component
    if (changes && changes?.transferOccurred?.currentValue) this.doOnInit();
    //console.log(changes);
  }

  onTransferButtonClick(){
    this.openTransferDialogEvent.next(null);
  }

  getAllManageableUsersByStore(storeId){
    this._userService.getAllManageableUsersByStore(storeId)
      .subscribe(
        (data:any) => {
          this.users = data;
          switch(data.length){
            case 1: this.usersDescription = '1 User is assigned to this store';
              break;
            default: this.usersDescription = data.length + ' Users are assigned to this store';
              break
          }
        
          this.addUserCheckboxesToForm(this.users);
          this.getAllPricingRulesByStoreIdSansMatchCriteria(this.storeId);
        },
        (response) => {
          this._messageService.onFailure('Failed to get Users in Store.', response);
        })
  }

  getAllPricingRulesByStoreIdSansMatchCriteria(storeId){
    this._pricingRulesService.getAllPricingRulesByStoreIdSansMatchCriteria(storeId)
      .subscribe(
        (data:any) => {
          this.pricingRules = data;
          switch(data.length){
            case 1: this.pricingRulesDescription = '1 Pricing Rule is owned by this store';
              break;
            default: this.pricingRulesDescription = data.length + ' Pricing Rules are owned by this store';
              break
          }
          this.addPricingRuleCheckboxesToForm(this.pricingRules);
          this.getControlledStores(this.storeId);
        },
        (response) => {
          this._messageService.onFailure('Failed to get Pricing Rules for Store.', response);
        })
  }

  getControlledStores(storeId){
    this._storeService.getControlledStores(storeId)
      .subscribe(
        (data:any) => {
          this.controlledStores = data;
          switch(data.length){
            case 1: this.controlledStoresDescription = '1 Store is controlled by this store';
              break;
            default: this.controlledStoresDescription = data.length + ' Stores are controlled by this store';
              break
          }
          this.addControlledStoreCheckboxesToForm(this.controlledStores);
          this.assetCount = this.users.length + this.pricingRules.length + this.controlledStores.length;
          this.assetCountEvent.next(this.assetCount);
        },
        (response) => {
          this._messageService.onFailure('Failed to get Controlled Stores for Store.', response);
        })
  }

  selectAllUsers(bool){
    this.usersFormArray.controls.map(control=>{
      control.setValue(bool);
    });
  }

  onUserDeleteClick(){
    if (this.myUser.hasDeletionPrivilege){
      this.selectedUserIds = this.userForm.value.users
        .map((checked, i) => checked ? this.users[i].userId : null)
        .filter(v => v !== null);
      //console.log(this.selectedUserIds);
      if (this.selectedUserIds.length > 0) this.confirmDeletion('Users', this.selectedUserIds, false);
      else this._messageService.alert('No users are selected. Please select at least one user and try again.');
    }
    else {
      this._messageService.alert('You are not authorized to delete users. If you believe you should have that privilege, contact an administrator.');
    }
  }

  selectAllPricingRules(bool){
    this.pricingRulesFormArray.controls.map(control=>{
      control.setValue(bool);
    });
  }

  onPricingRuleDeleteClick(){
    if (this.myUser.hasDeletionPrivilege){
      this.selectedPricingRuleIds = this.pricingRuleForm.value.pricingRules
        .map((checked, i) => checked ? this.pricingRules[i].pricingRuleId : null)
        .filter(v => v !== null);
      //console.log(this.selectedPricingRuleIds);
      if (this.selectedPricingRuleIds.length > 0) this.confirmDeletion('Pricing Rules', this.selectedPricingRuleIds, false);
      else this._messageService.alert('No pricing rules are selected. Please select at least one pricing rule and try again.');
    }
    else {
      this._messageService.alert('You are not authorized to delete pricing rules. If you believe you should have that privilege, contact an administrator.');
    }
  }

  selectAllControlledStores(bool){
    this.controlledStoresFormArray.controls.map(control=>{
      control.setValue(bool);
    });
  }

  onControlledStoreDeleteClick(){
    if (this.myUser.hasDeletionPrivilege){
      this.selectedControlledStoreIds = this.controlledStoreForm.value.controlledStores
        .map((checked, i) => checked ? this.controlledStores[i].storeId : null)
        .filter(v => v !== null);
      //console.log(this.selectedControlledStoreIds);
      if (this.selectedControlledStoreIds.length > 0) this.confirmDeletion('Controlled Stores', this.selectedControlledStoreIds, false);
      else this._messageService.alert('No controlled stores are selected. Please select at least one controlled store and try again.');
    }
    else {
      this._messageService.alert('You are not authorized to delete stores. If you believe you should have that privilege, contact an administrator.');
    }
  }

  deleteAssets(assetType, selectedIds, forceDeletion){
    //Users with hasReadOnlyRestriction set to true should not even be allowed to invoke this action because it is hidden or disabled in the UI.
    //This is for extra protection. The back end will also be protected.
    if (this.myUser.hasReadOnlyRestriction){
      this._messageService.alertReadOnlyRestriction();
      return;
    }

    this._deletionService.deleteSelectAssetsFromEntity(this.storeId, 'Store', assetType, forceDeletion, selectedIds)
      .subscribe(
        (data:any) => {
          this._messageService.onSuccess('Successfully performed action on selected assets');
          this.deletionOccurredEvent.next(true);
          this.doOnInit();
        },
        (response) => {
          let reason = response.error.modelState ? response.error?.modelState[''].join(' ') : undefined;
          if (reason) this.confirmLongDeletion(assetType, selectedIds, reason);
          else this._messageService.onFailure('Failed to perform action on selected assets.', response);
        })
  }

  confirmDeletion(assetType, selectedIds, forceDeletion){
    this.confirmationDialogRef = this._dialog.open(ConfirmationDialogComponent, {
      disableClose: false,
      //width: '600px',
      maxHeight: '80vh',
      data: {
        title: 'Are you sure?',
        message: `Do you want to perform this action on the selected assets of type: ${assetType}?`,
        confirmText: 'Yes, proceed with this action',
        cancelText: 'No, cancel this action!',
        thirdButtonText: undefined,
      }
    });
    this.confirmationDialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.deleteAssets(assetType, selectedIds, false);
      }
      this.confirmationDialogRef = null;
    });
  }

  confirmLongDeletion(assetType, selectedIds, reason){
    if (reason) reason = reason.replace(/\r\n/g, '<br/>')
      .replace(/\u00b9/g, '<span class="indent-1"></span>')
      .replace(/\u00b2/g, '<span class="indent-2"></span>')
      .replace(/\u00b3/g, '<span class="indent-3"></span>')
      .replace(/\u2074/g, '<span class="indent-4"></span>')
      .replace(/\u2075/g, '<span class="indent-5"></span>')
      .replace(/\u2076/g, '<span class="indent-6"></span>')
      .replace(/\u2077/g, '<span class="indent-7"></span>')
      .replace(/\u2078/g, '<span class="indent-8"></span>');
    this.confirmationDialogRef = this._dialog.open(ConfirmationDialogComponent, {
      disableClose: false,
      //width: '600px',
      maxHeight: '80vh',
      data: {
        title: 'Are you sure?',
        message: reason,
        confirmText: 'Yes, proceed with this action',
        cancelText: 'No, cancel this action!',
        thirdButtonText: undefined,
      }
    });
    this.confirmationDialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.deleteAssets(assetType, selectedIds, true);
      }
      this.confirmationDialogRef = null;
    });
  }

  addSpacesBetweenWords(v) {
    if (v != null && v != '' && v != undefined) {
      var valueNoSpaces = v.replace(/\s/g, '');//first remove all spaces if any
      var valueWithSpaces = valueNoSpaces.replace(/([A-Z])/g, ' $1');
      valueWithSpaces = valueWithSpaces.substr(1); //remove space before first word
      return valueWithSpaces;
    }
  }

}
