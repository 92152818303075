<div class="detail admin-dialog">
<h1 mat-dialog-title [ngClass]="{'accent': storeId == '0'}">{{mode}} Store # {{storeDetailForm.get('storeNumber').value}}</h1>
<div mat-dialog-content>
<mat-tab-group dynamicHeight="true" (selectedTabChange)="onTabChange($event)">
  <mat-tab label="Store Information">
    <form [formGroup]="storeDetailForm" id="store-detail-form" (ngSubmit)="onSubmitStore()">
      <div class="checkbox-form-field">
        <mat-checkbox id="active" formControlName="active">Active</mat-checkbox>
        <mat-checkbox id="isFranchise" formControlName="isFranchise">Franchise Store</mat-checkbox>
        <mat-checkbox *ngIf="storeDetailForm.get('controllingStoreId').value > 0" id="optOutOfCigaretteAndTobaccoPricingWhenControlled" formControlName="optOutOfCigaretteAndTobaccoPricingWhenControlled">Opt out of cigarette and tobacco pricing by controlling store</mat-checkbox>
      </div>
      <div class="row">
        <mat-form-field>
          <mat-label>Store Group</mat-label>
          <mat-select id="storeGroupId" formControlName="storeGroupId" placeholder="Store Group" [compareWith]="compareFn" (selectionChange)="onStoreGroupSelectionChange($event.value)" required>
            <mat-option *ngFor="let storeGroup of storeGroups" [value]="storeGroup.storeGroupId">
              {{storeGroup.name}}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="storeDetailForm.hasError('required', 'storeGroupId')">Store Group is required</mat-error>
        </mat-form-field>
        <mat-form-field>
          <mat-label>Controlling Store</mat-label>
          <mat-select id="controllingStoreId" formControlName="controllingStoreId" placeholder="Controlling Store" (selectionChange)="onControllingStoreChange($event.value)" [compareWith]="compareFn" [disabled]="isControllingStoreDisabled" matTooltip="{{controllingStoreHint}}">
            <mat-option value="">None</mat-option>
            <mat-option *ngFor="let store of controllingStores" [value]="store.storeId">
              {{store.numberAndLocation}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field>
          <mat-label>Store Number</mat-label>
          <input matInput id="storeNumber" formControlName="storeNumber" placeholder="Store Number" required maxlength="3">
          <mat-error *ngIf="storeDetailForm.hasError('required', 'storeNumber')">Store Number is required</mat-error>
          <mat-error *ngIf="storeDetailForm.hasError('pattern', 'storeNumber')">Store Number must be a number</mat-error>
          <mat-hint align="end">{{storeDetailForm.get('storeNumber').value.length}} / 3</mat-hint>
        </mat-form-field>
      </div>
      <div class="row">
        <mat-form-field>
          <mat-label>Street Address</mat-label>
          <input matInput id="streetAddress" formControlName="streetAddress" placeholder="Street Address" required maxlength="50">
          <mat-error *ngIf="storeDetailForm.hasError('required', 'streetAddress')">Street Address is required</mat-error>
          <mat-hint align="end">{{storeDetailForm.get('streetAddress').value.length}} / 50</mat-hint>
        </mat-form-field>
        <mat-form-field>
          <mat-label>City</mat-label>
          <input matInput id="city" formControlName="city" placeholder="City" required maxlength="30">
          <mat-error *ngIf="storeDetailForm.hasError('required', 'city')">City is required</mat-error>
          <mat-hint align="end">{{storeDetailForm.get('city').value.length}} / 30</mat-hint>
        </mat-form-field>
        <mat-form-field style="max-width: 14%">
          <mat-label>State</mat-label>
          <input matInput id="state" formControlName="state" placeholder="State" required maxlength="2">
          <mat-error *ngIf="storeDetailForm.hasError('required', 'state')">State is required</mat-error>
        </mat-form-field>
        <mat-form-field style="max-width: 15%">
          <mat-label>Zip Code</mat-label>
          <input matInput id="zipCode" formControlName="zipCode" placeholder="Zip Code" required>
          <mat-error *ngIf="storeDetailForm.hasError('required', 'zipCode')">Zip Code is required</mat-error>
          <mat-error *ngIf="storeDetailForm.hasError('pattern', 'zipCode')">Use valid Zip Code or Zip Code + 4</mat-error>
        </mat-form-field>
      </div>
      <div class="row">
        <mat-form-field>
          <mat-label>Store Email</mat-label>
          <input matInput id="storeEmailAddress" formControlName="storeEmailAddress" placeholder="Store Email" required>
          <mat-error *ngIf="storeDetailForm.hasError('required', 'storeEmailAddress')">Store Email is required</mat-error>
          <mat-error *ngIf="storeDetailForm.hasError('pattern', 'storeEmailAddress')">Please enter a valid email address</mat-error>
        </mat-form-field>
        <mat-form-field>
          <mat-label>Phone Number</mat-label>
          <input matInput id="phoneNumber" formControlName="phoneNumber" placeholder="Phone Number" mask="(000) 000-0000" required>
          <mat-error *ngIf="storeDetailForm.hasError('required', 'phoneNumber')">Store Phone Number is required</mat-error>
        </mat-form-field>
        <mat-form-field>
          <mat-label>Fax Number</mat-label>
          <input matInput id="faxNumber" formControlName="faxNumber" placeholder="Fax Number" mask="(000) 000-0000">
        </mat-form-field>
      </div>
      <div class="row">
        <mat-form-field>
          <mat-label>Store Owner</mat-label>
          <input matInput id="owner" formControlName="owner" placeholder="Store Owner" maxlength="100">
        </mat-form-field>
        <mat-form-field>
          <mat-label>Store Manager</mat-label>
          <input matInput id="manager" formControlName="manager" placeholder="Store Manager" maxlength="100">
        </mat-form-field>
      </div>
      <div class="row">
        <mat-form-field>
          <mat-label>Recall Contact</mat-label>
          <input matInput id="recallContact" formControlName="recallContact" placeholder="Recall Contact" maxlength="100">
        </mat-form-field>
        <mat-form-field>
          <mat-label>Recall Contact Phone Number</mat-label>
          <input matInput id="recallContactPhoneNumber" formControlName="recallContactPhoneNumber" placeholder="Recall Contact Phone Number" mask="(000) 000-0000">
        </mat-form-field>
      </div>
      <div class="detail-action-buttons">
        <button mat-raised-button color="primary" [disabled]="!storeDetailForm.valid" type="submit">Save</button>
        <button mat-raised-button (click)="closeDialog()" type="button">Cancel</button>
      </div>
    </form>
  </mat-tab>

  <mat-tab label="Store Group Diagram"  *ngIf=" storeId != '0'">
      <div [innerHTML]="storeGroupDiagramHtml"></div>
      <div class="detail-action-buttons">
        <button mat-raised-button (click)="closeDialog()" type="button">Close</button>
      </div>
  </mat-tab>

  <mat-tab label="Owned Assets" *ngIf=" storeId != '0'">
    <ng-template mat-tab-label>
      <div>Owned Assets<span class="detail badge">{{assetCount}}</span></div>
    </ng-template>
    <app-store-owned-assets (assetCountEvent)="onAssetCountEvent($event)" (openTransferDialogEvent)="onTransferButtonClick()" [storeId]="storeId" [showTransferButton]="showTransferButton"></app-store-owned-assets>
    <div class="detail-action-buttons">
      <button mat-raised-button (click)="closeDialog()" type="button">Close</button>
    </div>
  </mat-tab>
</mat-tab-group>
</div>
</div>