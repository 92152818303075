import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SpinnerService {

    spinnerMessage = "Processing Request...";
    private spinnerSubject = new Subject<any>();
    private spinnerMessageSubject = new Subject<any>();

    spinner$ = this.spinnerSubject.asObservable();
    spinnerMessage$ = this.spinnerMessageSubject.asObservable();

    handleProgressSpinnerVisibility(showOrHide, message?) {
        //console.log(message);
        this.spinnerMessage = message ? message : this.spinnerMessage;
        var spinnerModel = {
            visibility: showOrHide,
            message: message,
        }
        this.spinnerSubject.next(spinnerModel);
    }

    setProgressSpinnerMessage(message) {
        //console.log(message);
        this.spinnerMessage = message;
        this.spinnerMessageSubject.next(message);
    }

}
