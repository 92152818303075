<div class="grid-tabs">
    <a id="AllItems-tab" class="tab" (click)="onTabClick('AllItems','StockItems')" [ngClass]="{active: tabModel.activeTab == 'AllItems'}">All Depts</a>
    <a id="Grocery-tab" class="tab" class="active" (click)="onTabClick('Grocery','StockItems')" [ngClass]="{active: tabModel.activeTab == 'Grocery'}">Grocery</a>
    <a id="Meat-tab" class="tab" (click)="onTabClick('Meat','StockItems')" [ngClass]="{active: tabModel.activeTab == 'Meat'}">Meat</a>
    <a id="Produce-tab" class="tab" (click)="onTabClick('Produce','StockItems')" [ngClass]="{active: tabModel.activeTab == 'Produce'}">Produce</a>
    <a id="DeliBakery-tab" class="tab" (click)="onTabClick('DeliBakery','StockItems')" [ngClass]="{active: tabModel.activeTab == 'DeliBakery'}">Deli/Bakery</a>
    <a id="Dairy-tab" class="tab" (click)="onTabClick('Dairy','StockItems')" [ngClass]="{active: tabModel.activeTab == 'Dairy'}">Dairy</a>
    <a id="HBA-tab" class="tab" (click)="onTabClick('HBA','StockItems')" [ngClass]="{active: tabModel.activeTab == 'HBA'}">HBA</a>
    <a id="FrozenFood-tab" class="tab" (click)="onTabClick('FrozenFood','StockItems')" [ngClass]="{active: tabModel.activeTab == 'FrozenFood'}">Frozen Food</a>
    <a id="Preview-tab" class="tab" (click)="onTabClick('Preview','GroceryPreviewItems')" [ngClass]="{active: tabModel.activeTab == 'Preview'}">Preview Pricing</a>
</div>
<div class="subtab-container-wrapper">
    <div id="AllItems-subtab-container" class="subtab-container" *ngIf="tabModel.activeTab == 'AllItems'">
        <a (click)="onTabClick('AllItems','StockItems')" [ngClass]="{active: tabModel.activeSubTab == 'StockItems'}"><i class="fa fa-lock" aria-hidden="true" *ngIf="!storeItemPriceChangesEnabledForStoreObject.value"></i>Priced Items</a>
        <a (click)="onTabClick('AllItems','NonStockItems')" [ngClass]="{active: tabModel.activeSubTab == 'NonStockItems'}"><i class="fa fa-lock" aria-hidden="true" *ngIf="!storeItemPriceChangesEnabledForStoreObject.value"></i>Non-Priced Items</a>
        <a (click)="onTabClick('AllItems','AllItems')" [ngClass]="{active: tabModel.activeSubTab == 'AllItems'}"><i class="fa fa-lock" aria-hidden="true" *ngIf="!storeItemPriceChangesEnabledForStoreObject.value"></i>All Items</a>
    </div>

    <div id="Grocery-subtab-container" class="subtab-container" *ngIf="tabModel.activeTab == 'Grocery'">
        <a (click)="onTabClick('Grocery','StockItems')" [ngClass]="{active: tabModel.activeSubTab == 'StockItems'}"><i class="fa fa-lock" aria-hidden="true" *ngIf="!storeItemPriceChangesEnabledForStoreObject.value"></i>Priced Items</a>
        <a (click)="onTabClick('Grocery','NonStockItems')" [ngClass]="{active: tabModel.activeSubTab == 'NonStockItems'}"><i class="fa fa-lock" aria-hidden="true" *ngIf="!storeItemPriceChangesEnabledForStoreObject.value"></i>Non-Priced Items</a>
        <a (click)="onTabClick('Grocery','AllItems')" [ngClass]="{active: tabModel.activeSubTab == 'AllItems'}"><i class="fa fa-lock" aria-hidden="true" *ngIf="!storeItemPriceChangesEnabledForStoreObject.value"></i>All Grocery Items</a>
    </div>

    <div id="Meat-subtab-container" class="subtab-container" *ngIf="tabModel.activeTab == 'Meat'">
        <a (click)="onTabClick('Meat','StockItems')" [ngClass]="{active: tabModel.activeSubTab == 'StockItems'}"><i class="fa fa-lock" aria-hidden="true" *ngIf="!meatAndProduceStoreItemPriceChangesEnabledForStoreObject.value"></i>Priced Items</a>
        <a (click)="onTabClick('Meat','NonStockItems')" [ngClass]="{active: tabModel.activeSubTab == 'NonStockItems'}"><i class="fa fa-lock" aria-hidden="true" *ngIf="!meatAndProduceStoreItemPriceChangesEnabledForStoreObject.value"></i>Non-Priced Items</a>
        <a (click)="onTabClick('Meat','AllItems')" [ngClass]="{active: tabModel.activeSubTab == 'AllItems'}"><i class="fa fa-lock" aria-hidden="true" *ngIf="!meatAndProduceStoreItemPriceChangesEnabledForStoreObject.value"></i>All Meat Items</a>
    </div>

    <div id="Produce-subtab-container" class="subtab-container" *ngIf="tabModel.activeTab == 'Produce'">
        <a (click)="onTabClick('Produce','StockItems')" [ngClass]="{active: tabModel.activeSubTab == 'StockItems'}"><i class="fa fa-lock" aria-hidden="true" *ngIf="!meatAndProduceStoreItemPriceChangesEnabledForStoreObject.value"></i>Priced Items</a>
        <a (click)="onTabClick('Produce','NonStockItems')" [ngClass]="{active: tabModel.activeSubTab == 'NonStockItems'}"><i class="fa fa-lock" aria-hidden="true" *ngIf="!meatAndProduceStoreItemPriceChangesEnabledForStoreObject.value"></i>Non-Priced Items</a>
        <a (click)="onTabClick('Produce','AllItems')" [ngClass]="{active: tabModel.activeSubTab == 'AllItems'}"><i class="fa fa-lock" aria-hidden="true" *ngIf="!meatAndProduceStoreItemPriceChangesEnabledForStoreObject.value"></i>All Produce Items</a>
    </div>

    <div id="DeliBakery-subtab-container" class="subtab-container" *ngIf="tabModel.activeTab == 'DeliBakery'">
        <a (click)="onTabClick('DeliBakery','StockItems')" [ngClass]="{active: tabModel.activeSubTab == 'StockItems'}"><i class="fa fa-lock" aria-hidden="true" *ngIf="!storeItemPriceChangesEnabledForStoreObject.value"></i>Priced Items</a>
        <a (click)="onTabClick('DeliBakery','NonStockItems')" [ngClass]="{active: tabModel.activeSubTab == 'NonStockItems'}"><i class="fa fa-lock" aria-hidden="true" *ngIf="!storeItemPriceChangesEnabledForStoreObject.value"></i>Non-Priced Items</a>
        <a (click)="onTabClick('DeliBakery','AllItems')" [ngClass]="{active: tabModel.activeSubTab == 'AllItems'}"><i class="fa fa-lock" aria-hidden="true" *ngIf="!storeItemPriceChangesEnabledForStoreObject.value"></i>All Deli/Bakery Items</a>
    </div>

    <div id="Dairy-subtab-container" class="subtab-container" *ngIf="tabModel.activeTab == 'Dairy'">
        <a (click)="onTabClick('Dairy','StockItems')" [ngClass]="{active: tabModel.activeSubTab == 'StockItems'}"><i class="fa fa-lock" aria-hidden="true" *ngIf="!storeItemPriceChangesEnabledForStoreObject.value"></i>Priced Items</a>
        <a (click)="onTabClick('Dairy','NonStockItems')" [ngClass]="{active: tabModel.activeSubTab == 'NonStockItems'}"><i class="fa fa-lock" aria-hidden="true" *ngIf="!storeItemPriceChangesEnabledForStoreObject.value"></i>Non-Priced Items</a>
        <a (click)="onTabClick('Dairy','AllItems')" [ngClass]="{active: tabModel.activeSubTab == 'AllItems'}"><i class="fa fa-lock" aria-hidden="true" *ngIf="!storeItemPriceChangesEnabledForStoreObject.value"></i>All Dairy Items</a>
    </div>

    <div id="HBA-subtab-container" class="subtab-container" *ngIf="tabModel.activeTab == 'HBA'">
        <a (click)="onTabClick('HBA','StockItems')" [ngClass]="{active: tabModel.activeSubTab == 'StockItems'}"><i class="fa fa-lock" aria-hidden="true" *ngIf="!storeItemPriceChangesEnabledForStoreObject.value"></i>Priced Items</a>
        <a (click)="onTabClick('HBA','NonStockItems')" [ngClass]="{active: tabModel.activeSubTab == 'NonStockItems'}"><i class="fa fa-lock" aria-hidden="true" *ngIf="!storeItemPriceChangesEnabledForStoreObject.value"></i>Non-Priced Items</a>
        <a (click)="onTabClick('HBA','AllItems')" [ngClass]="{active: tabModel.activeSubTab == 'AllItems'}"><i class="fa fa-lock" aria-hidden="true" *ngIf="!storeItemPriceChangesEnabledForStoreObject.value"></i>All HBA Items</a>
    </div>

    <div id="FrozenFood-subtab-container" class="subtab-container" *ngIf="tabModel.activeTab == 'FrozenFood'">
        <a (click)="onTabClick('FrozenFood','StockItems')" [ngClass]="{active: tabModel.activeSubTab == 'StockItems'}"><i class="fa fa-lock" aria-hidden="true" *ngIf="!storeItemPriceChangesEnabledForStoreObject.value"></i>Priced Items</a>
        <a (click)="onTabClick('FrozenFood','NonStockItems')" [ngClass]="{active: tabModel.activeSubTab == 'NonStockItems'}"><i class="fa fa-lock" aria-hidden="true" *ngIf="!storeItemPriceChangesEnabledForStoreObject.value"></i>Non-Priced Items</a>
        <a (click)="onTabClick('FrozenFood','AllItems')" [ngClass]="{active: tabModel.activeSubTab == 'AllItems'}"><i class="fa fa-lock" aria-hidden="true" *ngIf="!storeItemPriceChangesEnabledForStoreObject.value"></i>All Frozen Food Items</a>
    </div>

    <div id="Preview-subtab-container" class="subtab-container" *ngIf="tabModel.activeTab == 'Preview'">
        <a (click)="onTabClick('Preview','GroceryPreviewItems')" [ngClass]="{active: tabModel.activeSubTab == 'GroceryPreviewItems'}"><i class="fa fa-lock" aria-hidden="true" *ngIf="!previewStoreItemPriceChangesEnabledForStoreObject.value"></i>Grocery Preview Items</a>
        <a (click)="onTabClick('Preview','MeatPreviewItems')" [ngClass]="{active: tabModel.activeSubTab == 'MeatPreviewItems'}"><i class="fa fa-lock" aria-hidden="true" *ngIf="!meatAndProducePreviewStoreItemPriceChangesEnabledForStoreObject.value"></i>Meat Preview Items</a>
        <a (click)="onTabClick('Preview','ProducePreviewItems')" [ngClass]="{active: tabModel.activeSubTab == 'ProducePreviewItems'}"><i class="fa fa-lock" aria-hidden="true" *ngIf="!meatAndProducePreviewStoreItemPriceChangesEnabledForStoreObject.value"></i>Produce Preview Items</a>
    </div>
</div>
