import { Component, OnInit, Input, Inject, LOCALE_ID } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from 'src/app/shared/confirmation-dialog/confirmation-dialog.component';
import { Validators, UntypedFormBuilder } from '@angular/forms';
import { MessageService } from 'src/app/shared/message.service';
import { AdminItemListDetailDialog } from '../../admin-item-lists/admin-item-list-list/admin-item-list-list.component';
import { PrivateLabelsService } from 'src/app/shared/private-labels.service';
import { formatDate } from '@angular/common';
import { AuthenticationService } from 'src/app/core/authentication/shared/authentication.service';

const LETTER_CODE_REGEX = /[A-Z]{1}/

@Component({
  selector: 'rpms-admin-private-labels-detail',
  templateUrl: './admin-private-labels-detail.component.html',
  styleUrls: ['./admin-private-labels-detail.component.css']
})
export class AdminPrivateLabelsDetailComponent implements OnInit {
  @Input() id: string; 

  confirmationDialogRef: MatDialogRef<ConfirmationDialogComponent>;

  privateLabelId: string;
  mode: string;
  privateLabel;
  myUser;

  privateLabelDetailForm = this._fb.group({
    active: [''],
    letterCode: ['', [Validators.required, Validators.pattern(LETTER_CODE_REGEX)]],
    label: ['', Validators.required],
    created: [''],
    modified: [''],
  });

  constructor(private _fb: UntypedFormBuilder,
              private _privateLabelsService: PrivateLabelsService,
              private _authenticationService: AuthenticationService,
              private _messageService: MessageService,
              public dialogRef: MatDialogRef<AdminItemListDetailDialog>,
              @Inject(LOCALE_ID) private locale: string) { 
                this.myUser = this._authenticationService.getMyUser();
              }

  ngOnInit() {
    if (this.myUser.hasReadOnlyRestriction) this.privateLabelDetailForm.disable();
    this.privateLabelId = this.id;
    this.mode = this.privateLabelId == '0' ? 'New' : 'Edit';
    if (this.privateLabelId != '0') {
      this.getPrivateLabel();
    }
    else{
      this.privateLabelDetailForm.patchValue({ active: true });
    }
    this.privateLabelDetailForm.get('created').disable();
    this.privateLabelDetailForm.get('modified').disable();
  }

  getPrivateLabel(){
    this._privateLabelsService.getPrivateLabelById(this.privateLabelId)
      .subscribe(
        (data:any) => {
          this.privateLabel = data;
          this.privateLabelDetailForm.patchValue({
            active: data.active,
            letterCode: data.letterCode,
            label: data.label,
            created: data.created ? formatDate(data.created, 'MM/dd/yyyy h:mm:ss a', this.locale) : '',
            modified: data.modified ? formatDate(data.modified, 'MM/dd/yyyy h:mm:ss a', this.locale) : '',
          });
        },
        (response) => {
          this._messageService.onFailure('Failed to get private label.', response);
        })
  }

  onSubmitPrivateLabel(){
    //Users with hasReadOnlyRestriction set to true should not even be allowed to invoke this action because it is hidden or disabled in the UI.
    //This is for extra protection. The back end will also be protected.
    if (this.myUser.hasReadOnlyRestriction){
      this._messageService.alertReadOnlyRestriction();
      return;
    }
    
    var privateLabel = this.privateLabelDetailForm.value;
    if (this.mode === 'New') {
      this._privateLabelsService.createPrivateLabel(privateLabel)
      .subscribe(
        () => {
          this.dialogRef.close('reload');
          this._messageService.onSuccess('Successfully created private label');
        },
        (response) => {
          this._messageService.onFailure('Failed to create private label.', response)
        }
      );
    }
    else {
      this._privateLabelsService.updatePrivateLabel(this.privateLabelId, privateLabel)
      .subscribe(
        () => {
          this.dialogRef.close('reload');
          this._messageService.onSuccess('Successfully updated private label');
        },
        (response) => {
          this._messageService.onFailure('Failed to update private label.', response)
        }
      );
    }
  }

  closeDialog(){
    this.dialogRef.close();
  }

}
