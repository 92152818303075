import { Component, OnInit, Input, Output, EventEmitter, Inject, ɵɵsetComponentScope, SimpleChanges } from '@angular/core';
import { ItemListService } from 'src/app/shared/item-list.service';
import { MessageService } from 'src/app/shared/message.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from 'src/app/shared/confirmation-dialog/confirmation-dialog.component';
import { PermissionsService } from 'src/app/shared/permissions.service';
import { SpinnerService } from 'src/app/shared/spinner.service';
import { AuthenticationService } from 'src/app/core/authentication/shared/authentication.service';
//import { environment } from 'src/environments/environment';
import { AppConfig } from 'src/app/app.config';

@Component({
  selector: 'rpms-item-list-editor',
  templateUrl: './item-list-editor.component.html',
  styleUrls: ['./item-list-editor.component.css']
})
export class ItemListEditorComponent implements OnInit {
  @Input() rowNodeToUpdate: any;
  @Input() itemLists: any;
  @Input() changeType: string;
  @Input() itemListEditorFilterValue: string;
  @Output() updatedRowNodeEvent = new EventEmitter<any>();
  @Output() isItemListEditorVisibleEvent = new EventEmitter<boolean>();
  @Output() itemListEditorFilterValueEvent = new EventEmitter<string>();
  private config = AppConfig.settings;

  itemListDialogRef: MatDialogRef<ItemListDetailDialog>;
  confirmationDialogRef: MatDialogRef<ConfirmationDialogComponent>;

  rowData: any = {};
  isSaveDisabled: boolean = true;
  allItemLists;
  permissions: any = {};
  currentItemListId;
  //emptiedItemList: any = {};
  filterValue = '';
  filteredItemLists;
  itemListsForCurrentItem = [];
  shouldReloadItemLists: boolean = false;
  myUser;
  myUserRole;

  constructor(
    private _itemListService: ItemListService,
    private _authenticationService: AuthenticationService,
    private _messageService: MessageService,
    private _permissionsService: PermissionsService,
    private _spinnerService: SpinnerService,
    private _dialog: MatDialog, 
  ) { }

  /*
    Item Lists are passed into this component from the price management component.

    If an item list is checked or unchecked (or chip removed) in this editor, then the selected item lists in this component
    are sent to the price management component to update the row data without reloading the item lists from the server.

    If an item list is created, edited, deleted, or have all items removed, then the property shouldReloadItemLists is set to true
    in the object that is sent back to the price management component to update the rowNode.
    This will call getActiveSharedItemLists in price management, update all the rows in the grid, send all the Item Lists back
    to this component, and everything gets updated with the latest Item Lists data in this component. (see ngOnChanges)
  */

  ngOnInit() {
    if (this.config.logInits) console.log('item list editor init');
    this._spinnerService.handleProgressSpinnerVisibility('hide');
    this.myUser = this._authenticationService.getMyUser();
    this.myUserRole = this._authenticationService.getUserRole();
    this.permissions = this._permissionsService.getPermissions();
    this.rowData = this.rowNodeToUpdate.data;
    //this.getActiveSharedItemLists();
    this.allItemLists = this.itemLists;
    this.initSelectedItemLists();
    this.filteredItemLists = this.itemLists;
    this.filterValue = this.itemListEditorFilterValue;
    this.filterList(this.filterValue);
  }

  ngOnChanges(changes: SimpleChanges) {
    //console.log('changes');
    this.rowData = this.rowNodeToUpdate.data;
    this.allItemLists = this.itemLists;
    this.initSelectedItemLists();
    this.filteredItemLists = this.itemLists;
    this.filterValue = this.itemListEditorFilterValue;
    this.filterList(this.filterValue);
  }

  ngOnDestroy(): void {
  }

  // getActiveSharedItemLists(){
  //   this._itemListService.getActiveSharedItemListsLite()
  //     .subscribe(
  //       (data) => {
  //         this.allItemLists = data;
  //         this.initSelectedItemLists();
  //       },
  //       (response) => {
  //         this._messageService.onFailure('Failed to get Item Lists.', response);
  //       }
  //     )
  // }

  initSelectedItemLists(){
    for (var i = 0; i < this.allItemLists.length; i++){ //loop over all item lists
      this.allItemLists[i].selected = false;
      for (var n = 0; n < this.rowData.itemLists.length; n++){ //loop over item lists contained in this row's data
        if (this.allItemLists[i].itemListId === this.rowData.itemLists[n].itemListId){ // if match then select it
          this.allItemLists[i].selected = true;
        }
      }
    }
  }

  onItemListCheckboxChange(itemList){
    if (this.permissions.roleAllowsUserToEditItemList){
      itemList.selected ? this.addItemToItemList(itemList) : this.removeItemFromItemList(itemList);
    }
  }

  onItemListChipRemove(itemList){
    if (this.permissions.roleAllowsUserToEditItemList){
      itemList.selected = false;
      this.removeItemFromItemList(itemList);
    }
  }

  addItemToItemList(itemList){
    //Users with hasReadOnlyRestriction set to true should not even be allowed to invoke this action because it is hidden or disabled in the UI.
    //This is for extra protection. The back end will also be protected.
    if (this.myUser.hasReadOnlyRestriction){
      this._messageService.alertReadOnlyRestriction();
      return;
    }

    this._itemListService.addItemCodeToItemList(itemList.itemListId, this.rowData.itemCode)
      .subscribe(
        (data) => {
          this._messageService.onSuccess('Successfully added item to item list.');
          this.shouldReloadItemLists = false;
          this.currentItemListId = itemList.itemListId;
          this.getSelectedItemListsForUpdatingRowNode();
        },
        (response) => {
          this._messageService.onFailure('Failed to add item to item list.', response);
        }
      )
  }

  removeItemFromItemList(itemList){
    //Users with hasReadOnlyRestriction set to true should not even be allowed to invoke this action because it is hidden or disabled in the UI.
    //This is for extra protection. The back end will also be protected.
    if (this.myUser.hasReadOnlyRestriction){
      this._messageService.alertReadOnlyRestriction();
      return;
    }
    
    this._itemListService.removeItemCodeFromItemList(itemList.itemListId, this.rowData.itemCode)
      .subscribe(
        (data) => {
          this._messageService.onSuccess('Successfully removed item from item list.');
          this.shouldReloadItemLists = false;
          this.currentItemListId = itemList.itemListId;
          this.getSelectedItemListsForUpdatingRowNode();
        },
        (response) => {
          this._messageService.onFailure('Failed to remove item from item list.', response);
        }
      )
  }

  getSelectedItemListsForUpdatingRowNode(){
    this.itemListsForCurrentItem = [];
    for (var i = 0; i < this.allItemLists.length; i++){
      if (this.allItemLists[i].selected) this.itemListsForCurrentItem.push(this.allItemLists[i]);
    }
    //this.rowNodeToUpdate.data.itemLists = itemListsForThisItem;
    this.sendUpdatedRowNode();
  }

  sendUpdatedRowNode() {
    this.updatedRowNodeEvent.next({ 
      rowNode: this.rowNodeToUpdate, 
      currentItemListId: this.currentItemListId, 
      itemListsForCurrentItem: this.itemListsForCurrentItem,
      shouldReloadItemLists: this.shouldReloadItemLists });

  }

  onEditItemListClick(itemList){
    //this.currentItemListId = itemList.itemListId;
    this.itemListDialogRef = this._dialog.open(ItemListDetailDialog, {
      width: '450px',
      data: {
        id: itemList.itemListId
      }
    });
    this.itemListDialogRef.afterClosed()
    .subscribe(result => {
        if (result == 'refresh'){
          this.doAfterEditCreateOrDelete();
        }
    });
  }

  doAfterEditCreateOrDelete(){
    this.shouldReloadItemLists = true;
    this.currentItemListId = null; //this is not needed so set it to null to avoid confusion
    this.sendUpdatedRowNode();
    // this._itemListService.getActiveSharedItemListsLite()
    // .subscribe(
    //   (data) => {
    //     this.allItemLists = data;
    //     this.initSelectedItemLists();
    //     this.getSelectedItemListsForUpdatingRowNode();
    //   },
    //   (response) => {
    //     this._messageService.onFailure('Failed to get Item Lists.', response);
    //   }
    // )

  }

  onRemoveAllItemsFromItemListClick(itemList){
    //Users with hasReadOnlyRestriction set to true should not even be allowed to invoke this action because it is hidden or disabled in the UI.
    //This is for extra protection. The back end will also be protected.
    if (this.myUser.hasReadOnlyRestriction){
      this._messageService.alertReadOnlyRestriction();
      return;
    }

    if (this.myUser.hasDeletionPrivilege || itemList.ownerId == this.myUser.userId){
      this._itemListService.getItemListById(itemList.itemListId)
        .subscribe(
          (data) => {
            let currentItemList: any = data;
            if (currentItemList.itemCodes.length > 0) {
              this.confirmationDialogRef = this._dialog.open(ConfirmationDialogComponent, {
                disableClose: false,
                data: {
                  title: 'Are you sure?',
                  message: 'Do you want to remove ' + currentItemList.itemCodes.length + ' items from "' + currentItemList.name + '"?',
                  confirmText: 'Yes, remove all items',
                  cancelText: 'No, cancel this action!'
                }
              });
              this.confirmationDialogRef.afterClosed().subscribe(result => {
                if(result) {
                  this.removeAllItemsFromItemList(currentItemList);
                }
                this.confirmationDialogRef = null;
              });
            }
            else{
              this._messageService.alert('"' + currentItemList.name + '" does not contain any items.', 'Attention');
            }
          },
          (error) => {
            this._messageService.onFailure('Failed to get Item List by ID.', error);
          }
        )
    }
    else {
      this._messageService.alert('You are not authorized to remove all items from this item list because you are not the creator. If you believe you should have that privilege, contact an administrator.');
    }

  }

  removeAllItemsFromItemList(itemList){
    //Users with hasReadOnlyRestriction set to true should not even be allowed to invoke this action because it is hidden or disabled in the UI.
    //This is for extra protection. The back end will also be protected.
    if (this.myUser.hasReadOnlyRestriction){
      this._messageService.alertReadOnlyRestriction();
      return;
    }

    itemList.itemCodes = [];
    this._itemListService.updateItemList(itemList.itemListId, itemList)
      .subscribe(
        (data) => {
          this._messageService.onSuccess('Successfully removed all items from item list');
          //this.emptiedItemList = itemList;
          this.doAfterEditCreateOrDelete();
        },
        (response) => {
          this._messageService.onFailure('Failed to remove all items from item list.', response);
        }
      )
  }

  onDeleteItemListClick(itemList){
    //Users with hasReadOnlyRestriction set to true should not even be allowed to invoke this action because it is hidden or disabled in the UI.
    //This is for extra protection. The back end will also be protected.
    if (this.myUser.hasReadOnlyRestriction){
      this._messageService.alertReadOnlyRestriction();
      return;
    }

    if (this.myUser.hasDeletionPrivilege || itemList.ownerId == this.myUser.userId){
      this._itemListService.getItemListById(itemList.itemListId)
        .subscribe(
          (data) => {
            let currentItemList: any = data;
            this.confirmationDialogRef = this._dialog.open(ConfirmationDialogComponent, {
              disableClose: false,
              //width: '600px',
              data: {
                title: 'Are you sure?',
                message: 'Do you want to delete "' + currentItemList.name + '" that contains ' + currentItemList.itemCodes.length + ' items?',
                confirmText: 'Yes, delete item list',
                cancelText: 'No, cancel this action!'
              }
            });
            this.confirmationDialogRef.afterClosed().subscribe(result => {
              if(result) {
                this.deleteItemList(itemList);
              }
              this.confirmationDialogRef = null;
            });
          },
          (error) => {
            this._messageService.onFailure('Failed to get Item List by ID.', error);
          }
        )
    }
    else {
      this._messageService.alert('You are not authorized to delete this item list because you are not the creator. If you believe you should have that privilege, contact an administrator.');
    }

  }

  deleteItemList(itemList){
    //Users with hasReadOnlyRestriction set to true should not even be allowed to invoke this action because it is hidden or disabled in the UI.
    //This is for extra protection. The back end will also be protected.
    if (this.myUser.hasReadOnlyRestriction){
      this._messageService.alertReadOnlyRestriction();
      return;
    }

    this._itemListService.deleteItemList(itemList.itemListId)
      .subscribe(
        (data) => {
          this._messageService.onSuccess('Successfully deleted item list');
          this.doAfterEditCreateOrDelete();
        },
        (response) => {
          this._messageService.onFailure('Failed to delete item list.', response);
        }
      )
  }

  onCreateItemListClick(){
    //Users with hasReadOnlyRestriction set to true should not even be allowed to invoke this action because it is hidden or disabled in the UI.
    //This is for extra protection. The back end will also be protected.
    if (this.myUser.hasReadOnlyRestriction){
      this._messageService.alertReadOnlyRestriction();
      return;
    }

    this.itemListDialogRef = this._dialog.open(ItemListDetailDialog, {
      width: '450px',
      data: {
        id: 0
      }
    });
    this.itemListDialogRef.afterClosed().subscribe(result => {
      if (result == 'refresh'){
        this.doAfterEditCreateOrDelete();
      }
  });
  }

  close(){
      this.isItemListEditorVisibleEvent.next(false);
  }

  // isEmpty(obj) {
  //   var hasOwnProperty = Object.prototype.hasOwnProperty;
  //     if (obj == null) return true;
  //     if (obj.length > 0)    return false;
  //     if (obj.length === 0)  return true;
  //     if (typeof obj !== "object") return true;
  //     for (var key in obj) {
  //         if (hasOwnProperty.call(obj, key)) return false;
  //     }
  //     return true;
  // }

  filterList(value){
    //console.log(value);
    this.itemListEditorFilterValueEvent.next(value);
    //this.filteredItemLists = this.filterByValue(this.allItemLists, value);
  }

  // filterByValue(array, string) {
  //   return array.filter(obj => obj.name.toLowerCase().includes(string.toLowerCase()));
  // }

  clearFilter(){
    this.filterValue='';
    //this.filteredItemLists = this.itemLists;
  }

  ifFiltered(itemList){
    if (this.filterValue == '') return false;
    else return !(itemList.name.toLowerCase().includes(this.filterValue.toLowerCase()));
  }

  isEditable(itemList){
    if (this.myUserRole == 'SuperAdmin' || this.myUserRole == 'Admin') return true;
    else if (itemList.ownerId == this.myUser.Id) return true;
    else if (itemList.modifiedBy != 'NoOne') return true;
    else return false;
  }
}

@Component({
  selector: 'rpms-item-list-detail-dialog',
  template: `<rpms-item-list-detail [id]="data.id"></rpms-item-list-detail>`,
})
export class ItemListDetailDialog {

  constructor(
    public dialogRef: MatDialogRef<ItemListDetailDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any) {}

}
