<div class="assets-scroller">
  <div class="action-buttons">
    <button mat-button (click)="accordion.openAll()">Expand All</button>
    <button mat-button (click)="accordion.closeAll()">Collapse All</button>
    <button *ngIf="showTransferButton && permissions.roleAllowsUserToTransferUserAssets" class="transfer-button" mat-stroked-button color="primary" (click)="onTransferButtonClick()">Open Transfer Dialog</button>
  </div>
  <mat-accordion multi>

    <mat-expansion-panel id="item-filters-panel" (opened)="itemFilterPanelOpenState = true" (closed)="itemFilterPanelOpenState = false">
      <mat-expansion-panel-header>
        <mat-panel-title>
          Item Filters
        </mat-panel-title>
        <mat-panel-description>
          {{itemFilterDescription}}
        </mat-panel-description>
      </mat-expansion-panel-header>
      <div *ngIf="itemFilters && itemFilters.length > 0" class="asset-row header">
        <div>Name</div>
        <div>Description</div>
        <div>Shared With</div>
        <div *ngIf="permissions.roleAllowsUserToDeleteScopedUsersOwnedAssetsItemFilters && showDelete && userHasDeletionPrivilege" class="delete-col">
          <button mat-stroked-button color="primary" [matMenuTriggerFor]="menu" [disabled]="myUser.hasReadOnlyRestriction">Actions <mat-icon>expand_more</mat-icon></button>
          <mat-menu #menu="matMenu" xPosition="before">
            <button mat-menu-item style="text-transform: unset !important;" (click)="selectAllItemFilters(true)">Select All (Item Filters)</button>
            <button mat-menu-item style="text-transform: unset !important;" (click)="selectAllItemFilters(false)">Unselect All (Item Filters)</button>
            <button mat-menu-item style="text-transform: unset !important;" (click)="onItemFilterDeleteClick()">Delete Selected (Item Filters)</button>
          </mat-menu>
        </div>
      </div>
      <form [formGroup]="itemFilterForm">
        <div formArrayName="itemFilters" *ngFor="let itemFilter of itemFiltersFormArray.controls; let i = index" class="asset-row">
          <div>{{itemFilters[i]?.name}}</div>
          <div>{{itemFilters[i]?.description}}</div>
          <div>{{addSpacesBetweenWords(itemFilters[i]?.sharedWith)}}</div>
          <div *ngIf="permissions.roleAllowsUserToDeleteScopedUsersOwnedAssetsItemFilters && showDelete && userHasDeletionPrivilege" class="delete-col"><mat-checkbox [formControlName]="i" [disabled]="myUser.hasReadOnlyRestriction"></mat-checkbox></div>
        </div>
      </form>
    </mat-expansion-panel>

    <mat-expansion-panel id="item-lists-panel" (opened)="itemListPanelOpenState = true" (closed)="itemListPanelOpenState = false">
      <mat-expansion-panel-header>
        <mat-panel-title>
          Item Lists
        </mat-panel-title>
        <mat-panel-description>
          {{itemListDescription}}
        </mat-panel-description>
      </mat-expansion-panel-header>
      <div *ngIf="itemLists && itemLists.length > 0" class="asset-row header">
        <div>Name</div>
        <div>Description</div>
        <div>Shared With</div>
        <div *ngIf="permissions.roleAllowsUserToDeleteScopedUsersOwnedAssetsItemLists && showDelete && userHasDeletionPrivilege" class="delete-col">
          <button mat-stroked-button color="primary" [matMenuTriggerFor]="menu" [disabled]="myUser.hasReadOnlyRestriction">Actions <mat-icon>expand_more</mat-icon></button>
          <mat-menu #menu="matMenu" xPosition="before">
            <button mat-menu-item style="text-transform: unset !important;" (click)="selectAllItemLists(true)">Select All (Item Lists)</button>
            <button mat-menu-item style="text-transform: unset !important;" (click)="selectAllItemLists(false)">Unselect All (Item Lists)</button>
            <button mat-menu-item style="text-transform: unset !important;" (click)="onItemListDeleteClick()">Delete Selected (Item Lists)</button>
          </mat-menu>
        </div>
      </div>
      <form [formGroup]="itemListForm">
        <div formArrayName="itemLists" *ngFor="let itemList of itemListsFormArray.controls; let i = index" class="asset-row">
          <div>{{itemLists[i]?.name}}</div>
          <div>{{itemLists[i]?.description}}</div>
          <div>{{addSpacesBetweenWords(itemLists[i]?.sharedWith)}}</div>
          <div *ngIf="permissions.roleAllowsUserToDeleteScopedUsersOwnedAssetsItemLists && showDelete && userHasDeletionPrivilege" class="delete-col"><mat-checkbox [formControlName]="i" [disabled]="myUser.hasReadOnlyRestriction"></mat-checkbox></div>
        </div>
      </form>
    </mat-expansion-panel>

    <mat-expansion-panel *ngIf="shouldShowManagedStoreGroupsSection" id="managed-store-groups-panel" (opened)="managedStoreGroupPanelOpenState = true" (closed)="managedStoreGroupPanelOpenState = false">
      <mat-expansion-panel-header>
        <mat-panel-title>
          Managed Store Groups
        </mat-panel-title>
        <mat-panel-description>
          {{managedStoreGroupDescription}}
        </mat-panel-description>
      </mat-expansion-panel-header>
      <div *ngIf="managedStoreGroups && managedStoreGroups.length > 0" class="asset-row header">
        <div>Name</div>
        <div>Description</div>
        <div>Stores In Group</div>
        <div *ngIf="permissions.roleAllowsUserToRemoveSSGAFromManagement && showDelete && userHasDeletionPrivilege" class="delete-col">
          <button mat-stroked-button color="primary" [matMenuTriggerFor]="menu" [disabled]="myUser.hasReadOnlyRestriction">Actions <mat-icon>expand_more</mat-icon>
            </button>
          <mat-menu #menu="matMenu" xPosition="before">
            <button mat-menu-item style="text-transform: unset !important;" (click)="selectAllManagedStoreGroups(true)">Select All
              (Managed Store Groups)</button>
            <button mat-menu-item style="text-transform: unset !important;" (click)="selectAllManagedStoreGroups(false)">Unselect All
              (Managed Store Groups)</button>
            <button mat-menu-item style="text-transform: unset !important;" (click)="onManagedStoreGroupDeleteClick()">Unmanage Selected Store Groups
              </button>
          </mat-menu>
        </div>
      </div>
      <form [formGroup]="managedStoreGroupForm">
        <div formArrayName="managedStoreGroups" *ngFor="let managedStoreGroup of managedStoreGroupsFormArray.controls; let i = index"
          class="asset-row">
          <div>{{managedStoreGroups[i]?.name}}</div>
          <div>{{managedStoreGroups[i]?.description}}</div>
          <div>{{managedStoreGroups[i]?.storesInGroup}}</div>
          <div *ngIf="permissions.roleAllowsUserToRemoveSSGAFromManagement && showDelete && userHasDeletionPrivilege" class="delete-col">
            <mat-checkbox [formControlName]="i" [disabled]="myUser.hasReadOnlyRestriction"></mat-checkbox>
          </div>
        </div>
      </form>
    </mat-expansion-panel>

  </mat-accordion>
</div>

