import { Injectable } from '@angular/core';
import { Idle, DEFAULT_INTERRUPTSOURCES, AutoResume } from '@ng-idle/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { AuthenticationService } from './authentication.service';

@Injectable({
    providedIn: 'root'
})
export class IdleService {

    isIdle:boolean = false;
    idleState = 'Not started.';
    isTimedOut:boolean = false;
    idleTime = 600; //600
    countdown = 60; //60
    isUserLoggedInSubscription;

    isIdle$: Observable<any>;
    private _isIdleSubject = new BehaviorSubject<any>(this.isIdle);
    idleState$: Observable<any>;
    private _idleStateSubject = new BehaviorSubject<any>(this.idleState);
    isTimedOut$: Observable<any>;
    private _isTimedOutSubject = new BehaviorSubject<any>(this.isTimedOut);
    countdown$: Observable<any>;
    private _countdownSubject = new BehaviorSubject<any>(this.countdown);
    isUserLoggedIn = false;
  
    constructor(
        private _idle: Idle,
        private _authenticationService: AuthenticationService,
    )
    {
        this.isUserLoggedInSubscription = this._authenticationService.isUserLoggedIn$
            .subscribe((data)=>{
                this.isUserLoggedIn = data;
        })

        this.isIdle$ = this._isIdleSubject.asObservable();
        this.idleState$ = this._idleStateSubject.asObservable();
        this.isTimedOut$ = this._isTimedOutSubject.asObservable();
        this.countdown$ = this._countdownSubject.asObservable();
    
        // sets an idle timeout of 5 seconds, for testing purposes.
        _idle.setIdle(this.idleTime);
        // sets a timeout period of 5 seconds. after 10 seconds of inactivity, the user will be considered timed out.
        _idle.setTimeout(this.countdown);
        // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
        _idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
        // user has to click button to resume. https://github.com/HackedByChinese/ng2-idle/issues/59
        //_idle.setAutoResume(AutoResume.notIdle);

        _idle.onIdleEnd.subscribe(() => {
            //this.idleState = 'No longer idle.'
            this.setIdleState('No longer idle.');
            this.setIsIdle(false);
            this.setIsTimedOut(false);
        });
        _idle.onTimeout.subscribe(() => {
            //this.idleState = 'Timed out!';
            //this.isTimedOut = true;
            //this.isIdle = false;
            this.setIdleState('Timed out!');
            this.setIsIdle(false);
            this.setIsTimedOut(true);
            this._authenticationService.logout();
        });
        _idle.onIdleStart.subscribe(() => {
            //this.idleState = 'You\'ve gone idle!'
            if (this.isUserLoggedIn){
                this.setIdleState('You\'ve gone idle!');
            }
            else{
                this.setIdleState('Stopped.');
                this.stopIdleWatch();
            }
        });
        _idle.onTimeoutWarning.subscribe((countdown) => {
            //this.idleState = 'You will time out in ' + countdown + ' seconds!';
            //this.isIdle = true;
            if (this.isUserLoggedIn){
                this.setCountdown(countdown);
                this.setIdleState('You will time out in ' + countdown + ' seconds!');
                this.setIsIdle(true);
            }
            else{
                this.setIdleState('Stopped.');
                this.stopIdleWatch();
            }
        });
    }

    setIsIdle(data) {
        this.isIdle = data;
        this._isIdleSubject.next(data);
    }

    setIdleState(data) {
        this.idleState = data;
        this._idleStateSubject.next(data);
    }

    setIsTimedOut(data) {
        this.isTimedOut = data;
        this._isTimedOutSubject.next(data);
    }

    setCountdown(data) {
        this.countdown = data;
        this._countdownSubject.next(data);
    }

    resetIdleWatch(){
        this._idle.watch();
        this.setIdleState('Started.');
        this.setIsTimedOut(false);
        this.setIsIdle(false);
    }

    stopIdleWatch(){
        this._idle.stop();
        this.setIdleState('Stopped!');
        this.setIsTimedOut(true);
        this.setIsIdle(false);
    }

    ngOnDestroy() {
        this._idle.stop();
        this.isUserLoggedInSubscription.unsubscribe();
    }

    //this.reset();
    // reset() {
    //     this._idle.watch();
    //     // this.idleState = 'Started.';
    //     // this.isTimedOut = false;
    //     // this.isIdle = false;
    //     this.setIdleState('Started.');
    //     this.setIsIdle(false);
    //     this.setIsTimedOut(false);
    // }

    // stop(){
    //     this._idle.stop();
    // }

}