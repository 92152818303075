import { Component, OnInit, Inject, LOCALE_ID, Input, ChangeDetectorRef, NgZone, ViewChild } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { UserService } from 'src/app/shared/user.service';
import { UntypedFormBuilder, Validators, AbstractControl } from '@angular/forms';
import { formatDate } from '@angular/common';
import { StoreGroupService } from 'src/app/shared/store-group.service';
import { StoreService } from 'src/app/shared/store.service';
import { AccessService } from 'src/app/shared/access.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MessageService } from 'src/app/shared/message.service';
import { TransferService } from 'src/app/shared/transfer.service';
import { PricingRulesService } from 'src/app/shared/pricing-rules.service';
import { ConfirmationDialogComponent } from 'src/app/shared/confirmation-dialog/confirmation-dialog.component';
import { MatAccordion } from '@angular/material/expansion';
import { AuthenticationService } from 'src/app/core/authentication/shared/authentication.service';
import { PermissionsService } from 'src/app/shared/permissions.service';
//import { environment } from 'src/environments/environment';
import { AppConfig } from 'src/app/app.config';

@Component({
  selector: 'app-store-transfer-dialog',
  templateUrl: './store-transfer-dialog.component.html',
  styleUrls: ['./store-transfer-dialog.component.css']
})
export class StoreTransferDialogComponent implements OnInit {

  @ViewChild(MatAccordion) accordion: MatAccordion;
  private config = AppConfig.settings;

  confirmationDialogRef: MatDialogRef<ConfirmationDialogComponent>;

  //users;

  storeGroups = [];
  allStoreGroupIds;
  
  stores = [];
  storeId;
  areStoresLoaded: boolean = false;

  assetTypes;

  controlledStores = [];
  allControlledStoreIds;
  areControlledStoresLoaded: boolean = false;

  pricingRules = [];
  allPricingRuleIds;
  arePricingRulesLoaded: boolean = false;

  assetUsers = [];
  allAssetUserIds;
  areAssetUsersLoaded: boolean = false;

  //inheritingStoreGroupStores = [];

  //rowData;
  storeGroupId; //passed in for store transfer to only show stores in same group
  entityType;
  entityName;
  donatingEntityId;
  logEntries = [];
  assetCount = 0;
  
  showTransferButton: boolean = false;
  transferOccurred: boolean = false; //used to reload list page if needed

  showFormControls: boolean = false;
  showInheritingStoreGroupSelect: boolean = false;
  showInheritingStoreSelect: boolean = false;
  showAssetTypeSelect: boolean = false;
  showControlledStoresSelect: boolean = false;
  showPricingRulesSelect: boolean = false;
  showAssetUsersSelect: boolean = false;

  usersPanelOpenState: boolean = false;
  pricingRulesPanelOpenState: boolean = false;
  usersDescription;
  pricingRulesDescription;

  myUser;
  permissions: any = {};
  shouldDisableStoreTransfer: boolean = true;
  
  assetTransferForm = this._fb.group({
    storeRadioButton: ['', Validators.required],
    inheritingStoreGroupId: [''],
    inheritingStoreId: [''],
    assetType: [''],
    controlledStoreIds: [''],
    pricingRuleIds: [''],
    assetUserIds: [''],
  });

  constructor(private _activatedRoute: ActivatedRoute,
              private _transferService: TransferService,
              private _userService: UserService,
              private _fb: UntypedFormBuilder,
              private _storeGroupService: StoreGroupService,
              private _storeService: StoreService,
              private _pricingRulesService: PricingRulesService,
              private _messageService: MessageService,
              private _authenticationService: AuthenticationService,
              private _permissionsService: PermissionsService,
              private ngZone: NgZone,
              private _dialog: MatDialog, 
              public dialogRef: MatDialogRef<StoreTransferDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) 
              {
                this.myUser = this._authenticationService.getMyUser();
                this.permissions = this._permissionsService.getPermissions();

                this.donatingEntityId = data.donatingEntityId,
                //this.rowData = data.rowData == undefined ? [] : data.rowData;
                this.entityType = 'Store', //data.entityType;
                this.entityName = data.entityName;
                //this.users = data.users == undefined ? [] : data.users;
                this.storeGroups = data.storeGroups == undefined ? [] : data.storeGroups;
                this.storeGroupId = data.storeGroupId;
                this.storeId = data.storeId;
                //this.doOnInit();
              }

  ngOnInit() {
    if (this.config.logInits) console.log('transfer dialog init');
    this.doOnInit();
  }

  doOnInit(){
    if (!this.myUser.hasTransferPrivilege || this.myUser.hasReadOnlyRestriction) {
      //lock fields
      this.assetTransferForm.disable();
    }

    if (this.permissions.roleAllowsUserToTransferStoreToAnotherStoreGroup) {
      this.shouldDisableStoreTransfer = false;
    }

    this.removeDonatingStoreGroupfromStoreGroups();
    this.getAssetTypes();
    this.getTransferLogs();
    this.getAllInheritingStoresByStoreGroupId(this.storeGroupId);
    //this.getAllPricingRulesByStoreIdSansMatchCriteria(this.donatingEntityId);
    //this.getAllManageableUsersByStore(this.donatingEntityId);
    //this.handleRequiredFields();
  }

  removeDonatingStoreGroupfromStoreGroups() {
    let arr = [];
    for (let i=0; i<this.storeGroups.length; i++) {
      if (this.storeGroups[i].storeGroupId != this.storeGroupId) arr.push(this.storeGroups[i]);
    }
    this.storeGroups = arr;
  }

  removeDonatingStorefromStores() {
    let arr = [];
    for (let i=0; i<this.stores.length; i++) {
      if (this.stores[i].storeId != this.storeId) arr.push(this.stores[i]);
    }
    this.stores = arr;
  }

  getAssetTypes(){
    this._transferService.getAccessibleTransferableAssetTypesByTransferEntityType(this.entityType)
    .subscribe(
      (data:any) => {
        this.assetTypes = data;
      },
      (response) => {
        this._messageService.onFailure('Failed to get Asset Types.', response);
      })
  }

  getTransferLogs(){
    this._transferService.getTransferLogsByTransferEntityTypeAndEntityId(this.entityType, this.donatingEntityId)
    .subscribe(
      (data:any) => {
        this.logEntries = data;
        this.logEntries.map(l => l.performedAt = new Date(l.performedAt).toLocaleString());
        this.logEntries.map(l => l.transferDescription = this.formatLogDescription(l.transferDescription));
      },
      (response) => {
        this._messageService.onFailure('Failed to get Transfer Log.', response);
      })
  }

  formatLogDescription(description) {
    if (description) {
      description = description.replace(/\r\n/g, '<br/>')
      .replace(/\u00b9/g, '<span class="description-highlight">')
      .replace(/\u00b2/g, '<span class="description-highlight">')
      .replace(/\u00b3/g, '<span class="description-highlight">')
      .replace(/\u2074/g, '<span class="description-highlight">')
      .replace(/\u2075/g, '<span class="description-highlight">')
      .replace(/\u2076/g, '<span class="description-highlight">')
      .replace(/\u2077/g, '<span class="description-highlight">')
      .replace(/\u2078/g, '<span class="description-highlight">')
      .replace(/\u00b7/g, '</span>');
    }
    return description;
  }

  // getAllDonatingStoresByStoreGroupId(storeGroupId) {
  //   this._storeService.getAllStoresByStoreGroupId(storeGroupId)
  //   .subscribe(
  //     (data:any) => {
  //       this.donatingStoreGroupStores = data;
  //     },
  //     (response) => {
  //       this._messageService.onFailure('Failed to get store from donating store group.', response);
  //     })
  // }

  getAllInheritingStoresByStoreGroupId(storeGroupId) {
    this.areStoresLoaded = false;
    this._storeService.getAllStoresByStoreGroupId(storeGroupId)
    .subscribe(
      (data:any) => {
        //this.inheritingStoreGroupStores = data;
        this.stores = data;
        this.removeDonatingStorefromStores();
        this.areStoresLoaded = true;
        //this.homeStores = data;
        // if (this.assetTransferForm.get('assetType').value == 'All') this.homeStores = this.donatingStoreGroupStores.concat(data);
      },
      (response) => {
        this._messageService.onFailure('Failed to get store from inheriting store group.', response);
      })
  }

  getControlledStores(storeId){
    this.areControlledStoresLoaded = false;
    this._storeService.getControlledStores(storeId)
      .subscribe(
        (data:any) => {
          this.controlledStores = data;
          this.allControlledStoreIds = data.map(a => a.storeId);
          this.areControlledStoresLoaded = true;
        },
        (response) => {
          this._messageService.onFailure('Failed to get Controlled Stores.', response);
        })
  }

  getAllPricingRulesByStoreIdSansMatchCriteria(storeId){
    this.arePricingRulesLoaded = false;
    this._pricingRulesService.getAllPricingRulesByStoreIdSansMatchCriteria(storeId)
      .subscribe(
        (data:any) => {
          this.pricingRules = data;
          this.allPricingRuleIds = data.map(a => a.pricingRuleId);
          this.pricingRulesDescription = data && data.length == 1 ? 
            '1 Pricing Rule is owned by this store' : data.length + ' Pricing Rules are owned by this store';
          this.arePricingRulesLoaded = true;
        },
        (response) => {
          this._messageService.onFailure('Failed to get Pricing Rules.', response);
        })
  }

  getAllManageableUsersByStore(storeId){
    this.areAssetUsersLoaded = false;
    this._userService.getAllManageableUsersByStore(storeId)
      .subscribe(
        (data:any) => {
          this.assetUsers = data.sort((a, b) => (a.fullName.toLowerCase() > b.fullName.toLowerCase()) ? 1 : -1);
          this.allAssetUserIds = data.map(a => a.userId);
          this.usersDescription = data && data.length == 1 ? 
            '1 User is in this store' : data.length + ' Users are in this store';
          this.areAssetUsersLoaded = true;
        },
        (response) => {
          this._messageService.onFailure('Failed to get Users in Store.', response);
        })
  }

  onUserRadioButtonChange(value){
    //https://stackoverflow.com/questions/41990171/angular-2-not-re-rendering-until-i-click-the-page
    //this behavior only happens on rows further down the ag-grid list (12+)
    this.ngZone.run(() => { //need this because radio buttons needed two mouse clicks

      this.unrequireAllAssetSelects();
      this.hideAllAssetSelects();
      this.unrequireAllFirstLevelSelects();
      this.hideAllFirstLevelSelects();
      this.clearAllSelects();
      this.showFormControls = true;
      switch(value) {
        case '1':
          this.assetTransferForm.get('inheritingStoreGroupId').setValidators([Validators.required]);
          this.assetTransferForm.get('inheritingStoreGroupId').updateValueAndValidity();
          this.showInheritingStoreGroupSelect = true;
          break;
        case '2':
          this.assetTransferForm.get('inheritingStoreId').setValidators([Validators.required]);
          this.assetTransferForm.get('inheritingStoreId').updateValueAndValidity();
          this.showInheritingStoreSelect = true;
          break;
        default:
          break;
      }

    });
  }

  onInheritingStoreChange(storeId){
    this.clearAllAssetSelects();
    this.assetTransferForm.get('assetType').setValue('');
    this.assetTransferForm.get('assetType').setValidators([Validators.required]);
    this.assetTransferForm.get('assetType').updateValueAndValidity();
    this.showAssetTypeSelect = true;
  }

  onInheritingStoreGroupChange(storeGroupId){
    this.clearAllAssetSelects();
    this.assetTransferForm.get('assetType').setValue('');
    this.getAllInheritingStoresByStoreGroupId(storeGroupId);
    //this.showHomeStoreSelect = true;
    //this.assetTransferForm.get('assetType').enable();
  }

  onAssetTypeChange(value) {
    this.unrequireAllAssetSelects();
    this.clearAllAssetSelects();
    this.hideAllAssetSelects();
    switch (value) {
      case 'All':
        break;
      case 'Controlled Stores': 
        //Commented out in GAIR-152
        // //test to make sure the inheriting store is not a controlled store
        // let selectedInheritingStoreId = this.assetTransferForm.get('inheritingStoreId').value;
        // let selectedIheritingStore = this.getStoreFromStoreId(selectedInheritingStoreId);
        // if (selectedIheritingStore.controllingStoreId != null) {
        //   this._messageService.alert('The inheriting store is a controlled store and cannot control other stores.');
        //   this.assetTransferForm.get('assetType').setValue('');
        // }
        // else {
          this.assetTransferForm.get('controlledStoreIds').setValidators([Validators.required]);
          this.assetTransferForm.get('controlledStoreIds').updateValueAndValidity();
          if (this.controlledStores.length == 0) this.getControlledStores(this.donatingEntityId);
          this.showControlledStoresSelect = true;
        //}
        break;
      case 'Pricing Rules': 
        this.assetTransferForm.get('pricingRuleIds').setValidators([Validators.required]);
        this.assetTransferForm.get('pricingRuleIds').updateValueAndValidity();
        if (this.pricingRules.length == 0) { 
          this.getAllPricingRulesByStoreIdSansMatchCriteria(this.donatingEntityId);
        }
        this.showPricingRulesSelect = true;
        break;
      case 'Users': 
        this.assetTransferForm.get('assetUserIds').setValidators([Validators.required]);
        this.assetTransferForm.get('assetUserIds').updateValueAndValidity();
        if (this.assetUsers.length == 0) this.getAllManageableUsersByStore(this.donatingEntityId);
        this.showAssetUsersSelect = true;
      break;
      default:
        this._messageService.alert(value + ' is not a valid asset type.')
      break;
    }
  }

  onTransferClick(){
    if (this.myUser.hasTransferPrivilege) {
        this.confirmationDialogRef = this._dialog.open(ConfirmationDialogComponent, {
        disableClose: false,
        //width: '600px',
        data: {
          title: 'Are you sure?',
          message: 'Have you coordinated these changes with the Retail Department?<br/><br/>Do you want to proceed with this transfer?',
          confirmText: 'Yes, proceed with transfer',
          cancelText: 'No, cancel this action!'
        }
      });
      this.confirmationDialogRef.afterClosed().subscribe(result => {
        if(result) {
          this.doTransfer()
        }
        this.confirmationDialogRef = null;
      });
    }
    else {
      this._messageService.alert('You are not authorized to transfer assets. If you believe you should have that privilege, contact an administrator.');
    }
  }

  doTransfer(){
    //Users with hasReadOnlyRestriction set to true should not even be allowed to invoke this action because it is hidden or disabled in the UI.
    //This is for extra protection. The back end will also be protected.
    if (this.myUser.hasReadOnlyRestriction){
      this._messageService.alertReadOnlyRestriction();
      return;
    }
    
    //if a property is on 'this', it comes from the data passed into this dialog from the user list page
    //if a property is on transfer, it comes from the form value
      let transfer = this.assetTransferForm.value;
      switch (transfer.storeRadioButton) {
        case '1': //store group and home store
          transfer.entityType = 'Store Group';
          transfer.donatingEntityId = this.storeGroupId;
          transfer.inheritingEntityId = transfer.inheritingStoreGroupId;
          transfer.assetType = 'Managed Stores (with Assets)';
          transfer.selectedAssetIds = [this.donatingEntityId]; //this store's id (in array)
          break;

        case '2': //assets
          transfer.entityType = 'Store';
          transfer.donatingEntityId = this.donatingEntityId;
          transfer.inheritingEntityId = transfer.inheritingStoreId;
          switch (transfer.assetType) {
            case 'All':
              transfer.selectedAssetIds = [''];
              break;

            case 'Controlled Stores':
              transfer.selectedAssetIds = transfer.controlledStoreIds;
              break;

            case 'Pricing Rules':
              transfer.selectedAssetIds = transfer.pricingRuleIds;
              break;

            case 'Users':
              transfer.selectedAssetIds = transfer.assetUserIds;
              break;
        
            default:
              transfer.selectedAssetIds = [];
              break;
          }
          break;

        default:
          break;
      }
      transfer.selectedAssetIds = transfer.selectedAssetIds.map(a => String(a));
      if (!transfer.homeStoreId) transfer.homeStoreId = 0;

      //console.log(transfer);
      this._transferService.transferSelectAssetsFromEntityToEntity(
        transfer.donatingEntityId, 
        transfer.inheritingEntityId, 
        transfer.entityType, 
        transfer.assetType, 
        transfer.homeStoreId, 
        transfer.selectedAssetIds)
        .subscribe(
          (data:any) => {
            this._messageService.onSuccess('Successfully transferred selected assets.')
            this.assetTransferForm.reset();
            this.hideAllAssetSelects();
            this.hideAllFirstLevelSelects();
            this.transferOccurred = true;
            //this.doOnInit(); //reload everything
            this.closeDialog();
          },
          (response) => {
            this._messageService.onFailure('Failed to transfer Assets.', response);
          })
  }

  getStoreFromStoreId(storeId){
    for (var i = 0; i < this.stores.length; i++) {
      if (this.stores[i].storeId == storeId) return this.stores[i];
    }
  }
  
  unrequireAllFirstLevelSelects(){
    this.assetTransferForm.get('inheritingStoreGroupId').setValidators([]);
    this.assetTransferForm.get('inheritingStoreGroupId').updateValueAndValidity();
    this.assetTransferForm.get('inheritingStoreId').setValidators([]);
    this.assetTransferForm.get('inheritingStoreId').updateValueAndValidity();
    this.assetTransferForm.get('assetType').setValidators([]);
    this.assetTransferForm.get('assetType').updateValueAndValidity();
  }

  unrequireAllAssetSelects(){
    this.assetTransferForm.get('controlledStoreIds').setValidators([]);
    this.assetTransferForm.get('controlledStoreIds').updateValueAndValidity();
    this.assetTransferForm.get('pricingRuleIds').setValidators([]);
    this.assetTransferForm.get('pricingRuleIds').updateValueAndValidity();
    this.assetTransferForm.get('assetUserIds').setValidators([]);
    this.assetTransferForm.get('assetUserIds').updateValueAndValidity();
  }
  
  clearAllSelects(){
    this.assetTransferForm.get('inheritingStoreGroupId').setValue('');
    this.assetTransferForm.get('inheritingStoreId').setValue('');
    this.assetTransferForm.get('assetType').setValue('');
    this.assetTransferForm.get('controlledStoreIds').setValue('');
    this.assetTransferForm.get('pricingRuleIds').setValue('');
    this.assetTransferForm.get('assetUserIds').setValue('');
  }

  clearAllAssetSelects(){
    this.assetTransferForm.get('controlledStoreIds').setValue('');
    this.assetTransferForm.get('pricingRuleIds').setValue('');
    this.assetTransferForm.get('assetUserIds').setValue('');
  }
 
  hideAllAssetSelects(){
    this.showControlledStoresSelect = false;
    this.showPricingRulesSelect = false;
    this.showAssetUsersSelect = false;
  }

  hideAllFirstLevelSelects(){
    this.showAssetTypeSelect = false;
    this.showInheritingStoreGroupSelect = false;
    this.showInheritingStoreSelect = false;
  }

  selectAllOptions(sa, formControlName, allIdsName) {
    if (sa._selected) {
      this.assetTransferForm.get(formControlName).setValue(this[allIdsName])
        sa._selected = true;
    }
    if (sa._selected == false) {
      this.assetTransferForm.get(formControlName).setValue([]);
    }
  }

  onAssetCountEvent(val){
    this.assetCount = val;
  }

  onDeletionMadeEvent(bool){
    if (bool) this.doOnInit();
  }

  closeDialog(){
    this.dialogRef.close(this.transferOccurred);
  }

}
