import { Component, OnInit, ViewChild } from '@angular/core';
import { AgGridAngular } from 'ag-grid-angular';
import { GridOptions } from 'ag-grid-community';
import { PriceManagementService } from 'src/app/shared/price-management.service';
import { GridService } from 'src/app/shared/grid.service';
import { MessageService } from 'src/app/shared/message.service';

@Component({
  selector: 'rpms-group-headers',
  templateUrl: './group-headers.component.html',
  styleUrls: ['./group-headers.component.css']
})
export class GroupHeadersComponent implements OnInit {
  @ViewChild('agGrid') agGrid: AgGridAngular;

  gridOptions: GridOptions;
  quickFilterText: string = '';
  context; //required by grid
  //frameworkComponents; //required by grid
  allStoreGroups;
  permissions: any = {};

  rowData = [];

  columnDefs:any = [
    {
      headerName: "Group #",
      field: "groupNumber",
      cellClass: "groupNumber-col",
      width:80,
      floatingFilterComponent: this._gridService.getCustomNumberFloatingFilter(),
      filter: this._gridService.getCustomNumberFilter(),
      filterParams: {
        defaultOption: "startsWith"
      }
    },
    {
      headerName: "Group Description",
      field: "groupDescription",
      cellClass: "groupDescription-col",
      width: 400,
      floatingFilterComponent: this._gridService.getCustomTextFloatingFilter(),
      filter: this._gridService.getCustomTextFilter(),
      filterParams: {
        defaultOption: "listOr"
      }
    },

  ];
  
  constructor(
    private _priceManagementService: PriceManagementService,
    private _gridService: GridService,
    private _messageService: MessageService,
    ) {
    this.gridOptions = <GridOptions> {
			columnDefs: this.columnDefs,
			enableColResize: true,
			enableFilter: true,
			enableSorting: true,
			rowData: this.rowData,
      rowHeight: 35,    
      defaultColDef: {
        sortable: true,
        resizable: true,
        floatingFilter: true,
      }
    }
    this.context = { componentParent: this }
   }

  ngOnInit() {
    this._priceManagementService.getAllOrderHeaders()
      .subscribe(
        (data) => {
          this.formatandSetRowData(data);
        },
        (response) => {
          this._messageService.onFailure('Failed to get order headers.', response);
        })
  }

  formatandSetRowData(data) {
    var gridData = [];
    for (var i=0; i<data.length; i++){
      var singleRowData = {};
      singleRowData = {
        groupNumber: data[i].groupNumber,
        groupDescription: data[i].groupDescription,
      };
      gridData.push(singleRowData);
    }
    this.rowData = gridData;
    this._gridService.onWindowResize('#ag-grid-wrapper');
    setTimeout(() => {
      this.fitColumns();
      this._gridService.sizeGrid('#ag-grid-wrapper');
    }, 100); 
  }

  clearFilters(){
    this.quickFilterText = '';
    this.agGrid.api.setGridOption('quickFilterText', this.quickFilterText);
    this.agGrid.api.setFilterModel(null);
    this.agGrid.api.onFilterChanged();
  }

  resetColumns(){
    this.agGrid.api.resetColumnState();
    this.fitColumns(); //since this is done by default also
  }

  autoSizeColumns(){
    var columns = this.agGrid.api.getColumns();
    this.agGrid.api.autoSizeColumns(columns);
  }

  fitColumns(){
    this.agGrid.api.sizeColumnsToFit();
  }

  onQuickFilterChange(){
    this.agGrid.api.setGridOption('quickFilterText', this.quickFilterText);
  }

}
