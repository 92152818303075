<div class="transfer admin-dialog">
<h1 mat-dialog-title class="transfer-header">Store Transfer: {{entityName}}</h1>
<mat-dialog-content>
<mat-tab-group dynamicHeight="true">
  <mat-tab label="Transfer">
    <form [formGroup]="assetTransferForm" id="asset-transfer-form" (ngSubmit)="onTransferClick()">

      <div class="no-privilege" *ngIf="!myUser.hasTransferPrivilege">You are not authorized to transfer assets. If you believe you should have that privilege, contact an administrator.</div>

      <mat-radio-group formControlName="storeRadioButton" aria-label="Select an option" class="vertical-radio"
        (change)="onUserRadioButtonChange($event.value)">
        <mat-radio-button value="1" [disabled]="shouldDisableStoreTransfer">Transfer store: {{entityName}} to another Store Group
        </mat-radio-button>
        <mat-radio-button value="2">Transfer the Assets of store: {{entityName}} to another Store</mat-radio-button>
      </mat-radio-group>

      <div class="form-controls" *ngIf="showFormControls">

        <div class="row" *ngIf="showInheritingStoreGroupSelect && storeGroups.length > 0">
          <mat-form-field>
            <mat-label>Inheriting Store Group</mat-label>
            <mat-select id="inheritingStoreGroupId" formControlName="inheritingStoreGroupId"
              placeholder="Inheriting Store Group" (selectionChange)="onInheritingStoreGroupChange($event.value)">
              <mat-option *ngFor="let storeGroup of storeGroups" [value]="storeGroup.storeGroupId">
                {{storeGroup.name}}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="assetTransferForm.hasError('required', 'storeGroup')">Inheriting Store Group is required
            </mat-error>
          </mat-form-field>
        </div>
        <div class="row" *ngIf="showInheritingStoreGroupSelect && storeGroups.length == 0">
          <div class="no-assets">You have no other store groups available to which to transfer assets.</div>
        </div>

        <div class="row" *ngIf="showInheritingStoreSelect && stores.length > 0">
          <mat-form-field>
            <mat-label>Inheriting Store</mat-label>
            <mat-select id="inheritingStoreId" formControlName="inheritingStoreId" 
            placeholder="Inheriting Store" (selectionChange)="onInheritingStoreChange($event.value)">
              <mat-option *ngFor="let store of stores" [value]="store.storeId">
                {{store.numberAndLocation}}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="assetTransferForm.hasError('required', 'store')">Inheriting Store is required</mat-error>
          </mat-form-field>
        </div>
        <div class="row" *ngIf="showInheritingStoreSelect && stores.length == 0 && areStoresLoaded">
          <div class="no-assets">You have no other stores available to which to transfer assets.</div>
        </div>

        <div class="row" *ngIf="showAssetTypeSelect">
          <mat-form-field>
            <mat-label>Asset Type</mat-label>
            <mat-select id="assetType" formControlName="assetType" placeholder="Asset Type" 
              (selectionChange)="onAssetTypeChange($event.value)">
              <mat-option *ngFor="let type of assetTypes" [value]="type">
                {{type}}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="assetTransferForm.hasError('required', 'user')">Asset Type is required</mat-error>
          </mat-form-field>
        </div>

        <div class="row" *ngIf="showControlledStoresSelect && controlledStores.length > 0">
          <mat-form-field>
            <mat-label>Controlled Stores</mat-label>
            <mat-select multiple id="controlledStoreIds" formControlName="controlledStoreIds" placeholder="Controlled Stores">
              <mat-option *ngIf="controlledStores.length > 1" [value]="0" (click)="selectAllOptions(sa5, 'controlledStoreIds', 'allControlledStoreIds')" #sa5 >Select All</mat-option>
              <mat-option *ngFor="let store of controlledStores" [value]="store.storeId">
                {{store.numberAndLocation}}
              </mat-option>
            </mat-select>
          </mat-form-field>	
        </div>
        <div class="row" *ngIf="showControlledStoresSelect && controlledStores.length == 0">
          <div class="no-assets">This store does not control any stores.</div>
        </div>

        <div class="row" *ngIf="showPricingRulesSelect && pricingRules.length > 0">
          <mat-form-field>
            <mat-label>Pricing Rules</mat-label>
            <mat-select multiple id="pricingRuleIds" formControlName="pricingRuleIds" placeholder="Pricing Rules">
              <mat-option *ngIf="pricingRules.length > 1" [value]="0" (click)="selectAllOptions(sa6, 'pricingRuleIds', 'allPricingRuleIds')" #sa6 >Select All</mat-option>
              <mat-option *ngFor="let pricingRule of pricingRules" [value]="pricingRule.pricingRuleId">
                {{pricingRule.name}}
              </mat-option>
            </mat-select>
          </mat-form-field>	
        </div>
        <div class="row" *ngIf="showPricingRulesSelect && pricingRules.length == 0 && arePricingRulesLoaded">
          <div class="no-assets">This {{entityType.toLowerCase()}} does not own any pricing rules.</div>
        </div>
    
        <div class="row" *ngIf="showAssetUsersSelect && assetUsers.length > 0">
          <mat-form-field>
            <mat-label>Users</mat-label>
            <mat-select multiple id="assetUserIds" formControlName="assetUserIds" placeholder="Users">
              <mat-option *ngIf="assetUsers.length > 1" [value]="0" (click)="selectAllOptions(sa7, 'assetUserIds', 'allAssetUserIds')" #sa7 >Select All</mat-option>
              <mat-option *ngFor="let user of assetUsers" [value]="user.userId">
                {{user.fullNameAndEmail}}
              </mat-option>
            </mat-select>
          </mat-form-field>	
        </div>
        <div class="row" *ngIf="showAssetUsersSelect && assetUsers.length == 0 && areAssetUsersLoaded">
          <div class="no-assets">This {{entityType.toLowerCase()}} does not own any users.</div>
        </div>

      </div>
      <div class="buttons-wrapper">
        <button mat-raised-button color="primary" [disabled]="!assetTransferForm.valid" type="submit">Transfer</button>
        <button mat-raised-button (click)="closeDialog()" type="button">Close</button>
      </div>

    </form>
  </mat-tab>

  <mat-tab label="Owned Assets">
    <ng-template mat-tab-label>
      <div>Owned Assets<span class="badge">{{assetCount}}</span></div>
    </ng-template>
    <app-store-owned-assets (assetCountEvent)="onAssetCountEvent($event)" (deletionMadeEvent)="onDeletionMadeEvent($event)" [storeId]="storeId" [showTransferButton]="showTransferButton" [transferOccurred]="transferOccurred"></app-store-owned-assets>
    <div class="buttons-wrapper">
      <button mat-raised-button (click)="closeDialog()" type="button">Close</button>
    </div>
  </mat-tab>

  <mat-tab label="Transfer History">
    <ng-template mat-tab-label>
      <div>Transfer History<span class="badge">{{logEntries.length}}</span></div>
    </ng-template>
      <div class="log-scroller">
      <div class="log-entry col-headers">
        <div class="time">Date, Time</div>
        <div class="type">Asset Type</div>
        <div class="description">Description</div>
        <div class="entity">Inheriting Entity</div>
        <div class="user">Performed By</div>
      </div>
      <div class="log-entry" *ngFor="let logEntry of logEntries">
        <div class="time">{{logEntry.performedAt}}</div>
        <div class="type">{{logEntry.assetType}}</div>
        <div class="description" [innerHTML]="logEntry.transferDescription"></div>
        <div class="entity">{{logEntry.inheritingEntityName}}</div>
        <div class="user">{{logEntry.performedByFullName}}</div>
      </div>
    </div>
    <div class="buttons-wrapper">
      <button mat-raised-button (click)="closeDialog()" type="button">Close</button>
    </div>
  </mat-tab>
</mat-tab-group>
</mat-dialog-content>
</div>