import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { AppConfig } from '../app.config';

@Injectable({
  providedIn: 'root'
})
export class ScheduleService {

  private _baseUrl = AppConfig.settings.baseUrl;
  private _reqHeader = new HttpHeaders({ 'Accept': 'application/json', 'Content-Type': 'application/json' });

  constructor ( 
    private _http: HttpClient, 
  ){}

  getSchedule() {
    return this._http.get(this._baseUrl + '/api/Schedule/GetSchedule', { headers: this._reqHeader });
  }

  updateSchedule(schedule) {
    return this._http.put(this._baseUrl + '/api/Schedule/UpdateSchedule', schedule, { headers: this._reqHeader });
  }

  setDailyScheduledEventBackwardOneDay(dailyScheduledEventAsString) {
    return this._http.get(this._baseUrl + '/api/Schedule/SetDailyScheduledEventBackwardOneDay/' + dailyScheduledEventAsString, { headers: this._reqHeader });
  }

  setDailyScheduledEventForwardOneDay(dailyScheduledEventAsString) {
    return this._http.get(this._baseUrl + '/api/Schedule/SetDailyScheduledEventForwardOneDay/' + dailyScheduledEventAsString, { headers: this._reqHeader });
  }

  setWeeklyScheduledEventBackwardOneWeek(weeklyScheduledEventAsString) {
    return this._http.get(this._baseUrl + '/api/Schedule/SetWeeklyScheduledEventBackwardOneWeek/' + weeklyScheduledEventAsString, { headers: this._reqHeader });
  }

  setWeeklyScheduledEventForwardOneWeek(weeklyScheduledEventAsString) {
    return this._http.get(this._baseUrl + '/api/Schedule/SetWeeklyScheduledEventForwardOneWeek/' + weeklyScheduledEventAsString, { headers: this._reqHeader });
  }

  setYearlyScheduledEventBackwardOneYear(yearlyScheduledEventAsString) {
    return this._http.get(this._baseUrl + '/api/Schedule/SetYearlyScheduledEventBackwardOneYear/' + yearlyScheduledEventAsString, { headers: this._reqHeader });
  }

  setYearlyScheduledEventForwardOneYear(yearlyScheduledEventAsString) {
    return this._http.get(this._baseUrl + '/api/Schedule/SetYearlyScheduledEventForwardOneYear/' + yearlyScheduledEventAsString, { headers: this._reqHeader });
  }

  isScheduleWindowOpen(scheduleWindowAsString) {
    return this._http.get(this._baseUrl + '/api/Schedule/IsScheduleWindowOpen/' + scheduleWindowAsString, { headers: this._reqHeader });
  }

  setProcessPricingRulesToNow() {
    return this._http.get(this._baseUrl + '/api/Schedule/SetOnDemandScheduledEventToNow/ProcessPricingRules', { headers: this._reqHeader });
  }

}
