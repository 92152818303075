<div class="detail admin-dialog">
  <h1 mat-dialog-title [ngClass]="{'accent': storeGroupId == '0'}">{{mode}} Store Group</h1>
  <mat-dialog-content>
    <mat-tab-group dynamicHeight="true">
      <mat-tab label="Store Group Information">
    
        <form [formGroup]="storeGroupDetailForm" id="store-group-detail-form" (ngSubmit)="onSubmitStoreGroup()">
          <div class="row">
            <mat-form-field>
              <mat-label>Store Group Name</mat-label>
              <input matInput id="name" formControlName="name" placeholder="Store Group Name" maxlength="30" required>
              <mat-error *ngIf="storeGroupDetailForm.hasError('required', 'name')">Store Group Name is required</mat-error>    
              <mat-hint align="end">{{storeGroupDetailForm.get('name').value.length}} / 30</mat-hint>
            </mat-form-field>
          </div>
          <div class="row">
            <mat-form-field>
              <mat-label>Description</mat-label>
              <input matInput id="description" formControlName="description" placeholder="Description">
            </mat-form-field>
          </div>
          <div class="detail-action-buttons">
            <button mat-raised-button color="primary" [disabled]="!storeGroupDetailForm.valid" type="submit">Save</button>
            <button mat-raised-button (click)="closeDialog()" type="button">Cancel</button>
          </div>
        </form>
      </mat-tab>
      <mat-tab label="Store Group Diagram" *ngIf=" storeGroupId != '0'">
        <div [innerHTML]="storeGroupDiagramHtml" class="store-group-diagram" ></div>
        <div class="detail-action-buttons">
          <button mat-raised-button (click)="closeDialog()" type="button">Close</button>
        </div>
      </mat-tab>
      <mat-tab label="Owned Assets" *ngIf=" storeGroupId != '0'">
        <ng-template mat-tab-label>
          <div>Owned Assets<span class="detail badge">{{assetCount}}</span></div>
        </ng-template>
        <app-store-group-owned-assets (assetCountEvent)="onAssetCountEvent($event)" (openTransferDialogEvent)="onTransferButtonClick()" [storeGroupId]="storeGroupId" [showTransferButton]="showTransferButton"></app-store-group-owned-assets>
        <div class="detail-action-buttons">
          <button mat-raised-button (click)="closeDialog()" type="button">Close</button>
        </div>      
      </mat-tab>
    </mat-tab-group>
    
  </mat-dialog-content>
</div>