<div class="assets admin-dialog">
  <h1 mat-dialog-title>Owned Assets: {{entityName}}</h1>
  <mat-dialog-content>
    <div *ngIf="entityType == 'Store Group'"><app-store-group-owned-assets (assetCountEvent)="onAssetCountEvent($event)" (deletionOccurredEvent)="onDeletionOccurredEvent($event)" (openTransferDialogEvent)="onTransferButtonClick()" [storeGroupId]="storeGroupId" [showTransferButton]="showTransferButton" [showDelete]="showDelete"></app-store-group-owned-assets></div>
    <div *ngIf="entityType == 'Store'"><app-store-owned-assets (assetCountEvent)="onAssetCountEvent($event)" (deletionOccurredEvent)="onDeletionOccurredEvent($event)" (openTransferDialogEvent)="onTransferButtonClick()" [storeId]="storeId" [showTransferButton]="showTransferButton" [showDelete]="showDelete"></app-store-owned-assets></div>
    <div *ngIf="entityType == 'User'"><app-user-owned-assets (assetCountEvent)="onAssetCountEvent($event)" (deletionOccurredEvent)="onDeletionOccurredEvent($event)" (openTransferDialogEvent)="onTransferButtonClick()" [userId]="userId" [showTransferButton]="showTransferButton" [showDelete]="showDelete"></app-user-owned-assets></div>
  </mat-dialog-content>
  <div class="non-scrolling-detail-action-buttons">
    <button mat-raised-button (click)="closeDialog()" type="button">Close</button>
  </div>      

</div>
