<div id="idle-modal" *ngIf="isIdle">
  <div id="idle-countdown">
      <h2>Session Timeout Warning</h2>
      <div class="content">
          <div>You have been idle for 10 minutes and will be logged out in:</div>
          <div class="timer">{{countdown}}</div>
          <div class="seconds">SECONDS</div>
          <div>Move the mouse to continue using this application.</div>
      </div>
  </div>
</div>
