<div id="pm-layout">
  <mat-progress-bar *ngIf="pbValue != 0" mode="determinate" [value]="pbValue"></mat-progress-bar>
  <div id="data-information" *ngIf="isDataInfoBarVisible" [ngClass]="{'synched': !isDataSynchronizing}">
      <span class="info">{{dataInfoBarMessage}}</span>
      <span class="synchronizing" *ngIf="isDataSynchronizing"><i class="fas fa-sync fa-spin"></i>&nbsp;Synchronizing with remote server now. </span>
      <button mat-stroked-button (click)="getItemsFromServer()"><i class="fas fa-redo-alt"></i>Reload data from server</button>
      <div class="close" (click)="closeDataInfoBar()"><i class="fa fa-times" aria-hidden="true"></i></div>
  </div>
  <div id="pm-grid-and-controls" [hidden]="!areDependenciesLoaded">
    <div id="pm-top-controls">
      <div class="mat-select-override">
        <label>Store:</label>
        <!-- <mat-form-field> -->
          <mat-select id="pm-store-select" name="store" #storeSelect="ngModel" [(ngModel)]="store" placeholder="Select a Store" [compareWith]="compareStoreObjects" (selectionChange)="onStoreSelected()">
            <!-- <mat-option [value]="{ storeId: null }" disabled style="color: #ccc">Select a Store</mat-option> -->
            <mat-option *ngFor="let store of stores" [value]="store">
              {{store.numberAndLocation}}
            </mat-option>
          </mat-select>
        <!-- </mat-form-field> -->
        <rpms-controlled-store-diagram [stores]="unfilteredStores" [store]="store" (shouldControlledStorePricingColumnBeVisibleEvent)="setControlledStorePricingColumnVisible($event)"></rpms-controlled-store-diagram>
      </div>
      <div id="pm-filter-tools" [hidden]="!isStoreSelected">
        <input id="pm-quick-filter" class="quick-filter help-trigger" placeholder="Quick Filter..." type="text" [(ngModel)]="quickFilterText" (keyup)="onQuickFilterChange()" (mouseenter)="showHelpfulTip($event)" (mouseleave)="hideHelpfulTip($event)" data-help="quick-filter"/>
        <button id="clear-filters-button" (click)="clearFilters()" class="help-trigger" mat-stroked-button [ngClass]="{active: isFilterApplied }" (mouseenter)="showHelpfulTip($event)" (mouseleave)="hideHelpfulTip($event)" data-help="clear-filters">Clear Filters</button>
        <rpms-price-management-item-filter #itemFilterComponent (loadedItemFiltersEvent)="onItemFiltersLoaded($event)" class="help-trigger" (mouseenter)="showHelpfulTip($event)" (mouseleave)="hideHelpfulTip($event)" data-help="saved-filters"></rpms-price-management-item-filter>
      </div>
      <button *ngIf="isStoreSelected && permissions.roleAllowsUserToExportPricing && !isMobile" mat-stroked-button id="export-button" class="help-trigger" data-help="export" (click)="onExport()" (mouseenter)="showHelpfulTip($event)" (mouseleave)="hideHelpfulTip($event)" data-help="export"><i class="fa fa-download"></i>Download Excel</button>
    </div>
    <rpms-price-management-tabs></rpms-price-management-tabs>
    <div id="grid-controls" [hidden]="!isStoreSelected">
      <mat-slide-toggle *ngIf="!isGridReadOnly" class="slide help-trigger" [checked]="isCellEditMode" (change)="onCellEditModeChange()" (mouseenter)="showHelpfulTip($event)" (mouseleave)="hideHelpfulTip($event)" data-help="cell-editing">Cell Editing</mat-slide-toggle>
      <span *ngIf="!isGridReadOnly" class="divider">&nbsp;</span>
      <mat-slide-toggle *ngIf="!isGridReadOnly" class="slide help-trigger" [checked]="isMasterInputMode" (change)="onMasterInputModeChange()" (mouseenter)="showHelpfulTip($event)" (mouseleave)="hideHelpfulTip($event)" data-help="master-inputs">Master Inputs</mat-slide-toggle>
      <button *ngIf="!isGridReadOnly" mat-flat-button color="accent" id="master-input-button" (click)="onApplyMasterChangeButtonClick()" [disabled]="!isMasterChangeModelValid">Apply</button>
      <span *ngIf="!isGridReadOnly" class="divider">&nbsp;</span>
      <button mat-stroked-button id="scroll-to-selected-button" class="help-trigger" (click)="viewSelectedRow()" (mouseenter)="showHelpfulTip($event)" (mouseleave)="hideHelpfulTip($event)" data-help="view-selected" title="View Selected Row"><i class="fa fa-eye"></i></button>
      <button mat-stroked-button id="reset-columns-button" class="help-trigger" (click)="resetColumns()" (mouseenter)="showHelpfulTip($event)" (mouseleave)="hideHelpfulTip($event)" data-help="reset-columns" title="Reset Columns"><i class="far fa-columns"></i></button>
      <button mat-stroked-button class="help-trigger" (click)="autoSizeColumns()" (mouseenter)="showHelpfulTip($event)" (mouseleave)="hideHelpfulTip($event)" data-help="autosize-columns" title="Auto Size Columns"><i class="far fa-arrow-from-left"></i></button>
    </div>
    <div id="pm-grid-and-footer-wrapper">
      <ag-grid-angular #agGrid 
      [gridOptions]="gridOptions"
      style="width: 100%;" 
      class="ag-theme-balham"
      id="pm-ag-grid-wrapper"
      [rowData]="rowData" 
      [columnDefs]="columnDefs"
      [excelStyles]="excelStyles"
      [context]="context"
      [quickFilterText]="quickFilterText"
      [statusBar]="statusBar"
      [getRowId]="getRowId"
      (filterChanged)="onFilterChanged()"
      (modelUpdated)="onModelUpdated()"
      >
      </ag-grid-angular>

      <div id="pm-grid-footer-wrapper" *ngIf="isShowingFooter">
        <div id="grid-footer">
          <div id="footer-content" class="cf">
            <div class="col col-20">
              <div><span class="footer-label">Item Code:</span><span id="footer-itemCode" class="footer-value">{{selectedRowData.itemCode}}</span></div>
              <div><span class="footer-label">UPC Code:</span><span id="footer-upcCode" class="footer-value">{{selectedRowData.upcCode}}</span></div>
              <div><span class="footer-label" title="Delivered Case Cost">Case Cost:</span><span id="footer-caseCost" class="footer-value">{{selectedRowData.deliveredCaseCost}}</span></div>
              <div><span class="footer-label">Base Cost:</span><span id="footer-baseCost" class="footer-value">{{selectedRowData.baseCost}}</span></div>
              <div>&nbsp;</div>
              <div><span class="footer-label">Status: </span><span id="footer-status" class="footer-value">{{selectedRowData.status}}</span> - <span id="footer-statusDescription"
                  class="footer-value">{{selectedRowData.statusDescription}}</span></div>
            </div>
            <div class="col col-40 cf">
              <h3 class="footer-header">Warehouse Information</h3>
              <div class="mvt cf">
                <div class="col-25"><span class="footer-label">LY Mvt</span><br /><span id="footer-lastYearsMovement" class="footer-value">{{selectedRowData.lastYearsMovement}}</span></div>
                <div class="col-25"><span class="footer-label">YTD Mvt</span><br /><span id="footer-yearToDateMovement" class="footer-value">{{selectedRowData.yearToDateMovement}}</span></div>
                <div class="col-25"><span class="footer-label">Wks in Whse</span><br /><span id="footer-weeksInInventory"
                    class="footer-value">{{selectedRowData.weeksInInventory}}</span></div>
                <div class="col-25"><span class="footer-label">Avg Wkly Mvt</span><br /><span id="footer-averageWeeklyMovement"
                    class="footer-value">{{selectedRowData.averageWeeklyMovement}}</span></div>
              </div>
              <div class="footer-item-info cf">
                <div class="col-50">Balance on Hand: <span id="footer-balanceOnHand" class="footer-value">{{selectedRowData.balanceOnHand}}</span></div>
                <div class="col-50">Off Pack: <span id="footer-offPack" class="footer-value">{{selectedRowData.offPack}}</span></div>
              </div>
              <div class="footer-item-info">
                <div>Order Header: <span id="footer-groupNumber" class="footer-value">{{selectedRowData.groupNumber}}</span> - <span id="footer-groupDescription"
                    class="footer-value">{{selectedRowData.groupDescription}}</span></div>
                <div>Buyer: <span id="footer-buyerNameAndEmail" class="footer-value"><a href="{{'mailto:' + selectedRowData.buyerEmail}}">{{selectedRowData.buyerName}}</a></span> &nbsp; <span id="footer-buyerPhone"
                    class="footer-value">{{selectedRowData.buyerPhone | phoneNumber}}</span></div>
              </div>
            </div>
            <div class="col col-40 cf">
              <h3 class="footer-header">Store <span id="footer-storeNumber" class="footer-value">{{store?.storeNumber}}</span> Information</h3>
              <div class="mvt cf">
                <div class="col-25"><span class="footer-label">LY Mvt</span><br /><span id="footer-lastYearsMovementForStore"
                    class="footer-value">{{selectedRowData.lastYearsMovementForStore}}</span></div>
                <div class="col-25"><span class="footer-label">YTD Mvt</span><br /><span id="footer-yearToDateMovementForStore"
                    class="footer-value">{{selectedRowData.yearToDateMovementForStore}}</span></div>
                <div class="col-25"><span class="footer-label">Wks in Store</span><br /><span id="footer-weeksInStore" class="footer-value">{{selectedRowData.weeksInStore}}</span></div>
                <div class="col-25"><span class="footer-label">Avg Wkly Mvt</span><br /><span id="footer-averageWeeklyMovementForStore"
                    class="footer-value">{{selectedRowData.averageWeeklyMovementForStore}}</span></div>
              </div>
              <div class="cf">
                <div class="col-66">
                  <div>Last Ordered: <span id="footer-lastPurchasedDate" class="footer-value">{{selectedRowData.lastPurchasedDate}}</span></div>
                  <div class="tag"><button mat-raised-button id="tpr-tag-button" (click)="showTprTagEditor()" [disabled]="!permissions.roleAllowsUserToOrderTprTags || myUser.hasReadOnlyRestriction">Compare &#64;
                      Tag</button><span id="tpr-tag-status">{{selectedRowData.tprTagStatus}}</span></div>
                  <div class="tag"><button mat-raised-button id="shelf-tag-button" (click)="showShelfTagEditor()" [disabled]="!permissions.roleAllowsUserToOrderShelfTags || myUser.hasReadOnlyRestriction">Shelf
                      Tag</button><span id="shelf-tag-status">{{selectedRowData.shelfTagStatus}}</span></div>
                </div>
                <div class="col-33">
                  <div>&nbsp;</div>
                  <div><mat-checkbox id="keepSrpCb" (change)="onKeepSrpChangeInFooter($event)" [(ngModel)]="isKeepSrpInFooterChecked" [disabled]="isGridReadOnly || !permissions.roleAllowsUserToEditPricing">Keep Price</mat-checkbox></div>
                  <div><mat-checkbox id="keepPercentCb" (change)="onKeepPercentChangeInFooter($event)" [(ngModel)]="isKeepPercentInFooterChecked" [disabled]="isGridReadOnly || !permissions.roleAllowsUserToEditPricing">Keep Percent</mat-checkbox></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="grid-footer-down" class="button toggle-footer" (click)="hideFooter()" *ngIf="isShowingFooter"><i class="fas fa-caret-down"></i></div>
      <div id="grid-footer-up" class="button toggle-footer" (click)="showFooter()" *ngIf="!isShowingFooter"><i class="fas fa-caret-up"></i></div>
    </div>
  </div>
  <div id="pm-statuses-wrapper">
    <div class="row-count" [hidden]="!areDependenciesLoaded">{{rowCount}} Rows</div>
    <div id="window-statuses" [hidden]="!isStoreSelected">
      <!-- <span class="window-status">Grocery Store Item<br />Price Changes</span><span class="status-open" *ngIf="storeItemPriceChangesEnabledForStoreObject.value">Open</span><span class="status-closed" *ngIf="!storeItemPriceChangesEnabledForStoreObject.value">Closed</span>
      <span class="window-status">Meat and Produce Store Item<br />Price Changes</span><span class="status-open" *ngIf="meatAndProduceStoreItemPriceChangesEnabledForStoreObject.value">Open</span><span class="status-closed" *ngIf="!meatAndProduceStoreItemPriceChangesEnabledForStoreObject.value">Closed</span>
      <span class="window-status">Grocery Preview<br />Price Changes</span><span class="status-open" *ngIf="previewStoreItemPriceChangesEnabledForStoreObject.value">Open</span><span class="status-closed" *ngIf="!previewStoreItemPriceChangesEnabledForStoreObject.value">Closed</span>
      <span class="window-status">Meat and Produce Preview<br />Price Changes</span><span class="status-open" *ngIf="meatAndProducePreviewStoreItemPriceChangesEnabledForStoreObject.value">Open</span><span class="status-closed" *ngIf="!meatAndProducePreviewStoreItemPriceChangesEnabledForStoreObject.value">Closed</span>
      <span class="window-status">Server<br />Maintenance</span><span class="status-open" *ngIf="serverMaintenanceWindowOpenObject.value">Open</span><span class="status-closed" *ngIf="!serverMaintenanceWindowOpenObject.value">Closed</span> -->
      <span class="window-status" [ngClass]="{ 'status-open' : storeItemPriceChangesEnabledForStoreObject.value }">Grocery Store Item<br />Price Changes <span class="status-open" *ngIf="storeItemPriceChangesEnabledForStoreObject.value">Open</span><span class="status-closed" *ngIf="!storeItemPriceChangesEnabledForStoreObject.value">Closed</span></span>
      <span class="window-status" [ngClass]="{ 'status-open' : meatAndProduceStoreItemPriceChangesEnabledForStoreObject.value }">Meat and Produce Store Item<br />Price Changes <span class="status-open" *ngIf="meatAndProduceStoreItemPriceChangesEnabledForStoreObject.value">Open</span><span class="status-closed" *ngIf="!meatAndProduceStoreItemPriceChangesEnabledForStoreObject.value">Closed</span></span>
      <span class="window-status" [ngClass]="{ 'status-open' : previewStoreItemPriceChangesEnabledForStoreObject.value }">Grocery Preview<br />Price Changes <span class="status-open" *ngIf="previewStoreItemPriceChangesEnabledForStoreObject.value">Open</span><span class="status-closed" *ngIf="!previewStoreItemPriceChangesEnabledForStoreObject.value">Closed</span></span>
      <span class="window-status" [ngClass]="{ 'status-open' : meatAndProducePreviewStoreItemPriceChangesEnabledForStoreObject.value }">Meat and Produce Preview<br />Price Changes <span class="status-open" *ngIf="meatAndProducePreviewStoreItemPriceChangesEnabledForStoreObject.value">Open</span><span class="status-closed" *ngIf="!meatAndProducePreviewStoreItemPriceChangesEnabledForStoreObject.value">Closed</span></span>
      <span class="window-status" [ngClass]="{ 'status-open' : serverMaintenanceWindowOpenObject.value }">Server Maintenance<br /><span class="status-open" *ngIf="serverMaintenanceWindowOpenObject.value">Open</span><span class="status-closed" *ngIf="!serverMaintenanceWindowOpenObject.value">Closed</span></span>
    </div>
  </div>
</div>


<rpms-price-percent-editor *ngIf="isPricePercentEditorVisible" [rowNodeToUpdate]="rowNodeToUpdate" [changeType]="changeType" [isGridReadOnly]="isGridReadOnly" [isItemReadOnly]="isItemReadOnly"
    (updatedRowNodeEvent)="onPricePercentOrBookChangeFromEditor($event)" 
    (isPricePercentEditorVisibleEvent)="setPricePercentEditorVisible($event)">
</rpms-price-percent-editor>

<rpms-book-editor *ngIf="isBookEditorVisible" [rowNodeToUpdate]="rowNodeToUpdate" [changeType]="changeType" [isGridReadOnly]="isGridReadOnly" [isItemReadOnly]="isItemReadOnly"
  (updatedRowNodeEvent)="onPricePercentOrBookChangeFromEditor($event)" 
  (isBookEditorVisibleEvent)="setBookEditorVisible($event)">
</rpms-book-editor>

<rpms-shelf-tag-editor *ngIf="isShelfTagEditorVisible" [rowNodeToUpdate]="rowNodeToUpdate"
  (updatedRowNodeEvent)="onShelfTagChangeFromEditor($event)" 
  (isShelfTagEditorVisibleEvent)="setShelfTagEditorVisible($event)">
</rpms-shelf-tag-editor>

<rpms-tpr-tag-editor *ngIf="isTprTagEditorVisible" [rowNodeToUpdate]="rowNodeToUpdate"
  (updatedRowNodeEvent)="onTprTagChangeFromEditor($event)" 
  (isTprTagEditorVisibleEvent)="setTprTagEditorVisible($event)">
</rpms-tpr-tag-editor>

<rpms-item-list-editor *ngIf="isItemListEditorVisible" [rowNodeToUpdate]="rowNodeToUpdate" [itemLists]="itemLists" [itemListEditorFilterValue]="itemListEditorFilterValue"
  (updatedRowNodeEvent)="updateItemWithUpdatedItemListData($event)" 
  (isItemListEditorVisibleEvent)="setItemListEditorVisible($event)"
  (itemListEditorFilterValueEvent)="setItemListEditorFilterValue($event)"
  >
</rpms-item-list-editor>

<rpms-controlled-store-pricing *ngIf="isControlledStorePricingVisible" [rowNodeToUpdate]="rowNodeToUpdate" [selectedStore]="store"
    (isControlledStorePricingVisibleEvent)="setControlledStorePricingVisible($event)">
</rpms-controlled-store-pricing>

<div id="export-tooltip" class="help">
  <div class="wrapper">
      <h4>Export to Excel</h4>
      <p>This button will export the data that is currently in the grid (with filters applied) in Excel format.</p>
  </div>
</div>
<div id="clear-filters-tooltip" class="help">
  <div class="wrapper">
      <h4>Clear All Filters</h4>
      <p>This button will clear all the filters that have been applied through column filters and/or the quick filter.</p>
  </div>
</div>
<div id="quick-filter-tooltip" class="help">
  <div class="wrapper">
      <h4>Quick Filter</h4>
      <p>Use this text input to filter across all columns at once.</p>
      <p>Example: Typing "123" will return all the items that contain the characters "123" in any column.</p>
  </div>
</div>
<div id="saved-filters-tooltip" class="help">
  <div class="wrapper">
      <h4>Saved Filters</h4>
      <p>This is a drop down list of all the saved filters. When items have been filtered using the column filters, the user can save the state of all the filters as a whole which can then be applied later by choosing the saved filter from this list.</p>
      <p>To save the current state of all the filters, click the Save button to the right of the Saved Filters drop down list, give the Filter a name (and optionally a description), and then click Save.  This filter will then be able to be applied at any time by selecting it from the list.</p>
      <p>Example: A user could filter the Private Label column to only show Piggly Wiggly Private Label items and at the same time filter the Price column to less than $3.00.  The user could then save that filter combination and name it something like "PW less than $3". This filter combination could quickly be applied in the future by selecting it from the list.</p>
  </div>
</div>
<div id="save-filter-tooltip" class="help">
  <div class="wrapper">
      <h4>Save Filter</h4>
      <p>This button will save the current state of the column filters. When items have been filtered using the column filters, the user can save the state of all the filters as a whole which can then be applied later by choosing the saved filter from the Saved Filters list.</p>
      <p>After clicking this Save button, give the Filter a name (and optionally a description), and then click Save.  This filter will then be able to be applied at any time by selecting it from the list.</p>
      <p>Example: A user could filter the Private Label column to only show Piggly Wiggly Private Label items and at the same time filter the Price column to less than $3.00.  The user could then save that filter combination and name it something like "PW less than $3". This filter combination could quickly be applied in the future by selecting it from the list.</p>
  </div>
</div>
<div id="show-keyword-filter-tooltip" class="help">
  <div class="wrapper">
      <h4>Show Keyword Filter</h4>
      <p>This button will open the keyword filter. Items can be filtered based on keywords that have been associated with each item.</p>
      <p>Example: Clicking a "Candy" keyword would filter the list to show all items with "Candy" as a keyword (i.e. show all candy items).  Further clicking on the the keyword "Chocolate" would only show items with "Chocolate" and "Candy" as keywords (i.e. show all chocolate candy items). Unchecking "Candy" would then show all items with "Chocolate" as a keyword (i.e. show all chocolate items... including the candy items that are chocolate).</p>
  </div>
</div>
<div id="cell-editing-tooltip" class="help">
  <div class="wrapper">
      <h4>Cell Editing</h4>
      <p>Turning this feature on allows you to edit Book, SRP Code, Multi, Price and Percent values directly in the grid when you double-click the desired cell.</p>
      <p>If this feature is off, editing is done by double-clicking the desired cell or by highlighting the desired cell and pressing enter.  This will open a detailed editor in which you can change values.</p>
  </div>
</div>
<div id="master-inputs-tooltip" class="help">
  <div class="wrapper">
      <h4>Master Inputs</h4>
      <p>Turning this feature on allows you to edit Book, SRP Code, Multi, Price and Percent values on multiple rows at once.</p>
      <p>The master inputs appear as green inputs in the column header. To change a value across multiple rows, you will need to check the box in each row you wish to change. After checking the desired rows and entering the desired value, click the Apply Master Value to Selected Rows button.</p>
  </div>
</div>
<div id="view-selected-tooltip" class="help">
  <div class="wrapper">
      <h4>View Selected Row</h4>
      <p>Pressing this button will scroll the selected row to the top of the grid.</p>
  </div>
</div>
<div id="reset-columns-tooltip" class="help">
  <div class="wrapper">
      <h4>Reset Columns</h4>
      <p>Pressing this button will clear all filters, reset the column order and size to the default settings, and reset each column filter to its default option (Starts With, Contains, etc.).</p>
  </div>
</div>
<div id="autosize-columns-tooltip" class="help">
  <div class="wrapper">
      <h4>Auto Size Columns</h4>
      <p>Pressing this button will size each column to fit its content.</p>
  </div>
</div>
<div id="column-menu-tooltip" class="help">
  <div class="wrapper">
      <h4>Column Menu</h4>
      <img src="/assets/images/column-menu.png" />
      <h5>Pin Column</h5>
      <p>Pin (freeze) this column to the left or right of the grid and prevent it from scrolling.</p>
      <h5>Autosize This Column</h5>
      <p>Autosize this column to fit its content. Note: You can manually resize each column by dragging the right column border.</p>
      <h5>Autosize All Columns</h5>
      <p>Autosize all columns to fit each columns respective content.</p>
      <h5>Reset Columns</h5>
      <p>Set all columns back to default size and position.</p>
      <h5>Tool Panel</h5>
      <p>This will open a tool panel pinned to the right of the grid.  To close the tool panel, open this menu on any column and deselect Tool Panel.</p>
      <hr />
      <h4>Column Filter Menu</h4>
      <img src="/assets/images/column-filter-menu.png" />
      <p>This allows you to filter the data on values in this column. This menu will change depending on whether the data type is text, number, date, boolean, or set values.  Note: This is the exact same filter and menu that you can access using the filters below each column title.</p>
      <hr />
      <h4>Column Visibility Menu</h4>
      <img src="/assets/images/column-visibility-menu.png" />
      <p>This allows you to control whether a column is visible by checking or unchecking the box next to that column.</p>
  </div>
</div>
<div id="filter-menu-tooltip" class="help">
  <div class="wrapper">
      <h4>Column Filter Menu</h4>
      <p>This allows you to filter the data on values in this column and change the filter options. This menu will change depending on whether the data type is text, number, date, boolean, or set values.  Note: Using the filter input in this menu is the same as using the input below the column title.</p>
      <h5>Text/Number/Date Filter</h5>
      <img src="/assets/images/column-filter-menu.png" />
      <h5>Set Filter</h5>
      <img src="/assets/images/column-filter-menu-set.png" />
  </div>
</div>

<div id="itemCode-column-tooltip" class="help">
  <div class="wrapper">
      <h4>Item Code Column</h4>
      <h5>Sorting:</h5>
      <p>Sort by clicking the column header. The first click will sort the column ascending (<i class="fa fa-arrow-up" aria-hidden="true"></i>), the second click descending (<i class="fa fa-arrow-down" aria-hidden="true"></i>), and the third click will remove the sorting on this column.</p>
      <h5>Filtering:</h5>
      <p>You can use the input below the column title to filter the list of items by Item Code.</p>
      <p>As you start typing, the list will only show items with an Item Code that starts with the characters you have typed.</p>
      <p>Example: Typing the number "1" will show all items with Item Codes that start with a "1".  Typing "12" will filter the list even more to only show items with an Item Code that starts with "12"... and so on.</p>
      <p>Note: You can change the behavior of the filter to something other than "Starts with".  The options available are "CSV List (Or)", "CSV List (And)", "CSV List (Starts with)", "Equals", "Not equal", "Starts with", "Ends with", "Contains", "Not contains", "Less than", "Less than or equals", "Greater than", "Greater than or equals", "In range", and "Regular expression".  This filter option can be changed by clicking the button to the right of the filter input.</p>
      <p>To clear the filter, either delete all of the characters from the filter input, or press the Clear All Filters button at the top of the page. Note: Clicking Clear All Filters will clear all filters across all columns.</p>
  </div>
</div>
<div id="isPreview-column-tooltip" class="help">
  <div class="wrapper">
      <h4>Is Preview Column</h4>
      <h5>Sorting:</h5>
      <p>Sort by clicking the column header. The first click will sort the column ascending (<i class="fa fa-arrow-up" aria-hidden="true"></i>), the second click descending (<i class="fa fa-arrow-down" aria-hidden="true"></i>), and the third click will remove the sorting on this column.</p>
      <h5>Filtering:</h5>
      <p>You can use the filter below the column title to filter the list of items by Is Preview.</p>
      <p>Check the box or boxes next to the value to show only items that have that Is Preview value.</p>
      <p>Example: Checking True will only show items where Is Preview is set to true (i.e. Preview Items). </p>
      <p>To clear the filter, either select all values, or press the Clear All Filters button at the top of the page. Note: Clicking Clear All Filters will clear all filters across all columns.</p>
  </div>
</div>
<div id="isStock-column-tooltip" class="help">
  <div class="wrapper">
      <h4>Is Stock Column</h4>
      <h5>Sorting:</h5>
      <p>Sort by clicking the column header. The first click will sort the column ascending (<i class="fa fa-arrow-up" aria-hidden="true"></i>), the second click descending (<i class="fa fa-arrow-down" aria-hidden="true"></i>), and the third click will remove the sorting on this column.</p>
      <h5>Filtering:</h5>
      <p>You can use the filter below the column title to filter the list of items by Is Stock.</p>
      <p>Check the box or boxes next to the value to show only items that have that Is Stock value.</p>
      <p>Example: Checking True will only show items where Is Stock is set to true (i.e. Stock Items). </p>
      <p>To clear the filter, either select all values, or press the Clear All Filters button at the top of the page. Note: Clicking Clear All Filters will clear all filters across all columns.</p>
  </div>
</div>
<div id="deptCode-column-tooltip" class="help">
  <div class="wrapper">
      <h4>Dept Code Column</h4>
      <h5>Sorting:</h5>
      <p>Sort by clicking the column header. The first click will sort the column ascending (<i class="fa fa-arrow-up" aria-hidden="true"></i>), the second click descending (<i class="fa fa-arrow-down" aria-hidden="true"></i>), and the third click will remove the sorting on this column.</p>
      <h5>Filtering:</h5>
      <p>You can use the filter below the column title to filter the list of items by Is Stock.</p>
      <p>Check the box or boxes next to the value to show only items that have that Dept Code value.</p>
      <p>Example: Checking 'P' will only show items where the Dept Code is set to 'P' (i.e. Produce items). </p>
      <p>To clear the filter, either select all values, or press the Clear All Filters button at the top of the page. Note: Clicking Clear All Filters will clear all filters across all columns.</p>
  </div>
</div>
<div id="size-column-tooltip" class="help">
  <div class="wrapper">
      <h4>Size Column</h4>
      <h5>Sorting:</h5>
      <p>Sort by clicking the column header. The first click will sort the column ascending (<i class="fa fa-arrow-up" aria-hidden="true"></i>), the second click descending (<i class="fa fa-arrow-down" aria-hidden="true"></i>), and the third click will remove the sorting on this column.</p>
      <h5>Filtering:</h5>
      <p>You can use the input below the column title to filter the list of items by Size.</p>
      <p>As you start typing, the list will only show items with a Size that contain the characters you have typed.</p>
      <p>Example: Typing the number "1" will show all items with a Size that contain a "1" character.  Typing "12" will filter the list even more to only show items with a Size that contain the characters "12"... and so on.</p>
      <p>Note: You can change the behavior of the filter to something other than "Contains".  The options available are "Equals", "Not equal", "Starts with", "Ends with", "Contains", and "Not contains".  This filter option can be changed by clicking the button to the right of the filter input.</p>
      <p>To clear the filter, either delete all of the characters from the filter input, or press the Clear All Filters button at the top of the page. Note: Clicking Clear All Filters will clear all filters across all columns.</p>
  </div>
</div>
<div id="description-column-tooltip" class="help">
  <div class="wrapper">
      <h4>Description Column</h4>
      <h5>Sorting:</h5>
      <p>Sort by clicking the column header. The first click will sort the column ascending (<i class="fa fa-arrow-up" aria-hidden="true"></i>), the second click descending (<i class="fa fa-arrow-down" aria-hidden="true"></i>), and the third click will remove the sorting on this column.</p>
      <h5>Filtering:</h5>
      <p>You can use the input below the column title to filter the list of items by Description.</p>
      <p>As you start typing, the list will only show items with a Description that contain the characters you have typed. You can enter multiple values separated by a comma.</p>
      <p>Example: Typing the letter "P" will show all items with a Description that contain a "P" character.  Typing "PI" will filter the list even more to only show items with a Description that contain the characters "PI"... and so on. Typing "PI, PW" will only show items that contain either a "PI" or "PW".</p>
      <p>Note: You can change the behavior of the filter to something other than "CSV List (Or)".  The options available are "CSV List (Or)", "CSV List (And)", "CSV List (Starts with)", "Equals", "Not equal", "Starts with", "Ends with", "Contains", "Not contains", and "Regular expression".  This filter option can be changed by clicking the button to the right of the filter input.</p>
      <p>To clear the filter, either delete all of the characters from the filter input, or press the Clear All Filters button at the top of the page. Note: Clicking Clear All Filters will clear all filters across all columns.</p>
  </div>
</div>
<div id="longDescription-column-tooltip" class="help">
  <div class="wrapper">
      <h4>Long Description Column</h4>
      <h5>Sorting:</h5>
      <p>Sort by clicking the column header. The first click will sort the column ascending (<i class="fa fa-arrow-up" aria-hidden="true"></i>), the second click descending (<i class="fa fa-arrow-down" aria-hidden="true"></i>), and the third click will remove the sorting on this column.</p>
      <h5>Filtering:</h5>
      <p>You can use the input below the column title to filter the list of items by Long Description.</p>
      <p>As you start typing, the list will only show items with a Long Description that contain the characters you have typed. You can enter multiple values separated by a comma.</p>
      <p>Example: Typing the letter "P" will show all items with a Long Description that contain a "P" character.  Typing "PI" will filter the list even more to only show items with a Long Description that contain the characters "PI"... and so on. Typing "PI, PW" will only show items that contain either a "PI" or "PW".</p>
      <p>Note: You can change the behavior of the filter to something other than "CSV List (Or)".  The options available are "CSV List (Or)", "CSV List (And)", "CSV List (Starts with)", "Equals", "Not equal", "Starts with", "Ends with", "Contains", "Not contains", and "Regular expression".  This filter option can be changed by clicking the button to the right of the filter input.</p>
      <p>To clear the filter, either delete all of the characters from the filter input, or press the Clear All Filters button at the top of the page. Note: Clicking Clear All Filters will clear all filters across all columns.</p>
  </div>
</div>
<div id="upcCode-column-tooltip" class="help">
  <div class="wrapper">
      <h4>UPC Column</h4>
      <h5>Sorting:</h5>
      <p>Sort by clicking the column header. The first click will sort the column ascending (<i class="fa fa-arrow-up" aria-hidden="true"></i>), the second click descending (<i class="fa fa-arrow-down" aria-hidden="true"></i>), and the third click will remove the sorting on this column.</p>
      <h5>Filtering:</h5>
      <p>You can use the input below the column title to filter the list of items by UPC Code.</p>
      <p>As you start typing, the list will only show items with a UPC Code that starts with the characters you have typed.</p>
      <p>Example: Typing the number "1" will show all items with a UPC Code that start with a "1" character.  Typing "12" will filter the list even more to only show items with a UPC Code that starts with the characters "12"... and so on.</p>
      <p>Note: You can change the behavior of the filter to something other than "Starts with".  The options available are "Equals", "Not equal", "Starts with", "Ends with", "Contains", and "Not contains".  This filter option can be changed by clicking the button to the right of the filter input.</p>
      <p>To clear the filter, either delete all of the characters from the filter input, or press the Clear All Filters button at the top of the page. Note: Clicking Clear All Filters will clear all filters across all columns.</p>
  </div>
</div>
<div id="groupNumber-column-tooltip" class="help">
  <div class="wrapper">
      <h4>Group Number Column</h4>
      <h5>Sorting:</h5>
      <p>Sort by clicking the column header. The first click will sort the column ascending (<i class="fa fa-arrow-up" aria-hidden="true"></i>), the second click descending (<i class="fa fa-arrow-down" aria-hidden="true"></i>), and the third click will remove the sorting on this column.</p>
      <h5>Filtering:</h5>
      <p>You can use the input below the column title to filter the list of items by Group Number.</p>
      <p>As you start typing, the list will only show items with a Group Number that equals the value you have typed. You can enter multiple values separated by a comma. You can also enter a range as one of the comma separated values.</p>
      <p>Example: Typing the number "123" will show all items with a Group Number that equals "123". Typing "123, 456" will show items with a Group Number that equals "123" or "456". Typing "123, 200:300" will show items with a Group Number that equals "123" plus items with a Group Number in the range between "200" and "300".</p>
      <p>Note: You can change the behavior of the filter to something other than "Equals".  The options available are "CSV List (Equals or Range)", "Equals", "Not equal", "Less than", "Less than or equals", "Greater than", "Greater than or equals", and "In range".  If you select "In range", then you can enter two values and the list will be filtered by values falling within the range of those two values.  This filter option can be changed by clicking the button to the right of the filter input.</p>
      <p>To clear the filter, either delete all of the characters from the filter input, or press the Clear All Filters button at the top of the page. Note: Clicking Clear All Filters will clear all filters across all columns.</p>
      <button mat-raised-button color="primary" (click)="showGroupHeadersDialog()">Show Group Header Reference</button>
      <!-- <md-button class="md-primary md-raised" ng-click="vm.showGroupHeadersDialog($event)">
          Show Group Header Reference
      </md-button> -->
  </div>
</div>
<!-- <md-dialog aria-label="Warning" id="group-headers-dialog" ng-controller="GroupHeadersCtrl as vm">
  <md-toolbar>
      <div class="md-toolbar-tools">
          <h2>Group Headers Reference</h2>
      </div>
  </md-toolbar>

  <div class="table-toolbar">
      <button ng-click="vm.clearGroupHeadersFilters()">Clear Filters</button>
      <input id="keywords-quick-filter" class="quick-filter" placeholder="Quick Filter..." type="text" ng-model="vm.groupHeadersQuickFilter" ng-change="vm.onGroupHeadersFilterChanged()" />
  </div>

  <div ag-grid="vm.groupHeadersGridOptions" id="group-headers-grid" class="ag-fresh grid-wrapper" style="height: 340px; width:100%"></div>
  <md-dialog-actions layout="row">
      <span flex></span>
      <md-button class="md-primary md-raised" type="submit" ng-click="vm.hideGroupHeadersDialog()">
          Close
      </md-button>
  </md-dialog-actions>
</md-dialog> -->
<div id="groupDescription-column-tooltip" class="help">
  <div class="wrapper">
      <h4>Group Description Column</h4>
      <h5>Sorting:</h5>
      <p>Sort by clicking the column header. The first click will sort the column ascending (<i class="fa fa-arrow-up" aria-hidden="true"></i>), the second click descending (<i class="fa fa-arrow-down" aria-hidden="true"></i>), and the third click will remove the sorting on this column.</p>
      <h5>Filtering:</h5>
      <p>You can use the input below the column title to filter the list of items by Group Description.</p>
      <p>As you start typing, the list will only show items with a Group Description that contain the characters you have typed.</p>
      <p>Example: Typing the letter "P" will show all items with a Group Description that contain a "P" character.  Typing "PI" will filter the list even more to only show items with a Group Description that contain the characters "PI"... and so on.</p>
      <p>Note: You can change the behavior of the filter to something other than "Contains".  The options available are "Equals", "Not equal", "Starts with", "Ends with", "Contains", and "Not contains".  This filter option can be changed by clicking the button to the right of the filter input.</p>
      <p>To clear the filter, either delete all of the characters from the filter input, or press the Clear All Filters button at the top of the page. Note: Clicking Clear All Filters will clear all filters across all columns.</p>
  </div>
</div>
<div id="lastYearsMovementForStore-column-tooltip" class="help">
  <div class="wrapper">
      <h4>Last Year's Movement for Store Column</h4>
      <h5>Sorting:</h5>
      <p>Sort by clicking the column header. The first click will sort the column ascending (<i class="fa fa-arrow-up" aria-hidden="true"></i>), the second click descending (<i class="fa fa-arrow-down" aria-hidden="true"></i>), and the third click will remove the sorting on this column.</p>
      <h5>Filtering:</h5>
      <p>You can use the input below the column title to filter the list of items by Last Year's Movement for Store.</p>
      <p>As you start typing, the list will only show items with a Last Year's Movement for Store value that starts with the characters you have typed.</p>
      <p>Example: Typing the number "1" will show all items with a Last Year's Movement for Store value that start with a "1" character.  Typing "12" will filter the list even more to only show items with a Last Year's Movement for Store that starts with the characters "12"... and so on.</p>
      <p>Note: You can change the behavior of the filter to something other than "Starts with".  The options available are "Equals", "Not equal", "Starts with", "Ends with", "Contains", and "Not contains".  This filter option can be changed by clicking the button to the right of the filter input.</p>
      <p>To clear the filter, either delete all of the characters from the filter input, or press the Clear All Filters button at the top of the page. Note: Clicking Clear All Filters will clear all filters across all columns.</p>
  </div>
</div>
<div id="yearToDateMovementForStore-column-tooltip" class="help">
  <div class="wrapper">
      <h4>Year to Date Movement for Store Column</h4>
      <h5>Sorting:</h5>
      <p>Sort by clicking the column header. The first click will sort the column ascending (<i class="fa fa-arrow-up" aria-hidden="true"></i>), the second click descending (<i class="fa fa-arrow-down" aria-hidden="true"></i>), and the third click will remove the sorting on this column.</p>
      <h5>Filtering:</h5>
      <p>You can use the input below the column title to filter the list of items by Year to Date Movement for Store.</p>
      <p>As you start typing, the list will only show items with a Year to Date Movement for Store value that starts with the characters you have typed.</p>
      <p>Example: Typing the number "1" will show all items with a Year to Date Movement for Store value that start with a "1" character.  Typing "12" will filter the list even more to only show items with a Year to Date Movement for Store that starts with the characters "12"... and so on.</p>
      <p>Note: You can change the behavior of the filter to something other than "Starts with".  The options available are "Equals", "Not equal", "Starts with", "Ends with", "Contains", and "Not contains".  This filter option can be changed by clicking the button to the right of the filter input.</p>
      <p>To clear the filter, either delete all of the characters from the filter input, or press the Clear All Filters button at the top of the page. Note: Clicking Clear All Filters will clear all filters across all columns.</p>
  </div>
</div>
<div id="lastYearsMovement-column-tooltip" class="help">
  <div class="wrapper">
      <h4>Last Year's Movement for Warehouse Column</h4>
      <h5>Sorting:</h5>
      <p>Sort by clicking the column header. The first click will sort the column ascending (<i class="fa fa-arrow-up" aria-hidden="true"></i>), the second click descending (<i class="fa fa-arrow-down" aria-hidden="true"></i>), and the third click will remove the sorting on this column.</p>
      <h5>Filtering:</h5>
      <p>You can use the input below the column title to filter the list of items by Last Year's Movement for Warehouse.</p>
      <p>As you start typing, the list will only show items with a Last Year's Movement for Warehouse value that starts with the characters you have typed.</p>
      <p>Example: Typing the number "1" will show all items with a Last Year's Movement for Warehouse value that start with a "1" character.  Typing "12" will filter the list even more to only show items with a Last Year's Movement for Warehouse that starts with the characters "12"... and so on.</p>
      <p>Note: You can change the behavior of the filter to something other than "Starts with".  The options available are "Equals", "Not equal", "Starts with", "Ends with", "Contains", and "Not contains".  This filter option can be changed by clicking the button to the right of the filter input.</p>
      <p>To clear the filter, either delete all of the characters from the filter input, or press the Clear All Filters button at the top of the page. Note: Clicking Clear All Filters will clear all filters across all columns.</p>
  </div>
</div>
<div id="yearToDateMovement-column-tooltip" class="help">
  <div class="wrapper">
      <h4>Year to Date Movement for Warehouse Column</h4>
      <h5>Sorting:</h5>
      <p>Sort by clicking the column header. The first click will sort the column ascending (<i class="fa fa-arrow-up" aria-hidden="true"></i>), the second click descending (<i class="fa fa-arrow-down" aria-hidden="true"></i>), and the third click will remove the sorting on this column.</p>
      <h5>Filtering:</h5>
      <p>You can use the input below the column title to filter the list of items by Year to Date Movement for Warehouse.</p>
      <p>As you start typing, the list will only show items with a Year to Date Movement for Warehouse value that starts with the characters you have typed.</p>
      <p>Example: Typing the number "1" will show all items with a Year to Date Movement for Warehouse value that start with a "1" character.  Typing "12" will filter the list even more to only show items with a Year to Date Movement for Warehouse that starts with the characters "12"... and so on.</p>
      <p>Note: You can change the behavior of the filter to something other than "Starts with".  The options available are "Equals", "Not equal", "Starts with", "Ends with", "Contains", and "Not contains".  This filter option can be changed by clicking the button to the right of the filter input.</p>
      <p>To clear the filter, either delete all of the characters from the filter input, or press the Clear All Filters button at the top of the page. Note: Clicking Clear All Filters will clear all filters across all columns.</p>
  </div>
</div>
<div id="privateLabel-column-tooltip" class="help">
  <div class="wrapper">
      <h4>Private Label Column</h4>
      <h5>Sorting:</h5>
      <p>Sort by clicking the column header. The first click will sort the column ascending (<i class="fa fa-arrow-up" aria-hidden="true"></i>), the second click descending (<i class="fa fa-arrow-down" aria-hidden="true"></i>), and the third click will remove the sorting on this column.</p>
      <h5>Filtering:</h5>
      <p>You can use the filter below the column title to filter the list of items by Private Label.</p>
      <p>Check the box or boxes next to the value to show only items that have that Private Label value.</p>
      <p>Example: Checking the Piggly Wiggly box will filter the list to only show Piggly Wiggly Private Label items. </p>
      <p>To clear the filter, either select all values, or press the Clear All Filters button at the top of the page. Note: Clicking Clear All Filters will clear all filters across all columns.</p>
  </div>
</div>
<div id="newItem-column-tooltip" class="help">
  <div class="wrapper">
      <h4>New Item Column</h4>
      <h5>Sorting:</h5>
      <p>Sort by clicking the column header. The first click will sort the column ascending (<i class="fa fa-arrow-up" aria-hidden="true"></i>), the second click descending (<i class="fa fa-arrow-down" aria-hidden="true"></i>), and the third click will remove the sorting on this column.</p>
      <h5>Filtering:</h5>
      <p>You can use the filter below the column title to filter the list of items by New Items.</p>
      <p>Check the box or boxes next to the value to show only items that have that New Item value.</p>
      <p>Example: Only checking the "true" box will filter the list to only show New Items. Only checking the "false" box will filter the list to only show items that are not New items. Checking "true" and "false" will show all items.</p>
      <p>To clear the filter, either select all values, or press the Clear All Filters button at the top of the page. Note: Clicking Clear All Filters will clear all filters across all columns.</p>
  </div>
</div>
<div id="firstTimeBuy-column-tooltip" class="help">
  <div class="wrapper">
      <h4>First Time Buy Column</h4>
      <h5>Sorting:</h5>
      <p>Sort by clicking the column header. The first click will sort the column ascending (<i class="fa fa-arrow-up" aria-hidden="true"></i>), the second click descending (<i class="fa fa-arrow-down" aria-hidden="true"></i>), and the third click will remove the sorting on this column.</p>
      <h5>Filtering:</h5>
      <p>You can use the filter below the column title to filter the list of items by First Time Buy.</p>
      <p>Check the box or boxes next to the value to show only items that have that First Time Buy value.</p>
      <p>Example: Only checking the "true" box will filter the list to only show First Time Buy items. Only checking the "false" box will filter the list to only show not items that are not First Time Buys. Checking "true" and "false" will show all items.</p>
      <p>To clear the filter, either select all values, or press the Clear All Filters button at the top of the page. Note: Clicking Clear All Filters will clear all filters across all columns.</p>
  </div>
</div>
<div id="wicItem-column-tooltip" class="help">
  <div class="wrapper">
      <h4>WIC Column</h4>
      <h5>Sorting:</h5>
      <p>Sort by clicking the column header. The first click will sort the column ascending (<i class="fa fa-arrow-up" aria-hidden="true"></i>), the second click descending (<i class="fa fa-arrow-down" aria-hidden="true"></i>), and the third click will remove the sorting on this column.</p>
      <h5>Filtering:</h5>
      <p>You can use the filter below the column title to filter the list of items by WIC items.</p>
      <p>Check the box or boxes next to the value to show only items that have that WIC value.</p>
      <p>Example: Only checking the "true" box will filter the list to only show WIC items. Only checking the "false" box will filter the list to only show items that are not WIC items. Checking "true" and "false" will show all items.</p>
      <p>To clear the filter, either select all values, or press the Clear All Filters button at the top of the page. Note: Clicking Clear All Filters will clear all filters across all columns.</p>
  </div>
</div>
<div id="nowItem-column-tooltip" class="help">
  <div class="wrapper">
      <h4>Now Item Column</h4>
      <h5>Sorting:</h5>
      <p>Sort by clicking the column header. The first click will sort the column ascending (<i class="fa fa-arrow-up" aria-hidden="true"></i>), the second click descending (<i class="fa fa-arrow-down" aria-hidden="true"></i>), and the third click will remove the sorting on this column.</p>
      <h5>Filtering:</h5>
      <p>You can use the filter below the column title to filter the list of items by Now items.</p>
      <p>Check the box or boxes next to the value to show only items that have that Now Item value.</p>
      <p>Example: Only checking the "true" box will filter the list to only show Now items. Only checking the "false" box will filter the list to only show items that are not Now items. Checking "true" and "false" will show all items.</p>
      <p>To clear the filter, either select all values, or press the Clear All Filters button at the top of the page. Note: Clicking Clear All Filters will clear all filters across all columns.</p>
  </div>
</div>
<div id="unitCost-column-tooltip" class="help">
  <div class="wrapper">
      <h4>Unit Cost Column</h4>
      <h5>Sorting:</h5>
      <p>Sort by clicking the column header. The first click will sort the column ascending (<i class="fa fa-arrow-up" aria-hidden="true"></i>), the second click descending (<i class="fa fa-arrow-down" aria-hidden="true"></i>), and the third click will remove the sorting on this column.</p>
      <h5>Filtering:</h5>
      <p>You can use the input below the column title to filter the list of items by Unit Cost.</p>
      <p>Enter an amount to only show items that have a Unit Cost with that amount.</p>
      <p>Example: Typing "1.23" will filter the list to only show items with a Unit Cost that equals $1.23</p>
      <p>Note: You can change the behavior of the filter to something other than "Equals".  The options available are "Equals", "Not equal", "Less than", "Less than or equals", "Greater than", "Greater than or equals", and "In range".  If you select "In range", then you can enter two values and the list will be filtered by values falling within the range of those two values.  This filter option can be changed by clicking the button to the right of the filter input.</p>
      <p>To clear the filter, either delete all of the characters from the filter input, or press the Clear All Filters button at the top of the page. Note: Clicking Clear All Filters will clear all filters across all columns.</p>
  </div>
</div>
<div id="oldUnitCost-column-tooltip" class="help">
  <div class="wrapper">
      <h4>Old Unit Cost Column</h4>
      <h5>Sorting:</h5>
      <p>Sort by clicking the column header. The first click will sort the column ascending (<i class="fa fa-arrow-up" aria-hidden="true"></i>), the second click descending (<i class="fa fa-arrow-down" aria-hidden="true"></i>), and the third click will remove the sorting on this column.</p>
      <h5>Filtering:</h5>
      <p>You can use the input below the column title to filter the list of items by Old Unit Cost.</p>
      <p>Enter an amount to only show items that have an Old Unit Cost with that amount.</p>
      <p>Example: Typing "1.23" will filter the list to only show items with an Old Unit Cost that equals $1.23</p>
      <p>Note: You can change the behavior of the filter to something other than "Equals".  The options available are "Equals", "Not equal", "Less than", "Less than or equals", "Greater than", "Greater than or equals", and "In range".  If you select "In range", then you can enter two values and the list will be filtered by values falling within the range of those two values.  This filter option can be changed by clicking the button to the right of the filter input.</p>
      <p>To clear the filter, either delete all of the characters from the filter input, or press the Clear All Filters button at the top of the page. Note: Clicking Clear All Filters will clear all filters across all columns.</p>
  </div>
</div>
<div id="book-column-tooltip" class="help">
  <div class="wrapper">
      <h4>Book Column</h4>
      <h5>Sorting:</h5>
      <p>Sort by clicking the column header. The first click will sort the column ascending (<i class="fa fa-arrow-up" aria-hidden="true"></i>), the second click descending (<i class="fa fa-arrow-down" aria-hidden="true"></i>), and the third click will remove the sorting on this column.</p>
      <h5>Filtering:</h5>
      <p>You can use the filter button below the column title to filter the list of items by Book.</p>
      <p>Select one or more values you want to filter on by checking the box next to each value.</p>
      <p>Example: Checking only "1" will filter the list of items to show only items with a Book value of "1".</p>
      <p>To clear the filter, either select all values, or press the Clear All Filters button at the top of the page. Note: Clicking Clear All Filters will clear all filters across all columns.</p>
  </div>
</div>
<div id="cd-column-tooltip" class="help">
  <div class="wrapper">
      <h4>SRP Code Column</h4>
      <h5>Sorting:</h5>
      <p>Sort by clicking the column header. The first click will sort the column ascending (<i class="fa fa-arrow-up" aria-hidden="true"></i>), the second click descending (<i class="fa fa-arrow-down" aria-hidden="true"></i>), and the third click will remove the sorting on this column.</p>
      <h5>Filtering:</h5>
      <p>You can use the filter button below the column title to filter the list of items by SRP Code.</p>
      <p>Select one or more values you want to filter on by checking the box next to each value.</p>
      <p>Example: Checking only "1" will filter the list of items to show only items with an SRP Code value of "1".</p>
      <p>To clear the filter, either select all values, or press the Clear All Filters button at the top of the page. Note: Clicking Clear All Filters will clear all filters across all columns.</p>
  </div>
</div>
<div id="n-column-tooltip" class="help">
  <div class="wrapper">
      <h4>Multi Column</h4>
      <h5>Sorting:</h5>
      <p>Sort by clicking the column header. The first click will sort the column ascending (<i class="fa fa-arrow-up" aria-hidden="true"></i>), the second click descending (<i class="fa fa-arrow-down" aria-hidden="true"></i>), and the third click will remove the sorting on this column.</p>
      <h5>Filtering:</h5>
      <p>You can use the input below the column title to filter the list of items by Multi.</p>
      <p>Enter an amount to only show items that have a Multi value that equals that amount.</p>
      <p>Example: Typing "3" will filter the list to only show items with a Multi value that equals 3.</p>
      <p>Note: You can change the behavior of the filter to something other than "Equals".  The options available are "Equals", "Not equal", "Less than", "Less than or equals", "Greater than", "Greater than or equals", and "In range".  If you select "In range", then you can enter two values and the list will be filtered by values falling within the range of those two values.  This filter option can be changed by clicking the button to the right of the filter input.</p>
      <p>To clear the filter, either delete all of the characters from the filter input, or press the Clear All Filters button at the top of the page. Note: Clicking Clear All Filters will clear all filters across all columns.</p>
  </div>
</div>
<div id="price-column-tooltip" class="help">
  <div class="wrapper">
      <h4>Price Column</h4>
      <h5>Sorting:</h5>
      <p>Sort by clicking the column header. The first click will sort the column ascending (<i class="fa fa-arrow-up" aria-hidden="true"></i>), the second click descending (<i class="fa fa-arrow-down" aria-hidden="true"></i>), and the third click will remove the sorting on this column.</p>
      <h5>Filtering:</h5>
      <p>You can use the input below the column title to filter the list of items by Price.</p>
      <p>Enter an amount to only show items that have a Price with that amount.</p>
      <p>Example: Typing "1.23" will filter the list to only show items with a Price that equals $1.23</p>
      <p>Note: You can change the behavior of the filter to something other than "Equals".  The options available are "Equals", "Not equal", "Less than", "Less than or equals", "Greater than", "Greater than or equals", and "In range".  If you select "In range", then you can enter two values and the list will be filtered by values falling within the range of those two values.  This filter option can be changed by clicking the button to the right of the filter input.</p>
      <p>To clear the filter, either delete all of the characters from the filter input, or press the Clear All Filters button at the top of the page. Note: Clicking Clear All Filters will clear all filters across all columns.</p>
  </div>
</div>
<div id="keepSrp-column-tooltip" class="help">
  <div class="wrapper">
      <h4>Keep Price Column</h4>
      <h5>Sorting:</h5>
      <p>Sort by clicking the column header. The first click will sort the column ascending (<i class="fa fa-arrow-up" aria-hidden="true"></i>), the second click descending (<i class="fa fa-arrow-down" aria-hidden="true"></i>), and the third click will remove the sorting on this column.</p>
      <h5>Filtering:</h5>
      <p>You can use the filter below the column title to filter the list of items by Keep Price.</p>
      <p>Check the box or boxes next to the value to show only items that have Keep Price set to that value.</p>
      <p>Example: Only checking the "true" box will filter the list to only show items with Keep Price set to true. Only checking the "false" box will filter the list to only show items with Keep Price set to false. Checking "true" and "false" will show all items.</p>
      <p>To clear the filter, either select all values, or press the Clear All Filters button at the top of the page. Note: Clicking Clear All Filters will clear all filters across all columns.</p>
  </div>
</div>
<div id="percent-column-tooltip" class="help">
  <div class="wrapper">
      <h4>Percent Column</h4>
      <h5>Sorting:</h5>
      <p>Sort by clicking the column header. The first click will sort the column ascending (<i class="fa fa-arrow-up" aria-hidden="true"></i>), the second click descending (<i class="fa fa-arrow-down" aria-hidden="true"></i>), and the third click will remove the sorting on this column.</p>
      <h5>Filtering:</h5>
      <p>You can use the input below the column title to filter the list of items by Percent.</p>
      <p>Enter an amount to only show items that have a Percent value that equals that amount.</p>
      <p>Example: Typing "30" will filter the list to only show items with a Percent value that equals 30.</p>
      <p>Note: You can change the behavior of the filter to something other than "Equals".  The options available are "Equals", "Not equal", "Less than", "Less than or equals", "Greater than", "Greater than or equals", and "In range".  If you select "In range", then you can enter two values and the list will be filtered by values falling within the range of those two values.  This filter option can be changed by clicking the button to the right of the filter input.</p>
      <p>To clear the filter, either delete all of the characters from the filter input, or press the Clear All Filters button at the top of the page. Note: Clicking Clear All Filters will clear all filters across all columns.</p>
  </div>
</div>
<div id="keepPercent-column-tooltip" class="help">
  <div class="wrapper">
      <h4>Keep Percent Column</h4>
      <h5>Sorting:</h5>
      <p>Sort by clicking the column header. The first click will sort the column ascending (<i class="fa fa-arrow-up" aria-hidden="true"></i>), the second click descending (<i class="fa fa-arrow-down" aria-hidden="true"></i>), and the third click will remove the sorting on this column.</p>
      <h5>Filtering:</h5>
      <p>You can use the filter below the column title to filter the list of items by Keep Percent.</p>
      <p>Check the box or boxes next to the value to show only items that have Keep Percent set to that value.</p>
      <p>Example: Only checking the "true" box will filter the list to only show items with Keep Percent set to true. Only checking the "false" box will filter the list to only show items with Keep Percent set to false. Checking "true" and "false" will show all items.</p>
      <p>To clear the filter, either select all values, or press the Clear All Filters button at the top of the page. Note: Clicking Clear All Filters will clear all filters across all columns.</p>
  </div>
</div>
<div id="setPriceKeepPercent-column-tooltip" class="help">
  <div class="wrapper">
      <h4>Set Price Keep Percent Column</h4>
      <p>This column is used during Cell Editing and Master Input Changes. If the checkbox in this column is checked (cell editing an item) or if the master input drop down menu is set to True (master inputs) and you commit your changes, then the price will be set using the price you entered. The percent will be calculated using your set price and then "Keep Percent" will be set going forward using the calcuated percent.</p>
  </div>
</div>
<div id="deal-column-tooltip" class="help">
  <div class="wrapper">
      <h4>Deal Column</h4>
      <h5>Sorting:</h5>
      <p>Sort by clicking the column header. The first click will sort the column ascending (<i class="fa fa-arrow-up" aria-hidden="true"></i>), the second click descending (<i class="fa fa-arrow-down" aria-hidden="true"></i>), and the third click will remove the sorting on this column.</p>
      <h5>Filtering:</h5>
      <p>You can use the input below the column title to filter the list of items by Deal Amount.</p>
      <p>Enter an amount to only show items that have a Deal with that amount.</p>
      <p>Example: Typing "1.23" will filter the list to only show items with a Deal Amount that equals $1.23</p>
      <p>Note: You can change the behavior of the filter to something other than "Equals".  The options available are "Equals", "Not equal", "Less than", "Less than or equals", "Greater than", "Greater than or equals", and "In range".  If you select "In range", then you can enter two values and the list will be filtered by values falling within the range of those two values.  This filter option can be changed by clicking the button to the right of the filter input.</p>
      <p>To clear the filter, either delete all of the characters from the filter input, or press the Clear All Filters button at the top of the page. Note: Clicking Clear All Filters will clear all filters across all columns.</p>
  </div>
</div>
<div id="dealDate-column-tooltip" class="help">
  <div class="wrapper">
      <h4>Deal Date</h4>
      <h5>Sorting:</h5>
      <p>Sort by clicking the column header. The first click will sort the column ascending (<i class="fa fa-arrow-up" aria-hidden="true"></i>), the second click descending (<i class="fa fa-arrow-down" aria-hidden="true"></i>), and the third click will remove the sorting on this column.</p>
      <h5>Filtering:</h5>
      <p>You can use the input below the column title to filter the list of items by Deal Date.</p>
      <p>Enter an amount to only show items that have a Deal Date with that value by typing a date in mm/dd/yyyy format or by choosing a date from the drop down calendar.</p>
      <p>Example: Entering "12/30/2017" will filter the list to only show items with a Deal Date that equals 12/30/2017.</p>
      <p>Note: You can change the behavior of the filter to something other than "Equals".  The options available are "Equals", "Not equal", "Less than", "Greater than", and "In range".  If you select "In range", then you can enter two values and the list will be filtered by values falling within the range of those two values.  This filter option can be changed by clicking the button to the right of the filter input.</p>
      <p>To clear the filter, either delete all of the characters from the filter input, or press the Clear All Filters button at the top of the page. Note: Clicking Clear All Filters will clear all filters across all columns.</p>
  </div>
</div>
<div id="oldBook-column-tooltip" class="help">
  <div class="wrapper">
      <h4>Old Book Column</h4>
      <h5>Sorting:</h5>
      <p>Sort by clicking the column header. The first click will sort the column ascending (<i class="fa fa-arrow-up" aria-hidden="true"></i>), the second click descending (<i class="fa fa-arrow-down" aria-hidden="true"></i>), and the third click will remove the sorting on this column.</p>
      <h5>Filtering:</h5>
      <p>You can use the filter button below the column title to filter the list of items by Old Book value.</p>
      <p>Select one or more values you want to filter on by checking the box next to each value.</p>
      <p>Example: Checking only "1" will filter the list of items to show only items with an Old Book value of "1".</p>
      <p>To clear the filter, either select all values, or press the Clear All Filters button at the top of the page. Note: Clicking Clear All Filters will clear all filters across all columns.</p>
  </div>
</div>
<div id="oldSRP-column-tooltip" class="help">
  <div class="wrapper">
      <h4>Old SRP Code Column</h4>
      <h5>Sorting:</h5>
      <p>Sort by clicking the column header. The first click will sort the column ascending (<i class="fa fa-arrow-up" aria-hidden="true"></i>), the second click descending (<i class="fa fa-arrow-down" aria-hidden="true"></i>), and the third click will remove the sorting on this column.</p>
      <h5>Filtering:</h5>
      <p>You can use the filter button below the column title to filter the list of items by Old SRP Code.</p>
      <p>Select one or more values you want to filter on by checking the box next to each value.</p>
      <p>Example: Checking only "1" will filter the list of items to show only items with an Old SRP Code value of "1".</p>
      <p>To clear the filter, either select all values, or press the Clear All Filters button at the top of the page. Note: Clicking Clear All Filters will clear all filters across all columns.</p>
  </div>
</div>
<div id="oldMulti-column-tooltip" class="help">
  <div class="wrapper">
      <h4>Old Multi Column</h4>
      <h5>Sorting:</h5>
      <p>Sort by clicking the column header. The first click will sort the column ascending (<i class="fa fa-arrow-up" aria-hidden="true"></i>), the second click descending (<i class="fa fa-arrow-down" aria-hidden="true"></i>), and the third click will remove the sorting on this column.</p>
      <h5>Filtering:</h5>
      <p>You can use the input below the column title to filter the list of items by Old Multi value.</p>
      <p>Enter an amount to only show items that have an Old Multi value that equals that amount.</p>
      <p>Example: Typing "3" will filter the list to only show items with an Old Multi value that equals 3.</p>
      <p>Note: You can change the behavior of the filter to something other than "Equals".  The options available are "Equals", "Not equal", "Less than", "Less than or equals", "Greater than", "Greater than or equals", and "In range".  If you select "In range", then you can enter two values and the list will be filtered by values falling within the range of those two values.  This filter option can be changed by clicking the button to the right of the filter input.</p>
      <p>To clear the filter, either delete all of the characters from the filter input, or press the Clear All Filters button at the top of the page. Note: Clicking Clear All Filters will clear all filters across all columns.</p>
  </div>
</div>
<div id="oldPrice-column-tooltip" class="help">
  <div class="wrapper">
      <h4>Old Price Column</h4>
      <h5>Sorting:</h5>
      <p>Sort by clicking the column header. The first click will sort the column ascending (<i class="fa fa-arrow-up" aria-hidden="true"></i>), the second click descending (<i class="fa fa-arrow-down" aria-hidden="true"></i>), and the third click will remove the sorting on this column.</p>
      <h5>Filtering:</h5>
      <p>You can use the input below the column title to filter the list of items by Old Price value.</p>
      <p>Enter an amount to only show items that have an Old Price with that amount.</p>
      <p>Example: Typing "1.23" will filter the list to only show items with an Old Price that equals $1.23</p>
      <p>Note: You can change the behavior of the filter to something other than "Equals".  The options available are "Equals", "Not equal", "Less than", "Less than or equals", "Greater than", "Greater than or equals", and "In range".  If you select "In range", then you can enter two values and the list will be filtered by values falling within the range of those two values.  This filter option can be changed by clicking the button to the right of the filter input.</p>
      <p>To clear the filter, either delete all of the characters from the filter input, or press the Clear All Filters button at the top of the page. Note: Clicking Clear All Filters will clear all filters across all columns.</p>
  </div>
</div>
<div id="oldKeepPrice-column-tooltip" class="help">
  <div class="wrapper">
      <h4>Old Keep Price Column</h4>
      <h5>Sorting:</h5>
      <p>Sort by clicking the column header. The first click will sort the column ascending (<i class="fa fa-arrow-up" aria-hidden="true"></i>), the second click descending (<i class="fa fa-arrow-down" aria-hidden="true"></i>), and the third click will remove the sorting on this column.</p>
      <h5>Filtering:</h5>
      <p>You can use the filter below the column title to filter the list of items by Old Keep Price value.</p>
      <p>Check the box or boxes next to the value to show only items that have Old Keep Price set to that value.</p>
      <p>Example: Only checking the "true" box will filter the list to only show items with Old Keep Price set to true. Only checking the "false" box will filter the list to only show items with Old Keep Price set to false. Checking "true" and "false" will show all items.</p>
      <p>To clear the filter, either select all values, or press the Clear All Filters button at the top of the page. Note: Clicking Clear All Filters will clear all filters across all columns.</p>
  </div>
</div>
<div id="oldPercent-column-tooltip" class="help">
  <div class="wrapper">
      <h4>Old Percent Column</h4>
      <h5>Sorting:</h5>
      <p>Sort by clicking the column header. The first click will sort the column ascending (<i class="fa fa-arrow-up" aria-hidden="true"></i>), the second click descending (<i class="fa fa-arrow-down" aria-hidden="true"></i>), and the third click will remove the sorting on this column.</p>
      <h5>Filtering:</h5>
      <p>You can use the input below the column title to filter the list of items by Old Percent.</p>
      <p>Enter an amount to only show items that have an Old Percent value that equals that amount.</p>
      <p>Example: Typing "30" will filter the list to only show items with an Old Percent value that equals 30.</p>
      <p>Note: You can change the behavior of the filter to something other than "Equals".  The options available are "Equals", "Not equal", "Less than", "Less than or equals", "Greater than", "Greater than or equals", and "In range".  If you select "In range", then you can enter two values and the list will be filtered by values falling within the range of those two values.  This filter option can be changed by clicking the button to the right of the filter input.</p>
      <p>To clear the filter, either delete all of the characters from the filter input, or press the Clear All Filters button at the top of the page. Note: Clicking Clear All Filters will clear all filters across all columns.</p>
  </div>
</div>
<div id="oldKeepPercent-column-tooltip" class="help">
  <div class="wrapper">
      <h4>Old Keep Percent Column</h4>
      <h5>Sorting:</h5>
      <p>Sort by clicking the column header. The first click will sort the column ascending (<i class="fa fa-arrow-up" aria-hidden="true"></i>), the second click descending (<i class="fa fa-arrow-down" aria-hidden="true"></i>), and the third click will remove the sorting on this column.</p>
      <h5>Filtering:</h5>
      <p>You can use the filter below the column title to filter the list of items by Old Keep Percent value.</p>
      <p>Check the box or boxes next to the value to show only items that have Old Keep Percent set to that value.</p>
      <p>Example: Only checking the "true" box will filter the list to only show items with Old Keep Percent set to true. Only checking the "false" box will filter the list to only show items with Old Keep Percent set to false. Checking "true" and "false" will show all items.</p>
      <p>To clear the filter, either select all values, or press the Clear All Filters button at the top of the page. Note: Clicking Clear All Filters will clear all filters across all columns.</p>
  </div>
</div>
<div id="caseCostLessDeliveryFee-column-tooltip" class="help">
  <div class="wrapper">
      <h4>Case Cost Less Delivery Fee Column</h4>
      <h5>Sorting:</h5>
      <p>Sort by clicking the column header. The first click will sort the column ascending (<i class="fa fa-arrow-up" aria-hidden="true"></i>), the second click descending (<i class="fa fa-arrow-down" aria-hidden="true"></i>), and the third click will remove the sorting on this column.</p>
      <h5>Filtering:</h5>
      <p>You can use the input below the column title to filter the list of items by Case Cost Less Delivery Fee value.</p>
      <p>Enter an amount to only show items that have a Case Cost Less Delivery Fee with that amount.</p>
      <p>Example: Typing "1.23" will filter the list to only show items with a Case Cost Less Delivery Fee that equals $1.23</p>
      <p>Note: You can change the behavior of the filter to something other than "Equals".  The options available are "Equals", "Not equal", "Less than", "Less than or equals", "Greater than", "Greater than or equals", and "In range".  If you select "In range", then you can enter two values and the list will be filtered by values falling within the range of those two values.  This filter option can be changed by clicking the button to the right of the filter input.</p>
      <p>To clear the filter, either delete all of the characters from the filter input, or press the Clear All Filters button at the top of the page. Note: Clicking Clear All Filters will clear all filters across all columns.</p>
  </div>
</div>
<div id="deliveredCaseCost-column-tooltip" class="help">
  <div class="wrapper">
      <h4>Delivered Case Cost Column</h4>
      <h5>Sorting:</h5>
      <p>Sort by clicking the column header. The first click will sort the column ascending (<i class="fa fa-arrow-up" aria-hidden="true"></i>), the second click descending (<i class="fa fa-arrow-down" aria-hidden="true"></i>), and the third click will remove the sorting on this column.</p>
      <h5>Filtering:</h5>
      <p>You can use the input below the column title to filter the list of items by Delivered Case Cost value.</p>
      <p>Enter an amount to only show items that have a Delivered Case Cost with that amount.</p>
      <p>Example: Typing "1.23" will filter the list to only show items with a Delivered Case Cost that equals $1.23</p>
      <p>Note: You can change the behavior of the filter to something other than "Equals".  The options available are "Equals", "Not equal", "Less than", "Less than or equals", "Greater than", "Greater than or equals", and "In range".  If you select "In range", then you can enter two values and the list will be filtered by values falling within the range of those two values.  This filter option can be changed by clicking the button to the right of the filter input.</p>
      <p>To clear the filter, either delete all of the characters from the filter input, or press the Clear All Filters button at the top of the page. Note: Clicking Clear All Filters will clear all filters across all columns.</p>
  </div>
</div>
<div id="oldDeliveredCaseCost-column-tooltip" class="help">
  <div class="wrapper">
      <h4>Old Delivered Case Cost Column</h4>
      <h5>Sorting:</h5>
      <p>Sort by clicking the column header. The first click will sort the column ascending (<i class="fa fa-arrow-up" aria-hidden="true"></i>), the second click descending (<i class="fa fa-arrow-down" aria-hidden="true"></i>), and the third click will remove the sorting on this column.</p>
      <h5>Filtering:</h5>
      <p>You can use the input below the column title to filter the list of items by Old Delivered Case Cost value.</p>
      <p>Enter an amount to only show items that have an Old Delivered Case Cost with that amount.</p>
      <p>Example: Typing "1.23" will filter the list to only show items with an Old Delivered Case Cost that equals $1.23</p>
      <p>Note: You can change the behavior of the filter to something other than "Equals".  The options available are "Equals", "Not equal", "Less than", "Less than or equals", "Greater than", "Greater than or equals", and "In range".  If you select "In range", then you can enter two values and the list will be filtered by values falling within the range of those two values.  This filter option can be changed by clicking the button to the right of the filter input.</p>
      <p>To clear the filter, either delete all of the characters from the filter input, or press the Clear All Filters button at the top of the page. Note: Clicking Clear All Filters will clear all filters across all columns.</p>
  </div>
</div>
<div id="baseCost-column-tooltip" class="help">
  <div class="wrapper">
      <h4>Base Cost Column</h4>
      <h5>Sorting:</h5>
      <p>Sort by clicking the column header. The first click will sort the column ascending (<i class="fa fa-arrow-up" aria-hidden="true"></i>), the second click descending (<i class="fa fa-arrow-down" aria-hidden="true"></i>), and the third click will remove the sorting on this column.</p>
      <h5>Filtering:</h5>
      <p>You can use the input below the column title to filter the list of items by Base Cost value.</p>
      <p>Enter an amount to only show items that have a Base Cost with that amount.</p>
      <p>Example: Typing "1.23" will filter the list to only show items with a Base Cost that equals $1.23</p>
      <p>Note: You can change the behavior of the filter to something other than "Equals".  The options available are "Equals", "Not equal", "Less than", "Less than or equals", "Greater than", "Greater than or equals", and "In range".  If you select "In range", then you can enter two values and the list will be filtered by values falling within the range of those two values.  This filter option can be changed by clicking the button to the right of the filter input.</p>
      <p>To clear the filter, either delete all of the characters from the filter input, or press the Clear All Filters button at the top of the page. Note: Clicking Clear All Filters will clear all filters across all columns.</p>
  </div>
</div>
<div id="oldBaseCost-column-tooltip" class="help">
  <div class="wrapper">
      <h4>Old Base Cost Column</h4>
      <h5>Sorting:</h5>
      <p>Sort by clicking the column header. The first click will sort the column ascending (<i class="fa fa-arrow-up" aria-hidden="true"></i>), the second click descending (<i class="fa fa-arrow-down" aria-hidden="true"></i>), and the third click will remove the sorting on this column.</p>
      <h5>Filtering:</h5>
      <p>You can use the input below the column title to filter the list of items by Old Base Cost value.</p>
      <p>Enter an amount to only show items that have an Old Base Cost with that amount.</p>
      <p>Example: Typing "1.23" will filter the list to only show items with an Old Base Cost that equals $1.23</p>
      <p>Note: You can change the behavior of the filter to something other than "Equals".  The options available are "Equals", "Not equal", "Less than", "Less than or equals", "Greater than", "Greater than or equals", and "In range".  If you select "In range", then you can enter two values and the list will be filtered by values falling within the range of those two values.  This filter option can be changed by clicking the button to the right of the filter input.</p>
      <p>To clear the filter, either delete all of the characters from the filter input, or press the Clear All Filters button at the top of the page. Note: Clicking Clear All Filters will clear all filters across all columns.</p>
  </div>
</div>
<div id="edited-column-tooltip" class="help">
  <div class="wrapper">
      <h4>Edited Column</h4>
      <h5>Sorting:</h5>
      <p>Sort by clicking the column header. The first click will sort the column ascending (<i class="fa fa-arrow-up" aria-hidden="true"></i>), the second click descending (<i class="fa fa-arrow-down" aria-hidden="true"></i>), and the third click will remove the sorting on this column.</p>
      <h5>Filtering:</h5>
      <p>You can use the filter below the column title to filter the list of items to only show items where the Book, SRP Code, Multi, Price or Percent has been edited.</p>
      <p>Example: Only checking the "true" box will filter the list to only show items that have been edited. Only checking the "false" box will filter the list to only show items that have not been edited. Checking "true" and "false" will show all items.</p>
      <p>To clear the filter, either select all values, or press the Clear All Filters button at the top of the page. Note: Clicking Clear All Filters will clear all filters across all columns.</p>
  </div>
</div>
<div id="itemLists-column-tooltip" class="help">
  <div class="wrapper">
      <h4>Item Lists Column</h4>
      <p>Item Lists are arbitrary lists of items created by a user.  These lists' names will appear in this column for items that are a part of each respective list. </p>
      <p>Example: A user could create a list called "This Week's Ad Items" and then add those desired items to the list.</p>
      <h5>Adding or Removing Items:</h5>
      <p>To add or remove an item, double-click the Item Lists cell in that item's row.  In the editor that appears, you can check or uncheck the boxes next to the Item List names to add or remove the item from that Item List.  You can also create a new Item List by using the "Create New Item List" input and the "Create" button.</p>
      <h5>Sorting:</h5>
      <p>Sort by clicking the column header. The first click will sort the column ascending (<i class="fa fa-arrow-up" aria-hidden="true"></i>), the second click descending (<i class="fa fa-arrow-down" aria-hidden="true"></i>), and the third click will remove the sorting on this column.</p>
      <h5>Filtering:</h5>
      <p>To show only items from a specific list, you will use the Item List Tab at the top of the grid and choose your Item List from the drop-down menu.</p>
  </div>
</div>


