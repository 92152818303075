import { Component, OnInit, Inject, LOCALE_ID, Input } from '@angular/core';
import { Validators, UntypedFormBuilder } from '@angular/forms';
import { ItemListService } from 'src/app/shared/item-list.service';
import { MessageService } from 'src/app/shared/message.service';
import { ItemListDetailDialog } from '../item-list-editor.component';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { AccessService } from 'src/app/shared/access.service';
import { PermissionsService } from 'src/app/shared/permissions.service';
import { AuthenticationService } from 'src/app/core/authentication/shared/authentication.service';

@Component({
  selector: 'rpms-item-list-detail',
  templateUrl: './item-list-detail.component.html',
  styleUrls: ['./item-list-detail.component.css']
})
export class ItemListDetailComponent implements OnInit {
  @Input() id;
  
  itemList;
  mode: string = '';
  modifiedByOptions = ['Everyone', 'AllUsersManagedByMe', 'NoOne'];
  sharedWithOptions = ['Everyone', 'AllUsersManagedByMe', 'NoOne'];
  isItemListModifiable: boolean = false;
  canItemListSharedWithAndOrModifiedByPropertiesBeChanged: boolean = false;
  permissions: any = {};
  myUser;

  itemListDetailForm = this._fb.group({
    name: ['', Validators.required],
    description: [''],
    creator: [''],
    sharedWith: ['', Validators.required],
    modifiedBy: ['', Validators.required],
    itemCodes: [''],
  });

  constructor(private _fb: UntypedFormBuilder,
    private _permissionsService: PermissionsService,
    private _authenticationService: AuthenticationService,
    private _accessService: AccessService,
    private _itemListService: ItemListService,
    private _messageService: MessageService,
    public dialogRef: MatDialogRef<ItemListDetailDialog>,
    private _dialog: MatDialog, 
    @Inject(LOCALE_ID) private locale: string
    ) { }

  ngOnInit(){
    this.permissions = this._permissionsService.getPermissions();
    this.myUser = this._authenticationService.getMyUser();
    if (this.permissions.roleAllowsUserToEditItemList) this.mode = 'Edit';
    if (this.id === 0){
      this.mode = 'Create';
    }
    else{
      this.getIsItemListModifiable();    
    }
  }

  getIsItemListModifiable(){
    this._accessService.isItemListModifiable(this.id)
      .subscribe(
        (data:any) => {
          this.isItemListModifiable = data.value;
          if (!this.isItemListModifiable || this.myUser.hasReadOnlyRestriction){
            this.itemListDetailForm.get('name').disable();
            this.itemListDetailForm.get('description').disable();
            this.itemListDetailForm.get('sharedWith').disable();
            this.itemListDetailForm.get('modifiedBy').disable();
          }
          //these 2 should always be disabled
          this.itemListDetailForm.get('creator').disable();
          this.itemListDetailForm.get('itemCodes').disable();
          this.getCanItemListSharedWithAndOrModifiedByPropertiesBeChanged();
        },
        (response) => {
          this._messageService.onFailure('Failed to determine if item list is modifiable.', response);
        }
      )
  }

  getCanItemListSharedWithAndOrModifiedByPropertiesBeChanged(){
    this._accessService.canItemListSharedWithAndOrModifiedByPropertiesBeChanged(this.id)
      .subscribe(
        (data:any) => {
          this.canItemListSharedWithAndOrModifiedByPropertiesBeChanged = data.value;
          if (!this.canItemListSharedWithAndOrModifiedByPropertiesBeChanged){
            this.itemListDetailForm.get('sharedWith').disable();
            this.itemListDetailForm.get('modifiedBy').disable();
          }
          this.getItemListById();
        },
        (response) => {
          this._messageService.onFailure('Failed to determine if item list\'s Shared With or Modified By properties can be changed.', response);
        }
      )
  }

  getItemListById(){
    this._itemListService.getItemListById(this.id)
    .subscribe(
      (data:any) => {
        this.itemList = data;
        this.itemListDetailForm.patchValue({
          name: data.name,
          description: data.description,
          creator: data.owner.fullNameAndEmail,
          sharedWith: data.sharedWith,
          modifiedBy: data.modifiedBy,
          itemCodes: data.itemCodes,
        });
        this.itemListDetailForm.controls['creator'].disable();
        this.itemListDetailForm.controls['itemCodes'].disable();
      },
      (response) => {
        this._messageService.onFailure('Failed to get Item List.', response);
      })
  }

  onSubmitItemList(){
    //Users with hasReadOnlyRestriction set to true should not even be allowed to invoke this action because it is hidden or disabled in the UI.
    //This is for extra protection. The back end will also be protected.
    if (this.myUser.hasReadOnlyRestriction){
      this._messageService.alertReadOnlyRestriction();
      return;
    }
    
    var itemList = this.itemListDetailForm.getRawValue(); //gets values from disabled fields
    itemList.active = true;
    if (this.mode === 'Create') {
      itemList.itemCodes = [];
      this._itemListService.createItemList(itemList)
      .subscribe(
        () => {
          this.dialogRef.close('refresh');
          this._messageService.onSuccess('Successfully created Item List');
        },
        (response) => {
          this._messageService.onFailure('Failed to create Item List.', response)
        }
      );
    }
    else {
      //console.log(itemList);
      this._itemListService.updateItemList(this.itemList.itemListId, itemList)
      .subscribe(
        () => {
          this.dialogRef.close('refresh');
          this._messageService.onSuccess('Successfully updated Item List');
        },
        (response) => {
          this._messageService.onFailure('Failed to update Item List.', response)
        }
      );
    }
  }

  addSpacesBetweenWords = function (v) {
    if (v != null && v != '') {
      var valueWithSpaces = v.replace(/([A-Z])/g, ' $1');
      valueWithSpaces = valueWithSpaces.substr(1); //remove space before first word
      return valueWithSpaces;
    }
  };

  closeDialog(){
    this.dialogRef.close();
  }

}
