<div class="detail admin-dialog">
<h1 mat-dialog-title>Clone Pos Export Settings</h1>
<div mat-dialog-content>
  <form [formGroup]="clonePosExportSettingsForm" id="clone-pos-export-settings-form" (ngSubmit)="onSubmitClonePosExportSettings()">
    <div class="row">
      <mat-form-field>
        <mat-label>From Store</mat-label>
        <input matInput id="sourceStore" formControlName="sourceStore" placeholder="From Store" readonly>
      </mat-form-field>
    </div>
    <div class="row">
      <mat-form-field>
        <mat-label>To Store</mat-label>
        <mat-select id="targetPosSettingsId" formControlName="targetPosSettingsId" placeholder="To Store">
          <mat-option *ngFor="let setting of allSettingsForCloning" [value]="setting.storePosExportSettingsId">
            {{setting.store.numberAndLocation}}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="clonePosExportSettingsForm.hasError('required', 'targetPosSettingsId')">Target Store is required</mat-error>
      </mat-form-field>
    </div>
    <div class="detail-action-buttons">
      <button mat-raised-button color="primary" [disabled]="!clonePosExportSettingsForm.valid" type="submit">Clone</button>
      <button mat-raised-button (click)="closeDialog()" type="button">Cancel</button>
    </div>
  </form>
</div>
</div>