import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdminComponent } from './admin/admin.component';
import { AdminHomeComponent } from './admin-home/admin-home.component';
import { AdminUserListComponent } from './admin-users/admin-user-list/admin-user-list.component';
import { AdminUserDetailComponent } from './admin-users/admin-user-detail/admin-user-detail.component';
import { AdminApplicationComponent } from './admin-application/admin-application.component';
import { AdminStoreListComponent } from './admin-stores/admin-store-list/admin-store-list.component';
import { AdminScheduleComponent } from './admin-schedule/admin-schedule.component';
import { AdminItemFilterListComponent } from './admin-item-filters/admin-item-filter-list/admin-item-filter-list.component';
import { AdminItemListListComponent } from './admin-item-lists/admin-item-list-list/admin-item-list-list.component';
import { AdminTprTagListComponent } from './admin-tag-orders/admin-tpr-tag-list/admin-tpr-tag-list.component';
import { AdminPosExportListComponent } from './admin-pos-export/admin-pos-export-list/admin-pos-export-list.component';
import { AdminShelfTagListComponent } from './admin-tag-orders/admin-shelf-tag-list/admin-shelf-tag-list.component';
import { AdminStoreGroupListComponent } from './admin-store-groups/admin-store-group-list/admin-store-group-list.component';
import { AdminPosExportDetailComponent } from './admin-pos-export/admin-pos-export-detail/admin-pos-export-detail.component';
import { PosExportDepartmentAssociationsComponent } from './admin-pos-export/pos-export-department-associations/pos-export-department-associations.component';
import { AdminPrivateLabelsListComponent } from './admin-private-labels/admin-private-labels-list/admin-private-labels-list.component';
import { AdminPricingRulesListComponent } from './admin-pricing-rules/admin-pricing-rules-list/admin-pricing-rules-list.component';
import { AdminPricingRulesDetailComponent } from './admin-pricing-rules/admin-pricing-rules-detail/admin-pricing-rules-detail.component';
import { PrioritizePricingRulesComponent } from './admin-pricing-rules/prioritize-pricing-rules/prioritize-pricing-rules.component';
import { AuthGuard } from '../core/authentication/shared/auth.guard';
import { AdminAnnouncementsListComponent } from './admin-announcements/admin-announcements-list/admin-announcements-list.component';

const routes: Routes = [
  {
    path: 'admin',
    canActivate:[AuthGuard],
    component: AdminComponent,
    data: { title: 'Admin' },
    children: [
      {
        path: '',
        canActivate:[AuthGuard],
        component: AdminHomeComponent,
        data: { title: 'Admin Home' }
      },
      {
        path: 'users',
        canActivate:[AuthGuard],
        component: AdminUserListComponent,
        data: { title: 'Users' }
      },
      {
        path: 'users/:id',
        canActivate:[AuthGuard],
        component: AdminUserDetailComponent,
        data: { title: 'Users Detail' }
      },
      {
        path: 'stores',
        canActivate:[AuthGuard],
        component: AdminStoreListComponent,
        data: { title: 'Stores' }
        // children: [
        //   {
        //     path: ':id',
        //     component: AdminUserDetailComponent,
        //     //canDeactivate: [CanDeactivateGuard],
        //     // resolve: {
        //     //   crisis: CrisisDetailResolverService
        //     // }
        //   },
        //   {
        //     path: '',
        //     component: AdminUserListComponent
        //   }
        // ]
      },
      {
        path: 'store-groups',
        canActivate:[AuthGuard],
        component: AdminStoreGroupListComponent,
        data: { title: 'Store Groups' }
      },
      {
        path: 'schedule',
        canActivate:[AuthGuard],
        component: AdminScheduleComponent,
        data: { title: 'Schedule' }
      },
      {
        path: 'item-filters',
        canActivate:[AuthGuard],
        component: AdminItemFilterListComponent,
        data: { title: 'Item Filters' }
      },
      {
        path: 'item-lists',
        canActivate:[AuthGuard],
        component: AdminItemListListComponent,
        data: { title: 'Item Lists' }
      },
      {
        path: 'shelf-tag-orders',
        canActivate:[AuthGuard],
        component: AdminShelfTagListComponent,
        data: { title: 'Shelf Tag Orders' }
      },
      {
        path: 'tpr-tag-orders',
        canActivate:[AuthGuard],
        component: AdminTprTagListComponent,
        data: { title: 'TPR Tag Orders' }
      },
      {
        path: 'pos-export',
        canActivate:[AuthGuard],
        component: AdminPosExportListComponent,
        data: { title: 'POS Export' }
      },
      {
        path: 'private-labels',
        canActivate:[AuthGuard],
        component: AdminPrivateLabelsListComponent,
        data: { title: 'Private Labels' }
      },
      {
        path: 'pricing-rules',
        canActivate:[AuthGuard],
        component: AdminPricingRulesListComponent,
        data: { title: 'Pricing Rules' }
      },
      {
        path: 'prioritize-pricing-rules',
        canActivate:[AuthGuard],
        component: PrioritizePricingRulesComponent,
        data: { title: 'Prioritize Pricing Rules' }
      },
      {
        path: 'pricing-rules/:pricingRuleId',
        canActivate:[AuthGuard],
        component: AdminPricingRulesDetailComponent,
        data: { title: 'Pricing Rules Detail' }
      },
      {
        path: 'pos-export',
        canActivate:[AuthGuard],
        component: AdminPosExportListComponent,
        data: { title: 'POS Export' }
      },
      {
        path: 'pos-export/department-associations/:id',
        canActivate:[AuthGuard],
        component: PosExportDepartmentAssociationsComponent,
        data: { title: 'POS Export Department Associations' }
      },
      {
        path: 'announcements',
        canActivate:[AuthGuard],
        component: AdminAnnouncementsListComponent,
        data: { title: 'Announcements' }
      },
      {
        path: 'application',
        canActivate:[AuthGuard],
        component: AdminApplicationComponent,
        data: { title: 'Application' }
      },

    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AdminRoutingModule { }
