import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AppConfig } from 'src/app/app.config';
import { PermissionsService } from 'src/app/shared/permissions.service';
//import { environment } from 'src/environments/environment';

@Component({
  selector: 'rpms-book-editor',
  templateUrl: './book-editor.component.html',
  styleUrls: ['./book-editor.component.css']
})
export class BookEditorComponent implements OnInit {
  @Input() rowNodeToUpdate: any;
  @Input() changeType: string;
  @Input() isGridReadOnly: boolean;
  @Input() isItemReadOnly: boolean;
  @Output() updatedRowNodeEvent = new EventEmitter<any>();
  @Output() isBookEditorVisibleEvent = new EventEmitter<boolean>();
  private config = AppConfig.settings;

  rowData: any = {};
  bookData: any = [];
  permissions: any = {};
  doesSrpCodeHaveValue: boolean = false;

  constructor(
    private _permissionsService: PermissionsService
  ) { }

  ngOnInit() {
    if (this.config.logInits) console.log('book editor init');
    this.permissions = this._permissionsService.getPermissions();
    this.rowData = this.rowNodeToUpdate.data;
    this.populateBookPricingGrid();
  }

  ngAfterContentInit(){
    setTimeout(() => { this.highlightCurrentValue() }, 100);
  }

  populateBookPricingGrid() {
    var data = this.rowData;
    //need to structure data so that the view has empty values to render td's
    var rowAData =  [{},{},{},{},{},{},{}];
    var rowBData =  [{},{},{},{},{},{},{}];
    var rowCData =  [{},{},{},{},{},{},{}];
    var rowHData =  [{},{},{},{},{},{},{}];
    var otherRowsArray = [
                    [{},{},{},{},{},{},{}],
                    [{},{},{},{},{},{},{}],
                    [{},{},{},{},{},{},{}],
                    [{},{},{},{},{},{},{}],
                    [{},{},{},{},{},{},{}],
                    [{},{},{},{},{},{},{}],
                    [{},{},{},{},{},{},{}],
                    [{},{},{},{},{},{},{}],
                    [{},{},{},{},{},{},{}],
                  ];

    if (data == null) return;

    // row C
    rowCData[0] = { price: data.multi1C > 1 ? data.multi1C + "/" + data.current1.toFixed(2) : data.current1.toFixed(2), percent: data.percent1C + "%" };
    rowCData[1] = { price: data.multi2C > 1 ? data.multi2C + "/" + data.current2.toFixed(2) : data.current2.toFixed(2), percent: data.percent2C + "%" };
    rowCData[2] = { price: data.multi3C > 1 ? data.multi3C + "/" + data.current3.toFixed(2) : data.current3.toFixed(2), percent: data.percent3C + "%" };
    rowCData[3] = { price: data.multi4C > 1 ? data.multi4C + "/" + data.current4.toFixed(2) : data.current4.toFixed(2), percent: data.percent4C + "%" };
    rowCData[4] = { price: data.multi5C > 1 ? data.multi5C + "/" + data.current5.toFixed(2) : data.current5.toFixed(2), percent: data.percent5C + "%" };
    rowCData[5] = { price: data.multi6C > 1 ? data.multi6C + "/" + data.current6.toFixed(2) : data.current6.toFixed(2), percent: data.percent6C + "%" };
    rowCData[6] = { price: data.multi7C > 1 ? data.multi7C + "/" + data.current7.toFixed(2) : data.current7.toFixed(2), percent: data.percent7C + "%" };

    // from RPMS 1.0
    // $('#book-pricing-table #row-C #book-1').html(data.multi1C > 1 ? (data.multi1C + "/" + data.current1 + "<span></span>" + data.percent1C + "%") : (data.current1 + "<span></span>" + data.percent1C + "%"));
    // $('#book-pricing-table #row-C #book-2').html(data.multi2C > 1 ? (data.multi2C + "/" + data.current2 + "<span></span>" + data.percent2C + "%") : (data.current2 + "<span></span>" + data.percent2C + "%"));
    // $('#book-pricing-table #row-C #book-3').html(data.multi3C > 1 ? (data.multi3C + "/" + data.current3 + "<span></span>" + data.percent3C + "%") : (data.current3 + "<span></span>" + data.percent3C + "%"));
    // $('#book-pricing-table #row-C #book-4').html(data.multi4C > 1 ? (data.multi4C + "/" + data.current4 + "<span></span>" + data.percent4C + "%") : (data.current4 + "<span></span>" + data.percent4C + "%"));
    // $('#book-pricing-table #row-C #book-5').html(data.multi5C > 1 ? (data.multi5C + "/" + data.current5 + "<span></span>" + data.percent5C + "%") : (data.current5 + "<span></span>" + data.percent5C + "%"));
    // $('#book-pricing-table #row-C #book-6').html(data.multi6C > 1 ? (data.multi6C + "/" + data.current6 + "<span></span>" + data.percent6C + "%") : (data.current6 + "<span></span>" + data.percent6C + "%"));
    // $('#book-pricing-table #row-C #book-7').html(data.multi7C > 1 ? (data.multi7C + "/" + data.current7 + "<span></span>" + data.percent7C + "%") : (data.current7 + "<span></span>" + data.percent7C + "%"));

    if (data.base1 > 0) {
      //row B
      rowBData[0] = {price: data.multi1 > 1 ? data.multi1 + "/" + data.base1.toFixed(2) : data.base1.toFixed(2), percent: Math.trunc((((data.base1 - data.deliveredUnitCost) / data.base1)) * 100.0) + "%"}
      rowBData[1] = {price: data.multi2 > 1 ? data.multi2 + "/" + data.base2.toFixed(2) : data.base2.toFixed(2), percent: Math.trunc((((data.base2 - data.deliveredUnitCost) / data.base2)) * 100.0) + "%"}
      rowBData[2] = {price: data.multi3 > 1 ? data.multi3 + "/" + data.base3.toFixed(2) : data.base3.toFixed(2), percent: Math.trunc((((data.base3 - data.deliveredUnitCost) / data.base3)) * 100.0) + "%"}
      rowBData[3] = {price: data.multi4 > 1 ? data.multi4 + "/" + data.base4.toFixed(2) : data.base4.toFixed(2), percent: Math.trunc((((data.base4 - data.deliveredUnitCost) / data.base4)) * 100.0) + "%"}
      rowBData[4] = {price: data.multi5 > 1 ? data.multi5 + "/" + data.base5.toFixed(2) : data.base5.toFixed(2), percent: Math.trunc((((data.base5 - data.deliveredUnitCost) / data.base5)) * 100.0) + "%"}
      rowBData[5] = {price: data.multi6 > 1 ? data.multi6 + "/" + data.base6.toFixed(2) : data.base6.toFixed(2), percent: Math.trunc((((data.base6 - data.deliveredUnitCost) / data.base6)) * 100.0) + "%"}
      rowBData[6] = {price: data.multi7 > 1 ? data.multi7 + "/" + data.base7.toFixed(2) : data.base7.toFixed(2), percent: Math.trunc((((data.base7 - data.deliveredUnitCost) / data.base7)) * 100.0) + "%"}
      
      // from RPMS 1.0
      //   $('#book-pricing-table #row-B #book-1').html(data.multi1 > 1 ? (data.multi1 + "/" + data.base1 + "<span></span>" + Math.trunc((((data.base1 - data.unitCost) / data.base1).toFixed(2)) * 100.0) + "%") : (data.base1 + "<span></span>" + Math.trunc((((data.base1 - data.unitCost) / data.base1).toFixed(2)) * 100.0) + "%"));
      //   $('#book-pricing-table #row-B #book-2').html(data.multi2 > 1 ? (data.multi2 + "/" + data.base2 + "<span></span>" + Math.trunc((((data.base2 - data.unitCost) / data.base2).toFixed(2)) * 100.0) + "%") : (data.base2 + "<span></span>" + Math.trunc((((data.base2 - data.unitCost) / data.base2).toFixed(2)) * 100.0) + "%"));
      //   $('#book-pricing-table #row-B #book-3').html(data.multi3 > 1 ? (data.multi3 + "/" + data.base3 + "<span></span>" + Math.trunc((((data.base3 - data.unitCost) / data.base3).toFixed(2)) * 100.0) + "%") : (data.base3 + "<span></span>" + Math.trunc((((data.base3 - data.unitCost) / data.base3).toFixed(2)) * 100.0) + "%"));
      //   $('#book-pricing-table #row-B #book-4').html(data.multi4 > 1 ? (data.multi4 + "/" + data.base4 + "<span></span>" + Math.trunc((((data.base4 - data.unitCost) / data.base4).toFixed(2)) * 100.0) + "%") : (data.base4 + "<span></span>" + Math.trunc((((data.base4 - data.unitCost) / data.base4).toFixed(2)) * 100.0) + "%"));
      //   $('#book-pricing-table #row-B #book-5').html(data.multi5 > 1 ? (data.multi5 + "/" + data.base5 + "<span></span>" + Math.trunc((((data.base5 - data.unitCost) / data.base5).toFixed(2)) * 100.0) + "%") : (data.base5 + "<span></span>" + Math.trunc((((data.base5 - data.unitCost) / data.base5).toFixed(2)) * 100.0) + "%"));
      //   $('#book-pricing-table #row-B #book-6').html(data.multi6 > 1 ? (data.multi6 + "/" + data.base6 + "<span></span>" + Math.trunc((((data.base6 - data.unitCost) / data.base6).toFixed(2)) * 100.0) + "%") : (data.base6 + "<span></span>" + Math.trunc((((data.base6 - data.unitCost) / data.base6).toFixed(2)) * 100.0) + "%"));
      //   $('#book-pricing-table #row-B #book-7').html(data.multi7 > 1 ? (data.multi7 + "/" + data.base7 + "<span></span>" + Math.trunc((((data.base7 - data.unitCost) / data.base7).toFixed(2)) * 100.0) + "%") : (data.base7 + "<span></span>" + Math.trunc((((data.base7 - data.unitCost) / data.base7).toFixed(2)) * 100.0) + "%"));

      var packCount = Number(data.size.trim().substr(0, data.size.trim().indexOf('/')));
      var dealTemp = (!isNaN(data.deal) ? data.deal / packCount : 0);
      var book, srpCode;

      for (book = 1; book <= 7; book++) {

        var multi = eval('data.multi' + book);
        var base = eval('data.base' + book);

        // row A
        var newAmountPassed = (multi < 2 ? dealTemp : dealTemp * multi);
        var newPrice = Math.round(((base - newAmountPassed) + 0.0055) * 100) / 100;
        var percentTemp = Math.floor(((multi < 2 ? (((newPrice * packCount) - data.deliveredCaseCost) / (newPrice * packCount) + 0.0055) : ((((newPrice / multi) * packCount) - data.deliveredCaseCost) / ((newPrice / multi) * packCount) + 0.0055)) * 100.0));

        //$('#book-pricing-table #row-A #book-' + book).html(multi > 1 ? (multi + "/" + newPrice + "<span></span>" + percentTemp + "%") : (newPrice + "<span></span>" + percentTemp + "%"));
        rowAData[book-1] = {price: multi > 1 ? multi + "/" + newPrice.toFixed(2) : newPrice.toFixed(2), percent: percentTemp + "%" };
      
        var otherRowData = [];
        // rows 20 - 90
        for (srpCode = 1; srpCode <= 9; srpCode++) {

          newAmountPassed = (multi < 2 ? dealTemp * (srpCode / 10) : dealTemp * (srpCode / 10) * multi);
          newPrice = Math.round(((base - newAmountPassed) + 0.0055) * 100) / 100;
          percentTemp = Math.floor(((multi < 2 ? (((newPrice * packCount) - data.deliveredCaseCost) / (newPrice * packCount) + 0.0055) : ((((newPrice / multi) * packCount) - data.deliveredCaseCost) / ((newPrice / multi) * packCount) + 0.0055)) * 100.0));
        
          otherRowsArray[srpCode - 1][book - 1] = { price: multi > 1 ? multi + "/" + newPrice.toFixed(2) : newPrice.toFixed(2), percent: percentTemp + "%" };
          //$('#book-pricing-table #row-' + srpCode + ' #book-' + book).html(multi > 1 ? (multi + "/" + newPrice + "<span></span>" + percentTemp + "%") : (newPrice + "<span></span>" + percentTemp + "%"));
        }
        rowHData = otherRowsArray[4].slice(); //clone 50% array for H (half)
      }
    }
    this.bookData.push(rowAData);
    this.bookData.push(rowBData);
    this.bookData.push(rowCData);
    this.bookData.push(rowHData);
    for (var i=0; i<otherRowsArray.length; i++) {
      this.bookData.push(otherRowsArray[i]);
    }
  }

  highlightCurrentValue(){
    if (this.rowData.srpCode && this.rowData.srpCode != null && this.rowData.srpCode != ''){
      this.doesSrpCodeHaveValue = true;
      var rowId = 'row-' + this.rowData.srpCode;
      var row = document.getElementById(rowId);
      var element = row.children.item(this.rowData.book);
      element.classList.add('selected');
     }
  }

  onCellClick(srpCode, book){
    if (!this.isGridReadOnly && !this.isItemReadOnly) this.sendUpdatedRowNodeAndChangeType(srpCode, book);
  }

  onDeleteClick(){
    this.sendUpdatedRowNodeAndChangeType('', 'D');
  }

  sendUpdatedRowNodeAndChangeType(srpCode, book) {
    this.isBookEditorVisibleEvent.next(false);
    var newValues = {
      book: String(book),
      srpCode: String(srpCode),
      }
    this.updatedRowNodeEvent.next({ rowNode: this.rowNodeToUpdate, newValues: newValues, changeType: 'book' });
  }

  close(){
    this.isBookEditorVisibleEvent.next(false);
  }


}
