<div id="controlled-store-pricing-wrapper" class="editor-wrapper" cdkDrag>
  <div class="editor-title" cdkDragHandle>
    Controlled Store Pricing
    <span class="close" (click)="close()"><i class="fa fa-times"></i></span>
  </div>
  <div class="editor-item-title">
    Item Code: {{rowData ? rowData.itemCode : ''}} - {{rowData ? rowData.description : ''}}
  </div>
  <div class="editor-content">
    <div class="scroll-wrapper">
      <div class="scroll-content">
        <table *ngIf="pricingData && pricingData.length > 0 && pricingData[0] !== undefined">
          <tr class="table-header">
            <td class="cs-store">Store</td>
            <td class="cs-book">Bk</td>
            <td class="cs-code">Deal Code</td>
            <td class="cs-multi">Multi</td>
            <td class="cs-price">Price</td>
            <td class="cs-keepPrice">Keep$</td>
            <td class="cs-percent">%</td>
            <td class="cs-keepPercent">Keep%</td>
          </tr>
          <tr *ngFor="let row of pricingData; let i = index" [ngClass]="{'controlling-store-row': i == 0, 'selected-store-row': row && row.store && row.store.storeId == selectedStore.storeId}">
            <td class="cs-store">{{row && row.store ? row.store.storeNumber : ''}} <span *ngIf="row && row.store && row.store.isControllingStore"><i class="far fa-crown"></i></span></td>
            <td class="tac">{{row ? row.book : ''}}</td>
            <td class="tac">{{row ? row.srpCode : ''}}</td>
            <td class="tac">{{row ? row.multi : ''}}</td>
            <td class="tar">{{row ? (row.price | currency) : ''}}</td>
            <td class="tac"><i *ngIf="row && row.keepSrp" class="fa fa-check" aria-hidden="true"></i></td>
            <td class="tac">{{row ? row.percent : ''}}</td>
            <td class="tac"><i *ngIf="row && row.keepPercent" class="fa fa-check" aria-hidden="true"></i></td>
          </tr>
        </table>
        <div *ngIf="!pricingData || pricingData.length === 0 || pricingData[0] === undefined" style="margin:20px;">
          This item is not priced in the controlling store.
        </div>
      </div>
    </div>
  </div>
</div>
