<div id="home-content" class="content">
  <div id="home-page-header">
    <img id="pig-logo-home" src="/assets/images/pig-logo-home.png" />
    <img id="text-logo-home" src="/assets/images/piggly-wiggly-text-logo.png" />
    <span>Retail Price Management System</span>
  </div>
  <div id="home-tiles" class="cf">
    <div class="tile-wrapper">
      <mat-card appearance="outlined" (click)="goToPriceManagement('[Grocery Preview]','Preview','GroceryPreviewItems')" class="price-card">
        <i class="far fa-usd-circle"></i>
        <div class="card-content-wrapper">
          <h2>Preview Price Management</h2>
          <mat-card-content>
            <div *ngIf="previewPricingWindowOpen">
              <div class="window-open">WINDOW OPEN</div>
              <p>Window Closes In:</p>
            </div>
            <div *ngIf="!previewPricingWindowOpen">
              <div class="window-closed">WINDOW CLOSED</div>
              <p>Window Opens In:</p>
            </div>
            <span class="countdown">{{previewWindowCountdownTimer}}</span>
          </mat-card-content>
        </div>
        <mat-card-footer class="right-justified">
          <button mat-raised-button color="primary">Go</button>
        </mat-card-footer>
      </mat-card>
    </div>
    <div class="tile-wrapper">
      <mat-card appearance="outlined" (click)="goToPriceManagement('','AllItems','AllItems')" class="price-card">
          <i class="far fa-usd-circle"></i>
          <div class="card-content-wrapper">
            <h2>Store Price Management</h2>
            <mat-card-content>
              <div *ngIf="storeItemPricingWindowOpen">
                <div class="window-open">WINDOW OPEN</div>
                <p>Window Closes In:</p>
              </div>
              <div *ngIf="!storeItemPricingWindowOpen">
                <div class="window-closed">WINDOW CLOSED</div>
                <p>Window Opens In:</p>
              </div>
              <span class="countdown">{{storeItemPricingWindowCountdownTimer}}</span>
            </mat-card-content>
          </div>
          <mat-card-footer class="right-justified">
            <button mat-raised-button color="primary">Go</button>
          </mat-card-footer>
        </mat-card>
    </div>
    <div class="tile-wrapper">
      <mat-card appearance="outlined" (click)="goToPriceManagement('[First Time Buys or NOWs]','AllItems','AllItems')" class="price-card">
        <i class="far fa-usd-circle"></i>
        <div class="card-content-wrapper">
          <h2>First Time Buys &amp; Now Cost Changes</h2>
          <mat-card-content>
            <p>There are <span [innerHtml]="firstTimeBuysCount"></span> First Time Buys and <span [innerHtml]="nowCostChangesCount"></span> Now Cost Changes as of {{now | date:'short'}}.</p>
          </mat-card-content>
        </div>
        <mat-card-footer class="right-justified">
          <button mat-raised-button color="primary">Go</button>
        </mat-card-footer>
      </mat-card>
    </div>
    <div class="tile-wrapper" *ngIf="permissions.roleAllowsUserToExportCashRegisterFile">
      <mat-card appearance="outlined" class="report-card" (click)="onExportClick()">
        <i class="far fa-file-spreadsheet"></i>
        <div class="card-content-wrapper">
          <h2>Export Cash Register File</h2>
          <mat-card-content>
              <p>Export the Grocery cash register file for the latest week.</p>
          </mat-card-content>
        </div>
        <mat-card-footer class="right-justified">
          <button mat-raised-button color="primary">Go</button>
        </mat-card-footer>
      </mat-card>
    </div>
  </div>
  <div *ngFor="let announcement of announcements">
		<div class="announcement">{{announcement.message}}</div>
	</div>
</div>