import { Component, OnInit, Inject, LOCALE_ID, Input, ChangeDetectorRef, NgZone, ViewChild } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { UserService } from 'src/app/shared/user.service';
import { UntypedFormBuilder, Validators, AbstractControl } from '@angular/forms';
import { formatDate } from '@angular/common';
import { StoreGroupService } from 'src/app/shared/store-group.service';
import { StoreService } from 'src/app/shared/store.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MessageService } from 'src/app/shared/message.service';
import { TransferService } from '../../../shared/transfer.service';
import { PricingRulesService } from '../../../shared/pricing-rules.service';
import { ConfirmationDialogComponent } from '../../../shared/confirmation-dialog/confirmation-dialog.component';
import { MatAccordion } from '@angular/material/expansion';
import { AuthenticationService } from 'src/app/core/authentication/shared/authentication.service';
//import { environment } from 'src/environments/environment';
import { AppConfig } from 'src/app/app.config';

@Component({
  selector: 'app-store-group-transfer-dialog',
  templateUrl: './store-group-transfer-dialog.component.html',
  styleUrls: ['./store-group-transfer-dialog.component.css']
})
export class StoreGroupTransferDialogComponent implements OnInit {

  @ViewChild(MatAccordion) accordion: MatAccordion;
  private config = AppConfig.settings;

  confirmationDialogRef: MatDialogRef<ConfirmationDialogComponent>;

  storeGroups = [];
  allStoreGroupIds;

  assetTypes;

  // managedStoreGroups = [];
  // allManagedStoreGroupIds;

  managedStores = [];
  allManagedStoreIds;
  areManagedStoresLoaded: boolean = false;

  pricingRules = [];
  allPricingRuleIds;
  arePricingRulesLoaded: boolean = false;

  superStoreGroupAdmins = [];
  allSuperStoreGroupAdminIds;
  areSuperStoreGroupAdminsLoaded: boolean = false;

  assetUsers = [];
  allAssetUserIds;
  areAssetUsersLoaded: boolean = false;

  homeStores = [];
  homeStoreId;
  areHomeStoresLoaded: boolean = false;
  inheritingStoreGroupStores = [];

  storeGroupId; //passed in for store transfer to only show stores in same group
  entityType;
  entityName;
  donatingEntityId;
  logEntries = [];
  assetCount = 0;

  showTransferButton: boolean = false;
  transferOccurred: boolean = false; //used to reload list page if needed

  showFormControls: boolean = false;
  showInheritingStoreGroupSelect: boolean = false;
  showAssetTypeSelect: boolean = false;
  showManagedStoresSelect: boolean = false;
  showPricingRulesSelect: boolean = false;
  showSuperStoreGroupAdministratorsSelect: boolean = false;
  showAssetUsersSelect: boolean = false;
  showHomeStoreSelect: boolean = false;

  usersPanelOpenState: boolean = false;
  pricingRulesPanelOpenState: boolean = false;
  usersDescription;
  pricingRulesDescription;

  myUser;

  assetTransferForm = this._fb.group({
    storeGroupRadioButton: [''],
    inheritingStoreGroupId: ['', Validators.required],
    assetType: ['', Validators.required],
    managedStoreIds: [''],
    pricingRuleIds: [''],
    superStoreGroupAdministratorIds: [''],
    assetUserIds: [''],
    homeStoreId: [''],
  });

  constructor(private _activatedRoute: ActivatedRoute,
              private _transferService: TransferService,
              private _userService: UserService,
              private _fb: UntypedFormBuilder,
              private _storeGroupService: StoreGroupService,
              private _storeService: StoreService,
              private _pricingRulesService: PricingRulesService,
              private _messageService: MessageService,
              private _authenticationService: AuthenticationService,
              private ngZone: NgZone,
              private _dialog: MatDialog, 
              public dialogRef: MatDialogRef<StoreGroupTransferDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) 
              {
                this.myUser = this._authenticationService.getMyUser();

                this.donatingEntityId = data.donatingEntityId,
                this.entityType = 'Store Group';
                this.entityName = data.entityName;
                this.storeGroups = data.storeGroups == undefined ? [] : data.storeGroups;
                this.storeGroupId = data.donatingEntityId;
                //this.homeStoreId = data.homeStoreId;
                //this.managedStores = data.storesInGroup == undefined ? [] : data.storesInGroup;

                //this.doOnInit();
              }

  ngOnInit() {
    if (this.config.logInits) console.log('transfer dialog init');
    this.doOnInit();
  }

  doOnInit(){
    if (!this.myUser.hasTransferPrivilege || this.myUser.hasReadOnlyRestriction) {
      //lock fields
      this.assetTransferForm.disable();
    }

    this.removeDonatingStoreGroupfromStoreGroups();
    this.getAssetTypes();
    this.getTransferLogs();
    this.getStoresInGroup();
    //this.getAllPricingRulesByStoreGroupIdSansMatchCriteria(this.donatingEntityId);
    //this.getAllManageableUsersByStoreGroup(this.donatingEntityId);
    //this.handleRequiredFields();
  }

  removeDonatingStoreGroupfromStoreGroups() {
    let arr = [];
    for (let i=0; i<this.storeGroups.length; i++) {
      if (this.storeGroups[i].storeGroupId != this.storeGroupId) arr.push(this.storeGroups[i]);
    }
    this.storeGroups = arr;
  }

  getStoresInGroup(){
    this.areManagedStoresLoaded = false;
    this._storeService.getAllStoresByStoreGroupId(this.storeGroupId)
      .subscribe(
        (data: any) => {
          this.managedStores = data;
          this.allManagedStoreIds = data.map(a => a.storeId);
          this.areManagedStoresLoaded = true;
        },
        (response) => {
          this._messageService.onFailure('Failed to get Stores.', response);
        })
  }

  getAssetTypes(){
    this._transferService.getAccessibleTransferableAssetTypesByTransferEntityType(this.entityType)
    .subscribe(
      (data:any) => {
        this.assetTypes = data;
        //since there is only one option
        this.assetTransferForm.get('storeGroupRadioButton').setValue('1');
        this.showFormControls = true;
        this.showInheritingStoreGroupSelect = true;
      },
      (response) => {
        this._messageService.onFailure('Failed to get Asset Types.', response);
      })
  }

  getTransferLogs(){
    this._transferService.getTransferLogsByTransferEntityTypeAndEntityId(this.entityType, this.donatingEntityId)
    .subscribe(
      (data:any) => {
        this.logEntries = data;
        this.logEntries.map(l => l.performedAt = new Date(l.performedAt).toLocaleString());
        this.logEntries.map(l => l.transferDescription = this.formatLogDescription(l.transferDescription));
      },
      (response) => {
        this._messageService.onFailure('Failed to get Transfer Log.', response);
      })
  }

  formatLogDescription(description) {
    if (description) {
      description = description.replace(/\r\n/g, '<br/>')
      .replace(/\u00b9/g, '<span class="description-highlight">')
      .replace(/\u00b2/g, '<span class="description-highlight">')
      .replace(/\u00b3/g, '<span class="description-highlight">')
      .replace(/\u2074/g, '<span class="description-highlight">')
      .replace(/\u2075/g, '<span class="description-highlight">')
      .replace(/\u2076/g, '<span class="description-highlight">')
      .replace(/\u2077/g, '<span class="description-highlight">')
      .replace(/\u2078/g, '<span class="description-highlight">')
      .replace(/\u00b7/g, '</span>');
    }
    return description;
  }

  // getAllDonatingStoresByStoreGroupId(storeGroupId) {
  //   this._storeService.getAllStoresByStoreGroupId(storeGroupId)
  //   .subscribe(
  //     (data:any) => {
  //       this.donatingStoreGroupStores = data;
  //     },
  //     (response) => {
  //       this._messageService.onFailure('Failed to get store from donating store group.', response);
  //     })
  // }

  getAllInheritingStoresByStoreGroupId(storeGroupId) {
    this.areHomeStoresLoaded = false;
    this._storeService.getAllStoresByStoreGroupId(storeGroupId)
    .subscribe(
      (data:any) => {
        this.inheritingStoreGroupStores = data;
        this.homeStores = data;
        this.areHomeStoresLoaded = true;
        // if (this.assetTransferForm.get('assetType').value == 'All') this.homeStores = this.donatingStoreGroupStores.concat(data);
      },
      (response) => {
        this._messageService.onFailure('Failed to get store from inheriting store group.', response);
      })
  }

  getAllPricingRulesByStoreGroupIdSansMatchCriteria(storeGroupId){
    this.arePricingRulesLoaded = false;
    this._pricingRulesService.getAllPricingRulesByStoreGroupIdSansMatchCriteria(storeGroupId)
      .subscribe(
        (data:any) => {
          this.pricingRules = data;
          this.allPricingRuleIds = data.map(a => a.pricingRuleId);
          this.pricingRulesDescription = data && data.length == 1 ? 
            '1 Pricing Rule is owned by this store' : data.length + ' Pricing Rules are owned by this store';
          this.arePricingRulesLoaded = true;
        },
        (response) => {
          this._messageService.onFailure('Failed to get Pricing Rules.', response);
        })
  }

  getAllManageableUsersByStoreGroup(storeId){
    this.areAssetUsersLoaded = false;
    this._userService.getAllManageableUsersByStoreGroup(storeId)
      .subscribe(
        (data:any) => {
          this.assetUsers = data.sort((a, b) => (a.fullName.toLowerCase() > b.fullName.toLowerCase()) ? 1 : -1);
          this.allAssetUserIds = data.map(a => a.userId);
          this.usersDescription = data && data.length == 1 ? 
            '1 User is in this store group' : data.length + ' Users are in this store group';
          this.areAssetUsersLoaded = true;
        },
        (response) => {
          this._messageService.onFailure('Failed to get Users in Store Group.', response);
        })
  }

  getSuperStoreGroupAdministratorsManagingStoreGroup(storeGroupId){
    this.areSuperStoreGroupAdminsLoaded = false;
    this._userService.getSuperStoreGroupAdministratorsManagingStoreGroup(storeGroupId)
      .subscribe(
        (data:any) => {
          this.superStoreGroupAdmins = data.sort((a, b) => (a.fullName.toLowerCase() > b.fullName.toLowerCase()) ? 1 : -1);
          this.allSuperStoreGroupAdminIds = data.map(a => a.userId);
          this.areSuperStoreGroupAdminsLoaded = true;
        },
        (response) => {
          this._messageService.onFailure('Failed to get Super Store Group Admininstrators managing Store Group.', response);
        })
  }

  onUserRadioButtonChange(value){
    // this.hideAllAssetSelects();
    // this.hideAllFirstLevelSelects();
    // this.clearAllSelects();
    // this.showFormControls = true;
    // switch(value) {
    //   case '1':
    //     this.showInheritingStoreGroupSelect = true;
    //     break;
    //   case '2':
    //     this.getAllInheritingStoresByStoreGroupId(this.storeGroupId);
    //     this.showHomeStoreSelect = true;
    //     break;
    //   case '3':
    //     this.showInheritingUserSelect = true;
    //     break;
    //   default:
    //     break;
    // }
  }

  onInheritingStoreGroupChange(storeGroupId){
    this.clearAllAssetSelects();
    this.assetTransferForm.get('assetType').setValue('');
    this.getAllInheritingStoresByStoreGroupId(storeGroupId);
    this.showAssetTypeSelect = true;
  }

  onAssetTypeChange(value) {
    this.hideAllAssetSelects();
    this.clearAllAssetSelects();
    this.unrequireAllAssetSelects();
    switch (value) {
      case 'All':
        this.assetTransferForm.get('homeStoreId').setValidators([Validators.required]);
        this.assetTransferForm.get('homeStoreId').updateValueAndValidity();
        this.homeStores = this.inheritingStoreGroupStores;
        this.showHomeStoreSelect = true;
      break;
      case 'Managed Stores (with Assets)': //store group
        this.assetTransferForm.get('managedStoreIds').setValidators([Validators.required]);
        this.assetTransferForm.get('managedStoreIds').updateValueAndValidity();
        //managed stores are passed in with the store group from the store group list page
        this.showManagedStoresSelect = true;
      break;
      case 'Pricing Rules': //store group, store
        this.assetTransferForm.get('pricingRuleIds').setValidators([Validators.required]);
        this.assetTransferForm.get('pricingRuleIds').updateValueAndValidity();
        if (this.pricingRules.length == 0) { 
          this.getAllPricingRulesByStoreGroupIdSansMatchCriteria(this.donatingEntityId)
        }
        this.showPricingRulesSelect = true;
        break;
      case 'Super Store Group Administrators': //store group
      this.assetTransferForm.get('superStoreGroupAdministratorIds').setValidators([Validators.required]);
      this.assetTransferForm.get('superStoreGroupAdministratorIds').updateValueAndValidity();
      if (this.superStoreGroupAdmins.length == 0) this.getSuperStoreGroupAdministratorsManagingStoreGroup(this.donatingEntityId);
        this.showSuperStoreGroupAdministratorsSelect = true;
      break;
      case 'Users (to new Home Store)': //store group
        this.assetTransferForm.get('assetUserIds').setValidators([Validators.required]);
        this.assetTransferForm.get('assetUserIds').updateValueAndValidity();
        this.assetTransferForm.get('homeStoreId').setValidators([Validators.required]);
        this.assetTransferForm.get('homeStoreId').updateValueAndValidity();
        if (this.assetUsers.length == 0) {
          this.getAllManageableUsersByStoreGroup(this.donatingEntityId);
        }
        this.showAssetUsersSelect = true;
        this.homeStores = this.inheritingStoreGroupStores;
        this.showHomeStoreSelect = true;
      break;
      default:
        this._messageService.alert(value + ' is not a valid asset type.')
      break;
    }
  }

  onTransferClick(){
    if (this.myUser.hasTransferPrivilege) {
        this.confirmationDialogRef = this._dialog.open(ConfirmationDialogComponent, {
        disableClose: false,
        //width: '600px',
        data: {
          title: 'Are you sure?',
          message: 'Have you coordinated these changes with the Retail Department?<br/><br/>Do you want to proceed with this transfer?',
          confirmText: 'Yes, proceed with transfer',
          cancelText: 'No, cancel this action!'
        }
      });
      this.confirmationDialogRef.afterClosed().subscribe(result => {
        if(result) {
          this.doTransfer()
        }
        this.confirmationDialogRef = null;
      });
    }
    else {
      this._messageService.alert('You are not authorized to transfer assets. If you believe you should have that privilege, contact an administrator.');
    }
  }

  doTransfer(){
    //Users with hasReadOnlyRestriction set to true should not even be allowed to invoke this action because it is hidden or disabled in the UI.
    //This is for extra protection. The back end will also be protected.
    if (this.myUser.hasReadOnlyRestriction){
      this._messageService.alertReadOnlyRestriction();
      return;
    }
    
    //if a property is on 'this', it comes from the data passed into this dialog from the list page
    //if a property is on transfer, it comes from the form value
      let transfer = this.assetTransferForm.value;
      switch (transfer.storeGroupRadioButton) {
        case '1': //assets
          transfer.entityType = 'Store Group';
          transfer.donatingEntityId = this.donatingEntityId;
          transfer.inheritingEntityId = transfer.inheritingStoreGroupId;
          switch (transfer.assetType) {
            case 'All':
              transfer.selectedAssetIds = [''];
              break;
            case 'Managed Stores (with Assets)':
              transfer.selectedAssetIds = transfer.managedStoreIds;
              break;
            case 'Pricing Rules':
              transfer.selectedAssetIds = transfer.pricingRuleIds;
              break;
            case 'Super Store Group Administrators':
              transfer.selectedAssetIds = transfer.superStoreGroupAdministratorIds;
              break;
            case 'Users (to new Home Store)':
              transfer.selectedAssetIds = transfer.assetUserIds;
              break;
            default:
              transfer.selectedAssetIds = [];
              break;
          }
        default:
          break;
      }
      transfer.selectedAssetIds = transfer.selectedAssetIds.map(a => String(a));
      if (!transfer.homeStoreId) transfer.homeStoreId = 0;

      //console.log(transfer);
      this._transferService.transferSelectAssetsFromEntityToEntity(
        transfer.donatingEntityId, 
        transfer.inheritingEntityId, 
        transfer.entityType, 
        transfer.assetType, 
        transfer.homeStoreId, 
        transfer.selectedAssetIds)
        .subscribe(
          (data:any) => {
            this._messageService.onSuccess('Successfully transferred selected assets.')
            this.assetTransferForm.reset();
            this.hideAllAssetSelects();
            this.hideAllFirstLevelSelects();
            this.transferOccurred = true;
            //this.doOnInit(); //reload everything
            this.closeDialog();
          },
          (response) => {
            this._messageService.onFailure('Failed to transfer Assets.', response);
          })
  }
  
  unrequireAllAssetSelects() {
    this.assetTransferForm.get('managedStoreIds').setValidators([]);
    this.assetTransferForm.get('managedStoreIds').updateValueAndValidity();
    this.assetTransferForm.get('pricingRuleIds').setValidators([]);
    this.assetTransferForm.get('pricingRuleIds').updateValueAndValidity();
    this.assetTransferForm.get('superStoreGroupAdministratorIds').setValidators([]);
    this.assetTransferForm.get('superStoreGroupAdministratorIds').updateValueAndValidity();
    this.assetTransferForm.get('assetUserIds').setValidators([]);
    this.assetTransferForm.get('assetUserIds').updateValueAndValidity();
    this.assetTransferForm.get('homeStoreId').setValidators([]);
    this.assetTransferForm.get('homeStoreId').updateValueAndValidity();
  }
  
  clearAllSelects(){
    this.assetTransferForm.get('inheritingStoreGroupId').setValue('');
    this.assetTransferForm.get('assetType').setValue('');
    this.assetTransferForm.get('managedStoreIds').setValue('');
    this.assetTransferForm.get('pricingRuleIds').setValue('');
    this.assetTransferForm.get('superStoreGroupAdministratorIds').setValue('');
    this.assetTransferForm.get('assetUserIds').setValue('');
    this.assetTransferForm.get('homeStoreId').setValue('');
  }

  clearAllAssetSelects(){
    this.assetTransferForm.get('managedStoreIds').setValue('');
    this.assetTransferForm.get('pricingRuleIds').setValue('');
    this.assetTransferForm.get('superStoreGroupAdministratorIds').setValue('');
    this.assetTransferForm.get('assetUserIds').setValue('');
    this.assetTransferForm.get('homeStoreId').setValue('');
  }
 
  hideAllAssetSelects(){
    this.showManagedStoresSelect = false;
    this.showPricingRulesSelect = false;
    this.showSuperStoreGroupAdministratorsSelect = false;
    this.showAssetUsersSelect = false;
    this.showHomeStoreSelect = false;
  }

  hideAllFirstLevelSelects(){
    this.showAssetTypeSelect = false;
    //this.showInheritingStoreGroupSelect = false;
  }

  selectAllOptions(sa, formControlName, allIdsName) {
    if (sa._selected) {
      this.assetTransferForm.get(formControlName).setValue(this[allIdsName])
        sa._selected = true;
    }
    if (sa._selected == false) {
      this.assetTransferForm.get(formControlName).setValue([]);
    }
  }

  onAssetCountEvent(val){
    this.assetCount = val;
  }

  onDeletionMadeEvent(bool){
    if (bool) this.doOnInit();
  }

  closeDialog(){
    this.dialogRef.close(this.transferOccurred);
  }

}

