import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { AgGridAngular } from 'ag-grid-angular';
import { GridOptions } from 'ag-grid-community';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from 'src/app/shared/confirmation-dialog/confirmation-dialog.component';
import { GridService } from 'src/app/shared/grid.service';
import { MessageService } from 'src/app/shared/message.service';
import { PermissionsService } from 'src/app/shared/permissions.service';
import { PricingRulesService } from 'src/app/shared/pricing-rules.service';
import { ViewDetailButtonComponent } from 'src/app/shared/view-detail-button/view-detail-button.component';
import { DeleteButtonComponent } from 'src/app/shared/delete-button/delete-button.component';
import { StoreGroupService } from 'src/app/shared/store-group.service';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { AuthenticationService } from 'src/app/core/authentication/shared/authentication.service';

declare const $: any;

@Component({
  selector: 'app-admin-pricing-rules-list',
  templateUrl: './admin-pricing-rules-list.component.html',
  styleUrls: ['./admin-pricing-rules-list.component.css']
})
export class AdminPricingRulesListComponent implements OnInit {
  @ViewChild('agGrid') agGrid: AgGridAngular;

  prioritizePricingRulesRef: MatDialogRef<PrioritizePricingRulesDialog>;
  confirmationDialogRef: MatDialogRef<ConfirmationDialogComponent>;

  gridOptions: GridOptions;
  quickFilterText: string = '';
  context; //required by grid
  //frameworkComponents; //required by grid
  allPrivateLabels;
  permissions: any = {};
  storeGroups;
  storeGroup;
  pricingRules;
  prioritizedPricingRules = [];
  isPrioritizeWindowVisible: boolean = false;
  storeGroupIdToSelect = null;
  myUser;

  allStoreGroupsOption = {
    "storeGroupId": "all",
    "name": "All Store Groups",
    "description": "",
    "stores": [],
  }

  rowData = [];

  getColumnDefs(){
    return [
      {
        headerName: "",
        field: "action",
        width: 70,
        minWidth: 70,
        maxWidth: 90,
        cellClass: "action-col",
        cellRenderer: "ViewDetailButtonComponent",
        pinned: "left",
        sortable: false,
        filter: false,
        suppressHeaderMenuButton: true,
        suppressNavigable: true,
      },
			{
				headerName: "Priority",
				field: "priority",
				cellClass: "priority-col",
        filter: "agTextColumnFilter",
        get headerTooltip() { return this.headerName; }
			},
			{
				headerName: "Rule Name",
				field: "name",
				width: 200,
				cellClass: "name-col",
        filter: "agTextColumnFilter",
        get headerTooltip() { return this.headerName; }
			},
			{
				headerName: "Description",
				field: "description",
				cellClass: "description-col",
        filter: "agTextColumnFilter",
        get headerTooltip() { return this.headerName; }
			},
			{
				headerName: "Rule Type",
				field: "ruleType",
				cellClass: "ruleType-col",
        filter: "agTextColumnFilter",
        get headerTooltip() { return this.headerName; }
			},
			{
				headerName: "Store Group",
				field: "storeGroup",
				cellClass: "storeGroup-col",
        filter: "agTextColumnFilter",
        get headerTooltip() { return this.headerName; }
			},
			{
				headerName: "Store",
				field: "store",
				cellClass: "store-col",
        filter: "agTextColumnFilter",
        get headerTooltip() { return this.headerName; }
			},
			{
				headerName: "Set Pricing",
				field: "setPricing",
				width: 60,
				cellClass: ["setPricing-col", "tac"],
				cellRenderer: this._gridService.checkRenderer,
				filter: "agSetColumnFilter",
				filterParams: {
					cellRenderer: this._gridService.checkFilterRenderer,
					values: [true, false],
					newRowsAction: 'keep'
        },
        get headerTooltip() { return this.headerName; }
			},
			{
				headerName: "Active",
				field: "active",
				width: 60,
				cellClass: ["active-col", "tac"],
				cellRenderer: this._gridService.checkRenderer,
				filter: "agSetColumnFilter",
				filterParams: {
					cellRenderer: this._gridService.checkFilterRenderer,
					values: [true, false],
					newRowsAction: 'keep'
        },
        get headerTooltip() { return this.headerName; }
			},
      {
        headerName: "",
        field: "delete",
        width: 80,
        minWidth: 80,
        maxWidth: 80,
        cellClass: ["delete-col", "tac"],
        cellRenderer: this.permissions.roleAllowsUserToDeletePricingRule && !this.myUser.hasReadOnlyRestriction ? "DeleteButtonComponent" : null,
        sortable: false,
        filter: false,
        suppressHeaderMenuButton: true,
        suppressNavigable: true,
        hide: !this.permissions.roleAllowsUserToDeletePricingRule,
      }

    ];
  }
  
  constructor(
    private _pricingRulesService: PricingRulesService,
    private _storeGroupService: StoreGroupService,
    private _authenticationService: AuthenticationService,
    private _gridService: GridService,
    private _dialog: MatDialog, 
    private _messageService: MessageService,
    private _permissionsService: PermissionsService,
    private _activatedRoute: ActivatedRoute,
    private _router: Router,
    ) {
    this.myUser = this._authenticationService.getMyUser();
    this.permissions = this._permissionsService.getPermissions();
    this.gridOptions = <GridOptions> {
			columnDefs: this.getColumnDefs(),
			rowData: this.rowData,
      rowHeight: 35,    
      components: {
        ViewDetailButtonComponent: ViewDetailButtonComponent,
        DeleteButtonComponent: DeleteButtonComponent,
      },
      defaultColDef: {
        sortable: true,
        resizable: true,
        floatingFilter: true,
        suppressSpanHeaderHeight: true,
      },
      statusBar: {
        statusPanels: [
          { statusPanel: 'agTotalAndFilteredRowCountComponent', align: 'left' },
        ],
      },

    }
    this.context = { componentParent: this }
  }

  ngOnInit() {
    this.getMyStoreGroupsWithStores();
    // this._activatedRoute.paramMap.subscribe((params: ParamMap) => {
    //   var storeGroupId = params.get('storeGroupId');
    //   //will equal '0' if clicking create new pricing rule button without selecting store group first
    //   this.storeGroupIdToSelect = storeGroupId == '0' ? null : storeGroupId;
    // })
  }

  getMyStoreGroupsWithStores(){
    this._storeGroupService.getMyStoreGroupsWithStores()
      .subscribe(
        (data:any) => {
          this.storeGroups = data;
          if (data.length > 1) this.storeGroups.unshift(this.allStoreGroupsOption); //don't add the all store groups option if there is only one
          this.storeGroupIdToSelect = this._pricingRulesService.getSelectedStoreGroupId();
          if (this.storeGroupIdToSelect) { //if returning to page from detail
            if (this.storeGroupIdToSelect == 'all') this.storeGroup = this.storeGroups[0];
            else this.storeGroup = this.getStoreGroupFromId(Number(this.storeGroupIdToSelect));
            this.onStoreGroupSelected();
          }
          else if (this.storeGroups.length === 1){
            this.storeGroup = this.storeGroups[0];
            this.onStoreGroupSelected();
          }
        },
        (response) => {
          this._messageService.onFailure('Failed to get store groups.', response);
        })
  }

  onStoreGroupSelected() {
    this._pricingRulesService.setSelectedStoreGroupId(this.storeGroup.storeGroupId);
    if (this.storeGroup.storeGroupId === 'all') {
      this.getAllPricingRulesSansMatchCriteria();
    }
    else {
      this.getAllPricingRulesByStoreGroupIdSansMatchCriteria(this.storeGroup.storeGroupId);
    }
  }

  //this allows for setting a select value with an object
  compareStoreGroupObjects(o1: any, o2: any): boolean {
    return o1 && o2 ? o1.storeGroupId == o2.storeGroupId : false;
  }

  getAllPricingRulesSansMatchCriteria() {
    this._pricingRulesService.getAllPricingRulesSansMatchCriteria()
      .subscribe(
        (data:any) => {
          this.pricingRules = data;
          this.prioritizedPricingRules = [];
          for (var i = 0; i < data.length; i++) {
            this.prioritizedPricingRules.push({
              pricingRuleId: data[i].pricingRuleId,
              name: data[i].name,
              priority: data[i].priority,
            });
          }
          this.prioritizedPricingRules.sort(this.compare);
          this.formatandSetRowData(data);
        },
        (response) => {
          this._messageService.onFailure('Failed to get all pricing rules.', response);
        })
  };

  getAllPricingRulesByStoreGroupIdSansMatchCriteria(storeGroupId) {
    this._pricingRulesService.getAllPricingRulesByStoreGroupIdSansMatchCriteria(storeGroupId)
      .subscribe(
        (data:any) => {
          this.pricingRules = data;
          this.prioritizedPricingRules = [];
          for (var i = 0; i < data.length; i++) {
            this.prioritizedPricingRules.push({
              pricingRuleId: data[i].pricingRuleId,
              name: data[i].name,
              priority: data[i].priority,
            });
          }
          this.prioritizedPricingRules.sort(this.compare);
          this.formatandSetRowData(data);
        },
        (response) => {
          this._messageService.onFailure('Failed to get pricing rules by store id.', response);
        })
  };

  compare(a, b) {
    if (Number(a.priority) < Number(b.priority))
      return -1;
    if (Number(a.priority) > Number(b.priority))
      return 1;
    return 0;
  }

  // getAllPricingRules(){
  //   this._pricingRulesService.getAllPricingRuleMatchCriterionTypes()
  //     .subscribe(
  //       (data) => {
  //         this.formatandSetRowData(data);
  //       },
  //       (response) => {
  //         this._messageService.onFailure('Failed to get all pricing rules.', response);
  //       })
  // }

  formatandSetRowData(data) {
    var gridData = [];
    for (var i=0; i<data.length; i++){
      var singleRowData = {};
      singleRowData = {
        priority: data[i].priority,
        pricingRuleId: data[i].pricingRuleId,
        name: data[i].name,
        description: data[i].description,
        ruleType: data[i].ruleType,
        storeGroup: this.getStoreGroupNameFromId(data[i].storeGroupId),
        store: this.getStoreNumberAndLocationFromId(data[i].storeGroupId, data[i].storeId),
        setPricing: data[i].setPricing,
        active: data[i].active,
      };
      gridData.push(singleRowData);
    }
    this.rowData = gridData;
    // var sort = [
    //   {
    //     colId: "priority",
    //     sort: "asc"
    //   }
    // ];
    this.agGrid.api.applyColumnState({ 
      state: [
        {
          colId: "priority",
          sort: "asc"
        }
      ]
    });
    this._gridService.onWindowResize('#ag-grid-wrapper');
    setTimeout(() => {
      this.fitColumns();
      this._gridService.sizeGrid('#ag-grid-wrapper');
    }, 100); 
  }

  getStoreGroupFromId(storeGroupId) {
    const storeGroup = this.storeGroups.find(group => group.storeGroupId === storeGroupId);

    return storeGroup ? storeGroup : {};
  }

  getStoreGroupNameFromId(storeGroupId) {
    const storeGroup = this.storeGroups.find(group => group.storeGroupId === storeGroupId);
  
    return storeGroup ? storeGroup.name : '';
  }

  getStoreNumberAndLocationFromId(storeGroupId, storeId) {
    const storeGroup = this.storeGroups.find(group => group.storeGroupId === storeGroupId);
  
    if (!storeGroup) {
      return '';
    }
  
    const store = storeGroup.stores.find(store => store.storeId === storeId);
    
    return store ? store.numberAndLocation : '';
  }

  clearFilters(){
    this.quickFilterText = '';
    this.agGrid.api.setGridOption('quickFilterText', this.quickFilterText);
    this.agGrid.api.setFilterModel(null);
    this.agGrid.api.onFilterChanged();
  }

  resetColumns(){
    this.agGrid.api.resetColumnState();
    this.fitColumns(); //since this is done by default also
  }

  autoSizeColumns(){
    var columns = this.agGrid.api.getColumns();
    this.agGrid.api.autoSizeColumns(columns);
  }

  fitColumns(){
    this.agGrid.api.sizeColumnsToFit();
  }

  onQuickFilterChange(){
    this.agGrid.api.setGridOption('quickFilterText', this.quickFilterText);
  }

  onDetailButtonClick(params){
    // this._router.navigate(['/admin/pricing-rules', this.storeGroup.storeGroupId, params.data.pricingRuleId]);
    this._router.navigate(['/admin/pricing-rules', params.data.pricingRuleId]);
  }

  onDeleteButtonClick(params){
    //Users with hasReadOnlyRestriction set to true should not even be allowed to invoke this action because it is hidden or disabled in the UI.
    //This is for extra protection. The back end will also be protected.
    if (this.myUser.hasReadOnlyRestriction){
      this._messageService.alertReadOnlyRestriction();
      return;
    }

    this.confirmationDialogRef = this._dialog.open(ConfirmationDialogComponent, {
      disableClose: false,
      //width: '600px',
      data: {
        title: 'Are you sure?',
        message: 'Do you want to delete "' + params.data.name + '"?',
        confirmText: 'Yes, delete it',
        cancelText: 'No, cancel this action!'
      }
    });
    this.confirmationDialogRef.afterClosed().subscribe(result => {
      if(result) {
        this._pricingRulesService.deletePricingRule(params.data.pricingRuleId)
          .subscribe(
            (data) => {
              this._messageService.onSuccess('Successfully deleted pricing rule');
              //this.getAllPricingRulesByStoreGroupIdSansMatchCriteria(this.storeGroup.storeGroupId);
              //reload this list based on which store group is selected or "all"
              this.onStoreGroupSelected();
            },
            (response) => {
              this._messageService.onFailure('Failed to delete pricing rule.', response);
            }
          )
      }
      this.confirmationDialogRef = null;
    });
  }

  goToCreatePricingRule(){
    //var storeGroupId = this.storeGroup ? this.storeGroup.storeGroupId : 0;
    //this._router.navigate(['/admin/pricing-rules/' + storeGroupId, 0]);
    this._router.navigate(['/admin/pricing-rules/', 0]);
  }

  onPrioritizeButtonClick(){
    //Users with hasReadOnlyRestriction set to true should not even be allowed to invoke this action because it is hidden or disabled in the UI.
    //This is for extra protection. The back end will also be protected.
    if (this.myUser.hasReadOnlyRestriction){
      this._messageService.alertReadOnlyRestriction();
      return;
    }
    
    if (this.storeGroup.storeGroupId != 'all') {
      //console.log('prioritize button clicked')
      this.prioritizePricingRulesRef = this._dialog.open(PrioritizePricingRulesDialog, {
        disableClose: false,
        //width: '600px',
        height: '500px',
        data: {
          prioritizedPricingRules: this.prioritizedPricingRules
        }
      });
      this.prioritizePricingRulesRef.afterClosed().subscribe(result => {
        if(result == 'reload') {
          this.getAllPricingRulesByStoreGroupIdSansMatchCriteria(this.storeGroup.storeGroupId);
        }
        this.prioritizePricingRulesRef = null;
      });
    }
    else {
      this._messageService.alert('You must choose only one store group in order to prioritize its pricing rules.<br><br><em>(Currently, "All Store Groups" is selected.)</em>');
    }
  }



}

@Component({
  selector: 'app-prioritize-pricing-rules-dialog',
  template: `<rpms-prioritize-pricing-rules [prioritizedPricingRules]="data.prioritizedPricingRules"></rpms-prioritize-pricing-rules>`,
})
export class PrioritizePricingRulesDialog {

  constructor(
    public dialogRef: MatDialogRef<PrioritizePricingRulesDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any) {}

}
