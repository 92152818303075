import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { AppConfig } from '../app.config';

@Injectable({
  providedIn: 'root'
})
export class PricingRulesService {

  private _baseUrl = AppConfig.settings.baseUrl;
  private _reqHeader = new HttpHeaders({ 'Accept': 'application/json', 'Content-Type': 'application/json' });

  private _selectedStoreGroupId = 'all';

  constructor ( 
    private _http: HttpClient, 
  ){}

  setSelectedStoreGroupId(id){
    this._selectedStoreGroupId = id;
  }

  getSelectedStoreGroupId(){
    return this._selectedStoreGroupId;
  }

  getAllPricingRulesWithMatchCriteria() {
    return this._http.get(this._baseUrl + '/api/PricingRules/GetAllPricingRulesWithMatchCriteria', { headers: this._reqHeader });
  }

  getActivePricingRulesWithMatchCriteria() {
    return this._http.get(this._baseUrl + '/api/PricingRules/GetActivePricingRulesWithMatchCriteria', { headers: this._reqHeader });
  }

  getAllPricingRulesSansMatchCriteria() {
    return this._http.get(this._baseUrl + '/api/PricingRules/GetAllPricingRulesSansMatchCriteria', { headers: this._reqHeader });
  }

  getActivePricingRulesSansMatchCriteria() {
    return this._http.get(this._baseUrl + '/api/PricingRules/GetActivePricingRulesSansMatchCriteria', { headers: this._reqHeader });
  }

  // by store group id

  getAllPricingRulesByStoreGroupIdWithMatchCriteria(storeGroupId) {
    return this._http.get(this._baseUrl + '/api/PricingRules/GetAllPricingRulesByStoreGroupIdWithMatchCriteria/' + storeGroupId, { headers: this._reqHeader });
  }

  getAllPricingRulesByStoreGroupIdSansMatchCriteria(storeGroupId) {
    return this._http.get(this._baseUrl + '/api/PricingRules/GetAllPricingRulesByStoreGroupIdSansMatchCriteria/' + storeGroupId, { headers: this._reqHeader });
  }

  getActivePricingRulesByStoreGroupIdSansMatchCriteria(storeGroupId) {
    return this._http.get(this._baseUrl + '/api/PricingRules/GetActivePricingRulesByStoreGroupIdSansMatchCriteria/' + storeGroupId, { headers: this._reqHeader });
  }

  getActivePricingRulesByStoreGroupIdWithMatchCriteria(storeGroupId) {
    return this._http.get(this._baseUrl + '/api/PricingRules/GetActivePricingRulesByStoreGroupIdWithMatchCriteria/' + storeGroupId, { headers: this._reqHeader });
  }

  //by store id

  getAllPricingRulesByStoreIdWithMatchCriteria(storeId) {
    return this._http.get(this._baseUrl + '/api/PricingRules/GetAllPricingRulesByStoreIdWithMatchCriteria/' + storeId, { headers: this._reqHeader });
  }

  getAllPricingRulesByStoreIdSansMatchCriteria(storeId) {
    return this._http.get(this._baseUrl + '/api/PricingRules/GetAllPricingRulesByStoreIdSansMatchCriteria/' + storeId, { headers: this._reqHeader });
  }

  getActivePricingRulesByStoreIdSansMatchCriteria(storeId) {
    return this._http.get(this._baseUrl + '/api/PricingRules/GetActivePricingRulesByStoreIdSansMatchCriteria/' + storeId, { headers: this._reqHeader });
  }

  getActivePricingRulesByStoreIdWithMatchCriteria(storeId) {
    return this._http.get(this._baseUrl + '/api/PricingRules/GetActivePricingRulesByStoreIdWithMatchCriteria/' + storeId, { headers: this._reqHeader });
  }

 //

  getAllPricingRuleMatchCriterionTypes() {
    return this._http.get(this._baseUrl + '/api/PricingRules/GetAllPricingRuleMatchCriterionTypes', { headers: this._reqHeader });
  }

  getPricingRuleById(pricingRuleId) {
    return this._http.get(this._baseUrl + '/api/PricingRules/GetPricingRuleById/' + pricingRuleId, { headers: this._reqHeader });
  }

  getPricingRuleByStoreGroupIdAndName(storeGroupId, pricingRuleName) {
    return this._http.get(this._baseUrl + '/api/PricingRules/GetPricingRuleByStoreGroupIdAndName/' + storeGroupId + '/' + pricingRuleName, { headers: this._reqHeader });
  }

  createPricingRule(pricingRule) {
    return this._http.post(this._baseUrl + '/api/PricingRules/CreatePricingRule', pricingRule, { headers: this._reqHeader });
  }

  updatePricingRule(pricingRuleId, pricingRule) {
    return this._http.put(this._baseUrl + '/api/PricingRules/UpdatePricingRule/' + pricingRuleId, pricingRule, { headers: this._reqHeader });
  }

  updatePricingRulePriorities(prioritizedPricingRules) {
    return this._http.put(this._baseUrl + '/api/PricingRules/UpdatePricingRulePriorities', prioritizedPricingRules, { headers: this._reqHeader });
  }

  deletePricingRule(pricingRuleId) {
    return this._http.delete(this._baseUrl + '/api/PricingRules/DeletePricingRule/' + pricingRuleId, { headers: this._reqHeader });
  }

  previewPricingRule(pricingRulePreviewBindingModel) {
    return this._http.post(this._baseUrl + '/api/PricingRules/PreviewPricingRule', pricingRulePreviewBindingModel, { headers: this._reqHeader });
  }

}
