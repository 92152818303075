import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { SpinnerService } from './spinner.service';
//import { AuthenticationService } from '../core/authentication/shared/authentication.service';
import { CriticalErrorDialogComponent } from './critical-error-dialog/critical-error-dialog.component';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { ReportService } from './report.service';
import { MessageService } from './message.service';
import { AppConfig } from '../app.config';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {

  criticalErrorDialogRef: MatDialogRef<CriticalErrorDialogComponent>;

  constructor //changed in Angular 11
  ( 
    private appConfig: AppConfig,
    private injector: Injector,
    // private _spinnerService: SpinnerService,
    // private _reportService: ReportService,
    // private _messageService: MessageService,
    // private _dialog: MatDialog, 
  ) {
  }
  
  handleError(error) {
    console.log(this)
    // your custom error handling logic
    let _spinnerService = this.injector.get(SpinnerService);
    let _reportService = this.injector.get(ReportService);
    let _messageService = this.injector.get(MessageService);
    _spinnerService.handleProgressSpinnerVisibility('hide', 'Processing Request...');
    let msg = String(error.message + '. ' + error.stack);
    _reportService.logClientMessage({ message: msg })
      .subscribe(
        data => {
          _messageService.showToastBottomLeft('Logged error');
        },
        response => {
          _messageService.onFailure('Failed to log error.', response);
        }
      )
    this.showLockoutAlert(error);
    // this._spinnerService.handleProgressSpinnerVisibility('hide', 'Processing Request...');
    // let msg = String(error.message + '. ' + error.stack);
    // this._reportService.logClientMessage({ message: msg })
    //   .subscribe(
    //     data => {
    //       this._messageService.showToastBottomLeft('Logged error');
    //     },
    //     response => {
    //       this._messageService.onFailure('Failed to log error.', response);
    //     }
    //   )
    // this.showLockoutAlert(error);
    console.error(error);
  }

  showLockoutAlert(error){
    let _dialog = this.injector.get(MatDialog);
    this.criticalErrorDialogRef = _dialog.open(CriticalErrorDialogComponent, {
      disableClose: true,
      //width: '600px',
      data: {
        //message: error.message + '<br/><br/><strong>Please contact an administrator about this error.</strong><br/><br/>' + error.stack,
        message: error.message + '<br/><br/>Please contact support if this error persists.<br/><br/><strong>It is recommended that you log out and then log back in before continuing your work.  Continuing to work without logging out could have unexpected results.<br/><br/>Do you want to continue working, or log out?</strong>'
      }
    });
    this.criticalErrorDialogRef.afterClosed().subscribe(result => {
      if(result) {
        //authenticationService was undefined here. Don't know why. So I put the logout on the critical-error-dialog.component
        //this._authenticationService.logout();
      }
      this.criticalErrorDialogRef = null;
    });
  }

}