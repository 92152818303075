import { Component, OnInit, Input, Inject, LOCALE_ID } from '@angular/core';
import { Validators, UntypedFormBuilder } from '@angular/forms';
import { ItemListService } from 'src/app/shared/item-list.service';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { AdminItemListDetailDialog } from '../admin-item-list-list/admin-item-list-list.component';
import { formatDate } from '@angular/common';
import { ConfirmationDialogComponent } from 'src/app/shared/confirmation-dialog/confirmation-dialog.component';
import { MessageService } from 'src/app/shared/message.service';
import { AccessService } from 'src/app/shared/access.service';
import { PermissionsService } from 'src/app/shared/permissions.service';
import { AppConfig } from 'src/app/app.config';
import { AuthenticationService } from 'src/app/core/authentication/shared/authentication.service';

@Component({
  selector: 'rpms-admin-item-list-detail',
  templateUrl: './admin-item-list-detail.component.html',
  styleUrls: ['./admin-item-list-detail.component.css']
})
export class AdminItemListDetailComponent implements OnInit {
  @Input() id: string; 
  private config = AppConfig.settings;

  confirmationDialogRef: MatDialogRef<ConfirmationDialogComponent>;

  itemListId: string;
  mode: string;
  itemList;
  modifiedByOptions = ['Everyone', 'AllUsersManagedByMe', 'NoOne'];
  sharedWithOptions = ['Everyone', 'AllUsersManagedByMe', 'NoOne'];
  isItemListModifiable: boolean = false;
  canItemListSharedWithAndOrModifiedByPropertiesBeChanged: boolean = false;
  permissions: any = {};
  myUser;

  itemListDetailForm = this._fb.group({
    active: [''],
    name: ['', Validators.required],
    description: [''],
    creator: [''],
    sharedWith: [''],
    modifiedBy: [''],
    created: [''],
    modified: [''],
    accessed: [''],
    itemCodes: [''],
  });

  constructor(
    private _fb: UntypedFormBuilder,
    private _accessService: AccessService,
    private _permissionsService: PermissionsService,
    private _itemListService: ItemListService,
    private _authenticationService: AuthenticationService,
    private _messageService: MessageService,
    public dialogRef: MatDialogRef<AdminItemListDetailDialog>,
    private _dialog: MatDialog, 
    @Inject(LOCALE_ID) private locale: string
    ) { }

  ngOnInit() {
    this.myUser = this._authenticationService.getMyUser();
    if(this.myUser.hasReadOnlyRestriction) this.itemListDetailForm.disable();
    this.itemListId = this.id;
    this.mode = this.itemListId == '0' ? 'New' : 'Edit';
    this.permissions = this._permissionsService.getPermissions();
    if (this.itemListId != '0') this.getIsItemListModifiable();
  }

  getIsItemListModifiable(){
    this._accessService.isItemListModifiable(this.itemListId)
      .subscribe(
        (data:any) => {
          this.isItemListModifiable = data.value;
          if (!this.isItemListModifiable) {
            this.itemListDetailForm.get('active').disable();
            this.itemListDetailForm.get('name').disable();
            this.itemListDetailForm.get('description').disable();
            this.itemListDetailForm.get('sharedWith').disable();
            this.itemListDetailForm.get('modifiedBy').disable();
          }
          //these are always disabled
          this.itemListDetailForm.get('creator').disable();
          this.itemListDetailForm.get('created').disable();
          this.itemListDetailForm.get('modified').disable();
          this.itemListDetailForm.get('accessed').disable();
          //this.itemListDetailForm.get('itemCodes').disable();
          this.getCanItemListSharedWithAndOrModifiedByPropertiesBeChanged();
        },
        (response) => {
          this._messageService.onFailure('Failed to determine if item list is modifiable.', response);
        }
      )
  }

  getCanItemListSharedWithAndOrModifiedByPropertiesBeChanged(){
    this._accessService.canItemListSharedWithAndOrModifiedByPropertiesBeChanged(this.itemListId)
      .subscribe(
        (data:any) => {
          this.canItemListSharedWithAndOrModifiedByPropertiesBeChanged = data.value;
          if (!this.canItemListSharedWithAndOrModifiedByPropertiesBeChanged) {
            this.itemListDetailForm.get('sharedWith').disable();
            this.itemListDetailForm.get('modifiedBy').disable();
          }
          this.getItemList();
        },
        (response) => {
          this._messageService.onFailure('Failed to determine if item list\'s Shared With and Modified By properties can be changed.', response);
        }
      )
  }

  getItemList(){
    this._itemListService.getItemListById(this.itemListId)
      .subscribe(
        (data:any) => {
          this.itemList = data;
          if (this.config.logIds) console.log(`%citemListName: ${data.name}`, 'background: black; color: white; font-weight: bold; font-size: 120%');
          if (this.config.logIds) console.log(`%citemListId: ${data.itemListId}`, 'background: yellow; font-weight: bold; font-size: 120%');

          this.itemListDetailForm.patchValue({
            active: data.active,
            name: data.name,
            description: data.description,
            creator: data.owner.fullNameAndEmail,
            sharedWith: data.sharedWith,
            modifiedBy: data.modifiedBy,
            created: data.created ? formatDate(data.created, 'MM/dd/yyyy h:mm:ss a', this.locale) : '',
            modified: data.modified ? formatDate(data.modified, 'MM/dd/yyyy h:mm:ss a', this.locale) : '',
            accessed: data.accessed ? formatDate(data.accessed, 'MM/dd/yyyy h:mm:ss a', this.locale) : '',
            itemCodes: data.itemCodes,
          });
        },
        (response) => {
          this._messageService.onFailure('Failed to get Item List.', response);
        })
  }

  clearAllItemCodes(){
    this.confirmationDialogRef = this._dialog.open(ConfirmationDialogComponent, {
      disableClose: false,
      //width: '600px',
      data: {
        title: 'Are you sure?',
        message: 'Do you want to delete all Item Codes?',
        confirmText: 'Yes, delete all item codes',
        cancelText: 'No, cancel this action!'
      }
    });
    this.confirmationDialogRef.afterClosed().subscribe(result => {
      if(result) {
        this.itemListDetailForm.patchValue({
          itemCodes: '',
        });
      }
      this.confirmationDialogRef = null;
    });
  }

  onSubmitItemList(){
    //Users with hasReadOnlyRestriction set to true should not even be allowed to invoke this action because it is hidden or disabled in the UI.
    //This is for extra protection. The back end will also be protected.
    if (this.myUser.hasReadOnlyRestriction){
      this._messageService.alertReadOnlyRestriction();
      return;
    }
    
    var itemList = this.itemListDetailForm.value;
    if (this.mode === 'New') {
      this._itemListService.createItemList(itemList)
      .subscribe(
        (data) => {
          this.dialogRef.close('reload');
          this._messageService.onSuccess('Successfully created Item List');
        },
        (response) => {
          this._messageService.onFailure('Failed to create Item List.', response)
        }
      );
    }
    else {
      this._itemListService.updateItemList(this.itemListId, itemList)
      .subscribe(
        (data) => {
          this.dialogRef.close('reload');
          this._messageService.onSuccess('Successfully updated Item List');
        },
        (response) => {
          this._messageService.onFailure('Failed to update Item List.', response)
        }
      );
    }
  }

  addSpacesBetweenWords(v) {
    if (v != null && v != '') {
      var valueWithSpaces = v.replace(/([A-Z])/g, ' $1');
      valueWithSpaces = valueWithSpaces.substr(1); //remove space before first word
      return valueWithSpaces;
    }
  }

  closeDialog(){
    this.dialogRef.close();
  }


}
