import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { AppConfig } from '../app.config';

@Injectable({
  providedIn: 'root'
})
export class ItemFilterService {

  private _baseUrl = AppConfig.settings.baseUrl;
  private _reqHeader = new HttpHeaders({ 'Accept': 'application/json', 'Content-Type': 'application/json' });

  constructor ( 
    private _http: HttpClient, 
  ){}

  getItemFilterById(itemFilterId) {
    return this._http.get(this._baseUrl + '/api/ItemFilters/GetItemFilterById/' + itemFilterId, { headers: this._reqHeader });
  }

  getAllSharedItemFilters(params?) {
    return this._http.get(this._baseUrl + '/api/ItemFilters/GetAllSharedItemFilters', { params: params, headers: this._reqHeader });
  }

  getActiveSharedItemFilters(params?) {
    return this._http.get(this._baseUrl + '/api/ItemFilters/GetActiveSharedItemFilters', { params: params, headers: this._reqHeader });
  }

  getOwnedItemFilters(userId) {
    return this._http.get(this._baseUrl + '/api/ItemFilters/GetOwnedItemFilters/' + userId, { headers: this._reqHeader });
  }

  createItemFilter(itemFilter) {
    return this._http.post(this._baseUrl + '/api/ItemFilters/CreateItemFilter', itemFilter, { headers: this._reqHeader });
  }

  updateItemFilter(itemFilterId, itemFilter) {
    return this._http.put(this._baseUrl + '/api/ItemFilters/UpdateItemFilter/' + itemFilterId, itemFilter, { headers: this._reqHeader });
  }

  deleteItemFilter(itemFilterId) {
    return this._http.delete(this._baseUrl + '/api/ItemFilters/DeleteItemFilter/' + itemFilterId, { headers: this._reqHeader });
  }
}
