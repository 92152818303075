import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { AppConfig } from '../app.config';

@Injectable({
  providedIn: 'root'
})
export class StoreService {

  private _baseUrl = AppConfig.settings.baseUrl;
  private _reqHeader = new HttpHeaders({ 'Accept': 'application/json', 'Content-Type': 'application/json' });

  private _selectedStore: any = {};

  constructor ( 
    private _http: HttpClient, 
  ){}

  getStoreById(storeId) {
    return this._http.get(this._baseUrl + '/api/Stores/GetStoreById/' + storeId, { headers: this._reqHeader });
  }

  createStore(store) {
    return this._http.post(this._baseUrl + '/api/Stores/CreateStore', store, { headers: this._reqHeader });
  }

  updateStore(storeId, store) {
    return this._http.put(this._baseUrl + '/api/Stores/UpdateStore/' + storeId, store, { headers: this._reqHeader });
  }

  getAllStores(params?) {
    return this._http.get(this._baseUrl + '/api/Stores/GetAllStores', { params: params, headers: this._reqHeader });
  }

  getActiveStores(params?) {
    return this._http.get(this._baseUrl + '/api/Stores/GetActiveStores', { params: params, headers: this._reqHeader });
  }

  getControlledStores(storeId) {
    return this._http.get(this._baseUrl + '/api/Stores/GetControlledStores/' + storeId, { headers: this._reqHeader });
  }

  getAllStoresByStoreGroupId(storeGroupId) {
    return this._http.get(this._baseUrl + '/api/Stores/GetAllStoresByStoreGroupId/' + storeGroupId, { headers: this._reqHeader });
  }

  getActiveStoresByStoreGroupId(storeGroupId) {
    return this._http.get(this._baseUrl + '/api/Stores/GetActiveStoresByStoreGroupId/' + storeGroupId, { headers: this._reqHeader });
  }

  getAccessibleStoresByStoreGroupId(storeGroupId) {
    return this._http.get(this._baseUrl + '/api/Stores/GetAccessibleStoresByStoreGroupId/' + storeGroupId, { headers: this._reqHeader });
  }

  getAccessibleActiveStoresByStoreGroupId(storeGroupId) {
    return this._http.get(this._baseUrl + '/api/Stores/GetAccessibleActiveStoresByStoreGroupId/' + storeGroupId, { headers: this._reqHeader });
  }

  deleteStore(storeId, forceDisassociationOrDeletionOfDependencies) {
    return this._http.delete(this._baseUrl + '/api/Stores/DeleteStore/' + storeId + '/' + forceDisassociationOrDeletionOfDependencies, { headers: this._reqHeader });
  }

  // keep track of selected store for price management
  getSelectedStore(){
    return this._selectedStore;
  }

  setSelectedStore(storeObj){
    this._selectedStore = storeObj;
  }

}
