<div class="pm-editor-dialog">
<h1 mat-dialog-title>{{this.mode}} Item List: {{itemListDetailForm.get('name').value}}</h1>
<div mat-dialog-content>
  <form [formGroup]="itemListDetailForm" id="item-list-detail-form" (ngSubmit)="onSubmitItemList()">
    <div class="row">
      <mat-form-field>
        <mat-label>Item List Name</mat-label>
        <input matInput id="name" formControlName="name" placeholder="Item List Name" required maxlength="30">
        <mat-error *ngIf="itemListDetailForm.hasError('required', 'name')">Item List Name is required</mat-error>
        <mat-hint align="end">{{itemListDetailForm.get('name').value.length}} / 30</mat-hint>
      </mat-form-field>
    </div>
    <div class="row">
      <mat-form-field>
        <mat-label>Description</mat-label>
        <input matInput id="description" formControlName="description" placeholder="Description">
      </mat-form-field>
    </div>
    <div class="row">
      <mat-form-field>
        <mat-label>Shared With</mat-label>
        <mat-select id="sharedWith" formControlName="sharedWith" placeholder="Shared With" required>
          <mat-option *ngFor="let swOption of sharedWithOptions" [value]="swOption">
            {{addSpacesBetweenWords(swOption)}}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="itemListDetailForm.hasError('required', 'sharedWith')">Shared With is required</mat-error>
      </mat-form-field>
    </div>
    <div class="row">
      <mat-form-field>
        <mat-label>Modified By</mat-label>
        <mat-select id="modifiedBy" formControlName="modifiedBy" placeholder="Modified By" required>
          <mat-option *ngFor="let mbOption of modifiedByOptions" [value]="mbOption">
            {{addSpacesBetweenWords(mbOption)}}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="itemListDetailForm.hasError('required', 'mbOption')">Modified By is required</mat-error>
      </mat-form-field>
    </div>
    <div class="row" *ngIf="this.id != 0">
      <mat-form-field>
        <mat-label>Creator</mat-label>
        <input matInput id="creator" formControlName="creator" placeholder="Creator">
      </mat-form-field>
    </div>
    <div class="row" *ngIf="this.id != 0">
      <mat-form-field>
        <mat-label>Item Codes</mat-label>
        <textarea matInput id="itemCodes" formControlName="itemCodes" placeholder="Item Codes"></textarea>
      </mat-form-field>
    </div>
    <div>
      <button mat-raised-button color="primary" [disabled]="!itemListDetailForm.valid || myUser.hasReadOnlyRestriction" type="submit">Save</button>
      <button mat-raised-button (click)="closeDialog()" type="button">Cancel</button>
    </div>
  </form>
</div>
</div>
