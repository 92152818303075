import { Component, OnInit, Inject, LOCALE_ID, Input, ViewChild, EventEmitter } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { UserService } from 'src/app/shared/user.service';
import { UntypedFormBuilder, Validators, AbstractControl } from '@angular/forms';
import { formatDate } from '@angular/common';
import { StoreGroupService } from 'src/app/shared/store-group.service';
import { StoreService } from 'src/app/shared/store.service';
import { AccessService } from 'src/app/shared/access.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AdminUserDetailDialog } from '../admin-user-list/admin-user-list.component';
import { MessageService } from 'src/app/shared/message.service';
import { MatAccordion } from '@angular/material/expansion';
import { ItemFilterService } from 'src/app/shared/item-filter.service';
import { ItemListService } from 'src/app/shared/item-list.service';
import { TransferService } from 'src/app/shared/transfer.service';
import { UserTransferDialogComponent } from 'src/app/admin/admin-users/user-transfer-dialog/user-transfer-dialog.component';
import { PermissionsService } from 'src/app/shared/permissions.service';
//import { environment } from 'src/environments/environment';
import { AppConfig } from 'src/app/app.config';
import { AuthenticationService } from 'src/app/core/authentication/shared/authentication.service';

//const EMAIL_REGEX = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/
const EMAIL_REGEX = /[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?/

@Component({
  selector: 'rpms-admin-user-detail',
  templateUrl: './admin-user-detail.component.html',
  styleUrls: ['./admin-user-detail.component.css']
})
export class AdminUserDetailComponent implements OnInit {

  @Input() id: string; 
  @ViewChild(MatAccordion) accordion: MatAccordion;
  private config = AppConfig.settings;

  transferDialogRef: MatDialogRef<UserTransferDialogComponent>;

  entityType = 'User';
  hide = true;
  userId: string;
  mode: string;
  passwordMode: string;
  isNewAccount: boolean;
  isMyAccount: boolean;
  isAdministrationUsersAddUserStoreGroupSelectionEnabled: boolean;
  isAdministrationUsersAddUserStoreSelectionEnabled: boolean;
  showStoreGroupAndStoreSelection: boolean;
  roles;
  storeGroups;
  stores;
  lastLoginDate: string;
  selectedHomeStoreGroupId: number;
  homeStoreGroupRequired: boolean;
  homeStoreRequired: boolean;
  user: any;
  users; //used for transfer dialog
  myUser: any;
  isMyUser: boolean =  false;
  permissions: any;

  itemFilters = [];
  itemFilterDescription;
  itemLists = [];
  itemListDescription;

  assetCount = 0;
  rowData;
  params;

  itemFilterPanelOpenState: boolean = false;
  itemListPanelOpenState: boolean = false;

  showTransferButton: boolean = true;
  transferOccurred: boolean = false; //used to reload list page if needed
  
  userDetailForm = this._fb.group({
    active: [''],
    hasReadOnlyRestriction: [{value: false, disabled: true}],
    hasDeletionPrivilege: [{value: false, disabled: true}],
    hasTransferPrivilege: [{value: false, disabled: true}],
    role: [''],
    userName: ['', [Validators.required, Validators.pattern(EMAIL_REGEX)]],
    firstName: [''],
    lastName: ['', Validators.required],
    phoneNumber: [''],
    managedStoreGroupIds: [''],
    homeStoreGroupId: ['', Validators.required],
    homeStoreId: [''],
    password: [''],
    confirmPassword: [''],
  }, {validators: [this.passwordValidator]});

  passwordForm = this._fb.group({
    currentPassword: [''],
    password: ['', Validators.required],
    confirmPassword: ['', Validators.required],
  }, {validators: [this.passwordValidator]});


  constructor(private _activatedRoute: ActivatedRoute,
              private _accessService: AccessService,
              private _authenticationService: AuthenticationService,
              private _userService: UserService,
              private _itemFilterService: ItemFilterService,
              private _itemListService: ItemListService,
              private _transferService: TransferService,
              private _fb: UntypedFormBuilder,
              private _storeGroupService: StoreGroupService,
              private _storeService: StoreService,
              private _router: Router,
              private _messageService: MessageService,
              private _permissionsService: PermissionsService,
              private _dialog: MatDialog, 
              public dialogRef: MatDialogRef<AdminUserDetailDialog>,
              @Inject(LOCALE_ID) private locale: string,
              @Inject(MAT_DIALOG_DATA) public data: any) 
              {
                this.userId = data.id;
                this.users = data.users == undefined ? [] : data.users;
                this.rowData = data.rowData;
                this.params = data.params;
                this.isMyUser = data.isMyUser; //comes from header component
                this.myUser = this._authenticationService.getMyUser();
                this.permissions = this._permissionsService.getPermissions();
              }

  ngOnInit() {
    if (this.config.logInits) console.log('user detail init');

    //if changing users without leaving page (manage myUser)
    //ngOnInit will not run again, but the subscribe method below will update and call doOnInit()
    this._activatedRoute.paramMap.subscribe((params: ParamMap) =>{
      if (!this.userId) this.userId = params.get('id');
      this.doOnInit();
    })
    if (!this.userId) this.userId = this.id;
    this.doOnInit();

    this.userDetailForm.get('hasReadOnlyRestriction').valueChanges.subscribe(checked => {
      if (checked) {
        this.userDetailForm.get('hasDeletionPrivilege').setValue(false, {emitEvent: false});
        this.userDetailForm.get('hasTransferPrivilege').setValue(false, {emitEvent: false});
      }
    });
  
    this.userDetailForm.get('hasDeletionPrivilege').valueChanges.subscribe(checked => {
      if (checked) {
        this.userDetailForm.get('hasReadOnlyRestriction').setValue(false, {emitEvent: false});
      }
    });
  
    this.userDetailForm.get('hasTransferPrivilege').valueChanges.subscribe(checked => {
      if (checked) {
        this.userDetailForm.get('hasReadOnlyRestriction').setValue(false, {emitEvent: false});
      }
    });
  }

  ngOnContentInit(){
    this.setConditionalValidation();
  }

  doOnInit(){
    //these fields are set to disabled by default when they are created
    if (this.permissions.roleAllowsUserToSetDeleteAndTransferPrivileges && !this.myUser.hasReadOnlyRestriction && !this.isMyUser) {
      this.userDetailForm.get('hasDeletionPrivilege').enable();
      this.userDetailForm.get('hasTransferPrivilege').enable();
    }

    //this field is set to disabled by default when created
    if (!this.myUser.hasReadOnlyRestriction && !this.isMyUser) {
      this.userDetailForm.get('hasReadOnlyRestriction').enable();
    }

    if (this.userId === '0') { //new account
			this.mode = 'Create New User Account:';
			this.isNewAccount = true;
			this.isMyAccount = false;
      this.userDetailForm.patchValue({
        active: true,
      });
    }
		else if (this.isMyUser) { //my account
      this.mode = 'Manage My Account:';
      this.passwordMode = 'Change My';
			this.isNewAccount = false;
			this.isMyAccount = true;
      this.showTransferButton = false;
      this.userDetailForm.get('hasReadOnlyRestriction').disable();
      this.userDetailForm.get('hasDeletionPrivilege').disable();
      this.userDetailForm.get('hasTransferPrivilege').disable();
      this.userDetailForm.get('hasReadOnlyRestriction').updateValueAndValidity();
      this.userDetailForm.get('hasDeletionPrivilege').updateValueAndValidity();
      this.userDetailForm.get('hasTransferPrivilege').updateValueAndValidity();
      this.userDetailForm.get('homeStoreGroupId').disable();
      this.userDetailForm.get('homeStoreId').disable();
      this.userDetailForm.get('homeStoreGroupId').updateValueAndValidity();
      this.userDetailForm.get('homeStoreId').updateValueAndValidity();
    } 
    else { // editing existing account
			this.mode = 'Edit User:';
      this.passwordMode = 'Set';
			this.isNewAccount = false;
			this.isMyAccount = false;
      this.userDetailForm.get('homeStoreGroupId').disable();
      this.userDetailForm.get('homeStoreId').disable();
      this.userDetailForm.get('homeStoreGroupId').updateValueAndValidity();
      this.userDetailForm.get('homeStoreId').updateValueAndValidity();
		}

    this._accessService.isAdministrationUsersAddUserStoreGroupSelectionEnabled()
      .subscribe(
        (data:any) => {
          this.isAdministrationUsersAddUserStoreGroupSelectionEnabled = data.value;
          this.getIsAdministrationUsersAddUserStoreSelectionEnabled();
        },
        (response) => {
          this._messageService.onFailure('Failed to determine if store group selection is enabled.', response);
        })

    this.userDetailForm.get('role').valueChanges
      .subscribe(
        (role : string) => {
          //these fields are set to disabled by default when they are created
          if (this.permissions.roleAllowsUserToSetDeleteAndTransferPrivileges && !this.myUser.hasReadOnlyRestriction && !this.isMyUser) {
            this.userDetailForm.get('hasDeletionPrivilege').enable();
            this.userDetailForm.get('hasTransferPrivilege').enable();
            this.userDetailForm.get('hasDeletionPrivilege').updateValueAndValidity();
            this.userDetailForm.get('hasTransferPrivilege').updateValueAndValidity();
          }

          //this field is set to disabled by default when created
          if (!this.myUser.hasReadOnlyRestriction && !this.isMyUser) {
            this.userDetailForm.get('hasReadOnlyRestriction').enable();
            this.userDetailForm.get('hasReadOnlyRestriction').updateValueAndValidity();
          }


          if (role === 'Admin' || role === 'SuperAdmin' || role === 'Mainframe' || role === 'SuperStoreGroupAdmin') {
            this.showStoreGroupAndStoreSelection = false;
            this.userDetailForm.get('homeStoreGroupId').setValidators([]);
            this.userDetailForm.get('homeStoreGroupId').updateValueAndValidity();
            this.userDetailForm.patchValue({
              homeStoreGroupId: '',
              homeStoreId: '',
            });
          }
          else {
            this.showStoreGroupAndStoreSelection = true;
            this.userDetailForm.get('homeStoreGroupId').setValidators([Validators.required]);
            this.userDetailForm.get('homeStoreGroupId').updateValueAndValidity();
          }

          if (role === 'SuperAdmin') {
            this.userDetailForm.get('hasReadOnlyRestriction').setValue(false);
            this.userDetailForm.get('hasDeletionPrivilege').setValue(true);
            this.userDetailForm.get('hasTransferPrivilege').setValue(true);
            this.userDetailForm.get('hasReadOnlyRestriction').disable();
            this.userDetailForm.get('hasDeletionPrivilege').disable();
            this.userDetailForm.get('hasTransferPrivilege').disable();
            this.userDetailForm.get('hasReadOnlyRestriction').updateValueAndValidity();
            this.userDetailForm.get('hasDeletionPrivilege').updateValueAndValidity();
            this.userDetailForm.get('hasTransferPrivilege').updateValueAndValidity();
          }

          if (role === 'StorePricingViewer' || role === 'StorePricingViewerAndReporter' ||
              role === 'StoreGroupPricingManager' || role === 'StorePricingManager' ) {
            this.userDetailForm.get('hasDeletionPrivilege').setValue(false);
            this.userDetailForm.get('hasTransferPrivilege').setValue(false);
            this.userDetailForm.get('hasDeletionPrivilege').disable();
            this.userDetailForm.get('hasTransferPrivilege').disable();
            this.userDetailForm.get('hasDeletionPrivilege').updateValueAndValidity();
            this.userDetailForm.get('hasTransferPrivilege').updateValueAndValidity();
          }

        })
    
    if(!this.isMyUser && this.myUser.hasReadOnlyRestriction) {
      this.userDetailForm.disable();
      this.passwordForm.disable();
    }
  }

  setConditionalValidation(){
    if (this.isNewAccount) {
      this.userDetailForm.get('password').setValidators([Validators.required]);
      this.userDetailForm.get('password').updateValueAndValidity();
      this.userDetailForm.get('confirmPassword').setValidators([Validators.required]);
      this.userDetailForm.get('confirmPassword').updateValueAndValidity();
    }
    if (this.isMyAccount){
      this.userDetailForm.get('currentPassword').setValidators([Validators.required]);
      this.userDetailForm.get('currentPassword').updateValueAndValidity();
    }
  }

  getIsAdministrationUsersAddUserStoreSelectionEnabled(){
    this._accessService.isAdministrationUsersAddUserStoreSelectionEnabled()
      .subscribe(
        (data:any) => {
          this.isAdministrationUsersAddUserStoreSelectionEnabled = data.value;
          this.getRoles();
        },
        (response) => {
          this._messageService.onFailure('Failed to determine if store selection is enabled.', response);
        })
  }

  getRoles(){
    this._userService.getAllRoles()
      .subscribe(
        (data: any) => {
          this.roles = data;
          this.getStoreGroups();
        },
        (response) => {
          this._messageService.onFailure('Failed to get roles.', response);
        })
  }

  getStoreGroups(){
    this._storeGroupService.getAllStoreGroups()
      .subscribe(
        (data:any) => {
          this.storeGroups = data;
          if (this.isMyAccount) { //my account
            this.getMyUserFromServer();
          }
          else if (!this.isNewAccount) { //editing existing account
            this.getUser(this.userId);
          }
          else{ //new account
            this.showStoreGroupAndStoreSelection = true;
            // TODO??? get currently logged in user to be able to set homeStoreGroupId and homeStoreId to the logged in user's homeStoreGroupId and homeStoreId
          }
        },
        (response) => {
          this._messageService.onFailure('Failed to get Store Groups.', response);
        })
  }

  getMyUserFromServer(){
    this._userService.getMyUser()
      .subscribe(
        (data:any) => {
          this.doAfterGettingUser(data);
        },
        (response) => {
          this._messageService.onFailure('Failed to get your user account.', response);
        })
  }

  getUser(userId){
    this._userService.getUserById(userId)
      .subscribe(
        (data:any) => {
          this.doAfterGettingUser(data);
        },
        (response) => {
          this._messageService.onFailure('Failed to get user account.', response);
        })
  }

  doAfterGettingUser(data){
    this.user = data;
    if (this.config.logIds) console.log(`%cuserName: ${data.userName}`, 'background: black; color: white; font-weight: bold; font-size: 120%');
    if (this.config.logIds) console.log(`%cuserId: ${data.userId}`, 'background: yellow; font-weight: bold; font-size: 120%');
    //if (this.itemFilters.length == 0) this.getOwnedItemFilters(this.user.userId);
    //if (this.itemLists.length == 0) this.getOwnedItemLists(this.user.userId);
    this.showStoreGroupAndStoreSelection = !(data.roles[0] === 'Admin' || data.roles[0] === 'SuperAdmin' || data.roles[0] === 'Mainframe' || data.roles[0] === 'SuperStoreGroupAdmin');
    if (this.showStoreGroupAndStoreSelection) {
      this.selectedHomeStoreGroupId = data.homeStoreGroupId;
      this.homeStoreGroupRequired = true;
    }
    this.userDetailForm.patchValue({
      active: data.active,
      hasReadOnlyRestriction: data.hasReadOnlyRestriction,
      hasDeletionPrivilege: data.hasDeletionPrivilege,
      hasTransferPrivilege: data.hasTransferPrivilege,
      role: data.roles[0],
      userName: data.userName,
      firstName: data.firstName,
      lastName: data.lastName,
      phoneNumber: data.phoneNumber,
      managedStoreGroupIds: data.managedStoreGroupIds,
      homeStoreGroupId: data.homeStoreGroupId,
    });
    this.lastLoginDate = formatDate(data.lastLoginDate, 'MM/dd/yyyy h:mm:ss a', this.locale);
    if (data.homeStoreGroupId > 0) { 
      this.getAllStoresByStoreGroupId(data.homeStoreGroupId);
    }
  }

  getAllStoresByStoreGroupId(storeGroupId) {
    this.homeStoreRequired = false;
    this._storeService.getAllStoresByStoreGroupId(storeGroupId)
      .subscribe(
        (data:any) => {
          this.stores = data;
          var found = false;
          if (!this.isNewAccount){ //new accounts won't have user info
            for (var i=0; i<this.stores.length; i++){
              if (this.stores[i].storeId === this.user.homeStoreId) found=true;
            }
          }
          this.userDetailForm.patchValue({
            homeStoreId: found ? this.user.homeStoreId : '',
          });
        },
        (response) => {
          this._messageService.onFailure('Failed to get Stores from Store Group.', response);
        })
  }

  onSubmitUser(){
    var user = this.userDetailForm.getRawValue();
    user.roleNamesToApply = [user.role]; //array expected in model
    user.email = user.userName; //email expected in model
    user.managedStoreGroupIdsToApply = (this.userDetailForm.get('role').value === 'SuperStoreGroupAdmin') ? user.managedStoreGroupIds : [];
    if (this.userDetailForm.get('role').value === 'SuperStoreGroupAdmin') {
      user.homeStoreGroupId = '';
      user.homeStoreId = '';
    }
    if (this.userDetailForm.get('role').value === 'SuperAdmin') {
      //makes sure these can't be set to false for super admins
      //value is set on back end to true
      delete user.hasDeletionPrivilege;
      delete user.hasTransferPrivilege;
      //makes sure this can't be set to true for super admins
      //value is set on back end to false
      delete user.hasReadOnlyRestriction;
    }
    if (this.isMyAccount){
      //makes sure a user can't elevate his own privileges
      delete user.hasReadOnlyRestriction;
      delete user.hasDeletionPrivilege;
      delete user.hasTransferPrivilege;
      this._userService.updateMyUser(user)
      .subscribe(
        (data) => {
          this.dialogRef.close('reload');
          this._messageService.onSuccess('Successfully updated your user account');
        },
        (response) => {
          this._messageService.onFailure('Failed to update your user account.', response)
        }
      );
    }
    else if (this.isNewAccount) {
      //Users with hasReadOnlyRestriction set to true should not even be allowed to invoke this action because it is hidden or disabled in the UI.
      //This is for extra protection. The back end will also be protected.
      if (this.myUser.hasReadOnlyRestriction){
        this._messageService.alertReadOnlyRestriction();
        return;
      }

      this._userService.createUser(user)
      .subscribe(
        (data) => {
          this.dialogRef.close('reload');
          this._messageService.onSuccess('Successfully created user account');
          this._router.navigate(['/admin/users']);
        },
        (response) => {
          this._messageService.onFailure('Failed to create user account.', response)
        }
      );
    }
    else {
      //Users with hasReadOnlyRestriction set to true should not even be allowed to invoke this action because it is hidden or disabled in the UI.
      //This is for extra protection. The back end will also be protected.
      if (this.myUser.hasReadOnlyRestriction){
        this._messageService.alertReadOnlyRestriction();
        return;
      }
      
      this._userService.updateUser(this.userId, user)
      .subscribe(
        (data) => {
          this.dialogRef.close('reload');
          this._messageService.onSuccess('Successfully updated user account');
          this._router.navigate(['/admin/users']);
        },
        (response) => {
          this._messageService.onFailure('Failed to update user account.', response)
        }
      );
    }
  }

  onSubmitPassword(){
    if (this.isMyAccount){
      this._userService.changeMyPassword({ 'currentPassword': this.passwordForm.get('currentPassword').value, 'newPassword': this.passwordForm.get('password').value, 'confirmPassword': this.passwordForm.get('confirmPassword').value })
        .subscribe(
          (data) => {
            this._messageService.onSuccess('Successfully changed your password');
            this.dialogRef.close();
          },
          (response) => {
            this._messageService.onFailure('Failed to change your password.', response)
          }
        );
    }
    else{
      this._userService.setPassword(this.user.userId, { 'newPassword': this.passwordForm.get('password').value, 'confirmPassword': this.passwordForm.get('confirmPassword').value })
        .subscribe(
          (data) => {
            this._messageService.onSuccess('Successfully set password');
            this.dialogRef.close();
          },
          (response) => {
            this._messageService.onFailure('Failed to set password.', response)
          }
        );
    }
  }

  onTabChange($event){
    // if ($event.index == 2) {
    //   if (!this.itemFilters) this.getOwnedItemFilters(this.user.userId);
    //   if (!this.itemLists) this.getOwnedItemLists(this.user.userId);
    // }
  }

  // getOwnedItemFilters(userId){
  //   this._itemFilterService.getOwnedItemFilters(userId)
  //     .subscribe(
  //       (data:any) => {
  //         this.itemFilters = data;
  //         switch(data.length){
  //           case 1: this.itemFilterDescription = '1 Item Filter is owned by this user';
  //             break;
  //           default: this.itemFilterDescription = data.length + ' Item Filters are owned by this user';
  //             break
  //         }
  //       },
  //       (response) => {
  //         this._messageService.onFailure('Failed to get owned Item Filters.', response);
  //       })
  // }

  // getOwnedItemLists(userId){
  //   this._itemListService.getOwnedItemLists(userId)
  //     .subscribe(
  //       (data:any) => {
  //         this.itemLists = data;
  //         switch(data.length){
  //           case 1: this.itemListDescription = '1 Item List is owned by this user';
  //             break;
  //           default: this.itemListDescription = data.length + ' Item Lists are owned by this user';
  //             break
  //         }
  //       },
  //       (response) => {
  //         this._messageService.onFailure('Failed to get Item Lists.', response);
  //       })
  // }

  onOpenTransferDialog = new EventEmitter();

  onTransferButtonClick(){
    this.closeDialog();
    this.onOpenTransferDialog.emit(this.params);
  }

  // onTransferButtonClick() {
  //   this.openTransferDialog();
  //   // this._transferService.doesUserHaveTransferableAssets(this.user.userId)
  //   //   .subscribe(
  //   //     (data: any) => { //returns boolean
  //   //       if (data) { 
  //   //         this.openTransferDialog(); 
  //   //       }
  //   //       else{
  //   //         this._messageService.alert('This user does not have any transferable assets.');
  //   //       }
  //   //     },
  //   //     (response) => {
  //   //       this._messageService.onFailure('Failed to determine if this user has transferable assets.', response);
  //   //     }
  //   //   )
  // }

  // openTransferDialog() {
  //   this.transferDialogRef = this._dialog.open(UserTransferDialogComponent, {
  //     disableClose: false,
  //     minWidth: '700px',
  //     data: {
  //       entityType: 'User',
  //       entityName: this.user.userName,
  //       donatingEntityId: this.user.userId,
  //       users: this.users,
  //       storeGroups: this.storeGroups,
  //       storeGroupId: this.user.homeStoreGroupId,
  //       homeStoreId: this.user.homeStoreId,
  //     }
  //   })
  //   this.transferDialogRef.beforeClosed().subscribe(result => {
  //     //console.log(this.transferDialogRef);
  //     this.transferOccurred = this.transferDialogRef.componentInstance.transferOccurred;
  //     if (this.transferOccurred) { 
  //       this.doOnInit();
  //       this.getOwnedItemFilters(this.user.userId);
  //       this.getOwnedItemLists(this.user.userId);  
  //     }
  //     this.transferDialogRef = null;
  //   });

  // }

  onAssetCountEvent(val){
    this.assetCount = val;
  }

  closeDialog(){
    this.dialogRef.close(this.transferOccurred);
  }

  compareFn(v1, v2): boolean {
    return v1 === v2;
  }

  //custom password validator came from this video
  //https://www.youtube.com/watch?v=dlVVYmntDnE&list=PLC3y8-rFHvwhwL-XH04cHOpJnkgRKykFi&index=24
  passwordValidator(control: AbstractControl): {[key: string]:boolean} | null {
    const password = control.get('password');
    const confirmPassword = control.get('confirmPassword');
    //it did not work with angular material, so this post solved that issue
    //https://stackoverflow.com/questions/52480822/angular-material-mat-error-not-showing-despite-true-methods
    var misMatch = password && confirmPassword && password.value !== confirmPassword.value;
    if (misMatch) {
      confirmPassword.setErrors({
        misMatch: true
      });
    } else {
      confirmPassword.setErrors(null);
    }
    return password && confirmPassword && password.value !== confirmPassword.value ? {'misMatch': true} : null;
  }


}
