import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { ItemFilterService } from 'src/app/shared/item-filter.service';
import { MessageService } from 'src/app/shared/message.service';
import { PriceManagementFilterService } from '../price-management-filter.service';
import { AccessService } from 'src/app/shared/access.service';
import { PermissionsService } from 'src/app/shared/permissions.service';
import { SpinnerService } from 'src/app/shared/spinner.service';
import { AuthenticationService } from 'src/app/core/authentication/shared/authentication.service';

@Component({
  selector: 'rpms-price-management-item-filter',
  templateUrl: './price-management-item-filter.component.html',
  styleUrls: ['./price-management-item-filter.component.css']
})
export class PriceManagementItemFilterComponent implements OnInit {
  @Output() loadedItemFiltersEvent = new EventEmitter<any>();

  itemFilters;
  itemFilter: any; // = {itemFilterId: null};

  isShowingForm:boolean = false;

  itemFilterName;
  sharedWith;
  modifiedBy;
  description;
  isItemFilterModifiable;
  canItemFilterSharedWithAndOrModifiedByPropertiesBeChanged;
  itemFilterSelectOptions = ['Everyone', 'AllUsersManagedByMe', 'NoOne'];
  updatedOrSavedItemFilter:any = {};
  permissions:any = {};
  myUser;

  constructor(
    private _itemFilterService: ItemFilterService,
    private _accessService: AccessService,
    private _priceManagementFilterService: PriceManagementFilterService,
    private _permissionsService: PermissionsService,
    private _authenticationService: AuthenticationService,
    private _messageService: MessageService,
    private _spinnerService: SpinnerService,
  ) { }

  ngOnInit() {
    this.permissions = this._permissionsService.getPermissions();
    this.myUser = this._authenticationService.getMyUser();
    //this.getActiveSharedItemFilters();
  }

  getActiveSharedItemFilters(updating?){
    this._spinnerService.handleProgressSpinnerVisibility('show', 'Loading Item Filters...');
    this._itemFilterService.getActiveSharedItemFilters()
      .subscribe(
        (data) => {
          this.itemFilters = data;
          //if (Object.keys(this.updatedOrSavedItemFilter).length > 0 && this.updatedOrSavedItemFilter.constructor === Object){
          if (!this.isEmpty(this.updatedOrSavedItemFilter)){
            this.itemFilter = this.updatedOrSavedItemFilter;
          }
          //getActiveSharedItemFilters() is called from price mangement component to get all dependencies on page load
          //this event sends back to the price management component so that it can continue to load the rest of the page dependencies
          //don't do this when saving new or updating item filters.  (send the updating argument as true)
          if (!updating) this.loadedItemFiltersEvent.next(true); 
        },
        (response) => {
          this._messageService.onFailure('Failed to get Item Filters.', response);
        }
      )
  }

  onItemFilterSelected(){
    this.isShowingForm = false;
    //console.log(this.itemFilter);
    var tabModel = {
      activeTab: this.itemFilter.priceManagementTab,
      activeSubTab: this.itemFilter.priceManagementSubTab,
    }
    this._priceManagementFilterService.setGridFilterModel(this.itemFilter.itemFilterMetadata);
    this._priceManagementFilterService.setGridFilterModelFromTabModel(tabModel);
    //TODO - Find a way to only unset this if an item filter has been modified.
    this._priceManagementFilterService.setNameOfItemFilterToApplyAfterLoadingItems('');
    //this._priceManagementFilterService.setNameOfItemFilterToApplyAfterLoadingItems(this.itemFilter.name);
  }

  clearItemFilter(){
    this.itemFilter = {itemFilterId: null};
    this._priceManagementFilterService.setNameOfItemFilterToApplyAfterLoadingItems('');
  }

  selectItemFilterByName(name){
    for (var i=0; i < this.itemFilters.length; i++){
      if (this.itemFilters[i].name === name) {
        this.itemFilter = this.itemFilters[i];
        this.onItemFilterSelected();
      }
    }
  }

  showFilterSaveDialog(){
    var that = this;
    this.isShowingForm = !this.isShowingForm;
    if (typeof(this.itemFilter) != 'undefined' && this.itemFilter.itemFilterId != null){
      that.getIsItemFilterModifiable(function (){
        that.getCanItemFilterSharedWithAndOrModifiedByPropertiesBeChanged(function(){
          that.itemFilterName = that.itemFilter.name;
          that.description = that.itemFilter.description;
          that.sharedWith = that.itemFilter.sharedWith;
          that.modifiedBy = that.itemFilter.modifiedBy;
        });
      });
    }
    else{
				//creating a new item filter
				this.isItemFilterModifiable = true;
				this.canItemFilterSharedWithAndOrModifiedByPropertiesBeChanged = true;
				//this.itemFilterForm.$setPristine(); //removes validation errors
				//this.itemFilterForm.$setUntouched(); //removes validation errors
				this.itemFilterName = '';
				this.description = '';
				this.sharedWith = 'NoOne';
        this.modifiedBy = 'NoOne';
    }

  }

  getIsItemFilterModifiable(callback?){
    this._accessService.isItemFilterModifiable(this.itemFilter.itemFilterId)
      .subscribe(
        (data:any) => {
          this.isItemFilterModifiable = data.value;
          if (typeof(callback) === 'function') callback();
        },
        (response) => {
          this._messageService.onFailure('Failed to determine if Item Filter is modifiable.', response);
        }
      )
  }

  getCanItemFilterSharedWithAndOrModifiedByPropertiesBeChanged(callback?){
    this._accessService.canItemFilterSharedWithAndOrModifiedByPropertiesBeChanged(this.itemFilter.itemFilterId)
      .subscribe(
        (data:any) => {
          this.canItemFilterSharedWithAndOrModifiedByPropertiesBeChanged = data.value;
          if (typeof(callback) === 'function') callback();
        },
        (response) => {
          this._messageService.onFailure('Failed to determine if Item Filter properties can be changed.', response);
        }
      )
  }

  cancel(){
    this.isShowingForm = false;
  }

  onSaveItemFilter(){
    //Users with hasReadOnlyRestriction set to true should not even be allowed to invoke this action because it is hidden or disabled in the UI.
    //This is for extra protection. The back end will also be protected.
    if (this.myUser.hasReadOnlyRestriction){
      this._messageService.alertReadOnlyRestriction();
      return;
    }

    var currentItemFilterId;
    this.updatedOrSavedItemFilter = {};
    var filterModel = this._priceManagementFilterService.getGridFilterModel();
    var tabModel = this._priceManagementFilterService.getTabModel();
    var itemFilterModel:any = {};
    itemFilterModel.name = this.itemFilterName;
    itemFilterModel.active = true;
    itemFilterModel.description = this.description;
    itemFilterModel.itemFilterMetadata = JSON.stringify(filterModel);
    itemFilterModel.sharedWith = this.sharedWith;
    itemFilterModel.modifiedBy = this.modifiedBy;
    itemFilterModel.priceManagementTab = tabModel.activeTab;
    itemFilterModel.priceManagementSubTab = tabModel.activeSubTab;

    //See if the name already exists
    for (var i = 0; i < this.itemFilters.length; i++) {
      if (this.itemFilters[i].name.toLowerCase() == this.itemFilterName.toLowerCase()) {
        currentItemFilterId = this.itemFilters[i].itemFilterId;
        break;
      }
    }

    if (currentItemFilterId != null && currentItemFilterId != this.itemFilter.itemFilterId) {
      //the name already exists but it is not the currently selected item filter because they have changed the name to another existing item filter
      this._messageService.alert('There is already an item filter named "' + this.itemFilterName + '".  Please choose another name.', 'Attention');
    }
    else if (typeof this.itemFilter != 'undefined' && this.itemFilter.itemFilterId != null) {
      //the selected item filter needs updating
      this._itemFilterService.updateItemFilter(this.itemFilter.itemFilterId, itemFilterModel)
        .subscribe(
          (data:any) => {
            this._messageService.onSuccess("Successfully updated item filter.");
            this.isShowingForm = false;
            //update doesn't return itemFilter object
            this.updatedOrSavedItemFilter = itemFilterModel;
            this.updatedOrSavedItemFilter.itemFilterId = this.itemFilter.itemFilterId;
            this.getActiveSharedItemFilters(true); //true = updating
          },
          (response) => {
            this._messageService.onFailure('Failed to update item filter.', response);
          }
        )
    }
    else {
      // create a new item filter
      this._itemFilterService.createItemFilter(itemFilterModel)
        .subscribe(
          (data:any) => {
            this._messageService.onSuccess("Successfully saved item filter.");
            this.isShowingForm = false;
            this.updatedOrSavedItemFilter = data;
            this.getActiveSharedItemFilters(true); //true = updating
          },
          (response) => {
            this._messageService.onFailure('Failed to save item filter.', response);
          }
        )
    }
  }

  addSpacesBetweenWords = function (v) {
    if (v != null && v != '') {
      var valueWithSpaces = v.replace(/([A-Z])/g, ' $1');
      valueWithSpaces = valueWithSpaces.substr(1); //remove space before first word
      return valueWithSpaces;
    }
  };

  compareItemFilterObjects(o1: any, o2: any): boolean {
    return o1 && o2 ? o1.itemFilterId === o2.itemFilterId : false;
  }

  isEmpty(obj) {
    var hasOwnProperty = Object.prototype.hasOwnProperty;
      if (obj == null) return true;
      if (obj.length > 0)    return false;
      if (obj.length === 0)  return true;
      if (typeof obj !== "object") return true;
      for (var key in obj) {
          if (hasOwnProperty.call(obj, key)) return false;
      }
      return true;
  }

}
