<div class="content cf">
  <div *ngIf="permissions.roleAllowsUserToViewAdminUsers" class="tile-wrapper" routerLink="/admin/users">
    <mat-card appearance="outlined">
      <i class="fa fa-user"></i>
      <div class="card-content-wrapper">
        <h2>User Accounts</h2>
        <mat-card-content>
          <p>Manage your user account and other user accounts managed by you.</p>
        </mat-card-content>
      </div>
      <mat-card-footer class="right-justified">
        <button mat-raised-button color="primary">Manage</button>
      </mat-card-footer>

    </mat-card>
  </div>
  <div *ngIf="permissions.roleAllowsUserToViewAdminStores" class="tile-wrapper" routerLink="/admin/stores">
    <mat-card appearance="outlined">
      <i class="fa fa-map-marker-alt"></i>
      <div class="card-content-wrapper">
        <h2>Stores</h2>
        <mat-card-content>
          <p>Manage stores.</p>
        </mat-card-content>
      </div>
      <mat-card-footer class="right-justified">
        <button mat-raised-button color="primary">Manage</button>
      </mat-card-footer>
    </mat-card>
  </div>
  <div *ngIf="permissions.roleAllowsUserToViewAdminStores" class="tile-wrapper" routerLink="/admin/store-groups">
    <mat-card appearance="outlined">
      <i class="far fa-map-marker-alt"></i>
      <div class="card-content-wrapper">
        <h2>Store Groups</h2>
        <mat-card-content>
          <p>Manage store groups.</p>
        </mat-card-content>
      </div>
      <mat-card-footer class="right-justified">
        <button mat-raised-button color="primary">Manage</button>
      </mat-card-footer>
    </mat-card>
  </div>
  <div *ngIf="permissions.roleAllowsUserToViewAdminSchedule" class="tile-wrapper" routerLink="/admin/schedule">
    <mat-card appearance="outlined">
      <i class="far fa-calendar-alt"></i>
      <div class="card-content-wrapper">
        <h2>Schedule</h2>
        <mat-card-content>
          <p>Manage windows of time for specific tasks.</p>
        </mat-card-content>
      </div>
      <mat-card-footer class="right-justified">
        <button mat-raised-button color="primary">Manage</button>
      </mat-card-footer>
    </mat-card>
  </div>
  <div *ngIf="permissions.roleAllowsUserToViewAdminItemFilters" class="tile-wrapper" routerLink="/admin/item-filters">
    <mat-card appearance="outlined">
      <i class="fa fa-filter"></i>
      <div class="card-content-wrapper">
        <h2>Item Filters</h2>
        <mat-card-content>
          <p>Manage saved filters used in the price management grid.</p>
        </mat-card-content>
      </div>
      <mat-card-footer class="right-justified">
        <button mat-raised-button color="primary">Manage</button>
      </mat-card-footer>
    </mat-card>
  </div>
  <div *ngIf="permissions.roleAllowsUserToViewAdminItemLists" class="tile-wrapper" routerLink="/admin/item-lists">
    <mat-card appearance="outlined">
      <i class="fa fa-list-ul"></i>
      <div class="card-content-wrapper">
        <h2>Item Lists</h2>
        <mat-card-content>
          <p>Manage item lists used in the price management grid.</p>
        </mat-card-content>
      </div>
      <mat-card-footer class="right-justified">
        <button mat-raised-button color="primary">Manage</button>
      </mat-card-footer>
    </mat-card>
  </div>
  <div *ngIf="permissions.roleAllowsUserToViewAdminTagOrders" class="tile-wrapper" routerLink="/admin/shelf-tag-orders">
    <mat-card appearance="outlined">
      <i class="fas fa-tags"></i>
      <div class="card-content-wrapper">
        <h2>Shelf Tag Orders</h2>
        <mat-card-content>
          <p>View pending Shelf Tag orders for a specific store.</p>
        </mat-card-content>
      </div>
      <mat-card-footer class="right-justified">
        <button mat-raised-button color="primary">Manage</button>
      </mat-card-footer>
    </mat-card>
  </div>
  <div *ngIf="permissions.roleAllowsUserToViewAdminTagOrders" class="tile-wrapper" routerLink="/admin/tpr-tag-orders">
    <mat-card appearance="outlined">
      <i class="far fa-tags"></i>
      <div class="card-content-wrapper">
        <h2>Compare At Tag Orders</h2>
        <mat-card-content>
          <p>View pending Compare At Tag orders for a specific store.</p>
        </mat-card-content>
      </div>
      <mat-card-footer class="right-justified">
        <button mat-raised-button color="primary">Manage</button>
      </mat-card-footer>
    </mat-card>
  </div>
  <div *ngIf="permissions.roleAllowsUserToViewAdminPosExport" class="tile-wrapper" routerLink="/admin/pos-export">
    <mat-card appearance="outlined">
      <i class="fa fa-download"></i>
      <div class="card-content-wrapper">
        <h2>POS Export</h2>
        <mat-card-content>
          <p>Configure settings for POS Export.</p>
        </mat-card-content>
      </div>
      <mat-card-footer class="right-justified">
        <button mat-raised-button color="primary">Manage</button>
      </mat-card-footer>
    </mat-card>
  </div>
  <div *ngIf="permissions.roleAllowsUserToViewAdminPricingRules" class="tile-wrapper" routerLink="/admin/pricing-rules">
    <mat-card appearance="outlined">
      <i class="fa fa-usd-circle"></i>
      <div class="card-content-wrapper">
        <h2>Pricing Rules</h2>
        <mat-card-content>
          <p>Configure settings for Pricing Rules.</p>
        </mat-card-content>
      </div>
      <mat-card-footer class="right-justified">
        <button mat-raised-button color="primary">Manage</button>
      </mat-card-footer>
    </mat-card>
  </div>
  <div *ngIf="permissions.roleAllowsUserToViewAdminPrivateLabels" class="tile-wrapper" routerLink="/admin/private-labels">
    <mat-card appearance="outlined">
      <i class="fa fa-flag"></i>
      <div class="card-content-wrapper">
        <h2>Private Labels</h2>
        <mat-card-content>
          <p>Configure settings for Private Labels.</p>
        </mat-card-content>
      </div>
      <mat-card-footer class="right-justified">
        <button mat-raised-button color="primary">Manage</button>
      </mat-card-footer>
    </mat-card>
  </div>
  <div class="tile-wrapper" routerLink="/admin/announcements">
    <mat-card appearance="outlined">
      <i class="fas fa-bullhorn"></i>
      <div class="card-content-wrapper">
        <h2>Announcements</h2>
        <mat-card-content>
          <p>Homepage announcements.</p>
        </mat-card-content>
      </div>
      <mat-card-footer class="right-justified">
        <button mat-raised-button color="primary">Manage</button>
      </mat-card-footer>
    </mat-card>
  </div>
  <div class="tile-wrapper" routerLink="/admin/application">
    <mat-card appearance="outlined">
      <i class="fa fa-wrench"></i>
      <div class="card-content-wrapper">
        <h2>Application</h2>
        <mat-card-content>
          <p>Global application settings.</p>
        </mat-card-content>
      </div>
      <mat-card-footer class="right-justified">
        <button mat-raised-button color="primary">Manage</button>
      </mat-card-footer>
    </mat-card>
  </div>
</div>