<h1><i class="fa fa-list-ul"></i>Group Headers</h1>
<div class="table-toolbar">
    <div class="filter-tools">
        <button (click)="clearFilters()" mat-raised-button>Clear Filters</button>
        <input id="quick-filter" class="quick-filter" placeholder="Quick Filter..." type="text" [(ngModel)]="quickFilterText" (keyup)="onQuickFilterChange()" />
    </div>
    <div class="column-tools">
        Columns: &nbsp;
        <button (click)="resetColumns()" mat-raised-button>Reset Defaults</button>
        <button (click)="fitColumns()" mat-raised-button>Fit to Width</button>
        <button (click)="autoSizeColumns()" mat-raised-button>Autosize</button>
    </div>
</div>
<ag-grid-angular #agGrid 
    [gridOptions]="gridOptions"
    style="width: 100%; height: 1px;" 
    class="ag-theme-balham"
    id="ag-grid-wrapper"
    [rowData]="rowData" 
    [columnDefs]="columnDefs"
    [context]="context"
    [quickFilterText]="quickFilterText"
    >
</ag-grid-angular>
