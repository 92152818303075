import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { AppConfig } from '../app.config';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  private _baseUrl = AppConfig.settings.baseUrl;
  private _reqHeader = new HttpHeaders({ 'Accept': 'application/json', 'Content-Type': 'application/json' });

  constructor ( 
    private _http: HttpClient, 
  ){}

  getNowCostChanges() {
    return this._http.get(this._baseUrl + '/api/Notifications/GetNowCostChanges', { headers: this._reqHeader });
  }

  getNowCostChangesCount() {
    return this._http.get(this._baseUrl + '/api/Notifications/GetNowCostChangesCount', { headers: this._reqHeader });
  }

  getFirstTimeBuys() {
    return this._http.get(this._baseUrl + '/api/Notifications/GetFirstTimeBuys', { headers: this._reqHeader });
  }

  getFirstTimeBuysCount() {
    return this._http.get(this._baseUrl + '/api/Notifications/GetFirstTimeBuysCount', { headers: this._reqHeader });
  }
  
  getPreviewStoreItemPriceChangeNotificationsSinceLastUpdateFromPreview() {
    return this._http.get(this._baseUrl + '/api/Notifications/GetPreviewStoreItemPriceChangeNotificationsSinceLastUpdateFromPreview', { headers: this._reqHeader });
  }
  
  getMeatAndProducePreviewStoreItemPriceChangeNotificationsSinceLastUpdateFromPreview(params?) {
    return this._http.get(this._baseUrl + '/api/Notifications/GetMeatAndProducePreviewStoreItemPriceChangeNotificationsSinceLastUpdateFromPreview', { params: params, headers: this._reqHeader });
  }
  
  getStoreItemPriceChangeNotificationsSinceLastUpdateFromPreview() {
    return this._http.get(this._baseUrl + '/api/Notifications/GetStoreItemPriceChangeNotificationsSinceLastUpdateFromPreview', { headers: this._reqHeader });
  }
  
  getStoreItemPriceChangeNotificationsByStoreIdSinceLastUpdateFromPreview(storeId) {
    return this._http.get(this._baseUrl + '/api/Notifications/GetStoreItemPriceChangeNotificationsByStoreIdSinceLastUpdateFromPreview/' + storeId, { headers: this._reqHeader });
  }
  
  getStoreItemPriceChangesByStoreIdWhereStoreItemEditedSinceLastUpdateFromPreview(storeId) {
    return this._http.get(this._baseUrl + '/api/Notifications/GetStoreItemPriceChangesByStoreIdWhereStoreItemEditedSinceLastUpdateFromPreview/' + storeId, { headers: this._reqHeader });
  }

}
