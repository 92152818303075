import { Component, OnInit, ViewChild } from '@angular/core';
import { AgGridAngular } from 'ag-grid-angular';
import { GridOptions } from 'ag-grid-community';
import { PosExportService } from 'src/app/shared/pos-export.service';
import { GridService } from 'src/app/shared/grid.service';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { MessageService } from 'src/app/shared/message.service';
import { PermissionsService } from 'src/app/shared/permissions.service';
import { FileUploader } from 'ng2-file-upload';
import { SpinnerService } from 'src/app/shared/spinner.service';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from 'src/app/shared/confirmation-dialog/confirmation-dialog.component';
import { AppConfig } from 'src/app/app.config';
import { AuthenticationService } from 'src/app/core/authentication/shared/authentication.service';

@Component({
  selector: 'app-pos-export-department-associations',
  templateUrl: './pos-export-department-associations.component.html',
  styleUrls: ['./pos-export-department-associations.component.css']
})
export class PosExportDepartmentAssociationsComponent implements OnInit {
  @ViewChild('agGrid') agGrid: AgGridAngular;

  confirmationDialogRef: MatDialogRef<ConfirmationDialogComponent>;

  url;
  private _baseUrl = AppConfig.settings.baseUrl;
  public uploader;

  gridOptions: GridOptions;
  quickFilterText: string = '';
  context; //required by grid
  //frameworkComponents; //required by grid

  storeName;
  departmentAssociations;
  orderHeaders;
  storePosExportSettingsId;
  store: any = {};
  rowToUpdate;
  permissions: any = {};
  doesIncludeHeaderRow: boolean = false;
  isHelpVisible:boolean = false;
  fileHasBeenAddedToQueue:boolean = false;
  myUser;

  rowData = [];
  getColumnDefs() {
    return [
			{
				headerName: "Order Header Group #",
				field: "orderHeaderGroupNumber",
				width: 100,
				cellClass: "orderHeader-col",
				filter: "agTextColumnFilter",
				filterParams: {
					defaultOption: "startsWith"
        },
        get headerTooltip() { return this.headerName; }
			},
			{
				headerName: "Order Header Group Description",
				field: "orderHeaderGroupDescription",
				width: 300,
				cellClass: "orderHeader-col",
        filter: "agTextColumnFilter",
        get headerTooltip() { return this.headerName; }
			},
			{
				headerName: "Dept #",
				field: "departmentNumber",
				cellClass: "departmentNumber-col",
				width: 300,
				editable: this.permissions.roleAllowsUserToEditPosExportDeptAsscs && !this.myUser.hasReadOnlyRestriction,
				filter: "agTextColumnFilter",
				filterParams: {
					defaultOption: "startsWith"
        },
        get headerTooltip() { return this.headerName; }
			},
			{
				headerName: "Created Date",
				field: "departmentAssociationCreatedDate",
				cellClass: "createdDate-col",
				cellRenderer: this._gridService.dateTimeRenderer,
				filter: "agDateColumnFilter",
				filterParams: {
					comparator: this._gridService.dateComparator,
					inRangeInclusive: true
        },
        get headerTooltip() { return this.headerName; }
			},
			{
				headerName: "Modified Date",
				field: "departmentAssociationModifiedDate",
				cellClass: "modifiedDate-col",
				cellRenderer: this._gridService.dateTimeRenderer,
				filter: "agDateColumnFilter",
				filterParams: {
					comparator: this._gridService.dateComparator,
					inRangeInclusive: true
        },
        get headerTooltip() { return this.headerName; }
			},
    ];
  }

  constructor(
    private _posExportService: PosExportService,
    private _gridService: GridService,
    private _activatedRoute: ActivatedRoute,
    private _messageService: MessageService,
    private _authenticationService: AuthenticationService,
    private _permissionsService: PermissionsService,
    private _spinnerService: SpinnerService,
    private _router: Router,
    private _dialog: MatDialog, 
  ) { 
    this.myUser = this._authenticationService.getMyUser();
    this.permissions = this._permissionsService.getPermissions();
    this.gridOptions = <GridOptions> {
      columnDefs: this.getColumnDefs(),
      rowData: this.rowData,
      rowHeight: 35,
      onCellEditingStopped: this.doOnCellEditingStopped, 
      defaultColDef: {
        sortable: true,
        resizable: true,
        floatingFilter: true,
      }
    }
    this.context = { componentParent: this }

  }

  ngOnInit() {
    var self = this;
    this._activatedRoute.paramMap.subscribe((params: ParamMap) => {
      this.storePosExportSettingsId = params.get('id');

      this.uploader = new FileUploader({
        url: this._baseUrl + "/api/StorePosExportDeptAsscs/CreateStorePosExportDeptAsscsFromCsv/" + self.storePosExportSettingsId + '/' + self.doesIncludeHeaderRow,
        filters: [{
          name: 'isCSV',
          // A user-defined filter
          fn: function (item) {
            //console.log(item);
            //if (item.type === "application/vnd.ms-excel") {
            //the line above was changed to the line below on 6/22/2022
            //I don't know why it was set to application/vnd.ms-excel and not text/csv
            if (item.type === "text/csv") {
              return true;
            }
            else {
              self._messageService.alert('That is not a valid CSV file.');
              self._spinnerService.handleProgressSpinnerVisibility('hide', 'Processing CSV file...');
              return false;
            }
          }
        }],
        queueLimit: 1,
        authToken: 'Bearer ' + sessionStorage.getItem('rpmsToken'),
      });
      this.uploader.onSuccessItem = (item, response, status, headers) => {
        self._messageService.onSuccess('Successfully uploaded CSV file.');
        self._spinnerService.handleProgressSpinnerVisibility('hide', 'Processing Request...');
        self.uploader.clearQueue();
        self.uploader.cancelAll();
        self.getStorePosExportSettingsById();
      }
      this.uploader.onErrorItem = (item, response, status, headers) => {
        self._spinnerService.handleProgressSpinnerVisibility('hide', 'Processing Request...');
        let res = JSON.parse(response);
        if (res.modelState){
          self._messageService.alert(res.modelState.file[0], 'Failed to upload CSV file.');
        }
        else{
          self._messageService.alert(res.message, 'Failed to upload CSV file.');
        }
      }
      this.uploader.onProgressItem = (file:any, progress:any) => {
        self._spinnerService.handleProgressSpinnerVisibility('show', 'Processing CSV file...');
      }
      this.uploader.onAfterAddingFile = (file:any) => {
        file.withCredentials = false; 
        self._spinnerService.handleProgressSpinnerVisibility('hide');
      }
      this.uploader.onBeforeUploadItem = (file:any) => {
        self._spinnerService.handleProgressSpinnerVisibility('show', 'Processing CSV file...');
      }
      this.getStorePosExportSettingsById();
    });
  }

  getStorePosExportSettingsById(){
    this._posExportService.getStorePosExportSettingsById(this.storePosExportSettingsId)
      .subscribe(
        (data:any) => {
          this.store = data.store;
          this.getAllOrderHeaders();
        },
        (response) => {
          this._messageService.onFailure('Failed to get Export Settings.', response);
        })
  }

  getAllOrderHeaders(){
    this._posExportService.getAllOrderHeaders()
      .subscribe(
        (data:any) => {
          this.orderHeaders = data;
          this.getAllStorePosExportDeptAsscsByStoreId();
        },
        (response) => {
          this._messageService.onFailure('Failed to get Order Headers.', response);
        })
  }

  getAllStorePosExportDeptAsscsByStoreId(){
    this._posExportService.getAllStorePosExportDeptAsscsByStoreId(this.store.storeId)
      .subscribe(
        (data:any) => {
          this.departmentAssociations = data;
          this.formatandSetRowData(this.orderHeaders); //note: don't format returned data
        },
        (response) => {
          this._messageService.onFailure('Failed to get Department Associations.', response);
        })
  }

  formatandSetRowData(data) {
    var gridData = [];
    for (var i=0; i<data.length; i++){
      var singleRowData: any = {};
      var departmentAssociation = this.getDeptAssociation(data[i]);
      singleRowData = {
        orderHeaderId: data[i].orderHeaderId,
        orderHeaderGroupNumber: data[i].groupNumber,
        orderHeaderGroupDescription: data[i].groupDescription,
        departmentAssociation: departmentAssociation,
        departmentAssociationId: departmentAssociation != null ? departmentAssociation.storePosExportDeptAsscId : '',
        departmentNumber: departmentAssociation ? departmentAssociation.departmentNumber : '',
        departmentAssociationCreatedDate: departmentAssociation ? new Date(departmentAssociation.created) : null,
        departmentAssociationModifiedDate: departmentAssociation ? new Date(departmentAssociation.modified) : null,
      };
      gridData.push(singleRowData);
    }
    this.rowData = gridData;
    this._gridService.onWindowResize('#ag-grid-wrapper');
    setTimeout(() => {
      this.fitColumns();
      this._gridService.sizeGrid('#ag-grid-wrapper');
    }, 100); 
  }

  getDeptAssociation(orderHeaderRow) {
    var deptAssociation = null;
    for (var i = 0; i < this.departmentAssociations.length; i++) {
      if (this.departmentAssociations[i].orderHeaderId == orderHeaderRow.orderHeaderId) {
        deptAssociation = this.departmentAssociations[i];
        break;
      }
    }
    return deptAssociation;
  }

  onDoesIncludeHeaderRowChange(){
    var self = this;
    this.uploader.setOptions({
      url: this._baseUrl + "/api/StorePosExportDeptAsscs/CreateStorePosExportDeptAsscsFromCsv/" + self.storePosExportSettingsId + '/' + self.doesIncludeHeaderRow,
      filters: [{
        name: 'isCSV',
        // A user-defined filter
        fn: function (item) {
          //console.log(item);
          if (item.type === "application/vnd.ms-excel") {
            return true;
          }
          else {
            self._messageService.alert('That is not a valid CSV file.');
            self._spinnerService.handleProgressSpinnerVisibility('hide', 'Processing CSV file...');
            return false;
          }
        }
      }],
      queueLimit: 1,
      authToken: 'Bearer ' + sessionStorage.getItem('rpmsToken'),

    })
  }

  clearFilters(){
    this.quickFilterText = '';
    this.agGrid.api.setGridOption('quickFilterText', this.quickFilterText);
    this.agGrid.api.setFilterModel(null);
    this.agGrid.api.onFilterChanged();
  }

  resetColumns(){
    this.agGrid.api.resetColumnState();
    this.fitColumns(); //since this is done by default also
  }

  autoSizeColumns(){
    var columns = this.agGrid.api.getColumns();
    this.agGrid.api.autoSizeColumns(columns);
  }

  fitColumns(){
    this.agGrid.api.sizeColumnsToFit();
  }

  onQuickFilterChange(){
    this.agGrid.api.setGridOption('quickFilterText', this.quickFilterText);
  }

  doOnCellEditingStopped(params) {
    //Users with hasReadOnlyRestriction set to true should not even be allowed to invoke this action because it is hidden or disabled in the UI.
    //This is for extra protection. The back end will also be protected.
    if (params.context.componentParent.myUser.hasReadOnlyRestriction){
      params.context.componentParent._messageService.alertReadOnlyRestriction();
      return;
    }

    var deptAssociation: any = {};
    if (params.value) {
      deptAssociation.storePosExportSettingsId = params.context.componentParent.storePosExportSettingsId;
      deptAssociation.orderHeaderId = params.data.orderHeaderId;
      deptAssociation.departmentNumber = params.value;
      if (params.data.departmentAssociationId) {
        params.context.componentParent._posExportService.updateStorePosExportDeptAssc(params.data.departmentAssociationId, deptAssociation)
          .subscribe(
            (data) => {
              params.context.componentParent._messageService.onSuccess('Successfully updated Department Association');
              params.context.componentParent.onSaveOrUpdateDepartmentAssociationsSuccess(data);
            },
            (response) => {
              params.context.componentParent._messageService.onFailure('Failed to update Department Association.', response)
            }
          );
      }
      else {
        params.context.componentParent._posExportService.createStorePosExportDeptAssc(deptAssociation)
          .subscribe(
            (data) => {
              params.context.componentParent._messageService.onSuccess('Successfully created Department Association');
              params.context.componentParent.onSaveOrUpdateDepartmentAssociationsSuccess(data);
            },
            (response) => {
              params.context.componentParent._messageService.onFailure('Failed to create Department Association.', response)
            }
          );
      }
    }
    else {
      if (params.data.departmentAssociationId) {
        params.context.componentParent._posExportService.deleteStorePosExportDeptAssc(params.data.departmentAssociationId)
          .subscribe(
            () => {
              params.context.componentParent._messageService.onSuccess('Successfully deleted Department Association');
              params.context.componentParent.rowToUpdate.data.departmentAssociationCreatedDate = null;
              params.context.componentParent.rowToUpdate.data.departmentAssociationId = '';
              params.context.componentParent.rowToUpdate.data.departmentAssociationModifiedDate = null;
              params.context.componentParent.rowToUpdate.data.departmentNumber = '';
              params.context.componentParent.agGrid.api.refreshCells({ rowNodes: [params.context.componentParent.rowToUpdate], force: true });
            },
            (response) => {
              params.context.componentParent._messageService.onFailure('Failed to delete Department Association.', response)
            }
          );
      }
      else {
        //do nothing
      }
    }
    params.context.componentParent.rowToUpdate = params.node;
  }

  onSaveOrUpdateDepartmentAssociationsSuccess(data){
    this.rowToUpdate.data.departmentAssociationId = data.storePosExportDeptAsscId;
    this.rowToUpdate.data.departmentAssociationCreatedDate = data.created;
    this.rowToUpdate.data.departmentAssociationModifiedDate = data.modified;
    this.rowToUpdate.data.departmentNumber = data.departmentNumber;
    this.agGrid.api.redrawRows({rowNodes: [this.rowToUpdate]});
  }

  onDeleteButtonClick(){
    //Users with hasReadOnlyRestriction set to true should not even be allowed to invoke this action because it is hidden or disabled in the UI.
    //This is for extra protection. The back end will also be protected.
    if (this.myUser.hasReadOnlyRestriction){
      this._messageService.alertReadOnlyRestriction();
      return;
    }
    
    var self = this;
    this.confirmationDialogRef = this._dialog.open(ConfirmationDialogComponent, {
      disableClose: false,
      //width: '600px',
      data: {
        title: 'Are you sure?',
        message: 'Do you want to remove all department associations?',
        confirmText: 'Yes, remove them all',
        cancelText: 'No, cancel this action!'
      }
    });
    this.confirmationDialogRef.afterClosed().subscribe(result => {
      if(result) {
        this._posExportService.deleteStorePosExportDeptAsscsByStoreId(self.store.storeId)
        .subscribe(
          (data) => {
            this._messageService.onSuccess('Successfully removed all department associations');
            this.getStorePosExportSettingsById();
          },
          (response) => {
            this._messageService.onFailure('Failed to remove department associations.', response);
          })
      }
      this.confirmationDialogRef = null;
    });
  }

  ngOnDestroy(){
    this._spinnerService.setProgressSpinnerMessage('Processing Request...');
  }

  goBack(){
    this._router.navigate(['/admin/pos-export']);
  }

}

