import { Component, OnInit, Input } from '@angular/core';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import { MatDialogRef } from '@angular/material/dialog';
import { PrioritizePricingRulesDialog } from '../admin-pricing-rules-list/admin-pricing-rules-list.component';
import { PricingRulesService } from 'src/app/shared/pricing-rules.service';
import { MessageService } from 'src/app/shared/message.service';
import { AuthenticationService } from 'src/app/core/authentication/shared/authentication.service';

@Component({
  selector: 'rpms-prioritize-pricing-rules',
  templateUrl: './prioritize-pricing-rules.component.html',
  styleUrls: ['./prioritize-pricing-rules.component.css']
})
export class PrioritizePricingRulesComponent implements OnInit {
  @Input() prioritizedPricingRules: any;

  myUser;
  
  constructor(
    public dialogRef: MatDialogRef<PrioritizePricingRulesDialog>,
    private _pricingRulesService: PricingRulesService,
    private _authenticationService: AuthenticationService,
    private _messageService: MessageService,
  ) {
    this.myUser = this._authenticationService.getMyUser();
   }

  ngOnInit() {
    //console.log(this.prioritizedPricingRules);
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.prioritizedPricingRules, event.previousIndex, event.currentIndex);
    for (var i = 0; i < this.prioritizedPricingRules.length; i++) {
      this.prioritizedPricingRules[i].priority = i+1;
    }
    //console.log(this.prioritizedPricingRules);
  }

  savePriorityOrder(){
    //Users with hasReadOnlyRestriction set to true should not even be allowed to invoke this action because it is hidden or disabled in the UI.
    //This is for extra protection. The back end will also be protected.
    if (this.myUser.hasReadOnlyRestriction){
      this._messageService.alertReadOnlyRestriction();
      return;
    }

    var pricingRulePrioritiesUpdateBindingModel = {
      rulePriorities: this.prioritizedPricingRules
    }
    this._pricingRulesService.updatePricingRulePriorities(pricingRulePrioritiesUpdateBindingModel)
      .subscribe(
        (data) => {
          this._messageService.onSuccess('Successfully prioritized pricing rules');
          this.dialogRef.close('reload');
        },
        (response) => {
          this._messageService.onFailure('Failed to prioritize pricing rules.', response);
        }
      )
  }

  cancel(){
    this.dialogRef.close();
  }


}
