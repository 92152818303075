import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AppConfig } from '../app.config';

@Injectable({
  providedIn: 'root'
})
export class ApplicationService {

  private _baseUrl = AppConfig.settings.baseUrl;
  private _reqHeader = new HttpHeaders({ 'Accept': 'application/json', 'Content-Type': 'application/json' });

  constructor(
    private _http: HttpClient, 
  ) { }

  logOutInactiveUsers() {
    return this._http.get(this._baseUrl + '/api/Application/LogOutInactiveUsers', { headers: this._reqHeader });
  }
 
}
