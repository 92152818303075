import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthenticationService } from 'src/app/core/authentication/shared/authentication.service';

@Component({
  selector: 'app-critical-error-dialog',
  templateUrl: './critical-error-dialog.component.html',
  styleUrls: ['./critical-error-dialog.component.css']
})
export class CriticalErrorDialogComponent {
  htmlContent: string;
  constructor(
    private _authenticationService: AuthenticationService,
    public dialogRef: MatDialogRef<CriticalErrorDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      this.htmlContent = data.message;
    }

  public confirmMessage:string;
  isLoggingOut: boolean = false;

  logout() {
    this._authenticationService.logout();
    this.isLoggingOut = true;
  }

  closeDialog(){
    this.dialogRef.close();
  }
}
