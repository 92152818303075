 <div id="app-header">
    <div id="app-logo" title="Home" (click)="navigateToHome()" [ngClass]="{dev: isDevMode}">
        <img id="oval-logo" src="/assets/images/piggly-wiggly-oval-logo.png" />
        <img id="rpms-logo" src="/assets/images/RPMS-text-logo.png" />
        <span *ngIf="isDevMode">DEV</span>
    </div>
    <div *ngIf="isUserLoggedIn">
        <ul id="app-nav">
            <li routerLink="/home" title="Home">
                <i class="far fa-home fa-lg"></i>
            </li>
            <li *ngIf="permissions.roleAllowsUserToViewPriceManagement" title="Price Management" routerLink="/price-management" routerLinkActive="active">
                <!-- <i class="fa fa-dollar-sign fa-lg"></i> --><i class="far fa-usd-circle fa-lg"></i>
                <span>Price Management</span>
            </li>
            <li *ngIf="permissions.roleAllowsUserToViewAdWeek" title="Ad Week" routerLink="/ad-week" routerLinkActive="active">
                <i class="far fa-newspaper fa-lg"></i>
                <span>Ad Week</span>
            </li>
            <li *ngIf="permissions.roleAllowsUserToViewReportsAndExports" title="Reports and Exports" routerLink="/reports" routerLinkActive="active">
                <!-- <i class="fa fa-file-alt fa-lg"></i>--><i class="far fa-file-spreadsheet fa-lg"></i> 
                <span>Reports and Exports</span>
            </li>
            <li *ngIf="permissions.roleAllowsUserToViewAdmin" title="Administration" routerLink="/admin" routerLinkActive="active">
                <i class="far fa-cog fa-lg"></i>
                <span>Administration</span>
            </li>
            <li id="help-tab" class="top-nav tab" title="Help" routerLink="/help" routerLinkActive="active">
                <i class="far fa-question-circle fa-lg"></i>
                <span>Help</span>
            </li>
        </ul>
        <mat-slide-toggle id="helpful-tips" *ngIf="!isMobile" class="slide" [checked]="isHelpfulMode" (change)="onHelpfulModeChange()">Helpful Tips</mat-slide-toggle>
        <div id="app-nav-right">
            <div id="account-info" *ngIf="myUser != null" (click)="toggleAccountMenu($event)">
                <span class="username">{{myUser.userName}}</span>
                <span class="role">{{addSpacesBetweenWords(userRole)}}<span *ngIf="myUser.hasReadOnlyRestriction"> (Read Only)</span></span>
                <span class="arrow-down"></span>
            </div>
            <div id="account-menu" *ngIf="accountMenuVisible">
                <!-- <div ui-sref="administration.adminUserAccounts.adminUserAccount({userId: null})">Manage My Account</div>
                <div (click)="logout()">Logout</div> -->
                <button mat-stroked-button color="primary" (click)="goToMyAccount()">Manage My Account</button>
                <button mat-stroked-button color="primary" (click)="logout()">Logout</button>
            </div>
        </div>
    </div>
</div>
