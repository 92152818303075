import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { AgGridAngular } from 'ag-grid-angular';
import { GridOptions } from 'ag-grid-community';
import { MatDialogRef } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from 'src/app/shared/confirmation-dialog/confirmation-dialog.component';
import { StoreService } from 'src/app/shared/store.service';
import { GridService } from 'src/app/shared/grid.service';
import { MessageService } from 'src/app/shared/message.service';
import { ViewDetailButtonComponent } from 'src/app/shared/view-detail-button/view-detail-button.component';
import { DeleteButtonComponent } from 'src/app/shared/delete-button/delete-button.component';
import { PriceManagementService } from 'src/app/shared/price-management.service';

@Component({
  selector: 'app-admin-shelf-tag-list',
  templateUrl: './admin-shelf-tag-list.component.html',
  styleUrls: ['./admin-shelf-tag-list.component.css']
})
export class AdminShelfTagListComponent implements OnInit {
  @ViewChild('agGrid') agGrid: AgGridAngular;

  //adminDetailRef: MatDialogRef<AdminShelfTagDetailDialog>;
  confirmationDialogRef: MatDialogRef<ConfirmationDialogComponent>;

  gridOptions: GridOptions;
  quickFilterText: string = '';
  private context; //required by grid
  //private frameworkComponents; //required by grid
  stores;
  isDataLoaded: boolean = false;

  rowData = [];
  columnDefs = [
    {
      headerName: "Item Code",
      field: "itemCode",
      cellClass: "itemCode-col",
      filter: "agTextColumnFilter",
      filterParams: {
        defaultOption: "startsWith"
      },
      get headerTooltip() { return this.headerName; }              
    },
    {
      headerName: "Description",
      field: "description",
      cellClass: "description-col",
      filter: "agTextColumnFilter",
      get headerTooltip() { return this.headerName; }
    },
    {
      headerName: "Request Date",
      field: "requestDate",
      cellClass: "requestDate-col",
      cellRenderer: this._gridService.dateTimeRenderer,
      filter: 'agDateColumnFilter',
      filterParams: {
        comparator: this._gridService.dateComparator,
        inRangeInclusive: true
      },
      get headerTooltip() { return this.headerName; }
    },
    {
      headerName: "Quantity Ordered",
      field: "quantity",
      cellClass: "quantity-col",
      filter: 'agNumberColumnFilter',
      get headerTooltip() { return this.headerName; }
    },

  ];
  
  constructor(
    private _storeService: StoreService,
    private _priceManagementService: PriceManagementService,
    private _gridService: GridService,
    private _messageService: MessageService,
    ) {
    this.gridOptions = <GridOptions> {
			columnDefs: this.columnDefs,
      rowData: this.rowData,
      rowHeight: 35,    
      components: {
        ViewDetailButtonComponent: ViewDetailButtonComponent,
        DeleteButtonComponent: DeleteButtonComponent,
      },
      defaultColDef: {
        sortable: true,
        resizable: true,
        floatingFilter: true,
      },
      statusBar: {
        statusPanels: [
          { statusPanel: 'agTotalAndFilteredRowCountComponent', align: 'left' },
        ],
      },

    }
    this.context = { componentParent: this }
   }

  ngOnInit() {
    this._storeService.getAllStores()
      .subscribe(
        (data) => {
          this.stores = data;
        },
        (response) => {
          this._messageService.onFailure('Failed to get Stores.', response);
        })
  }

  getShelfTags(storeId){
    this._priceManagementService.getAllStockItemsWithShelfTagPendingFulfillmentByStoreId(storeId)
      .subscribe(
        (data) => {
          this.isDataLoaded = true;
          this.formatandSetRowData(data);
        },
        (response) => {
          this._messageService.onFailure('Failed to get Pending Shelf Tags.', response);
        })
  }

  formatandSetRowData(data) {
    var gridData = [];
    for (var i=0; i<data.length; i++){
      var singleRowData = {};
      singleRowData = {
        itemCode: data[i].item.itemCode,
        description: data[i].item.description,
        requestDate: data[i].shelfTagRequestDate != null ? new Date(data[i].shelfTagRequestDate) : '',
        quantity: data[i].shelfTagQuantityOrdered,
      };
      gridData.push(singleRowData);
    }
    this.rowData = gridData;
    this._gridService.onWindowResize('#ag-grid-wrapper');
    setTimeout(() => {
      this.fitColumns();
      this._gridService.sizeGrid('#ag-grid-wrapper');
    }, 100); 
  }

  clearFilters(){
    this.quickFilterText = '';
    this.agGrid.api.setGridOption('quickFilterText', this.quickFilterText);
    this.agGrid.api.setFilterModel(null);
    this.agGrid.api.onFilterChanged();
  }

  resetColumns(){
    this.agGrid.api.resetColumnState();
    this.fitColumns(); //since this is done by default also
  }

  autoSizeColumns(){
    var columns = this.agGrid.api.getColumns();
    this.agGrid.api.autoSizeColumns(columns);
  }

  fitColumns(){
    this.agGrid.api.sizeColumnsToFit();
  }

  onQuickFilterChange(){
    this.agGrid.api.setGridOption('quickFilterText', this.quickFilterText);
  }

}

