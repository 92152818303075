import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { PosExportService } from 'src/app/shared/pos-export.service';
import { GridService } from 'src/app/shared/grid.service';
import { MatDialogRef } from '@angular/material/dialog';
import { AdminPosExportDetailDialog } from '../admin-pos-export-list/admin-pos-export-list.component';
import { MessageService } from 'src/app/shared/message.service';
import { AuthenticationService } from 'src/app/core/authentication/shared/authentication.service';

@Component({
  selector: 'rpms-admin-pos-export-detail',
  templateUrl: './admin-pos-export-detail.component.html',
  styleUrls: ['./admin-pos-export-detail.component.css']
})
export class AdminPosExportDetailComponent implements OnInit {
  @Input() id: string; 

  storePosExportSettingsId;
  posExportSettings;
  storeId;
  storeName;
  myUser;

  posExportSettingsDetailForm = this._fb.group({
    delimiter: ['', Validators.required],
    qualifyText: [''],
    textQualifier: ['', Validators.required],
    includeHeaderRow: [''],
    booleanValuesAs0Or1: [''],
    forceWicItemOff: [''],
    singleUnitMultiFormat: [''],
  });

  constructor(
    private _posExportService: PosExportService,
    private _authenticationService: AuthenticationService,
    private _fb: UntypedFormBuilder,
    private _messageService: MessageService,
    public dialogRef: MatDialogRef<AdminPosExportDetailDialog>,
    ) { 
      this.myUser = this._authenticationService.getMyUser();
    }
    
  ngOnInit() {
    //if changing ids without leaving page ngOnInit will not run again, but the subscribe method below will update and call doOnInit()
    // this._activatedRoute.paramMap.subscribe((params: ParamMap) => {
    //   this.storePosExportSettingsId = params.get('id');
    //   this.getStorePosExportSettingsById();
    // })
    this.storePosExportSettingsId = this.id;
    this.getStorePosExportSettingsById();
    if (this.myUser.hasReadOnlyRestriction) this.posExportSettingsDetailForm.disable();
  }

  getStorePosExportSettingsById(){
    this._posExportService.getStorePosExportSettingsById(this.storePosExportSettingsId)
    .subscribe(
      (data:any) => {
        this.posExportSettings = data;
        this.storeId = data.storeId;
        this.storeName = data.store.numberAndLocation;
        this.posExportSettingsDetailForm.patchValue({
          delimiter: data.delimiter,
          qualifyText: data.qualifyText,
          textQualifier: data.textQualifier,
          includeHeaderRow: data.includeHeaderRow,
          booleanValuesAs0Or1: String(data.booleanValuesAs0Or1),
          forceWicItemOff: data.forceWicItemOff,
          singleUnitMultiFormat: String(data.singleUnitMultiFormat)
        });
      },
      (response) => {
        this._messageService.onFailure('Failed to get POS Export Settings.', response);
      })
}

  onSubmitPosSettings(){
    //Users with hasReadOnlyRestriction set to true should not even be allowed to invoke this action because it is hidden or disabled in the UI.
    //This is for extra protection. The back end will also be protected.
    if (this.myUser.hasReadOnlyRestriction){
      this._messageService.alertReadOnlyRestriction();
      return;
    }
    
    this._posExportService.updateStorePosExportSettings(this.storePosExportSettingsId, this.posExportSettingsDetailForm.value)
      .subscribe(
        (data) => {
          this.dialogRef.close('reload');
          this._messageService.onSuccess('Successfully updated POS Settings');
        },
        (response) => {
          this._messageService.onFailure('Failed to update POS Settings.', response)
        }
      );
  }

  closeDialog(){
    this.dialogRef.close();
  }

}


