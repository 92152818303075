import { Component } from '@angular/core';
import { SpinnerService } from '../spinner.service';
import { ChangeDetectorRef } from '@angular/core';

@Component({
  selector: 'rpms-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.css']
})
export class SpinnerComponent {

  spinnerSubscription;
  spinnerMessageSubscription;
  showProgressSpinner:boolean = false;
  progressSpinnerMessage: string = 'Processing Request...';

  constructor(
    private _spinnerService: SpinnerService,
    private _changeDetectorRef: ChangeDetectorRef,
  ) {

    this.spinnerSubscription = this._spinnerService.spinner$
    .subscribe(
      (data) => {
        this.showProgressSpinner = data.visibility === 'show' ? true : false;
        this.progressSpinnerMessage = data.message ? data.message : this.progressSpinnerMessage;
        this._changeDetectorRef.detectChanges();
      }
    );

    this.spinnerMessageSubscription = this._spinnerService.spinnerMessage$
      .subscribe(
        (data) => {
          this.progressSpinnerMessage = data ? data : this.progressSpinnerMessage;
          this._changeDetectorRef.detectChanges();
        }
      );
  }

  ngOnDestroy(){
    this.spinnerSubscription.unsubscribe();
    this.spinnerMessageSubscription.unsubscribe();
  }

}
