import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class HelpService {

  isHelpfulMode: boolean = false;
  showHelpTimeout;

  constructor() { }

  getIsHelpfulMode(){
    return this.isHelpfulMode;
  }

  setIsHelpfulMode(bool){
    this.isHelpfulMode = bool;
  }

  showHelpfulTip(event){
    //console.log(event);
    var self = this;
    var helps:any = document.getElementsByClassName('help');
    if (helps.length){
      for (var i = 0; i < helps.length; i++) {
        helps[i].style.opacity = '0';
        helps[i].style.transform = 'scale(.1, .1)';  
        helps[i].style['pointer-events'] = 'none';
      }
    }
    var target = event.target.attributes['data-help'].value;
    var tooltip = <HTMLElement>document.getElementById(target + '-tooltip');
    if (tooltip && self.isHelpfulMode){
      var tooltipWidth = tooltip.clientWidth;
      var tooltipHeight = tooltip.clientHeight;
      tooltip.style['pointer-events'] = 'auto';
      tooltip.style.position = "fixed";
      // tooltip would be too far right and bottom
      if (event.x + tooltipWidth > window.innerWidth && event.y + tooltipHeight > window.innerHeight) {
        tooltip.style.transformOrigin = 'bottom right';
        tooltip.style.borderRadius = "10px 10px 0 10px";
        tooltip.style.left = event.x - tooltipWidth +'px';
        tooltip.style.top = event.y - tooltipHeight +'px';
      }
      // tooltip only too far right
      else if (event.x + tooltipWidth > window.innerWidth) {
        tooltip.style.transformOrigin = 'top right';
        tooltip.style.borderRadius = "10px 0 10px 10px";
        tooltip.style.left = event.x - tooltipWidth +'px';
        tooltip.style.top = event.y +'px';
      }
      // tooltip only too far bottom
      else if (event.y + tooltipHeight > window.innerHeight) {
        tooltip.style.transformOrigin = 'bottom left';
        tooltip.style.borderRadius = "10px 10px 10px 0";
        tooltip.style.left = event.x +'px';
        tooltip.style.top = event.y - tooltipHeight +'px';
      }
      else{
        tooltip.style.transformOrigin = 'top left';
        tooltip.style.borderRadius = "0 10px 10px 10px";
        tooltip.style.left = event.x +'px';
        tooltip.style.top = event.y +'px';
      }
      tooltip.addEventListener('mouseover', function(){
        event.target.classList.add('child-has-mouse');
      })
      tooltip.addEventListener('mouseleave', function(){
        event.target.classList.remove('child-has-mouse');
        self.hideHelpfulTip(event);
      })
      self.showHelpTimeout = setTimeout(() => {
        tooltip.style['pointer-events'] = 'auto';
        tooltip.style.opacity = '1';
        tooltip.style.transform = 'scale(1, 1)';
        }, 1000);
    }
  }

  hideHelpfulTip(event){
    var self = this;
    clearTimeout(self.showHelpTimeout);
    setTimeout(() => {
      if (!event.target.classList.contains('child-has-mouse')){
        var target = event.target.attributes['data-help'].value;
        var tooltip = <HTMLElement>document.getElementById(target + '-tooltip');
        if (tooltip && self.isHelpfulMode){
          tooltip.style.opacity = '0';
          tooltip.style.transform = 'scale(.1, .1)';
          var helps:any = document.getElementsByClassName('help');
          //clear all of them if any are visible
          if (helps.length){
            for (var i = 0; i < helps.length; i++) {
              helps[i].style.opacity = '0';
              helps[i].style.transform = 'scale(.1, .1)';  
              helps[i].style['pointer-events'] = 'none';
            }
          }
        }
      }
    }, 100);
  }

}
