import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AppConfig } from 'src/app/app.config';
//import { environment } from 'src/environments/environment';

@Component({
  selector: 'rpms-tpr-tag-editor',
  templateUrl: './tpr-tag-editor.component.html',
  styleUrls: ['./tpr-tag-editor.component.css']
})
export class TprTagEditorComponent implements OnInit {
  @Input() rowNodeToUpdate: any;
  @Input() changeType: string;
  @Output() updatedRowNodeEvent = new EventEmitter<any>();
  @Output() isTprTagEditorVisibleEvent = new EventEmitter<boolean>();
  private config = AppConfig.settings;

  rowData: any = {};
  isSaveDisabled: boolean = true;
  tprType;
  bookValues;
  selectedBook;

  constructor(
  ) { }

  ngOnInit() {
    if (this.config.logInits) console.log('tpr editor init');
    this.rowData = this.rowNodeToUpdate.data;
    this.bookValues = [
      this.rowData.current1,
      this.rowData.current2,
      this.rowData.current3,
      this.rowData.current4,
      this.rowData.current5,
      this.rowData.current6,
      this.rowData.current7,
    ]
    if (this.rowData.tprTagPendingFulfillment){
      this.tprType = this.rowData.tprPersist ? 'always' : 'once';
    }
    else{
      this.tprType = 'none';
    }
    this.selectedBook = this.rowData.tprBook;
  }

  onTprTypeChanged(){
    if (this.selectedBook){
      this.isSaveDisabled = false;
    }
  }

  onCellClicked(book, isBookDisabled){
    //console.log(book);
    if (!isBookDisabled){
      this.isSaveDisabled = false;
      this.selectedBook = book;
    }
  }

  sendRowNodeAndTprTagModel() {
    this.rowNodeToUpdate.data.tprBook = this.selectedBook;
    this.updatedRowNodeEvent.next({ rowNode: this.rowNodeToUpdate, tprTagModel: { tprBook: this.selectedBook, tprType: this.tprType}});
  }

  close(){
      this.isTprTagEditorVisibleEvent.next(false);
  }

}
