import { Component } from "@angular/core";
import { AuthenticationService } from "./core/authentication/shared/authentication.service";
import { PingerService } from "./core/authentication/shared/pinger.service";
import { PermissionsService } from "./shared/permissions.service";
import { SignalRService } from "./shared/signal-r.service";
import { IdleService } from "./core/authentication/shared/idle.service";
import { Router } from "@angular/router";
import { MessageService } from "./shared/message.service";
import { environment } from "src/environments/environment";
import { LicenseManager } from "ag-grid-enterprise";

LicenseManager.setLicenseKey(
  "Using_this_{AG_Grid}_Enterprise_key_{AG-052244}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{Piggly_Wiggly_Alabama_Distributing_co.}_is_granted_a_{Multiple_Applications}_Developer_License_for_{1}_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_need_to_be_licensed_in_addition_to_the_ones_working_with_{AG_Grid}_Enterprise___This_key_has_not_been_granted_a_Deployment_License_Add-on___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{15_February_2025}____[v3]_[01]_MTczOTU3NzYwMDAwMA==3cb57cd1746ee2411623fdd548eed6ad"
);

@Component({
  selector: "rpms-app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"]
})
export class AppComponent {

  version = environment.version;
  
  constructor(
    private _idleService: IdleService,
    private _authenticationService: AuthenticationService,
    private _pingerService: PingerService,
    private _permissionsService: PermissionsService,
    private _signalRService: SignalRService,
    private _router: Router,
    private _messageService: MessageService
  ) {}

  ngOnInit() {
    var token = sessionStorage.getItem("rpmsToken");
    if (token != null) {
      //if refreshing page
      var role = this._authenticationService.getUserRole();
      this._permissionsService.initRolePermissions(role);
      this._authenticationService.setIsUserLoggedIn(true);
      this._idleService.resetIdleWatch();
      this._pingerService.startPingerTimer();
      this._signalRService.loadSignalRHubs();
    }
    var sessionId = localStorage.getItem("rpmsSessionId");
    //if not logged in but there is a sessionId or logged in and there is no sessionId (this shouldn't ever happen)
    if (!token && sessionId) {
      this._authenticationService.doOnLogout();
    } 
    else if (token && !sessionId) {
      this._authenticationService.logout();
    }
    // if this page is loaded and the sessionId doesn't equal the window name
    // This same code is also in auth.guard (route changes)
    else if (token && sessionId && sessionId !== window.name) {
      //document.documentElement.innerHTML = '';
      this._messageService.alert(
        "RPMS can only run on one browser tab. You may have closed the previous tab without logging out first. Please close all but one tab running RPMS.  You may have to log in again."
      );
      this._router.navigate(["/login"]);
      this._authenticationService.logout();
    }
  }
}
