<div id="schedule-page">
<h1><i class="fa fa-calendar"></i>Schedule</h1>
<nav mat-tab-nav-bar [tabPanel]="tabPanel">
    <a mat-tab-link *ngFor="let link of links"
        (click)="onTabClick(link)"
        [active]="activeLink == link"> {{link}} </a>
</nav>
<mat-tab-nav-panel #tabPanel></mat-tab-nav-panel>
<mat-accordion multi="true">
    <mat-expansion-panel [expanded]="isWindowPanelOpen" (opened)="isWindowPanelOpen = true" (closed)="isWindowPanelOpen = false">
        <mat-expansion-panel-header>
        <mat-panel-title>
            <i class="far fa-window-maximize"></i>Time Windows
        </mat-panel-title>
        <mat-panel-description>
            Windows for Pricing, Server Maintenance, etc.
        </mat-panel-description>
        </mat-expansion-panel-header>
        <!-- content -->
        <mat-card appearance="outlined" class="daily" *ngIf="activeLink == 'Daily' || activeLink == 'All'">
                <h3>Daily Server Maintenance<span [ngClass]="isServerMaintenanceWindowOpen ? 'window-open' : 'window-closed'">{{isServerMaintenanceWindowOpen ? 'OPEN' : 'CLOSED'}}</span></h3>
                <div class="controls">
                    <mat-form-field>
                      <mat-label>Next Window Opens</mat-label>
                      <mat-select id="serverMaintenanceWindowOpensDayOfWeek" [(ngModel)]="schedule.serverMaintenanceWindowOpensDayOfWeek" placeholder="Next Window Opens" (selectionChange)="updateSchedule()" [disabled]="!permissions.roleAllowsUserToEditAdminSchedule || myUser.hasReadOnlyRestriction">
                          <mat-option *ngFor="let day of daysOfWeek" [value]="day">{{day}}</mat-option>
                      </mat-select>
                    </mat-form-field>
                    <button (click)="onBackwardOneDay('ServerMaintenanceWindowOpens')" mat-stroked-button color="primary" [disabled]="!permissions.roleAllowsUserToEditAdminSchedule || myUser.hasReadOnlyRestriction"><i class="fa fa-chevron-left"></i></button>
                    <span class="scheduled-date">{{schedule.serverMaintenanceWindowOpensNext}}</span>
                    <button (click)="onForwardOneDay('ServerMaintenanceWindowOpens')" mat-stroked-button color="primary" [disabled]="!permissions.roleAllowsUserToEditAdminSchedule || myUser.hasReadOnlyRestriction"><i class="fa fa-chevron-right"></i></button>
                    <mat-form-field>
                      <mat-label>Open Time</mat-label>
                      <input matInput [(ngModel)]="schedule.serverMaintenanceWindowOpensTimeOfDayView" placeholder="Open Time" type="time" [disabled]="!permissions.roleAllowsUserToEditAdminSchedule || myUser.hasReadOnlyRestriction" (blur)="updateSchedule()">
                    </mat-form-field>
                    <!-- <mdp-time-picker [(ngModel)]="schedule.serverMaintenanceWindowOpensTimeOfDayView" (change)="updateSchedule()" aria-label="Server Maintenance Window Opens Time" mdp-disabled="!ac.permissions.roleAllowsUserToEditAdminSchedule"></mdp-time-picker> -->
                </div>
                <div class="controls">
                    <mat-form-field>
                      <mat-label>{{isServerMaintenanceWindowOpen ? 'Current Window Closes' : 'Next Window Closes'}}</mat-label>
                      <mat-select id="serverMaintenanceWindowClosesDayOfWeek" [(ngModel)]="schedule.serverMaintenanceWindowClosesDayOfWeek" placeholder="{{isServerMaintenanceWindowOpen ? 'Current Window Closes' : 'Next Window Closes'}}" (selectionChange)="updateSchedule()" [disabled]="!permissions.roleAllowsUserToEditAdminSchedule || myUser.hasReadOnlyRestriction">
                          <mat-option *ngFor="let day of daysOfWeek" [value]="day">{{day}}</mat-option>
                      </mat-select>
                    </mat-form-field>
                    <button (click)="onBackwardOneDay('ServerMaintenanceWindowCloses')" mat-stroked-button color="primary" [disabled]="!permissions.roleAllowsUserToEditAdminSchedule || myUser.hasReadOnlyRestriction"><i class="fa fa-chevron-left"></i></button>
                    <span class="scheduled-date">{{schedule.serverMaintenanceWindowClosesNext}}</span>
                    <button (click)="onForwardOneDay('ServerMaintenanceWindowCloses')" mat-stroked-button color="primary" [disabled]="!permissions.roleAllowsUserToEditAdminSchedule || myUser.hasReadOnlyRestriction"><i class="fa fa-chevron-right"></i></button>
                    <mat-form-field>
                      <mat-label>Close Time</mat-label>
                      <input matInput [(ngModel)]="schedule.serverMaintenanceWindowClosesTimeOfDayView" placeholder="Close Time" type="time" [disabled]="!permissions.roleAllowsUserToEditAdminSchedule || myUser.hasReadOnlyRestriction" (blur)="updateSchedule()">
                    </mat-form-field>
                </div>
            </mat-card>
            
            <mat-card appearance="outlined" class="weekly" *ngIf="activeLink == 'Weekly' || activeLink == 'All'">
                <h3>Weekly Grocery Preview Pricing<span [ngClass]="isPreviewPricingWindowOpen ? 'window-open' : 'window-closed'">{{isPreviewPricingWindowOpen ? 'OPEN' : 'CLOSED'}}</span></h3>
                <div class="controls">
                    <mat-form-field>
                      <mat-label>Next Window Opens</mat-label>
                      <mat-select id="previewPricingWindowOpensDayOfWeek" [(ngModel)]="schedule.previewPricingWindowOpensDayOfWeek" placeholder="Next Window Opens" (selectionChange)="updateSchedule()" [disabled]="!permissions.roleAllowsUserToEditAdminSchedule || myUser.hasReadOnlyRestriction">
                          <mat-option *ngFor="let day of daysOfWeek" [value]="day">{{day}}</mat-option>
                      </mat-select>
                    </mat-form-field>
                    <button (click)="onBackwardOneWeek('PreviewPricingWindowOpens')" mat-stroked-button color="primary" [disabled]="!permissions.roleAllowsUserToEditAdminSchedule || myUser.hasReadOnlyRestriction"><i class="fa fa-chevron-left"></i></button>
                    <span class="scheduled-date">{{schedule.previewPricingWindowOpensNext}}</span>
                    <button (click)="onForwardOneWeek('PreviewPricingWindowOpens')" mat-stroked-button color="primary" [disabled]="!permissions.roleAllowsUserToEditAdminSchedule || myUser.hasReadOnlyRestriction"><i class="fa fa-chevron-right"></i></button>
                    <mat-form-field>
                      <mat-label>Open Time</mat-label>
                      <input matInput [(ngModel)]="schedule.previewPricingWindowOpensTimeOfDayView" placeholder="Open Time" type="time" [disabled]="!permissions.roleAllowsUserToEditAdminSchedule || myUser.hasReadOnlyRestriction" (blur)="updateSchedule()">
                    </mat-form-field>
                </div>
                <div class="controls">
                    <mat-form-field>
                      <mat-label>{{isPreviewPricingWindowOpen ? 'Current Window Closes' : 'Next Window Closes'}}</mat-label>
                      <mat-select id="previewPricingWindowClosesDayOfWeek" [(ngModel)]="schedule.previewPricingWindowClosesDayOfWeek" placeholder="{{isPreviewPricingWindowOpen ? 'Current Window Closes' : 'Next Window Closes'}}" (selectionChange)="updateSchedule()" [disabled]="!permissions.roleAllowsUserToEditAdminSchedule || myUser.hasReadOnlyRestriction">
                          <mat-option *ngFor="let day of daysOfWeek" [value]="day">{{day}}</mat-option>
                      </mat-select>
                    </mat-form-field>
                    <button (click)="onBackwardOneWeek('PreviewPricingWindowCloses')" mat-stroked-button color="primary" [disabled]="!permissions.roleAllowsUserToEditAdminSchedule || myUser.hasReadOnlyRestriction"><i class="fa fa-chevron-left"></i></button>
                    <span class="scheduled-date">{{schedule.previewPricingWindowClosesNext}}</span>
                    <button (click)="onForwardOneWeek('PreviewPricingWindowCloses')" mat-stroked-button color="primary" [disabled]="!permissions.roleAllowsUserToEditAdminSchedule || myUser.hasReadOnlyRestriction"><i class="fa fa-chevron-right"></i></button>
                    <mat-form-field>
                      <mat-label>Close Time</mat-label>
                      <input matInput [(ngModel)]="schedule.previewPricingWindowClosesTimeOfDayView" placeholder="Close Time" type="time" [disabled]="!permissions.roleAllowsUserToEditAdminSchedule || myUser.hasReadOnlyRestriction" (blur)="updateSchedule()">
                    </mat-form-field>
                </div>
            </mat-card>
            
            <mat-card appearance="outlined" class="weekly" *ngIf="activeLink == 'Weekly' || activeLink == 'All'">
                <h3>Weekly Meat and Produce Preview Pricing<span [ngClass]="isMeatAndProducePreviewPricingWindowOpen ? 'window-open' : 'window-closed'">{{isMeatAndProducePreviewPricingWindowOpen ? 'OPEN' : 'CLOSED'}}</span></h3>
                <div class="controls">
                    <mat-form-field>
                      <mat-label>Next Window Opens</mat-label>
                      <mat-select id="meatAndProducePreviewPricingWindowOpensDayOfWeek" [(ngModel)]="schedule.meatAndProducePreviewPricingWindowOpensDayOfWeek" placeholder="Next Window Opens" (selectionChange)="updateSchedule()" [disabled]="!permissions.roleAllowsUserToEditAdminSchedule || myUser.hasReadOnlyRestriction">
                          <mat-option *ngFor="let day of daysOfWeek" [value]="day">{{day}}</mat-option>
                      </mat-select>
                    </mat-form-field>
                    <button (click)="onBackwardOneWeek('MeatAndProducePreviewPricingWindowOpens')" mat-stroked-button color="primary" [disabled]="!permissions.roleAllowsUserToEditAdminSchedule || myUser.hasReadOnlyRestriction"><i class="fa fa-chevron-left"></i></button>
                    <span class="scheduled-date">{{schedule.meatAndProducePreviewPricingWindowOpensNext}}</span>
                    <button (click)="onForwardOneWeek('MeatAndProducePreviewPricingWindowOpens')" mat-stroked-button color="primary" [disabled]="!permissions.roleAllowsUserToEditAdminSchedule || myUser.hasReadOnlyRestriction"><i class="fa fa-chevron-right"></i></button>
                    <mat-form-field>
                      <mat-label>Open Time</mat-label>
                      <input matInput [(ngModel)]="schedule.meatAndProducePreviewPricingWindowOpensTimeOfDayView" placeholder="Open Time" type="time" [disabled]="!permissions.roleAllowsUserToEditAdminSchedule || myUser.hasReadOnlyRestriction" (blur)="updateSchedule()">
                    </mat-form-field>
                </div>
                <div class="controls">
                    <mat-form-field>
                      <mat-label>{{isMeatAndProducePreviewPricingWindowOpen ? 'Current Window Closes' : 'Next Window Closes'}}</mat-label>
                      <mat-select id="meatAndProducePreviewPricingWindowClosesDayOfWeek" [(ngModel)]="schedule.meatAndProducePreviewPricingWindowClosesDayOfWeek" placeholder="{{isMeatAndProducePreviewPricingWindowOpen ? 'Current Window Closes' : 'Next Window Closes'}}" (selectionChange)="updateSchedule()" [disabled]="!permissions.roleAllowsUserToEditAdminSchedule || myUser.hasReadOnlyRestriction">
                          <mat-option *ngFor="let day of daysOfWeek" [value]="day">{{day}}</mat-option>
                      </mat-select>
                    </mat-form-field>
                    <button (click)="onBackwardOneWeek('MeatAndProducePreviewPricingWindowCloses')" mat-stroked-button color="primary" [disabled]="!permissions.roleAllowsUserToEditAdminSchedule || myUser.hasReadOnlyRestriction"><i class="fa fa-chevron-left"></i></button>
                    <span class="scheduled-date">{{schedule.meatAndProducePreviewPricingWindowClosesNext}}</span>
                    <button (click)="onForwardOneWeek('MeatAndProducePreviewPricingWindowCloses')" mat-stroked-button color="primary" [disabled]="!permissions.roleAllowsUserToEditAdminSchedule || myUser.hasReadOnlyRestriction"><i class="fa fa-chevron-right"></i></button>
                    <mat-form-field>
                      <mat-label>Close Time</mat-label>
                      <input matInput [(ngModel)]="schedule.meatAndProducePreviewPricingWindowClosesTimeOfDayView" placeholder="Close Time" type="time" [disabled]="!permissions.roleAllowsUserToEditAdminSchedule || myUser.hasReadOnlyRestriction" (blur)="updateSchedule()">
                    </mat-form-field>
                </div>
            </mat-card>
            
            <mat-card appearance="outlined" class="weekly" *ngIf="activeLink == 'Weekly' || activeLink == 'All'">
                <h3>Weekly Grocery Store Item Price Changes<span [ngClass]="isStoreItemPriceChangesWindowOpen ? 'window-open' : 'window-closed'">{{isStoreItemPriceChangesWindowOpen ? 'OPEN' : 'CLOSED'}}</span></h3>
                <div class="controls">
                    <mat-form-field>
                      <mat-label>Next Window Opens</mat-label>
                      <mat-select id="storeItemPriceChangesWindowOpensDayOfWeek" [(ngModel)]="schedule.storeItemPriceChangesWindowOpensDayOfWeek" placeholder="Next Window Opens" (selectionChange)="updateSchedule()" [disabled]="!permissions.roleAllowsUserToEditAdminSchedule || myUser.hasReadOnlyRestriction">
                          <mat-option *ngFor="let day of daysOfWeek" [value]="day">{{day}}</mat-option>
                      </mat-select>
                    </mat-form-field>
                    <button (click)="onBackwardOneWeek('StoreItemPriceChangesWindowOpens')" mat-stroked-button color="primary" [disabled]="!permissions.roleAllowsUserToEditAdminSchedule || myUser.hasReadOnlyRestriction"><i class="fa fa-chevron-left"></i></button>
                    <span class="scheduled-date">{{schedule.storeItemPriceChangesWindowOpensNext}}</span>
                    <button (click)="onForwardOneWeek('StoreItemPriceChangesWindowOpens')" mat-stroked-button color="primary" [disabled]="!permissions.roleAllowsUserToEditAdminSchedule || myUser.hasReadOnlyRestriction"><i class="fa fa-chevron-right"></i></button>
                    <mat-form-field>
                      <mat-label>Open Time</mat-label>
                      <input matInput [(ngModel)]="schedule.storeItemPriceChangesWindowOpensTimeOfDayView" placeholder="Open Time" type="time" [disabled]="!permissions.roleAllowsUserToEditAdminSchedule || myUser.hasReadOnlyRestriction" (blur)="updateSchedule()">
                    </mat-form-field>
                </div>
                <div class="controls">
                    <mat-form-field>
                      <mat-label>{{isStoreItemPriceChangesWindowOpen ? 'Current Window Closes' : 'Next Window Closes'}}</mat-label>
                      <mat-select id="storeItemPriceChangesWindowClosesDayOfWeek" [(ngModel)]="schedule.storeItemPriceChangesWindowClosesDayOfWeek" placeholder="{{isStoreItemPriceChangesWindowOpen ? 'Current Window Closes' : 'Next Window Closes'}}" (selectionChange)="updateSchedule()" [disabled]="!permissions.roleAllowsUserToEditAdminSchedule || myUser.hasReadOnlyRestriction">
                          <mat-option *ngFor="let day of daysOfWeek" [value]="day">{{day}}</mat-option>
                      </mat-select>
                    </mat-form-field>
                    <button (click)="onBackwardOneWeek('StoreItemPriceChangesWindowCloses')" mat-stroked-button color="primary" [disabled]="!permissions.roleAllowsUserToEditAdminSchedule || myUser.hasReadOnlyRestriction"><i class="fa fa-chevron-left"></i></button>
                    <span class="scheduled-date">{{schedule.storeItemPriceChangesWindowClosesNext}}</span>
                    <button (click)="onForwardOneWeek('StoreItemPriceChangesWindowCloses')" mat-stroked-button color="primary" [disabled]="!permissions.roleAllowsUserToEditAdminSchedule || myUser.hasReadOnlyRestriction"><i class="fa fa-chevron-right"></i></button>
                    <mat-form-field>
                      <mat-label>Close Time</mat-label>
                      <input matInput [(ngModel)]="schedule.storeItemPriceChangesWindowClosesTimeOfDayView" placeholder="Close Time" type="time" [disabled]="!permissions.roleAllowsUserToEditAdminSchedule || myUser.hasReadOnlyRestriction" (blur)="updateSchedule()">
                    </mat-form-field>
                </div>
            </mat-card>
            
            <mat-card appearance="outlined" class="weekly" *ngIf="activeLink == 'Weekly' || activeLink == 'All'">
                <h3>Weekly Meat and Produce Store Item Price Changes<span [ngClass]="isMeatAndProduceStoreItemPriceChangesWindowOpen ? 'window-open' : 'window-closed'">{{isMeatAndProduceStoreItemPriceChangesWindowOpen ? 'OPEN' : 'CLOSED'}}</span></h3>
                <div class="controls">
                    <mat-form-field>
                      <mat-label>Next Window Opens</mat-label>
                      <mat-select id="meatAndProduceStoreItemPriceChangesWindowOpensDayOfWeek" [(ngModel)]="schedule.meatAndProduceStoreItemPriceChangesWindowOpensDayOfWeek" placeholder="Next Window Opens" (selectionChange)="updateSchedule()" [disabled]="!permissions.roleAllowsUserToEditAdminSchedule || myUser.hasReadOnlyRestriction">
                          <mat-option *ngFor="let day of daysOfWeek" [value]="day">{{day}}</mat-option>
                      </mat-select>
                    </mat-form-field>
                    <button (click)="onBackwardOneWeek('MeatAndProduceStoreItemPriceChangesWindowOpens')" mat-stroked-button color="primary" [disabled]="!permissions.roleAllowsUserToEditAdminSchedule || myUser.hasReadOnlyRestriction"><i class="fa fa-chevron-left"></i></button>
                    <span class="scheduled-date">{{schedule.meatAndProduceStoreItemPriceChangesWindowOpensNext}}</span>
                    <button (click)="onForwardOneWeek('MeatAndProduceStoreItemPriceChangesWindowOpens')" mat-stroked-button color="primary" [disabled]="!permissions.roleAllowsUserToEditAdminSchedule || myUser.hasReadOnlyRestriction"><i class="fa fa-chevron-right"></i></button>
                    <mat-form-field>
                      <mat-label>Open Time</mat-label>
                      <input matInput [(ngModel)]="schedule.meatAndProduceStoreItemPriceChangesWindowOpensTimeOfDayView" placeholder="Open Time" type="time" [disabled]="!permissions.roleAllowsUserToEditAdminSchedule || myUser.hasReadOnlyRestriction" (blur)="updateSchedule()">
                    </mat-form-field>
                </div>
                <div class="controls">
                    <mat-form-field>
                      <mat-label>{{isMeatAndProduceStoreItemPriceChangesWindowOpen ? 'Current Window Closes' : 'Next Window Closes'}}</mat-label>
                      <mat-select id="meatAndProduceStoreItemPriceChangesWindowClosesDayOfWeek" [(ngModel)]="schedule.meatAndProduceStoreItemPriceChangesWindowClosesDayOfWeek" placeholder="{{isMeatAndProduceStoreItemPriceChangesWindowOpen ? 'Current Window Closes' : 'Next Window Closes'}}" (selectionChange)="updateSchedule()" [disabled]="!permissions.roleAllowsUserToEditAdminSchedule || myUser.hasReadOnlyRestriction">
                          <mat-option *ngFor="let day of daysOfWeek" [value]="day">{{day}}</mat-option>
                      </mat-select>
                    </mat-form-field>
                    <button (click)="onBackwardOneWeek('MeatAndProduceStoreItemPriceChangesWindowCloses')" mat-stroked-button color="primary" [disabled]="!permissions.roleAllowsUserToEditAdminSchedule || myUser.hasReadOnlyRestriction"><i class="fa fa-chevron-left"></i></button>
                    <span class="scheduled-date">{{schedule.meatAndProduceStoreItemPriceChangesWindowClosesNext}}</span>
                    <button (click)="onForwardOneWeek('MeatAndProduceStoreItemPriceChangesWindowCloses')" mat-stroked-button color="primary" [disabled]="!permissions.roleAllowsUserToEditAdminSchedule || myUser.hasReadOnlyRestriction"><i class="fa fa-chevron-right"></i></button>
                    <mat-form-field>
                      <mat-label>Close Time</mat-label>
                      <input matInput [(ngModel)]="schedule.meatAndProduceStoreItemPriceChangesWindowClosesTimeOfDayView" placeholder="Close Time" type="time" [disabled]="!permissions.roleAllowsUserToEditAdminSchedule || myUser.hasReadOnlyRestriction" (blur)="updateSchedule()">
                    </mat-form-field>
                </div>
            </mat-card>
            
            <mat-card appearance="outlined" class="weekly" *ngIf="activeLink == 'Weekly' || activeLink == 'All'">
                <h3>Weekly NOW Changes Lockout<span [ngClass]="isNowChangesLockoutWindowOpen ? 'window-open' : 'window-closed'">{{isNowChangesLockoutWindowOpen ? 'OPEN' : 'CLOSED'}}</span></h3>
                <div class="controls">
                    <mat-form-field>
                      <mat-label>Next Window Opens</mat-label>
                      <mat-select id="nowChangesLockoutWindowOpensDayOfWeek" [(ngModel)]="schedule.nowChangesLockoutWindowOpensDayOfWeek" placeholder="Next Window Opens" (selectionChange)="updateSchedule()" [disabled]="!permissions.roleAllowsUserToEditAdminSchedule || myUser.hasReadOnlyRestriction">
                          <mat-option *ngFor="let day of daysOfWeek" [value]="day">{{day}}</mat-option>
                      </mat-select>
                    </mat-form-field>
                    <button (click)="onBackwardOneWeek('NowChangesLockoutWindowOpens')" mat-stroked-button color="primary" [disabled]="!permissions.roleAllowsUserToEditAdminSchedule || myUser.hasReadOnlyRestriction"><i class="fa fa-chevron-left"></i></button>
                    <span class="scheduled-date">{{schedule.nowChangesLockoutWindowOpensNext}}</span>
                    <button (click)="onForwardOneWeek('NowChangesLockoutWindowOpens')" mat-stroked-button color="primary" [disabled]="!permissions.roleAllowsUserToEditAdminSchedule || myUser.hasReadOnlyRestriction"><i class="fa fa-chevron-right"></i></button>
                    <mat-form-field>
                      <mat-label>Open Time</mat-label>
                      <input matInput [(ngModel)]="schedule.nowChangesLockoutWindowOpensTimeOfDayView" placeholder="Open Time" type="time" [disabled]="!permissions.roleAllowsUserToEditAdminSchedule || myUser.hasReadOnlyRestriction" (blur)="updateSchedule()">
                    </mat-form-field>
                </div>
                <div class="controls">
                    <mat-form-field>
                      <mat-label>{{isNowChangesLockoutWindowOpen ? 'Current Window Closes' : 'Next Window Closes'}}</mat-label>
                      <mat-select id="nowChangesLockoutWindowClosesDayOfWeek" [(ngModel)]="schedule.nowChangesLockoutWindowClosesDayOfWeek" placeholder="{{isNowChangesLockoutWindowOpen ? 'Current Window Closes' : 'Next Window Closes'}}" (selectionChange)="updateSchedule()" [disabled]="!permissions.roleAllowsUserToEditAdminSchedule || myUser.hasReadOnlyRestriction">
                          <mat-option *ngFor="let day of daysOfWeek" [value]="day">{{day}}</mat-option>
                      </mat-select>
                    </mat-form-field>
                    <button (click)="onBackwardOneWeek('NowChangesLockoutWindowCloses')" mat-stroked-button color="primary" [disabled]="!permissions.roleAllowsUserToEditAdminSchedule || myUser.hasReadOnlyRestriction"><i class="fa fa-chevron-left"></i></button>
                    <span class="scheduled-date">{{schedule.nowChangesLockoutWindowClosesNext}}</span>
                    <button (click)="onForwardOneWeek('NowChangesLockoutWindowCloses')" mat-stroked-button color="primary" [disabled]="!permissions.roleAllowsUserToEditAdminSchedule || myUser.hasReadOnlyRestriction"><i class="fa fa-chevron-right"></i></button>
                    <mat-form-field>
                      <mat-label>Close Time</mat-label>
                      <input matInput [(ngModel)]="schedule.nowChangesLockoutWindowClosesTimeOfDayView" placeholder="Close Time" type="time" [disabled]="!permissions.roleAllowsUserToEditAdminSchedule || myUser.hasReadOnlyRestriction" (blur)="updateSchedule()">
                    </mat-form-field>
                </div>
            </mat-card>
            
    </mat-expansion-panel>
    <mat-expansion-panel [expanded]="isTaskPanelOpen" (opened)="isTaskPanelOpen = true" (closed)="isTaskPanelOpen = false">
        <mat-expansion-panel-header>
        <mat-panel-title>
            <i class="fas fa-clipboard-check"></i>Scheduled Tasks
        </mat-panel-title>
        <mat-panel-description>
            Scheduled Purges, Updates, etc.
        </mat-panel-description>
        </mat-expansion-panel-header>
        <!-- content -->
        <mat-card appearance="outlined" class="daily" *ngIf="activeLink == 'Daily' || activeLink == 'All'">
                <h3>Daily Purge of Older Log Actions</h3>
                <div class="controls">
                    <mat-form-field>
                      <mat-label>Next Purge</mat-label>
                      <mat-select id="purgeOldLogActionsDayOfWeek" [(ngModel)]="schedule.purgeOldLogActionsDayOfWeek" placeholder="Next Purge" (selectionChange)="updateSchedule()" [disabled]="!permissions.roleAllowsUserToEditAdminSchedule || myUser.hasReadOnlyRestriction">
                          <mat-option *ngFor="let day of daysOfWeek" [value]="day">{{day}}</mat-option>
                      </mat-select>
                    </mat-form-field>
                    <button (click)="onBackwardOneDay('PurgeOldLogActions')" mat-stroked-button color="primary" [disabled]="!permissions.roleAllowsUserToEditAdminSchedule || myUser.hasReadOnlyRestriction"><i class="fa fa-chevron-left"></i></button>
                    <span class="scheduled-date">{{schedule.purgeOldLogActionsNext}}</span>
                    <button (click)="onForwardOneDay('PurgeOldLogActions')" mat-stroked-button color="primary" [disabled]="!permissions.roleAllowsUserToEditAdminSchedule || myUser.hasReadOnlyRestriction"><i class="fa fa-chevron-right"></i></button>
                    <mat-form-field>
                      <mat-label>Time</mat-label>
                      <input matInput [(ngModel)]="schedule.purgeOldLogActionsTimeOfDayView" placeholder="Time" type="time" [disabled]="!permissions.roleAllowsUserToEditAdminSchedule || myUser.hasReadOnlyRestriction" (blur)="updateSchedule()">
                    </mat-form-field>
                </div>
                <div class="controls">
                    <mat-form-field>
                      <mat-label>Days to Retain</mat-label>
                      <input matInput [(ngModel)]="schedule.logActionsTimeToLiveInDays" placeholder="Days to Retain" [disabled]="!permissions.roleAllowsUserToEditAdminSchedule || myUser.hasReadOnlyRestriction" (blur)="updateSchedule()">
                    </mat-form-field>
                    <mat-form-field>
                      <mat-label>Last Started</mat-label>
                      <input matInput [(ngModel)]="schedule.purgeOldLogActionsLastStarted" placeholder="Last Started" readonly>
                    </mat-form-field>
                    <mat-form-field>
                      <mat-label>Last Completed</mat-label>
                      <input matInput [(ngModel)]="schedule.purgeOldLogActionsLastCompleted" placeholder="Last Completed" readonly>
                    </mat-form-field>
                </div>
                <div class="row">
                    <label>Last Execution: </label>
                    <span class="label-value successful-execution" *ngIf="schedule.purgeOldLogActionsLastExecutionSuccessful"><i class="fa fa-check" aria-hidden="true"></i> Succeeded</span>
                    <span class="label-value failed-execution" *ngIf="!schedule.purgeOldLogActionsLastExecutionSuccessful && schedule.purgeOldLogActionsLastExecutionSuccessful != null"><i class="fa fa-times" aria-hidden="true"></i> Failed</span>
                    <span class="label-value" *ngIf="schedule.purgeOldLogActionsLastExecutionSuccessful === null"> N/A</span>
                </div>
                <div class="processing" *ngIf="schedule.purgeOldLogActionsExecutionRequested">
                    <div><i class="fa fa-cog fa-spin fa-lg fa-fw"></i> <strong>In Process</strong> as of {{formatDate(schedule.purgeOldLogActionsExecutionRequested)}}</div>
                </div>
            </mat-card>
            
            <mat-card appearance="outlined" class="daily" *ngIf="activeLink == 'Daily' || activeLink == 'All'">
                <h3>Daily Purge of Older Messages</h3>
                <div class="controls">
                    <mat-form-field>
                      <mat-label>Next Purge</mat-label>
                      <mat-select id="purgeOldMessagesDayOfWeek" [(ngModel)]="schedule.purgeOldMessagesDayOfWeek" placeholder="Next Purge" (selectionChange)="updateSchedule()" [disabled]="!permissions.roleAllowsUserToEditAdminSchedule || myUser.hasReadOnlyRestriction">
                          <mat-option *ngFor="let day of daysOfWeek" [value]="day">{{day}}</mat-option>
                      </mat-select>
                    </mat-form-field>
                    <button (click)="onBackwardOneDay('PurgeOldMessages')" mat-stroked-button color="primary" [disabled]="!permissions.roleAllowsUserToEditAdminSchedule || myUser.hasReadOnlyRestriction"><i class="fa fa-chevron-left"></i></button>
                    <span class="scheduled-date">{{schedule.purgeOldMessagesNext}}</span>
                    <button (click)="onForwardOneDay('PurgeOldMessages')" mat-stroked-button color="primary" [disabled]="!permissions.roleAllowsUserToEditAdminSchedule || myUser.hasReadOnlyRestriction"><i class="fa fa-chevron-right"></i></button>
                    <mat-form-field>
                      <mat-label>Time</mat-label>
                      <input matInput [(ngModel)]="schedule.purgeOldMessagesTimeOfDayView" placeholder="Time" type="time" [disabled]="!permissions.roleAllowsUserToEditAdminSchedule || myUser.hasReadOnlyRestriction" (blur)="updateSchedule()">
                    </mat-form-field>
                </div>
                <div class="controls">
                    <mat-form-field>
                      <mat-label>Days to Retain</mat-label>
                      <input matInput [(ngModel)]="schedule.messagesTimeToLiveInDays" placeholder="Days to Retain" [disabled]="!permissions.roleAllowsUserToEditAdminSchedule || myUser.hasReadOnlyRestriction" (blur)="updateSchedule()">
                    </mat-form-field>
                    <mat-form-field>
                      <mat-label>Last Started</mat-label>
                      <input matInput [(ngModel)]="schedule.purgeOldMessagesLastStarted" placeholder="Last Started" readonly>
                    </mat-form-field>
                    <mat-form-field>
                      <mat-label>Last Completed</mat-label>
                      <input matInput [(ngModel)]="schedule.purgeOldMessagesLastCompleted" placeholder="Last Completed" readonly>
                    </mat-form-field>
                </div>
                <div class="row">
                    <label>Last Execution: </label>
                    <span class="label-value successful-execution" *ngIf="schedule.purgeOldMessagesLastExecutionSuccessful"><i class="fa fa-check" aria-hidden="true"></i> Succeeded</span>
                    <span class="label-value failed-execution" *ngIf="!schedule.purgeOldMessagesLastExecutionSuccessful && schedule.purgeOldMessagesLastExecutionSuccessful != null"><i class="fa fa-times" aria-hidden="true"></i> Failed</span>
                    <span class="label-value" *ngIf="schedule.purgeOldMessagesLastExecutionSuccessful === null"> N/A</span>
                </div>
                <div class="processing" *ngIf="schedule.purgeOldMessagesExecutionRequested">
                    <div><i class="fa fa-cog fa-spin fa-lg fa-fw"></i> <strong>In Process</strong> as of {{formatDate(schedule.purgeOldMessagesExecutionRequested)}}</div>
                </div>
            </mat-card>

            <mat-card appearance="outlined" class="weekly" *ngIf="activeLink == 'Weekly' || activeLink == 'All'">
                <h3>Weekly Purge of Older Ad Items</h3>
                <div class="controls">
                    <mat-form-field>
                      <mat-label>Next Purge</mat-label>
                      <mat-select id="purgeOldAdItemsDayOfWeek" [(ngModel)]="schedule.purgeOldAdItemsDayOfWeek" placeholder="Next Purge" (selectionChange)="updateSchedule()" [disabled]="!permissions.roleAllowsUserToEditAdminSchedule || myUser.hasReadOnlyRestriction">
                          <mat-option *ngFor="let day of daysOfWeek" [value]="day">{{day}}</mat-option>
                      </mat-select>
                    </mat-form-field>
                    <button (click)="onBackwardOneWeek('PurgeOldAdItems')" mat-stroked-button color="primary" [disabled]="!permissions.roleAllowsUserToEditAdminSchedule || myUser.hasReadOnlyRestriction"><i class="fa fa-chevron-left"></i></button>
                    <span class="scheduled-date">{{schedule.purgeOldAdItemsNext}}</span>
                    <button (click)="onForwardOneWeek('PurgeOldAdItems')" mat-stroked-button color="primary" [disabled]="!permissions.roleAllowsUserToEditAdminSchedule || myUser.hasReadOnlyRestriction"><i class="fa fa-chevron-right"></i></button>
                    <mat-form-field>
                      <mat-label>Time</mat-label>
                      <input matInput [(ngModel)]="schedule.purgeOldAdItemsTimeOfDayView" placeholder="Time" type="time" [disabled]="!permissions.roleAllowsUserToEditAdminSchedule || myUser.hasReadOnlyRestriction" (blur)="updateSchedule()">
                    </mat-form-field>
                </div>
                <div class="controls">
                    <mat-form-field>
                      <mat-label>Weeks to Retain</mat-label>
                      <input matInput [(ngModel)]="schedule.adItemsTimeToLiveInWeeks" placeholder="Weeks to Retain" [disabled]="!permissions.roleAllowsUserToEditAdminSchedule || myUser.hasReadOnlyRestriction" (blur)="updateSchedule()">
                    </mat-form-field>
                    <mat-form-field>
                      <mat-label>Last Started</mat-label>
                      <input matInput [(ngModel)]="schedule.purgeOldAdItemsLastStarted" placeholder="Last Started" readonly>
                    </mat-form-field>
                    <mat-form-field>
                      <mat-label>Last Completed</mat-label>
                      <input matInput [(ngModel)]="schedule.purgeOldAdItemsLastCompleted" placeholder="Last Completed" readonly>
                    </mat-form-field>
                </div>
                <div class="row">
                    <label>Last Execution: </label>
                    <span class="label-value successful-execution" *ngIf="schedule.purgeOldAdItemsLastExecutionSuccessful"><i class="fa fa-check" aria-hidden="true"></i> Succeeded</span>
                    <span class="label-value failed-execution" *ngIf="!schedule.purgeOldAdItemsLastExecutionSuccessful && schedule.purgeOldAdItemsLastExecutionSuccessful != null"><i class="fa fa-times" aria-hidden="true"></i> Failed</span>
                    <span class="label-value" *ngIf="schedule.purgeOldAdItemsLastExecutionSuccessful === null"> N/A</span>
                </div>
                <div class="processing" *ngIf="schedule.purgeOldAdItemsExecutionRequested">
                    <div><i class="fa fa-cog fa-spin fa-lg fa-fw"></i> <strong>In Process</strong> as of {{formatDate(schedule.purgeOldAdItemsExecutionRequested)}}</div>
                </div>
            </mat-card>

            <mat-card appearance="outlined" class="weekly" *ngIf="activeLink == 'Weekly' || activeLink == 'All'">
                <h3>Weekly Purge of Older Store Item Price Changes</h3>
                <div class="controls">
                    <mat-form-field>
                      <mat-label>Next Purge</mat-label>
                      <mat-select id="purgeOldStoreItemPriceChangesDayOfWeek" [(ngModel)]="schedule.purgeOldStoreItemPriceChangesDayOfWeek" placeholder="Next Purge" (selectionChange)="updateSchedule()" [disabled]="!permissions.roleAllowsUserToEditAdminSchedule || myUser.hasReadOnlyRestriction">
                          <mat-option *ngFor="let day of daysOfWeek" [value]="day">{{day}}</mat-option>
                      </mat-select>
                    </mat-form-field>
                    <button (click)="onBackwardOneWeek('PurgeOldStoreItemPriceChanges')" mat-stroked-button color="primary" [disabled]="!permissions.roleAllowsUserToEditAdminSchedule || myUser.hasReadOnlyRestriction"><i class="fa fa-chevron-left"></i></button>
                    <span class="scheduled-date">{{schedule.purgeOldStoreItemPriceChangesNext}}</span>
                    <button (click)="onForwardOneWeek('PurgeOldStoreItemPriceChanges')" mat-stroked-button color="primary" [disabled]="!permissions.roleAllowsUserToEditAdminSchedule || myUser.hasReadOnlyRestriction"><i class="fa fa-chevron-right"></i></button>
                    <mat-form-field>
                      <mat-label>Time</mat-label>
                      <input matInput [(ngModel)]="schedule.purgeOldStoreItemPriceChangesTimeOfDayView" placeholder="Time" type="time" [disabled]="!permissions.roleAllowsUserToEditAdminSchedule || myUser.hasReadOnlyRestriction" (blur)="updateSchedule()">
                    </mat-form-field>
                </div>
                <div class="controls">
                    <mat-form-field>
                      <mat-label>Weeks to Retain</mat-label>
                      <input matInput [(ngModel)]="schedule.storeItemPriceChangesTimeToLiveInWeeks" placeholder="Weeks to Retain" [disabled]="!permissions.roleAllowsUserToEditAdminSchedule || myUser.hasReadOnlyRestriction" (blur)="updateSchedule()">
                    </mat-form-field>
                    <mat-form-field>
                      <mat-label>Last Started</mat-label>
                      <input matInput [(ngModel)]="schedule.purgeOldStoreItemPriceChangesLastStarted" placeholder="Last Started" readonly>
                    </mat-form-field>
                    <mat-form-field>
                      <mat-label>Last Completed</mat-label>
                      <input matInput [(ngModel)]="schedule.purgeOldStoreItemPriceChangesLastCompleted" placeholder="Last Completed" readonly>
                    </mat-form-field>
                </div>
                <div class="row">
                    <label>Last Execution: </label>
                    <span class="label-value successful-execution" *ngIf="schedule.purgeOldStoreItemPriceChangesLastExecutionSuccessful"><i class="fa fa-check" aria-hidden="true"></i> Succeeded</span>
                    <span class="label-value failed-execution" *ngIf="!schedule.purgeOldStoreItemPriceChangesLastExecutionSuccessful && schedule.purgeOldStoreItemPriceChangesLastExecutionSuccessful != null"><i class="fa fa-times" aria-hidden="true"></i> Failed</span>
                    <span class="label-value" *ngIf="schedule.purgeOldStoreItemPriceChangesLastExecutionSuccessful === null"> N/A</span>
                </div>
                <div class="processing" *ngIf="schedule.purgeOldStoreItemPriceChangesExecutionRequested">
                    <div><i class="fa fa-cog fa-spin fa-lg fa-fw"></i> <strong>In Process</strong> as of {{formatDate(schedule.purgeOldStoreItemPriceChangesExecutionRequested)}}</div>
                </div>
            </mat-card>

            <mat-card appearance="outlined" class="weekly" *ngIf="activeLink == 'Weekly' || activeLink == 'All'">
                <h3>Weekly Purge of Older Store Item Cost Changes</h3>
                <div class="controls">
                    <mat-form-field>
                      <mat-label>Next Purge</mat-label>
                      <mat-select id="purgeOldStoreItemCostChangesDayOfWeek" [(ngModel)]="schedule.purgeOldStoreItemCostChangesDayOfWeek" placeholder="Next Purge" (selectionChange)="updateSchedule()" [disabled]="!permissions.roleAllowsUserToEditAdminSchedule || myUser.hasReadOnlyRestriction">
                          <mat-option *ngFor="let day of daysOfWeek" [value]="day">{{day}}</mat-option>
                      </mat-select>
                    </mat-form-field>
                    <button (click)="onBackwardOneWeek('PurgeOldStoreItemCostChanges')" mat-stroked-button color="primary" [disabled]="!permissions.roleAllowsUserToEditAdminSchedule || myUser.hasReadOnlyRestriction"><i class="fa fa-chevron-left"></i></button>
                    <span class="scheduled-date">{{schedule.purgeOldStoreItemCostChangesNext}}</span>
                    <button (click)="onForwardOneWeek('PurgeOldStoreItemCostChanges')" mat-stroked-button color="primary" [disabled]="!permissions.roleAllowsUserToEditAdminSchedule || myUser.hasReadOnlyRestriction"><i class="fa fa-chevron-right"></i></button>
                    <mat-form-field>
                      <mat-label>Time</mat-label>
                      <input matInput [(ngModel)]="schedule.purgeOldStoreItemCostChangesTimeOfDayView" placeholder="Time" type="time" [disabled]="!permissions.roleAllowsUserToEditAdminSchedule || myUser.hasReadOnlyRestriction" (blur)="updateSchedule()">
                    </mat-form-field>
                </div>
                <div class="controls">
                    <mat-form-field>
                      <mat-label>Weeks to Retain</mat-label>
                      <input matInput [(ngModel)]="schedule.storeItemCostChangesTimeToLiveInWeeks" placeholder="Weeks to Retain" [disabled]="!permissions.roleAllowsUserToEditAdminSchedule || myUser.hasReadOnlyRestriction" (blur)="updateSchedule()">
                    </mat-form-field>
                    <mat-form-field>
                      <mat-label>Last Started</mat-label>
                      <input matInput [(ngModel)]="schedule.purgeOldStoreItemCostChangesLastStarted" placeholder="Last Started" readonly>
                    </mat-form-field>
                    <mat-form-field>
                      <mat-label>Last Completed</mat-label>
                      <input matInput [(ngModel)]="schedule.purgeOldStoreItemCostChangesLastCompleted" placeholder="Last Completed" readonly>
                    </mat-form-field>
                </div>
                <div class="row">
                    <label>Last Execution: </label>
                    <span class="label-value successful-execution" *ngIf="schedule.purgeOldStoreItemCostChangesLastExecutionSuccessful"><i class="fa fa-check" aria-hidden="true"></i> Succeeded</span>
                    <span class="label-value failed-execution" *ngIf="!schedule.purgeOldStoreItemCostChangesLastExecutionSuccessful && schedule.purgeOldStoreItemCostChangesLastExecutionSuccessful != null"><i class="fa fa-times" aria-hidden="true"></i> Failed</span>
                    <span class="label-value" *ngIf="schedule.purgeOldStoreItemCostChangesLastExecutionSuccessful === null"> N/A</span>
                </div>
                <div class="processing" *ngIf="schedule.purgeOldStoreItemCostChangesExecutionRequested">
                    <div><i class="fa fa-cog fa-spin fa-lg fa-fw"></i> <strong>In Process</strong> as of {{formatDate(schedule.purgeOldStoreItemCostChangesExecutionRequested)}}</div>
                </div>
            </mat-card>

            <mat-card appearance="outlined" class="weekly" *ngIf="activeLink == 'Weekly' || activeLink == 'All'">
              <h3>Weekly Purge of Older Archival Preview Store Item Price Changes</h3>
              <div class="controls">
                  <mat-form-field>
                    <mat-label>Next Purge</mat-label>
                    <mat-select id="purgeOldDenormalizedPreviewStoreItemPriceChangesDayOfWeek" [(ngModel)]="schedule.purgeOldDenormalizedPreviewStoreItemPriceChangesDayOfWeek" placeholder="Next Purge" (selectionChange)="updateSchedule()" [disabled]="!permissions.roleAllowsUserToEditAdminSchedule || myUser.hasReadOnlyRestriction">
                        <mat-option *ngFor="let day of daysOfWeek" [value]="day">{{day}}</mat-option>
                    </mat-select>
                  </mat-form-field>
                  <button (click)="onBackwardOneWeek('PurgeOldDenormalizedPreviewStoreItemPriceChanges')" mat-stroked-button color="primary" [disabled]="!permissions.roleAllowsUserToEditAdminSchedule || myUser.hasReadOnlyRestriction"><i class="fa fa-chevron-left"></i></button>
                  <span class="scheduled-date">{{schedule.purgeOldDenormalizedPreviewStoreItemPriceChangesNext}}</span>
                  <button (click)="onForwardOneWeek('PurgeOldDenormalizedPreviewStoreItemPriceChanges')" mat-stroked-button color="primary" [disabled]="!permissions.roleAllowsUserToEditAdminSchedule || myUser.hasReadOnlyRestriction"><i class="fa fa-chevron-right"></i></button>
                  <mat-form-field>
                    <mat-label>Time</mat-label>
                    <input matInput [(ngModel)]="schedule.purgeOldDenormalizedPreviewStoreItemPriceChangesTimeOfDayView" placeholder="Time" type="time" [disabled]="!permissions.roleAllowsUserToEditAdminSchedule || myUser.hasReadOnlyRestriction" (blur)="updateSchedule()">
                  </mat-form-field>
              </div>
              <div class="controls">
                  <mat-form-field>
                    <mat-label>Weeks to Retain</mat-label>
                    <input matInput [(ngModel)]="schedule.denormalizedPreviewStoreItemPriceChangesTimeToLiveInWeeks" placeholder="Weeks to Retain" [disabled]="!permissions.roleAllowsUserToEditAdminSchedule || myUser.hasReadOnlyRestriction" (blur)="updateSchedule()">
                  </mat-form-field>
                  <mat-form-field>
                    <mat-label>Last Started</mat-label>
                    <input matInput [(ngModel)]="schedule.purgeOldDenormalizedPreviewStoreItemPriceChangesLastStarted" placeholder="Last Started" readonly>
                  </mat-form-field>
                  <mat-form-field>
                    <mat-label>Last Completed</mat-label>
                    <input matInput [(ngModel)]="schedule.purgeOldDenormalizedPreviewStoreItemPriceChangesLastCompleted" placeholder="Last Completed" readonly>
                  </mat-form-field>
              </div>
              <div class="row">
                  <label>Last Execution: </label>
                  <span class="label-value successful-execution" *ngIf="schedule.purgeOldDenormalizedPreviewStoreItemPriceChangesLastExecutionSuccessful"><i class="fa fa-check" aria-hidden="true"></i> Succeeded</span>
                  <span class="label-value failed-execution" *ngIf="!schedule.purgeOldDenormalizedPreviewStoreItemPriceChangesLastExecutionSuccessful && schedule.purgeOldDenormalizedPreviewStoreItemPriceChangesLastExecutionSuccessful != null"><i class="fa fa-times" aria-hidden="true"></i> Failed</span>
                  <span class="label-value" *ngIf="schedule.purgeOldDenormalizedPreviewStoreItemPriceChangesLastExecutionSuccessful === null"> N/A</span>
              </div>
              <div class="processing" *ngIf="schedule.purgeOldDenormalizedPreviewStoreItemPriceChangesExecutionRequested">
                  <div><i class="fa fa-cog fa-spin fa-lg fa-fw"></i> <strong>In Process</strong> as of {{formatDate(schedule.purgeOldDenormalizedPreviewStoreItemPriceChangesExecutionRequested)}}</div>
              </div>
            </mat-card>

            <mat-card appearance="outlined" class="weekly" *ngIf="activeLink == 'Weekly' || activeLink == 'All'">
                <!-- <h3>Weekly Update of Store Item Pricing from Grocery Preview Pricing</h3> -->
                <h3>Weekly Status Reset and Cleanup (Grocery)</h3>
                <div class="controls">
                    <mat-form-field>
                      <mat-label>Next Update</mat-label>
                      <mat-select id="updateStoreItemsFromPreviewStoreItemsDayOfWeek" [(ngModel)]="schedule.updateStoreItemsFromPreviewStoreItemsDayOfWeek" placeholder="Next Update" (selectionChange)="updateSchedule()" [disabled]="!permissions.roleAllowsUserToEditAdminSchedule || myUser.hasReadOnlyRestriction">
                          <mat-option *ngFor="let day of daysOfWeek" [value]="day">{{day}}</mat-option>
                      </mat-select>
                    </mat-form-field>
                    <button (click)="onBackwardOneWeek('UpdateStoreItemsFromPreviewStoreItems')" mat-stroked-button color="primary" [disabled]="!permissions.roleAllowsUserToEditAdminSchedule || myUser.hasReadOnlyRestriction"><i class="fa fa-chevron-left"></i></button>
                    <span class="scheduled-date">{{schedule.updateStoreItemsFromPreviewStoreItemsNext}}</span>
                    <button (click)="onForwardOneWeek('UpdateStoreItemsFromPreviewStoreItems')" mat-stroked-button color="primary" [disabled]="!permissions.roleAllowsUserToEditAdminSchedule || myUser.hasReadOnlyRestriction"><i class="fa fa-chevron-right"></i></button>
                    <mat-form-field>
                      <mat-label>Time</mat-label>
                      <input matInput [(ngModel)]="schedule.updateStoreItemsFromPreviewStoreItemsTimeOfDayView" placeholder="Time" type="time" [disabled]="!permissions.roleAllowsUserToEditAdminSchedule || myUser.hasReadOnlyRestriction" (blur)="updateSchedule()">
                    </mat-form-field>
                </div>
                <div class="controls">
                    <mat-form-field>
                      <mat-label>Last Started</mat-label>
                      <input matInput [(ngModel)]="schedule.updateStoreItemsFromPreviewStoreItemsLastStarted" placeholder="Last Started" readonly>
                    </mat-form-field>
                    <mat-form-field>
                      <mat-label>Last Completed</mat-label>
                      <input matInput [(ngModel)]="schedule.updateStoreItemsFromPreviewStoreItemsLastCompleted" placeholder="Last Completed" readonly>
                    </mat-form-field>
                </div>
                <div class="row">
                    <label>Last Execution: </label>
                    <span class="label-value successful-execution" *ngIf="schedule.updateStoreItemsFromPreviewStoreItemsLastExecutionSuccessful"><i class="fa fa-check" aria-hidden="true"></i> Succeeded</span>
                    <span class="label-value failed-execution" *ngIf="!schedule.updateStoreItemsFromPreviewStoreItemsLastExecutionSuccessful && schedule.updateStoreItemsFromPreviewStoreItemsLastExecutionSuccessful != null"><i class="fa fa-times" aria-hidden="true"></i> Failed</span>
                    <span class="label-value" *ngIf="schedule.updateStoreItemsFromPreviewStoreItemsLastExecutionSuccessful === null"> N/A</span>
                </div>
                <div class="processing" *ngIf="schedule.updateStoreItemsFromPreviewStoreItemsExecutionRequested">
                    <div><i class="fa fa-cog fa-spin fa-lg fa-fw"></i> <strong>In Process</strong> as of {{formatDate(schedule.updateStoreItemsFromPreviewStoreItemsExecutionRequested)}}</div>
                </div>
            </mat-card>
            
            <mat-card appearance="outlined" class="weekly" *ngIf="activeLink == 'Weekly' || activeLink == 'All'">
                <!-- <h3>Weekly Update of Store Item Pricing from Meat and Produce Preview Pricing</h3> -->
                <h3>Weekly Status Reset and Cleanup (Meat and Produce)</h3>
                <div class="controls">
                    <mat-form-field>
                      <mat-label>Next Update</mat-label>
                      <mat-select id="updateStoreItemsFromMeatAndProducePreviewStoreItemsDayOfWeek" [(ngModel)]="schedule.updateStoreItemsFromMeatAndProducePreviewStoreItemsDayOfWeek" placeholder="Next Update" (selectionChange)="updateSchedule()" [disabled]="!permissions.roleAllowsUserToEditAdminSchedule || myUser.hasReadOnlyRestriction">
                          <mat-option *ngFor="let day of daysOfWeek" [value]="day">{{day}}</mat-option>
                      </mat-select>
                    </mat-form-field>
                    <button (click)="onBackwardOneWeek('UpdateStoreItemsFromMeatAndProducePreviewStoreItems')" mat-stroked-button color="primary" [disabled]="!permissions.roleAllowsUserToEditAdminSchedule || myUser.hasReadOnlyRestriction"><i class="fa fa-chevron-left"></i></button>
                    <span class="scheduled-date">{{schedule.updateStoreItemsFromMeatAndProducePreviewStoreItemsNext}}</span>
                    <button (click)="onForwardOneWeek('UpdateStoreItemsFromMeatAndProducePreviewStoreItems')" mat-stroked-button color="primary" [disabled]="!permissions.roleAllowsUserToEditAdminSchedule || myUser.hasReadOnlyRestriction"><i class="fa fa-chevron-right"></i></button>
                    <mat-form-field>
                      <mat-label>Time</mat-label>
                      <input matInput [(ngModel)]="schedule.updateStoreItemsFromMeatAndProducePreviewStoreItemsTimeOfDayView" placeholder="Time" type="time" [disabled]="!permissions.roleAllowsUserToEditAdminSchedule || myUser.hasReadOnlyRestriction" (blur)="updateSchedule()">
                    </mat-form-field>
                </div>
                <div class="controls">
                    <mat-form-field>
                      <mat-label>Last Started</mat-label>
                      <input matInput [(ngModel)]="schedule.updateStoreItemsFromMeatAndProducePreviewStoreItemsLastStarted" placeholder="Last Started" readonly>
                    </mat-form-field>
                    <mat-form-field>
                      <mat-label>Last Completed</mat-label>
                      <input matInput [(ngModel)]="schedule.updateStoreItemsFromMeatAndProducePreviewStoreItemsLastCompleted" placeholder="Last Completed" readonly>
                    </mat-form-field>
                </div>
                <div class="row">
                    <label>Last Execution: </label>
                    <span class="label-value successful-execution" *ngIf="schedule.updateStoreItemsFromMeatAndProducePreviewStoreItemsLastExecutionSuccessful"><i class="fa fa-check" aria-hidden="true"></i> Succeeded</span>
                    <span class="label-value failed-execution" *ngIf="!schedule.updateStoreItemsFromMeatAndProducePreviewStoreItemsLastExecutionSuccessful && schedule.updateStoreItemsFromMeatAndProducePreviewStoreItemsLastExecutionSuccessful != null"><i class="fa fa-times" aria-hidden="true"></i> Failed</span>
                    <span class="label-value" *ngIf="schedule.updateStoreItemsFromMeatAndProducePreviewStoreItemsLastExecutionSuccessful === null"> N/A</span>
                </div>
                <div class="processing" *ngIf="schedule.updateStoreItemsFromMeatAndProducePreviewStoreItemsExecutionRequested">
                    <div><i class="fa fa-cog fa-spin fa-lg fa-fw"></i> <strong>In Process</strong> as of {{formatDate(schedule.updateStoreItemsFromMeatAndProducePreviewStoreItemsExecutionRequested)}}</div>
                </div>
            </mat-card>
            
            <mat-card appearance="outlined" class="weekly" *ngIf="activeLink == 'Weekly' || activeLink == 'All'">
                <h3>Weekly Deletion of Meat and Produce Preview Store Items</h3>
                <div class="controls">
                    <mat-form-field>
                      <mat-label>Next Deletion</mat-label>
                      <mat-select id="deleteMeatAndProducePreviewStoreItemsDayOfWeek" [(ngModel)]="schedule.deleteMeatAndProducePreviewStoreItemsDayOfWeek" placeholder="Next Deletion" (selectionChange)="updateSchedule()" [disabled]="!permissions.roleAllowsUserToEditAdminSchedule || myUser.hasReadOnlyRestriction">
                          <mat-option *ngFor="let day of daysOfWeek" [value]="day">{{day}}</mat-option>
                      </mat-select>
                    </mat-form-field>
                    <button (click)="onBackwardOneWeek('DeleteMeatAndProducePreviewStoreItems')" mat-stroked-button color="primary" [disabled]="!permissions.roleAllowsUserToEditAdminSchedule || myUser.hasReadOnlyRestriction"><i class="fa fa-chevron-left"></i></button>
                    <span class="scheduled-date">{{schedule.deleteMeatAndProducePreviewStoreItemsNext}}</span>
                    <button (click)="onForwardOneWeek('DeleteMeatAndProducePreviewStoreItems')" mat-stroked-button color="primary" [disabled]="!permissions.roleAllowsUserToEditAdminSchedule || myUser.hasReadOnlyRestriction"><i class="fa fa-chevron-right"></i></button>
                    <mat-form-field>
                      <mat-label>Time</mat-label>
                      <input matInput [(ngModel)]="schedule.deleteMeatAndProducePreviewStoreItemsTimeOfDayView" placeholder="Time" type="time" [disabled]="!permissions.roleAllowsUserToEditAdminSchedule || myUser.hasReadOnlyRestriction" (blur)="updateSchedule()">
                    </mat-form-field>
                </div>
                <div class="controls">
                    <mat-form-field>
                      <mat-label>Last Started</mat-label>
                      <input matInput [(ngModel)]="schedule.deleteMeatAndProducePreviewStoreItemsLastStarted" placeholder="Last Started" readonly>
                    </mat-form-field>
                    <mat-form-field>
                      <mat-label>Last Completed</mat-label>
                      <input matInput [(ngModel)]="schedule.deleteMeatAndProducePreviewStoreItemsLastCompleted" placeholder="Last Completed" readonly>
                    </mat-form-field>
                </div>
                <div class="row">
                    <label>Last Execution: </label>
                    <span class="label-value successful-execution" *ngIf="schedule.deleteMeatAndProducePreviewStoreItemsLastExecutionSuccessful"><i class="fa fa-check" aria-hidden="true"></i> Succeeded</span>
                    <span class="label-value failed-execution" *ngIf="!schedule.deleteMeatAndProducePreviewStoreItemsLastExecutionSuccessful && schedule.deleteMeatAndProducePreviewStoreItemsLastExecutionSuccessful != null"><i class="fa fa-times" aria-hidden="true"></i> Failed</span>
                    <span class="label-value" *ngIf="schedule.deleteMeatAndProducePreviewStoreItemsLastExecutionSuccessful === null"> N/A</span>
                </div>
                <div class="processing" *ngIf="schedule.deleteMeatAndProducePreviewStoreItemsExecutionRequested">
                    <div><i class="fa fa-cog fa-spin fa-lg fa-fw"></i> <strong>In Process</strong> as of {{formatDate(schedule.deleteMeatAndProducePreviewStoreItemsExecutionRequested)}}</div>
                </div>
            </mat-card>
            
            <mat-card appearance="outlined" class="weekly" *ngIf="activeLink == 'Weekly' || activeLink == 'All'">
                <h3>Weekly Increment of Store Item Weeks in Store</h3>
                <div class="controls">
                    <mat-form-field>
                      <mat-label>Next Increment</mat-label>
                      <mat-select id="incrementStoreItemsWeeksInStoreDayOfWeek" [(ngModel)]="schedule.incrementStoreItemsWeeksInStoreDayOfWeek" placeholder="Next Increment" (selectionChange)="updateSchedule()" [disabled]="!permissions.roleAllowsUserToEditAdminSchedule || myUser.hasReadOnlyRestriction">
                          <mat-option *ngFor="let day of daysOfWeek" [value]="day">{{day}}</mat-option>
                      </mat-select>
                    </mat-form-field>
                    <button (click)="onBackwardOneWeek('IncrementStoreItemsWeeksInStore')" mat-stroked-button color="primary" [disabled]="!permissions.roleAllowsUserToEditAdminSchedule || myUser.hasReadOnlyRestriction"><i class="fa fa-chevron-left"></i></button>
                    <span class="scheduled-date">{{schedule.incrementStoreItemsWeeksInStoreNext}}</span>
                    <button (click)="onForwardOneWeek('IncrementStoreItemsWeeksInStore')" mat-stroked-button color="primary" [disabled]="!permissions.roleAllowsUserToEditAdminSchedule || myUser.hasReadOnlyRestriction"><i class="fa fa-chevron-right"></i></button>
                    <mat-form-field>
                      <mat-label>Time</mat-label>
                      <input matInput [(ngModel)]="schedule.incrementStoreItemsWeeksInStoreTimeOfDayView" placeholder="Time" type="time" [disabled]="!permissions.roleAllowsUserToEditAdminSchedule || myUser.hasReadOnlyRestriction" (blur)="updateSchedule()">
                    </mat-form-field>
                </div>
                <div class="controls">
                    <mat-form-field>
                      <mat-label>Last Started</mat-label>
                      <input matInput [(ngModel)]="schedule.incrementStoreItemsWeeksInStoreLastStarted" placeholder="Last Started" readonly>
                    </mat-form-field>
                    <mat-form-field>
                      <mat-label>Last Completed</mat-label>
                      <input matInput [(ngModel)]="schedule.incrementStoreItemsWeeksInStoreLastCompleted" placeholder="Last Completed" readonly>
                    </mat-form-field>
                </div>
                <div class="row">
                    <label>Last Execution: </label>
                    <span class="label-value successful-execution" *ngIf="schedule.incrementStoreItemsWeeksInStoreLastExecutionSuccessful"><i class="fa fa-check" aria-hidden="true"></i> Succeeded</span>
                    <span class="label-value failed-execution" *ngIf="!schedule.incrementStoreItemsWeeksInStoreLastExecutionSuccessful && schedule.incrementStoreItemsWeeksInStoreLastExecutionSuccessful != null"><i class="fa fa-times" aria-hidden="true"></i> Failed</span>
                    <span class="label-value" *ngIf="schedule.incrementStoreItemsWeeksInStoreLastExecutionSuccessful === null"> N/A</span>
                </div>
                <div class="processing" *ngIf="schedule.incrementStoreItemsWeeksInStoreExecutionRequested">
                    <div><i class="fa fa-cog fa-spin fa-lg fa-fw"></i> <strong>In Process</strong> as of {{formatDate(schedule.incrementStoreItemsWeeksInStoreExecutionRequested)}}</div>
                </div>
            </mat-card>
            
            <mat-card appearance="outlined" class="yearly" *ngIf="activeLink == 'Yearly' || activeLink == 'All'">
                <h3>Yearly Reset of Store Item Movement and Weeks in Store</h3>
                <div class="controls">
                    <mat-form-field>
                      <mat-label>Next Reset</mat-label>
                      <mat-select id="resetStoreItemsMovementAndWeeksInStoreDayOfWeek" [(ngModel)]="schedule.resetStoreItemsMovementAndWeeksInStoreDayOfWeek" placeholder="Next Reset" (selectionChange)="updateSchedule()" [disabled]="!permissions.roleAllowsUserToEditAdminSchedule || myUser.hasReadOnlyRestriction">
                          <mat-option *ngFor="let day of daysOfWeek" [value]="day">{{day}}</mat-option>
                      </mat-select>
                    </mat-form-field>
                    <button (click)="onBackwardOneYear('ResetStoreItemsMovementAndWeeksInStore')" mat-stroked-button color="primary" [disabled]="!permissions.roleAllowsUserToEditAdminSchedule || myUser.hasReadOnlyRestriction"><i class="fa fa-chevron-left"></i></button>
                    <span class="scheduled-date">{{schedule.resetStoreItemsMovementAndWeeksInStoreNext}}</span>
                    <button (click)="onForwardOneYear('ResetStoreItemsMovementAndWeeksInStore')" mat-stroked-button color="primary" [disabled]="!permissions.roleAllowsUserToEditAdminSchedule || myUser.hasReadOnlyRestriction"><i class="fa fa-chevron-right"></i></button>
                    <mat-form-field>
                      <mat-label>Time</mat-label>
                      <input matInput [(ngModel)]="schedule.resetStoreItemsMovementAndWeeksInStoreTimeOfDayView" placeholder="Time" type="time" [disabled]="!permissions.roleAllowsUserToEditAdminSchedule || myUser.hasReadOnlyRestriction" (blur)="updateSchedule()">
                    </mat-form-field>
                </div>
                <div class="controls">
                    <mat-form-field>
                      <mat-label>Last Started</mat-label>
                      <input matInput [(ngModel)]="schedule.resetStoreItemsMovementAndWeeksInStoreLastStarted" placeholder="Last Started" readonly>
                    </mat-form-field>
                    <mat-form-field>
                      <mat-label>Last Completed</mat-label>
                      <input matInput [(ngModel)]="schedule.resetStoreItemsMovementAndWeeksInStoreLastCompleted" placeholder="Last Completed" readonly>
                    </mat-form-field>
                </div>
                <div class="row">
                    <label>Last Execution: </label>
                    <span class="label-value successful-execution" *ngIf="schedule.resetStoreItemsMovementAndWeeksInStoreLastExecutionSuccessful"><i class="fa fa-check" aria-hidden="true"></i> Succeeded</span>
                    <span class="label-value failed-execution" *ngIf="!schedule.resetStoreItemsMovementAndWeeksInStoreLastExecutionSuccessful && schedule.resetStoreItemsMovementAndWeeksInStoreLastExecutionSuccessful != null"><i class="fa fa-times" aria-hidden="true"></i> Failed</span>
                    <span class="label-value" *ngIf="schedule.resetStoreItemsMovementAndWeeksInStoreLastExecutionSuccessful === null"> N/A</span>
                </div>
                <div class="processing" *ngIf="schedule.resetStoreItemsMovementAndWeeksInStoreExecutionRequested">
                    <div><i class="fa fa-cog fa-spin fa-lg fa-fw"></i> <strong>In Process</strong> as of {{formatDate(schedule.resetStoreItemsMovementAndWeeksInStoreExecutionRequested)}}</div>
                </div>
            </mat-card>
            
    </mat-expansion-panel>

    <!-- <mat-expansion-panel [expanded]="isOnDemandPanelOpen" (opened)="isOnDemandPanelOpen = true" (closed)="isOnDemandPanelOpen = false">
        <mat-expansion-panel-header>
        <mat-panel-title>
            <i class="far fa-hand-point-right"></i>On Demand Processing
        </mat-panel-title>
        <mat-panel-description>
            Processing Pricing Rules
        </mat-panel-description>
        </mat-expansion-panel-header>
        <mat-card *ngIf="activeLink == 'All'">
            <h3>On Demand Processing of Pricing Rules</h3>
            <div class="row" style="margin-top:16px">
                <button mat-raised-button color="primary" (click)="invokePricingRulesProcess()" [disabled]="!permissions.roleAllowsUserToEditAdminSchedule || myUser.hasReadOnlyRestriction">Invoke Process</button><span style="padding-left: 15px;">{{pricingRulesMessage}}</span>
            </div>
            <div class="controls">
                <mat-form-field>
                    <input matInput [(ngModel)]="schedule.processPricingRulesLastStarted" placeholder="Last Started" readonly>
                </mat-form-field>
                <mat-form-field>
                    <input matInput [(ngModel)]="schedule.processPricingRulesLastCompleted" placeholder="Last Completed" readonly>
                </mat-form-field>
            </div>
            <div class="row">
                <label>Last Execution: </label>
                <span class="label-value successful-execution" *ngIf="schedule.processPricingRulesLastExecutionSuccessful"><i class="fa fa-check" aria-hidden="true"></i> Succeeded</span>
                <span class="label-value failed-execution" *ngIf="!schedule.processPricingRulesLastExecutionSuccessful && schedule.processPricingRulesLastExecutionSuccessful != null"><i class="fa fa-times" aria-hidden="true"></i> Failed</span>
                <span class="label-value" *ngIf="schedule.processPricingRulesLastExecutionSuccessful === null"> N/A</span>
            </div>
            <div class="processing" *ngIf="schedule.processPricingRulesExecutionRequested">
                <div><i class="fa fa-cog fa-spin fa-lg fa-fw"></i> <strong>In Process</strong> as of {{formatDate(schedule.processPricingRulesExecutionRequested)}}</div>
            </div>
        </mat-card>
    </mat-expansion-panel> -->

</mat-accordion>
</div>

    
    
    
    
    
