import { Component, OnInit, ViewChild } from '@angular/core';
import { AgGridAngular } from 'ag-grid-angular';
import { GridOptions } from 'ag-grid-community';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from 'src/app/shared/confirmation-dialog/confirmation-dialog.component';
import { StoreService } from 'src/app/shared/store.service';
import { PriceManagementService } from 'src/app/shared/price-management.service';
import { GridService } from 'src/app/shared/grid.service';
import { MessageService } from 'src/app/shared/message.service';
import { ViewDetailButtonComponent } from 'src/app/shared/view-detail-button/view-detail-button.component';
import { DeleteButtonComponent } from 'src/app/shared/delete-button/delete-button.component';
import { PermissionsService } from 'src/app/shared/permissions.service';
import { RpmsMainframeOperationsService } from 'src/app/shared/rpms-mainframe-operations.service';
import { AuthenticationService } from 'src/app/core/authentication/shared/authentication.service';

@Component({
  selector: "app-admin-tpr-tag-list",
  templateUrl: "./admin-tpr-tag-list.component.html",
  styleUrls: ["./admin-tpr-tag-list.component.css"],
})
export class AdminTprTagListComponent implements OnInit {
  @ViewChild('agGrid') agGrid: AgGridAngular;

  //adminDetailRef: MatDialogRef<AdminShelfTagDetailDialog>;
  confirmationDialogRef: MatDialogRef<ConfirmationDialogComponent>;

  gridOptions: GridOptions;
  quickFilterText: string = "";
  private context; //required by grid
  //private frameworkComponents; //required by grid
  stores;
  store;
  isDataLoaded: boolean = false;
  permissions: any = {};
  myUser;

  rowData = [];
  getColumnDefs() {
    return [
      {
        headerName: "Item Code",
        field: "itemCode",
        cellClass: "itemCode-col",
        filter: "agTextColumnFilter",
        filterParams: {
          defaultOption: "startsWith",
        },
        get headerTooltip() {
          return this.headerName;
        },
      },
      {
        headerName: "Description",
        field: "description",
        cellClass: "description-col",
        filter: "agTextColumnFilter",
        get headerTooltip() {
          return this.headerName;
        },
      },
      {
        headerName: "Request Date",
        field: "requestDate",
        cellClass: "requestDate-col",
        cellRenderer: this._gridService.dateTimeRenderer,
        filter: "agDateColumnFilter",
        filterParams: {
          comparator: this._gridService.dateComparator,
          inRangeInclusive: true,
        },
        get headerTooltip() {
          return this.headerName;
        },
      },
      {
        headerName: "Requested",
        field: "requested",
        cellClass: "requested-col",
        filter: "agSetColumnFilter",
        get headerTooltip() {
          return this.headerName;
        },
      },
      {
        headerName: "Compare At Book",
        field: "book",
        cellClass: "book-col",
        filter: "agTextColumnFilter",
        get headerTooltip() {
          return this.headerName;
        },
      },
      {
        headerName: "",
        field: "delete",
        width: 80,
        minWidth: 80,
        maxWidth: 80,
        cellClass: ["delete-col", "tac"],
        cellRenderer: this.permissions.roleAllowsUserToDeleteTprTag && !this.myUser.hasReadOnlyRestriction
          ? "DeleteButtonComponent"
          : null,
        sortable: false,
        filter: false,
        suppressNavigable: true,
        hide: !this.permissions.roleAllowsUserToDeleteTprTag,
      },
    ];
  }

  constructor(
    private _storeService: StoreService,
    private _priceManagementService: PriceManagementService,
    private _rpmsMainframeOperationsService: RpmsMainframeOperationsService,
    private _authenticationService: AuthenticationService,
    private _gridService: GridService,
    private _dialog: MatDialog, 
    private _permissionsService: PermissionsService,
    private _messageService: MessageService
  ) {
    this.myUser = this._authenticationService.getMyUser();
    this.permissions = this._permissionsService.getPermissions();
    this.gridOptions = <GridOptions>{
      columnDefs: this.getColumnDefs(),
      rowData: this.rowData,
      rowHeight: 35,
      components: {
        ViewDetailButtonComponent: ViewDetailButtonComponent,
        DeleteButtonComponent: DeleteButtonComponent,
      },
      defaultColDef: {
        sortable: true,
        resizable: true,
        floatingFilter: true,
      },
      statusBar: {
        statusPanels: [
          { statusPanel: 'agTotalAndFilteredRowCountComponent', align: 'left' },
        ],
      },

    };
    this.context = { componentParent: this };
  }

  ngOnInit() {
    this._storeService.getAllStores().subscribe(
      (data) => {
        this.stores = data;
      },
      (response) => {
        this._messageService.onFailure("Failed to get Stores.", response);
      }
    );
  }

  getTprTags() {
    this._priceManagementService
      .getAllStockItemsWithTprTagPendingFulfillmentByStoreId(this.store.storeId)
      .subscribe(
        (data) => {
          this.isDataLoaded = true;
          this.formatandSetRowData(data);
        },
        (response) => {
          this._messageService.onFailure(
            "Failed to get Pending Compare At Tags.",
            response
          );
        }
      );
  }

  formatandSetRowData(data) {
    var gridData = [];
    for (var i = 0; i < data.length; i++) {
      var singleRowData = {};
      singleRowData = {
        itemCode: data[i].item.itemCode,
        description: data[i].item.description,
        requestDate:
          data[i].tprTagRequestDate != null
            ? new Date(data[i].tprTagRequestDate)
            : "",
        requested: data[i].tprPersist ? "Always" : "Once",
        book: data[i].tprBook,
        storeItemId: data[i].storeItemId,
      };
      gridData.push(singleRowData);
    }
    this.rowData = gridData;
    this._gridService.onWindowResize("#ag-grid-wrapper");
    setTimeout(() => {
      this.fitColumns();
      this._gridService.sizeGrid("#ag-grid-wrapper");
    }, 100);
  }

  clearFilters(){
    this.quickFilterText = '';
    this.agGrid.api.setGridOption('quickFilterText', this.quickFilterText);
    this.agGrid.api.setFilterModel(null);
    this.agGrid.api.onFilterChanged();
  }

  resetColumns(){
    this.agGrid.api.resetColumnState();
    this.fitColumns(); //since this is done by default also
  }

  autoSizeColumns(){
    var columns = this.agGrid.api.getColumns();
    this.agGrid.api.autoSizeColumns(columns);
  }

  fitColumns(){
    this.agGrid.api.sizeColumnsToFit();
  }

  onQuickFilterChange(){
    this.agGrid.api.setGridOption('quickFilterText', this.quickFilterText);
  }

  onDeleteButtonClick(params){
    //Users with hasReadOnlyRestriction set to true should not even be allowed to invoke this action because it is hidden or disabled in the UI.
    //This is for extra protection. The back end will also be protected.
    if (this.myUser.hasReadOnlyRestriction){
      this._messageService.alertReadOnlyRestriction();
      return;
    }
    
    this.confirmationDialogRef = this._dialog.open(ConfirmationDialogComponent, {
      disableClose: false,
      //width: '600px',
      data: {
        title: 'Are you sure?',
        message: 'Do you want to delete the Compare At Tag order for item code: ' + params.data.itemCode + '?',
        confirmText: 'Yes, delete it',
        cancelText: 'No, cancel this action!'
      }
    });
    this.confirmationDialogRef.afterClosed().subscribe(result => {
      if(result) {
        this._rpmsMainframeOperationsService.deleteStoreItemTprTag(params.data.storeItemId)
        .subscribe(
          (data) => {
            this._messageService.onSuccess('Successfully deleted Compare At Tag Order');
            this.getTprTags();
          },
          (response) => {
            this._messageService.onFailure('Failed to delete Compare At Tag order.', response);
          })
      }
      this.confirmationDialogRef = null;
    });
  }
}

