import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { AppConfig } from '../app.config';

@Injectable({
  providedIn: 'root'
})
export class ItemListService {

  private _baseUrl = AppConfig.settings.baseUrl;
  private _reqHeader = new HttpHeaders({ 'Accept': 'application/json', 'Content-Type': 'application/json' });

  constructor ( 
    private _http: HttpClient, 
  ){}

  getItemListById(itemListId) {
    return this._http.get(this._baseUrl + '/api/ItemLists/GetItemListById/' + itemListId, { headers: this._reqHeader });
  }

  getAllSharedItemLists(params?) {
    return this._http.get(this._baseUrl + '/api/ItemLists/GetAllSharedItemLists', { params: params, headers: this._reqHeader });
  }

  getActiveSharedItemLists(params?) {
    return this._http.get(this._baseUrl + '/api/ItemLists/GetActiveSharedItemLists', { params: params, headers: this._reqHeader });
  }

  getActiveSharedItemListsLite(params?) {
    return this._http.get(this._baseUrl + '/api/ItemLists/GetActiveSharedItemListsLite', { params: params, headers: this._reqHeader });
  }

  getOwnedItemLists(userId) {
    return this._http.get(this._baseUrl + '/api/ItemLists/GetOwnedItemLists/' + userId, { headers: this._reqHeader });
  }

  createItemList(itemList) {
    return this._http.post(this._baseUrl + '/api/ItemLists/CreateItemList', itemList, { headers: this._reqHeader });
  }

  updateItemList(itemListId, itemFilter) {
    return this._http.put(this._baseUrl + '/api/ItemLists/UpdateItemList/' + itemListId, itemFilter, { headers: this._reqHeader });
  }

  deleteItemList(itemListId) {
    return this._http.delete(this._baseUrl + '/api/ItemLists/DeleteItemList/' + itemListId, { headers: this._reqHeader });
  }

  addItemCodeToItemList(itemListId, itemCode) {
    return this._http.patch(this._baseUrl + '/api/ItemLists/AddItemCodeToItemList/' + itemListId + '/' + itemCode, { headers: this._reqHeader });
  }

  removeItemCodeFromItemList(itemListId, itemCode) {
    return this._http.patch(this._baseUrl + '/api/ItemLists/RemoveItemCodeFromItemList/' + itemListId + '/' + itemCode, { headers: this._reqHeader });
  }

}
