<div id="shelf-tag-editor-wrapper" class="editor-wrapper" cdkDrag>
  <div class="editor-title" cdkDragHandle>
    Shelf Tags Requested
    <span class="close" (click)="close()"><i class="fa fa-times"></i></span>
  </div>
  <div class="editor-item-title">
    Item Code: {{rowData.itemCode}}
  </div>
  <div class="editor-content">
    <mat-radio-group [(ngModel)]="shelfTagQty" (change)="isSaveDisabled = false">
      <mat-radio-button *ngFor="let qty of shelfTagQuantities;" [value]="qty">{{qty == '0' ? 'None' : qty}}</mat-radio-button>
    </mat-radio-group>
    <div class="button-wrapper">
      <button mat-raised-button color="primary" (click)="sendRowNodeAndShelfTagQty()" [disabled]="isSaveDisabled">Save Changes</button>
    </div>
  </div>
</div>