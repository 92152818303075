<div id="item-list-editor-wrapper" class="editor-wrapper" cdkDrag>
  <div class="editor-title" cdkDragHandle>
    Item List Editor <span *ngIf="!permissions.roleAllowsUserToEditItemList || myUser.hasReadOnlyRestriction">(Read Only)</span>
    <span class="close" (click)="close()"><i class="fa fa-times"></i></span>
  </div>
  <div class="editor-item-title">
    Item Code: {{rowData.itemCode}} - {{rowData.description}}
  </div>
  <div class="editor-content">
    <div class="left-pane">
      <div class="pane-title">Item Lists Containing This Item</div>
      <div class="scroller">
        <div class="scroll-content">
          <div *ngFor="let itemList of allItemLists" class="chip-wrapper">
            <div *ngIf="itemList.selected" class="chip">{{itemList.name}} <span class="chip-delete" *ngIf="isEditable(itemList) && !myUser.hasReadOnlyRestriction" (click)="onItemListChipRemove(itemList)"><i class="fas fa-times"></i></span></div>
          </div>
        </div>
      </div>
    </div>
    <div class="right-pane">
      <div class="pane-title">Add This Item to Selected Item Lists</div>
      <div class="filter">
        <mat-form-field class="filter-input" appearance="fill">
          <mat-label>Type Here to Filter List</mat-label>
          <input matInput type="text" [(ngModel)]="filterValue" (keyup)="filterList(filterValue)">
          <button *ngIf="filterValue" matSuffix mat-icon-button aria-label="Clear" (click)="clearFilter()">
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>
      </div>
      <div class="scroller">
        <div class="scroll-content">
          <div *ngFor="let itemList of allItemLists">
            <div class="item-list-line" [hidden]="ifFiltered(itemList)">
              <mat-checkbox [(ngModel)]="itemList.selected" (change)="onItemListCheckboxChange(itemList)" [disabled]="!permissions.roleAllowsUserToEditItemList || !isEditable(itemList) || myUser.hasReadOnlyRestriction">{{itemList.name}}</mat-checkbox>
              <div class="item-list-actions">
                <i class="fas fa-edit" title="Edit this Item List" *ngIf="permissions.roleAllowsUserToEditItemList && !myUser.hasReadOnlyRestriction" (click)="onEditItemListClick(itemList)" ></i>
                <i class="fas fa-eye" title="View Item List Details" *ngIf="!permissions.roleAllowsUserToEditItemList || myUser.hasReadOnlyRestriction" (click)="onEditItemListClick(itemList)" ></i>
                <i class="fa fa-minus-circle" title="Remove all items from this Item List" (click)="onRemoveAllItemsFromItemListClick(itemList)" *ngIf="permissions.roleAllowsUserToDeleteItemList && !myUser.hasReadOnlyRestriction"></i>
                <i class="fa fa-trash" title="Delete this Item List" (click)="onDeleteItemListClick(itemList)" *ngIf="permissions.roleAllowsUserToDeleteItemList && !myUser.hasReadOnlyRestriction"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="button-wrapper">
      <button mat-raised-button color="primary" (click)="onCreateItemListClick()" [disabled]="!permissions.roleAllowsUserToCreateItemList || myUser.hasReadOnlyRestriction">Create New Item List</button>
    </div>
  </div>
</div>
