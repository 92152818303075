<div id="price-percent-editor-wrapper" class="editor-wrapper" cdkDrag>
  <div class="editor-title" cdkDragHandle>
    Price/Percent Editor <span *ngIf="isGridReadOnly || isItemReadOnly">(Read Only)</span>
    <span class="close" (click)="close()"><i class="fa fa-times"></i></span>
  </div>
  <div class="editor-item-title">
    Item Code: {{rowData.itemCode}} - {{rowData.description}}
  </div>
  <div class="editor-content">
    <div id="edit-pane">
      <div class="info">{{note}}</div>
      <form [formGroup]="pricePercentEditorForm" (ngSubmit)="sendUpdatedRowNodeAndChangeType()">
        <!-- <div class="row">
          <mat-radio-group id="changeType" formControlName="changeType">
            <mat-radio-button value="price">Price Change</mat-radio-button>
            <mat-radio-button value="percent">Percent Change</mat-radio-button>
          </mat-radio-group>
        </div> -->
        <div class="note">{{multiMessage}}</div>
        <div class="row">
          <mat-form-field appearance="outline" [ngClass]="{'highlight': this.changeType === 'price'}">
            <mat-label>Multi</mat-label>
            <input matInput id="multi" formControlName="multi" placeholder="Multi" type="number" step="1" min="0" max="99" required (keyup)="onMultiChange($event)" (change)="onMultiChange($event)">
          </mat-form-field>
        </div>
        <div class="note" *ngIf="pricePercentEditorForm.controls.multi.errors?.min">Multi must be between 0 and 99</div>
        <div class="note" *ngIf="pricePercentEditorForm.controls.multi.errors?.max">Multi must be between 0 and 99</div>
        <div class="note">{{priceMessage}}</div>
        <div class="row">
          <mat-form-field appearance="outline" [ngClass]="{'highlight': this.changeType === 'price'}">
              <mat-label>Price</mat-label>
              <input matInput id="price" formControlName="price" placeholder="Price" type="number" step=".01" min="0" max="999.99" required (keyup)="onPriceChange($event)" (change)="onPriceChange($event)" (blur)="onPriceBlur($event)">
            </mat-form-field>
            <mat-checkbox id="keepSrp" formControlName="keepSrp"(change)="onChangeKeepSrp($event)">Keep Price</mat-checkbox>
          </div>
        <div class="note" *ngIf="pricePercentEditorForm.controls.price.errors?.min">Price must be between 0 and 999.99</div>
        <div class="note" *ngIf="pricePercentEditorForm.controls.price.errors?.max">Price must be between 0 and 999.99</div>
        <div class="note">{{percentMessage}}</div>
        <div class="row">
          <mat-form-field appearance="outline" [ngClass]="{'highlight': this.changeType === 'percent'}">
              <mat-label>Percent</mat-label>
              <input matInput id="percent" formControlName="percent" placeholder="Percent" type="number" step="1" min="-99" max="99" required (keyup)="onPercentChange($event)" (change)="onPercentChange($event)">
          </mat-form-field>
          <mat-checkbox id="keepPercent" formControlName="keepPercent" (change)="onChangeKeepPercent($event)">Keep Percent</mat-checkbox>
        </div>
        <div class="note" *ngIf="pricePercentEditorForm.controls.percent.errors?.min">Percent must be between -99 and 99</div>
        <div class="note" *ngIf="pricePercentEditorForm.controls.percent.errors?.max">Percent must be between -99 and 99</div>
        <!-- <div>
          <mat-checkbox *ngIf="showSetPriceKeepPercent" id="setPriceKeepPercent" formControlName="setPriceKeepPercent" (change)="onChangeSetPriceKeepPercent($event)">Set price using price above<br/>but then keep percent</mat-checkbox>
          <div *ngIf="showSetPriceKeepPercentNote" class="note-bottom">Note: The percentage shown above is only an estimate and will be recalculated based on the price above.</div>
        </div> -->
        <button mat-raised-button style="margin-top:20px;" color="primary" [disabled]="!pricePercentEditorForm.valid || isSaveDisabled || isGridReadOnly || isItemReadOnly" type="submit">Save Changes</button>
      </form>
    </div>
    <div id="price-history-pane">
      <p class="pane-title">Price Change History</p>
      <div class="scroll-wrapper">
        <div class="scroll-content">
          <table>
            <tr class="table-header">
              <td class="ph-date">Date, Time</td>
              <td class="ph-book">Bk</td>
              <td class="ph-code">Cd</td>
              <td class="ph-multi">M</td>
              <td class="ph-price">$</td>
              <td class="ph-keepPrice">K$</td>
              <td class="ph-percent">%</td>
              <td class="ph-keepPercent">K%</td>
              <td class="ph-madeByUser">Change Made By</td>
              <td class="ph-bookPricePercentRequested">Requested</td>
              <!-- <td class="ph-pricingChangeSourceType">Change Type</td> -->
            </tr>
            <tr *ngFor="let entry of priceHistoryEntries">
              <td *ngIf="entry.changeType != 'Book'" class="ph-date"><a (click)="onPriceHistoryClick(entry.changeType, entry, $event)">{{entry.created | date : "short"}}</a></td>
              <td *ngIf="entry.changeType == 'Book'" class="ph-date">{{entry.created | date : "short"}}</td>
              <!-- <td class="ph-book" [ngClass]="{'change-type': entry.changeType == 'Book'}"><a (click)="onPriceHistoryClick('book', entry)">{{entry.book}}</a></td>
              <td class="ph-code" [ngClass]="{'change-type': entry.changeType == 'Book'}"><a (click)="onPriceHistoryClick('book', entry)">{{entry.srpCode}}</a></td> -->
              <td class="ph-book" [ngClass]="{'change-type': entry.changeType == 'Book'}">{{entry.book}}</td>
              <td class="ph-code" [ngClass]="{'change-type': entry.changeType == 'Book'}">{{entry.srpCode}}</td>
              <td class="ph-multi" [ngClass]="{'change-type': entry.changeType == 'Price'}">{{entry.multi}}</td>
              <td class="ph-price" [ngClass]="{'change-type': entry.changeType == 'Price'}">{{entry.price | currency : ''}}</td>
              <td class="ph-keepPrice" [ngClass]="{'change-type': entry.changeType == 'Price' || entry.changeType == 'KeepOnly'}"><i *ngIf="entry.keepSrp" class="fa fa-check" aria-hidden="true"></i></td>
              <td class="ph-percent" [ngClass]="{'change-type': entry.changeType == 'Percent'}">{{entry.percent}}</td>
              <td class="ph-keepPercent" [ngClass]="{'change-type': entry.changeType == 'Percent' || entry.changeType == 'KeepOnly'}"><i *ngIf="entry.keepPercent" class="fa fa-check" aria-hidden="true"></i></td>
              <td class="ph-madeByUser">{{(entry.madeByUser == null || entry.madeByUser == '') ? 'Mainframe' : entry.madeByUser}}</td>
              <td class="ph-bookPricePercentRequested">{{entry.bookPricePercentRequested}}</td>
              <!-- <td class="ph-pricingChangeSourceType">{{addSpacesBetweenWords(entry.pricingChangeSourceType)}}</td> -->
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>