import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AuthenticationService } from './authentication.service';
import { Router } from '@angular/router';
import { AppConfig } from 'src/app/app.config';

@Injectable({
  providedIn: 'root'
})
export class PingerService {
  
  private _baseUrl = AppConfig.settings.baseUrl;
  pingerInterval;

  constructor(private _authenticationService : AuthenticationService,
              private _router: Router){
  }

  startPingerTimer() {
    //in the pinger() function 'this' was getting set to 'window', so we must rescope 'this'
    //https://stackoverflow.com/questions/2749244/javascript-setinterval-and-this-solution
      this.pingerInterval = setInterval(
        (function(self) {         //Self-executing func which takes 'this' as self
            return function() {   //Return a function in the context of 'self'
                self.pinger();    //Thing you wanted to run as non-window 'this'
            }
        })(this),60000); //1 minute
  };

  pinger() {
    var token = sessionStorage.getItem('rpmsToken');
    //var token = this._authenticationService.getToken();

    if (token != null) {
      //console.log('ping');
      this._authenticationService.ping().subscribe((data : any)=>{
        //console.log(data);
        if (!data.isLoggedIn) {
          this.logout();
        }
      },
      (response : HttpErrorResponse)=>{
        if (response.error.error === 'account_not_logged_in'){
          this.logout();
        }
        else{
          //console.log(response);
        }
      });
    }
    else{
      clearInterval(this.pingerInterval);
    }
  };

  logout(){
    clearInterval(this.pingerInterval);
    sessionStorage.removeItem('rpmsToken');
    sessionStorage.removeItem('rpmsUser');
    sessionStorage.removeItem('rpmsUserName');
    sessionStorage.removeItem('rpmsUserFullName');
    sessionStorage.removeItem('rpmsUserRole');
    this._authenticationService.setIsUserLoggedIn(false);
    this._router.navigate(['/login']);
  }


}
