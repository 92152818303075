import { Component, EventEmitter, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { StoreGroupOwnedAssetsComponent } from 'src/app/admin/admin-store-groups/store-group-owned-assets/store-group-owned-assets.component';
import { StoreOwnedAssetsComponent } from 'src/app/admin/admin-stores/store-owned-assets/store-owned-assets.component';
import { UserOwnedAssetsComponent } from 'src/app/admin/admin-users/user-owned-assets/user-owned-assets.component';
import { PermissionsService } from '../permissions.service';

@Component({
  selector: 'app-assets-dialog',
  templateUrl: './assets-dialog.component.html',
  styleUrls: ['./assets-dialog.component.css']
})
export class AssetsDialogComponent implements OnInit {

  entityName;
  entityType;
  storeGroupId;
  storeId;
  userId;
  rowData;
  params;

  permissions: any = {};

  showTransferButton: boolean = true;
  showDelete: boolean = true;

  constructor(
    private _permissionsService: PermissionsService,
    private _dialog: MatDialog, 
    public storeGroupOwnedAssetsDialogRef: MatDialogRef<StoreGroupOwnedAssetsComponent>,
    public storeOwnedAssetsDialogRef: MatDialogRef<StoreOwnedAssetsComponent>,
    public userOwnedAssetsDialogRef: MatDialogRef<UserOwnedAssetsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) 
    {
      this.entityType = data.entityType;
      this.entityName = data.entityName;
      this.storeGroupId = data.storeGroupId;
      this.storeId = data.storeId;
      this.userId = data.userId;
      this.rowData = data.rowData;
      this.params = data.params;

      this.permissions = this._permissionsService.getPermissions();
    }

  ngOnInit(): void {
  }

  onOpenTransferDialog = new EventEmitter();

  onTransferButtonClick(){
    this.closeDialog();
    this.onOpenTransferDialog.emit(this.params);
  }

  onAssetCountEvent(val){

  }
  
  //TODO combine these 2 functions below
  onDeletionOccurredEvent(val){
    switch(this.entityType){
      case 'Store Group':
        this.storeGroupOwnedAssetsDialogRef.close(val); 
        break;
      case 'Store':
        this.storeOwnedAssetsDialogRef.close(val); 
        break;
      case 'User':
        this.userOwnedAssetsDialogRef.close(val); 
        break;
    }
  }

  closeDialog(){
    switch(this.entityType){
      case 'Store Group':
        this.storeGroupOwnedAssetsDialogRef.close(); 
        break;
      case 'Store':
        this.storeOwnedAssetsDialogRef.close(); 
        break;
      case 'User':
        this.userOwnedAssetsDialogRef.close(); 
        break;
    }
  }

}
