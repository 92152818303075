import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { Subject } from 'rxjs/internal/Subject';
import { environment } from 'src/environments/environment';
import { MessageService } from 'src/app/shared/message.service';
import { BehaviorSubject } from 'rxjs';
import { AppConfig } from 'src/app/app.config';
import { MatDialog } from '@angular/material/dialog';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  private config = AppConfig.settings;

  //private _baseUrl = environment.baseUrl; //old way before config file in assets folder
  private _baseUrl = AppConfig.settings.baseUrl;
  isUserLoggedIn: boolean = false;
  myUser: any;
  userRole;
  token;

  isUserLoggedIn$: Observable<any>;
  private _isUserLoggedInSubject = new BehaviorSubject<any>(this.isUserLoggedIn);
  myUser$: Observable<any>;
  private _myUserSubject = new Subject<any>();

  constructor ( 
    private _http: HttpClient, 
    private _router: Router,
    private _messageService: MessageService,
    private dialogRef: MatDialog,
    //private _signalRService: SignalRService,
  ){
    this.isUserLoggedIn$ = this._isUserLoggedInSubject.asObservable();
    this.myUser$ = this._myUserSubject.asObservable();
    //if (this.config.logInits) console.log('auth init');
    var token = sessionStorage.getItem('rpmsToken');

    this.setIsUserLoggedIn(token != null);
    var user = JSON.parse(sessionStorage.getItem('rpmsUser'));
    if (user && !this.isEmpty(user)) this.setMyUser(user);
  }

  getConfig(){
    return this.config();
  }
  
  login(loginForm) {
    var data = "username=" + loginForm.userName + "&password=" + loginForm.password + "&grant_type=password";
    var reqHeader = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
    return this._http.post(this._baseUrl + '/Token', data, { headers: reqHeader })
  }

  logout(){
    var reqHeader = new HttpHeaders({ 'Accept': 'application/json' });
    this._http.post(this._baseUrl + '/api/Account/Logout', { headers: reqHeader })
              .subscribe((data) => {
                this.doOnLogout();
              },
              (response : HttpErrorResponse)=>{
                if (response.error.error === 'account_not_logged_in'){
                  this.doOnLogout();
                }
                else{
                  this._messageService.onFailure('Failed to log out.', response);
                }
              })
  }

  ping() {
    var reqHeader = new HttpHeaders({ 'Accept': 'application/json' });
    return this._http.post(this._baseUrl + '/api/Account/Ping', { headers: reqHeader });
  }

  setToken(data){
    this.token = data;
    sessionStorage.setItem('rpmsToken', data);
  }

  getToken(){
    return this.token;
  }

  setIsUserLoggedIn(data) {
    this.isUserLoggedIn = data;
    this._isUserLoggedInSubject.next(data);
  }

  getIsUserLoggedIn(){
    return this.isUserLoggedIn;
  }

  setMyUser(data){
    this.myUser = data;
    this.userRole = data.roles[0];
    sessionStorage.setItem('rpmsUser', JSON.stringify(data));
    sessionStorage.setItem('rpmsUserName', data.userName);
    sessionStorage.setItem('rpmsUserFullName', data.fullName);
    sessionStorage.setItem('rpmsUserRole', data.roles[0]);
    this._myUserSubject.next(data);
  }

  getMyUser(){
    //console.log({myUser: this.myUser});
    return this.myUser;
  }

  getUserRole(){
    return this.userRole;
  }

  doOnLogout(){
    //this._appComponent.isLoggedIn = false;
    //this._appComponent.stopIdleWatch();

    // this._signalRService.logOff(
    //   function () {
    //   },
    //   function (error) {
    //     this._messageService.onFailure('SignalR send error.', error);
    //   });
    this.dialogRef.closeAll();
    sessionStorage.removeItem('rpmsToken');
    sessionStorage.removeItem('rpmsUser');
    sessionStorage.removeItem('rpmsUserName');
    sessionStorage.removeItem('rpmsUserFullName');
    sessionStorage.removeItem('rpmsUserRole');
    localStorage.removeItem('rpmsSessionId');
    this.setIsUserLoggedIn(false);
    this._router.navigate(['/login']);
    window.location.reload();
  }

  isEmpty(obj) {
    var hasOwnProperty = Object.prototype.hasOwnProperty;
    if (obj == null) return true;
    if (obj.length > 0)    return false;
    if (obj.length === 0)  return true;
    if (typeof obj !== "object") return true;
    for (var key in obj) {
        if (hasOwnProperty.call(obj, key)) return false;
    }
    return true;
  }


}
