import { Component, OnInit, Input, EventEmitter, Output, ViewEncapsulation, ChangeDetectorRef } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { PriceManagementService } from 'src/app/shared/price-management.service';
import { MessageService } from 'src/app/shared/message.service';
import { PriceManagementDataService } from '../price-management-data.service';
import { PriceManagementFilterService } from '../price-management-filter.service';
//import { environment } from 'src/environments/environment';
import { AppConfig } from 'src/app/app.config';

@Component({
  selector: 'rpms-price-percent-editor',
  templateUrl: './price-percent-editor.component.html',
  styleUrls: ['./price-percent-editor.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class PricePercentEditorComponent implements OnInit{
    @Input() rowNodeToUpdate: any;
    @Input() changeType: string;
    @Input() isGridReadOnly: boolean;
    @Input() isItemReadOnly: boolean;
    @Output() updatedRowNodeEvent = new EventEmitter<any>();
    @Output() isPricePercentEditorVisibleEvent = new EventEmitter<boolean>();
    private config = AppConfig.settings;

    rowData: any = {};
    priceHistoryEntries;
    priceMaximum;
    percentMaximum;
    currentUnitCost = 0;
    isSaveDisabled:boolean = false;
    note = '';
    tabModel;
    showSetPriceKeepPercent:boolean = true;

    multiValueBeforeChange;
    priceValueBeforeChange;
    percentValueBeforeChange;

    sentValues;
    multiMessage = '';
    priceMessage = '';
    percentMessage = '';
    showSetPriceKeepPercentNote:boolean = false;

    pricePercentEditorForm = this._fb.group({
        multi: [''],
        price: [''],
        keepSrp: [''],
        percent: [''],
        keepPercent: [''],
        //setPriceKeepPercent: [''],
      });

    updatedRowNodeSubscription;

    constructor(
        private _fb: UntypedFormBuilder,
        private _priceManagementService: PriceManagementService,
        private _priceManagementDataService: PriceManagementDataService,
        private _priceManagementFilterService: PriceManagementFilterService,
        private _messageService: MessageService,
        private _changeDetectorRef: ChangeDetectorRef
    ){
    }

    ngOnInit(){
        if (this.config.logInits) console.log('price editor init');
        this.changeType = 'keepOnly';
        this.rowData = this.rowNodeToUpdate.data;
        this.priceMaximum = this.rowData.current1;
        this.percentMaximum = this.rowData.percent1C;
        this.currentUnitCost = this.rowData.deliveredUnitCost;
        this.multiValueBeforeChange = this.rowData.multi;
        this.priceValueBeforeChange = this.rowData.price;
        this.percentValueBeforeChange = this.rowData.percent;
    
        this.pricePercentEditorForm.patchValue({
            multi: this.rowData.multi,
            price: Number(this.rowData.price).toFixed(2),
            keepSrp: this.rowData.keepSrp,
            percent: this.rowData.percent,
            keepPercent: this.rowData.keepPercent,
        });

        // if (this.rowData.keepSrp) {
        //     this.note = 'Note: Uncheck "Keep Price" to enable percent changes.';
        //     this.changeType = 'price';
        //     this.pricePercentEditorForm.get('percent').disable();
        //     this.pricePercentEditorForm.get('keepPercent').disable();
        // }
        // if (this.rowData.keepPercent) {
        //     this.note = 'Note: Uncheck "Keep Percent" to enable price changes.';
        //     this.changeType = 'percent';
        //     this.pricePercentEditorForm.get('multi').disable();
        //     this.pricePercentEditorForm.get('price').disable();
        //     this.pricePercentEditorForm.get('keepSrp').disable();
        //     this.showSetPriceKeepPercent = true;      
        // }
        if (this.rowData.isPreview) {
          this.getPreviewStoreItemPriceChangeHistory();
        }
        else {
          this.getStoreItemPriceChangeHistory();
        }
    }

    getPreviewStoreItemPriceChangeHistory(){
        this._priceManagementService.getAllPreviewStoreItemPriceChangesByPreviewStoreItemId(this.rowData.previewStoreItemId)
            .subscribe(
                (data) => {
                    this.priceHistoryEntries = data;
                    this.priceHistoryEntries.reverse();
                },
                (response) => {
                    this._messageService.onFailure('Failed to get price history.', response);
                }
            )
    }

    getStoreItemPriceChangeHistory(){
        this._priceManagementService.getAllStoreItemPriceChangesByStoreItemId(this.rowData.storeItemId)
            .subscribe(
                (data) => {
                    this.priceHistoryEntries = data;
                    this.priceHistoryEntries.reverse();
                },
                (response) => {
                    this._messageService.onFailure('Failed to get price history.', response);
                }
            )
    }

    ngAfterContentInit(){
        this.tabModel = this._priceManagementFilterService.getTabModel();
        //this is what gets returned after a price change (updating with actual values)
        this.updatedRowNodeSubscription = this._priceManagementDataService.updatedRowNode$
        .subscribe(
          (data) => {
            var rowNode = data;
            if (rowNode.data && rowNode.data.itemCode === this.rowData.itemCode) {
                this.multiMessage = '';
                this.priceMessage = '';
                this.percentMessage = '';

                this.pricePercentEditorForm.patchValue({
                    multi: rowNode.data.multi,
                    price: Number(rowNode.data.price).toFixed(2),
                    keepSrp: rowNode.data.keepSrp,
                    percent: rowNode.data.percent,
                    keepPercent: rowNode.data.keepPercent,
                })

                if (this.sentValues && rowNode.data.multi != this.sentValues.multi){
                    this.multiMessage = 'Multi sent: ' + this.sentValues.multi + ', Actual multi set: ' + rowNode.data.multi;
                }
                if (this.sentValues && rowNode.data.price != this.sentValues.price){
                    this.priceMessage = 'Price sent: ' + this.sentValues.price + ', Actual price set: ' + rowNode.data.price;
                }
                if (this.sentValues && rowNode.data.percent != this.sentValues.percent){
                    this.percentMessage = 'Percent sent: ' + this.sentValues.percent + ', Actual percent set: ' + rowNode.data.percent;
                }
                // meat and produce preview items were also updating the store item which updated the 'updatedRowNode$' subscription with an item where isPreview was false.
                // getStoreItemPriceChangeHistory was being called even while looking at the preview item. Detecting the activeTab should fix this.
                if (rowNode.data.isPreview && this.tabModel.activeTab === 'Preview') {
                  this.getPreviewStoreItemPriceChangeHistory();
                }
                else if (this.tabModel.activeTab !== 'Preview') {
                  this.getStoreItemPriceChangeHistory();
                }
            }
          }
        );
        if (this.isGridReadOnly || this.isItemReadOnly){
            this.pricePercentEditorForm.get('multi').disable();
            this.pricePercentEditorForm.get('price').disable();
            this.pricePercentEditorForm.get('keepSrp').disable();
            this.pricePercentEditorForm.get('percent').disable();
            this.pricePercentEditorForm.get('keepPercent').disable();
        }

    }

    ngOnDestroy(){
        this.updatedRowNodeSubscription.unsubscribe();
    }

    // onPriceHistoryClick(changeType, entry, event?){
    //     if (!this.isGridReadOnly && !this.isItemReadOnly){
    //         this.setChangeType(changeType);
    //         this.pricePercentEditorForm.patchValue({
    //           keepSrp: false,
    //           keepPercent: false,
    //         });
    //         this.pricePercentEditorForm.get('multi').enable();
    //         this.pricePercentEditorForm.get('price').enable();
    //         this.pricePercentEditorForm.get('keepSrp').enable();
    //         this.pricePercentEditorForm.get('percent').enable();
    //         this.pricePercentEditorForm.get('keepPercent').enable();    
    //         switch (changeType){
    //           case 'multi':
    //               this.pricePercentEditorForm.patchValue({
    //                   multi: entry.multi,
    //                   price: Number(entry.price).toFixed(2),
    //                   keepSrp: entry.keepSrp,
    //               });
    //               event.target.value = entry.multi;
    //               this.onMultiChange(event);
    //               if (entry.keepSrp) this.onChangeKeepSrp({checked: true});    
    //               break;
    //           case 'price':
    //               this.pricePercentEditorForm.patchValue({
    //                   multi: entry.multi,
    //                   price: Number(entry.price).toFixed(2),
    //                   keepSrp: entry.keepSrp,
    //               });
    //               event.target.value = entry.price;
    //               this.onPriceChange(event);
    //               if (entry.keepSrp) this.onChangeKeepSrp({checked: true});    
    //               break;
    //           case 'percent':
    //               this.pricePercentEditorForm.patchValue({
    //                   percent: entry.percent,
    //                   keepPercent: entry.keepPercent,
    //               });
    //               event.target.value = entry.percent;
    //               this.onPercentChange(event);
    //               if (entry.keepPercent) this.onChangeKeepPercent({checked: true});
    //               break;
    //         }
            
    //     }
    // }

    onPriceHistoryClick(changeType, entry, event?){
      if (!this.isGridReadOnly && !this.isItemReadOnly){
        //TODO - set fields based on values and change type
        this.changeType = changeType.toLowerCase();
        // this.pricePercentEditorForm.get('multi').enable();
        // this.pricePercentEditorForm.get('price').enable();
        // this.pricePercentEditorForm.get('keepSrp').enable();
        // this.pricePercentEditorForm.get('percent').enable();
        // this.pricePercentEditorForm.get('keepPercent').enable();    
        this.pricePercentEditorForm.patchValue({
          multi: entry.multi,
          price: entry.price.toFixed(2),
          percent: entry.percent,
          keepSrp: entry.keepSrp,
          keepPercent: entry.keepPercent,
          //setPriceKeepPercent: entry.setPriceKeepPercent,
        });
        //this._changeDetectorRef.detectChanges();
        //if (entry.keepSrp) this.onChangeKeepSrp({checked: true});
        //if (entry.keepPercent) this.onChangeKeepPercent({checked: true});
        //if (entry.setPriceKeepPercent) this.onChangeSetPriceKeepPercent({checked: true});
      }
    }


    sendUpdatedRowNodeAndChangeType() {
        this.sentValues = {
            multi: this.pricePercentEditorForm.get('multi').value,
            price: this.pricePercentEditorForm.get('price').value,
            keepSrp: this.pricePercentEditorForm.get('keepSrp').value,
            percent: this.pricePercentEditorForm.get('percent').value,
            keepPercent: this.pricePercentEditorForm.get('keepPercent').value,
            //setPriceKeepPercent: this.pricePercentEditorForm.get('setPriceKeepPercent').value,
        }
        this.updatedRowNodeEvent.next({ rowNode: this.rowNodeToUpdate, newValues: this.sentValues, changeType: this.changeType });
    }

    setChangeType(type){
        this.changeType = type;
    }

    hidePanelNotes(){
        this.multiMessage = '';
        this.priceMessage = '';
        this.percentMessage = '';
    }

    onMultiChange(event) {
        //if (event.target.value != this.multiValueBeforeChange) this.changeType = 'price';
        this.changeType = 'price';
        var prePriced = this.rowData.offPack.indexOf('PP') == 0;
        var price = Number(this.pricePercentEditorForm.get('price').value);
        var multi = event.target.value;
        multi = (multi == 0 ? 1 : multi);
        var tempPrice = price / multi;

        if (prePriced && tempPrice > this.priceMaximum) {
            price = this.priceMaximum * multi;
            tempPrice = this.priceMaximum;
            //this._messageService.alert('The price cannot exceed $' + price.toFixed(2) + ' (' + this.priceMaximum.toFixed(2) + ' x ' + multi + ') for this pre-priced item.');
            //GAIR-103
            this._messageService.alert(`This item is prepriced at $${this.priceMaximum.toFixed(2)}. You cannot set the price higher than the package price.`);
        }

        //if (event.type != 'keyup') {
            this.pricePercentEditorForm.patchValue({
                price: Number(price).toFixed(2),
            });
        //}

        var percent = Math.floor(((tempPrice - this.currentUnitCost) / tempPrice) * 100);
        this.pricePercentEditorForm.patchValue({
            percent: percent,
        });
        this.isSaveDisabled = false;
        this.hidePanelNotes();
        this.setValuesBeforeChange();
    }

    onPriceChange(event){
        //if (event.target.value != this.priceValueBeforeChange) this.changeType = 'price';
        this.changeType = 'price';
        var price = event.target.value;
        var prePriced = this.rowData.offPack.indexOf('PP') == 0;
        var multi = Number(this.pricePercentEditorForm.get('multi').value);
        multi = (multi == 0 ? 1 : multi);
        var tempPrice = price / multi;

        if (prePriced && tempPrice > this.priceMaximum) {
            price = this.priceMaximum * multi;
            tempPrice = this.priceMaximum;
            this._messageService.closeAll(); //change and keyup trigger the alert below so close the dialog before showing another
            //this._messageService.alert('The price cannot exceed $' + price.toFixed(2) + ' (' + this.priceMaximum.toFixed(2) + ' x ' + multi + ') for this pre-priced item.');
            //GAIR-103
            this._messageService.alert(`This item is prepriced at $${this.priceMaximum.toFixed(2)}. You cannot set the price higher than the package price.`);
        }
        if (event.type != 'keyup'){
            this.pricePercentEditorForm.patchValue({
                price: Number(price).toFixed(2),
            });
        }

        var percent = Math.floor(((tempPrice - this.currentUnitCost) / tempPrice) * 100);
        this.pricePercentEditorForm.patchValue({
            percent: percent,
        });
        this.isSaveDisabled = false;
        this.hidePanelNotes();
        this.setValuesBeforeChange();
    }

    onPriceBlur(event){
      var price = event.target.value;
      this.pricePercentEditorForm.patchValue({
        price: Number(price).toFixed(2),
      });
    }

    onPercentChange(event) {
        //if (event.target.value != this.percentValueBeforeChange) this.changeType = 'percent';
        this.changeType = 'percent';
        var percent = event.target.value;
        var prePriced = this.rowData.offPack.indexOf('PP') == 0;
        var multi = Number(this.pricePercentEditorForm.get('multi').value);
        multi = (multi == 0 ? 1 : multi);
        var price = Number(this.currentUnitCost / (1 - (percent / 100)) * multi);
        var tempPrice = price / multi;

        var maxPercent = Math.floor(((this.priceMaximum - this.currentUnitCost) / this.priceMaximum) * 100);

        if (prePriced && tempPrice > this.priceMaximum) {
            price = this.priceMaximum * multi;
            tempPrice = this.priceMaximum;
            //this._messageService.alert('The percent cannot exceed ' + maxPercent + ' because it would cause the price to exceed $' + price.toFixed(2) + ' (' + this.priceMaximum.toFixed(2) + ' x ' + multi + ') for this pre-priced item.');
            //GAIR-103
            this._messageService.alert(`This item is prepriced at $${this.priceMaximum.toFixed(2)}. You cannot set the price higher than the package price.`);
        }
        //if (event.type != 'keyup'){
            this.pricePercentEditorForm.patchValue({
                price: Number(price).toFixed(2),
            });
        //}

        if (prePriced && percent > maxPercent){
            this.pricePercentEditorForm.patchValue({
                percent: maxPercent,
            });
        }
        this.isSaveDisabled = false;
        this.hidePanelNotes();
        this.setValuesBeforeChange();
    }

    onChangeKeepSrp(event){
      if (event.checked) this.pricePercentEditorForm.get('keepPercent').setValue(false);
        //this.changeType = 'price';
        // this.pricePercentEditorForm.get('price').enable();
        // this.pricePercentEditorForm.get('keepSrp').enable();
        // if (event.checked){
        //   this.note = 'Note: Uncheck "Keep Price" to enable percent changes.';
        //   this.pricePercentEditorForm.get('percent').disable();
        //   this.pricePercentEditorForm.get('keepPercent').disable();
        // }
        // else {
        //   this.note = '';
        //   this.pricePercentEditorForm.get('percent').enable();
        //   this.pricePercentEditorForm.get('keepPercent').enable();
        // }
    }

    onChangeKeepPercent(event){
      if (event.checked) this.pricePercentEditorForm.get('keepSrp').setValue(false);
        // this.changeType = 'percent';
        // this.pricePercentEditorForm.get('percent').enable();
        // this.pricePercentEditorForm.get('keepPercent').enable();
        // //this.showSetPriceKeepPercent = event.checked;
        // if (event.checked){
        //   this.note = 'Note: Uncheck "Keep Percent" to enable price changes.';
        //   this.pricePercentEditorForm.get('multi').disable();
        //   this.pricePercentEditorForm.get('price').disable();
        //   this.pricePercentEditorForm.get('keepSrp').disable();
        // }
        // else {
        //   this.note = '';
        //   this.pricePercentEditorForm.get('multi').enable();
        //   this.pricePercentEditorForm.get('price').enable();
        //   this.pricePercentEditorForm.get('keepSrp').enable();
        // }
    }

    // onChangeSetPriceKeepPercent(event) {
    //   if (event.checked){
    //     this.changeType = 'price';
    //     this.note = 'Note: Uncheck the "Set price... keep percent" checkbox to enable locked fields.'
    //     this.showSetPriceKeepPercentNote = true;
    //     this.pricePercentEditorForm.get('multi').enable();
    //     this.pricePercentEditorForm.get('price').enable();
    //     this.pricePercentEditorForm.get('keepSrp').disable();
    //     this.pricePercentEditorForm.get('percent').disable();
    //     this.pricePercentEditorForm.get('keepPercent').disable();
    //     this.pricePercentEditorForm.get('keepSrp').setValue(false);
    //     this.pricePercentEditorForm.get('keepPercent').setValue(true);
    //   }
    //   else {
    //     this.changeType = 'price';
    //     this.note = '';
    //     this.showSetPriceKeepPercentNote = false;
    //     this.pricePercentEditorForm.get('multi').enable();
    //     this.pricePercentEditorForm.get('price').enable();
    //     this.pricePercentEditorForm.get('keepSrp').enable();
    //     this.pricePercentEditorForm.get('percent').enable();
    //     this.pricePercentEditorForm.get('keepPercent').enable();
    //     this.pricePercentEditorForm.get('keepSrp').setValue(false);
    //     this.pricePercentEditorForm.get('keepPercent').setValue(false);
    //   }
    // }

    //this is so I can set change type based on whether the user actually changes the value
    //I have to use a mouseup event for the number arrows so I can't just use the change event
    setValuesBeforeChange(){
        this.multiValueBeforeChange = this.pricePercentEditorForm.get('multi').value;
        this.priceValueBeforeChange = this.pricePercentEditorForm.get('price').value;
        this.percentValueBeforeChange = this.pricePercentEditorForm.get('percent').value;
    }

    addSpacesBetweenWords(v) {
        if (v != null && v != '') {
          var valueWithSpaces = v.replace(/([A-Z])/g, ' $1');
          valueWithSpaces = valueWithSpaces.substr(1); //remove space before first word
          return valueWithSpaces;
        }
    }

    close(){
        this.isPricePercentEditorVisibleEvent.next(false);
    }

}