import { Component, OnInit, Input, Inject, LOCALE_ID, ViewChild, NgZone, EventEmitter } from '@angular/core';
import { Validators, UntypedFormBuilder } from '@angular/forms';
import { AdminStoreDetailDialog } from '../admin-store-list/admin-store-list.component';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { StoreService } from 'src/app/shared/store.service';
import { StoreGroupService } from 'src/app/shared/store-group.service';
import { MessageService } from 'src/app/shared/message.service';
import { MatAccordion } from '@angular/material/expansion';
import { StoreTransferDialogComponent } from 'src/app/admin/admin-stores/store-transfer-dialog/store-transfer-dialog.component';
import { UserService } from 'src/app/shared/user.service';
import { PricingRulesService } from 'src/app/shared/pricing-rules.service';
import { TransferService } from 'src/app/shared/transfer.service';
//import { environment } from 'src/environments/environment';
import { AppConfig } from 'src/app/app.config';
import { AuthenticationService } from 'src/app/core/authentication/shared/authentication.service';

//const EMAIL_REGEX = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/
const EMAIL_REGEX = /[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?/
const STORE_NUMBER_REGEX = /[0-9]{1,3}/
const ZIP_CODE_REGEX = /(^\d{5}$)|(^\d{5}-\d{4}$)/

@Component({
  selector: 'rpms-admin-store-detail',
  templateUrl: './admin-store-detail.component.html',
  styleUrls: ['./admin-store-detail.component.css']
})
export class AdminStoreDetailComponent implements OnInit {

  @Input() id: string; 
  @ViewChild(MatAccordion) accordion: MatAccordion;
  private config = AppConfig.settings;

  transferDialogRef: MatDialogRef<StoreTransferDialogComponent>;
  
  entityType = 'Store';
  storeId: string;
  mode: string;
  storeGroups; //used for transfer dialog
  stores;  //used for transfer dialog
  controllingStores;
  controllingStoreHint: string;
  isControllingStoreDisabled: boolean;
  isControllingStore: boolean;
  selectedHomeStoreGroupId: number;
  store;
  storeGroupDiagramHtml = 'Select a store group to see its diagram.';
  usersDescription;
  pricingRulesDescription;
  users = [];
  pricingRules = [];
  assetCount = 0;
  rowData;
  params;
  myUser;
  transferOccurred: boolean = false; //used to reload list page if needed

  storeDetailForm = this._fb.group({
    active: [''],
    isFranchise: [''],
    optOutOfCigaretteAndTobaccoPricingWhenControlled: [''],
    storeGroupId: ['', Validators.required],
    controllingStoreId: [''],
    storeNumber: ['', [Validators.required, Validators.pattern(STORE_NUMBER_REGEX)]],
    streetAddress: ['', Validators.required],
    city: ['', Validators.required],
    state: ['', Validators.required],
    zipCode: ['', [Validators.required, Validators.pattern(ZIP_CODE_REGEX)]],
    storeEmailAddress: ['', [Validators.required, Validators.pattern(EMAIL_REGEX)]],
    phoneNumber: ['', Validators.required],
    faxNumber: [''],
    owner: [''],
    manager: [''],
    recallContact: [''],
    recallContactPhoneNumber: [''],
  });

  constructor(
              private _fb: UntypedFormBuilder,
              private _storeGroupService: StoreGroupService,
              private _storeService: StoreService,
              private _authenticationService: AuthenticationService,
              private _userService: UserService,
              private _pricingRulesService: PricingRulesService,
              private _transferService: TransferService,
              private _messageService: MessageService,
              private ngZone: NgZone,
              private _dialog: MatDialog, 
              public dialogRef: MatDialogRef<AdminStoreDetailDialog>,
              @Inject(LOCALE_ID) private locale: string,
              @Inject(MAT_DIALOG_DATA) public data: any) 
              {
                this.storeId = data.id;
                this.stores = data.stores == undefined ? [] : data.stores;
                this.storeGroups = data.storeGroups;
                this.rowData = data.rowData;
                this.params = data.params;
              }

  ngOnInit() {
    this.myUser = this._authenticationService.getMyUser();
    if (this.config.logInits) console.log('init store detail');
    this.doOnInit();
  }

  doOnInit() {
    if (this.myUser.hasReadOnlyRestriction) this.storeDetailForm.disable();
    //this.storeId = this.id;  //8-18-2022
    this.mode = this.storeId == '0' ? 'New' : 'Edit';
    this.getStoreGroups();
    // this.getAllManageableUsersByStore(this.storeId);
    // this.getAllPricingRulesByStoreIdSansMatchCriteria(this.storeId);
  }

  getStoreGroups(){
    this._storeGroupService.getAllStoreGroups()
      .subscribe(
        (data:any) => {
          this.storeGroups = data;
          if (this.storeId != '0'){
            this.getStore();
          }
          else{
            this.storeDetailForm.patchValue({
              active: true,
              isFranchise: false,
            })
          }
        },
        (response) => {
          this._messageService.onFailure('Failed to get Store Groups.', response);
        })
  }

  getStore(){
    this._storeService.getStoreById(this.storeId)
      .subscribe(
        (data: any) => {
          this.store = data;
          if (this.config.logIds) console.log(`%cstoreNumber: ${data.numberAndLocation}`, 'background: black; color: white; font-weight: bold; font-size: 120%');
          if (this.config.logIds) console.log(`%cstoreId: ${data.storeId}`, 'background: yellow; font-weight: bold; font-size: 120%');

          this.storeDetailForm.patchValue({
            active: data.active,
            isFranchise: data.isFranchise,
            optOutOfCigaretteAndTobaccoPricingWhenControlled: data.optOutOfCigaretteAndTobaccoPricingWhenControlled,
            storeGroupId: data.storeGroupId,
            controllingStoreId: data.controllingStoreId,
            storeNumber: data.storeNumber,
            streetAddress: data.streetAddress,
            city: data.city,
            state: data.state,
            zipCode: data.zipCode,
            storeEmailAddress: data.storeEmailAddress,
            phoneNumber: data.phoneNumber,
            faxNumber: data.faxNumber,
            owner: data.owner,
            manager: data.manager,
            recallContact: data.recallContact,
            recallContactPhoneNumber: data.recallContactPhoneNumber,
          });
          this.getPotentialControllingStores(this.store.storeGroupId);
        },
        (response) => {
          this._messageService.onFailure('Failed to get Store.', response);
        })
  }

  onStoreGroupSelectionChange(storeGroupId){
    this.isControllingStoreDisabled = false;
    this.isControllingStore = false;
    this.storeDetailForm.patchValue({
      optOutOfCigaretteAndTobaccoPricingWhenControlled: false,
      controllingStoreId: null,
    });
    this.getPotentialControllingStores(storeGroupId);
  }

  onControllingStoreChange(controllingStoreId){
    this.storeDetailForm.patchValue({
      optOutOfCigaretteAndTobaccoPricingWhenControlled: false,
    });
  }

  getPotentialControllingStores(storeGroupId){
    this._storeService.getAllStoresByStoreGroupId(storeGroupId)
      .subscribe(
        (data:any) => {
          //create store diagram will all stores
          this.generateStoreList(data);

          //create list of only potential controlling stores
          this.controllingStores = [];
          for (var i = 0; i < data.length; i++) {
            if (data[i].controllingStoreId == null) this.controllingStores.push(data[i]);
          }

          if (this.storeId != '0'){ //not if creating a new store
            // if there is only one store in this group and this store being edited is that store...            
            if (data.length == 1) {
              if (data[0].storeId == this.store.storeId) {
                this.controllingStoreHint = 'Store ' + this.store.storeNumber + ' is the only store in this group.';
                this.isControllingStoreDisabled = true;
              }
            }
            // loop over all stores to see if any have this store's id as the controllingStoreId.  If so, make this controlling store select disabled.  (Changing groups will also be disabled for controlling stores)
            else {
              for (var i = 0; i < data.length; i++) {
                if (data[i].controllingStoreId && data[i].controllingStoreId == this.store.storeId) {
                  this.controllingStoreHint = 'Store ' + this.store.storeNumber + ' is a controlling store.';
                  this.isControllingStoreDisabled = true;
                  this.isControllingStore = true;
                }
              }
            }
          }
        },
        (response) => {
          this._messageService.onFailure('Failed to get Controlling Stores.', response);
        })
  }

  generateStoreList(storesInGroup) {
    var htmlString = ''
    var controllingStores = [];
    var i = 0;
    var n = 0;
    //first get all controlling stores
    for (i = 0; i < storesInGroup.length; i++) {
      if (storesInGroup[i].controllingStoreId == null) {
        controllingStores.push(storesInGroup[i]);
      }
    }
    controllingStores.sort(this.compare);
    //then place controlled stores under respective controlled store
    for (n = 0; n < controllingStores.length; n++) {
      var controlledStores = [];
      for (i = 0; i < storesInGroup.length; i++) {
        if (controllingStores[n].storeId == storesInGroup[i].controllingStoreId) {
          controlledStores.push(storesInGroup[i]);
        }
      }
      controlledStores.sort(this.compare);
      controllingStores[n].controlledStores = controlledStores;
    }
    for (i = 0; i < controllingStores.length; i++) {
      var optionalControllingStoreText = controllingStores[i].controlledStores.length > 0 ? ' (Controlling Store)' : '';
      var optionalControllingStoreClass = controllingStores[i].controlledStores.length > 0 ? 'controlling-store' : 'independent-store';
      htmlString += '<div class="' + optionalControllingStoreClass + '">' + controllingStores[i].numberAndLocation + optionalControllingStoreText + '</div>';
      var controlledStoresHtml = '';
      for (n = 0; n < controllingStores[i].controlledStores.length; n++) {
        controlledStoresHtml += '<div class="controlled-store"><span>-</span>&nbsp;' + controllingStores[i].controlledStores[n].numberAndLocation + '</div>';
      }
      if (controllingStores[i].controlledStores.length > 0) {
        htmlString += '<div class="controlled-store-wrapper">' + controlledStoresHtml + '</div>';
      }
      else {
        htmlString += controlledStoresHtml;
      }
    }
    this.storeGroupDiagramHtml = htmlString;
  }

  compare(a, b) {
    if (Number(a.storeNumber) < Number(b.storeNumber))
      return -1;
    if (Number(a.storeNumber) > Number(b.storeNumber))
      return 1;
    return 0;
  }

  onSubmitStore(){
    //Users with hasReadOnlyRestriction set to true should not even be allowed to invoke this action because it is hidden or disabled in the UI.
    //This is for extra protection. The back end will also be protected.
    if (this.myUser.hasReadOnlyRestriction){
      this._messageService.alertReadOnlyRestriction();
      return;
    }

    var store = this.storeDetailForm.value;
    if (store.controllingStoreId == null || store.controllingStoreId == '') store.optOutOfCigaretteAndTobaccoPricingWhenControlled = false;
    if (this.mode === 'New') {
      this._storeService.createStore(store)
      .subscribe(
        (data) => {
          this.dialogRef.close('reload');
          this._messageService.onSuccess('Successfully create Store');
        },
        (response) => {
          this._messageService.onFailure('Failed to create Store.', response)
        }
      );
    }
    else {
      this._storeService.updateStore(this.storeId, store)
      .subscribe(
        (data) => {
          this.dialogRef.close('reload');
          this._messageService.onSuccess('Successfully updated Store');
        },
        (response) => {
          this._messageService.onFailure('Failed to update Store.', response)
        }
      );
    }
  }

  onTabChange($event){
    // if ($event.index == 2) {
    //   if (!this.users) this.getAllManageableUsersByStore(this.storeId);
    //   if (!this.pricingRules) this.getAllPricingRulesByStoreIdSansMatchCriteria(this.storeId);
    // }
  }

  // getAllManageableUsersByStore(storeId){
  //   this._userService.getAllManageableUsersByStore(storeId)
  //     .subscribe(
  //       (data:any) => {
  //         this.users = data;
  //         switch(data.length){
  //           case 1: this.usersDescription = '1 User is assigned to this store';
  //             break;
  //           default: this.usersDescription = data.length + ' Users are assigned to this store';
  //             break
  //         }
  //       },
  //       (response) => {
  //         this._messageService.onFailure('Failed to get Users in Store.', response);
  //       })
  // }

  // getAllPricingRulesByStoreIdSansMatchCriteria(storeId){
  //   this._pricingRulesService.getAllPricingRulesByStoreIdSansMatchCriteria(storeId)
  //     .subscribe(
  //       (data:any) => {
  //         this.pricingRules = data;
  //         switch(data.length){
  //           case 1: this.pricingRulesDescription = '1 Pricing Rule is owned by this store';
  //             break;
  //           default: this.pricingRulesDescription = data.length + ' Pricing Rules are owned by this store';
  //             break
  //         }
  //       },
  //       (response) => {
  //         this._messageService.onFailure('Failed to get Pricing Rules for Store.', response);
  //       })
  // }

  onOpenTransferDialog = new EventEmitter();

  onTransferButtonClick(){
    this.closeDialog();
    this.onOpenTransferDialog.emit(this.params);
  }

  // onTransferButtonClick() {
  //   this.openTransferDialog();
  //   // this.ngZone.run(() => {
  //   //   this._transferService.doesStoreHaveTransferableAssets(this.storeId)
  //   //     .subscribe(
  //   //       (data: any) => { //returns boolean
  //   //         if (data) { 
  //   //           this.openTransferDialog(); 
  //   //         }
  //   //         else{
  //   //           this._messageService.alert('This store does not have any transferable assets.');
  //   //         }
  //   //       },
  //   //       (response) => {
  //   //         this._messageService.onFailure('Failed to determine if this store has transferable assets.', response);
  //   //       }
  //   //     )
  //   // })
  // }

  // openTransferDialog() {
  //   this.transferDialogRef = this._dialog.open(StoreTransferDialogComponent, {
  //     disableClose: false,
  //     minWidth: '700px',
  //     data: {
  //       entityType: 'Store',
  //       entityName: this.store.numberAndLocation,
  //       donatingEntityId: this.store.storeId,
  //       stores: this.stores,
  //       storeGroupId: this.store.storeGroupId,
  //       storeGroups: this.storeGroups,
  //     }
  //   })
  //   this.transferDialogRef.beforeClosed().subscribe(result => {
  //     console.log(this.transferDialogRef);
  //     this.transferOccurred = this.transferDialogRef.componentInstance.transferOccurred;
  //     if (this.transferOccurred) { 
  //       this.doOnInit();
  //       // this.getAllManageableUsersByStore(this.storeId);
  //       // this.getAllPricingRulesByStoreIdSansMatchCriteria(this.storeId);  
  //     }
  //     this.transferDialogRef = null;
  //   });

  // }
  
  onAssetCountEvent(val){
    this.assetCount = val;
  }

  closeDialog(){
    this.dialogRef.close(this.transferOccurred);
  }

  compareFn(v1, v2): boolean {
    return v1 === v2;
  }

}
