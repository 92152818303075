import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AppConfig } from '../app.config';

@Injectable({
  providedIn: 'root'
})
export class TransferService {

  private _baseUrl = AppConfig.settings.baseUrl;
  private _reqHeader = new HttpHeaders({ 'Accept': 'application/json', 'Content-Type': 'application/json' });

  constructor ( 
    private _http: HttpClient, 
  ){}

  doesUserHaveTransferableAssets(userId) { 
    return this._http.get(this._baseUrl + '/api/Transfers/DoesUserHaveTransferableAssets/' + userId, { headers: this._reqHeader });
  }

  doesStoreHaveTransferableAssets(storeId) { 
    return this._http.get(this._baseUrl + '/api/Transfers/DoesStoreHaveTransferableAssets/' + storeId, { headers: this._reqHeader });
  }

  doesStoreGroupHaveTransferableAssets(storeGroupId) { 
    return this._http.get(this._baseUrl + '/api/Transfers/DoesStoreGroupHaveTransferableAssets/' + storeGroupId, { headers: this._reqHeader });
  }

  getTransferableAssetTypesByTransferEntityType(entityType:('Store Group' | 'Store' | 'User')) { 
    return this._http.get(this._baseUrl + '/api/Transfers/GetTransferableAssetTypesByTransferEntityType/' + encodeURI(entityType), { headers: this._reqHeader });
  }

  getAccessibleTransferableAssetTypesByTransferEntityType(entityType:('Store Group' | 'Store' | 'User')) { 
    return this._http.get(this._baseUrl + '/api/Transfers/GetAccessibleTransferableAssetTypesByTransferEntityType/' + encodeURI(entityType), { headers: this._reqHeader });
  }

  getTransferLogsByTransferEntityType(entityType:('Store Group' | 'Store' | 'User')) { 
    return this._http.get(this._baseUrl + '/api/Transfers/GetTransferLogsByTransferEntityType/' + encodeURI(entityType), { headers: this._reqHeader });
  }

  getTransferLogsByTransferEntityTypeAndEntityId(entityType:('Store Group' | 'Store' | 'User'), entityId) { 
    return this._http.get(this._baseUrl + '/api/Transfers/GetTransferLogsByTransferEntityTypeAndEntityId/' + encodeURI(entityType) + '/' +  entityId, { headers: this._reqHeader });
  }

  transferSelectAssetsFromEntityToEntity(donatingEntityId:string, inheritingEntityId:string, entityType:('Store Group' | 'Store' | 'User'), assetTypeOrAll, homeStoreId, selectedAssetIds:number[]) {
    return this._http.put(this._baseUrl + '/api/Transfers/TransferSelectAssetsFromEntityToEntity/' + String(donatingEntityId) + '/' + String(inheritingEntityId) + '/' + encodeURI(entityType) + '/' + encodeURI(assetTypeOrAll)+ '/' + homeStoreId, selectedAssetIds, { headers: this._reqHeader });
  }

}
