import { ChangeDetectorRef, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { NotificationWindowService } from '../notification-window.service';
import { trigger, state, style, transition, group, query, animateChild, animate } from '@angular/animations';
import { AuthenticationService } from 'src/app/core/authentication/shared/authentication.service';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { MessageService } from '../message.service';
import { SignalRService } from '../signal-r.service';
import { HelpService } from '../help.service';
//import { environment } from 'src/environments/environment';
//import { AppConfig } from 'src/app/app.config';

@Component({
  selector: 'rpms-notification-window',
  templateUrl: './notification-window.component.html',
  styleUrls: ['./notification-window.component.css'],
  animations: [
    trigger('notificationWindowAnimation', [
      state('open', style({
        bottom: '0px', //-115px to keep chat hidden
      })),
      state('closed', style({
        bottom: '-565px',
      })),
      transition('open <=> closed', [
        group([
          query('@arrowAnimation', animateChild()),
          animate('0.9s cubic-bezier(0.55, 0.31, 0.15, 0.93)'),
        ]),
      ]),
    ]),
    trigger('usersPaneAnimation', [
      state('open', style({
        left: '-301px',
      })),
      state('closed', style({
        left: '0',
      })),
      transition('open <=> closed', [
        animate('0.9s cubic-bezier(0.55, 0.31, 0.15, 0.93)'),
      ]),
    ]),
    trigger('arrowAnimation', [
      state('open', style({
        transform: 'rotate(180deg)',
      })),
      state('closed', style({
        transform: 'rotate(0deg)',
      })),
      transition('* <=> *', [
        animate('0.9s cubic-bezier(0.55, 0.31, 0.15, 0.93)')
      ]),
    ]),

  ],
  encapsulation: ViewEncapsulation.None //allows for css to be applied to dynamic html
})

export class NotificationWindowComponent implements OnInit {
  //private config = AppConfig.settings;

  confirmationDialogRef: MatDialogRef<ConfirmationDialogComponent>;

  isNotificationWindowOpen = false;
  isUsersPaneOpen = false;
  isUserLoggedIn = false;
  quantity: number = 0;
  lastAudioTime = new Date().getTime();

  isUserLoggedInSubscription;
  notificationHtmlSubscription;
  reachableUsersSubscription;
  isMobile;

  constructor(
    private _notificationWindowService: NotificationWindowService,
    private _authenticationService: AuthenticationService,
    private _signalRService: SignalRService,
    private _dialog: MatDialog, 
    private _messageService: MessageService,
    private _helpService: HelpService,
    private _changeDetectorRef: ChangeDetectorRef
  ) { }

  ngOnInit() {
    //if (this.config.logInits) console.log('notification window init');
    this.isUserLoggedInSubscription = this._authenticationService.isUserLoggedIn$
      .subscribe(
        (data) => {
          this.isUserLoggedIn = data; 
        }
      ); 

    // subscribe to notifications
    this.notificationHtmlSubscription = this._notificationWindowService.notificationHtml$
    .subscribe(
      (data) => {
        if (data === 'CLEAR'){
          //don't confirm with dialog
          var content = document.getElementById('notification-content');
          content.innerHTML = '';
          this.quantity = 0;
          this._changeDetectorRef.detectChanges();
        }
        else{
          this.writeNotificationToWindow(data);
        }
      }
    );

    this.reachableUsersSubscription = this._notificationWindowService.reachableUsers$
    .subscribe(
      (data) => {
        this.handleReachableUsers(data);
      }
    );

    //this.isUserLoggedIn = this._authenticationService.getIsUserLoggedIn();
    this.lastAudioTime = new Date().getTime();
    this.isMobile = navigator.userAgent.indexOf( "Mobile" ) !== -1 || 
                    navigator.userAgent.indexOf( "iPhone" ) !== -1 || 
                    navigator.userAgent.indexOf( "Android" ) !== -1 || 
                    navigator.userAgent.indexOf( "Windows Phone" ) !== -1;

  }

  ngOnDestroy(){
    this.isUserLoggedInSubscription.unsubscribe();
    this.notificationHtmlSubscription.unsubscribe();
    this.reachableUsersSubscription.unsubscribe();
  }

  toggleNotificationWindow() {
    this.isNotificationWindowOpen = !this.isNotificationWindowOpen;
  }

  toggleUsersPane() {
    this.isUsersPaneOpen = !this.isUsersPaneOpen;
  }

  writeNotificationToWindow(htmlData){
    var parent = document.getElementById('notification-content');
    var child = document.createElement("div");
    child.className = 'notification-wrapper';
    child.innerHTML = htmlData;
    parent.insertBefore(child, parent.firstChild);
    if (htmlData.indexOf('class="close"') >= 0) child.querySelector('.close').addEventListener('click', this.closeNotification.bind(this));
    if (htmlData.indexOf('item-link') >= 0) child.querySelector('.item-link').addEventListener('click', this.setNodeIdToView.bind(this));
    this.updateChatContentQuantity();
  }

  handleReachableUsers(userArray){
    //console.log('handleReachableUsers called.');
    //console.log(userArray);
    var self = this;
    var myUser = this._authenticationService.getMyUser();
    var i;
    var haveCreatedNotInAStoreHeader = false;
    var storeGroupIds = [];
    var storeIds = [];
    var connectionIds = [];
    var usersWrapper = document.getElementById('users-wrapper');
    var usersSelectWrapper = document.getElementById('users-select-wrapper');
    var usersDiv = <HTMLElement>document.createElement('div');
    if (usersWrapper && usersSelectWrapper) {
      usersWrapper.innerHTML = '';
      usersSelectWrapper.innerHTML = '';
      //remove inner event listeners?
      usersWrapper.appendChild(usersDiv);
      var usersSelect = <HTMLSelectElement>document.createElement('select');
      usersSelect.id = 'users-select';
      usersSelect.options[usersSelect.options.length] = new Option('-- Select --', '');
      if (myUser && myUser.roles[0] == 'Admin' || myUser.roles[0] == 'SuperAdmin') usersSelect.options[usersSelect.options.length] = new Option('All', 'all-0');

      // add store groups to DOM
      for (i = 0; i < userArray.length; i++) {
        if (userArray[i].storeWorkingIn != null) { //logged into a store
          if (storeGroupIds.indexOf(userArray[i].storeWorkingIn.storeGroupId) == -1) {
            storeGroupIds.push(userArray[i].storeWorkingIn.storeGroupId);
            var storeGroup = <HTMLElement>document.createElement('div');
            storeGroup.id = 'storeGroupId-' + userArray[i].storeWorkingIn.storeGroupId;
            storeGroup.classList.add('store-group');
            var heading = <HTMLElement>document.createElement('h5');
            heading.innerHTML = '<i class="fa fa-globe" aria-hidden="true"></i>Store Group: ' + userArray[i].storeWorkingIn.storeGroup.name;
            heading.addEventListener('click', function () { self.onMessageTargetClick(storeGroup.id)});
            storeGroup.appendChild(heading);
            usersDiv.appendChild(storeGroup);
            //select
            usersSelect.options[usersSelect.options.length] = new Option('Store Group: ' + userArray[i].storeWorkingIn.storeGroup.name, 'storeGroupId-' + userArray[i].storeWorkingIn.storeGroupId);
          }
        }
        else { //not logged into a store
          if (!haveCreatedNotInAStoreHeader) {
            var storeGroup = <HTMLElement>document.createElement('div');
            storeGroup.id = 'noStoreGroupId';
            storeGroup.classList.add('store-group');
            storeGroup.innerHTML = '<h5><i class="fa fa-globe" aria-hidden="true"></i>Not In A Store</h5>';
            usersDiv.appendChild(storeGroup);
            haveCreatedNotInAStoreHeader = true;
          }
        }
      }

      //add stores to DOM
      for (i = 0; i < userArray.length; i++) {
        if (userArray[i].storeWorkingIn != null) { //logged into a store
          if (storeIds.indexOf(userArray[i].storeWorkingIn.storeId) == -1) {
            storeIds.push(userArray[i].storeWorkingIn.storeId);
            var storeGroup = document.getElementById('storeGroupId-' + userArray[i].storeWorkingIn.storeGroupId);
            var store = <HTMLElement>document.createElement('div');
            store.id = 'storeId-' + userArray[i].storeWorkingIn.storeId;
            store.classList.add('store');
            var heading = <HTMLElement>document.createElement('h6');
            heading.innerHTML = '<i class="fa fa-map-marker" aria-hidden="true"></i>Store: ' + userArray[i].storeWorkingIn.numberAndLocation;
            heading.addEventListener('click', function () { self.onMessageTargetClick(store.id)});
            store.appendChild(heading);
            storeGroup.appendChild(store);
            //select
            usersSelect.options[usersSelect.options.length] = new Option('Store: ' + userArray[i].storeWorkingIn.numberAndLocation, 'storeId-' + userArray[i].storeWorkingIn.storeId);
          }
        }
      }

      for (i = 0; i < userArray.length; i++) {
        if (userArray[i].storeWorkingIn != null) { //logged into a store
          if (connectionIds.indexOf(userArray[i].connectionId) == -1) {
            var store = document.getElementById('storeId-' + userArray[i].storeWorkingIn.storeId);
            let user = <HTMLElement>document.createElement('div');
            user.id = 'connectionId-' + userArray[i].connectionId;
            user.classList.add('user');
            user.innerHTML = this.generateAvatar(userArray[i].user, i) + '<div class="full-name">' + userArray[i].user.fullName + '</div><div class="sender-email">' + userArray[i].user.userName + '</div>';
            user.addEventListener('click', function () { self.onMessageTargetClick(user.id)});
            store.appendChild(user);
          }
        }
        else { //not logged into a store
          if (connectionIds.indexOf(userArray[i].connectionId) == -1) {
            var noStoreGroup = document.getElementById('noStoreGroupId');
            let user = <HTMLElement>document.createElement('div');
            user.id = 'connectionId-' + userArray[i].connectionId;
            user.classList.add('user');
            user.innerHTML = this.generateAvatar(userArray[i].user, i) + '<div class="full-name">' + userArray[i].user.fullName + '</div><div class="sender-email">' + userArray[i].user.userName + '</div>';
            user.addEventListener('click', function () { self.onMessageTargetClick(user.id)});
            noStoreGroup.appendChild(user);
          }
        }
        //populate select
        if (connectionIds.indexOf(userArray[i].connectionId) == -1) {
          usersSelect.options[usersSelect.options.length] = new Option('User: ' + userArray[i].user.fullName, 'connectionId-' + userArray[i].connectionId);
        }
        connectionIds.push(userArray[i].connectionId);
      }
      usersSelectWrapper.appendChild(usersSelect);
    }
  }

  generateAvatar(user, i) {
    var firstInitial = (user.firstName == null || user.firstName == '') ? '' : user.firstName.substr(0, 1);
    var initials = firstInitial + user.lastName.substr(0, 1);
    var colors = ['#C0392B', '#9B59B6', '#2980B9', '#16A085', '#F39C12', '#E74C3C', '#8E44AD', '#3498DB', '#27AE60', '#F1C40F'];
    i = i % colors.length;
    return '<div class="avatar" style="background:' + colors[i] + '">' + initials + '</div>';
  }

  onMessageTargetClick(id){
    //console.log(id);
    var select = <HTMLSelectElement>document.getElementById('users-select');
    select.value = id;
  }

  sendMessage() {
    var self = this;
    var select = <HTMLSelectElement>document.getElementById('users-select');
    var selectValue = select.value;
    var sendTo = select.options[select.selectedIndex].innerHTML;
    var prefix = selectValue != '' ? selectValue.substring(0, selectValue.indexOf('-')) : '';
    var id = selectValue != '' ? selectValue.substring(selectValue.indexOf('-') + 1) : '';
    var textarea = <HTMLTextAreaElement>document.getElementById('message-text');
    var message = '<strong>' + textarea.value + '</strong><br/><br/><em>Message sent to ' + sendTo + '</em>';
    if (selectValue == '') {
      this._messageService.alert("Please select someone to send your message to.", "Attention");
    }
    else {
      switch (prefix) {
        case 'storeId':
          this._signalRService.sendMessageWithinStore(id, message, false,
            function (data) {
              self.onSuccessfulMessage();
            },
            function (response) {
              this._messageService.onFailure('Failed to send message.', response);
            });
          break;
        case 'storeGroupId':
          this._signalRService.sendMessageWithinStoreGroup(id, message, false,
            function (data) {
              self.onSuccessfulMessage();
            },
            function (response) {
              this._messageService.onFailure('Failed to send message.', response);
            });
          break;
        case 'connectionId':
          this._signalRService.sendMessageToUser(id, message, false,
            function (data) {
              self.onSuccessfulMessage();
            },
            function (response) {
              this._messageService.onFailure('Failed to send message.', response);
            });
          break;
        case 'all':
          this._signalRService.sendAdminMessage(message,
            function (data) {
              self.onSuccessfulMessage();
            },
            function (response) {
              this._messageService.onFailure('Failed to send message.', response);
            });
          break;
        default:
          this._messageService.alert('Could not get value of "Send Message To".');
          break;
      }
    }
  }

  onSuccessfulMessage() {
    // Clear text box and reset focus for next comment.
    var select = <HTMLSelectElement>document.getElementById('users-select');
    var textarea = <HTMLTextAreaElement>document.getElementById('message-text');
    textarea.value = '';
    textarea.focus();
    var remember = <HTMLInputElement>document.getElementById('rememberSendTo');
    if (!remember.checked) select.value = '';
  }

  updateChatContentQuantity() {
    var notifications = document.getElementsByClassName('notification-wrapper');
    this.quantity = notifications.length;
    this._changeDetectorRef.detectChanges();
    var now = new Date().getTime();
    if (now - 1000 > this.lastAudioTime){
      this.lastAudioTime = new Date().getTime();
      var audio = new Audio('/assets/audio/pop.mp3');
      if (!this.isMobile) audio.play(); 
    }
  }

  setNodeIdToView(event){
    var id = event.target.id;
    var nodeId = id.substring(id.lastIndexOf('-') + 1);
    this._notificationWindowService.setNodeIdToView(nodeId);
  }
  
  closeNotification(event){
    var target = this.closest(event.target, "notification-wrapper");
    target.parentNode.removeChild(target);
  }

  clearWindow(){
    this.confirmationDialogRef = this._dialog.open(ConfirmationDialogComponent, {
      disableClose: false,
      //width: '600px',
      data: {
        title: 'Are you sure?',
        message: 'Do you want to clear all notifications?',
        confirmText: 'Yes, clear them',
        cancelText: 'No, cancel this action!'
      }
    });
    this.confirmationDialogRef.afterClosed().subscribe(result => {
      if(result) {
        //console.log('confirmed!');
        var content = document.getElementById('notification-content');
        content.innerHTML = '';
        this.quantity = 0;
        this._changeDetectorRef.detectChanges();
      }
      this.confirmationDialogRef = null;
    });
  }

  hasClass(elem, cls) {
    var str = " " + elem.className + " ";
    var testCls = " " + cls + " ";
    return(str.indexOf(testCls) != -1) ;
  }

  closest(el, cls) {
    while (el  && el !== document) {
        if (this.hasClass(el, cls)) return el;
        el = el.parentNode;
    }
    return null;
  }

  showHelpfulTip(event){
    this._helpService.showHelpfulTip(event);
  }

  hideHelpfulTip(event){
    this._helpService.hideHelpfulTip(event);
  }

}
