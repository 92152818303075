import { Component, OnInit, ViewChild, Inject, NgZone } from '@angular/core';
import { AgGridAngular } from 'ag-grid-angular';
import { GridOptions } from 'ag-grid-community';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from 'src/app/shared/confirmation-dialog/confirmation-dialog.component';
import { StoreGroupService } from 'src/app/shared/store-group.service';
import { GridService } from 'src/app/shared/grid.service';
import { MessageService } from 'src/app/shared/message.service';
import { ViewDetailButtonComponent } from 'src/app/shared/view-detail-button/view-detail-button.component';
import { DeleteButtonComponent } from 'src/app/shared/delete-button/delete-button.component';
import { PermissionsService } from 'src/app/shared/permissions.service';
import { AccessService } from 'src/app/shared/access.service';
import { TransferButtonComponent } from 'src/app/shared/transfer-button/transfer-button.component';
import { StoreGroupTransferDialogComponent } from 'src/app/admin/admin-store-groups/store-group-transfer-dialog/store-group-transfer-dialog.component';
import { TransferService } from 'src/app/shared/transfer.service';
import { AdminStoreGroupDetailComponent } from '../admin-store-group-detail/admin-store-group-detail.component';
import { DeletionHistoryDialogComponent } from 'src/app/shared/deletion-history-dialog/deletion-history-dialog.component';
import { ViewAssetsButtonComponent } from 'src/app/shared/view-assets-button/view-assets-button.component';
import { StoreGroupOwnedAssetsComponent } from '../store-group-owned-assets/store-group-owned-assets.component';
import { AssetsDialogComponent } from 'src/app/shared/assets-dialog/assets-dialog.component';
import { AdminActionButtonsComponent } from 'src/app/shared/admin-action-buttons/admin-action-buttons.component';
import { AuthenticationService } from 'src/app/core/authentication/shared/authentication.service';

@Component({
  selector: 'app-admin-store-group-list',
  templateUrl: './admin-store-group-list.component.html',
  styleUrls: ['./admin-store-group-list.component.css']
})
export class AdminStoreGroupListComponent implements OnInit {
  @ViewChild('agGrid') agGrid: AgGridAngular;

  //adminDetailRef: MatDialogRef<AdminStoreGroupDetailDialog>;
  adminDetailRef: MatDialogRef<AdminStoreGroupDetailComponent>;
  ownedAssetsRef: MatDialogRef<AssetsDialogComponent>;
  confirmationDialogRef: MatDialogRef<ConfirmationDialogComponent>;
  transferDialogRef: MatDialogRef<StoreGroupTransferDialogComponent>;
  deletionHistoryDialogRef: MatDialogRef<DeletionHistoryDialogComponent>;

  gridOptions: GridOptions;
  quickFilterText: string = '';
  context; //required by grid
  //frameworkComponents; //required by grid
  myStoreGroups;
  permissions: any = {};
  myUser;
  rowData = [];
  
  getColumnDefs(){
    return [
      {
        headerName: "",
        field: "action",
        width: 60,
        minWidth: 60,
        maxWidth: 60,
        cellClass: "action-col",
        cellRenderer: this.permissions.roleAllowsUserToEditStoreGroup ? "ViewDetailButtonComponent" : null,
        pinned: "left",
        sortable: false,
        filter: false,
        suppressHeaderMenuButton: true,
        suppressNavigable: true,
        hide: !this.permissions.roleAllowsUserToEditStoreGroup
      },
      {
        headerName: "Store Group",
        field: "storeGroupName",
        cellClass: "storeGroupName-col",
        filter: "agTextColumnFilter",
        get headerTooltip() { return this.headerName; }
      },
      {
        headerName: "Stores In Group",
        field: "storeNumbersInGroup",
        cellClass: "storeNumbersInGroup-col",
        filter: "agTextColumnFilter",
        get headerTooltip() { return this.headerName; }
      },
      {
        headerName: "Description",
        field: "description",
        cellClass: "description-col",
        filter: "agTextColumnFilter",
        get headerTooltip() { return this.headerName; }
      },
      {
        headerName: "Actions",
        field: "actions",
        width: 160,
        minWidth: 160,
        maxWidth: 160,
        cellClass: ["assets-col", "tac"],
        cellRenderer: "AdminActionButtonsComponent",
        sortable: false,
        filter: false,
        suppressHeaderMenuButton: true,
        suppressNavigable: true,
      },

      // {
      //   headerName: "Assets",
      //   field: "assets",
      //   width: 80,
      //   minWidth: 80,
      //   maxWidth: 80,
      //   cellClass: ["assets-col", "tac"],
      //   cellRenderer: this.permissions.roleAllowsUserToDeleteStoreGroup ? "ViewAssetsButtonComponent" : null,
      //   sortable: false,
      //   filter: false,
      //   suppressHeaderMenuButton: true,
      //   suppressNavigable: true,
      //   hide: !this.permissions.roleAllowsUserToDeleteStoreGroup
      // },
      // {
      //   headerName: "Transfer",
      //   field: "transfer",
      //   width: 80,
      //   minWidth: 80,
      //   maxWidth: 80,
      //   cellClass: ["transfer-col", "tac"],
      //   cellRenderer:  this.permissions.roleAllowsUserToTransferStoreGroupAssets ? "TransferButtonComponent" : null,
      //   sortable: false,
      //   filter: false,
      //   suppressHeaderMenuButton: true,
      //   suppressNavigable: true,
      //   hide: !this.permissions.roleAllowsUserToTransferStoreGroupAssets,
      // },
      // {
      //   headerName: "Delete",
      //   field: "delete",
      //   width: 80,
      //   minWidth: 80,
      //   maxWidth: 80,
      //   cellClass: ["delete-col", "tac"],
      //   cellRenderer: this.permissions.roleAllowsUserToDeleteStoreGroup ? "DeleteButtonComponent" : null,
      //   sortable: false,
      //   filter: false,
      //   suppressHeaderMenuButton: true,
      //   suppressNavigable: true,
      //   hide: !this.permissions.roleAllowsUserToDeleteStoreGroup
      // },

    ];
  }
  
  constructor(
    private _storeGroupService: StoreGroupService,
    private _accessService: AccessService,
    private _transferService: TransferService,
    private _gridService: GridService,
    private _dialog: MatDialog, 
    private _messageService: MessageService,
    private _permissionsService: PermissionsService,
    private _authenticationService: AuthenticationService,
    private ngZone: NgZone,
    ) {
    this.permissions = this._permissionsService.getPermissions();
    this.myUser = this._authenticationService.getMyUser();

    this.gridOptions = <GridOptions> {
			columnDefs: this.getColumnDefs(),
			rowData: this.rowData,
      rowHeight: 35,    
      components: {
        ViewDetailButtonComponent: ViewDetailButtonComponent,
        ViewAssetsButtonComponent: ViewAssetsButtonComponent,
        DeleteButtonComponent: DeleteButtonComponent,
        TransferButtonComponent: TransferButtonComponent,
        AdminActionButtonsComponent: AdminActionButtonsComponent
      },
      defaultColDef: {
        sortable: true,
        resizable: true,
        floatingFilter: true,
      },
      statusBar: {
        statusPanels: [
          { statusPanel: 'agTotalAndFilteredRowCountComponent', align: 'left' },
        ],
      },

    }
    this.context = { componentParent: this }
  }

  ngOnInit() {
    this.doOnInit();
  }

  doOnInit(){
    this.getMyStoreGroupsWithStores();
  }

  getMyStoreGroupsWithStores(){
    this._storeGroupService.getMyStoreGroupsWithStores()
      .subscribe(
        (data) => {
          this.myStoreGroups = data;
          this.formatandSetRowData(data);
        },
        (response) => {
          this._messageService.onFailure('Failed to get Store Groups.', response);
        })
  }

  formatandSetRowData(data) {
    var gridData = [];
    for (var i=0; i<data.length; i++){
      var singleRowData = {};
      var actionFieldValue = '';
      var deleteFieldValue = '';
      singleRowData = {
        storeGroupId: data[i].storeGroupId, //need this to pass to detail page from detail button
        action: actionFieldValue,
        storeGroupName: data[i].name,
        storeNumbersInGroup: this.getListOfStoreNumbers(data[i]),
        storesInGroup: data[i].stores,
        description: data[i].description,
        delete: deleteFieldValue,
        permissions: { //used to show or hide action buttons
          roleAllowsUserToViewAssets: this.permissions.roleAllowsUserToViewStoreGroupAssets, 
          roleAllowsUserToTransferAssets: this.permissions.roleAllowsUserToTransferStoreGroupAssets, 
          roleAllowsUserToDelete: this.permissions.roleAllowsUserToDeleteStoreGroup,
          userHasReadOnlyRestriction: this.myUser.hasReadOnlyRestriction,
        },
    };
      gridData.push(singleRowData);
    }
    this.rowData = gridData;
    this._gridService.onWindowResize('#ag-grid-wrapper');
    setTimeout(() => {
      this.fitColumns();
      this._gridService.sizeGrid('#ag-grid-wrapper');
    }, 100); 
  }

  getListOfStoreNumbers(storeGroup) {
    var list = '';
    storeGroup.stores.sort(this.compare);
    for (var i = 0; i < storeGroup.stores.length; i++) {
      list += storeGroup.stores[i].storeNumber;
      if (i != storeGroup.stores.length - 1) list += ', ';
    }
    return list;
  }

  compare(a, b) {
    if (Number(a.storeNumber) < Number(b.storeNumber))
      return -1;
    if (Number(a.storeNumber) > Number(b.storeNumber))
      return 1;
    return 0;
  }

  clearFilters(){
    this.quickFilterText = '';
    this.agGrid.api.setGridOption('quickFilterText', this.quickFilterText);
    this.agGrid.api.setFilterModel(null);
    this.agGrid.api.onFilterChanged();
  }

  resetColumns(){
    this.agGrid.api.resetColumnState();
    this.fitColumns(); //since this is done by default also
  }

  autoSizeColumns(){
    var columns = this.agGrid.api.getColumns();
    this.agGrid.api.autoSizeColumns(columns);
  }

  fitColumns(){
    this.agGrid.api.sizeColumnsToFit();
  }

  onQuickFilterChange(){
    this.agGrid.api.setGridOption('quickFilterText', this.quickFilterText);
  }

  onDetailButtonClick(params){
    this.adminDetailRef = this._dialog.open(AdminStoreGroupDetailComponent, {
      width: '90vw',
      maxWidth: '1000px',
      maxHeight: '90vh',
      data: {
        id: params.data.storeGroupId,
        params: params,
        storeGroups: this.myStoreGroups,
        rowData: params.data,
        entityType: 'Store Group'
      }
    });
    const subscribeDialog = this.adminDetailRef.componentInstance.onOpenTransferDialog.subscribe((params) => {
      this.onTransferButtonClick(params);
    });

    // this.adminDetailRef.beforeClosed().subscribe(result => {
    //   console.log(this.adminDetailRef);
    //   let wasTransferMade = this.adminDetailRef.componentInstance.wasTransferMade;
    //   if (wasTransferMade) this.getMyStoreGroupsWithStores();
    //   this.adminDetailRef = null;
    // });

    this.adminDetailRef.afterClosed().subscribe(reload => {
      if(reload) {
        this.doOnInit();
      }
      subscribeDialog.unsubscribe();
      this.adminDetailRef = null;
    });
  }

  onAssetButtonClick(params){
    this.ownedAssetsRef = this._dialog.open(AssetsDialogComponent, {
      width: '90vw',
      maxWidth: '1000px',
      maxHeight: '90vh',
      data: {
        rowData: params.data,
        params: params,
        entityName: params.data.storeGroupName,
        entityType: 'Store Group',
        storeGroupId: params.data.storeGroupId,
      }
    });
    const subscribeDialog = this.ownedAssetsRef.componentInstance.onOpenTransferDialog.subscribe((params) => {
      this.onTransferButtonClick(params);
    });
    this.ownedAssetsRef.afterClosed().subscribe(reload => {
      if(reload) {
        this.doOnInit();
      }
      subscribeDialog.unsubscribe();
      this.ownedAssetsRef = null;
    });
  }

  onTransferButtonClick(params) {
    this.openTransferDialog(params);
    // this.ngZone.run(() => {
    //   this._transferService.doesStoreGroupHaveTransferableAssets(params.data.storeGroupId)
    //     .subscribe(
    //       (data: any) => { //returns boolean
    //         if (data) { 
    //           this.openTransferDialog(params);
    //         }
    //         else{
    //           this._messageService.alert('This store group does not have any transferable assets.')
    //         }
    //       },
    //       (response) => {
    //         this._messageService.onFailure('Failed to determine if this store group has transferable assets.', response);
    //       }
    //     )
    // });
  }

  openTransferDialog(params) {
    this.transferDialogRef = this._dialog.open(StoreGroupTransferDialogComponent, {
      disableClose: false,
      width: '90vw',
      maxWidth: '1000px',
      maxHeight: '90vh',
      data: {
        donatingEntityId: params.data.storeGroupId,
        rowData: params.data,
        params: params,
        entityType: 'Store Group',
        entityName: params.data.storeGroupName,
        storeGroups: this.myStoreGroups,
        storesInGroup: params.data.storesInGroup,
        storeGroupId: params.data.storeGroupId,
      }
    })
    this.transferDialogRef.afterClosed().subscribe(reload => {
      if (reload){
        this.doOnInit(); //reload page
      }
      this.transferDialogRef = null;
    });

  }

  onDeleteButtonClick(params){
    this.ngZone.run(() => {
      if (this.myUser.hasDeletionPrivilege) {
        this._accessService.isForcedDeletionRequiredForStoreGroup(params.data.storeGroupId)
          .subscribe(
            (data: any) => {
              this.confirmDeletion(params, data.forcedDeletionRequired, data.reason);
            },
            (response) => {
              this._messageService.onFailure('Failed to determine if forced deletion is required.', response);
            }
          )
      }
      else {
        this._messageService.alert('You are not authorized to delete store groups. If you believe you should have that privilege, contact an administrator.');
      }
    });
  }

  confirmDeletion(params, isForcedDeletionRequiredForStoreGroup, reason){
    //if (reason) reason = reason.replace(/\r\n/g, '<br/>').replace(/\.\.\./g, '<span class="indenter">&bull;</span>');
    if (reason) reason = reason.replace(/\r\n/g, '<br/>')
      .replace(/\u00b9/g, '<span class="indent-1"></span>')
      .replace(/\u00b2/g, '<span class="indent-2"></span>')
      .replace(/\u00b3/g, '<span class="indent-3"></span>')
      .replace(/\u2074/g, '<span class="indent-4"></span>')
      .replace(/\u2075/g, '<span class="indent-5"></span>')
      .replace(/\u2076/g, '<span class="indent-6"></span>')
      .replace(/\u2077/g, '<span class="indent-7"></span>')
      .replace(/\u2078/g, '<span class="indent-8"></span>');
    let msg = isForcedDeletionRequiredForStoreGroup ? reason : 
      '"Forced Deletion" is not required for this store group. Do you want to delete ' + params.data.name +'?';
    this.confirmationDialogRef = this._dialog.open(ConfirmationDialogComponent, {
      disableClose: false,
      //width: '600px',
      data: {
        title: 'Are you sure?',
        message: msg,
        confirmText: 'Yes, delete store group',
        cancelText: 'No, cancel this action!',
        thirdButtonText: isForcedDeletionRequiredForStoreGroup ? 'Open Transfer Dialog' : undefined,
      }
    });
    this.confirmationDialogRef.afterClosed().subscribe(result => {
      if (result == 'thirdAction'){
        this.openTransferDialog(params);
      }
      else if(result) {
        if (isForcedDeletionRequiredForStoreGroup) this.deleteStoreGroup(params, true);
        else this.deleteStoreGroup(params, false);
      }
      this.confirmationDialogRef = null;
    });
  }

  onViewDeletionHistoryButtonClick() {
    this.deletionHistoryDialogRef = this._dialog.open(DeletionHistoryDialogComponent, {
      disableClose: false,
      width: '90vw',
      maxWidth: '1500px',
      maxHeight: '90vh',
      data: {
        entityType: 'Store Group'
      }
    })
  }

  deleteStoreGroup(params, forceDisassociationOrDeletionOfDependencies){
    //Users with hasReadOnlyRestriction set to true should not even be allowed to invoke this action because it is hidden or disabled in the UI.
    //This is for extra protection. The back end will also be protected.
    if (this.myUser.hasReadOnlyRestriction){
      this._messageService.alertReadOnlyRestriction();
      return;
    }

    this._storeGroupService.deleteStoreGroup(params.data.storeGroupId, forceDisassociationOrDeletionOfDependencies)
    .subscribe(
      (data) => {
        this._messageService.onSuccess('Successfully deleted store group');
        this.getMyStoreGroupsWithStores();
      },
      (response) => {
        this._messageService.onFailure('Failed to delete store group.', response);
      })
  }

  goToCreateStoreGroup(){
    this.adminDetailRef = this._dialog.open(AdminStoreGroupDetailComponent, {
      width: '550px',
      //height: '700px',
      data: {
        id: '0'
      }
    });
    this.adminDetailRef.afterClosed().subscribe(result => {
      if(result === 'reload') {
        this.getMyStoreGroupsWithStores();
      }
      this.adminDetailRef = null;
    });
  }

}

@Component({
  selector: 'app-admin-store-group-detail-dialog',
  template: `<rpms-admin-store-group-detail [id]="data.id"></rpms-admin-store-group-detail>`,
})
export class AdminStoreGroupDetailDialog {

  constructor(
    public dialogRef: MatDialogRef<AdminStoreGroupDetailDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any) {}

}

