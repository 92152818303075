import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse } from "@angular/common/http";
import { Observable } from "rxjs/internal/Observable";
import { tap } from 'rxjs/operators';
import { Injectable } from "@angular/core";
import { SpinnerService } from "src/app/shared/spinner.service";
import { Router } from "@angular/router";
import { AuthenticationService } from "./authentication.service";

@Injectable({
    providedIn: 'root'
  })
export class AuthInterceptor implements HttpInterceptor {
    config;
    constructor(
        private _spinnerService: SpinnerService,
        private _router: Router,
        ) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        var token = sessionStorage.getItem('rpmsToken');
        //var token = this._authenticationService.getToken();

        //show loading spinner except when pinging server
        //Need to add set loader variable inside a setTimeout to prevent this error:
        //ExpressionChangedAfterItHasBeenCheckedError: Expression has changed after it was checked. Previous value: 'ngIf: false'. Current value: 'ngIf: true'.
        //https://blog.angular-university.io/angular-debugging/ 
        //Also documented in angular's own documentation https://angular.io/guide/component-interaction#!#parent-to-view-child
        if (req.urlWithParams.indexOf('Ping') == -1) {
            //setTimeout(() => this._spinnerService.handleProgressSpinnerVisibility('show'),0);
            //moved these out of setTimeout after moving spinner component subscriptions into the constructor instead of ngOnInit
            //no longer get the ExpressionChangedAfterItHasBeenCheckedError
            this._spinnerService.handleProgressSpinnerVisibility('show');
        }

        if (token) {
            req = req.clone({
                setHeaders: {
                    Authorization: 'Bearer ' + token
                }
            });
        }

        const started = Date.now();
        return next.handle(req).pipe(
        tap(event => {
          if (event instanceof HttpResponse) {
            const elapsed = Date.now() - started;
            //console.log(req);
            //console.log(event);
            //App.Config can't be injected like other files because it uses the interceptor to get the file from the assets folder
            //So instead, I'm just reading the config.json file if that is the requested url then setting it as a variable in this interceptor service
            if (req.url.indexOf('assets/config') > -1){
              this.config = event.body;
            }
            if (this.config && this.config.logRequests) console.log(`Request for ${req.urlWithParams} took ${elapsed} ms.`);

            if (req.urlWithParams.indexOf('Ping') != -1){
              //don't hide spinner when complete
            }
            else if (this._router.routerState.snapshot.url == '/price-management' && req.urlWithParams.indexOf('UpdateAdWeekGridMetadata') != -1) {
              //don't hide spinner when complete 
              //this is saving metadata from AdWeek when navigating to price management
              //when it completed, it hid the spinner while item filters were in the process of loading
            }
            else{
              //hide spinner when all other calls are complete
              this._spinnerService.handleProgressSpinnerVisibility('hide');
            }
            //console.log(this.config);
          }
        }, error => {
          console.error('ERROR (from Interceptor)', error)
          //this._messageService.alert(error.error.error_description, 'Attention!')

          //setTimeout(() => this._spinnerService.handleProgressSpinnerVisibility('hide'),0);
          this._spinnerService.handleProgressSpinnerVisibility('hide');
        })
      )
    }
}