import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { AppConfig } from '../app.config';

@Injectable({
  providedIn: 'root'
})
export class PosExportService {

  private _baseUrl = AppConfig.settings.baseUrl;
  private _reqHeader = new HttpHeaders({ 'Accept': 'application/json', 'Content-Type': 'application/json' });

  constructor ( 
    private _http: HttpClient, 
  ){}

  getAllStorePosExportSettings() {
    return this._http.get(this._baseUrl + '/api/StorePosExportSettings/GetAllStorePosExportSettings', { headers: this._reqHeader });
  }

  getStorePosExportSettingsById(settingsId) {
    return this._http.get(this._baseUrl + '/api/StorePosExportSettings/GetStorePosExportSettingsById/' + settingsId, { headers: this._reqHeader });
  }

  updateStorePosExportSettings(settingsId, settings) {
    return this._http.put(this._baseUrl + '/api/StorePosExportSettings/UpdateStorePosExportSettings/' + settingsId, settings, { headers: this._reqHeader });
  }

  updateStorePosExportSettingsFromSettings(targetSettingsId, sourceSettingsId, settings) {
    return this._http.put(this._baseUrl + '/api/StorePosExportSettings/UpdateStorePosExportSettingsFromSettings/' + targetSettingsId + '/' + sourceSettingsId, settings, { headers: this._reqHeader });
  }

  //dept associations

  getAllStorePosExportDeptAsscsByStoreId(storeId) {
    return this._http.get(this._baseUrl + '/api/StorePosExportDeptAsscs/GetAllStorePosExportDeptAsscsByStoreId/' + storeId, { headers: this._reqHeader });
  }

  createStorePosExportDeptAssc(deptAssc) {
    return this._http.post(this._baseUrl + '/api/StorePosExportDeptAsscs/CreateStorePosExportDeptAssc', deptAssc, { headers: this._reqHeader });
  }

  updateStorePosExportDeptAssc(associationId, association) {
    return this._http.put(this._baseUrl + '/api/StorePosExportDeptAsscs/UpdateStorePosExportDeptAssc/' + associationId, association, { headers: this._reqHeader });
  }

  deleteStorePosExportDeptAssc(associationId) {
    return this._http.delete(this._baseUrl + '/api/StorePosExportDeptAsscs/DeleteStorePosExportDeptAssc/' + associationId, { headers: this._reqHeader });
  }

  deleteStorePosExportDeptAsscsByStoreId(storeId) {
    return this._http.delete(this._baseUrl + '/api/StorePosExportDeptAsscs/DeleteStorePosExportDeptAsscsByStoreId/' + storeId, { headers: this._reqHeader });
  }

  getAllOrderHeaders() {
    return this._http.get(this._baseUrl + '/api/OrderHeaders/GetAllOrderHeaders', { headers: this._reqHeader });
  }

}
