import { Component, OnInit, Inject, LOCALE_ID, Input, ChangeDetectorRef, NgZone, ViewChild } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { UserService } from 'src/app/shared/user.service';
import { UntypedFormBuilder, Validators, AbstractControl } from '@angular/forms';
import { formatDate } from '@angular/common';
import { StoreGroupService } from 'src/app/shared/store-group.service';
import { StoreService } from 'src/app/shared/store.service';
import { AccessService } from 'src/app/shared/access.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MessageService } from 'src/app/shared/message.service';
import { ItemListService } from 'src/app/shared/item-list.service';
import { TransferService } from 'src/app/shared/transfer.service';
import { ItemFilterService } from 'src/app/shared/item-filter.service';
import { ConfirmationDialogComponent } from 'src/app/shared/confirmation-dialog/confirmation-dialog.component';
import { MatAccordion } from '@angular/material/expansion';
import { AuthenticationService } from 'src/app/core/authentication/shared/authentication.service';
//import { environment } from 'src/environments/environment';
import { AppConfig } from 'src/app/app.config';

@Component({
  selector: 'app-user-transfer-dialog',
  templateUrl: './user-transfer-dialog.component.html',
  styleUrls: ['./user-transfer-dialog.component.css']
})
export class UserTransferDialogComponent implements OnInit {

  @ViewChild(MatAccordion) accordion: MatAccordion;
  private config = AppConfig.settings;

  confirmationDialogRef: MatDialogRef<ConfirmationDialogComponent>;

  users;
  userId;

  storeGroups = [];
  allStoreGroupIds;

  managedStoreGroups = [];
  allManagedStoreGroupIds;
  areManagedStoreGroupsLoaded: boolean = false;

  homeStores = [];
  inheritingStoreGroupStores = [];
  homeStoreId;
  areHomeStoresLoaded: boolean = false;
  //homeStoreIds; //not needed since it's not multiselect

  itemFilters = [];
  allItemFilterIds;
  itemFilterDescription;
  areItemFiltersLoaded: boolean = false;

  itemLists = [];
  allItemListIds;
  itemListDescription;
  areItemListsLoaded: boolean = false;

  assetTypes;
  rowData;
  storeGroupId; //passed in for store transfer to only show stores in same group
  entityType;
  entityName;
  donatingEntityId;
  logEntries = [];
  assetCount = 0;

  showTransferButton: boolean = false;
  transferOccurred: boolean = false; //used to reload list page if needed

  showFormControls: boolean = false;
  showInheritingUserSelect: boolean = false;
  showInheritingStoreGroupSelect: boolean = false;
  showAssetTypeSelect: boolean = false;
  showItemFiltersSelect: boolean = false;
  showItemListsSelect: boolean = false;
  showManagedStoreGroupsSelect: boolean = false;
  showHomeStoreSelect: boolean = false;

  itemFilterPanelOpenState: boolean = false;
  itemListPanelOpenState: boolean = false;

  myUser;
  
  assetTransferForm = this._fb.group({
    userRadioButton: ['', Validators.required],
    inheritingStoreGroupId: [''],
    inheritingUserId: [''],
    assetType: [''],
    itemFilterIds: [''],
    itemListIds: [''],
    managedStoreGroupIds: [''],
    homeStoreId: [''],
  });

  constructor(private _activatedRoute: ActivatedRoute,
              private _transferService: TransferService,
              private _userService: UserService,
              private _fb: UntypedFormBuilder,
              private _storeGroupService: StoreGroupService,
              private _storeService: StoreService,
              private _itemFilterService: ItemFilterService,
              private _itemListService: ItemListService,
              private _messageService: MessageService,
              private _authenticationService: AuthenticationService,
              private ngZone: NgZone,
              private _dialog: MatDialog, 
              public dialogRef: MatDialogRef<UserTransferDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) 
              {
                this.myUser = this._authenticationService.getMyUser();

                this.donatingEntityId = data.donatingEntityId,
                this.rowData = data.rowData == undefined ? [] : data.rowData;
                this.entityType = 'User', //data.entityType;
                this.entityName = data.entityName;
                this.users = data.users == undefined ? [] : data.users.sort((a, b) => (a.fullName.toLowerCase() > b.fullName.toLowerCase()) ? 1 : -1);
                this.storeGroups = data.storeGroups == undefined ? [] : data.storeGroups;
                this.storeGroupId = data.storeGroupId;
                this.homeStoreId = data.homeStoreId;
                this.userId = data.userId;
                //this.doOnInit();
              }

  ngOnInit() {
    if (this.config.logInits) console.log('transfer dialog init');
    //if changing users without leaving page (manage myUser)
    //ngOnInit will not run again, but the subscribe method below will update and call doOnInit()
    // this._activatedRoute.paramMap.subscribe((params: ParamMap) =>{
    //   this.userId = params.get('id');
    //   this.doOnInit();
    // })
    // this.userId = this.id;
    this.doOnInit();
  }

  doOnInit(){
    if (!this.myUser.hasTransferPrivilege || this.myUser.hasReadOnlyRestriction) {
      //lock fields
      this.assetTransferForm.disable();
    }
    //getting users is not needed if passing users from user list page
    //this.getUsers();
    this.removeDonatingUserfromUsers();
    this.removeDonatingStoreGroupfromStoreGroups();
    this.getAssetTypes();
    this.getTransferLogs();
    //this.getOwnedItemFilters(this.donatingEntityId);
    //this.getOwnedItemLists(this.donatingEntityId);
    //this.handleRequiredFields();
  }

  removeDonatingStoreGroupfromStoreGroups() {
    let arr = [];
    for (let i=0; i<this.storeGroups.length; i++) {
      if (this.storeGroups[i].storeGroupId != this.storeGroupId) arr.push(this.storeGroups[i]);
    }
    this.storeGroups = arr;
  }

  removeDonatingUserfromUsers() {
    let arr = [];
    for (let i=0; i<this.users.length; i++) {
      if (this.users[i].userId != this.userId) arr.push(this.users[i]);
    }
    this.users = arr;
  }

  removeDonatingStorefromStores() {
    let arr = [];
    for (let i=0; i<this.homeStores.length; i++) {
      if (this.homeStores[i].storeId != this.homeStoreId) arr.push(this.homeStores[i]);
    }
    this.homeStores = arr;
  }


  getAssetTypes(){
    this._transferService.getAccessibleTransferableAssetTypesByTransferEntityType(this.entityType)
    .subscribe(
      (data:any) => {
        this.assetTypes = data;
        // if (this.entityType == 'Store Group') this.getAllDonatingStoresByStoreGroupId(this.donatingEntityId)
      },
      (response) => {
        this._messageService.onFailure('Failed to get Asset Types.', response);
      })
  }

  getTransferLogs(){
    this._transferService.getTransferLogsByTransferEntityTypeAndEntityId(this.entityType, this.donatingEntityId)
    .subscribe(
      (data:any) => {
        this.logEntries = data;
        this.logEntries.map(l => l.performedAt = new Date(l.performedAt).toLocaleString());
        this.logEntries.map(l => l.transferDescription = this.formatLogDescription(l.transferDescription));
      },
      (response) => {
        this._messageService.onFailure('Failed to get Transfer Log.', response);
      })
  }

  formatLogDescription(description) {
    if (description) {
      description = description.replace(/\r\n/g, '<br/>')
      .replace(/\u00b9/g, '<span class="description-highlight">')
      .replace(/\u00b2/g, '<span class="description-highlight">')
      .replace(/\u00b3/g, '<span class="description-highlight">')
      .replace(/\u2074/g, '<span class="description-highlight">')
      .replace(/\u2075/g, '<span class="description-highlight">')
      .replace(/\u2076/g, '<span class="description-highlight">')
      .replace(/\u2077/g, '<span class="description-highlight">')
      .replace(/\u2078/g, '<span class="description-highlight">')
      .replace(/\u00b7/g, '</span>');
    }
    return description;
  }

  getOwnedItemFilters(userId){
    this.areItemFiltersLoaded = false;
    this._itemFilterService.getOwnedItemFilters(userId)
      .subscribe(
        (data:any) => {
          this.itemFilters = data;
          this.allItemFilterIds = data.map(a => a.itemFilterId);
          this.itemFilterDescription = data && data.length == 1 ? 
            '1 Item Filter is owned by this user' : data.length + ' Item Filters are owned by this user';
          this.areItemFiltersLoaded = true;
        },
        (response) => {
          this._messageService.onFailure('Failed to get Item Filters.', response);
        })
  }

  getOwnedItemLists(userId){
    this.areItemListsLoaded = false;
    this._itemListService.getOwnedItemLists(userId)
      .subscribe(
        (data:any) => {
          this.itemLists = data;
          this.allItemListIds = data.map(a => a.itemListId);
          this.itemListDescription = data && data.length == 1 ? 
            '1 Item List is owned by this user' : data.length + ' Item Lists are owned by this user';
          this.areItemListsLoaded = true;
        },
        (response) => {
          this._messageService.onFailure('Failed to get Item Lists.', response);
        })
  }

  // getAllDonatingStoresByStoreGroupId(storeGroupId) {
  //   this._storeService.getAllStoresByStoreGroupId(storeGroupId)
  //   .subscribe(
  //     (data:any) => {
  //       this.donatingStoreGroupStores = data;
  //     },
  //     (response) => {
  //       this._messageService.onFailure('Failed to get store from donating store group.', response);
  //     })
  // }

  getAllInheritingStoresByStoreGroupId(storeGroupId) {
    this.areHomeStoresLoaded = false;
    this._storeService.getAccessibleStoresByStoreGroupId(storeGroupId)
    .subscribe(
      (data:any) => {
        this.homeStores = data;
        this.removeDonatingStorefromStores();
        this.areHomeStoresLoaded = true;
        // if (this.assetTransferForm.get('assetType').value == 'All') this.homeStores = this.donatingStoreGroupStores.concat(data);
      },
      (response) => {
        this._messageService.onFailure('Failed to get store from inheriting store group.', response);
      })
  }

  getManagedStoreGroupsByUserId(userId){
    this.areManagedStoreGroupsLoaded = false;
    this._storeGroupService.getManagedStoreGroupsByUserId(userId)
      .subscribe(
        (data:any) => {
          this.managedStoreGroups = data
            .sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : -1);
          this.allManagedStoreGroupIds = data
            .sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : -1)
            .map(a => a.storeGroupId);
        },
        (response) => {
          this._messageService.onFailure('Failed to get Store Groups for User.', response);
        })
  }

  onUserRadioButtonChange(value){
        //https://stackoverflow.com/questions/41990171/angular-2-not-re-rendering-until-i-click-the-page
    //this behavior only happens on rows further down the ag-grid list (12+)
    this.ngZone.run(() => { //need this because radio buttons needed two mouse clicks

      this.unrequireAllAssetSelects();
      this.hideAllAssetSelects();
      this.unrequireAllFirstLevelSelects();
      this.hideAllFirstLevelSelects();
      this.clearAllSelects();
      this.showFormControls = true;
      switch(value) {
        case '1':
          this.assetTransferForm.get('inheritingStoreGroupId').setValidators([Validators.required]);
          this.assetTransferForm.get('inheritingStoreGroupId').updateValueAndValidity();
          this.assetTransferForm.get('homeStoreId').setValidators([Validators.required]);
          this.assetTransferForm.get('homeStoreId').updateValueAndValidity();
          this.showInheritingStoreGroupSelect = true;
          break;
        case '2':
          this.assetTransferForm.get('homeStoreId').setValidators([Validators.required]);
          this.assetTransferForm.get('homeStoreId').updateValueAndValidity();
          this.getAllInheritingStoresByStoreGroupId(this.storeGroupId);
          this.showHomeStoreSelect = true;
          break;
        case '3':
          this.assetTransferForm.get('inheritingUserId').setValidators([Validators.required]);
          this.assetTransferForm.get('inheritingUserId').updateValueAndValidity();
          this.showInheritingUserSelect = true;
          break;
        default:
          break;
      }

    });
  }

  onSelectClick(formControlName){
    switch(formControlName) {
      case 'inheritingStoreGroupId':
        if (!this.storeGroups.length) 
          this._messageService.alert('You have no store groups available to inherit assets.');
        break;
      case 'inheritingUserId':
        if (!this.users.length) 
          this._messageService.alert('You have no users available to inherit assets.');
        break;
      default:
        this._messageService.alert('No options available.');
        break;
    }
  }

  onInheritingStoreGroupChange(storeGroupId){
    this.clearAllAssetSelects();
    this.assetTransferForm.get('assetType').setValue('');
    this.getAllInheritingStoresByStoreGroupId(storeGroupId);
    this.showHomeStoreSelect = true;
  }

  onInheritingUserChange(userId){
    this.clearAllAssetSelects();
    this.assetTransferForm.get('assetType').setValue('');
    this.assetTransferForm.get('assetType').setValidators([Validators.required]);
    this.assetTransferForm.get('assetType').updateValueAndValidity();
    this.showAssetTypeSelect = true;
  }

  onAssetTypeChange(value) {
    this.unrequireAllAssetSelects();
    this.clearAllAssetSelects();
    this.hideAllAssetSelects();
    switch (value) {
      case 'All':
        if (this.entityType == 'Store Group') { 
          // this.homeStores = this.donatingStoreGroupStores.concat(this.inheritingStoreGroupStores);
          this.assetTransferForm.get('homeStoreId').setValidators([Validators.required]);
          this.assetTransferForm.get('homeStoreId').updateValueAndValidity();
          this.homeStores = this.inheritingStoreGroupStores;
          this.showHomeStoreSelect = true;
        }
        break;
      case 'Item Filters': //user
        this.assetTransferForm.get('itemFilterIds').setValidators([Validators.required]);
        this.assetTransferForm.get('itemFilterIds').updateValueAndValidity();
        if (this.itemFilters.length == 0) this.getOwnedItemFilters(this.donatingEntityId);
        this.showItemFiltersSelect = true;
        break;
      case 'Item Lists': //user
        this.assetTransferForm.get('itemListIds').setValidators([Validators.required]);
        this.assetTransferForm.get('itemListIds').updateValueAndValidity();
        if (this.itemLists.length == 0) this.getOwnedItemLists(this.donatingEntityId);
        this.showItemListsSelect = true;
        break;
      case 'Managed Store Groups': //user
        this.assetTransferForm.get('managedStoreGroupIds').setValidators([Validators.required]);
        this.assetTransferForm.get('managedStoreGroupIds').updateValueAndValidity();
        if (this.managedStoreGroups.length == 0) this.getManagedStoreGroupsByUserId(this.donatingEntityId);
        this.showManagedStoreGroupsSelect = true;
      break;
      default:
        this._messageService.alert(value + ' is not a valid asset type.')
      break;
    }
  }

  onTransferClick(){
    if (this.myUser.hasTransferPrivilege) {
        this.confirmationDialogRef = this._dialog.open(ConfirmationDialogComponent, {
        disableClose: false,
        //width: '600px',
        data: {
          title: 'Are you sure?',
          message: 'Have you coordinated these changes with the Retail Department?<br/><br/>Do you want to proceed with this transfer?',
          confirmText: 'Yes, proceed with transfer',
          cancelText: 'No, cancel this action!'
        }
      });
      this.confirmationDialogRef.afterClosed().subscribe(result => {
        if(result) {
          this.doTransfer()
        }
        this.confirmationDialogRef = null;
      });
    }
    else {
      this._messageService.alert('You are not authorized to transfer assets. If you believe you should have that privilege, contact an administrator.');
    }
  }

  doTransfer(){
    //Users with hasReadOnlyRestriction set to true should not even be allowed to invoke this action because it is hidden or disabled in the UI.
    //This is for extra protection. The back end will also be protected.
    if (this.myUser.hasReadOnlyRestriction){
      this._messageService.alertReadOnlyRestriction();
      return;
    }

    //if a property is on 'this', it comes from the data passed into this dialog from the user list page
    //if a property is on transfer, it comes from the form value
      let transfer = this.assetTransferForm.getRawValue();
      switch (transfer.userRadioButton) {
        case '1': //store group and home store
          transfer.entityType = 'Store Group';
          transfer.donatingEntityId = this.storeGroupId;
          transfer.inheritingEntityId = transfer.inheritingStoreGroupId;
          transfer.homeStoreId = transfer.homeStoreId;
          transfer.assetType = 'Users (to new Home Store)';
          transfer.selectedAssetIds = [this.donatingEntityId]; //this user's id (in array)
          break;

        case '2': //home store in same group
          transfer.entityType = 'Store';
          transfer.donatingEntityId = this.homeStoreId;
          transfer.inheritingEntityId = transfer.homeStoreId;
          transfer.homeStoreId = 0;
          transfer.assetType = 'Users';
          transfer.selectedAssetIds = [this.donatingEntityId]; //this user's id (in array)
          break;

        case '3': //assets
          transfer.entityType = 'User';
          transfer.donatingEntityId = this.donatingEntityId;
          transfer.inheritingEntityId = transfer.inheritingUserId;
          transfer.homeStoreId = 0;
          switch (transfer.assetType) {
            case 'All':
              transfer.selectedAssetIds = [''];
              break;

            case 'Item Filters':
              transfer.selectedAssetIds = transfer.itemFilterIds;
              break;

            case 'Item Lists':
              transfer.selectedAssetIds = transfer.itemListIds;
              break;

            case 'Managed Store Groups':
              transfer.selectedAssetIds = transfer.managedStoreGroupIds;
              break;

            default:
              transfer.selectedAssetIds = [];
              break;
          }
          break;

        default:
          break;
      }
      transfer.selectedAssetIds = transfer.selectedAssetIds.map(a => String(a));
      //console.log(transfer);
      this._transferService.transferSelectAssetsFromEntityToEntity(
        transfer.donatingEntityId, 
        transfer.inheritingEntityId, 
        transfer.entityType, 
        transfer.assetType, 
        transfer.homeStoreId, 
        transfer.selectedAssetIds)
        .subscribe(
          (data:any) => {
            this._messageService.onSuccess('Successfully transferred selected assets.')
            this.assetTransferForm.reset();
            this.hideAllAssetSelects();
            this.hideAllFirstLevelSelects();
            this.transferOccurred = true;
            //this.doOnInit(); //reload everything
            this.closeDialog();
          },
          (response) => {
            this._messageService.onFailure('Failed to transfer Assets.', response);
          })
  }
  
  unrequireAllFirstLevelSelects(){
    this.assetTransferForm.get('inheritingStoreGroupId').setValidators([]);
    this.assetTransferForm.get('inheritingStoreGroupId').updateValueAndValidity();
    this.assetTransferForm.get('inheritingUserId').setValidators([]);
    this.assetTransferForm.get('inheritingUserId').updateValueAndValidity();
    this.assetTransferForm.get('assetType').setValidators([]);
    this.assetTransferForm.get('assetType').updateValueAndValidity();
    this.assetTransferForm.get('homeStoreId').setValidators([]);
    this.assetTransferForm.get('homeStoreId').updateValueAndValidity();
  }

  unrequireAllAssetSelects(){
    this.assetTransferForm.get('itemFilterIds').setValidators([]);
    this.assetTransferForm.get('itemFilterIds').updateValueAndValidity();
    this.assetTransferForm.get('itemListIds').setValidators([]);
    this.assetTransferForm.get('itemListIds').updateValueAndValidity();
    this.assetTransferForm.get('managedStoreGroupIds').setValidators([]);
    this.assetTransferForm.get('managedStoreGroupIds').updateValueAndValidity();
    this.assetTransferForm.get('homeStoreId').setValidators([]);
    this.assetTransferForm.get('homeStoreId').updateValueAndValidity();
  }
  
  clearAllSelects(){
    this.assetTransferForm.get('inheritingStoreGroupId').setValue('');
    this.assetTransferForm.get('inheritingUserId').setValue('');
    this.assetTransferForm.get('assetType').setValue('');
    this.assetTransferForm.get('itemFilterIds').setValue('');
    this.assetTransferForm.get('itemListIds').setValue('');
    this.assetTransferForm.get('managedStoreGroupIds').setValue('');
    this.assetTransferForm.get('homeStoreId').setValue('');
  }

  clearAllAssetSelects(){
    this.assetTransferForm.get('itemFilterIds').setValue('');
    this.assetTransferForm.get('itemListIds').setValue('');
    this.assetTransferForm.get('managedStoreGroupIds').setValue('');
    this.assetTransferForm.get('homeStoreId').setValue('');
  }

  hideAllFirstLevelSelects(){
    this.showInheritingStoreGroupSelect = false;
    this.showInheritingUserSelect = false;
    this.showAssetTypeSelect = false;
  }
 
  hideAllAssetSelects(){
    this.showItemFiltersSelect = false;
    this.showItemListsSelect = false;
    this.showManagedStoreGroupsSelect = false;
    this.showHomeStoreSelect = false;
  }

  selectAllOptions(sa, formControlName, allIdsName) {
    if (sa._selected) {
      this.assetTransferForm.get(formControlName).setValue(this[allIdsName])
        sa._selected = true;
    }
    if (sa._selected == false) {
      this.assetTransferForm.get(formControlName).setValue([]);
    }
  }

  onAssetCountEvent(val){
    this.assetCount = val;
  }

  onDeletionMadeEvent(bool){
    if (bool) this.doOnInit();
  }

  closeDialog(){
    this.dialogRef.close(this.transferOccurred);
  }

}
