<div id="offline-message">Offline</div>
<div id="pm-layout">
  <div id="data-information" *ngIf="isDataInfoBarVisible" [ngClass]="dataInfoBarClass">
    <span class="info">{{dataInfoBarMessage}}</span>
    <span class="synchronizing" *ngIf="isDataSynchronizing"><i class="fas fa-sync fa-spin"></i>&nbsp;Synchronizing with remote server now. </span>
    <div class="close" (click)="closeDataInfoBar()"><i class="fa fa-times" aria-hidden="true"></i></div>
  </div>
  <div id="pm-grid-and-controls">
    <div id="pm-top-controls">
      <div class="mat-select-override">
        <label>Store:</label>
        <!-- <mat-form-field> -->
          <mat-select id="pm-store-select" name="store" #storeSelect="ngModel" [(ngModel)]="store" placeholder="Select a Store" [compareWith]="compareStoreObjects" (selectionChange)="onStoreSelected()">
            <mat-option *ngFor="let store of stores" [value]="store">
              {{store.numberAndLocation}}
            </mat-option>
          </mat-select>
        <!-- </mat-form-field> -->
      </div>
      <div id="pm-filter-tools" class="row" [hidden]="!isStoreSelected">
        <input id="pm-quick-filter" class="quick-filter help-trigger" placeholder="Quick Filter..." type="text" [(ngModel)]="quickFilterText" (keyup)="onQuickFilterChange()" (mouseenter)="showHelpfulTip($event)" (mouseleave)="hideHelpfulTip($event)" data-help="quick-filter"/>
        <button id="clear-filters-button" (click)="clearFilters()" class="help-trigger" mat-stroked-button [ngClass]="{active: isFilterApplied }" (mouseenter)="showHelpfulTip($event)" (mouseleave)="hideHelpfulTip($event)" data-help="clear-filters">Clear Filters</button>
      </div>
      <div id="grid-controls">
        <button mat-stroked-button id="scroll-to-selected-button" class="help-trigger" (click)="viewSelectedRow()" (mouseenter)="showHelpfulTip($event)" (mouseleave)="hideHelpfulTip($event)" data-help="view-selected"><i class="fa fa-eye"></i></button>
        <button mat-stroked-button id="reset-columns-button" class="help-trigger" (click)="resetColumns()" (mouseenter)="showHelpfulTip($event)" (mouseleave)="hideHelpfulTip($event)" data-help="reset-columns">Reset Columns</button>
      </div>
    </div>
    <rpms-price-management-tabs></rpms-price-management-tabs>
    <div id="pm-grid-and-footer-wrapper">
      <ag-grid-angular #agGrid 
      [gridOptions]="gridOptions"
      style="width: 100%;" 
      class="ag-theme-balham"
      id="pm-ag-grid-wrapper"
      [rowData]="rowData" 
      [columnDefs]="columnDefs"
      [context]="context"
      [quickFilterText]="quickFilterText"
      [statusBar]="statusBar"
      [getRowId]="getRowId"
      (filterChanged)="onFilterChanged()"
      (modelUpdated)="onModelUpdated()"
      >
      </ag-grid-angular>

      <div id="pm-grid-footer-wrapper" *ngIf="isShowingFooter">
        <div id="grid-footer">
          <div id="footer-content" class="cf">
            <div class="col col-20">
              <div><span class="footer-label">Item Code:</span><span id="footer-itemCode" class="footer-value">{{selectedRowData.itemCode}}</span></div>
              <div><span class="footer-label">UPC Code:</span><span id="footer-upcCode" class="footer-value">{{selectedRowData.upcCode}}</span></div>
              <div><span class="footer-label">Case Cost:</span><span id="footer-caseCost" class="footer-value">{{selectedRowData.caseCost}}</span></div>
              <div><span class="footer-label">Base Cost:</span><span id="footer-baseCost" class="footer-value">{{selectedRowData.baseCost}}</span></div>
              <div>&nbsp;</div>
              <div><span class="footer-label">Status: </span><span id="footer-status" class="footer-value">{{selectedRowData.status}}</span> - <span id="footer-statusDescription"
                  class="footer-value">{{selectedRowData.statusDescription}}</span></div>
            </div>
            <div class="col col-40 cf">
              <h3 class="footer-header">Warehouse Information</h3>
              <div class="mvt cf">
                <div class="col-25"><span class="footer-label">LY Mvt</span><br /><span id="footer-lastYearsMovement" class="footer-value">{{selectedRowData.lastYearsMovement}}</span></div>
                <div class="col-25"><span class="footer-label">YTD Mvt</span><br /><span id="footer-yearToDateMovement" class="footer-value">{{selectedRowData.yearToDateMovement}}</span></div>
                <div class="col-25"><span class="footer-label">Wks in Whse</span><br /><span id="footer-weeksInInventory"
                    class="footer-value">{{selectedRowData.weeksInInventory}}</span></div>
                <div class="col-25"><span class="footer-label">Avg Wkly Mvt</span><br /><span id="footer-averageWeeklyMovement"
                    class="footer-value">{{selectedRowData.averageWeeklyMovement}}</span></div>
              </div>
              <div class="footer-item-info cf">
                <div class="col-50">Balance on Hand: <span id="footer-balanceOnHand" class="footer-value">{{selectedRowData.balanceOnHand}}</span></div>
                <div class="col-50">Off Pack: <span id="footer-offPack" class="footer-value">{{selectedRowData.offPack}}</span></div>
              </div>
              <div class="footer-item-info">
                <div>Order Header: <span id="footer-groupNumber" class="footer-value">{{selectedRowData.groupNumber}}</span> - <span id="footer-groupDescription"
                    class="footer-value">{{selectedRowData.groupDescription}}</span></div>
                    <div>Buyer: <span id="footer-buyerNameAndEmail" class="footer-value"><a href="{{'mailto:' + selectedRowData.buyerEmail}}">{{selectedRowData.buyerName}}</a></span> &nbsp; <span id="footer-buyerPhone"
                      class="footer-value">{{selectedRowData.buyerPhone | phoneNumber}}</span></div>
              </div>
            </div>
            <div class="col col-40 cf">
              <h3 class="footer-header">Store <span id="footer-storeNumber" class="footer-value"></span> Information</h3>
              <div class="mvt cf">
                <div class="col-25"><span class="footer-label">LY Mvt</span><br /><span id="footer-lastYearsMovementForStore"
                    class="footer-value">{{selectedRowData.lastYearsMovementForStore}}</span></div>
                <div class="col-25"><span class="footer-label">YTD Mvt</span><br /><span id="footer-yearToDateMovementForStore"
                    class="footer-value">{{selectedRowData.yearToDateMovementForStore}}</span></div>
                <div class="col-25"><span class="footer-label">Wks in Store</span><br /><span id="footer-weeksInStore" class="footer-value">{{selectedRowData.weeksInStore}}</span></div>
                <div class="col-25"><span class="footer-label">Avg Wkly Mvt</span><br /><span id="footer-averageWeeklyMovementForStore"
                    class="footer-value">{{selectedRowData.averageWeeklyMovementForStore}}</span></div>
              </div>
              <div class="cf">
                <div class="col-66">
                  <div>Last Ordered: <span id="footer-lastPurchasedDate" class="footer-value"></span></div>
                  <div class="tag"><button mat-raised-button id="tpr-tag-button" [disabled]="true">
                    Compare &#64; Tag</button><span id="tpr-tag-status">{{selectedRowData.tprTagStatus}}</span></div>
                  <div class="tag"><button mat-raised-button id="shelf-tag-button" [disabled]="true">
                    Shelf Tag</button><span id="shelf-tag-status">{{selectedRowData.shelfTagStatus}}</span></div>
              </div>
                <div class="col-33">
                  <div>&nbsp;</div>
                  <div><mat-checkbox id="keepSrpCb" disabled>Keep Price</mat-checkbox></div>
                  <div><mat-checkbox id="keepPercentCb" disabled>Keep Percent</mat-checkbox></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="grid-footer-down" class="button toggle-footer" (click)="hideFooter()" *ngIf="isShowingFooter"><i class="fas fa-caret-down"></i></div>
      <div id="grid-footer-up" class="button toggle-footer" (click)="showFooter()" *ngIf="!isShowingFooter"><i class="fas fa-caret-up"></i></div>
    </div>
  </div>
  <div class="row-count">{{rowCount}} Rows</div>
</div>






