<div class="detail admin-dialog">
<h1 mat-dialog-title [ngClass]="{'accent': id == '0'}">{{mode}} Announcement</h1>
<div mat-dialog-content>
  <form [formGroup]="announcementDetailForm" id="announcement-detail-form" (ngSubmit)="onSubmitAnnouncement()">
    <div class="checkbox-form-field">
      <mat-checkbox id="active" formControlName="active">Active</mat-checkbox>
    </div>
    <div class="row">
      <mat-form-field>
        <mat-label>Message</mat-label>
        <textarea matInput id="message" formControlName="message" placeholder="Message" required></textarea>
        <mat-error *ngIf="announcementDetailForm.hasError('required', 'message')">Message is required</mat-error>
      </mat-form-field>
    </div>
    <div class="row" *ngIf="mode !== 'New'">
      <mat-form-field>
        <mat-label>Created</mat-label>
        <input matInput id="created" formControlName="created" placeholder="Created">
      </mat-form-field>
      <mat-form-field>
        <mat-label>Modified</mat-label>
        <input matInput id="modified" formControlName="modified" placeholder="Modified">
      </mat-form-field>
    </div>
    <div class="detail-action-buttons">
      <button mat-raised-button color="primary" [disabled]="!announcementDetailForm.valid" type="submit">Save</button>
      <button mat-raised-button (click)="closeDialog()" type="button">Cancel</button>
    </div>
  </form>
</div>
</div>