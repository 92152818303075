import { Component, OnInit, Input, Inject, LOCALE_ID, ViewChild, NgZone, EventEmitter } from '@angular/core';
import { Validators, UntypedFormBuilder } from '@angular/forms';
import { StoreGroupService } from 'src/app/shared/store-group.service';
import { StoreService } from 'src/app/shared/store.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MessageService } from 'src/app/shared/message.service';
import { MatAccordion } from '@angular/material/expansion';
import { StoreGroupTransferDialogComponent } from '../store-group-transfer-dialog/store-group-transfer-dialog.component';
import { AppConfig } from 'src/app/app.config';
import { AuthenticationService } from 'src/app/core/authentication/shared/authentication.service';

@Component({
  selector: 'rpms-admin-store-group-detail',
  templateUrl: './admin-store-group-detail.component.html',
  styleUrls: ['./admin-store-group-detail.component.css']
})
export class AdminStoreGroupDetailComponent implements OnInit {

  @Input() id: string; 
  @ViewChild(MatAccordion) accordion: MatAccordion;
  private config = AppConfig.settings;

  transferDialogRef: MatDialogRef<StoreGroupTransferDialogComponent>;

  entityType = 'Store Group';
  storeGroupId: string;
  mode: string;
  storeGroup;
  storeGroups; //passed in from list but not needed since assets and transfers were reworked
  storesInGroup; //used for store diagram
  stores;
  storeGroupDiagramHtml = '';

  usersDescription;
  pricingRulesDescription;
  users = [];
  pricingRules = [];
  
  assetCount = 0;
  rowData;
  params;
  myUser;

  usersPanelOpenState: boolean = false;
  pricingRulesPanelOpenState: boolean = false;

  showTransferButton: boolean = true;
  transferOccurred: boolean = false; //used to reload list page if needed

  storeGroupDetailForm = this._fb.group({
    name: ['', Validators.required],
    description: [''],
  });

  constructor(private _fb: UntypedFormBuilder,
              private _storeGroupService: StoreGroupService,
              private _storeService: StoreService,
              private _authenticationService: AuthenticationService,
              private _messageService: MessageService,
              public dialogRef: MatDialogRef<AdminStoreGroupDetailComponent>,
              @Inject(LOCALE_ID) private locale: string,
              @Inject(MAT_DIALOG_DATA) public data: any) 
              {
                this.storeGroupId = data.id;
                this.storeGroups = data.storeGroups == undefined ? [] : data.storeGroups;
                this.rowData = data.rowData;
                this.params = data.params;
                this.myUser = this._authenticationService.getMyUser();
              }

  ngOnInit() {
    if (this.config.logInits) console.log('init store group detail');
    this.doOnInit();
  }

  doOnInit(){
    if (this.myUser.hasReadOnlyRestriction) this.storeGroupDetailForm.disable();
    //this.storeGroupId = this.id;  //8-18-2022
    this.mode = this.storeGroupId == '0' ? 'New' : 'Edit';
    if (this.storeGroupId != '0') this.getStoreGroup();
  }

  getStoreGroup(){
    this._storeGroupService.getStoreGroupById(this.storeGroupId)
      .subscribe(
        (data:any) => {
          this.storeGroup = data;
          if (this.config.logIds) console.log(`%cname: ${data.name}`, 'background: black; color: white; font-weight: bold; font-size: 120%');
          if (this.config.logIds) console.log(`%cstoreGroupId: ${data.storeGroupId}`, 'background: yellow; font-weight: bold; font-size: 120%');
      
          this.storeGroupDetailForm.patchValue({
            name: data.name,
            description: data.description,
          });
          this.getStoresInGroup();
        },
        (response) => {
          this._messageService.onFailure('Failed to get Store Group.', response);
        })
  }

  getStoresInGroup(){
    this._storeService.getAllStoresByStoreGroupId(this.storeGroupId)
      .subscribe(
        (data: any) => {
          this.storesInGroup = data;
          this.generateStoreList(data);
        },
        (response) => {
          this._messageService.onFailure('Failed to get Stores.', response);
        })
  }

  generateStoreList(storesInGroup) {
    var htmlString = ''
    var controllingStores = [];
    var i = 0;
    var n = 0;
    //first get all controlling stores
    for (i = 0; i < storesInGroup.length; i++) {
      if (storesInGroup[i].controllingStoreId == null) {
        controllingStores.push(storesInGroup[i]);
      }
    }
    controllingStores.sort(this.compare);
    //then place controlled stores under respective controlled store
    for (n = 0; n < controllingStores.length; n++) {
      var controlledStores = [];
      for (i = 0; i < storesInGroup.length; i++) {
        if (controllingStores[n].storeId == storesInGroup[i].controllingStoreId) {
          controlledStores.push(storesInGroup[i]);
        }
      }
      controlledStores.sort(this.compare);
      controllingStores[n].controlledStores = controlledStores;
    }
    for (i = 0; i < controllingStores.length; i++) {
      var optionalControllingStoreText = controllingStores[i].controlledStores.length > 0 ? ' (Controlling Store)' : '';
      var optionalControllingStoreClass = controllingStores[i].controlledStores.length > 0 ? 'controlling-store' : 'independent-store';
      htmlString += '<div class="' + optionalControllingStoreClass + '">' + controllingStores[i].numberAndLocation + optionalControllingStoreText + '</div>';
      var controlledStoresHtml = '';
      for (n = 0; n < controllingStores[i].controlledStores.length; n++) {
        controlledStoresHtml += '<div class="controlled-store"><span>-</span>&nbsp;' + controllingStores[i].controlledStores[n].numberAndLocation + '</div>';
      }
      if (controllingStores[i].controlledStores.length > 0) {
        htmlString += '<div class="controlled-store-wrapper">' + controlledStoresHtml + '</div>';
      }
      else {
        htmlString += controlledStoresHtml;
      }
    }
    // this.storeGroupDiagramHtml = '<label class="mat-label">Store Group Diagram</label>' + htmlString;
    this.storeGroupDiagramHtml = htmlString;
  }

  compare(a, b) {
    if (Number(a.storeNumber) < Number(b.storeNumber))
      return -1;
    if (Number(a.storeNumber) > Number(b.storeNumber))
      return 1;
    return 0;
  }

  onSubmitStoreGroup(){
    //Users with hasReadOnlyRestriction set to true should not even be allowed to invoke this action because it is hidden or disabled in the UI.
    //This is for extra protection. The back end will also be protected.
    if (this.myUser.hasReadOnlyRestriction){
      this._messageService.alertReadOnlyRestriction();
      return;
    }

    var storeGroup = this.storeGroupDetailForm.value;
    if (this.mode === 'New') {
      this._storeGroupService.createStoreGroup(storeGroup)
      .subscribe(
        (data) => {
          this.dialogRef.close('reload');
          this._messageService.onSuccess('Successfully created Store Group');
        },
        (response) => {
          this._messageService.onFailure('Failed to create Store Group.', response)
        }
      );
    }
    else {
      this._storeGroupService.updateStoreGroup(this.storeGroupId, storeGroup)
      .subscribe(
        (data) => {
          this.dialogRef.close('reload');
          this._messageService.onSuccess('Successfully updated Store Group');
        },
        (response) => {
          this._messageService.onFailure('Failed to update Store Group.', response)
        }
      );
    }
  }

  onOpenTransferDialog = new EventEmitter();

  onTransferButtonClick(){
    this.closeDialog();
    this.onOpenTransferDialog.emit(this.params);
  }

  onAssetCountEvent(val){
    this.assetCount = val;
  }

  closeDialog(){
    this.dialogRef.close(); //if true it will reload list page
  }


}
