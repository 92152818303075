<div class="detail admin-dialog">
  <h1 mat-dialog-title>{{mode}} Item Filter: {{itemFilterDetailForm.get('name').value}}</h1>
  <div mat-dialog-content>
    <form [formGroup]="itemFilterDetailForm" id="item-filter-detail-form" (ngSubmit)="onSubmitItemFilter()">
      <div class="checkbox-form-field">
        <mat-checkbox id="active" formControlName="active">Active</mat-checkbox>
      </div>
      <div class="row">
        <mat-form-field>
          <mat-label>Item Filter Name</mat-label>
          <input matInput id="name" formControlName="name" placeholder="Item Filter Name" required maxlength="30">
          <mat-error *ngIf="itemFilterDetailForm.hasError('required', 'name')">Item Filter Name is required</mat-error>
          <mat-hint align="end">{{itemFilterDetailForm.get('name').value.length}} / 30</mat-hint>
        </mat-form-field>
      </div>
      <div class="row">
        <mat-form-field>
          <mat-label>Description</mat-label>
          <input matInput id="description" formControlName="description" placeholder="Description">
        </mat-form-field>
      </div>
      <div class="row">
        <mat-form-field>
          <mat-label>Creator</mat-label>
          <input matInput id="creator" formControlName="creator" placeholder="Creator">
        </mat-form-field>
        <mat-form-field>
          <mat-label>Shared With</mat-label>
          <mat-select id="sharedWith" formControlName="sharedWith" placeholder="Shared With">
            <mat-option *ngFor="let swOption of sharedWithOptions" [value]="swOption">
              {{addSpacesBetweenWords(swOption)}}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="itemFilterDetailForm.hasError('required', 'sharedWith')">Shared With is required</mat-error>
        </mat-form-field>
        <mat-form-field>
          <mat-label>Modified By</mat-label>
          <mat-select id="modifiedBy" formControlName="modifiedBy" placeholder="Modified By">
            <mat-option *ngFor="let mbOption of modifiedByOptions" [value]="mbOption">
              {{addSpacesBetweenWords(mbOption)}}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="itemFilterDetailForm.hasError('required', 'mbOption')">Modified By is required</mat-error>
        </mat-form-field>
      </div>
      <div class="row">
        <mat-form-field>
          <mat-label>Filter Model</mat-label>
          <textarea matInput id="itemFilterMetadata" formControlName="itemFilterMetadata" placeholder="Filter Model" readonly></textarea>
        </mat-form-field>
      </div>
      <div class="detail-action-buttons">
        <button mat-raised-button color="primary" [disabled]="!itemFilterDetailForm.valid" type="submit">Save</button>
        <button mat-raised-button (click)="closeDialog()" type="button">Cancel</button>
      </div>
    </form>
  </div>
</div>
