import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { AgGridAngular, ICellRendererAngularComp } from 'ag-grid-angular';
import { GridOptions } from 'ag-grid-community';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from 'src/app/shared/confirmation-dialog/confirmation-dialog.component';
import { GridService } from 'src/app/shared/grid.service';
import { MessageService } from 'src/app/shared/message.service';
import { PosExportService } from 'src/app/shared/pos-export.service';
import { Router } from '@angular/router';
import { PermissionsService } from 'src/app/shared/permissions.service';
import { AppConfig } from 'src/app/app.config';

@Component({
  selector: 'app-admin-pos-export-list',
  templateUrl: './admin-pos-export-list.component.html',
  styleUrls: ['./admin-pos-export-list.component.css']
})
export class AdminPosExportListComponent implements OnInit {
  @ViewChild('agGrid') agGrid: AgGridAngular;

  posExportDialogRef: MatDialogRef<AdminPosExportDetailDialog>;
  cloneDialogRef: MatDialogRef<ClonePosSettingsDialog>;
  confirmationDialogRef: MatDialogRef<ConfirmationDialogComponent>;
  private config = AppConfig.settings;

  gridOptions: GridOptions;
  quickFilterText: string = '';
  context; //required by grid
  //frameworkComponents; //required by grid

  allSettingsForCloning = [];
  permissions: any = {};
  rowData = [];

  getColumnDefs(){
    return [
      {
        headerName: "",
        field: "action",
        width: 150,
        minWidth: 150,
        maxWidth: 180,
        cellClass: "action-col",
        cellRenderer: "PosActionButtonsComponent",
        pinned: "left",
        filter: false,
        sortable: false,
        suppressHeaderMenuButton: true,
        suppressNavigable: true,
      },
      {
        headerName: "Store #",
        field: "storeNumber",
        width: 100,
        cellClass: "storeNumber-col",
        filter: "agTextColumnFilter",
        filterParams: {
          defaultOption: "startsWith"
        },
        get headerTooltip() { return this.headerName; }
      },
      {
        headerName: "Store Location",
        field: "storeLocation",
        cellClass: "storeLocation-col",
        filter: "agTextColumnFilter",
        get headerTooltip() { return this.headerName; }
      },
      {
        headerName: "Delimiter",
        field: "delimiter",
        width: 60,
        cellClass: ["delimiter-col", "tac"],
        filter: "agTextColumnFilter",
        get headerTooltip() { return this.headerName; }
      },
      {
        headerName: "Qualify Text",
        field: "qualifyText",
        width: 60,
        cellClass: ["qualifyText-col", "tac"],
        cellRenderer: this._gridService.checkRenderer,
        filter: "agSetColumnFilter",
        filterParams: {
          cellRenderer: this._gridService.checkFilterRenderer,
          values: [true, false],
          newRowsAction: 'keep'
        },
        get headerTooltip() { return this.headerName; }
      },
      {
        headerName: "Text Qualifier",
        field: "textQualifier",
        width: 60,
        cellClass: ["textQualifier-col", "tac"],
        filter: "agTextColumnFilter",
        get headerTooltip() { return this.headerName; }
      },
      {
        headerName: "Include Header Row",
        field: "includeHeaderRow",
        width: 60,
        cellClass: ["includeHeaderRow-col", "tac"],
        cellRenderer: this._gridService.checkRenderer,
        filter: "agSetColumnFilter",
        filterParams: {
          cellRenderer: this._gridService.checkFilterRenderer,
          values: [true, false],
          newRowsAction: 'keep'
        },
        get headerTooltip() { return this.headerName; }
      },
      {
        headerName: "Export Boolean Values As",
        field: "exportBooleansAs",
        width: 100,
        cellClass: ["exportBooleansAs-col", "tac"],
        cellRenderer: this.exportBooleansAsRenderer,
        filter: "agSetColumnFilter",
        filterParams: {
          cellRenderer: this.exportBooleansFilterRenderer,
          values: [true, false],
          newRowsAction: 'keep'
        },
        get headerTooltip() { return this.headerName; }
      },
      {
        headerName: "Export Single Unit Multis (0 or 1) As",
        field: "singleUnitMultiFormat",
        width: 100,
        cellClass: ["singleUnitMultiFormat-col", "tac"],
        filter: "agSetColumnFilter",
        get headerTooltip() { return this.headerName; }
      },
      {
        headerName: "Created Date",
        field: "createdDate",
        cellClass: "createdDate-col",
        cellRenderer: this._gridService.dateTimeRenderer,
        filter: "agDateColumnFilter",
        filterParams: {
          comparator: this._gridService.dateComparator,
          inRangeInclusive: true
        },
        get headerTooltip() { return this.headerName; }
      },
      {
        headerName: "Modified Date",
        field: "modifiedDate",
        cellClass: "modifiedDate-col",
        cellRenderer: this._gridService.dateTimeRenderer,
        filter: "agDateColumnFilter",
        filterParams: {
          comparator: this._gridService.dateComparator,
          inRangeInclusive: true
        },
        get headerTooltip() { return this.headerName; }
      },

    ];
  }
  
  constructor(
    private _posExportService: PosExportService,
    private _gridService: GridService,
    private _dialog: MatDialog, 
    private _router: Router,
    private _messageService: MessageService,
    private _permissionsService: PermissionsService,
    ) {
      this.permissions = this._permissionsService.getPermissions();
      this.gridOptions = <GridOptions> {
			columnDefs: this.getColumnDefs(),
			rowData: this.rowData,
      rowHeight: 35,    
      components: {
        PosActionButtonsComponent: PosActionButtonsComponent,
      },
      defaultColDef: {
        sortable: true,
        resizable: true,
        floatingFilter: true,
      },
      statusBar: {
        statusPanels: [
          { statusPanel: 'agTotalAndFilteredRowCountComponent', align: 'left' },
        ],
      },

    }
    this.context = { componentParent: this }
   }

  ngOnInit() {
    if (this.config.logInits) console.log('pos init');
    this.getAllStorePosExportSettings();
  }

  getAllStorePosExportSettings(){
    this._posExportService.getAllStorePosExportSettings()
      .subscribe(
        (data) => {
          this.formatandSetRowData(data);
        },
        (response) => {
          this._messageService.onFailure('Failed to get POS Export Settings.', response);
        })
  }

  formatandSetRowData(data) {
    var gridData = [];
    for (var i=0; i<data.length; i++){
      var singleRowData = {};
      var actionFieldValue = '';
      singleRowData = {
        storePosExportSettingsId: data[i].storePosExportSettingsId,
        action: actionFieldValue,
        storeNumber: data[i].store.storeNumber,
        storeLocation: data[i].store.city + ', ' + data[i].store.state,
        storeNumberAndLocation: data[i].store.numberAndLocation,
        delimiter: data[i].delimiter,
        qualifyText: data[i].qualifyText,
        textQualifier: data[i].textQualifier,
        includeHeaderRow: data[i].includeHeaderRow,
        exportBooleansAs: data[i].booleanValuesAs0Or1,
        singleUnitMultiFormat: data[i].singleUnitMultiFormat,
        createdDate: data[i].created != null ? new Date(data[i].created) : '',
        modifiedDate: data[i].modified != null ? new Date(data[i].modified) : '',
      };
      gridData.push(singleRowData);
      var settings = {
        store: data[i].store,
        storePosExportSettingsId: data[i].storePosExportSettingsId,
      };
      this.allSettingsForCloning.push(settings);
    }
    this.rowData = gridData;
    this._gridService.onWindowResize('#ag-grid-wrapper');
    setTimeout(() => {
      this.fitColumns();
      this._gridService.sizeGrid('#ag-grid-wrapper');
    }, 100); 
  }

  exportBooleansAsRenderer(params) {
    var eCell;
    if (params.value != null) {
      eCell = document.createElement('span');
      if (params.value == true) {
        eCell.innerHTML = '1/0';
      }
      else {
        eCell.innerHTML = 'True/False';
      }
    }
    else {
      eCell = document.createElement('span');
      eCell.innerHTML = '';
    }
    return eCell;
  }

  exportBooleansFilterRenderer(params) {
    if (params.value != null) {
      var eCell = document.createElement('span');
      if (params.value == 'true') {
        eCell.innerHTML = '1/0';
      }
      else {
        eCell.innerHTML = 'True/False';
      }
    }
    return eCell;
  }

  clearFilters(){
    this.quickFilterText = '';
    this.agGrid.api.setGridOption('quickFilterText', this.quickFilterText);
    this.agGrid.api.setFilterModel(null);
    this.agGrid.api.onFilterChanged();
  }

  resetColumns(){
    this.agGrid.api.resetColumnState();
    this.fitColumns(); //since this is done by default also
  }

  autoSizeColumns(){
    var columns = this.agGrid.api.getColumns();
    this.agGrid.api.autoSizeColumns(columns);
  }

  fitColumns(){
    this.agGrid.api.sizeColumnsToFit();
  }

  onQuickFilterChange(){
    this.agGrid.api.setGridOption('quickFilterText', this.quickFilterText);
  }

  onDetailButtonClick(params){
    //this._router.navigate(['/admin/pos-export', params.data.storePosExportSettingsId]);
    this.posExportDialogRef = this._dialog.open(AdminPosExportDetailDialog, {
      width: '440px',
      //height: '600px',
      data: {
        id: params.data.storePosExportSettingsId
      }
    });
    this.posExportDialogRef.afterClosed().subscribe(result => {
      if(result === 'reload') {
        this.getAllStorePosExportSettings();
      }
      this.posExportDialogRef = null;
    });
  }

  onDeptAsscsButtonClick(params){
    this._router.navigate(['/admin/pos-export/department-associations/', params.data.storePosExportSettingsId]);
  }

  onCloneButtonClick(params){
    this.cloneDialogRef = this._dialog.open(ClonePosSettingsDialog, {
      width: '440px',
      //height: '600px',
      data: {
        sourceSettingsId: params.data.storePosExportSettingsId,
        sourceStoreNumberAndLocation: params.data.storeNumberAndLocation,
        allSettingsForCloning: this.allSettingsForCloning,
      }
    });
    this.cloneDialogRef.afterClosed().subscribe(result => {
      if(result === 'reload') {
        this.getAllStorePosExportSettings();
      }
      this.cloneDialogRef = null;
    });
  }

}

@Component({
  selector: 'rpms-admin-detail-dialog',
  template: `<rpms-admin-pos-export-detail [id]="data.id"></rpms-admin-pos-export-detail>`,
})
export class AdminPosExportDetailDialog {

  constructor(
    public dialogRef: MatDialogRef<AdminPosExportDetailDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any) {}

}

@Component({
  selector: 'rpms-pos-clone-dialog',
  template: `<rpms-pos-export-clone [data]="data"></rpms-pos-export-clone>`,
})
export class ClonePosSettingsDialog {

  constructor(
    public dialogRef: MatDialogRef<ClonePosSettingsDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any) {}

}

@Component({
  selector: 'rpms-pos-export-action-buttons',
  template: `<span>
              <button *ngIf="permissions.roleAllowsUserToEditPosExportSettings" matTooltip="View POS Settings" matTooltipShowDelay="500" (click)="viewDetail()" class="btn btn-info"><i class="far fa-eye"></i></button>
              <button *ngIf="permissions.roleAllowsUserToEditPosExportDeptAsscs" matTooltip="View Department Associations" matTooltipShowDelay="500" (click)="viewDeptAsscs()" class="btn btn-info"><i class="fas fa-list-ol"></i></button>
              <button *ngIf="permissions.roleAllowsUserToClonePosExportSettingsAndDeptAsscs" matTooltip="Clone POS Settings" matTooltipShowDelay="500" (click)="cloneSettings()" class="btn btn-info"><i class="far fa-clone"></i></button>
            </span>`,
  styles: []
})
export class PosActionButtonsComponent implements ICellRendererAngularComp  {

  public params: any;
  permissions: any = {};


  agInit(params: any): void {
      this.params = params;
      this.permissions = params.context.componentParent.permissions;
  }

  public viewDetail() {
      this.params.context.componentParent.onDetailButtonClick(this.params);
  }

  public viewDeptAsscs() {
    this.params.context.componentParent.onDeptAsscsButtonClick(this.params);
  }

  public cloneSettings() {
    this.params.context.componentParent.onCloneButtonClick(this.params);
  }

  refresh(): boolean {
      return false;
  }

}

