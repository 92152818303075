<div id="ad-week-grid-and-controls">
    <h1><i class="far fa-newspaper fa-lg"></i>Ad Week</h1>
    <div id="ad-week-top-controls">
      <div style="display: flex;">
        <div class="mat-select-override">
            <label>Store:</label>
            <!-- <mat-form-field> -->
                <mat-select id="adweek-store-select" name="store" #storeSelect="ngModel" [(ngModel)]="store" placeholder="Select a Store" [compareWith]="compareStoreObjects" (selectionChange)="onStoreSelected()">
                    <mat-option *ngFor="let store of stores" [value]="store">
                        {{store.numberAndLocation}}
                    </mat-option>
                </mat-select>
            <!-- </mat-form-field> -->
        </div>
        <div class="mat-select-override" *ngIf="isStoreSelected">
            <label>Week:</label>
            <!-- <mat-form-field> -->
                <mat-select id="adweek-week-select" name="week" #weekSelect="ngModel" [(ngModel)]="week" placeholder="Select a Week" [compareWith]="compareWeekObjects" (selectionChange)="onWeekSelected()">
                    <mat-option *ngFor="let week of weeks" [value]="week">
                        {{week.label}}
                    </mat-option>
                </mat-select>
            <!-- </mat-form-field> -->
        </div>
      </div>
      <div id="pm-filter-tools" [hidden]="!isDataLoaded">
          <input id="pm-quick-filter" class="quick-filter" placeholder="Quick Filter..." type="text" [(ngModel)]="quickFilterText" (keyup)="onQuickFilterChange()" />
          <button id="clear-filters-button" (click)="clearFilters()" mat-stroked-button>Clear Filters</button>
          <button id="reset-columns-button" style="margin-right:0;" mat-stroked-button class="help-trigger no-text" data-help="reset-button" (click)="resetColumns()" title="Reset Columns"><i class="far fa-columns"></i></button>
      </div>
      <button *ngIf="isDataLoaded" mat-stroked-button id="export-button" class="help-trigger" data-help="export" (click)="onExport()"><i class="fa fa-download"></i>Download Excel</button>
      <div id="grid-controls">
      </div>
    </div>
    
    <div class="grid-tabs" [hidden]="!isDataLoaded">
        <a id="AllItems-tab" class="tab" (click)="onTabClick('AllItems')" [ngClass]="{active: activeTab == 'AllItems'}">All Depts</a>
        <a id="Grocery-tab" class="tab" class="active" (click)="onTabClick('Grocery')" [ngClass]="{active: activeTab == 'Grocery'}">Grocery</a>
        <a id="Meat-tab" class="tab" (click)="onTabClick('Meat')" [ngClass]="{active: activeTab == 'Meat'}">Meat</a>
        <a id="Produce-tab" class="tab" (click)="onTabClick('Produce')" [ngClass]="{active: activeTab == 'Produce'}">Produce</a>
        <a id="DeliBakery-tab" class="tab" (click)="onTabClick('DeliBakery')" [ngClass]="{active: activeTab == 'DeliBakery'}">Deli/Bakery</a>
        <a id="Dairy-tab" class="tab" (click)="onTabClick('Dairy')" [ngClass]="{active: activeTab == 'Dairy'}">Dairy</a>
        <a id="HBA-tab" class="tab" (click)="onTabClick('HBA')" [ngClass]="{active: activeTab == 'HBA'}">HBA</a>
        <a id="FrozenFood-tab" class="tab" (click)="onTabClick('FrozenFood')" [ngClass]="{active: activeTab == 'FrozenFood'}">Frozen Food</a>
    </div>
    <div id="ad-week-grid-wrapper" [hidden]="!isDataLoaded">
        <ag-grid-angular #agGrid
        [gridOptions]="gridOptions"
        style="width: 100%; height: 1px;" 
        class="ag-theme-balham"
        id="ad-week-ag-grid-wrapper"
        [rowData]="rowData" 
        [columnDefs]="columnDefs"
        [excelStyles]="excelStyles"
        [context]="context"
        [quickFilterText]="quickFilterText"
        [statusBar]="statusBar"
        (modelUpdated)="onModelUpdated()"
        >
        </ag-grid-angular>
    </div>   

    <div class="row-count" *ngIf="isDataLoaded">{{rowCount}} Rows</div>

</div>