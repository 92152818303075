import { Component, OnInit } from '@angular/core';
//import { AppConfig } from 'src/app/app.config';
import { IdleService } from 'src/app/core/authentication/shared/idle.service';
//import { environment } from 'src/environments/environment';

@Component({
  selector: 'rpms-idle-dialog',
  templateUrl: './idle-dialog.component.html',
  styleUrls: ['./idle-dialog.component.css']
})
export class IdleDialogComponent implements OnInit {
  //private config = AppConfig.settings;

  isIdleSubscription;
  isIdle: boolean = false;
  idleStateSubscription;
  idleState = '';
  isTimedOutSubscription;
  isTimedOut: boolean = false;
  countdownSubscription;
  countdown;

  constructor(
    private _idleService: IdleService,

  ) { }

  ngOnInit() {
    //if (this.config.logInits) console.log('idle dialog init');
    this.isIdleSubscription = this._idleService.isIdle$
    .subscribe(
      (data) => {
        this.isIdle = data;
      }
    );

    this.idleStateSubscription = this._idleService.idleState$
    .subscribe(
      (data) => {
        this.idleState = data;
      }
    );

    this.isTimedOutSubscription = this._idleService.isTimedOut$
    .subscribe(
      (data) => {
        this.isTimedOut = data;
      }
    );

    this.countdownSubscription = this._idleService.countdown$
    .subscribe(
      (data) => {
        this.countdown = data;
      }
    );

  }

  ngOnDestroy(){
    this.isIdleSubscription.unsubscribe();
    this.idleStateSubscription.unsubscribe();
    this.isTimedOutSubscription.unsubscribe();
    this.countdownSubscription.unsubscribe();
  }

}
