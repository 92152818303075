import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'app-view-controlled-stores-pricing-button',
  template: `<span><button style="height: 20px" (click)="invokeParentMethod()" class="btn btn-info" title="View Controlled Stores Pricing"><i class="fas fa-sitemap"></i></button></span>`,
  styles: [
    `.btn {
           line-height: 0.5;
           background: white !important;
           color: #33a8f4 !important;
           margin-right: 0 !important;
         }`
  ]
})
export class ViewControlledStorePricingButtonComponent implements ICellRendererAngularComp {

  public params: any;

  agInit(params: any): void {
      this.params = params;
  }

  public invokeParentMethod() {
      this.params.context.componentParent.onViewControlledStoresPricingButtonClick(this.params);
  }

  refresh(): boolean {
      return false;
  }

}
