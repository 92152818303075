<h3 mat-dialog-title>Prioritize Pricing Rules</h3>
<mat-dialog-content class="dialog-content">
  <p>Use the mouse to drag and drop the rules below to set their priority order.</p>
  <div class="sort-scroller">
    <div cdkDropList class="example-list" (cdkDropListDropped)="drop($event)">
        <div class="example-box" *ngFor="let pricingRule of prioritizedPricingRules; let i = index;" cdkDrag><span class="priority">{{i + 1}}</span> {{pricingRule.name}}</div>
    </div>
  </div>

  <div class="dialog-actions">
    <button mat-raised-button color="primary" (click)="savePriorityOrder()" [disabled]="myUser.hasReadOnlyRestriction">Save Priority Order</button>
    <button mat-raised-button (click)="cancel()">Cancel</button>
  </div>
</mat-dialog-content>

