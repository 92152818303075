import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'app-admin-action-buttons',
  template: `<span>
    <button *ngIf="params.data?.permissions?.roleAllowsUserToViewAssets" (click)="invokeAssetParentMethod()" class="btn btn-assets" title="View Assets"><i class="fas fa-project-diagram fa-flip-vertical"></i></button>
    <button *ngIf="params.data?.permissions?.roleAllowsUserToTransferAssets" (click)="invokeTransferParentMethod()" class="btn btn-transfer" title="Transfer Assets"><i class="fas fa-share"></i></button>
    <button *ngIf="params.data?.permissions?.roleAllowsUserToDelete" [disabled]="params.data?.permissions?.userHasReadOnlyRestriction" (click)="invokeDeleteParentMethod()" class="btn btn-delete" title="Delete"><i class="fas fa-times"></i></button>
  </span>`,
  styles: [
    `
         .btn {
           line-height: 0.5 !important;
           color:#fff !important;
         }
         .btn.btn-assets {
           background: purple !important;
         }
         .btn.btn-assets i {
           position: relative;
           left: -1px;
           top: -1px;
         }
         .btn.btn-transfer {
           background: orange !important;
         }
         .btn.btn-delete {
           background: red !important;
         }
         .btn:disabled {
           background: #ccc !important;
         }
         `
  ]
})
export class AdminActionButtonsComponent implements ICellRendererAngularComp {

  public params: any;

  agInit(params: any): void {
      this.params = params;
  }

  public invokeAssetParentMethod() {
      this.params.context.componentParent.onAssetButtonClick(this.params);
  }

  public invokeTransferParentMethod() {
      this.params.context.componentParent.onTransferButtonClick(this.params);
  }

  public invokeDeleteParentMethod() {
      this.params.context.componentParent.onDeleteButtonClick(this.params);
  }

  refresh(): boolean {
      return false;
  }
}
