<h1 md-dialog-title class="dialog-title">Client Error</h1>
<mat-dialog-content class="dialog-content">
  <span [innerHtml]="htmlContent | sanitizeHtml"></span>
</mat-dialog-content>
<mat-dialog-actions class="dialog-actions">
  <!-- <button mat-stroked-button color="primary" (click)="dialogRef.close(true)">Logout</button> -->
  <button mat-stroked-button color="primary" (click)="closeDialog()">Continue Working</button>
  <button mat-raised-button color="primary" (click)="logout()">Log Out</button>
  <div *ngIf="isLoggingOut" style="margin-top: 20px;"><i class="fas fa-spinner fa-spin"></i> Logging Out...</div>
</mat-dialog-actions>
