<button class="mb" mat-raised-button (click)="goBack()" type="button"><i class="fas fa-chevron-left"></i>Go Back to POS Export List</button>
<h1>POS Export Department Associations for Store {{store.numberAndLocation}}</h1>
<div id="upload-dept-associations">
    <!-- <div id="dept-association-help">
        <p>A CSV file can be uploaded to associate Order Header numbers and Department Numbers. The format for the CSV data should be the Order Header number followed by a comma, followed by the Department number, followed by an end-of-line character.</p>
        <p><em>Example:</em></p>
        <p>3,30<br />10,50<br />12,20</p>
    </div> -->
    <mat-checkbox [(ngModel)]="doesIncludeHeaderRow" (change)="onDoesIncludeHeaderRowChange()" [disabled]="myUser.hasReadOnlyRestriction">File Includes Header Row</mat-checkbox>
    <div class="spaced-row rel">
        <input id="file-uploader" type="file" ng2FileSelect [uploader]="uploader" [disabled]="myUser.hasReadOnlyRestriction"/><i class="fa fa-question-circle fa-lg upload-help" aria-hidden="true" (mouseover)="isHelpVisible = true" (mouseleave)="isHelpVisible = false"></i>
        <button id="remove-dept-asscs" mat-raised-button (click)="onDeleteButtonClick()" [disabled]="myUser.hasReadOnlyRestriction"><i class="fas fa-minus-circle"></i>Remove All Dept Associations</button>
    </div>
    <!-- <ul>
        <li ng-repeat="item in uploader.queue">
            <div>Name: <span ng-bind="item.file.name"></span><span><i class="fa fa-times fa-lg remove-item" aria-hidden="true"></i></span></div>
            <!-- <md-button ng-click="item.upload()" class="md-primary md-raised item-upload-button">Upload</md-button> 
            <button mat-raised-button color="primary" (click)="upload()">Save</button>
        </li>
    </ul> -->
    <button id="select-file-button" mat-raised-button [disabled]="myUser.hasReadOnlyRestriction"><i class="fas fa-upload"></i> Select CSV File to Upload</button>
    <div *ngFor="let item of uploader.queue" class="queue-item">{{ item?.file?.name }}
        <button mat-raised-button (click)="item.remove()"><i class="fas fa-file-times"></i> Remove</button>
        <button mat-raised-button color="primary" (click)="uploader.uploadAll()" [disabled]="!uploader.getNotUploadedItems().length">
            <i class="fas fa-file-upload"></i> Upload
        </button>
    </div>
    <div id="dept-association-help" *ngIf="isHelpVisible">
        <p>A CSV file can be uploaded to associate Order Header numbers and Department Numbers. The format for the CSV data should be the Order Header number followed by a comma, followed by the Department number, followed by an end-of-line character.</p>
        <p><em>Example:</em></p>
        <p>3,30<br />10,50<br />12,20</p>
    </div>
</div>
<div class="table-toolbar">
    <div class="filter-tools">
        <button (click)="clearFilters()" mat-raised-button>Clear Filters</button>
        <input id="quick-filter" class="quick-filter" placeholder="Quick Filter..." type="text" [(ngModel)]="quickFilterText" (keyup)="onQuickFilterChange()" />
    </div>
    <div class="column-tools">
        Columns: &nbsp;
        <button (click)="resetColumns()" mat-raised-button>Reset Defaults</button>
        <button (click)="fitColumns()" mat-raised-button>Fit to Width</button>
        <button (click)="autoSizeColumns()" mat-raised-button>Autosize</button>
    </div>
</div>
<ag-grid-angular #agGrid
    [gridOptions]="gridOptions"
    style="width: 100%; height: 1px;" 
    class="ag-theme-balham"
    id="ag-grid-wrapper"
    [rowData]="rowData" 
    [context]="context"
    [quickFilterText]="quickFilterText"
    >
</ag-grid-angular>