import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild, ɵɵsetComponentScope } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatAccordion } from '@angular/material/expansion';
import { Subscription } from 'rxjs';
import { AuthenticationService } from 'src/app/core/authentication/shared/authentication.service';
import { AccessService } from 'src/app/shared/access.service';
import { ConfirmationDialogComponent } from 'src/app/shared/confirmation-dialog/confirmation-dialog.component';
import { DeletionService } from 'src/app/shared/deletion.service';
import { PermissionsService } from 'src/app/shared/permissions.service';
import { StoreGroupService } from 'src/app/shared/store-group.service';
import { MessageService } from '../../../shared/message.service';
import { PricingRulesService } from '../../../shared/pricing-rules.service';
import { UserService } from '../../../shared/user.service';

@Component({
  selector: 'app-store-group-owned-assets',
  templateUrl: './store-group-owned-assets.component.html',
  styleUrls: ['./store-group-owned-assets.component.css']
})
export class StoreGroupOwnedAssetsComponent implements OnInit {
  @ViewChild(MatAccordion) accordion: MatAccordion;
  @Input() storeGroupId: any;
  @Input() showTransferButton: boolean;
  @Input() showDelete: boolean;
  @Input() transferOccurred: boolean;
  @Output() openTransferDialogEvent = new EventEmitter<any>();
  @Output() assetCountEvent = new EventEmitter<any>();
  @Output() deletionOccurredEvent = new EventEmitter<any>();

  confirmationDialogRef: MatDialogRef<ConfirmationDialogComponent>;
  
  assetCount;
  usersDescription;
  pricingRulesDescription;
  superStoreGroupAdminsDescription;
  managedStoresDescription;
  users = [];
  pricingRules = [];
  superStoreGroupAdmins = [];
  managedStores = [];
  managedStoresHash = [];
  selectedUserIds;
  selectedPricingRuleIds;
  selectedSuperStoreGroupAdminIds;
  selectedManagedStoreIds;

  usersPanelOpenState: boolean = false;
  pricingRulesPanelOpenState: boolean = false;
  superStoreGroupAdminsPanelOpenState: boolean = false;
  managedStoresPanelOpenState: boolean = false;

  userForm: UntypedFormGroup;
  pricingRuleForm: UntypedFormGroup;
  superStoreGroupAdminForm: UntypedFormGroup;
  managedStoreForm: UntypedFormGroup;

  permissions: any = {};
  myUser;
  userHasDeletionPrivilege: boolean = false;
  
  get usersFormArray() {
    return this.userForm.controls.users as UntypedFormArray;
  }

  get pricingRulesFormArray() {
    return this.pricingRuleForm.controls.pricingRules as UntypedFormArray;
  }

  get superStoreGroupAdminsFormArray() {
    return this.superStoreGroupAdminForm.controls.superStoreGroupAdmins as UntypedFormArray;
  }

  get managedStoresFormArray() {
    return this.managedStoreForm.controls.managedStores as UntypedFormArray;
  }
  
  constructor(
    private _fb: UntypedFormBuilder,
    private _userService: UserService,
    private _pricingRulesService: PricingRulesService,
    private _storeGroupService: StoreGroupService,
    private _deletionService: DeletionService,
    private _accessService: AccessService,
    private _permissionsService: PermissionsService,
    private _authenticationService: AuthenticationService,
    private _messageService: MessageService,
    private _dialog: MatDialog,
  ) { 
    this.userForm = this._fb.group({
      users: new UntypedFormArray([])
    });

    this.pricingRuleForm = this._fb.group({
      pricingRules: new UntypedFormArray([])
    });

    this.superStoreGroupAdminForm = this._fb.group({
      superStoreGroupAdmins: new UntypedFormArray([])
    });

    this.managedStoreForm = this._fb.group({
      managedStores: new UntypedFormArray([])
    });

    this.permissions = this._permissionsService.getPermissions();
    this.myUser = this._authenticationService.getMyUser();
    if (this.myUser.hasDeletionPrivilege) this.userHasDeletionPrivilege = true;
  }

  addUserCheckboxesToForm(arr) {
    arr.forEach(() => this.usersFormArray.push(new UntypedFormControl(false)));
  }

  addPricingRuleCheckboxesToForm(arr) {
    arr.forEach(() => this.pricingRulesFormArray.push(new UntypedFormControl(false)));
  }

  addSuperStoreGroupAdminCheckboxesToForm(arr) {
    arr.forEach(() => this.superStoreGroupAdminsFormArray.push(new UntypedFormControl(false)));
  }

  addManagedStoreCheckboxesToForm(arr) {
    arr.forEach(() => this.managedStoresFormArray.push(new UntypedFormControl(false)));
  }
  
  ngOnInit(): void {
    this.doOnInit();
  }

  doOnInit(){
    this.userForm = this._fb.group({
      users: new UntypedFormArray([])
    });

    this.pricingRuleForm = this._fb.group({
      pricingRules: new UntypedFormArray([])
    });

    this.superStoreGroupAdminForm = this._fb.group({
      superStoreGroupAdmins: new UntypedFormArray([])
    });

    this.managedStoreForm = this._fb.group({
      managedStores: new UntypedFormArray([])
    });

    this.getAllManageableUsersByStoreGroup(this.storeGroupId);
  }
 
  ngOnChanges(changes) {
    //transferOccurred change will cause a reload of this component
    if (changes && changes?.transferOccurred?.currentValue) this.doOnInit();
    //console.log(changes);
  }

  onTransferButtonClick(){
    this.openTransferDialogEvent.next(null);
  }

  getAllManageableUsersByStoreGroup(storeGroupId){
    this._userService.getAllManageableUsersByStoreGroup(storeGroupId)
      .subscribe(
        (data:any) => {
          this.users = data;
          switch(data.length){
            case 1: this.usersDescription = '1 User is assigned to this store group';
              break;
            default: this.usersDescription = data.length + ' Users are assigned to this store group';
              break
          }
        
          this.addUserCheckboxesToForm(this.users);
          this.getAllPricingRulesByStoreGroupIdSansMatchCriteria(this.storeGroupId);
        },
        (response) => {
          this._messageService.onFailure('Failed to get Users in Store Group.', response);
        })
  }

  getAllPricingRulesByStoreGroupIdSansMatchCriteria(storeGroupId){
    this._pricingRulesService.getAllPricingRulesByStoreGroupIdSansMatchCriteria(storeGroupId)
      .subscribe(
        (data:any) => {
          this.pricingRules = data;
          switch(data.length){
            case 1: this.pricingRulesDescription = '1 Pricing Rule is owned by this store group';
              break;
            default: this.pricingRulesDescription = data.length + ' Pricing Rules are owned by this store group';
              break
          }
          this.addPricingRuleCheckboxesToForm(this.pricingRules);
          this.getSuperStoreGroupAdministratorsManagingStoreGroup(this.storeGroupId)
        },
        (response) => {
          this._messageService.onFailure('Failed to get Pricing Rules for Store Group.', response);
        })
  }

  getSuperStoreGroupAdministratorsManagingStoreGroup(storeGroupId){
    this._userService.getSuperStoreGroupAdministratorsManagingStoreGroup(storeGroupId)
      .subscribe(
        (data:any) => {
          this.superStoreGroupAdmins = data;
          switch(data.length){
            case 1: this.superStoreGroupAdminsDescription = '1 Super Store Group Admin manages this store group';
              break;
            default: this.superStoreGroupAdminsDescription = data.length + ' Super Store Group Admins manage this store group';
              break
          }
          this.addSuperStoreGroupAdminCheckboxesToForm(this.superStoreGroupAdmins);
          this.getStoreGroupWithStoresById(this.storeGroupId);
        },
        (response) => {
          this._messageService.onFailure('Failed to get Super Store Group Admininstrators.', response);
        })
  }

  getStoreGroupWithStoresById(storeGroupId){
    this._storeGroupService.getStoreGroupWithStoresById(storeGroupId)
      .subscribe(
        (data:any) => {
          this.managedStores = data.stores;
          for (let i=0; i<data.stores.length; i++) {
            this.managedStoresHash[data.stores[i].storeId] = data.stores[i];
          }
          switch(data.length){
            case 1: this.managedStoresDescription = '1 Store is a member of this store group';
              break;
            default: this.managedStoresDescription = data.stores.length + ' Stores are members of this store group';
              break
          }
          this.addManagedStoreCheckboxesToForm(this.managedStores);
          this.assetCount = this.users.length + this.pricingRules.length + this.superStoreGroupAdmins.length + this.managedStores.length;
          this.assetCountEvent.next(this.assetCount);
          this.getStoreNumberAndLocationForPricingRules();
        },
        (response) => {
          this._messageService.onFailure('Failed to get Member Stores.', response);
        })
  }

  getStoreNumberAndLocationForPricingRules() {
    this.pricingRules.map((p) => { p.storeNumber = this.managedStoresHash[p.storeId]?.storeNumber});
    console.log(this.pricingRules)
  }

  selectAllUsers(bool){
    this.usersFormArray.controls.map(control=>{
      control.setValue(bool);
    });
  }

  onUserDeleteClick(){
    if (this.myUser.hasDeletionPrivilege){
      this.selectedUserIds = this.userForm.value.users
        .map((checked, i) => checked ? this.users[i].userId : null)
        .filter(v => v !== null);
      //console.log(this.selectedUserIds);
      if (this.selectedUserIds.length > 0) this.confirmDeletion('Users', this.selectedUserIds, false);
      else this._messageService.alert('No users are selected. Please select at least one user and try again.');
    }
    else {
      this._messageService.alert('You are not authorized to delete users. If you believe you should have that privilege, contact an administrator.');
    }
  }

  selectAllPricingRules(bool){
    this.pricingRulesFormArray.controls.map(control=>{
      control.setValue(bool);
    });
  }

  onPricingRuleDeleteClick(){
    if (this.myUser.hasDeletionPrivilege){
      this.selectedPricingRuleIds = this.pricingRuleForm.value.pricingRules
        .map((checked, i) => checked ? this.pricingRules[i].pricingRuleId : null)
        .filter(v => v !== null);
      //console.log(this.selectedPricingRuleIds);
      if (this.selectedPricingRuleIds.length > 0) this.confirmDeletion('Pricing Rules', this.selectedPricingRuleIds, false);
      else this._messageService.alert('No pricing rules are selected. Please select at least one pricing rule and try again.');
    }
    else {
      this._messageService.alert('You are not authorized to delete pricing rules. If you believe you should have that privilege, contact an administrator.');
    }
  }

  selectAllSuperStoreGroupAdmins(bool){
    this.superStoreGroupAdminsFormArray.controls.map(control=>{
      control.setValue(bool);
    });
  }

  onSuperStoreGroupAdminDeleteClick(){
    if (this.myUser.hasDeletionPrivilege){
      this.selectedSuperStoreGroupAdminIds = this.superStoreGroupAdminForm.value.superStoreGroupAdmins
        .map((checked, i) => checked ? this.superStoreGroupAdmins[i].userId : null)
        .filter(v => v !== null);
      //console.log(this.selectedSuperStoreGroupAdminIds);
      if (this.selectedSuperStoreGroupAdminIds.length > 0) this.confirmDeletion('Super Store Group Administrators', this.selectedSuperStoreGroupAdminIds, false);
      else this._messageService.alert('No super store group admins are selected. Please select at least one super store group admin and try again.');
    }
    else {
      this._messageService.alert('You are not authorized to delete super store group administrators. If you believe you should have that privilege, contact an administrator.');
    }
  }

  selectAllManagedStores(bool){
    this.managedStoresFormArray.controls.map(control=>{
      control.setValue(bool);
    });
  }

  onManagedStoreDeleteClick(){
    if (this.myUser.hasDeletionPrivilege){
      this.selectedManagedStoreIds = this.managedStoreForm.value.managedStores
        .map((checked, i) => checked ? this.managedStores[i].storeId : null)
        .filter(v => v !== null);
      //console.log(this.selectedManagedStoreIds);
      if (this.selectedManagedStoreIds.length > 0) this.confirmDeletion('Managed Stores (with Assets)', this.selectedManagedStoreIds, false);
      else this._messageService.alert('No managed stores are selected. Please select at least one managed store and try again.');
    }
    else {
      this._messageService.alert('You are not authorized to delete stores. If you believe you should have that privilege, contact an administrator.');
    }
  }

  confirmDeletion(assetType, selectedIds, forceDeletion){
    this.confirmationDialogRef = this._dialog.open(ConfirmationDialogComponent, {
      disableClose: false,
      //width: '600px',
      maxHeight: '80vh',
      data: {
        title: 'Are you sure?',
        message: `Do you want to perform this action on the selected assets of type: ${assetType}?`,
        confirmText: 'Yes, proceed with this action',
        cancelText: 'No, cancel this action!',
        thirdButtonText: undefined,
      }
    });
    this.confirmationDialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.deleteAssets(assetType, selectedIds, false);
      }
      this.confirmationDialogRef = null;
    });
  }

  confirmLongDeletion(assetType, selectedIds, reason){
    if (reason) reason = reason.replace(/\r\n/g, '<br/>')
      .replace(/\u00b9/g, '<span class="indent-1"></span>')
      .replace(/\u00b2/g, '<span class="indent-2"></span>')
      .replace(/\u00b3/g, '<span class="indent-3"></span>')
      .replace(/\u2074/g, '<span class="indent-4"></span>')
      .replace(/\u2075/g, '<span class="indent-5"></span>')
      .replace(/\u2076/g, '<span class="indent-6"></span>')
      .replace(/\u2077/g, '<span class="indent-7"></span>')
      .replace(/\u2078/g, '<span class="indent-8"></span>');
    this.confirmationDialogRef = this._dialog.open(ConfirmationDialogComponent, {
      disableClose: false,
      //width: '600px',
      maxHeight: '80vh',
      data: {
        title: 'Are you sure?',
        message: reason,
        confirmText: 'Yes, proceed with this action',
        cancelText: 'No, cancel this action!',
        thirdButtonText: undefined,
      }
    });
    this.confirmationDialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.deleteAssets(assetType, selectedIds, true);
      }
      this.confirmationDialogRef = null;
    });
  }

  deleteAssets(assetType, selectedIds, forceDeletion){
    //Users with hasReadOnlyRestriction set to true should not even be allowed to invoke this action because it is hidden or disabled in the UI.
    //This is for extra protection. The back end will also be protected.
    if (this.myUser.hasReadOnlyRestriction){
      this._messageService.alertReadOnlyRestriction();
      return;
    }

    this._deletionService.deleteSelectAssetsFromEntity(this.storeGroupId, 'Store Group', assetType, forceDeletion, selectedIds)
      .subscribe(
        (data:any) => {
          this._messageService.onSuccess('Successfully performed action on selected assets');
          this.deletionOccurredEvent.next(true);
          this.doOnInit();
        },
        (response) => {
          let reason = response.error.modelState ? response.error?.modelState[''].join(' ') : undefined;
          if (reason) this.confirmLongDeletion(assetType, selectedIds, reason);
          else this._messageService.onFailure('Failed to perform action on selected assets.', response);
        })
  }

  addSpacesBetweenWords(v) {
    if (v != null && v != '' && v != undefined) {
      var valueNoSpaces = v.replace(/\s/g, '');//first remove all spaces if any
      var valueWithSpaces = valueNoSpaces.replace(/([A-Z])/g, ' $1');
      valueWithSpaces = valueWithSpaces.substr(1); //remove space before first word
      return valueWithSpaces;
    }
  }

}
