import { Component } from '@angular/core';
import { AuthenticationService } from '../shared/authentication.service';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { PingerService } from '../shared/pinger.service';
import { MessageService } from 'src/app/shared/message.service';
import { UserService } from 'src/app/shared/user.service';
import { PermissionsService } from 'src/app/shared/permissions.service';
import { SignalRService } from 'src/app/shared/signal-r.service';
import { IdleService } from '../shared/idle.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent{

  hide = true;
  isLoginError: boolean = false;
  isLoggedIn: boolean = false;
  isOfflineLinkVisible;

  loginForm = this._fb.group({
    userName: ['', Validators.required],
    password: ['', Validators.required],
  });

  constructor(private _authenticationService : AuthenticationService,
    private _permissionsService: PermissionsService,
    private _userService: UserService,
    private _router : Router, 
    private _fb: UntypedFormBuilder,
    private _pingerService: PingerService,
    private _idleService: IdleService,
    private _messageService: MessageService,
    private _signalRService: SignalRService,
  ) { 
  }

  ngOnInit(){
    //this.getStoreDataFromIndexedDb();
    this.createIndexedDbIfMissing();
  }

  ngAfterContentInit(){
    this.isLoggedIn = false;
  }

  onSubmit(){
    this._authenticationService.login(this.loginForm.value)
      .subscribe(
        (data : any)=>{
          this.isLoggedIn = true;
          this._authenticationService.setToken(data.access_token);
          this._authenticationService.setIsUserLoggedIn(true);
          //set unique string on window.name to keep user from using multiple tabs
          //the check for this is in auth.guard
          var id = new Date().getTime().toString();
          window.name = id;
          //don't store it in localStorage if logging in and someone is already logged in on another tab
          var sessionId = localStorage.getItem('rpmsSessionId');
          if (!sessionId) localStorage.setItem('rpmsSessionId', id);   

          this.getMyUserFromServer();
        },
        (response : HttpErrorResponse)=>{
          this.isLoginError = true;
          this._messageService.onFailure('Login failed.', response);
        });
  }

  getMyUserFromServer(){
    this._userService.getMyUser()
      .subscribe(
        (data : any)=>{
          if (data.roles[0] === 'Mainframe' || data.roles[0] === 'Automation'){
            this._messageService.alert('Users in a Mainframe or Automation role do not have access to this user interface.');
            this._authenticationService.logout();
          }
          else{
            this._authenticationService.setMyUser(data);
            this._permissionsService.initRolePermissions(data.roles[0]);
            this._idleService.resetIdleWatch();
            this._pingerService.startPingerTimer();
            this._signalRService.loadSignalRHubs();
            this._router.navigate(['/home']);
          }
        },
        (response : HttpErrorResponse)=>{
          this._messageService.onFailure('Failed to get user.', response);
        });
  }

  createIndexedDbIfMissing() {
    var self = this;
    var db;
    var stores = [];
    var storeIdsToDelete = [];
    var requestOpen = window.indexedDB.open("RPMS2", 3);

    requestOpen.onerror = function (event) {
      console.warn("Failed to open offline database.");
    };

    requestOpen.onsuccess = function (event:any) {
      db = event.target.result;

      var transaction = db.transaction(["pricingData"], "readwrite");
      var objectStore = transaction.objectStore("pricingData");

      ///////////////////  Can remove this code in June 2019.  This is to remove IDB store pricing data in the old key format  (i.e. 16, not 16-1 and 16-2)

      objectStore.openCursor().onsuccess = function (event) {
        var cursor = event.target.result;

        if (cursor) {
          if (!cursor.value.key || String(cursor.value.key).indexOf('-') == -1) {
            //delete this one
            storeIdsToDelete.push(cursor.value.storeId);
            var request = cursor.delete();
          }
          cursor.continue();
        }
        else {
          // no more entries
          transaction = db.transaction(["storeData"], "readwrite");
          objectStore = transaction.objectStore("storeData");

          objectStore.openCursor().onsuccess = function (event) {
            var cursor = event.target.result;

            if (cursor) {
              if (storeIdsToDelete.indexOf(cursor.value.storeId) > -1) {
                var request = cursor.delete();
              }
              else {
                stores.push(cursor.value.storeId);
              }
              cursor.continue();
            }
            else {
              //no more storeData entries
            }
          };
        }
      };

    };

    requestOpen.onupgradeneeded = function (event:any) {
      //console.log(event);
      var newVersion = event.target.result;
      //remove all object stores
      if (newVersion.objectStoreNames.contains("pricingData")) newVersion.deleteObjectStore("pricingData");
      if (newVersion.objectStoreNames.contains("storeData")) newVersion.deleteObjectStore("storeData");
      //if (newVersion.objectStoreNames.contains("columnMetadata")) newVersion.deleteObjectStore("columnMetadata");

      //then add all object stores
      if (!newVersion.objectStoreNames.contains("pricingData")) newVersion.createObjectStore("pricingData", { keyPath: "key" });
      if (!newVersion.objectStoreNames.contains("storeData")) newVersion.createObjectStore("storeData", { keyPath: "storeId" });
      //if (!newVersion.objectStoreNames.contains("columnMetadata")) newVersion.createObjectStore("columnMetadata", { keyPath: "id" });

    };

  }

  navigateToOffline(){
    this._router.navigate(['/offline-price-management']);
  }



}
