import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { AgGridAngular } from 'ag-grid-angular';
import { GridOptions } from 'ag-grid-community';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from 'src/app/shared/confirmation-dialog/confirmation-dialog.component';
import { GridService } from 'src/app/shared/grid.service';
import { MessageService } from 'src/app/shared/message.service';
import { PermissionsService } from 'src/app/shared/permissions.service';
import { ViewDetailButtonComponent } from 'src/app/shared/view-detail-button/view-detail-button.component';
import { DeleteButtonComponent } from 'src/app/shared/delete-button/delete-button.component';
import { AnnouncementService } from 'src/app/shared/announcement.service';
import { AuthenticationService } from 'src/app/core/authentication/shared/authentication.service';

@Component({
  selector: 'app-admin-announcements-list',
  templateUrl: './admin-announcements-list.component.html',
  styleUrls: ['./admin-announcements-list.component.css'],
})
export class AdminAnnouncementsListComponent implements OnInit {
  @ViewChild('agGrid') agGrid: AgGridAngular;

  adminDetailRef: MatDialogRef<AdminAnnouncementsDetailDialog>;
  confirmationDialogRef: MatDialogRef<ConfirmationDialogComponent>;

  gridOptions: GridOptions;
  quickFilterText: string = '';
  context; //required by grid
  //frameworkComponents; //required by grid
  allPrivateLabels;
  permissions: any = {};
  myUser;

  rowData = [];

  getColumnDefs(){
    return [
      {
        headerName: "",
        field: "action",
        width: 70,
        minWidth: 70,
        maxWidth: 90,
        cellClass: "action-col",
        cellRenderer: this.permissions.roleAllowsUserToCreateAnnouncements ? "ViewDetailButtonComponent" : null,
        pinned: "left",
        sortable: false,
        filter: false,
        suppressHeaderMenuButton: true,
        suppressNavigable: true,
        hide: !this.permissions.roleAllowsUserToCreateAnnouncements,
      },
			{
				headerName: "Message",
				field: "message",
				cellClass: "message-col",
        filter: "agTextColumnFilter",
        get headerTooltip() { return this.headerName; }
			},
			{
				headerName: "Created",
				field: "created",
				cellClass: "created-col",
				cellRenderer: this._gridService.dateTimeRenderer,
        filter: 'agDateColumnFilter',
        filterParams: {
          comparator: this._gridService.dateComparator,
          inRangeInclusive: true
        },
        get headerTooltip() { return this.headerName; }
      },
			{
				headerName: "Modified",
				field: "modified",
				cellClass: "modified-col",
				cellRenderer: this._gridService.dateTimeRenderer,
        filter: 'agDateColumnFilter',
        filterParams: {
          comparator: this._gridService.dateComparator,
          inRangeInclusive: true
        },
        get headerTooltip() { return this.headerName; }
      },
			{
				headerName: "Active",
				field: "active",
				width: 60,
				cellClass: ["active-col", "tac"],
				cellRenderer: this._gridService.checkRenderer,
				filter: "agSetColumnFilter",
				filterParams: {
					cellRenderer: this._gridService.checkFilterRenderer,
					values: [true, false],
					newRowsAction: 'keep'
        },
        get headerTooltip() { return this.headerName; }
			},
      {
        headerName: "",
        field: "delete",
        width: 80,
        minWidth: 80,
        maxWidth: 80,
        cellClass: ["delete-col", "tac"],
        cellRenderer: this.permissions.roleAllowsUserToCreateAnnouncements && !this.myUser.hasReadOnlyRestriction ? "DeleteButtonComponent" : null,
        sortable: false,
        filter: false,
        suppressHeaderMenuButton: true,
        suppressNavigable: true,
        hide: !this.permissions.roleAllowsUserToCreateAnnouncements
      }

    ];
  }
  
  constructor(
    private _announcementService: AnnouncementService,
    private _authenticationService: AuthenticationService,
    private _gridService: GridService,
    private _dialog: MatDialog, 
    private _messageService: MessageService,
    private _permissionsService: PermissionsService,
    ) {
    this.myUser = this._authenticationService.getMyUser();
    this.permissions = this._permissionsService.getPermissions();
    this.gridOptions = <GridOptions> {
			columnDefs: this.getColumnDefs(),
			rowData: this.rowData,
      rowHeight: 35,    
      components: {
        ViewDetailButtonComponent: ViewDetailButtonComponent,
        DeleteButtonComponent: DeleteButtonComponent,
      },
      defaultColDef: {
        sortable: true,
        resizable: true,
        floatingFilter: true,
      }
    }
    this.context = { componentParent: this }
   }

  ngOnInit() {
    this.getAllAnnouncements();
  }

  getAllAnnouncements(){
    this._announcementService.getAllAnnouncements()
      .subscribe(
        (data) => {
          this.formatandSetRowData(data);
        },
        (response) => {
          this._messageService.onFailure('Failed to get announcements.', response);
        })
  }

  formatandSetRowData(data) {
    var gridData = [];
    for (var i=0; i<data.length; i++){
      var singleRowData = {};
      singleRowData = {
        announcementId: data[i].announcementId,
        message: data[i].message,
        created: data[i].created,
        modified: data[i].modified,
        active: data[i].active,
    };
      gridData.push(singleRowData);
    }
    this.rowData = gridData;
    this._gridService.onWindowResize('#ag-grid-wrapper');
    setTimeout(() => {
      this.fitColumns();
      this._gridService.sizeGrid('#ag-grid-wrapper');
    }, 100); 
  }

  clearFilters(){
    this.quickFilterText = '';
    this.agGrid.api.setGridOption('quickFilterText', this.quickFilterText);
    this.agGrid.api.setFilterModel(null);
    this.agGrid.api.onFilterChanged();
  }

  resetColumns(){
    this.agGrid.api.resetColumnState();
    this.fitColumns(); //since this is done by default also
  }

  autoSizeColumns(){
    var columns = this.agGrid.api.getColumns();
    this.agGrid.api.autoSizeColumns(columns);
  }

  fitColumns(){
    this.agGrid.api.sizeColumnsToFit();
  }

  onQuickFilterChange(){
    this.agGrid.api.setGridOption('quickFilterText', this.quickFilterText);
  }

  onDetailButtonClick(params){
    this.adminDetailRef = this._dialog.open(AdminAnnouncementsDetailDialog, {
      width: '850px',
      //height: '600px',
      data: {
        id: params.data.announcementId,
      }
    });
    this.adminDetailRef.afterClosed().subscribe(result => {
      if(result === 'reload') {
        this.getAllAnnouncements();
      }
      this.adminDetailRef = null;
    });
  }

  onDeleteButtonClick(params){
    //Users with hasReadOnlyRestriction set to true should not even be allowed to invoke this action because it is hidden or disabled in the UI.
    //This is for extra protection. The back end will also be protected.
    if (this.myUser.hasReadOnlyRestriction){
      this._messageService.alertReadOnlyRestriction();
      return;
    }
    
    this.confirmationDialogRef = this._dialog.open(ConfirmationDialogComponent, {
      disableClose: false,
      //width: '600px',
      data: {
        title: 'Are you sure?',
        message: 'Do you want to delete "' + params.data.message + '"?',
        confirmText: 'Yes, delete it',
        cancelText: 'No, cancel this action!'
      }
    });
    this.confirmationDialogRef.afterClosed().subscribe(result => {
      if(result) {
        this._announcementService.deleteAnnouncement(params.data.announcementId)
          .subscribe(
            (data) => {
              this._messageService.onSuccess('Successfully deleted announcement.');
              this.getAllAnnouncements();
            },
            (response) => {
              this._messageService.onFailure('Failed to delete announcement.', response);
            }
          )
      }
      this.confirmationDialogRef = null;
    });
  }

  goToCreateAnnouncement(){
    this.adminDetailRef = this._dialog.open(AdminAnnouncementsDetailDialog, {
      width: '850px',
      //height: '600px',
      data: {
        id: 0
      }
    });
    this.adminDetailRef.afterClosed().subscribe(result => {
      if(result === 'reload') {
        this.getAllAnnouncements();
      }
      this.adminDetailRef = null;
    });
  }

}

@Component({
  selector: 'app-admin-announcements-detail-dialog',
  template: `<rpms-admin-announcements-detail [id]="data.id"></rpms-admin-announcements-detail>`,
})
export class AdminAnnouncementsDetailDialog {

  constructor(
    public dialogRef: MatDialogRef<AdminAnnouncementsDetailDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any) {}

}
