<div class="detail admin-dialog">
<h1 mat-dialog-title [ngClass]="{'accent': userId == '0'}">{{mode}} {{userDetailForm.get('userName').value}}</h1>
<div mat-dialog-content>
<mat-tab-group dynamicHeight="true" (selectedTabChange)="onTabChange($event)">
  <mat-tab label="User Information">
    <span *ngIf="!isNewAccount" class="last-login">Last Login: {{lastLoginDate}}</span>
    <form [formGroup]="userDetailForm" id="user-detail-form" (ngSubmit)="onSubmitUser()">
      <div class="row">
        <mat-form-field *ngIf="!isMyAccount">
          <mat-label>User Role</mat-label>
          <mat-select id="role" formControlName="role" placeholder="User Role" required>
            <mat-option *ngFor="let role of roles" [value]="role.name">
              {{role.friendlyName}}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="userDetailForm.hasError('required', 'role')">User Role is required</mat-error>
        </mat-form-field>
        <mat-form-field>
          <mat-label>Username (Email)</mat-label>
          <input matInput id="userName" formControlName="userName" placeholder="Username (Email)" maxlength="100" required autocomplete="new-password">
          <mat-error *ngIf="userDetailForm.hasError('required', 'userName')">Username (Email) is required</mat-error>
          <mat-error *ngIf="userDetailForm.hasError('pattern', 'userName')">Please enter a valid email address</mat-error>
          <!-- <mat-hint align="end">{{userDetailForm.get('userName').value.length}} / 100</mat-hint> -->
        </mat-form-field>
      </div>
      <div class="row">
        <mat-form-field>
          <mat-label>First Name</mat-label>
          <input matInput id="firstName" formControlName="firstName" placeholder="First Name" maxlength="100">
        </mat-form-field>
        <mat-form-field>
          <mat-label>Last Name</mat-label>
          <input matInput id="lastName" formControlName="lastName" placeholder="Last Name" required maxlength="100">
          <mat-error *ngIf="userDetailForm.hasError('required', 'lastName')">Last Name is required</mat-error>
        </mat-form-field>
        <mat-form-field>
          <mat-label>Phone</mat-label>
          <input matInput id="phoneNumber" formControlName="phoneNumber" placeholder="Phone" mask='(000) 000-0000'>
        </mat-form-field>
      </div>
      <div class="row" *ngIf="userDetailForm.get('role').value === 'SuperStoreGroupAdmin' && !isMyAccount">
        <mat-form-field>
          <mat-label>Managed Store Groups</mat-label>
          <mat-select multiple id="managedStoreGroupIds" formControlName="managedStoreGroupIds" placeholder="Managed Store Groups">
            <mat-option *ngFor="let sg of storeGroups" [value]="sg.storeGroupId">
              {{sg.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>	
      </div>
      <div class="row" *ngIf="showStoreGroupAndStoreSelection">
        <mat-form-field>
          <mat-label>Home Store Group</mat-label>
          <mat-select id="homeStoreGroupId" formControlName="homeStoreGroupId" placeholder="Home Store Group"
            [compareWith]="compareFn" (selectionChange)="getAllStoresByStoreGroupId($event.value)" [required]="userDetailForm.get('homeStoreGroupId').errors !== null && userDetailForm.get('homeStoreGroupId').errors.required">
            <mat-option *ngFor="let storeGroup of storeGroups" [value]="storeGroup.storeGroupId">
              {{storeGroup.name}}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="userDetailForm.hasError('required', 'homeStoreGroupId')">Home Store Group is required</mat-error>
        </mat-form-field>
        <mat-form-field>
          <mat-label>home Store</mat-label>
          <mat-select id="homeStoreId" formControlName="homeStoreId" placeholder="Home Store" [compareWith]="compareFn">
            <mat-option *ngFor="let store of stores" [value]="store.storeId">
              {{store.numberAndLocation}} <span *ngIf="!store.active" class="not-active">(not active)</span>
            </mat-option>
          </mat-select>
          <mat-error *ngIf="userDetailForm.hasError('required', 'homeStoreId')">Home Store is required</mat-error>
        </mat-form-field>
      </div>
      <div class="row" *ngIf="isNewAccount">
        <mat-form-field>
          <mat-label>Password</mat-label>
          <input matInput id="password" formControlName="password" placeholder="Password" [type]="hide ? 'password' : 'text'" required autocomplete="new-password" maxlength="100">
          <!-- <mat-icon matSuffix (click)="hide = !hide">{{hide ? 'visibility' : 'visibility_off'}}</mat-icon> -->
          <mat-error *ngIf="userDetailForm.hasError('required', 'password')">Password is required</mat-error>
        </mat-form-field>
        <mat-form-field>
          <mat-label>Confirm Password</mat-label>
          <input matInput id="confirmPassword" formControlName="confirmPassword" placeholder="Confirm Password" [type]="hide ? 'password' : 'text'" required autocomplete="new-password" maxlength="100">
          <!-- <mat-icon matSuffix (click)="hide = !hide">{{hide ? 'visibility' : 'visibility_off'}}</mat-icon> -->
          <mat-error *ngIf="userDetailForm.hasError('required', 'confirmPassword')">Confirm Password is required</mat-error>
          <mat-error *ngIf="userDetailForm.errors?.misMatch">Passwords do not match</mat-error>
        </mat-form-field>
      </div>
      <div class="field-note" *ngIf="isNewAccount">Note: Password must be at least 8 characters, contain at least 1 uppercase, 1 lowercase, and 1 numeric character.</div>
      <div class="checkbox-form-field">
        <mat-checkbox id="active" formControlName="active" *ngIf="!isMyAccount">Active</mat-checkbox>
      </div>
      <div class="checkbox-form-field">
        <mat-checkbox id="hasReadOnlyRestriction" formControlName="hasReadOnlyRestriction">Has Read Only Restriction</mat-checkbox>
      </div>
      <div class="checkbox-form-field">
        <mat-checkbox id="hasDeletionPrivilege" formControlName="hasDeletionPrivilege">Has Deletion Privilege</mat-checkbox>
      </div>
      <div class="checkbox-form-field">
        <mat-checkbox id="hasTransferPrivilege" formControlName="hasTransferPrivilege">Has Transfer Privilege</mat-checkbox>
      </div>
      <div class="detail-action-buttons">
        <button mat-raised-button color="primary" [disabled]="!userDetailForm.valid" type="submit">Save</button>
        <button mat-raised-button (click)="closeDialog()" type="button">Cancel</button>
      </div>
    </form>
  </mat-tab>
  <mat-tab label="{{passwordMode}} Password" *ngIf="!isNewAccount">
    <form [formGroup]="passwordForm" id="password-form" (ngSubmit)="onSubmitPassword()">
      <div class="row" *ngIf="isMyAccount">
        <mat-form-field>
          <input matInput id="currentPassword" formControlName="currentPassword" placeholder="Current Password" [type]="hide ? 'password' : 'text'" required autocomplete="new-password" maxlength="100">
          <!-- <mat-icon matSuffix (click)="hide = !hide">{{hide ? 'visibility' : 'visibility_off'}}</mat-icon> -->
          <mat-error *ngIf="passwordForm.hasError('required', 'currentPassword')">Current Password is required</mat-error>
        </mat-form-field>
      </div>
      <div class="row">
        <mat-form-field>
          <input matInput id="password" formControlName="password" placeholder="New Password" [type]="hide ? 'password' : 'text'" required>
          <!-- <mat-icon matSuffix (click)="hide = !hide">{{hide ? 'visibility' : 'visibility_off'}}</mat-icon> -->
          <mat-error *ngIf="passwordForm.hasError('required', 'password')">Password is required</mat-error>
        </mat-form-field>
        <mat-form-field>
          <input matInput id="confirmPassword" formControlName="confirmPassword" placeholder="Confirm Password" [type]="hide ? 'password' : 'text'" required autocomplete="new-password" maxlength="100">
          <!-- <mat-icon matSuffix (click)="hide = !hide">{{hide ? 'visibility' : 'visibility_off'}}</mat-icon> -->
          <mat-error *ngIf="passwordForm.hasError('required', 'confirmPassword')">Confirm Password is required</mat-error>
          <mat-error *ngIf="passwordForm.errors?.misMatch">Passwords do not match</mat-error>
        </mat-form-field>
      </div>
      <div class="field-note">Note: Password must be at least 8 characters, contain at least 1 uppercase, 1 lowercase, and 1 numeric character.</div>
      <div class="detail-action-buttons">
        <button mat-raised-button color="primary" [disabled]="!passwordForm.valid" type="submit">Save</button>
        <button mat-raised-button (click)="closeDialog()" type="button">Cancel</button>
      </div>
    </form>
  </mat-tab>
  <mat-tab label="Owned Assets" *ngIf="!isNewAccount">
    <ng-template mat-tab-label>
      <div>Owned Assets<span class="detail badge">{{assetCount}}</span></div>
    </ng-template>
    <app-user-owned-assets (assetCountEvent)="onAssetCountEvent($event)" (openTransferDialogEvent)="onTransferButtonClick()" [userId]="userId" [showTransferButton]="showTransferButton"></app-user-owned-assets>
    <div class="detail-action-buttons">
      <button mat-raised-button (click)="closeDialog()" type="button">Close</button>
    </div>
  </mat-tab>

</mat-tab-group>
</div>
</div>