import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AppConfig } from '../app.config';

@Injectable({
  providedIn: 'root'
})
export class DeletionService {

  private _baseUrl = AppConfig.settings.baseUrl;
  private _reqHeader = new HttpHeaders({ 'Accept': 'application/json', 'Content-Type': 'application/json' });

  constructor ( 
    private _http: HttpClient, 
  ){}

  getDeletionLogsByDeletionEntityType(entityType:('Store Group' | 'Store' | 'User')) { 
    return this._http.get(this._baseUrl + '/api/Deletions/GetDeletionLogsByDeletionEntityType/' + encodeURI(entityType), { headers: this._reqHeader });
  }

  deleteSelectAssetsFromEntity(entityId:string, entityType:('Store Group' | 'Store' | 'User'), assetTypeOrAll, forceForAll:boolean, selectedAssetIds:number[]) {
    return this._http.put(this._baseUrl + '/api/Deletions/DeleteSelectAssetsFromEntity/' + String(entityId) + '/' + encodeURI(entityType) + '/' + encodeURI(assetTypeOrAll)+ '/' + forceForAll, selectedAssetIds, { headers: this._reqHeader });
  }


}
