import { Component, OnInit, Inject, LOCALE_ID, ChangeDetectorRef, ViewChild, ViewChildren, QueryList } from '@angular/core';
import { UntypedFormBuilder, NgForm, NgModel } from '@angular/forms';
import { MessageService } from 'src/app/shared/message.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { PricingRulesService } from 'src/app/shared/pricing-rules.service';
import { ConfirmationDialogComponent } from 'src/app/shared/confirmation-dialog/confirmation-dialog.component';
import { StoreService } from 'src/app/shared/store.service';
import { StoreGroupService } from 'src/app/shared/store-group.service';
import { PermissionsService } from 'src/app/shared/permissions.service';
import { AppConfig } from 'src/app/app.config';
import { AuthenticationService } from 'src/app/core/authentication/shared/authentication.service';

@Component({
  selector: 'app-admin-pricing-rules-detail',
  templateUrl: './admin-pricing-rules-detail.component.html',
  styleUrls: ['./admin-pricing-rules-detail.component.css']
})
export class AdminPricingRulesDetailComponent implements OnInit {
  @ViewChild('pricingRuleForm') pricingRuleForm: NgForm;
  @ViewChildren('typeSelect') typeSelects: QueryList<NgModel>;
  @ViewChildren('matchingStrategySelect') matchingStrategySelects: QueryList<NgModel>;
  @ViewChildren('matchingValueInput') matchingValueInputs: QueryList<NgModel>;
  @ViewChildren('matchingValueSelect1') matchingValueSelects1: QueryList<NgModel>;
  @ViewChildren('matchingValueSelect2') matchingValueSelects2: QueryList<NgModel>;

  private config = AppConfig.settings;
  
  confirmationDialogRef: MatDialogRef<ConfirmationDialogComponent>;

  pricingRuleId;
  pricingRule:any = {
    matchCriteria: [
      {
        type: '',
        matchingStrategy: '',
        matchingValue: '',
      }
    ]
  };
  mode: string;
  stores;
  storeGroups;
  types;
  isStoreSelectionVisible: boolean = false;
  isStoreGroupSelectionVisible: boolean = false;
  pricingRulePreviewModel;
  pricingRulePreviewStores;
  pricingRulePreviewStoreId;
  haveAnyFieldsChanged: boolean = false;
  haveCriteriaFieldsChangedSinceViewingMatchedItems: boolean = false;
  areAllCriteriaFieldsValid: boolean = false;
  numberOfMatchingItems;
  isGridDataLoaded: boolean = false;
  valueFormat;
  pricingError;
  permissions: any = {};
  storeGroupId;
  myUser;

  constructor(private _fb: UntypedFormBuilder,
              private _storeService: StoreService,
              private _storeGroupService: StoreGroupService,
              private _pricingRulesService: PricingRulesService,
              private _authenticationService: AuthenticationService,
              private _messageService: MessageService,
              private _dialog: MatDialog, 
              private _activatedRoute: ActivatedRoute,
              private _router: Router,
              private _permissionsService: PermissionsService,
              private _changeDetectorRef: ChangeDetectorRef,
              @Inject(LOCALE_ID) private locale: string) 
              { 
                this.myUser = this._authenticationService.getMyUser();
                this.permissions = this._permissionsService.getPermissions();
              }

  ngOnInit() {
    this._activatedRoute.paramMap.subscribe((params: ParamMap) => {
      this.pricingRuleId = params.get('pricingRuleId');
      this.mode = this.pricingRuleId == '0' ? 'New' : 'Edit';
      this.getAllStores();
    })
  }

  getAllStores(){
    this._storeService.getAllStores()   
      .subscribe(
        (data) => {
          this.stores = data;
          this.getMyStoreGroupsWithStores();
        },
        (response) => {
          this._messageService.onFailure('Failed to get stores.', response);
        });
  }

  getMyStoreGroupsWithStores(){
    this._storeGroupService.getMyStoreGroupsWithStores()
      .subscribe(
        (data) => {
          this.storeGroups = data;
          this.getAllPricingRuleMatchCriterionTypes();
        },
        (response) => {
          this._messageService.onFailure('Failed to get store groups.', response);
        });
  }

  getAllPricingRuleMatchCriterionTypes(){
    this._pricingRulesService.getAllPricingRuleMatchCriterionTypes()
      .subscribe(
        (data) => {
          this.types = data;
          this.getOrCreatePricingRule();
        },
        (response) => {
          this._messageService.onFailure('Failed to get match criterion types.', response);
        });
  }

  getStoreFromId(id) {
    for (var i = 0; i < this.stores.length; i++) {
      if (this.stores[i].storeId == id) return this.stores[i];
    }
    return {};
  }

  getStoreGroupFromId(id) {
    for (var i = 0; i < this.storeGroups.length; i++) {
      if (this.storeGroups[i].storeGroupId == id) return this.storeGroups[i];
    }
    return {};
  }

  getCriteriaTypeObjectFromStringType(strType) {
    for (var i = 0; i < this.types.length; i++) {
      if (this.types[i].criterionType == strType) return this.types[i];
    }
    return {};
  } 

  getOrCreatePricingRule() {
    if (this.pricingRuleId == '0') {
      this.pricingRule = {
				active: true,
				name: '',
				description: '',
				ruleType: null,
				storeGroupId: null,
				storeId: null,
				changeType: '',
				book: null,
				srpCode: null,
				multi: '',
				price: '',
				percent: '',
				keepPercent: null,
				keepSrp: null,
				setPricing: true,
        matchCriteria: [
          {
            type: '',
            matchingStrategy: '',
            matchingValue: '',
          }
        ]
      };
      this.pricingRulePreviewModel = this.createPricingRuleModel();
    }
    else {
      this._pricingRulesService.getPricingRuleById(this.pricingRuleId)
      .subscribe(
        (data:any) => {
          this.pricingRule = data;
          if (this.config.logIds) console.log(`%cpricingRuleName: ${data.name}`, 'background: black; color: white; font-weight: bold; font-size: 120%');
          if (this.config.logIds) console.log(`%cpricingRuleId: ${data.pricingRuleId}`, 'background: yellow; font-weight: bold; font-size: 120%');

          this.pricingRule.multi = data.multi == 0 ? '' : data.multi;
          this.pricingRule.percent = data.percent == 0 ? '' : data.percent;
          this.pricingRule.price = data.price == 0 ? '' : Number(data.price).toFixed(2);

          for (var i = 0; i < this.pricingRule.matchCriteria.length; i++) {
            this.setStrategyTypesAndValidMatchingValuesFromCriterionType(i, this.pricingRule.matchCriteria[i].type);
          }
          setTimeout(() => {
            this.setChangeType(this.pricingRule.changeType);
            //should have no changes initially after loading
            this.haveAnyFieldsChanged = false;
          }, 100);
          if (this.pricingRule.ruleType === 'Store' && this.pricingRule.storeId != null) {
            this.isStoreSelectionVisible = true;
            this.isStoreGroupSelectionVisible = false;
            var store = this.getStoreFromId(this.pricingRule.storeId);
            this.pricingRulePreviewStores = [store];
            this.pricingRulePreviewStoreId = this.pricingRule.storeId;
          }
          else {
            this.isStoreSelectionVisible = false;
            this.isStoreGroupSelectionVisible = true;
            var storeGroup = this.getStoreGroupFromId(this.pricingRule.storeGroupId);
            this.pricingRulePreviewStores = storeGroup.stores;
            this.pricingRulePreviewStoreId = storeGroup.stores[0].storeId;
          }
          this.pricingRulePreviewModel = this.createPricingRuleModel();
        },
        (response) => {
          this._messageService.onFailure("Failed to get pricing rule.", response);
        });
    }
  }

  onAnyChange() {
    this.haveAnyFieldsChanged = true;
    this._changeDetectorRef.detectChanges();
    //put in settimeout so this runs after everything is evaluated
    setTimeout(() => { 
      this.areAllCriteriaFieldsValid = this.testIfAllCriteriaFieldsValid(); 
      //console.log('ARE ALL VALID: ' + this.areAllCriteriaFieldsValid);
    }, 1);
  }

  testIfAllCriteriaFieldsValid() {

    let areAllCriteriaFieldsValid = true;

    let nameValid = this.pricingRuleForm.controls['name'].valid;
    let descriptionValid = this.pricingRuleForm.controls['description'].valid;
    let ruleTypeValid = this.pricingRuleForm.controls['ruleType'].valid;
    let storeValid = this.pricingRuleForm.controls['store'] ? this.pricingRuleForm.controls['store'].valid : true;
    let storeGroupValid = this.pricingRuleForm.controls['storeGroup'] ? this.pricingRuleForm.controls['storeGroup'].valid : true;

    areAllCriteriaFieldsValid = nameValid 
                                  && descriptionValid 
                                  && ruleTypeValid 
                                  && storeValid 
                                  && storeGroupValid

    this.typeSelects.forEach(ctrl => {
      if (!ctrl.valid) {
        areAllCriteriaFieldsValid = false;
      }
    });

    this.matchingStrategySelects.forEach(ctrl => {
      if (!ctrl.valid) {
        areAllCriteriaFieldsValid = false;
      }
    });

    this.matchingValueInputs.forEach(ctrl => {
      if (!ctrl.valid) {
        areAllCriteriaFieldsValid = false;
      }
    });

    this.matchingValueSelects1.forEach(ctrl => {
      if (!ctrl.valid) {
        areAllCriteriaFieldsValid = false;
      }
    });

    this.matchingValueSelects2.forEach(ctrl => {
      if (!ctrl.valid) {
        areAllCriteriaFieldsValid = false;
      }
    });
  
    return areAllCriteriaFieldsValid;
  }

  onCriteriaFieldsChange(){
    this.onAnyChange();
    this.haveCriteriaFieldsChangedSinceViewingMatchedItems = true;
    this.pricingRulePreviewModel = this.createPricingRuleModel();

    //console.log(`pricingRuleForm.valid: ${this.pricingRuleForm.valid}`);
    //console.log(`permissions.roleAllowsUserToEditPricingRule: ${this.permissions.roleAllowsUserToEditPricingRule}`);
    //console.log(`haveCriteriaFieldsChangedSinceViewingMatchedItems: ${this.haveCriteriaFieldsChangedSinceViewingMatchedItems}`);
    //console.log(`haveAnyFieldsChanged: ${this.haveAnyFieldsChanged}`);
  }

  onActiveCheckboxChange(){
    //console.log(this.pricingRule.active);
    if (this.pricingRule.active) this.onCriteriaFieldsChange();
    else this.onAnyChange();
  }
  
  onSetPricingChange(){
    this.onCriteriaFieldsChange();
    this.pricingError = '';
  }

  setHaveCriteriaFieldsChangedSinceViewingMatchedItems(updatedValue: boolean) {
    this.haveCriteriaFieldsChangedSinceViewingMatchedItems = updatedValue;
  }

  setNumberOfMatchingItems(updatedValue) {
    this.numberOfMatchingItems = updatedValue;
  }

  setIsGridDataLoaded(updatedValue: boolean) {
    this.isGridDataLoaded = updatedValue;
  }

  onRuleTypeChanged() {
    this.onCriteriaFieldsChange();
    //this.handleChangeAffectingPricingRulePreview();
    if (this.pricingRule.ruleType === 'Store') {
      this.isStoreSelectionVisible = true;
      this.isStoreGroupSelectionVisible = false;
      this.pricingRulePreviewStores = [];
      this.pricingRulePreviewStoreId = null;
    }
    else {
      this.isStoreSelectionVisible = false;
      this.isStoreGroupSelectionVisible = true;
      this.onStoreGroupChanged();
    }
  };

  onValueChanged() {
    this.onCriteriaFieldsChange();
    //this.handleChangeAffectingPricingRulePreview();
    this._changeDetectorRef.detectChanges();
  };
  
  onStoreChanged() {
    this.onCriteriaFieldsChange();
    //this.handleChangeAffectingPricingRulePreview();
    var selectedStore = this.getStoreFromId(this.pricingRule.storeId);
    if (!this.isEmpty(selectedStore)) {
      this.pricingRule.storeGroupId = selectedStore.storeGroupId;
      this.pricingRulePreviewStores = [selectedStore];
      this.pricingRulePreviewStoreId = selectedStore.storeId;
    }
  };

  onStoreGroupChanged() {
    this.onCriteriaFieldsChange();
    //this.handleChangeAffectingPricingRulePreview();
    var selectedStoreGroup = this.getStoreGroupFromId(this.pricingRule.storeGroupId);
    if (!this.isEmpty(selectedStoreGroup)) {
      this.pricingRulePreviewStores = selectedStoreGroup.stores;
      this.pricingRulePreviewStoreId = selectedStoreGroup.stores[0].storeId;
    }
  };

  onCriteriaTypeChanged($index, strType) {
    this.onCriteriaFieldsChange();
    //this.handleChangeAffectingPricingRulePreview();
    //clear criteria for the entry whose type just changed
    this.pricingRule.matchCriteria[$index].matchingStrategy = null;
    this.pricingRule.matchCriteria[$index].matchingValue = '';
    this.pricingRule.matchCriteria[$index].matchingValueSelect = null;
    this.pricingRule.matchCriteria[$index].matchingValueMultipleSelect = [];
    this.setStrategyTypesAndValidMatchingValuesFromCriterionType($index, strType);
    switch (strType) {
      case "DealDate":
        this.valueFormat = 'yyyy-mm-dd';
        break;
      default:
        this.valueFormat = 'Value';
        break;
    }
  };

  setStrategyTypesAndValidMatchingValuesFromCriterionType($index, strType) {
    for (var i = 0; i < this.types.length; i++) {
      if (this.types[i].criterionType === strType) {
        this.pricingRule.matchCriteria[$index].supportedStrategyTypes = this.types[i].supportedStrategyTypes;
        this.pricingRule.matchCriteria[$index].validMatchingValues = this.types[i].validMatchingValues;
        // if there are vaild matching values, then set the select based on the matching value and then clear the matching value
        // so that the input doesn't end up with a different hidden value when it get submitted.
        if (this.types[i].validMatchingValues != null && strType != 'PrivateLabel') {
          this.pricingRule.matchCriteria[$index].matchingValueSelect = this.pricingRule.matchCriteria[$index].matchingValue;
          this.pricingRule.matchCriteria[$index].matchingValue = '';
        }
        if (this.types[i].validMatchingValues != null && strType == 'PrivateLabel') {
          if (this.pricingRule.matchCriteria[$index].matchingValue == '') {
            this.pricingRule.matchCriteria[$index].matchingValueMultipleSelect = [];
          }
          else {
            this.pricingRule.matchCriteria[$index].matchingValueMultipleSelect = this.pricingRule.matchCriteria[$index].matchingValue.split(',');
          }
          this.pricingRule.matchCriteria[$index].matchingValue = '';
        }
      }
    }
  }

  onMatchingStrategyChanged($index) {
    this.onCriteriaFieldsChange();
    //this.handleChangeAffectingPricingRulePreview();
    switch (this.pricingRule.matchCriteria[$index].matchingStrategy) {
      case 'ListOr':
      this.pricingRule.matchCriteria[$index].matchingValuePlaceholder = this.valueFormat + ',' + this.valueFormat + ',...';
        break;
      case 'ListAnd':
      this.pricingRule.matchCriteria[$index].matchingValuePlaceholder = this.valueFormat + ',' + this.valueFormat + ',...';
        break;
      case 'ListStartsWith':
      this.pricingRule.matchCriteria[$index].matchingValuePlaceholder = this.valueFormat + ',' + this.valueFormat + ',...';
        break;
      case 'ListEqualsOrRange':
      this.pricingRule.matchCriteria[$index].matchingValuePlaceholder = this.valueFormat + ',' + this.valueFormat + ':' + this.valueFormat + ',...';
        break;
      case 'InRange':
      this.pricingRule.matchCriteria[$index].matchingValuePlaceholder = this.valueFormat + ':' + this.valueFormat;
        break;
      case 'Regex':
      this.pricingRule.matchCriteria[$index].matchingValuePlaceholder = this.valueFormat === 'yyyy-mm-dd' ? 'Regular expression to match yyyy-mm-dd' : 'Regular Expression';
        break;
      default:
      this.pricingRule.matchCriteria[$index].matchingValuePlaceholder = this.valueFormat;
        break;
    }
  };

  addCriteriaEntry(event) {
    this.pricingRule.matchCriteria.push(
      {
        type: '',
        matchingStrategy: '',
        matchingValue: '',
      });
      this._changeDetectorRef.detectChanges();
      event.stopPropagation();
    //must go after add
    this.onCriteriaFieldsChange();
  };

  removeCriteriaEntry($index) {
    this.pricingRule.matchCriteria.splice($index, 1);
    this._changeDetectorRef.detectChanges();
    //must go after remove
    this.onCriteriaFieldsChange();
  };

  setChangeType(changeType) {
    if (this.haveCriteriaFieldsChangedSinceViewingMatchedItems) this.onCriteriaFieldsChange();
    else this.onAnyChange();
    this.pricingError = '';
    this.pricingRule.changeType = changeType;
    switch (changeType) {
      case 'Book':
        this.pricingRule.multi = '';
        this.pricingRule.price = '';
        this.pricingRule.keepSrp = '';
        this.pricingRule.percent = '';
        this.pricingRule.keepPercent = '';
        break;
      case 'Price':
        this.pricingRule.book = '';
        this.pricingRule.srpCode = '';
        this.pricingRule.percent = '';
        this.pricingRule.keepPercent = '';
        break;
      case 'Percent':
        this.pricingRule.book = '';
        this.pricingRule.srpCode = '';
        this.pricingRule.multi = '';
        this.pricingRule.price = '';
        this.pricingRule.keepSrp = '';
        break;
    }
    this._changeDetectorRef.detectChanges();
  };

  onPriceBlur() {
    this.pricingRule.price = this.pricingRule.price ? Number(this.pricingRule.price).toFixed(2) : '';
  };

  onPercentBlur() {
    this.pricingRule.percent = this.pricingRule.percent ? Math.round(Number(this.pricingRule.percent)) : '';
  };

  onMultiBlur() {
    this.pricingRule.multi = this.pricingRule.multi ? Math.round(Number(this.pricingRule.multi)) : '';
  };

  doesPricingPassValidation() {
    this.pricingError = '';

    var book = this.pricingRule.book;
    var srpCode = this.pricingRule.srpCode;
    var multi = this.pricingRule.multi;
    var price = this.pricingRule.price;
    var keepSrp = this.pricingRule.keepSrp;
    var percent = this.pricingRule.percent;
    var keepPercent = this.pricingRule.keepPercent;

    var passes = false;
    if (this.pricingRule.setPricing) {
      switch (this.pricingRule.changeType) {
        case 'Book':
          if (book == '' || srpCode == '' && book != 'D') {
            this.pricingError = 'Book and SRP Code are required.';
          }
          else {
            passes = true;
          }
          break;
        case 'Price':
          //if (multi == '' || price == '' || keepSrp == null) {
          if (multi == '' || price == '') { //changed 2-26-2024
            //this.pricingError = 'Multi, Price and Keep Price are required.';
            this.pricingError = 'Multi and Price are required.';
            passes = false;
          }
          else if (Number(price) < .01) {
            this.pricingError = 'Price must be greater than zero.';
            passes = false;
          }
          else if (Number(multi) < 1) {
            this.pricingError = 'Multi must be greater than zero.';
            passes = false;
          }
          else {
            passes = true;
          }
          break;
        case 'Percent':
          //if (percent == '' || keepPercent == null) {
          if (percent == '') {
            // this.pricingError = 'Percent and Keep Percent are required.';
            this.pricingError = 'Percent is required.';
            passes = false;
          }
          else if (Number(percent) < -99 || Number(percent) > 99) {
            this.pricingError = 'Percent must be between -99 and 99.';
            passes = false;
          }
          else {
            passes = true;
          }
          break;
        default:
          this.pricingError = 'Please enter values for a Book, Price or Percent change.';
          break;
      }
      //console.log(passes);
      return passes;
    }
    else {
      return true;
    }
  }

  createPricingRuleModel() {
    var pricingRuleModel:any = {};
    pricingRuleModel.pricingRuleId = this.pricingRule.pricingRuleId;
    pricingRuleModel.setPricing = this.pricingRule.setPricing;
    pricingRuleModel.active = this.pricingRule.active;
    pricingRuleModel.priority = this.pricingRule.priority;
    pricingRuleModel.name = this.pricingRule.name;
    pricingRuleModel.description = this.pricingRule.description;
    pricingRuleModel.ruleType = this.pricingRule.ruleType;
    pricingRuleModel.storeGroupId = this.pricingRule.storeGroupId == null ? null : Number(this.pricingRule.storeGroupId);
    pricingRuleModel.storeId = this.pricingRule.storeId == null ? null : Number(this.pricingRule.storeId);
    pricingRuleModel.changeType = this.pricingRule.changeType == '' ? 'Price' : this.pricingRule.changeType;
    pricingRuleModel.book = this.pricingRule.book == null || this.pricingRule.book == '' ? 'C' : this.pricingRule.book;
    pricingRuleModel.srpCode = this.pricingRule.srpCode;
    pricingRuleModel.multi = this.pricingRule.multi;
    pricingRuleModel.price = Number(this.pricingRule.price).toFixed(2);
    pricingRuleModel.percent = this.pricingRule.percent;
    pricingRuleModel.matchCriteria = this.pricingRule.matchCriteria;

    if (this.pricingRule.keepPercent === '') {
      pricingRuleModel.keepPercent = null;
    } 
    else {
        pricingRuleModel.keepPercent = this.pricingRule.keepPercent == 'true' ? true : false;
    }

    if (this.pricingRule.keepSrp === '') {
      pricingRuleModel.keepSrp = null;
    } 
    else {
        pricingRuleModel.keepSrp = this.pricingRule.keepSrp == 'true' ? true : false;
    }

    for (var i = 0; i < pricingRuleModel.matchCriteria.length; i++) {
      // if value is from select
      if (pricingRuleModel.matchCriteria[i].matchingValueSelect != null) {
        pricingRuleModel.matchCriteria[i].matchingValue = pricingRuleModel.matchCriteria[i].matchingValueSelect;
      }
      // if value is from multiple select
      else if (pricingRuleModel.matchCriteria[i].matchingValueMultipleSelect && pricingRuleModel.matchCriteria[i].matchingValueMultipleSelect.length > 0) {
        pricingRuleModel.matchCriteria[i].matchingValue = pricingRuleModel.matchCriteria[i].matchingValueMultipleSelect.toString();
      }
      if (pricingRuleModel.matchCriteria[i].matchingValue == '') pricingRuleModel.matchCriteria[i].matchingValue = null;
    }

    var selectedStore:any = {};
    if (this.isStoreSelectionVisible) {
      for (var i = 0; i < this.stores.length; i++) {
        if (this.stores[i].storeId == this.pricingRule.storeId) {
          selectedStore = this.stores[i];
          break;
        }
      }
      pricingRuleModel.storeGroupId = Number(selectedStore.storeGroupId);
    }
    //console.log(pricingRuleModel);
    return pricingRuleModel;
  }

  onSubmitPricingRule() {
    //Users with hasReadOnlyRestriction set to true should not even be allowed to invoke this action because it is hidden or disabled in the UI.
    //This is for extra protection. The back end will also be protected.
    if (this.myUser.hasReadOnlyRestriction){
      this._messageService.alertReadOnlyRestriction();
      return;
    }

    if (this.doesPricingPassValidation()) {
      var pricingRuleModel = this.createPricingRuleModel();
      this.confirmationDialogRef = this._dialog.open(ConfirmationDialogComponent, {
        disableClose: false,
        //width: '600px',
        data: {
          title: 'Are you sure?',
          message: `This Pricing Rule will be applied to the ${this.numberOfMatchingItems} items that are shown in the Pricing Rule Preview grid. Do you want to proceed with this action?`,
          confirmText: 'Yes, proceed',
          cancelText: 'No, cancel this action!'
        }
      });
      this.confirmationDialogRef.afterClosed().subscribe(result => {
        if(result) {
          if (this.pricingRuleId == 0) { // creating new
            this.createPricingRule(pricingRuleModel)      
          }
          else { // updating existing
            this.updatePricingRule(pricingRuleModel)      
          }
        }
        this.confirmationDialogRef = null;
      });
    }
  };

  createPricingRule(pricingRuleModel){
    //Users with hasReadOnlyRestriction set to true should not even be allowed to invoke this action because it is hidden or disabled in the UI.
    //This is for extra protection. The back end will also be protected.
    if (this.myUser.hasReadOnlyRestriction){
      this._messageService.alertReadOnlyRestriction();
      return;
    }

    this._pricingRulesService.createPricingRule(pricingRuleModel)
      .subscribe(
        (data) => {
          this._messageService.onSuccess('Successfully created pricing rule.');
          this._router.navigate(['/admin/pricing-rules']);
        },
        (response) => {
          this._messageService.onFailure('Failed to create pricing rule.', response);
        });
  }

  updatePricingRule(pricingRuleModel){
    //Users with hasReadOnlyRestriction set to true should not even be allowed to invoke this action because it is hidden or disabled in the UI.
    //This is for extra protection. The back end will also be protected.
    if (this.myUser.hasReadOnlyRestriction){
      this._messageService.alertReadOnlyRestriction();
      return;
    }
    
    pricingRuleModel.pricingRuleId = Number(this.pricingRuleId);
    this._pricingRulesService.updatePricingRule(pricingRuleModel.pricingRuleId, pricingRuleModel)
      .subscribe(
        (data) => {
          this._messageService.onSuccess('Successfully updated pricing rule.');
          this._router.navigate(['/admin/pricing-rules']);
        },
        (response) => {
          this._messageService.onFailure('Failed to update pricing rule.', response);
        });
  }

  cancel() {
    if (this.haveAnyFieldsChanged) {
      this.confirmationDialogRef = this._dialog.open(ConfirmationDialogComponent, {
        disableClose: false,
        //width: '600px',
        data: {
          title: 'Are you sure?',
          message: 'Would you like leave this page?',
          confirmText: 'Yes, leave this page',
          cancelText: 'No, cancel this action!'
        }
      });
      this.confirmationDialogRef.afterClosed().subscribe(result => {
        if(result) {
          this._router.navigate(['/admin/pricing-rules']);
        }
        this.confirmationDialogRef = null;
      });
    }
    else {
      this._router.navigate(['/admin/pricing-rules']);
    }
  };

  isEmpty(obj) {
    var hasOwnProperty = Object.prototype.hasOwnProperty;
      if (obj == null) return true;
      if (obj.length > 0)    return false;
      if (obj.length === 0)  return true;
      if (typeof obj !== "object") return true;
      for (var key in obj) {
          if (hasOwnProperty.call(obj, key)) return false;
      }
      return true;
  }

}


