<div class="page-heading">
  <h1><i class="fa fa-usd-circle"></i>Pricing Rule Preview</h1>
</div>
<div>
  <mat-form-field>
    <mat-select id="store-select" name="store" #storeSelect="ngModel" [(ngModel)]="pricingRulePreviewStoreId" placeholder="Store" (selectionChange)="onStoreSelected()">
      <mat-option *ngFor="let store of pricingRulePreviewStores" [value]="store.storeId">
        {{store.numberAndLocation}}
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>
<button mat-raised-button color="accent" (click)="showItems()" [disabled]="!pricingRulePreviewStoreId || !areAllCriteriaFieldsValid"><i class="far fa-eye"></i>View Items that Match Criteria</button>
<div [hidden]="!pricingRulePreviewStoreId || !isDataLoaded || haveCriteriaFieldsChangedSinceViewingMatchedItems">
  <div class="table-toolbar" style="margin-top: 20px;">
    <div class="filter-tools">
        <button (click)="clearFilters()" mat-raised-button title="Clear All Filters"><i class="far fa-ban"></i></button>
        <input id="quick-filter" class="quick-filter" placeholder="Quick Filter..." type="text" [(ngModel)]="quickFilterText" (keyup)="onQuickFilterChange()" />
    </div>
    <div class="column-tools">
      Columns: &nbsp;
      <button (click)="resetColumns()" mat-raised-button title="Reset Columns"><i class="far fa-columns"></i></button>
      <button (click)="fitColumns()" mat-raised-button title="Fit Columns to Width"><i class="far fa-arrows-h"></i></button>
      <button (click)="autoSizeColumns()" mat-raised-button title="Auto Size Columns"><i class="far fa-arrow-from-left"></i></button>
    </div>
  </div>
    <ag-grid-angular #agGrid 
    [gridOptions]="gridOptions"
    style="width: 100%; height: 500px;" 
    class="ag-theme-balham"
    id="ag-grid-wrapper"
    [rowData]="rowData" 
    [columnDefs]="columnDefs"
    [context]="context"
    [quickFilterText]="quickFilterText"
    (modelUpdated)="onModelUpdated()"
    >
  </ag-grid-angular>
  <div class="row-count">{{rowCount}} Rows</div>
</div>