import { Component, OnInit } from '@angular/core';
import { PermissionsService } from 'src/app/shared/permissions.service';

@Component({
  selector: 'app-admin-home',
  templateUrl: './admin-home.component.html',
  styleUrls: ['./admin-home.component.css']
})
export class AdminHomeComponent implements OnInit {

  permissions: any = {};

  constructor(
    private _permissionsService: PermissionsService
  ) { }

  ngOnInit() {
    this.permissions = this._permissionsService.getPermissions();
  }

}
