<div class="page-heading">
    <h1><i class="fa fa-tag"></i>Shelf Tag Orders</h1>
    <div class="page-heading-buttons">
    </div>
</div>
<div>
    <mat-form-field>
        <mat-select id="storeId" placeholder="Store" (selectionChange)="getShelfTags($event.value)">
            <mat-option *ngFor="let store of stores" [value]="store.storeId">
            {{store.numberAndLocation}} <span *ngIf="!store.active" class="not-active">(not active)</span>
            </mat-option>
        </mat-select>
    </mat-form-field>
</div>
<div *ngIf="isDataLoaded">
    <div class="table-toolbar">
        <div class="filter-tools">
            <button (click)="clearFilters()" mat-raised-button title="Clear All Filters"><i class="far fa-ban"></i></button>
            <input id="quick-filter" class="quick-filter" placeholder="Quick Filter..." type="text" [(ngModel)]="quickFilterText" (keyup)="onQuickFilterChange()" />
        </div>
        <div class="column-tools">
          Columns: &nbsp;
          <button (click)="resetColumns()" mat-raised-button title="Reset Columns"><i class="far fa-columns"></i></button>
          <button (click)="fitColumns()" mat-raised-button title="Fit Columns to Width"><i class="far fa-arrows-h"></i></button>
          <button (click)="autoSizeColumns()" mat-raised-button title="Auto Size Columns"><i class="far fa-arrow-from-left"></i></button>
        </div>
      </div>
        <ag-grid-angular #agGrid 
        [gridOptions]="gridOptions"
        style="width: 100%; height: 1px;" 
        class="ag-theme-balham"
        id="ag-grid-wrapper"
        [rowData]="rowData" 
        [columnDefs]="columnDefs"
        [context]="context"
        [quickFilterText]="quickFilterText"
        >
    </ag-grid-angular>
</div>