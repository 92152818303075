<div id="book-editor-wrapper" class="editor-wrapper" cdkDrag>
    <div class="editor-title" cdkDragHandle>
    Book Editor <span *ngIf="isGridReadOnly || isItemReadOnly">(Read Only)</span>
    <span class="close" (click)="close()"><i class="fa fa-times"></i></span>
    </div>
    <div class="editor-item-title">
    Item Code: {{rowData.itemCode}} - {{rowData.description}}
    </div>
    <div class="editor-content">
        <div id="book-pricing-table" [ngClass]="{'read-only' : isGridReadOnly || isItemReadOnly}">
            <table>
                <tr>
                    <td class="no-select">&nbsp;</td>
                    <td class="no-select">Book 1</td>
                    <td class="no-select">Book 2</td>
                    <td class="no-select">Book 3</td>
                    <td class="no-select">Book 4</td>
                    <td class="no-select">Book 5</td>
                    <td class="no-select">Book 6</td>
                    <td class="no-select">Book 7</td>
                </tr>
                <tr id="row-A">
                    <td class="no-select">A</td>
                    <td *ngFor="let rowA of bookData[0]; let i = index" (click)="onCellClick('A', i + 1)"><span class="price">{{rowA.price}}</span><span class="percent">{{rowA.percent}}</span></td>
                </tr>
                <tr id="row-B">
                    <td class="no-select">B</td>
                    <td *ngFor="let rowB of bookData[1]; let i = index" (click)="onCellClick('B', i + 1)"><span class="price">{{rowB.price}}</span><span class="percent">{{rowB.percent}}</span></td>
                </tr>
                <tr id="row-C">
                    <td class="no-select">C</td>
                    <td *ngFor="let rowC of bookData[2]; let i = index" (click)="onCellClick('C', i + 1)"><span class="price">{{rowC.price}}</span><span class="percent">{{rowC.percent}}</span></td>
                </tr>
                <tr id="row-H">
                    <td class="no-select">H</td>
                    <td *ngFor="let rowH of bookData[3]; let i = index" (click)="onCellClick('H', i + 1)"><span class="price">{{rowH.price}}</span><span class="percent">{{rowH.percent}}</span></td>
                </tr>
                <tr id="row-1">
                    <td class="no-select">10</td>
                    <td *ngFor="let row1 of bookData[4]; let i = index" (click)="onCellClick('1', i + 1)"><span class="price">{{row1.price}}</span><span class="percent">{{row1.percent}}</span></td>
                </tr>
                <tr id="row-2">
                    <td class="no-select">20</td>
                    <td *ngFor="let row2 of bookData[5]; let i = index" (click)="onCellClick('2', i + 1)"><span class="price">{{row2.price}}</span><span class="percent">{{row2.percent}}</span></td>
                </tr>
                <tr id="row-3">
                    <td class="no-select">30</td>
                    <td *ngFor="let row3 of bookData[6]; let i = index" (click)="onCellClick('3', i + 1)"><span class="price">{{row3.price}}</span><span class="percent">{{row3.percent}}</span></td>
                </tr>
                <tr id="row-4">
                    <td class="no-select">40</td>
                    <td *ngFor="let row4 of bookData[7]; let i = index" (click)="onCellClick('4', i + 1)"><span class="price">{{row4.price}}</span><span class="percent">{{row4.percent}}</span></td>
                </tr>
                <tr id="row-5">
                    <td class="no-select">50</td>
                    <td *ngFor="let row5 of bookData[8]; let i = index" (click)="onCellClick('5', i + 1)"><span class="price">{{row5.price}}</span><span class="percent">{{row5.percent}}</span></td>
                </tr>
                <tr id="row-6">
                    <td class="no-select">60</td>
                    <td *ngFor="let row6 of bookData[9]; let i = index" (click)="onCellClick('6', i + 1)"><span class="price">{{row6.price}}</span><span class="percent">{{row6.percent}}</span></td>
                </tr>
                <tr id="row-7">
                    <td class="no-select">70</td>
                    <td *ngFor="let row7 of bookData[10]; let i = index" (click)="onCellClick('7', i + 1)"><span class="price">{{row7.price}}</span><span class="percent">{{row7.percent}}</span></td>
                </tr>
                <tr id="row-8">
                    <td class="no-select">80</td>
                    <td *ngFor="let row8 of bookData[11]; let i = index" (click)="onCellClick('8', i + 1)"><span class="price">{{row8.price}}</span><span class="percent">{{row8.percent}}</span></td>
                </tr>
                <tr id="row-9">
                    <td class="no-select">90</td>
                    <td *ngFor="let row9 of bookData[12]; let i = index" (click)="onCellClick('9', i + 1)"><span class="price">{{row9.price}}</span><span class="percent">{{row9.percent}}</span></td>
                </tr>
            </table>
            <div class="button-wrapper">
                <button mat-raised-button color="primary" type="submit" (click)="onDeleteClick()" [disabled]="isGridReadOnly || isItemReadOnly || !permissions.roleAllowsUserToEditPricing">Delete This Item</button>
            </div>
            <div id="current-value" *ngIf="doesSrpCodeHaveValue"><span class="legend"></span>Current Value: Book {{rowData.book}}, SRP Code {{rowData.srpCode}}</div>
        </div>
    </div>
</div>
